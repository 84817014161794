import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

import type {
  TAddGeneralInformationParams,
  TCatalogParams,
  TCreateCatalogVersion,
  TShareCatalog,
  TUpdateGeneralInformationParams,
} from 'store/slices/catalogSlice/types';
import type { TGetExcelListParams } from 'store/slices/inventorySlice/types';

export const getCatalogListRequest = (options: TCatalogParams) =>
  client.get(endpoints.CatalogsService.getCatalogs(), { params: { ...options } });

export const getCatalogByIdRequest = (id: number) =>
  client.get(endpoints.CatalogsService.getCatalogById(id));

export const getCatalogByUUIDRequest = (uuid: string) =>
  client.get(endpoints.CatalogsService.getCatalogByUUID(uuid));

export const getAllExcelListRequest = (options: TGetExcelListParams) =>
  client.get(endpoints.CatalogsService.getExcelList(), { params: { ...options } });

export const createCatalogRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.CatalogsService.createCatalog(), body, options);
};

export const deleteCatalogRequest = (id: number) =>
  client.delete(endpoints.CatalogsService.deleteCatalog(id));

export const updateCatalogRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return client.put(endpoints.CatalogsService.updateCatalog(), body, options);
};
export const getCatalogVersionsByIdRequest = (id: number) =>
  client.get(endpoints.CatalogsService.getCatalogVersions(id));

export const createCatalogVersionRequest = (version: TCreateCatalogVersion) =>
  client.post(endpoints.CatalogsService.createCatalogVersion(), version);

export const getCatalogVersionsByVersionIdRequest = (id: number) =>
  client.get(endpoints.CatalogsService.getCatalogVersionsByVersionId(id));

export const shareCatalogRequest = (options: TShareCatalog) =>
  client.post(endpoints.CatalogsService.shareCatalog(), { ...options });

export const shareCatalogDataByIdRequest = (id: number) =>
  client.get(endpoints.CatalogsService.getShareDCatalogDataById(id));

export const getShareDCatalogDataByUserIdRequest = (id: number) =>
  client.get(endpoints.CatalogsService.getShareDCatalogDataByUserId(id));

export const getShareDCatalogDataByCatalogIdRequest = (id: number) =>
  client.get(endpoints.CatalogsService.getShareDCatalogDataByCatalogId(id));

export const removedAccessByIdRequest = (id: number[]) =>
  client.delete(endpoints.CatalogsService.removeCatalogAccess(id), { data: id });

export const removeCatalogSectionByIdRequest = (id: number) =>
  client.delete(endpoints.CatalogsService.deleteCatalogSection(id));

export const addGeneralInformationRequest = (params: TAddGeneralInformationParams) =>
  client.post(endpoints.CatalogsService.addGeneralInformation(), { ...params });

export const updateGeneralInformationRequest = (params: TUpdateGeneralInformationParams) =>
  client.put(endpoints.CatalogsService.updateGeneralInformation(), { ...params });

export const removeGeneralInformationRequest = (id: number) =>
  client.delete(endpoints.CatalogsService.removeGeneralInformation(id));

export const createCatalogTemplateRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.CatalogsService.createCatalogTemplate(), body, options);
};

export const updateCatalogTemplateRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.put(endpoints.CatalogsService.updateCatalogTemplate(), body, options);
};
