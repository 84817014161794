import { type FC } from 'react';
import { Box } from '@mui/material';

import { CustomTypography, CustomBreadcrumbs } from 'components';

import { breadCrumbOptions } from './utils';
import styles from './TransactionHeader.module.scss';

const TransactionHeader: FC = () => {
  return (
    <Box className={styles.container}>
      <Box className={styles.container__routing}>
        <Box className={styles.container__routing__pages}>
          <CustomBreadcrumbs options={breadCrumbOptions} />
        </Box>
      </Box>
      <Box className={styles.container__main}>
        <CustomTypography className={styles.container__main__title}>
          Transaction History
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default TransactionHeader;
