import { useEffect, type FC, useRef, useState, Fragment } from 'react';
import classNames from 'classnames';
import { GridColDef } from '@mui/x-data-grid';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { catalogsSliceSelector } from 'store/slices/catalogSlice/selectors';
import { MoreIcon, ShareIcon, TrashIcon, EditIconOutLined } from 'assets/icons';
import {
  useAppDispatch,
  useAppSelector,
  useInput,
  useOnClickOutside,
  useRefreshClearState,
} from 'hooks';
import {
  EmptyTitle,
  DeletePopup,
  CatalogCard,
  ScrollLayout,
  CustomDataGrid,
  CatalogCellCard,
  ShowAfterAccessView,
  ShareCatalogDropDown,
  DynamicPaginationControl,
} from 'components';
import { findCatalogs, getCatalogList } from 'store/thunks';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { deleteCatalogText } from 'components/views/Cards/CatalogCard/constants';
import CatalogCreateConfigure from 'components/views/Drawers/CatalogCreateConfigure';
import { EmptyTitles } from 'constants/EmptyTitles';
import { Routes } from 'types';

import { useCatalog } from './hooks';
import { catalogColumns } from './utils';
import styles from './Catalog.module.scss';
import CatalogHeader from './CatalogHeader';
import { sxMenuShareStyles, sxMenuStyles } from './styles';

import type { SortByEnums, TCatalog } from 'store/slices/catalogSlice/types';

const Catalog: FC = () => {
  const dispatch = useAppDispatch();

  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const { value, debouncedValue, handleChange } = useInput();
  const { isAccessToCatalogsEditor, isAccessToCatalogsViewer, isAccessToInventoryEditor } =
    useAppSelector(userPermissionsSelector);

  const haveAccessForActions = isAccessToCatalogsEditor && isAccessToInventoryEditor;

  const haveAccessInInventories = isAccessToCatalogsViewer || isAccessToCatalogsEditor;

  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);

  useRefreshClearState();

  const { catalogList, catalogLimit, catalogOffset, catalogListLoading, catalogAsc } =
    useAppSelector(catalogsSliceSelector);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field as SortByEnums);

    const sortByValue =
      column?.field === 'version'
        ? 'created_at'
        : column?.field === 'items'
        ? 'number_of_items'
        : column?.field;

    dispatch(
      getCatalogList({
        sort_by: sortByValue as SortByEnums,
        asc: catalogAsc,
        limit: catalogLimit,
        offset: catalogOffset,
      }),
    );
  };

  const {
    page,
    setPage,
    anchorEl,
    onDelete,
    openDrawer,
    closeDrawer,
    togglePopup,
    isPopupOpen,
    isNewDrawer,
    handleEvent,
    handleClose,
    isShareOpen,
    isViewInGrid,
    isEditCatalog,
    setIsShareOpen,
    selectedItemId,
    openEditDrawer,
    seeCatalogInfo,
    closeEditDrawer,
    removedUsersIds,
    handleMoreClick,
    setIsViewInGrid,
    handlePageChange,
    setRemovedUsersIds,
    handleRowsPerPageChange,
  } = useCatalog();

  const menuRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(menuRef, handleClose);

  useEffect(() => {
    if (debouncedValue?.length > 0) {
      dispatch(findCatalogs({ query: debouncedValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    dispatch(getCatalogList({ limit: catalogLimit, offset: catalogOffset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogOffset, catalogLimit]);

  const renderColumns: GridColDef[] = catalogColumns.map((column) => {
    const isColumnSorted = newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !catalogAsc },
      {
        [styles.activeSortHeader_asc]: catalogAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const dropdownItems = [
    {
      label: 'Edit',
      icon: <EditIconOutLined />,
      paramsAction: (id: number) => openEditDrawer(id),
    },
    {
      label: 'Delete',
      icon: <TrashIcon />,
      paramsAction: () => togglePopup(),
    },
    {
      label: 'Share',
      icon: <ShareIcon />,
      isNotClosable: true,
      paramsAction: () => setIsShareOpen(true),
    },
  ];

  const renderMenuItem = dropdownItems.map((item, idx) => {
    const onClick = () => {
      selectedItemId && item.paramsAction(selectedItemId);
      !item.isNotClosable && handleClose();
    };

    return (
      <MenuItem disabled={!isAccessToCatalogsEditor} key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderRows = catalogList?.data?.map((el: TCatalog) => {
    const renderCost = el.total_cost ? ` $${el.total_cost}.00` : '';

    return {
      id: el.id,
      uuid: el?.uuid,
      name: <CatalogCellCard userName={el?.name} img={el?.image_url_id as string} />,
      version: `V${el?.id}` || '--',
      description: el?.description || '--',
      category: el.category || '--',
      items: el.number_of_items || '--',
      cost: `${renderCost}` || '--',
      options: haveAccessForActions && (
        <IconButton sx={{ padding: '3px' }} onClick={(event) => handleMoreClick(event, el.id)}>
          <MoreIcon />
        </IconButton>
      ),
    };
  });

  const renderCatalogCards = catalogList?.data?.map((el) => (
    <CatalogCard
      key={el.id}
      id={el.id}
      uuid={el?.uuid}
      name={el.name}
      onDelete={onDelete}
      created_at={el.created_at}
      seeCatalogInfo={seeCatalogInfo}
      number_of_items={el.number_of_items}
      created_by={el?.created_by as string}
      openDrawerInEditMode={openEditDrawer}
      image_url_id={el.image_url_id as string}
    />
  ));

  const renderMenuClasses = isShareOpen ? sxMenuShareStyles : sxMenuStyles;

  const contentClasses = classNames(styles.container__content, {
    [styles.container__content__grid]: isViewInGrid,
    [styles.container__content__empty]: !isViewInGrid && !renderCatalogCards?.length,
  });

  useEffect(() => {
    if (!catalogList?.data?.length && page) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogList?.data?.length]);

  if (!haveAccessInInventories && !userAllRolesLoading) {
    return <Navigate to={Routes.ActivityFeed} replace />;
  }

  const haveAccess = isAccessToCatalogsViewer || isAccessToCatalogsEditor;

  return (
    <>
      <Box className={styles.container}>
        <CatalogHeader
          value={value}
          onChange={handleChange}
          isViewInGrid={isViewInGrid}
          createNewDrawer={openDrawer}
          setIsViewInGrid={setIsViewInGrid}
          totalRows={catalogList.total_count}
        />

        <ShowAfterAccessView emptyMessageTitle='Catalogs' isShow={haveAccess}>
          <Box className={contentClasses}>
            {isViewInGrid ? (
              <ScrollLayout>
                <CustomDataGrid
                  isLoading={catalogListLoading}
                  rowHeight={64}
                  headerHeight={44}
                  columns={renderColumns}
                  rows={renderRows}
                  onRowClick={handleEvent}
                  emptyTitle={EmptyTitles.Catalogs}
                  onColumnHeaderClick={handleColumnHeaderClick}
                />
              </ScrollLayout>
            ) : renderCatalogCards?.length ? (
              <Fragment>{renderCatalogCards}</Fragment>
            ) : (
              <EmptyTitle title='No Catalogs added yet' />
            )}
          </Box>
          {catalogList.total_count > 0 && (
            <DynamicPaginationControl
              page={page}
              rowsPerPage={catalogLimit}
              count={catalogList.total_count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
          <Menu
            ref={menuRef}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={renderMenuClasses}
            open={Boolean(anchorEl)}
          >
            {isShareOpen ? (
              <ShareCatalogDropDown
                onClose={handleClose}
                setIsOpen={setIsShareOpen}
                removedUsersIds={removedUsersIds}
                sharedId={selectedItemId as number}
                setRemovedUsersIds={setRemovedUsersIds}
              />
            ) : (
              renderMenuItem
            )}
          </Menu>
        </ShowAfterAccessView>
      </Box>
      <CatalogCreateConfigure open={isEditCatalog} onClose={closeEditDrawer} inEditMode />
      <CatalogCreateConfigure open={isNewDrawer} onClose={closeDrawer} />
      <DeletePopup
        withBody
        onDelete={onDelete}
        isOpen={isPopupOpen}
        onClose={togglePopup}
        body={deleteCatalogText}
        title='Delete Confirmation'
      />
    </>
  );
};

export default Catalog;
