import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import localforage from 'localforage';

import { Routes } from 'types';
import { GoogleIcon } from 'assets/icons';
import { loginOAuth } from 'store/slices/authSlice/authThunks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Button, ProcessFormTitle, CustomTypography } from 'components';
import { TUserProfile, TokenProvider } from 'store/slices/authSlice/types';
import { useAppDispatch, useUserOrganizationsList, useWindowSize } from 'hooks';

import styles from './SignIn.module.scss';

const SignIn = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    localforage.clear();
  }, []);

  const navigate = useNavigate();

  const location = useLocation();

  const { width } = useWindowSize();

  const [invitationToken, setInvitationToken] = useState<string>('');

  const { getAllOrganizations, loginDependentInOrganization } = useUserOrganizationsList();

  const currentOrganizationId = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const goToRegistrationPage = () => navigate(Routes.SignUp);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      BrowserStorageService.set(BrowserStorageKeys.GoogleToken, tokenResponse.access_token, {
        session: true,
      });

      if (!invitationToken) {
        const sendedData = new FormData();

        sendedData.append('oauth_token', String(tokenResponse.access_token));
        sendedData.append('oauth_provider', TokenProvider.GOOGLE);

        const response: TUserProfile = await dispatch(loginOAuth(sendedData)).unwrap();

        if (response.token && response.is_first_time_user && response.is_first_time_user === true) {
          navigate(Routes.ProcessForm);
        } else {
          const response = await getAllOrganizations().then((res) =>
            res?.data?.filter(
              (organization) =>
                organization?.resource_status !== 'pending' &&
                organization?.resource_status !== 'failed',
            ),
          );

          if (!response?.length) {
            navigate(Routes.ProcessForm);
            return;
          }

          if (response && response?.length > 1) {
            navigate(Routes.OrganizationsList);
          } else {
            const firstOrganizationStatus = response?.[0]?.resource_status;

            if (firstOrganizationStatus === 'pending' || firstOrganizationStatus === 'creating') {
              toast.info('You have organization in pending state please wait');

              return;
            } else if (firstOrganizationStatus === 'failed') {
              toast.info('Your organization creation failed please try again');

              navigate(Routes.ProcessForm);
              return;
            } else {
              loginDependentInOrganization(Number(response?.[0]?.id), tokenResponse.access_token);
            }
          }
        }
      } else {
        const sendedData = new FormData();

        sendedData.append('oauth_token', String(tokenResponse.access_token));
        sendedData.append('oauth_provider', TokenProvider.GOOGLE);
        sendedData.append('invitation_token', invitationToken);
        sendedData.append('org_id', String(currentOrganizationId));

        const response: TUserProfile = await dispatch(loginOAuth(sendedData)).unwrap();
        if (response.token && response.is_first_time_user && !response?.is_invited_user) {
          navigate(Routes.ProcessForm);
        } else {
          navigate(Routes.ActivityFeed);
        }
      }
    },
  });

  const getParameterByName = (name: string, url: string) => {
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  useEffect(() => {
    const invitationToken = getParameterByName('invitation_token', location.search);
    const invitationTokenOrgId = getParameterByName('org_id', location.search);

    if (invitationToken && invitationTokenOrgId) {
      BrowserStorageService.set(BrowserStorageKeys.InvitationToken, invitationToken, {
        session: true,
      });

      BrowserStorageService.set(BrowserStorageKeys.CurrentOrganizationId, invitationTokenOrgId);

      setInvitationToken(invitationToken);
    } else {
      setInvitationToken('');
      BrowserStorageService.remove(BrowserStorageKeys.InvitationToken, {
        session: true,
      });
    }
  }, [location.search]);

  return (
    <Box className={styles.container}>
      <div className={styles.container__main}>
        {Number(width) <= 576 ? (
          <ProcessFormTitle
            marginBottom='40px'
            title='Welcome Back!'
            subtitle='Please Log In To Continue'
          />
        ) : (
          <ProcessFormTitle marginBottom='40px' title='Please Log In To Continue' />
        )}

        <Stack direction='column' spacing={2} className={styles.container__footer}>
          <Button
            startIcon={<GoogleIcon />}
            className={styles.container__footer__btn}
            onClick={() => login()}
          >
            LOGIN WITH GOOGLE
          </Button>
        </Stack>
      </div>
      <Box className={styles.container__enroll}>
        <CustomTypography className={styles.container__enroll__text}>
          Don’t have an account?
        </CustomTypography>
        <Button onClick={goToRegistrationPage} className={styles.container__enroll__link}>
          Enroll
        </Button>
      </Box>
    </Box>
  );
};

export default SignIn;
