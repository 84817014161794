import { FC } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { TableEmptyOverlay } from 'components/shared';

import TableCheckbox from '../TableCheckbox';

import { sxStylesCreator } from './styles';

import type { TCustomDataGridProps } from './types';

const CustomDataGrid: FC<TCustomDataGridProps> = ({
  rows,
  columns,
  isLoading,
  rowHeight,
  onRowClick,
  borderStyle,
  emptyTitle,
  rowSelection,
  layoutHeight,
  headerHeight,
  rowSelectionModel,
  checkboxComponent,
  isAllSelection = true,
  fromRequisition = false,
  columnHeaderHeight = 20,
  onRowSelectionModelChange,
  linkFontFamily = 'CircularStdLight',

  ...rest
}) => {
  const sxStyles = sxStylesCreator(isLoading);

  return (
    <DataGrid
      {...rest}
      onRowClick={onRowClick}
      rowSelection={rowSelection}
      columnHeaderHeight={columnHeaderHeight}
      sx={{
        ...sxStyles,

        ' .MuiDataGrid-virtualScroller': {
          borderBottom: rows?.length ? '1px dashed #e6e6e6' : 'none',
          maxHeight: layoutHeight ? `calc(${layoutHeight}px - 52px - 28px - 52px)` : '100%',
        },

        ' .MuiDataGrid-columnHeaderCheckbox': {
          visibility: isAllSelection ? 'visible' : 'hidden',
        },

        ' .MuiDataGrid-columnHeaders': {
          maxHeight: headerHeight ? `${headerHeight}px !important` : '82px !important',
        },

        ' .MuiDataGrid-row': {
          border: `1px ${borderStyle || 'solid'} #E6E6E6`,
          cursor: onRowClick ? 'pointer' : 'default',
        },

        ' .MuiDataGrid-row.Mui-odd': {
          backgroundColor: fromRequisition ? '#fff' : '',
        },

        '&  a': {
          fontFamily: linkFontFamily,
        },
      }}
      slots={{
        baseCheckbox: checkboxComponent || TableCheckbox,
        noRowsOverlay: () => <TableEmptyOverlay emptyTitle={emptyTitle} isLoading={isLoading} />,
      }}
      editMode='cell'
      rows={rows || []}
      columns={columns}
      rowHeight={rowHeight}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
      }
    />
  );
};

export default CustomDataGrid;
