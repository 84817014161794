import { FC } from 'react';
import { Box } from '@mui/material';

import { CustomTypography } from 'components';

import styles from './BillingInfoItem.module.scss';

import type { TBillingInfoItemProps } from './types';

const BillingInfoItem: FC<TBillingInfoItemProps> = ({ title, subtitle }) => (
  <Box className={styles.container}>
    <CustomTypography className={styles.container__title}>{title}:</CustomTypography>
    <CustomTypography className={styles.container__subtitle}>{subtitle || '--'}</CustomTypography>
  </Box>
);

export default BillingInfoItem;
