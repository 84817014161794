import { useEffect, useState } from 'react';
import localforage from 'localforage';

import { clientCache } from 'api';
import { addMediaPromise } from 'utils/promises';

import { TUseMediaParams } from './types';

const useMediaFetching = ({ folderName, imgId }: TUseMediaParams) => {
  const [mediaSource, setMediaSource] = useState<string | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const keyValue = `${folderName}/${imgId}`;

  const fetchDataFromLocalForage = async () => {
    const storedItem = await localforage.getItem(keyValue);
    return storedItem;
  };

  const isVideoFile =
    /\.(mp4|mov|avi|flv|wmv|mkv|webm|mpg|mpeg|vob|ogv|ogg|gifv|m4v|3gp|3g2|f4v|f4p|f4a|quicktime|f4b)$/i.test(
      imgId,
    );

  const isSvg = imgId?.includes('svg+xml');

  useEffect(() => {
    if (!imgId) {
      setIsLoaded(false);
      setMediaSource('empty');
      return;
    }

    if (!isVideoFile) {
      setIsLoaded(true);

      fetchDataFromLocalForage().then((res) => {
        if (res) {
          const imageObjectURL = URL.createObjectURL(
            new Blob([res as any], { type: isSvg ? 'image/svg+xml' : undefined }),
          );

          setMediaSource(imageObjectURL);
          setIsLoaded(false);
          return;
        }

        const mediaPromiseCallback = () =>
          clientCache
            .get(`/media-api/sh/media/${folderName}/${imgId}`, {
              responseType: 'blob',
            })
            .then((response) => {
              const imageObjectURL = URL.createObjectURL(new Blob([response.data]));
              setMediaSource(imageObjectURL);

              localforage.setItem(keyValue, response.data);
            })
            .finally(() => {
              setIsLoaded(false);
            });

        addMediaPromise(`mediaCache_${folderName}_${imgId}`, mediaPromiseCallback);
      });

      return () => {
        if (mediaSource) {
          URL.revokeObjectURL(mediaSource);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderName, imgId]);

  return { mediaSource, isLoaded, key: `mediaCache_${folderName}_${imgId}` };
};

export default useMediaFetching;
