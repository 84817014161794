import React, { FC, Fragment, useState } from 'react';

import { Colors, FontNames } from 'types';

import EditableTab from '../EditableTab';

import styles from './CustomTabs.module.scss';

import type { TCustomTabsProps } from './types';

const CustomTabs: FC<TCustomTabsProps> = ({
  children,
  isEditable,
  addNewTabElement,
  isDisabled = false,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const sectionsLength = Array.isArray(children) && children?.length ? children?.length : 1;

  const tabButtons = React.Children.map(children, (child, index) => {
    if (React.isValidElement(child)) {
      return (
        <Fragment key={index}>
          {isEditable ? (
            <EditableTab
              index={index}
              activeTab={activeTab}
              isDisabled={isDisabled}
              name={child.props.name}
              onClick={handleTabClick}
              label={child.props.label}
              remove={child.props.remove}
              control={child.props.control}
              sectionsLength={sectionsLength}
            />
          ) : (
            <button
              type='button'
              onClick={() => {
                handleTabClick(index);
                child.props.getActiveIndex(child.props.label);
              }}
              style={{
                cursor: 'pointer',
                padding: '8px 16px',
                borderWidth: '2px',
                background: 'transparent',
                borderBottom: '1px solid #ccc',
                fontFamily: FontNames.CIRCULAR_MEDIUM,
                color: index === activeTab ? Colors.SAPPHIRE : Colors.OBSIDIAN_AURA,
                borderColor: index === activeTab ? Colors.SAPPHIRE : Colors.OBSIDIAN_AURA,
              }}
            >
              {child.props.label}
            </button>
          )}
        </Fragment>
      );
    }
    return null;
  });

  return (
    <div>
      <div style={{ marginBottom: 16, display: 'flex' }} className={styles.container}>
        <div className={styles.container__scroll}>
          <div className={styles.container__scroll__content}>{tabButtons}</div>
        </div>
        {addNewTabElement}
      </div>
      <div>{React.Children.toArray(children)[activeTab]}</div>
    </div>
  );
};

export default CustomTabs;
