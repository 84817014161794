import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './types';
import { getCustomerById, getCustomersList } from './thunks';

const initialState: TInitialState = {
  customersLimit: 5,
  customersAsc: true,
  customersOffset: 0,
  customerError: null,
  currentCustomer: null,
  customerLoading: false,
  customers: dataStructure,
  currentCustomerError: null,
  currentCustomerLoading: false,
};

const catalogSlice = createSlice({
  name: 'customerSlice',
  reducers: {
    setPage(state, action) {
      state.customersOffset = (action.payload - 1) * state.customersLimit;
    },
    setLimit(state, action) {
      state.customersLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersList.pending, (state) => {
        state.customerLoading = true;
        state.customerError = null;
      })
      .addCase(getCustomersList.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.customerLoading = false;
        state.customersAsc = !state.customersAsc;
      })
      .addCase(getCustomersList.rejected, (state) => {
        state.customerLoading = false;
      })
      .addCase(getCustomerById.pending, (state) => {
        state.currentCustomerLoading = true;
        state.currentCustomerError = null;
      })
      .addCase(getCustomerById.rejected, (state, action) => {
        state.currentCustomerLoading = false;
        state.currentCustomer = null;
        state.currentCustomerError = action?.payload as string;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.currentCustomer = action.payload;
        state.currentCustomerLoading = false;
        state.currentCustomerError = null;
      });
  },
});

export const { setPage, setLimit } = catalogSlice.actions;

export default catalogSlice.reducer;
