import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { purchaseOrderApi } from 'api';
import {
  PurchaseOrderItemDeleteToastMessages,
  PurchaseOrderItemUpdateToastMessages,
  PurchaseOrderReceiveToastMessages,
  PurchaseOrderSubmitToastMessages,
  PurchaseOrderToastMessages,
} from 'constants/ToastMessages';
import { RootState } from 'types';

import { getAllParts } from '../inventorySlice/thunks';
import { PartsSortBy } from '../inventorySlice/types';

import type { AxiosError } from 'axios';
import type {
  TPurchaseOrdersParams,
  TPurchaseOrderCreateBody,
  TPurchaseOrderItemEditReturnType,
  TUpdatePurchaseOrderItemReturnType,
  TOrderAction,
} from './types';

export const getPurchaseOrders = createAsyncThunk(
  'purchaseOrderSlice/getPurchaseOrders',
  async (options: TPurchaseOrdersParams | undefined, { getState }) => {
    const {
      purchaseOrder: { allPurchaseOrdersLimit, allPurchaseOrdersOffset },
    } = getState() as RootState;

    const {
      asc,
      sort_by,
      limit = allPurchaseOrdersLimit,
      offset = allPurchaseOrdersOffset,
    } = options ?? {};

    try {
      const response = await purchaseOrderApi.getAllPurchaseOrdersRequest({
        asc,
        limit,
        offset,
        sort_by,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPurchaseOrderItems = createAsyncThunk(
  'purchaseOrderSlice/getPurchaseOrderItems',
  async (id: number) => {
    try {
      const response = await purchaseOrderApi.getPurchaseOrderItemsByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPurchaseOrderDataById = createAsyncThunk(
  'purchaseOrderSlice/getPurchaseOrderDataById',
  async (id: number) => {
    try {
      const response = await purchaseOrderApi.getPurchaseOrderByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getPurchaseOrderDataByUuid = createAsyncThunk(
  'purchaseOrderSlice/getPurchaseOrderDataByUuid',
  async (uuid: string) => {
    try {
      const response = await purchaseOrderApi.getPurchaseOrderByUuidRequest(uuid);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updatePurchaseOrder = createAsyncThunk(
  'purchaseOrderSlice/updatePurchaseOrderItem',
  async ({ options, id }: TPurchaseOrderItemEditReturnType, { dispatch }) => {
    const start = toast.loading(PurchaseOrderItemUpdateToastMessages.ITEM_UPDATE_START);
    try {
      const response = await purchaseOrderApi.updatePurchaseOrder(options);

      dispatch(getPurchaseOrderDataById(id));

      toast.update(start, {
        render: PurchaseOrderItemUpdateToastMessages.ITEM_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseOrderItemUpdateToastMessages.ITEM_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updatePurchaseOrderItem = createAsyncThunk(
  'purchaseOrderSlice/updatePurchaseOrderItem',
  async (
    { options, id, fromEditOrder }: TUpdatePurchaseOrderItemReturnType,
    { dispatch, getState },
  ) => {
    const start =
      !fromEditOrder && toast.loading(PurchaseOrderItemUpdateToastMessages.ITEM_UPDATE_START);

    const {
      purchaseOrder: { allPurchaseOrdersLimit, allPurchaseOrdersOffset },
    } = getState() as RootState;

    try {
      const response = await purchaseOrderApi.updatePurchaseOrderItemRequest(options);

      dispatch(
        getPurchaseOrders({ limit: allPurchaseOrdersLimit, offset: allPurchaseOrdersOffset }),
      );
      dispatch(getPurchaseOrderDataById(id));
      !fromEditOrder &&
        start &&
        toast.update(start, {
          render: PurchaseOrderItemUpdateToastMessages.ITEM_UPDATE_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      !fromEditOrder &&
        start &&
        toast.update(start, {
          render: PurchaseOrderItemUpdateToastMessages.ITEM_UPDATE_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

      throw Error;
    }
  },
);

export const createPurchaseOrder = createAsyncThunk(
  'purchaseOrderSlice/createPurchaseOrder',
  async (body: TPurchaseOrderCreateBody, { dispatch, getState }) => {
    const start = toast.loading(PurchaseOrderToastMessages.PURCHASE_ORDER_CREATE_START);

    const {
      purchaseOrder: { allPurchaseOrdersLimit, allPurchaseOrdersOffset },
    } = getState() as RootState;

    try {
      const response = await purchaseOrderApi.createPurchaseOrderRequest(body);

      dispatch(
        getPurchaseOrders({ limit: allPurchaseOrdersLimit, offset: allPurchaseOrdersOffset }),
      );

      toast.update(start, {
        render: PurchaseOrderToastMessages.PURCHASE_ORDER_CREATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseOrderToastMessages.PURCHASE_ORDER_CREATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deletePurchaseOrder = createAsyncThunk(
  'purchaseOrderSlice/deletePurchaseOrder',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(PurchaseOrderItemDeleteToastMessages.ITEM_DELETE_START);

    const {
      purchaseOrder: { allPurchaseOrdersLimit, allPurchaseOrdersOffset },
    } = getState() as RootState;

    try {
      const response = await purchaseOrderApi.deletePurchaseOrderItemRequest(id);

      dispatch(
        getPurchaseOrders({ limit: allPurchaseOrdersLimit, offset: allPurchaseOrdersOffset }),
      );

      toast.update(start, {
        render: PurchaseOrderItemDeleteToastMessages.ITEM_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseOrderItemDeleteToastMessages.ITEM_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const receivePurchaseOrderItem = createAsyncThunk(
  'purchaseOrderSlice/receivePurchaseOrderItem',
  async ({ id, uuid }: TOrderAction, { dispatch, getState }) => {
    const {
      inventory: { allPartsLimit, allPartsOffset },
      purchaseOrder: { allPurchaseOrdersLimit, allPurchaseOrdersOffset },
    } = getState() as RootState;
    const start = toast.loading(PurchaseOrderReceiveToastMessages.ITEM_RECEIVE_START);
    try {
      const response = await purchaseOrderApi.receivePurchaseOrderItemRequest(id);

      dispatch(
        getAllParts({
          asc: true,
          limit: allPartsLimit,
          offset: allPartsOffset,
          sort_by: PartsSortBy.NAME,
        }),
      );
      uuid && dispatch(getPurchaseOrderDataByUuid(uuid as string));
      dispatch(
        getPurchaseOrders({ limit: allPurchaseOrdersLimit, offset: allPurchaseOrdersOffset }),
      );

      start &&
        toast.update(start, {
          render: PurchaseOrderReceiveToastMessages.ITEM_RECEIVE_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      start &&
        toast.update(start, {
          render: PurchaseOrderReceiveToastMessages.ITEM_RECEIVE_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

      throw Error;
    }
  },
);

export const submitPurchaseOrderItem = createAsyncThunk(
  'purchaseOrderSlice/submitPurchaseOrderItem',
  async ({ id, uuid }: TOrderAction, { getState, dispatch }) => {
    const start = toast.loading(PurchaseOrderSubmitToastMessages.ITEM_SUBMIT_START);
    const {
      purchaseOrder: { allPurchaseOrdersLimit, allPurchaseOrdersOffset },
    } = getState() as RootState;

    try {
      const response = await purchaseOrderApi.submitPurchaseOrderItemRequest(id);

      start &&
        toast.update(start, {
          render: PurchaseOrderSubmitToastMessages.ITEM_SUBMIT_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });

      dispatch(
        getPurchaseOrders({ limit: allPurchaseOrdersLimit, offset: allPurchaseOrdersOffset }),
      );
      uuid && dispatch(getPurchaseOrderDataByUuid(uuid));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      start &&
        toast.update(start, {
          render: PurchaseOrderSubmitToastMessages.ITEM_SUBMIT_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });

      throw Error;
    }
  },
);
