export const defaultJobTypeValues = {
  title: '',
  id: null,
  color: null,
};

export type TDefaultJobTypeValues = {
  title: string;
  id: null | number;
  color: null | string;
};
