import { Box } from '@mui/material';
import { type FC } from 'react';

import { AddIcon } from 'assets/icons';
import { useAppSelector } from 'hooks';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import { Button, CustomBreadcrumbs, CustomTypography } from 'components';
import { PermissionMessages } from 'constants/PermissionMessages';
import { Colors } from 'types';

import { purchaseOrderBreadCrumbs } from './utils';
import styles from './PurchaseOrderHeader.module.scss';

import type { TPurchaseOrderHeaderProps } from './types';

const PurchaseOrderHeader: FC<TPurchaseOrderHeaderProps> = ({ handleClick }) => {
  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={purchaseOrderBreadCrumbs} />

      <Box className={styles.container__actions}>
        <CustomTypography className={styles.container__title}>Purchasing Orders</CustomTypography>

        <Button
          color='white'
          variant='contained'
          disabled={isViewer}
          onClick={handleClick}
          backgroundColor={Colors.SAPPHIRE}
          className={styles.container__actions__add}
          startIcon={<AddIcon width={20} height={20} />}
          tooltipMessage={isViewer ? PermissionMessages.PurchaseOrderCreationMessage : undefined}
        >
          Create Purchase Order
        </Button>
      </Box>
    </Box>
  );
};

export default PurchaseOrderHeader;
