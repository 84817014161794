import { FC, useMemo } from 'react';
import { Box } from '@mui/material';

import { Button, CustomTextField, CustomTypography, NodeOrSkeleton } from 'components';
import { useAppSelector } from 'hooks';
import { estimatesSelector } from 'store/slices/estimatesSlice/selectors';

import styles from '../BomEstimateItem.module.scss';

import { TBomEstimateFooter } from './types';

const BomEstimateFooter: FC<TBomEstimateFooter> = ({
  forView,
  inputValue,
  handleChange,
  handleApplyCoupon,
  totalAmountFormatted,
}) => {
  const { discount, estimateItemLoading, estimateItem, discountCouponError } =
    useAppSelector(estimatesSelector);

  const renderNodeOrSkeleton = (
    label: string,
    value: string,
    className = styles.container__main__total__subtitle,
  ) => (
    <NodeOrSkeleton
      isLoading={estimateItemLoading}
      child={<CustomTypography className={className}>{`${label}: $${value}`}</CustomTypography>}
      height='20px'
      width='200px'
    />
  );

  const code = estimateItem?.discount?.[0]?.coupon_code;
  const codeValue = estimateItem?.discount_value;

  const totalPriceView = estimateItem?.total_price;
  const subtotalView = estimateItem?.sub_total?.toFixed(2);
  const totalTax = estimateItem?.total_tax?.toFixed(2);

  const totalAmountCalculated = useMemo(
    () => Number(subtotalView) - Number(discount?.discount || codeValue) + Number(totalTax),
    [codeValue, discount?.discount, subtotalView, totalTax],
  );

  return (
    <Box className={styles.container__main__footer}>
      {!forView ? (
        <Box className={styles.container__main__footer__coupons}>
          <CustomTextField
            maxWidth={133}
            padding='12px 16px'
            value={inputValue}
            onChange={handleChange}
            placeholder='Coupon Code'
            helperText={discountCouponError || ''}
            error={!!discountCouponError?.trim()?.length}
            className={styles.container__main__footer__coupons__code}
          />
          <Button
            onClick={handleApplyCoupon}
            className={styles.container__main__footer__coupons__apply}
          >
            Apply Coupon
          </Button>
        </Box>
      ) : (
        <div />
      )}

      <Box className={styles.container__main__total}>
        {renderNodeOrSkeleton('Sub Total', forView ? String(subtotalView) : totalAmountFormatted)}
        {forView
          ? renderNodeOrSkeleton(
              `Discount (${code || 'Code'})`,
              codeValue ? `-${codeValue}` : '0.00',
            )
          : renderNodeOrSkeleton(
              'Discount Promo',
              discount.discount ? `-${discount.discount}` : codeValue ? `-${codeValue}` : '0.00',
            )}
        {renderNodeOrSkeleton('Tax', forView ? String(totalTax) : String(totalTax) || '0.00')}
        {renderNodeOrSkeleton(
          'Total Price',
          forView ? String(totalPriceView?.toFixed(2)) : String(totalAmountCalculated?.toFixed(2)),
          styles.container__main__total__title,
        )}
      </Box>
    </Box>
  );
};

export default BomEstimateFooter;
