import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { getAgreementById, getAllAgreement, getAllJobTypes, getJobTypeById } from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  agreementOffset: 0,
  agreementLimit: 10,
  agreements: dataStructure,
  agreementsLoading: false,
  agreementsError: null,
  currentAgreementById: null,
  currentAgreementByIdLoad: false,
  currentAgreementByIdError: null,

  jobTypes: dataStructure,
  jobTypesLoading: false,
  jobTypesError: null,
  currentJobTypeById: null,
  currentJobTypeByIdLoad: false,
  currentJobTypeByIdError: null,
};

const agreementSlice = createSlice({
  name: 'agreementSlice',
  reducers: {
    setPage(state, action) {
      state.agreementOffset = (action.payload - 1) * state.agreementLimit;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllAgreement.pending, (state) => {
        state.agreementsLoading = true;
        state.agreementsError = null;
      })
      .addCase(getAllAgreement.fulfilled, (state, action) => {
        state.agreements = action.payload;
        state.agreementsLoading = false;
      })

      .addCase(getAllAgreement.rejected, (state, action) => {
        state.agreementsLoading = false;
        state.agreementsError = action.error.message as string;
      })
      .addCase(getAgreementById.fulfilled, (state, action) => {
        state.currentAgreementById = action.payload;
        state.currentAgreementByIdLoad = false;
        state.currentAgreementByIdError = null;
      })
      .addCase(getAgreementById.rejected, (state, action) => {
        state.currentAgreementByIdLoad = false;
        state.currentAgreementByIdError = action.error.message as string;
      })
      .addCase(getAgreementById.pending, (state) => {
        state.currentAgreementById = null;
        state.currentAgreementByIdLoad = true;
        state.currentAgreementByIdError = null;
      })

      .addCase(getAllJobTypes.pending, (state) => {
        state.jobTypesLoading = true;
        state.jobTypesError = null;
      })
      .addCase(getAllJobTypes.fulfilled, (state, action) => {
        state.jobTypes = action.payload;
        state.jobTypesLoading = false;
      })

      .addCase(getAllJobTypes.rejected, (state, action) => {
        state.jobTypesLoading = false;
        state.jobTypesError = action.error.message as string;
      })
      .addCase(getJobTypeById.fulfilled, (state, action) => {
        state.currentJobTypeById = action.payload;
        state.currentJobTypeByIdLoad = false;
        state.currentJobTypeByIdError = null;
      })
      .addCase(getJobTypeById.rejected, (state, action) => {
        state.currentJobTypeByIdLoad = false;
        state.currentJobTypeByIdError = action.error.message as string;
      })
      .addCase(getJobTypeById.pending, (state) => {
        state.currentJobTypeById = null;
        state.currentJobTypeByIdLoad = true;
        state.currentJobTypeByIdError = null;
      });
  },
});

export default agreementSlice.reducer;

export const { setPage } = agreementSlice.actions;
