import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { client } from 'api';
import { FileDownloadToastMessages } from 'constants/ToastMessages';

import type { TUsePdfDownloadParams } from './types';

const useFileDownload = ({ fileUrl, folder }: TUsePdfDownloadParams) => {
  const [fileName, setFileName] = useState<any>();
  const [isStarted, setIsStarted] = useState<boolean>(false);

  const getTheDocxFile = async () => {
    const start = toast.loading(FileDownloadToastMessages.FILE_DOWNLOAD_IN_PROGRESS);
    try {
      setIsStarted(true);
      const response = await client.get(`/media-api/sh/media/${folder}/${fileUrl}`, {
        responseType: 'arraybuffer',
      });

      toast.update(start, {
        render: FileDownloadToastMessages.FILE_DOWNLOAD_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      setIsStarted(false);

      return response.data;
    } catch (error) {
      toast.update(start, {
        render: FileDownloadToastMessages.FILE_DOWNLOAD_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  };

  const getThePdfFile = async () => {
    try {
      const response = await client.get(`/media-api/sh/media/${folder}/${fileUrl}`, {
        responseType: 'arraybuffer',
      });

      // Check if Content-Disposition header is present
      const contentDispositionHeader = response.headers['content-disposition'];

      if (contentDispositionHeader) {
        // Extract the filename from the header using a regular expression
        const match = contentDispositionHeader.match(/filename="?([^"]+)"?/);
        if (match && match[1]) {
          setFileName(fileName);
        } else {
          // eslint-disable-next-line no-console
          console.error('Unable to extract file name from Content-Disposition header');
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('Content-Disposition header not found in the response');
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  const downloadDocx = (docxData: any, fileName: string) => {
    const blob = new Blob([docxData], {
      type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName?.endsWith('.docx') ? fileName : `${fileName}.docx`; // Ensure proper file extension
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const fetchDataAndDownload = async () => {
    try {
      const docxData = await getTheDocxFile();
      downloadDocx(docxData, fileUrl);
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  return { fetchDataAndDownload, getThePdfFile, fileName, isStarted };
};

export default useFileDownload;
