import { createSlice } from '@reduxjs/toolkit';

import { TInitialState } from './types';
import {
  createSubscription,
  getDefaultPaymentMethod,
  getInvoiceHistory,
  getPaymentMethods,
  getUserSubscriptions,
  getUserSubscriptionsTrailing,
} from './thunks';

const initialState: TInitialState = {
  allPaymentMethods: null,
  userSubscriptions: null,
  invoicesHistory: null,
  userSubscriptionsTrial: null,
  defaultPaymentMethod: '',
  userSubscriptionsError: null,
  allPaymentMethodsError: null,
  freeSubscriptionTrial: false,
  allPaymentMethodsLoader: false,
  invoicesHistoryError: null,
  invoicesHistoryLoader: false,
  defaultPaymentMethodError: null,
  defaultPaymentMethodLoader: false,
  currentSubscriptionPlan: null,
  userSubscriptionsLoader: false,
  currentSubscriptionPlanError: null,
  currentSubscriptionPlanLoading: false,
};

const subscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  reducers: {
    startFreeSubscription(state) {
      state.freeSubscriptionTrial = true;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.pending, (state) => {
        state.currentSubscriptionPlanLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.currentSubscriptionPlanLoading = false;
        state.currentSubscriptionPlan = action.payload;
        state.currentSubscriptionPlanError = null;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.currentSubscriptionPlanLoading = false;
        state.currentSubscriptionPlanError = action.error.message as string;
      })

      .addCase(getUserSubscriptions.pending, (state) => {
        state.userSubscriptionsLoader = true;
      })
      .addCase(getUserSubscriptions.fulfilled, (state, action) => {
        state.userSubscriptionsLoader = false;
        state.userSubscriptions = action.payload;
        state.userSubscriptionsError = null;
      })
      .addCase(getUserSubscriptionsTrailing.fulfilled, (state, action) => {
        state.userSubscriptionsLoader = false;
        state.userSubscriptionsTrial = action.payload;
      })
      .addCase(getUserSubscriptions.rejected, (state, action) => {
        state.userSubscriptionsLoader = false;
        state.userSubscriptionsError = action.error.message as string;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.allPaymentMethods = action.payload;
        state.allPaymentMethodsLoader = false;
        state.allPaymentMethodsError = null;
      })
      .addCase(getPaymentMethods.pending, (state) => {
        state.allPaymentMethodsLoader = true;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.allPaymentMethodsLoader = false;
        state.allPaymentMethodsError = action.payload as string;
      })
      .addCase(getDefaultPaymentMethod.pending, (state) => {
        state.defaultPaymentMethodLoader = true;
      })
      .addCase(getDefaultPaymentMethod.rejected, (state, action) => {
        state.defaultPaymentMethodLoader = false;
        state.defaultPaymentMethodError = action.payload as string;
      })
      .addCase(getDefaultPaymentMethod.fulfilled, (state, action) => {
        state.defaultPaymentMethod = action.payload;
        state.defaultPaymentMethodLoader = false;
        state.defaultPaymentMethodError = null;
      })
      .addCase(getInvoiceHistory.fulfilled, (state, action) => {
        state.invoicesHistory = action.payload;
        state.invoicesHistoryLoader = false;
        state.invoicesHistoryError = null;
      })
      .addCase(getInvoiceHistory.rejected, (state, action) => {
        state.invoicesHistoryLoader = false;
        state.invoicesHistoryError = action.payload as string;
      })
      .addCase(getInvoiceHistory.pending, (state) => {
        state.invoicesHistoryLoader = true;
        state.invoicesHistoryError = null;
      });
  },
});

export const { startFreeSubscription } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
