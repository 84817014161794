import { FC } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { TableEmptyOverlay } from 'components/shared';

import TableCheckbox from '../TableCheckbox';

import { sxStyles } from './styles';
import { newSxStyles } from './newStyles';

import type { TDividedTableProps } from './types';

const DividedTable: FC<TDividedTableProps> = ({
  rows,
  columns,
  rowHeight,
  emptyTitle,
  onRowClick,
  rowSelection,
  layoutHeight,
  isLoading = false,
  rowSelectionModel,
  checkboxComponent,
  isAllSelection = true,
  columnHeaderHeight = 20,
  onRowSelectionModelChange,

  ...rest
}) => {
  const includedStyles = layoutHeight ? newSxStyles : sxStyles;
  const rowHeightValue = rowHeight || (layoutHeight ? 44 : 75);

  return (
    <DataGrid
      {...rest}
      onRowClick={onRowClick}
      rowSelection={rowSelection}
      columnHeaderHeight={columnHeaderHeight}
      sx={{
        ...includedStyles,

        ' .MuiDataGrid-virtualScroller': {
          marginTop: '8px',
          marginBottom: layoutHeight ? '8px' : 0,
          borderBottom: rows?.length ? '1px dashed #e6e6e6' : 'none',
          maxHeight: layoutHeight ? `calc(${layoutHeight}px - 52px - 28px - 52px)` : '100%',
        },

        ' .MuiDataGrid-columnHeaderCheckbox': {
          visibility: isAllSelection ? 'visible' : 'hidden',
        },

        ' .MuiDataGrid-row': {
          cursor: onRowClick ? 'pointer' : 'default',
        },
      }}
      slots={{
        baseCheckbox: checkboxComponent || TableCheckbox,
        noRowsOverlay: () => (
          <TableEmptyOverlay emptyTitle={emptyTitle} isLoading={isLoading} isFromDivided />
        ),
      }}
      editMode='cell'
      rows={rows || []}
      columns={columns}
      rowHeight={rowHeightValue}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={onRowSelectionModelChange}
    />
  );
};

export default DividedTable;
