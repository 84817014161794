import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import 'swiper/css';
import './styles.scss';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { PostImagePreview, PostVideoPreview } from 'components';
import { useWindowSize } from 'hooks';

import { breakPoints } from '../../../constants';

import { TPostsSliderProps } from './types';

const PostsSlider: FC<TPostsSliderProps> = ({ media }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.realIndex);
  };

  const mediaElements = media?.map((element, idx) => {
    if (element?.file_type?.startsWith('image')) {
      return (
        <SwiperSlide key={element?.id}>
          <PostImagePreview imgSrc={element?.media_url_id} media={element} />
        </SwiperSlide>
      );
    } else {
      return (
        <SwiperSlide key={element?.id}>
          <PostVideoPreview
            key={element?.id}
            imgSrc={element?.media_url_id}
            isActive={activeIndex === idx}
          />
        </SwiperSlide>
      );
    }
  });

  const { width } = useWindowSize();

  const sliderHeight = Number(width) <= breakPoints.TABLET_M ? '250px' : '313px';

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">' + '</span>';
    },
  };

  return (
    <Swiper
      navigation={true}
      keyboard={true}
      mousewheel={false}
      pagination={pagination}
      style={{ height: sliderHeight }}
      scrollbar={{ draggable: false }}
      modules={[Navigation, Pagination, Mousewheel, Keyboard]}
      className='mySwiper'
      onSlideChange={handleSlideChange}
    >
      {mediaElements}
    </Swiper>
  );
};

export default PostsSlider;
