import { BaseSyntheticEvent, FC, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import classNames from 'classnames';

import {
  findInventories,
  modifyRequisitionItem,
  addItemsToRequisition,
  updateRequisition,
  getRequisitionItemsByUuid,
  deletePurchaseRequisitionItem,
} from 'store/thunks';
import { useAppDispatch, useAppSelector, useInput, useOnClickOutside } from 'hooks';
import { TRequisitionItem } from 'store/slices/requisitionSlices/types';
import {
  requisitionItemsSelector,
  requisitionItemsLoadingSelector,
} from 'store/slices/requisitionSlices/selectors';
import { EditIconOutLined, MoreIcon, PlusFilledIcon, TrashIcon } from 'assets/icons';
import {
  TableCell,
  ScrollLayout,
  ImgWithDefault,
  CustomDataGrid,
  CustomTextField,
  CustomTypography,
  ControlledAutocomplete,
} from 'components';
import { ImageBlank } from 'assets/images';
import { Colors, ImgUrlFolders, Routes } from 'types';
import PreloadedImg from 'components/views/PreloadedImg';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import { foundedInventoriesSelector } from 'store/slices/searchSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';

import { sxMenuStyles } from '../styles';

import { columns } from './utils';
import RequisitionItemHeader from './RequisitionItemHeader';
import styles from './PurchaseRequisitionItem.module.scss';

const PurchaseRequisitionItem: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [status, setStatus] = useState<string>('');
  const [qtyValue, setQtyValue] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isQtyValueChanged, setIsQtyValueChanged] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null | string>(null);
  const [newSortBy, setNewSortBy] = useState<string | null>(null);
  const [asc, setAsc] = useState<boolean>(false);

  const userRoles = useAppSelector(userAllRolesSelector);
  const isLoad = useAppSelector(requisitionItemsLoadingSelector);
  const currentRequisition = useAppSelector(requisitionItemsSelector);
  const foundedParts = useAppSelector(foundedInventoriesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  const {
    value: nameValue,
    debouncedValue: nameDebouncedValue,
    handleChange: nameHandleChange,
  } = useInput();

  useEffect(() => {
    if (nameDebouncedValue?.length > 0) {
      dispatch(findInventories({ query: nameDebouncedValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameDebouncedValue]);

  const { control, watch, handleSubmit } = useForm({
    values: {
      items: currentRequisition?.items,
      description: currentRequisition?.description,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const handleMoreClick = (event: BaseSyntheticEvent, id: number | string | null) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setSelectedItemId(id);
  };

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    dispatch(getRequisitionItemsByUuid(id as string));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = foundedParts?.data?.map((item) => ({
    name: item?.name,
    part_id: item.id,
    cost: item?.cost || 0,
    quantity: item?.qty || 0,
    brand: item?.brand || '',
    description: item?.description,
    image_url_id: item?.image_url_id || '',
    manufacturer: item?.manufacturer || '',
  }));

  const onItemDelete = () => {
    if (!isNaN(selectedItemId as number)) {
      currentRequisition?.id &&
        dispatch(
          deletePurchaseRequisitionItem({
            id: Number(selectedItemId),
            requisitionId: currentRequisition?.id,
          }),
        );
    } else {
      remove(selectedItemId as number);
    }
  };

  const changeQtyValue = (event: BaseSyntheticEvent) => {
    setQtyValue(event.target.value);
    setIsQtyValueChanged(true);
  };

  const toggleEdit = () => setIsEditing(!isEditing);

  const dropdownItems = [
    {
      icon: <EditIconOutLined />,
      label: 'Edit',
      paramsAction: toggleEdit,
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: onItemDelete,
    },
  ];

  const clearEditingValue = () => {
    toggleEdit(), setQtyValue('');
    setIsQtyValueChanged(false);
  };

  useOnClickOutside(inputRef, clearEditingValue);

  const renderMenuItem = dropdownItems.map((item, idx) => {
    const onClick = () => {
      selectedItemId && item.paramsAction();
      handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted = column.field ? newSortBy === column.field : 'brand';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      sortable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const addItems = async (data: any) => {
    const filteredItems = data.items.filter((el: TRequisitionItem) => isNaN(el.id));

    const items = filteredItems?.map((item: any) => {
      return {
        part_id: item?.part_id,
        quantity: Number(item?.quantity) || 0,
        requisition_id: currentRequisition?.id as number,
      };
    });

    const response = await dispatch(addItemsToRequisition({ items })).unwrap();

    const renderStatus =
      status === 'Approval' ? 'approved' : status === 'Submit' ? 'pending' : 'declined';

    if (response) {
      dispatch(
        updateRequisition({
          status: renderStatus,
          id: currentRequisition?.id as number,
          description: data.description,
        }),
      );
      navigate(Routes.Requisition);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    quantityValue: string | number,
  ) => {
    if (event.key === 'Enter') {
      if (quantityValue !== qtyValue) {
        const selectedPartId = currentRequisition?.items?.find((el) => el.id === selectedItemId);

        dispatch(
          modifyRequisitionItem({
            id: currentRequisition?.id as number,
            body: {
              part_id: selectedPartId?.part_id as number,
              id: selectedItemId as number,
              quantity: Number(qtyValue) || 0,
            },
          }),
        );
        clearEditingValue();
      } else {
        clearEditingValue();
      }
    }
  };

  const renderDescriptionDisabled =
    currentRequisition?.status === 'approved' ||
    currentRequisition?.status === 'declined' ||
    isViewer;

  const watchResult = watch();

  const isNameFieldEmpty = watchResult?.items?.some((item) => !item.name);
  const isItemsExist = fields.length;

  const handleAppend = () => {
    append({
      id: 0,
      brand: '',
      cost: 0,
      created_at: '',
      created_by: 0,
      description: '',
      image_url_id: '',
      name: '',
      part_id: null,
      manufacturer: '',
      quantity: 0,
      status: '',
      updated_at: '',
    });
  };

  const renderRows =
    currentRequisition?.items &&
    fields?.map((el, idx) => ({
      id: watchResult?.items?.[idx]?.id || el.id,
      part_id: watchResult?.items?.[idx]?.part_id,
      image:
        el.image || watchResult?.items?.[idx]?.image_url_id ? (
          <PreloadedImg
            loading={false}
            folderName={ImgUrlFolders.INVENTORY}
            style={{ maxHeight: '100%', overflow: 'hidden' }}
            imgId={(el.image as string) || (watchResult?.items?.[idx]?.image_url_id as string)}
          />
        ) : (
          <ImgWithDefault
            src={ImageBlank}
            fallback={ImageBlank}
            style={{ maxHeight: '100%', overflow: 'hidden' }}
          />
        ),
      name: el.name || (
        <ControlledAutocomplete
          width='100%'
          padding='3px'
          fromRequisition
          options={options}
          control={control}
          borderRadius='8px'
          optionsName='name'
          borderColor={Colors.SOFT_SILVER}
          inputValue={nameValue}
          name={`items[${idx}]`}
          placeholder='Item Name'
          fontFamily='CircularStdRegular'
          handleInputChange={nameHandleChange}
          backgroundColor={Colors.LIGHT_SILVER}
        />
      ),
      brand: watchResult?.items?.[idx]?.brand && (
        <TableCell title={String(watchResult?.items?.[idx]?.brand) ?? ''} />
      ),
      cost: watchResult?.items?.[idx]?.cost && (
        <TableCell title={String(watchResult?.items?.[idx]?.cost ?? '')} />
      ),
      manufacturer: watchResult?.items?.[idx]?.manufacturer && (
        <TableCell title={String(watchResult?.items?.[idx]?.manufacturer) ?? ''} />
      ),
      quantity:
        (el.id === selectedItemId || watchResult?.items?.[idx]?.id === selectedItemId) &&
        isEditing ? (
          <Box className={styles.container__quantity}>
            <CustomTypography>Quantity</CustomTypography>
            <Box ref={inputRef}>
              <CustomTextField
                placeholder=''
                type='number'
                value={isQtyValueChanged ? String(qtyValue) : String(el.quantity)}
                onChange={changeQtyValue}
                onKeyDown={(event) => handleKeyDown(event, el.quantity)}
              />
            </Box>
          </Box>
        ) : (
          el.quantity || (
            <Box className={styles.container__quantity}>
              <CustomTypography>Quantity</CustomTypography>
              <Controller
                control={control}
                name={`items.${idx}.quantity`}
                render={({ field: { onChange } }) => (
                  <CustomTextField
                    type='number'
                    padding='5px'
                    fromRequisition
                    borderRadius='5px'
                    borderColor={Colors.SOFT_SILVER}
                    placeholder='Quantity'
                    backGroundColor={Colors.FROST_WHITE}
                    value={String(watchResult?.items?.[idx]?.quantity) || ''}
                    onChange={(event) => {
                      const inputValue = event.target.value;

                      if (inputValue?.startsWith('0') && inputValue?.length > 1) {
                        event.preventDefault();
                      } else {
                        onChange(event);
                      }
                    }}
                  />
                )}
              />
            </Box>
          )
        ),
      options:
        currentRequisition.status === 'draft' && !isViewer ? (
          <IconButton
            onClick={(event) =>
              handleMoreClick(event, watchResult?.items?.[idx]?.id || (el.id as number))
            }
            className={styles.container__icon}
          >
            <MoreIcon />
          </IconButton>
        ) : (
          <></>
        ),
    }));

  const handleSubmitWithClose = () => {
    handleSubmit(addItems)();
  };

  return (
    <>
      {!isLoad && currentRequisition?.items ? (
        <Box className={styles.container}>
          <RequisitionItemHeader
            count={fields.length}
            setStatus={setStatus}
            disabled={!!isNameFieldEmpty || !isItemsExist}
            onSubmit={handleSubmitWithClose}
            currentRequisition={currentRequisition as TRequisitionItem}
          />
          <form onSubmit={handleSubmit(addItems)}>
            <ScrollLayout>
              <CustomDataGrid
                headerHeight={52}
                rowHeight={77}
                rows={renderRows || []}
                columns={renderColumns}
                onColumnHeaderClick={handleColumnHeaderClick}
                emptyTitle={EmptyTitles.Purchase_Requisition_Items}
                sortModel={[{ field: newSortBy || 'cost', sort: asc ? 'asc' : 'desc' }]}
              />
            </ScrollLayout>
            {currentRequisition?.status === 'draft' && !isViewer && (
              <Box className={styles.container__add} onClick={handleAppend}>
                <PlusFilledIcon width={24} height={24} />
                <CustomTypography className={styles.container__add__text}>
                  Add An Item For Requisition
                </CustomTypography>
              </Box>
            )}
            <Controller
              name='description'
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomTextField
                  maxWidth={459}
                  borderRadius='5px'
                  onChange={onChange}
                  borderColor={Colors.SOFT_SILVER}
                  value={String(value)}
                  backGroundColor={Colors.FROST_WHITE}
                  label='Requisition Notes:(Optional)'
                  disabled={renderDescriptionDisabled}
                  placeholder='Requisition Notes:(Optional)'
                />
              )}
            />

            <Menu
              ref={menuRef}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={sxMenuStyles}
            >
              {renderMenuItem}
            </Menu>
          </form>
        </Box>
      ) : (
        <Box className={styles.loader}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default PurchaseRequisitionItem;
