import { useNavigate } from 'react-router-dom';

import { CustomTypography } from 'components';
import { useBackgroundPosition } from 'hooks';

import styles from './Error.module.scss';

const Error = () => {
  const { ref, handleMouseOver } = useBackgroundPosition();
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__container} ref={ref} onMouseMove={handleMouseOver}>
        <div className={styles.wrapper__container__content}>
          <h2>404</h2>
          <h4>Oops! Page not found</h4>
          <CustomTypography className={styles.wrapper__container__content__description}>
            {`Sorry, but the URL you entered doesn't lead to a valid page. Please return to the
          homepage and make sure you've entered the correct URL.`}
          </CustomTypography>
          <p
            role='button'
            className={styles.wrapper__container__content__link}
            onClick={() => navigate(-1)}
          >
            Back
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error;
