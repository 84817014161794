import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'hooks';
import Live from 'containers/LiveStream/Live';
import { getLiveStreamsById, getStreamWithUUID } from 'store/slices/liveStreamSlice/thunks';
import { NotesDrawer, CreateLiveStreamDrawer } from 'components';

import styles from './LiveStreamPage.module.scss';

const LiveStream: React.FC = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [isError, setIsError] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const onClose = () => {
    setOpenDrawer(false);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const openNoticeModal = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    const getLiveStream = async () => {
      try {
        const firstResponse = await dispatch(getStreamWithUUID(id as string)).unwrap();

        const response = await dispatch(getLiveStreamsById(Number(firstResponse?.id)));

        if (response.meta.requestStatus !== 'fulfilled' || !firstResponse) {
          setIsError(true);
        } else {
          setIsError(false);
        }
      } catch (error) {
        setIsError(true);
      }
    };

    getLiveStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className={styles.container}>
        <Live error={isError} contentRef={contentRef} openNoticeModal={openNoticeModal} />
      </div>
      <CreateLiveStreamDrawer open={openDrawer} handleClose={onClose} />
      <NotesDrawer contentRef={contentRef} open={openModal} onClose={onCloseModal} />
    </>
  );
};

export default LiveStream;
