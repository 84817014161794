import { ChangeEvent, FC } from 'react';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { Colors, TFormSubmit } from 'types';
import { editUserInfoFormGenerator } from 'utils';
import { useAppDispatch, useAppSelector, useUserRolesById } from 'hooks';
import { TUserProfile } from 'store/slices/authSlice/types';
import { Button, CustomTextField, Select } from 'components';
import { allRolesSelector } from 'store/slices/rolesSlice/selectors';
import { updateOrganizationMember } from 'store/slices/organizationsSlice/thunks';

import styles from './EditUserInfoModal.module.scss';

import type { TEditUserInfoModalProps } from './types';

const EditUserInfoModal: FC<TEditUserInfoModalProps> = ({ user, onClose }) => {
  const dispatch = useAppDispatch();

  const allRoles = useAppSelector(allRolesSelector);

  const handlePropagation = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.stopPropagation();
  };

  const {
    control,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
  } = useForm({
    values: editUserInfoFormGenerator(user as TUserProfile),
  });

  const foundedRole = (name: string) =>
    allRoles.find((role) => {
      if (role.value === name) {
        return role;
      }
    });

  const { getUserRoleById } = useUserRolesById({ user });

  const handleEditUser: TFormSubmit = async (data) => {
    if (foundedRole && data) {
      const id = foundedRole(data?.role)?.id;

      if (id) {
        await getUserRoleById(id, data?.email);
      }

      const roleValue = foundedRole(data?.role)?.value;

      if (isDirty && roleValue) {
        const response = await dispatch(
          updateOrganizationMember({
            id: user.id,
            name: data.fullName,
            email: user.email,
            designation: roleValue,
          }),
        ).unwrap();
        response && onClose();
      }
    }
  };

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit(handleEditUser)}
      onMouseDown={handlePropagation}
    >
      <Box className={styles.container__footer}>
        <Controller
          name='fullName'
          control={control}
          defaultValue=''
          rules={{ required: 'Full Name is required' }}
          render={({ field }) => (
            <CustomTextField
              required
              {...field}
              label='Full Name:'
              borderRadius='10px'
              placeholder='Full Name:'
              backGroundColor={Colors.PLATINUM_GRAY}
              error={!!errors.fullName}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          defaultValue=''
          rules={{ required: 'Email is required' }}
          render={({ field }) => (
            <CustomTextField
              required
              {...field}
              readonly
              label='Email'
              borderRadius='10px'
              placeholder='Email:'
              error={!!errors.email}
              backGroundColor={Colors.PLATINUM_GRAY}
            />
          )}
        />

        <Controller
          name='role'
          control={control}
          defaultValue=''
          rules={{ required: 'Role is required' }}
          render={({ field: { onChange, value } }) => (
            <Select
              label='Role'
              multiple={false}
              options={allRoles}
              borderRadius='10px'
              defaultValue='Role'
              value={value || ''}
              error={!!errors.role}
              backgroundColor={Colors.PLATINUM_GRAY}
              onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                onChange(event.target.value);
              }}
            />
          )}
        />
      </Box>
      <Box className={styles.container__submit}>
        <Button
          type='button'
          color={Colors.SAPPHIRE}
          minWidth='78px'
          onClick={onClose}
          backgroundColor={Colors.PASTEL_SKY}
          className={styles.container__submit__cancel}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          minWidth='65px'
          color={Colors.WHITE}
          backgroundColor={Colors.SAPPHIRE}
          disabled={!isDirty || isSubmitting}
          className={styles.container__submit__button}
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

export default EditUserInfoModal;
