import { FC } from 'react';
import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import breakPoints from 'constants/BreakPoints';
import { vendorCreateSchema } from 'constants/Schemas';
import { vendorCreateFormValuesGenerator } from 'utils';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { createVendor, updateVendor } from 'store/slices/inventorySlice/thunks';
import { currentVendorByIdSelector } from 'store/slices/inventorySlice/selectors';
import {
  Button,
  ControlledInput,
  ControlledSelect,
  DrawerLayout,
  NumberControlledInput,
} from 'components';
import { Colors, FontNames } from 'types';
import { stateOptions } from 'constants/States';

import styles from './AddVendorDrawer.module.scss';
import { inputConfigurations } from './AddVendor.utils';

import type { TAddVendorDrawerProps, TVendorFormValues } from './types';
import type { TVendor, TVendorUpdate } from 'store/slices/inventorySlice/types';

const AddVendorDrawer: FC<TAddVendorDrawerProps> = ({ isOpen, setIsOpen, inEditMode = false }) => {
  const dispatch = useAppDispatch();

  const { width } = useWindowSize();

  const currentVendor = useAppSelector(currentVendorByIdSelector);

  const {
    reset,
    watch,

    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<TVendorFormValues>({
    resolver: yupResolver(vendorCreateSchema),
    values: vendorCreateFormValuesGenerator({ inEditMode, currentVendor }),
  });

  const watchResult = watch();

  const { name, contact_person, phone, email } = watchResult;

  const isFormValid = name?.length && contact_person?.length && phone?.length && email?.length;

  const drawerTitle = inEditMode ? 'Edit Vendor' : ' Add Vendor';

  const handleClose = () => setIsOpen(false);

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    if (!inEditMode) {
      const response = await dispatch(createVendor(data as TVendor));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    } else {
      const sendedParams = {
        ...data,
        id: currentVendor?.id as number,
        vendor_link: data.vendor_link.trim(),
      };

      const response = await dispatch(updateVendor(sendedParams as TVendorUpdate));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    }
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset();
    handleClose();
  };

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 475
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 623;

  const inputFields = inputConfigurations?.map(
    ({ type, id, fontFamily, label, name, placeholder, borderRadius, required }) => {
      if (type === 'number') {
        return (
          <div key={id} className={styles.container__content__item}>
            <NumberControlledInput
              name={name}
              label={label}
              control={control}
              required={required}
              borderColor={Colors.SOFT_SILVER}
              fontFamily={fontFamily}
              placeholder={placeholder}
              borderRadius={borderRadius}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              error={errors[name as keyof TVendorFormValues]}
              helperText={errors[name as keyof TVendorFormValues]?.message}
            />
          </div>
        );
      } else if (type === 'select') {
        return (
          <div key={id} className={styles.container__content__item}>
            <ControlledSelect
              name={name}
              label={label}
              defaultValue=''
              control={control}
              required={required}
              options={stateOptions}
              fontFamily={fontFamily}
              border='1px solid #EDEFF1'
              borderRadius={borderRadius}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              errors={!!errors[name as keyof TVendorFormValues]}
            />
          </div>
        );
      } else {
        return (
          <div key={id} className={styles.container__content__item}>
            <ControlledInput
              name={name}
              label={label}
              control={control}
              required={required}
              borderColor={Colors.SOFT_SILVER}
              fontFamily={fontFamily}
              placeholder={placeholder}
              borderRadius={borderRadius}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              error={errors[name as keyof TVendorFormValues]}
              helperText={errors[name as keyof TVendorFormValues]?.message}
            />
          </div>
        );
      }
    },
  );

  return (
    <DrawerLayout
      open={isOpen}
      inCenter={false}
      width={drawerWidth}
      onClose={onDrawerClose}
      headerTitle={drawerTitle}
      onCloseReset={onDrawerClearClose}
      titleClassName={styles.container__title}
    >
      <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Box className={styles.container__content}>{inputFields}</Box>
        </Box>
        <Box className={styles.container__footer}>
          <Button
            type='button'
            maxWidth='68px'
            padding='11px 8px'
            minWidth='68px'
            borderRadius='5px'
            onClick={onDrawerClose}
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
            fontFamily={FontNames.CIRCULAR_BOLD}
            className={styles.container__content__submit}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='white'
            maxWidth='68px'
            minWidth='68px'
            padding='11px 8px'
            borderRadius='5px'
            backgroundColor={Colors.SAPPHIRE}
            fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            className={styles.container__content__submit}
            disabled={!isFormValid || isSubmitting || !isDirty}
          >
            {inEditMode ? 'Update' : 'Add'}
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default AddVendorDrawer;
