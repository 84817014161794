import { EmployeeTable } from 'components/tables';

import styles from './Employees.module.scss';

const Employees = () => (
  <div className={styles.container}>
    <EmployeeTable />
  </div>
);

export default Employees;
