import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { catalogApi } from 'api';
import { TShareCatalogDataReturnedType } from 'store/slices/catalogSlice/types';

const useUserCatalogAccess = (id: number) => {
  const [data, setData] = useState<TShareCatalogDataReturnedType | null>(null);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const getUserAccessToCatalogs = async () => {
    try {
      setIsLoad(true);
      const response = await catalogApi.getShareDCatalogDataByCatalogIdRequest(id);

      if (response?.data) {
        setData(response?.data);
      }
      setIsLoad(false);
    } catch (error) {
      const Error = error as AxiosError;

      setError(Error?.message);
    }
  };

  useEffect(() => {
    if (id) {
      getUserAccessToCatalogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    data,
    isLoad,
    error,
    getUserAccessToCatalogs,
  };
};

export default useUserCatalogAccess;
