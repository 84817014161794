import { FC, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';

import { CustomDataGrid, CustomTypography, ScrollLayout } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import {
  getPurchaseOrderItems,
  updatePurchaseOrderItem,
  getPurchaseOrderDataByUuid,
} from 'store/slices/purchaseOrderSlice/thunks';
import { deepEqual } from 'utils/deepEqual';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import { purchaseOrderDataByIdSelector } from 'store/slices/purchaseOrderSlice/selectors';

import styles from '../PurchaseOrderItems.module.scss';

import { columns } from './utils';
import { TPurchaseOrderItemsTableEditProps, TRow } from './types';

const PurchaseOrderItemsTableEdit: FC<TPurchaseOrderItemsTableEditProps> = ({ data, itemUuid }) => {
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string>('');

  const purchaseOrderData = useAppSelector(purchaseOrderDataByIdSelector);
  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const renderRows = data?.map((row) => {
    const renderNumber = generateInventoryItemNumber(Number(row?.part_id));

    return {
      id: row?.id,
      itemNumber: renderNumber,
      quantity: row?.quantity || 0,
      unit_cost: row?.unit_price || 0,
      amount: row.quantity * row.unit_price,
      description: row?.description || '--',
    };
  }) as TRow[];

  const processRowUpdate = async (newRow: TRow, oldRow: TRow) => {
    if (!deepEqual(newRow, oldRow)) {
      if (newRow.quantity >= 1000000) {
        setError('Quantity should be less than 1000000');
        return oldRow;
      } else {
        setError('');
      }
      const updatedRow = {
        ...newRow,
        isNew: false,
      };

      const updatedData = {
        id: updatedRow.id,
        quantity: updatedRow.quantity || 0,
        unit_price: updatedRow.unit_cost || 0,
        description: updatedRow.description,
      };

      purchaseOrderData?.id &&
        (await dispatch(
          updatePurchaseOrderItem({ options: updatedData, id: purchaseOrderData?.id }),
        ));
      dispatch(getPurchaseOrderItems(Number(purchaseOrderData?.id)));
      dispatch(getPurchaseOrderDataByUuid(itemUuid));

      return updatedRow;
    } else {
      return newRow;
    }
  };

  const renderColumns: GridColDef[] = columns.map((column) => {
    const renderEditable =
      purchaseOrderData?.status !== 'recieved' &&
      !isViewer &&
      (column.field === 'description' ||
        column.field === 'quantity' ||
        column.field === 'unit_cost');

    return {
      ...column,
      hideSortIcons: true,
      disableColumnMenu: true,
      editable: renderEditable,
      disableColumnSelector: true,
    };
  });

  return (
    <ScrollLayout>
      <CustomDataGrid
        rowHeight={80}
        editMode='cell'
        columns={renderColumns}
        rows={renderRows || []}
        processRowUpdate={processRowUpdate}
      />
      <CustomTypography className={styles.error}>{error}</CustomTypography>
    </ScrollLayout>
  );
};

export default PurchaseOrderItemsTableEdit;
