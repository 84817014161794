import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { getRequisitionItemsByUuid, getRequisitions } from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  allRequisitionsLimit: 5,
  allRequisitionsAsc: true,
  allRequisitionsOffset: 0,
  allRequisitionsError: null,
  allRequisitionsLoading: false,
  allRequisitions: dataStructure,
  requisitionItemsLimit: 10,
  requisitionItemsOffset: 0,
  requisitionItemsError: null,
  requisitionItemsLoading: false,
  requisitionItems: {},
};

const requisitionSlice = createSlice({
  name: 'requisitionSlice',
  reducers: {
    setPage(state, action) {
      state.allRequisitionsOffset = (action.payload - 1) * state.allRequisitionsLimit;
    },
    setLimit(state, action) {
      state.allRequisitionsLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getRequisitions.pending, (state) => {
        state.allRequisitionsLoading = true;
        state.allRequisitionsError = null;
      })
      .addCase(getRequisitions.fulfilled, (state, action) => {
        state.allRequisitionsLoading = false;
        state.allRequisitions = action.payload;
        state.allRequisitionsAsc = !state.allRequisitionsAsc;
      })
      .addCase(getRequisitions.rejected, (state, action) => {
        state.allRequisitionsLoading = false;
        state.allRequisitionsError = action.error.message as string;
      })
      .addCase(getRequisitionItemsByUuid.pending, (state) => {
        state.requisitionItemsLoading = true;
        state.requisitionItemsError = null;
      })
      .addCase(getRequisitionItemsByUuid.fulfilled, (state, action) => {
        state.requisitionItemsLoading = false;
        state.requisitionItems = action.payload;
      })
      .addCase(getRequisitionItemsByUuid.rejected, (state, action) => {
        state.requisitionItemsLoading = false;
        state.requisitionItemsError = action.error.message as string;
      });
  },
});

export default requisitionSlice.reducer;

export const { setPage, setLimit } = requisitionSlice.actions;
