import { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { GridColDef } from '@mui/x-data-grid';

import {
  deleteInvitedUser,
  getInvitationByOrgId,
  getInvitedUsers,
  resendInvitationToUser,
  updateInvitationStatus,
} from 'store/thunks';
import { MoreIcon, NewPlusIcon } from 'assets/icons';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { useAppDispatch, useAppSelector, useOrganizationNameById } from 'hooks';
import {
  Button,
  UserCard,
  DividedTable,
  ShadowLayout,
  ScrollLayout,
  EmployeeStatus,
  PortalDropDown,
  InviteUsersDrawer,
  DynamicPaginationControl,
} from 'components';
import { StatusEnums } from 'components/shared/EmployeeStatus/types';
import {
  pendingUsersDataSelector,
  pendingUsersLimitSelector,
  pendingUsersLoadSelector,
  pendingUsersOffsetSelector,
} from 'store/slices/organizationsSlice/selectors';
import { setInvitedUsersLimit, setInvitedUsersPage } from 'store/slices/organizationsSlice';
import { EmptyTitles } from 'constants/EmptyTitles';
import { PermissionMessages } from 'constants/PermissionMessages';

import { columns, dropdownOptionsPending, dropdownOptionsRevoke } from './Invitations.utils';
import styles from './Invitations.module.scss';

const Invitations = () => {
  const dispatch = useAppDispatch();

  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const invitedUsersLoading = useAppSelector(pendingUsersLoadSelector);

  const { isSendInvite } = useAppSelector(userPermissionsSelector);
  const invitedUsersData = useAppSelector(pendingUsersDataSelector);
  const invitedUsersLimit = useAppSelector(pendingUsersLimitSelector);
  const invitedUsersOffset = useAppSelector(pendingUsersOffsetSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>(null);

  const { identifyName } = useOrganizationNameById();

  const closeDrawer = () => setIsOpen(false);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setInvitedUsersPage(newPage + 1));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(setInvitedUsersLimit(event.target.value));
  };

  const rows = invitedUsersData?.data?.map((item) => {
    const statusValues = StatusEnums[item?.status.toUpperCase() as keyof typeof StatusEnums];

    const revokeAction = async () => {
      const response = await dispatch(
        updateInvitationStatus({ id: selectedId as number, status: 'revoked' }),
      ).unwrap();

      response &&
        currentOrganization &&
        dispatch(getInvitationByOrgId({ org_id: Number(currentOrganization) }));
    };

    const deleteAction = async () => {
      const response = await dispatch(deleteInvitedUser(selectedId as number)).unwrap();

      response &&
        currentOrganization &&
        dispatch(getInvitationByOrgId({ org_id: Number(currentOrganization) }));
    };

    const resendInvitation = () => {
      selectedId && dispatch(resendInvitationToUser(selectedId));
    };

    const options =
      item?.status === 'pending'
        ? dropdownOptionsPending(revokeAction, deleteAction)
        : dropdownOptionsRevoke(resendInvitation, deleteAction);

    return {
      id: item?.id,
      email: item?.email || '--',
      name: <UserCard width={24} height={24} userName={item?.full_name} userImg={''} />,
      role: identifyName(item?.role_id) || '--',
      options: (
        <PortalDropDown
          title={<MoreIcon className={styles.container__icon} />}
          options={options}
          selectedId={item.id}
          getSelectedId={setSelectedId}
        />
      ),
      status: <EmployeeStatus title={item?.status} status={statusValues} />,
    };
  });

  const columnsRender: GridColDef[] = columns.map((column) => {
    const isColumnSorted = newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      sortable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  useEffect(() => {
    if (!invitedUsersData.total_count && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedUsersData.total_count]);

  useEffect(() => {
    dispatch(
      getInvitedUsers({
        limit: invitedUsersLimit,
        offset: invitedUsersOffset,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedUsersLimit, invitedUsersOffset]);

  const renderClasses = classNames(styles.container__top, {
    [styles.container__top__between]: invitedUsersData.total_count > 0,
  });

  return (
    <>
      <div className={styles.container}>
        <Box className={renderClasses}>
          <Button
            isUppercase={false}
            disabled={!isSendInvite}
            startIcon={<NewPlusIcon />}
            tooltipMessage={!isSendInvite ? PermissionMessages.InviteUsersMessage : undefined}
            onClick={() => setIsOpen(true)}
            minWidth='124px'
            maxWidth='124px'
            className={styles.container__top__button}
          >
            Invite Users
          </Button>
        </Box>
        <ShadowLayout>
          <ScrollLayout>
            <DividedTable
              layoutHeight={544}
              isLoading={invitedUsersLoading}
              columns={columnsRender}
              rows={rows || []}
              emptyTitle={EmptyTitles.Invitations}
              onColumnHeaderClick={handleColumnHeaderClick}
              sortModel={[{ field: newSortBy || 'name', sort: asc ? 'asc' : 'desc' }]}
            />
          </ScrollLayout>
        </ShadowLayout>
        {!!invitedUsersData.total_count && (
          <Box padding='20px 0'>
            <DynamicPaginationControl
              page={currentPage}
              rowsPerPage={invitedUsersLimit}
              onPageChange={handlePageChange}
              labelRowsPerPage='Rows per page:'
              count={invitedUsersData.total_count}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </div>
      <InviteUsersDrawer open={isOpen} handleClose={closeDrawer} />
    </>
  );
};

export default Invitations;
