import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';

import { CustomDataGrid, DynamicPaginationControl, ScrollLayout, StatusInfo } from 'components';
import { Colors, Routes } from 'types';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import { convertToMMDDYYYY } from 'utils/formatDate';
import { EmptyTitles } from 'constants/EmptyTitles';
import { useAppDispatch, useAppSelector, useRefreshClearState } from 'hooks';
import { SortByEnums } from 'store/slices/transactionSlice/types';
import {
  allTransactionLoadingSelector,
  allTransactionsList,
} from 'store/slices/transactionSlice/selectors';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { getTransactions } from 'store/slices/transactionSlice/thunks';

import { transactionColumns } from './utils';
import styles from './Transactions.module.scss';
import TransactionHeader from './TransactionHeader';

const Transactions: FC = () => {
  const dispatch = useAppDispatch();
  const { isAccessToTransactionHistory } = useAppSelector(userPermissionsSelector);
  const userRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  useRefreshClearState();

  const transactions = useAppSelector(allTransactionsList); //to retreive list of all transactions
  const transactionLoading = useAppSelector(allTransactionLoadingSelector);

  const totalRows = transactions.length;
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);
  const [currentSortOrder, setCurrentSortOrder] = useState('asc');
  const [data, setData] = useState<[string, any][]>([]);
  const [transactionsAsc, setTransactionAsc] = useState(true);

  useEffect(() => {
    dispatch(getTransactions());
    setData(transactions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  }, []);

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
  };

  const renderRows = data?.map((el: any) => {
    return {
      id: el.id,
      customer_name: el.customer_name,
      amount: el.amount / 100,
      created_at: convertToMMDDYYYY(el.created_at),
      source_type: el.source_type,
      status: (
        <StatusInfo
          color={Colors.MIDNIGHT_BLACKISH}
          title={el?.status as string}
          status={el?.status as StatusEnums}
        />
      ),
    };
  });

  const handleColumnHeaderClick = (column: GridColDef) => {
    const sortValue = column.field === 'number' ? SortByEnums.ID : (column.field as SortByEnums);

    const sortedData = [...data];

    let newSortOrder: 'asc' | 'desc';
    if (newSortBy === sortValue && currentSortOrder === 'asc') {
      newSortOrder = 'desc';
      setTransactionAsc(false);
    } else {
      newSortOrder = 'asc';
      setTransactionAsc(true);
    }
    if (sortValue == 'id') {
      sortedData.sort((a: any, b: any) => compareStrings(a.id, b.id, newSortOrder));
    } else if (sortValue == 'customer_name') {
      sortedData.sort((a: any, b: any) =>
        compareStrings(a.costumer_name, b.costumer_name, newSortOrder),
      );
    } else if (sortValue == 'amount') {
      sortedData.sort((a: any, b: any) => compareNumbers(a.amount, b.amount, newSortOrder));
    } else if (sortValue === 'created_at') {
      sortedData.sort((a: any, b: any) => compareNumbers(a.created, b.created, newSortOrder));
    } else if (sortValue === 'source_type') {
      sortedData.sort((a: any, b: any) =>
        compareStrings(a.source_type, b.source_type, newSortOrder),
      );
    }
    setData(sortedData);
    setNewSortBy(sortValue);
    setCurrentSortOrder(newSortOrder);
  };

  const compareStrings = (a: string, b: string, sortOrder: 'asc' | 'desc') => {
    if (sortOrder === 'asc') {
      return a?.localeCompare(b);
    } else {
      return b?.localeCompare(a);
    }
  };

  const compareNumbers = (a: number, b: number, sortOrder: 'asc' | 'desc') => {
    if (sortOrder === 'asc') {
      return a - b;
    } else {
      return b - a;
    }
  };

  const renderColumns: GridColDef[] = transactionColumns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === SortByEnums.ID : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !transactionsAsc },
      {
        [styles.activeSortHeader_asc]: transactionsAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  if (!isAccessToTransactionHistory && !userRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  const startSlice = currentPage * rowsPerPage;
  const endSlice = startSlice + rowsPerPage;
  const paginatedRows = renderRows?.slice(startSlice, endSlice);

  return (
    <Box>
      <TransactionHeader></TransactionHeader>
      <Box className={styles.container__selection}></Box>
      <>
        <ScrollLayout>
          <CustomDataGrid
            rowHeight={44}
            headerHeight={44}
            rows={paginatedRows}
            rowSelection={false}
            columns={renderColumns}
            isLoading={transactionLoading}
            emptyTitle={EmptyTitles.Transactions}
            // onRowClick={(params) => handleClick(params?.row?.uuid)}  to add expand row func in future
            onColumnHeaderClick={handleColumnHeaderClick}
          />
        </ScrollLayout>
        {!!data?.length && (
          <DynamicPaginationControl
            count={totalRows}
            page={currentPage}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </>
    </Box>
  );
};

export default Transactions;
// function dispatch(arg0: any) {
//   throw new Error('Function not implemented.');
// }
