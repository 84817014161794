import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TAgreementCreateBody,
  TAgreementParams,
  TCreateJobTypeBody,
  TJobTypesParams,
  TUpdateAgreementBody,
  TUpdateJobTypeBody,
} from 'store/slices/agreementSlice/types';

export const getAllAgreementsRequest = (options: TAgreementParams) =>
  client.get(endpoints.AgreementServices.getAllAgreements(), { params: { ...options } });

export const createAgreementRequest = (new_agreement: TAgreementCreateBody) =>
  client.post(endpoints.AgreementServices.createAgreement(), { new_agreement: new_agreement });

export const deleteAgreementByIdRequest = (id: number) =>
  client.delete(endpoints.AgreementServices.deleteAgreement(id));

export const getAgreementByIdRequest = (id: number) =>
  client.get(endpoints.AgreementServices.getAgreementById(id));

export const updateAgreementRequest = (options: TUpdateAgreementBody) => {
  return client.put(endpoints.AgreementServices.updateAgreement(), { ...options });
};

export const getJobTypesRequest = (options: TJobTypesParams) =>
  client.get(endpoints.AgreementServices.getJobTypes(), { params: { ...options } });

export const createJobTypeRequest = (job_type: TCreateJobTypeBody) =>
  client.post(endpoints.AgreementServices.createJobType(), { job_type: job_type });

export const getJobTypeByIdRequest = (id: number) =>
  client.get(endpoints.AgreementServices.getJobTypeById(id));

export const deleteJobTypeByIdRequest = (id: number) =>
  client.delete(endpoints.AgreementServices.deleteJobType(id));

export const updateJobTypeRequest = (job_type: TUpdateJobTypeBody) => {
  return client.put(endpoints.AgreementServices.updateJobType(), { job_type });
};

export const sendAgreementToCustomerRequest = (id: number) =>
  client.post(endpoints.AgreementServices.sendAgreementToCustomer(id));
