import React, { useEffect, useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { MoreIcon, ImageGalleryIcon } from 'assets/icons';
import {
  Comment,
  CommentForm,
  ImageGallery,
  PostLikeToggle,
  ExpandableTypo,
  PortalDropDown,
  CustomizedAvatar,
  CustomTypography,
  PostImagePreview,
  PostVideoPreview,
  UserCardDataInfo,
  TeamInfoModal,
} from 'components';
import {
  deleteActivity,
  updateActivity,
  likeActivityPost,
  postCommentCreate,
  removeActivityPostLike,
  removeActivityPostComment,
} from 'store/slices/activitiesSlices/thunks';
import DeletePopup from 'components/views/DeletePopup';
import { TChangeInputEvent } from 'types/global/events';
import ReportsModal from 'components/views/Modals/ReportsModal';
import { TPostComment } from 'store/slices/activitiesSlices/types';
import PostsSlider from 'components/views/PostsSlider/PostsSlider';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { useAppDispatch, usePostComments, useAppSelector } from 'hooks';

import styles from './Post.module.scss';
import {
  dropdownOptionsCuratedCreator,
  dropdownOptionsMainCreator,
  dropdownOptionsSimpleCreator,
} from './Post.utils';

import type { TEmoji } from '../EmojiPicker/types';
import type { TPostProps } from './types';

const POST_COMMENTS_COUNT = 10;

const Post: React.FC<TPostProps> = ({ post }) => {
  const dispatch = useAppDispatch();

  const formFooterRef = useRef<HTMLDivElement | null>(null);

  const userInfo = useAppSelector(userDataSelector);

  const [isShowReplies, setIsShowReplies] = useState<boolean>(false);

  const [isEditable, setIsEditable] = useState(false);
  const [commentInput, setCommentInput] = useState('');
  const [writeReport, setWriteReport] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [refetch, setRefetch] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [updateValue, setUpdateValue] = useState(post?.content);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isTeamInfoOpen, setIsTeamInfoOpen] = useState<boolean>(false);
  const [isOpenSlider, setIsOpenSlider] = useState<boolean>(false);

  const inputElementComment = document.getElementById('comment_create') as HTMLInputElement;

  const {
    postComments,
    getPostComments,
    changeToNext,
    changeToPrev,
    isDisableNext,
    isDisablePrev,
  } = usePostComments(post?.id, showComment, 10);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const handleChangeToxNext = () => {
    changeToNext();
  };

  const openTeamInfo = () => {
    setIsTeamInfoOpen(true);
  };

  const toggleDropdown = () => setOpenDropdown(!openDropdown);

  const startRefetch = () => setRefetch(!refetch);

  useEffect(() => {
    setUpdateValue(post?.content);
  }, [post?.content]);

  const handleRemoveComment = useCallback(
    async (comment: TPostComment) => {
      await dispatch(removeActivityPostComment(comment?.id));

      toggleDropdown();

      if (comment?.activity_type === 'comment') {
        startRefetch();
      } else {
        getPostComments();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPostComments, startRefetch, toggleDropdown],
  );

  const commentsRenderer = postComments?.data?.map((comment) => {
    return (
      <Comment
        parentCoordinates={{ y: 0 }}
        id={`${comment?.id}`}
        isShowReplies={isShowReplies}
        setIsShowReplies={setIsShowReplies}
        refetchReplies={refetch}
        key={comment?.id}
        handleRemoveComment={handleRemoveComment}
        comment={comment}
        getComments={getPostComments}
        parentPostId={comment?.activity_type_id}
      />
    );
  });

  const allPostComments = commentsRenderer;

  const showSlider = () => setIsOpenSlider(true);

  const showGallery = () => setIsOpenSlider(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    toggleDropdown();
  };

  const isCurrenUser = userInfo?.id === post.user_id;
  const userImg = userInfo?.profile_image_url_id;

  useEffect(() => {
    if (updateValue !== post?.content) {
      setUpdateValue(post?.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post?.content]);

  const isLength = postComments?.data?.length;

  const toggleComment = () => {
    setShowComment(!showComment);
  };

  const startWriteReport = () => setWriteReport(true);
  const endReportWrite = () => setWriteReport(false);

  const addComment = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (commentInput?.trim() !== '') {
      await dispatch(
        postCommentCreate({
          content: commentInput,
          activity_type_id: post?.id,
          activity_type: 'post',
        }),
      );

      getPostComments();

      setCommentInput('');

      setShowComment(true);
    }
  };

  const updateContent = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!updateValue) {
      setIsEditable(false);
      setUpdateValue(post?.content);
    }

    if (updateValue?.trim() !== '') {
      await dispatch(
        updateActivity({
          id: post.id,
          pinned: true,
          content: updateValue,
          audience: post.audience,
          team_id: post?.team_id || null,
        }),
      );

      getPostComments();
      setIsEditable(false);
    }
  };

  const handleInputChange = (e: TChangeInputEvent) => {
    setCommentInput(e.target.value);
  };

  const handleUpdateChange = (e: TChangeInputEvent) => {
    setUpdateValue(e.target.value);
  };

  const handleEmojiSelect = (emoji: TEmoji) => {
    if (inputElementComment) {
      const cursor = inputElementComment.selectionStart || 0;

      const text = commentInput.slice(0, cursor) + emoji.native + commentInput.slice(cursor);
      setCommentInput(text);

      const newCursor = cursor + emoji.native.length;
      setTimeout(() => inputElementComment.setSelectionRange(newCursor, newCursor), 10);
      inputElementComment.focus();
    } else {
      setCommentInput((prevComment) => prevComment + emoji.native);
    }
  };

  const handleEmojiUpdateSelect = (emoji: TEmoji, inputElement?: HTMLInputElement) => {
    if (inputElement) {
      const cursor = inputElement.selectionStart || 0;

      const text = updateValue.slice(0, cursor) + emoji.native + updateValue.slice(cursor);
      setUpdateValue(text);

      const newCursor = cursor + emoji.native.length;
      setTimeout(() => inputElement.setSelectionRange(newCursor, newCursor), 10);
      inputElement.focus();
    } else {
      setUpdateValue((prevComment) => prevComment + emoji.native);
    }
  };

  const dividerClasses = classNames({
    [styles.container_divider]: showComment,
  });

  const medias =
    post.activity_feed_media &&
    post?.activity_feed_media?.map((media) => {
      if (media?.file_type?.startsWith('image')) {
        return <PostImagePreview key={media?.id} imgSrc={media?.media_url_id} media={media} />;
      } else {
        return <PostVideoPreview key={media?.id} imgSrc={media?.media_url_id} />;
      }
    });

  useEffect(() => {
    getPostComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateClick = async () => {
    setIsEditable(true);
    toggleDropdown();
  };

  const handleDeleteClick = async () => {
    await dispatch(deleteActivity(post.id));
    togglePopup();
  };

  const dropdownOptionsMain = dropdownOptionsMainCreator({
    editAction: handleUpdateClick,
    flagAction: startWriteReport,
    deleteAction: togglePopup,
  });

  const dropdownOptionsCurated = dropdownOptionsCuratedCreator({
    flagAction: startWriteReport,
    deleteAction: togglePopup,
  });

  const dropdownOptions = dropdownOptionsSimpleCreator({ flagAction: startWriteReport });

  const renderedOptions = isCurrenUser
    ? post?.type === 'post'
      ? dropdownOptionsMain
      : dropdownOptionsCurated
    : dropdownOptions;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_content}>
          <div className={styles.container_content_header}>
            <div className={styles.container_content_header_block}>
              <UserCardDataInfo
                openTeamInfo={openTeamInfo}
                name={post?.name}
                img={post?.profile_image_url_id}
                role={post?.user_designation as string}
                teamName={post?.team_name as string}
                time={post?.created_at as string}
              />

              <div>
                <PortalDropDown title={<MoreIcon />} options={renderedOptions} />
              </div>
            </div>
            <div className={styles.container_content_header__text}>
              {!isEditable ? (
                <ExpandableTypo title={post?.content} />
              ) : (
                <CommentForm
                  autoFocus
                  id='comment_input'
                  defaultValue={updateValue}
                  addComment={updateContent}
                  commentInput={updateValue}
                  placeholder='Edit your post title'
                  handleInputChange={handleUpdateChange}
                  handleEmojiSelect={handleEmojiUpdateSelect}
                />
              )}
            </div>
          </div>

          {post?.activity_feed_media && post?.activity_feed_media?.length > 1 ? (
            !isOpenSlider ? (
              <ImageGallery
                imageList={post?.activity_feed_media}
                onClick={showSlider}
                isShowMarks={isOpenSlider}
              />
            ) : (
              <Box sx={{ position: 'relative' }}>
                <div className={styles.container__gallery} role='button' onClick={showGallery}>
                  <ImageGalleryIcon height={25} width={25} />
                </div>
                <PostsSlider media={post?.activity_feed_media} />
              </Box>
            )
          ) : post?.activity_feed_media?.length === 1 ? (
            medias
          ) : null}

          <div className={styles.container_content_footer} ref={formFooterRef}>
            <div className={styles.container_content_footer_block}>
              <PostLikeToggle
                forPosts
                id={post?.id}
                activityId={post?.id}
                isLiked={post?.is_liked}
                likeRequest={likeActivityPost}
                unLikeRequest={removeActivityPostLike}
              />

              <div className={styles.container_content_footer_block_count}>
                <div
                  title={
                    postComments?.total_count > 1
                      ? `${postComments?.total_count} comments`
                      : undefined
                  }
                >
                  <CustomTypography onClick={toggleComment}>
                    {postComments?.total_count > 100 ? '99+' : postComments?.total_count | 0}{' '}
                    Comments
                  </CustomTypography>
                </div>
              </div>
            </div>
            <div className={styles.container_content_footer_block_comment}>
              <div className={styles.container_content_footer_block_comment_box}>
                <CustomizedAvatar
                  className={styles.container_content_footer_block_comment_box_icon}
                  width={45}
                  height={45}
                  alt='alt'
                  src={userImg}
                />
                <CommentForm
                  id='comment_create'
                  addComment={addComment}
                  commentInput={commentInput}
                  handleInputChange={handleInputChange}
                  handleEmojiSelect={handleEmojiSelect}
                />
              </div>

              {!!isLength && (
                <React.Fragment>
                  <div className={dividerClasses} />
                  {showComment ? (
                    <div
                      className={styles.container_content_footer_block_comment_scroll}
                      ref={scrollRef}
                    >
                      {allPostComments}
                    </div>
                  ) : null}
                  {showComment && postComments?.total_count > POST_COMMENTS_COUNT && (
                    <div className={styles.container_content_footer_block_comment_all}>
                      <ArrowUpwardIcon
                        onClick={changeToPrev}
                        style={{
                          opacity: isDisablePrev ? 0.5 : 1,
                          cursor: isDisablePrev ? 'auto' : 'pointer',
                        }}
                      />
                      <ArrowDownwardIcon
                        style={{
                          opacity: isDisableNext ? 0.5 : 1,
                          cursor: isDisableNext ? 'auto' : 'pointer',
                        }}
                        onClick={handleChangeToxNext}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <ReportsModal activityId={post?.id} isOpen={writeReport} onClose={endReportWrite} />

      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onDelete={handleDeleteClick}
        title='Delete Confirmation'
        body='Are you sure that you want to delete the post'
      />
      {post?.team_id && (
        <TeamInfoModal
          teamId={post?.team_id || 0}
          isOpen={isTeamInfoOpen}
          onClose={() => setIsTeamInfoOpen(false)}
        />
      )}
    </>
  );
};

export default Post;
