import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import ChatBot, { Step } from 'react-simple-chatbot';

import { UserImg, BotAvatar } from 'assets/images';
import { useAppSelector } from 'hooks';
import { activitiesApi } from 'api';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { returnImgUrl } from 'utils';
import { ImgUrlFolders } from 'types';

const MyChatBot: React.FC = () => {
  const userInfo = useAppSelector(userDataSelector);

  const Ai = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [res, setRes] = useState('');

    useEffect(() => {
      const fetchResponse = async () => {
        try {
          const response = await activitiesApi.genAiQuery(props.previousStep.value);
          if (response) {
            setLoading(false);
            props.triggerNextStep({ trigger: 'userQuery' });
            setRes(response.data);
          }
          return response.data;
        } catch (error) {
          props.triggerNextStep({ value: 'Sorry Could not find' });
          setLoading(false);
        }
      };
      fetchResponse();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.previousStep.value]);

    return <div>{loading ? <p>loading...</p> : res}</div>;
  };

  //define steps for chatbot
  const steps: Step[] = [
    {
      id: 'userQuery',
      message: 'What would you like to ask?',
      trigger: 'userInput',
    },
    {
      id: 'userInput',
      user: true,
      validator: (value: string) => {
        if (value.trim() === '') {
          return 'Input should not be empty';
        }
        if (!/[a-z]/i.test(value)) {
          return 'Input should contain at least one alphabet';
        }
        return true;
      },
      trigger: 'genAIResponse',
    },
    {
      id: 'genAIResponse',
      component: <Ai />, // Pass props correctly
      waitAction: true,
      asMessage: true,
      end: true,
    },
  ];

  // Define a theme for the chatbot
  const theme = {
    background: '#f5f8fb',
    fontFamily: 'CircularStdRegular',
    headerBgColor: '#2067dd',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#2067dd',
    botFontColor: '#fff',
    userBubbleColor: '#fff',
    userFontColor: '#4a4a4a',
  };

  const image = userInfo?.profile_image_url_id
    ? returnImgUrl(ImgUrlFolders.PROFILE_INFO, String(userInfo.profile_image_url_id))
    : UserImg;

  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        steps={steps}
        userAvatar={image}
        style={{ height: '560px' }}
        footerStyle={{ position: 'fixed', bottom: 0, width: '100%' }} // Set footer to fixed position
        botAvatar={BotAvatar}
      />
    </ThemeProvider>
  );
};

export default MyChatBot;
