import { TAllRequisitionsReturnType } from 'store/slices/requisitionSlices/types';

export type TActions = {
  body: string;
  title: string;
};

export type TPurchaseRequisitionHeaderProps = {
  renderedRequisitions: TAllRequisitionsReturnType;
};

export const actionEnums = (count: number) => ({
  submit: 'This will submit the requisition for approval. Please click on Confirm or Cancel.',
  delete: 'This will delete the requisition. Please click on Confirm or Cancel.',
  decline: 'This will reject the requisition. Please click on Confirm or Cancel.',
  approval: `This approval will add ${count} number of items to the inventory. Please click on Confirm or Cancel.`,
});
