import { useState, useEffect, useCallback, RefObject } from 'react';

type ElementSize = {
  width: number;
  height: number;
};

const useElementSize = (ref: RefObject<HTMLElement | null>): ElementSize | null => {
  const [elementSize, setElementSize] = useState<ElementSize | null>(null);

  const updateElementSize = useCallback(() => {
    if (ref.current !== null) {
      const rect = ref.current.getBoundingClientRect();
      setElementSize({
        width: rect.width,
        height: rect.height,
      });
    }
  }, [ref]);

  useEffect(() => {
    const handleResize = () => {
      updateElementSize();
    };

    window.addEventListener('resize', handleResize);
    updateElementSize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateElementSize]);

  return elementSize;
};

export default useElementSize;
