import { isNullOrWhiteSpace } from 'utils/StringUtils';

import { Theme, ThemeMode } from './CustomTheme';

const THEME_MODE_ATTRIBUTE = 'data-theme-mode';
const CSS_COLORS_PREFIX = 'theme-colors';

export const setThemeModeAttribute = (mode: ThemeMode) => {
  document.documentElement.setAttribute(THEME_MODE_ATTRIBUTE, mode);
};

export const createThemeStyles = (theme: Theme): void => {
  for (const key in theme.colors) {
    const themeMode = key as ThemeMode;
    createThemeStyleIfRequired(CSS_COLORS_PREFIX, theme.colors[themeMode], themeMode);
  }
  createThemeStyleIfRequired(CSS_COLORS_PREFIX, theme.colorPalette);
};

const createThemeStyleIfRequired = (
  cssVarsPrefix: string,
  themeValues: Record<string, string>,
  themeMode?: ThemeMode,
): void => {
  const styleId = cssVarsPrefix + (themeMode == null ? '' : `-${themeMode}`);
  const existentStyleElement = document.getElementById(styleId);
  if (existentStyleElement == null) {
    const styleSheet = createStyleSheet(styleId);
    const rule = createStyleRule(themeValues, cssVarsPrefix, themeMode);
    styleSheet.insertRule(rule);
  }
};

const createStyleSheet = (id: string): CSSStyleSheet => {
  const styleElement: HTMLStyleElement = document.createElement('style');
  styleElement.id = id;
  document.head.appendChild(styleElement);
  if (!styleElement.sheet) {
    throw new Error('Something went wrong during theme stylesheet creation');
  }

  return styleElement.sheet;
};

const getThemeModeSelector = (mode?: ThemeMode) =>
  mode == null ? '' : `[${THEME_MODE_ATTRIBUTE}="${mode}"]`;

const createStyleRule = (
  themeValues: Record<string, string>,
  cssVarsPrefix: string,
  themeMode?: ThemeMode,
): string => {
  let styleRuleToAdd = themeMode ? `:root${getThemeModeSelector(themeMode)}` : ':root';
  styleRuleToAdd += '{';

  for (const key in themeValues) {
    const value = themeValues[key];
    if (!isNullOrWhiteSpace(value)) {
      styleRuleToAdd += `--${cssVarsPrefix}-${key}: ${value};`;
    }
  }
  styleRuleToAdd += '}';
  return styleRuleToAdd;
};

export function getPaletteColorByIndex(colorPalette: Theme['colorPalette'], index: number) {
  return Object.values(colorPalette)[index];
}
