import { FC } from 'react';
import classNames from 'classnames';

import styles from './FocusedSimpleButton.module.scss';

import type { TFocusedSimpleButton } from './types';

const FocusedSimpleButton: FC<TFocusedSimpleButton> = ({ children, onClick, style, className }) => (
  <button className={classNames(styles.container, className)} style={style} onClick={onClick}>
    {children}
  </button>
);

export default FocusedSimpleButton;
