import { forwardRef, Ref } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';

import { FilledCheckedIcon, NewUncheckedIcon } from 'assets/icons';

const TableCheckbox = forwardRef((props: CheckboxProps, ref: Ref<HTMLButtonElement>) => (
  <Checkbox ref={ref} icon={<NewUncheckedIcon />} {...props} checkedIcon={<FilledCheckedIcon />} />
));

export default TableCheckbox;
