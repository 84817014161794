import { client, endpoints } from 'api';
import { TPagination } from 'types';

import type { TCreateCoupon, TUpdateCoupon } from 'store/slices/discountSlice/types';

export const getAllCouponsRequest = (options: TPagination) =>
  client.get(endpoints.DiscountService.getAllCoupons(), { params: { options } });

export const updateCouponRequest = (body: TUpdateCoupon) =>
  client.put(endpoints.DiscountService.updateCoupon(), { ...body });

export const getCouponByIdRequest = (id: number) =>
  client.get(endpoints.DiscountService.getCouponById(id));

export const createCouponRequest = (body: TCreateCoupon) =>
  client.post(endpoints.DiscountService.createCoupon(), { coupon: { ...body } });

export const removeCouponByIdRequest = (id: number) =>
  client.delete(endpoints.DiscountService.removeCouponById(id));

export const getCouponByCatalogIdRequest = (id: number) =>
  client.get(endpoints.DiscountService.getCouponBuCatalogId(id));
