import { FC, useEffect, useRef, useState } from 'react';

import { CustomSkeleton } from 'components/shared';

import { TVidProps } from '../types';

const StreamElement: FC<TVidProps> = ({ stream, contentRef, currentStream }) => {
  const ref = useRef<HTMLVideoElement | null>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (ref?.current && !!stream) {
      ref.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <div
      ref={contentRef}
      style={{
        aspectRatio: '1/1',
        height: '100%',
        borderRadius: '10px',
        overflow: 'hidden',
        background: 'transparent',
      }}
    >
      {(!currentStream || !isLoaded) && <CustomSkeleton height='100%' width='100%' />}
      <video onLoadedData={() => setIsLoaded(true)} autoPlay muted ref={ref} playsInline />
    </div>
  );
};

export default StreamElement;
