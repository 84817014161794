import { TOption } from 'components/shared/Select/types';

import { BusssinesType } from './types';

export const industryOptions: TOption[] = [
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'industrial', label: 'Industrial' },
  { value: 'retail', label: 'Retail' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'office', label: 'Office' },
  { value: 'educational', label: 'Educational' },
  { value: 'technology', label: 'Technology' },
];

export const businessOptions: TOption[] = [
  { value: 'company', label: 'Company' },
  { value: 'individual', label: 'Individual' },
  { value: 'non_profit', label: 'Non Profit' },
  { value: 'government_entity', label: 'Government Entity' },
];

export const subtitleOptions: Record<BusssinesType, { value: string; label: string }[]> = {
  [BusssinesType.Hospitality]: [
    { value: 'restaurants', label: 'Restaurants' },
    { value: 'hotels', label: 'Hotels' },
    { value: 'amusement parks', label: 'Amusement Parks' },
    { value: 'nightclubs', label: 'Nightclubs' },
    { value: 'movie theaters', label: 'Movie Theaters' },
    { value: 'stadiums', label: 'Stadiums' },
  ],
  [BusssinesType.Industrial]: [
    { value: 'hvac', label: 'HVAC' },
    { value: 'construction', label: 'Construction' },
    { value: 'warehouses', label: 'Warehouses' },
    { value: 'factories', label: 'Factories' },
    { value: 'repair shops', label: 'Repair Shops' },
    { value: 'plumbing', label: 'Plumbing' },
  ],
  [BusssinesType.Retail]: [
    { value: 'shops', label: 'Shops' },
    { value: 'convenience stores', label: 'Convenience Stores' },
    { value: 'shopping malls', label: 'Shopping Malls' },
    { value: 'showrooms', label: 'Showrooms' },
  ],
  [BusssinesType.Healthcare]: [
    { value: 'hospitals', label: 'Hospitals' },
    { value: 'dispensaries', label: 'Dispensaries' },
    { value: 'nursing homes', label: 'Nursing Homes' },
    { value: 'medical centers', label: 'Medical Centers' },
  ],
  [BusssinesType.Office]: [
    { value: 'serviced offices', label: 'Serviced Offices' },
    { value: 'office buildings', label: 'Office Buildings' },
    { value: 'research and development', label: 'Research and Development' },
  ],
  [BusssinesType.Educational]: [
    { value: 'schools', label: 'Schools' },
    { value: 'colleges', label: 'Colleges' },
    { value: 'universities', label: 'Universities' },
  ],
  [BusssinesType.Technology]: [
    { value: 'computers', label: 'Computers' },
    { value: 'computer software', label: 'Computer Software' },
    { value: 'networking', label: 'Networking' },
    { value: 'telephone communications', label: 'Telephone Communications' },
    { value: 'accounting systems', label: 'Accounting Systems' },
    { value: 'inventory control systems', label: 'Inventory Control Systems' },
    {
      value: "Customer Relationship Management Systems(CRM's)",
      label: "Customer Relationship Management Systems(CRM's)",
    },
  ],
};
