import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { GridColDef } from '@mui/x-data-grid';
import { Box, CircularProgress, IconButton } from '@mui/material';
import classNames from 'classnames';

import {
  EmptyTitle,
  ScrollLayout,
  CustomDataGrid,
  CustomTypography,
  PortalDropDown,
  DynamicPaginationControl,
} from 'components';
import { ImgUrlFolders, Routes } from 'types';
import { capitalizeFirstLetter } from 'utils';
import useXLSXDownload from 'hooks/useXLSXDownload';
import { convertToMMDDYYYY } from 'utils/formatDate';
import { useAppDispatch, useAppSelector, usePdfDownloadLink } from 'hooks';
import { getDocumentByIdAndSection } from 'store/thunks';
import { DownloadPdfIcon, MoreIcon, PdfIcon, XLSX } from 'assets/icons';
import { DocumentSectionCard, DocumentsHeader } from 'containers';
import { setItemsLimit, setItemsPage } from 'store/slices/documentsSlice';
import {
  documentsItemSelector,
  documentTypesAscSelector,
  documentsItemLimitSelector,
  documentsItemOffsetSelector,
} from 'store/slices/documentsSlice/selectors';
import { SortByEnums } from 'store/slices/documentsSlice/types';
import { EmptyTitles } from 'constants/EmptyTitles';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import { breadCrumbOptions, documentsColumns } from './utils';
import styles from './DocumentItemSection.module.scss';
import { TKeyValues } from './types';

const documentsView = BrowserStorageService.get(BrowserStorageKeys.DocumentsView, {
  session: true,
});

const DocumentItemSection = () => {
  const dispatch = useAppDispatch();

  const { name, id, section } = useParams();

  const documents = useAppSelector(documentsItemSelector);

  const rolesLoading = useAppSelector(userAllRolesLoadingSelector);
  const { isAccessToDocumentationAndReports } = useAppSelector(userPermissionsSelector);

  const documentsAsc = useAppSelector(documentTypesAscSelector);
  const documentsLimit = useAppSelector(documentsItemLimitSelector);
  const documentsOffset = useAppSelector(documentsItemOffsetSelector);

  const documentsViewValue = documentsView ? JSON.parse(documentsView) : false;

  const [isViewInGrid, setIsViewInGrid] = useState<boolean>(documentsViewValue);

  useEffect(() => {
    if (documentsViewValue) {
      setIsViewInGrid(documentsViewValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsViewValue]);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);

  const renderOptions = breadCrumbOptions(
    name as string,
    id as string,
    capitalizeFirstLetter(section as string),
  );

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(setItemsLimit(event.target.value));
  };

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setItemsPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColumnHeaderClick = (column: GridColDef) => {
    const orderBy =
      column.field === 'documentName' ? SortByEnums.NAME : (column.field as SortByEnums);

    setNewSortBy(orderBy);

    dispatch(
      getDocumentByIdAndSection({
        id: id as string,
        type: section,
        options: {
          direction: documentsAsc,
          limit: documentsLimit,
          order_by: orderBy,
          offset: documentsOffset,
        },
      }),
    );
  };

  const keyValues: TKeyValues = {
    name: 'documentName',
    last_modified: 'last_modified',
    size: 'size',
  };

  const renderColumns: GridColDef[] = documentsColumns?.map((column) => {
    const isColumnSorted = keyValues[newSortBy as keyof TKeyValues] === column.field;

    const isAsc = documentsAsc === 'ASC';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !isAsc },
      {
        [styles.activeSortHeader_asc]: isAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const selectedItem = documents.data?.find((item) => item.id === selectedId);

  const { fetchDataAndDownload, isStarted, openPdfInNewTab } = usePdfDownloadLink({
    fileUrl: selectedItem?.file_url_id as string,
    folder: ImgUrlFolders.DOCUMENT,
    withToastMessages: true,
  });

  const { fetchXLDataAndDownload, isXLStarted, openXLSXInNewTab } = useXLSXDownload({
    fileUrl: selectedItem?.file_url_id as string,
    folder: ImgUrlFolders.DOCUMENT,
    withToastMessages: true,
  });

  const dropdownItems = [
    {
      id: 1,
      icon: <DownloadPdfIcon />,
      name: 'Download',

      disabled: isStarted,
      action: () => {
        if (!isStarted) {
          fetchDataAndDownload();
        }
      },
    },
  ];

  const dropdownItemsForXLSX = [
    {
      id: 1,
      icon: <DownloadPdfIcon />,
      name: 'Download',

      disabled: isXLStarted,
      action: () => {
        if (!isXLStarted) {
          fetchXLDataAndDownload();
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getDocumentByIdAndSection({
        id: id as string,
        type: section,
        options: {
          direction: documentsAsc,
          limit: documentsLimit,
          order_by: SortByEnums.SIZE,
          offset: documentsOffset,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsLimit, documentsOffset]);

  const renderRows = documents?.data?.map((el) => {
    let documentName;
    if (el.document_type === 'xlsx') {
      // Handle xlsx document type differently
      documentName = (
        <div className={styles.container__name} onClick={() => openXLSXInNewTab(el?.file_url_id)}>
          {/* <div className={styles.container__name} onClick={() => openPdfInNewTab(el?.file_url_id)}> */}
          <XLSX width={24} height={24} />
          <p className={styles.container__name__title}>{el.name}</p>
        </div>
      );
    } else {
      // Render as usual for other document types
      documentName = (
        <div className={styles.container__name} onClick={() => openPdfInNewTab(el?.file_url_id)}>
          <PdfIcon width={24} height={24} />
          <p className={styles.container__name__title}>{el.name}</p>
        </div>
      );
    }
    let options;
    if (el.document_type === 'xlsx') {
      // Render default options for other document types
      options = (
        <PortalDropDown
          title={
            <IconButton className={styles.container__icon}>
              <MoreIcon />
            </IconButton>
          }
          getSelectedId={setSelectedId}
          selectedId={el.id}
          options={dropdownItemsForXLSX}
        />
      );
    } else {
      // Render default options for other document types
      options = (
        <PortalDropDown
          title={
            <IconButton className={styles.container__icon}>
              <MoreIcon />
            </IconButton>
          }
          getSelectedId={setSelectedId}
          selectedId={el.id}
          options={dropdownItems}
        />
      );
    }
    return {
      id: el.id,
      name: el.name,
      document_type: el.document_type,
      documentName,
      last_modified: convertToMMDDYYYY(el.last_modified),
      size: `${Math.ceil(el.size / 1024)} KB` || '--',
      options,
      link: el.file_url_id,
      created: convertToMMDDYYYY(el.created_at),
    };
  });

  const groupedDocuments: Record<string, any[]> = {};

  renderRows?.forEach((el) => {
    if (!groupedDocuments[el.created]) {
      groupedDocuments[el.created] = [];
    }

    groupedDocuments[el.created].push(el);
  });

  const renderDocumentsCards = Object.keys(groupedDocuments)?.map((date) => {
    const documents = groupedDocuments[date];
    return (
      <Box key={date} className={styles.container__cards}>
        <CustomTypography className={styles.container__cards__title}>{date}</CustomTypography>
        <Box className={styles.container__cards__content}>
          {documents?.map((document) => (
            <DocumentSectionCard key={document.id} document={document.name} link={document.link} />
          ))}
        </Box>
      </Box>
    );
  });

  useEffect(() => {
    if (!documents.total_count && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderRows?.length]);

  if (!isAccessToDocumentationAndReports && !rolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box className={styles.container}>
      <DocumentsHeader
        breadCrumbOptions={renderOptions}
        isViewInGrid={isViewInGrid}
        setIsViewInGrid={setIsViewInGrid}
      />

      <>
        {renderRows?.length ? (
          isViewInGrid ? (
            renderDocumentsCards?.length ? (
              renderDocumentsCards
            ) : (
              <EmptyTitle title='No Documents added yet' />
            )
          ) : (
            <ScrollLayout>
              <CustomDataGrid
                rowHeight={77}
                headerHeight={44}
                rows={renderRows}
                columns={renderColumns}
                emptyTitle={EmptyTitles.Document_Content}
                onColumnHeaderClick={handleColumnHeaderClick}
              />
            </ScrollLayout>
          )
        ) : (
          <Box className={styles.loading}>
            <CircularProgress />
          </Box>
        )}
        <Box className={styles.container__footer}>
          <DynamicPaginationControl
            count={documents.total_count}
            page={Number(currentPage)}
            onPageChange={handlePageChange}
            rowsPerPage={documentsLimit}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Box>
      </>
    </Box>
  );
};

export default DocumentItemSection;
