import { Box } from '@mui/material';
import { FC } from 'react';
import classNames from 'classnames';

import Button from '../Button';

import styles from './LivestreamTabs.module.scss';

import type { TLiveStreamTabs, TTabs } from './types';

const LiveStreamTabs: FC<TLiveStreamTabs> = ({ activeTab, setActiveTab, tabs }) => {
  const durationButtons = tabs.map((tab, idx) => {
    const buttonClasses = classNames(styles.container__button, {
      [styles.container__button_active]: activeTab.tab === tab.tab,
    });

    const handleChangeActiveValue = (
      event: React.SyntheticEvent<HTMLButtonElement>,
      tab: TTabs,
    ) => {
      event.stopPropagation();
      setActiveTab(tab);
    };

    return (
      <Button
        textClassName={styles.container__button__text}
        className={buttonClasses}
        key={idx}
        onClick={(event: React.SyntheticEvent<HTMLButtonElement>) =>
          handleChangeActiveValue(event, tab)
        }
      >
        {tab.tab}
      </Button>
    );
  });

  return (
    <Box className={styles.container}>
      <Box className={styles.container__border}>{durationButtons}</Box>
    </Box>
  );
};

export default LiveStreamTabs;
