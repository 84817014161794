import { FC, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'hooks';
import { TChangeInputEvent } from 'types/global/events';
import { CustomTypography } from 'components';
import { updateUserSettings } from 'store/slices/authSlice/authThunks';
import { notificationsSetting, userDataSelector } from 'store/slices/authSlice/selectors';
import CustomSwitch from 'components/shared/Switch';

import { NotificationSettings } from '../AccountSettingsComponents/Settings/mockData';

import styles from './SettingsItem.module.scss';

import type { TSettingItemProps } from './types';

const SettingsItem: FC<TSettingItemProps> = ({ title }) => {
  const dispatch = useAppDispatch();

  const notificationSettings = useAppSelector(notificationsSetting);
  const userInfo = useAppSelector(userDataSelector);

  const [isChecked, setIsChecked] = useState(false);

  const titleToValueMap = useMemo(
    () => ({
      [NotificationSettings.EMAIL]: notificationSettings?.email_notification,
      // [NotificationSettings.NOTIFICATIONS]: notificationSettings?.in_app_notification,
      [NotificationSettings.PHONE]: notificationSettings?.sms_notification,
    }),
    [
      notificationSettings?.email_notification,
      // notificationSettings?.in_app_notification,
      notificationSettings?.sms_notification,
    ],
  );

  useEffect(() => {
    setIsChecked(titleToValueMap[title] === true);
  }, [title, titleToValueMap]);

  const handleSwitchChange = (event: TChangeInputEvent) => {
    setIsChecked(event.target.checked);

    dispatch(
      updateUserSettings({
        id: Number(userInfo?.id),
        email_notification:
          title === NotificationSettings.EMAIL
            ? event.target.checked
            : Boolean(notificationSettings?.email_notification),
        sms_notification:
          title === NotificationSettings.PHONE
            ? event.target.checked
            : Boolean(notificationSettings?.sms_notification),
        // in_app_notification:
        //   title === NotificationSettings.NOTIFICATIONS
        //     ? event.target.checked
        //     : Boolean(notificationSettings?.in_app_notification),
      }),
    );
  };

  return (
    <Box className={styles.container}>
      <div className={styles.container__content}>
        <CustomTypography className={styles.container__content__title} fontWeight={500}>
          {title}
        </CustomTypography>
      </div>
      <CustomSwitch checked={isChecked} onChange={handleSwitchChange} />
    </Box>
  );
};

export default SettingsItem;
