import { ActiveCardsIcon, CircumIcon, DisableTableIcon } from 'assets/icons';

import DocumentsContent from './Contents/DocumentsContent';
import { DocumentsSectionsItems, TDocumentsSectionsItems } from './types';

export const generateDocumentSections = (isViewInGrid: boolean): TDocumentsSectionsItems[] => [
  {
    id: 1,
    title: DocumentsSectionsItems.DOCUMENTS,
    content: <DocumentsContent isViewInGrid={isViewInGrid} />,
  },
];

export const viewOptions = [
  { id: 1, icon: <DisableTableIcon />, activeIcon: <DisableTableIcon /> },
  { id: 2, icon: <CircumIcon />, activeIcon: <ActiveCardsIcon /> },
];
