import { Box, Stack } from '@mui/material';
import { useState, type FC, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector, useInput, useUserCatalogAccess } from 'hooks';
import { ArrowDown, SearchFilterIcon } from 'assets/icons';
import { Button, CustomTextField, CustomTypography } from 'components';
import {
  shareCatalog,
  removedAccessById,
  getShareDCatalogDataById,
} from 'store/slices/catalogSlice/thunks';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';
import { TOrganizationMember } from 'store/slices/organizationsSlice/types';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { Colors } from 'types';

import styles from './ShareDropDown.module.scss';
import SharedCatalogUserItem from './SharedCatalogUserItem';

import type { TShareDropDownProps } from './types';

const ShareCatalogDropDown: FC<TShareDropDownProps> = ({
  sharedId,
  removedUsersIds,
  setRemovedUsersIds,
}) => {
  const dispatch = useAppDispatch();

  const userData = useAppSelector(userDataSelector);

  const { data, getUserAccessToCatalogs } = useUserCatalogAccess(sharedId as number);
  const { value, handleChange } = useInput();

  const organizationUsers = useAppSelector(organizationMembersSelector)?.filter(
    (user) => user?.user_id !== userData?.id,
  );

  const renderedUsers =
    value?.trim()?.length > 0
      ? organizationUsers?.filter((user) =>
          user?.name?.toLowerCase()?.includes(value?.toLowerCase()),
        )
      : organizationUsers;

  const organizationUsersIds = renderedUsers?.map((user) => user?.user_id);

  const activeUsersIds = useMemo(
    () =>
      Array.from(new Set(data?.data?.map((user) => user?.user_id)))?.filter((user) =>
        organizationUsersIds?.includes(user),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.data],
  );

  const [selectedUsersList, setSelectedUsersList] = useState<number[]>(activeUsersIds || []);

  const filteredArray = selectedUsersList?.filter((element) => !activeUsersIds?.includes(element));

  const shareCatalogItem = async () => {
    if (removedUsersIds?.length) {
      await dispatch(removedAccessById(removedUsersIds)).then(() => getUserAccessToCatalogs()),
        setRemovedUsersIds([]);
    }

    if (filteredArray?.length) {
      filteredArray?.forEach(
        async (user) =>
          await dispatch(
            shareCatalog({
              user_id: user,
              catalog_id: sharedId as number,
              type: 'RO',
            }),
          ).then(() => getUserAccessToCatalogs()),
      );
    }
  };

  useEffect(() => {
    if (activeUsersIds) {
      setSelectedUsersList(activeUsersIds);
    }
  }, [activeUsersIds]);

  useEffect(() => {
    if (sharedId) {
      dispatch(getShareDCatalogDataById(sharedId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedId]);

  const handleCheckboxChange = (user: TOrganizationMember) => {
    if (selectedUsersList.includes(user?.user_id as number)) {
      setSelectedUsersList(selectedUsersList.filter((id) => id !== user?.user_id));
      const uncheckedUserRoleId = data?.data?.find((member) => member?.user_id === user?.user_id);
      if (uncheckedUserRoleId) {
        setRemovedUsersIds([...removedUsersIds, uncheckedUserRoleId?.id]);
      }
    } else {
      setSelectedUsersList([...selectedUsersList, user?.user_id as number]);
      const uncheckedUserRoleId = data?.data?.find((member) => member?.user_id === user?.user_id);

      setRemovedUsersIds(removedUsersIds?.filter((item) => item !== uncheckedUserRoleId?.id));
    }
  };

  const renderSelectList = renderedUsers?.map(
    (item, idx) =>
      item?.user_id && (
        <SharedCatalogUserItem
          key={idx}
          item={item}
          currentCatalogId={sharedId as number}
          handleCheckboxChange={handleCheckboxChange}
          selectedUsersList={selectedUsersList}
        />
      ),
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.container__header}>
        <CustomTypography className={styles.container__header__title}>
          Select Employee
        </CustomTypography>
        <ArrowDown className={styles.container__header__icon} />
      </Box>
      <CustomTextField
        backGroundColor={Colors.WHITE}
        borderColor={Colors.GRAY}
        padding='10px 8px '
        value={value}
        onChange={handleChange}
        borderRadius='5px'
        placeholder='“Search"'
        rightIcon={<SearchFilterIcon />}
        className={styles.container__content__search}
      />
      <Box className={styles.container__content}>
        <Box className={styles.container__content__list}>
          {renderedUsers?.length ? (
            renderSelectList
          ) : (
            <Stack justifyContent='center' alignItems='center' margin='10px 0'>
              No users
            </Stack>
          )}
        </Box>
      </Box>
      <Box className={styles.container__content__buttons}>
        <Button
          maxWidth='80px'
          color='white'
          type='submit'
          padding='11px 16px'
          onClick={shareCatalogItem}
          backgroundColor={Colors.SAPPHIRE}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default ShareCatalogDropDown;
