import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';

import { Button, ControlledInput, ControlledSelect, DrawerLayout } from 'components';
import { Colors, FontNames } from 'types';
import breakPoints from 'constants/BreakPoints';
import { vendorCreateSchema } from 'constants/Schemas';
import { vendorCreateFormValuesGenerator } from 'utils';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { createVendor, updateVendor } from 'store/slices/inventorySlice/thunks';
import { currentVendorByIdSelector } from 'store/slices/inventorySlice/selectors';
import { getElementsById } from 'store/slices/estimatesSlice/thunks';
import { TOption } from 'components/shared/Select/types';

import styles from './AddVendorDrawer.module.scss';

import type { TAddVendorDrawerProps, TField, TSection, TVendorFormValues } from './types';
import type { TVendor, TVendorUpdate } from 'store/slices/inventorySlice/types';

const AddEstimateDrawer: FC<TAddVendorDrawerProps> = ({
  isOpen,
  setIsOpen,
  id,
  inEditMode = false,
}) => {
  const dispatch = useAppDispatch();
  const [mypayload, setmyPayload] = useState([]);
  const { width } = useWindowSize();

  const currentVendor = useAppSelector(currentVendorByIdSelector);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TVendorFormValues>({
    resolver: yupResolver(vendorCreateSchema),
    values: vendorCreateFormValuesGenerator({ inEditMode, currentVendor }),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getElementsById(id || 0));
        const data = await response.payload;
        setmyPayload(data);
      } catch (error) {
        const Error = error as AxiosError;
        throw Error;
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const drawerTitle = inEditMode ? 'Edit Vendor' : ' Add Estimate';

  const handleClose = () => setIsOpen(false);

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    if (!inEditMode) {
      const response = await dispatch(createVendor(data as TVendor));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    } else {
      const sendedParams = {
        ...data,
        id: currentVendor?.id as number,
        vendor_link: data.vendor_link.trim(),
      };

      const response = await dispatch(updateVendor(sendedParams as TVendorUpdate));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    }
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset();
    handleClose();
  };

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 475
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 623;
  const inputFields = mypayload?.flatMap((section: TSection) => {
    return (
      <div key={section.section} className={styles.container__header__title}>
        <h2 className={styles.container__header__title}>{section.section}</h2>
        {section.fields.map((field: TField) => {
          switch (field.type) {
            case 'number':
            case 'text':
              return (
                <div
                  key={`${section.section}-${field.name}`}
                  className={styles.container__content__item}
                >
                  <ControlledInput
                    name={field.name}
                    label={field.name}
                    control={control}
                    required={false}
                    borderColor={Colors.SOFT_SILVER}
                    fontFamily={FontNames.CIRCULAR_REG}
                    placeholder={field.name}
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    error={errors[field.name as keyof TVendorFormValues]}
                    helperText={errors[field.name as keyof TVendorFormValues]?.message}
                  />
                </div>
              );
            case 'enum': {
              const fieldOptions: TOption[] = field.values.map((item, index) => ({
                id: index + 1,
                value: item,
                label: String(item),
              }));
              return (
                <div className={styles.container__content__item}>
                  <ControlledSelect
                    name={field.name}
                    label={field.name}
                    defaultValue=''
                    control={control}
                    required={true}
                    options={fieldOptions}
                    fontFamily={FontNames.CIRCULAR_REG}
                    border='1px solid #EDEFF1'
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    errors={!!errors[field.name as keyof TVendorFormValues]}
                  />
                </div>
              );
            }
            case 'boolean':
              return (
                <div className={styles.container__content__item}>
                  <ControlledSelect
                    name={field.name}
                    label={field.name}
                    defaultValue=''
                    control={control}
                    required={true}
                    options={[
                      { id: 1, value: 'true', label: 'true' },
                      { id: 2, value: 'false', label: 'false' },
                    ]}
                    fontFamily={FontNames.CIRCULAR_REG}
                    border='1px solid #EDEFF1'
                    borderRadius='5px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    errors={!!errors[field.name as keyof TVendorFormValues]}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  });

  return (
    <DrawerLayout
      open={isOpen}
      inCenter={false}
      width={drawerWidth}
      onClose={onDrawerClose}
      headerTitle={drawerTitle}
      onCloseReset={onDrawerClearClose}
      titleClassName={styles.container__title}
    >
      <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Box className={styles.container__content}>{inputFields}</Box>
        </Box>
        <Box className={styles.container__footer}>
          <Button
            type='button'
            maxWidth='68px'
            padding='11px 8px'
            minWidth='68px'
            borderRadius='5px'
            onClick={onDrawerClose}
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
            fontFamily={FontNames.CIRCULAR_BOLD}
            className={styles.container__content__submit}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='white'
            maxWidth='68px'
            minWidth='68px'
            padding='11px 8px'
            borderRadius='5px'
            backgroundColor={Colors.SAPPHIRE}
            fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            disabled={isSubmitting}
            className={styles.container__content__submit}
          >
            {inEditMode ? 'Update' : 'Add'}
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default AddEstimateDrawer;
