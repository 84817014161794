import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';

import { Colors, FontNames } from 'types';
import { Routes } from 'types';
import { findCustomer } from 'store/thunks';
import { PlusIcon } from 'assets/icons';
import ControlledInput from 'components/views/ControlledInput';
import { getAllTemplates } from 'store/slices/estimatesSlice/thunks';
import { excelListSelector } from 'store/slices/inventorySlice/selectors';
import { customersSelector } from 'store/slices/customersSlices/selectors';
import { foundedCustomerSelector } from 'store/slices/searchSlice/selectors';
import {
  useInput,
  useWindowSize,
  useAppDispatch,
  useAppSelector,
  useRefreshClearState,
} from 'hooks';
import { Button, ControlledAutocomplete, CustomTypography, DrawerLayout } from 'components';
import AddExcelDrawer from 'containers/Inventory/ExcelList/AddVendor';
import AddEstimateDrawer from 'containers/Inventory/ExcelList/AddEstimateViaTemplate';

import { breakPoints } from '../../../../constants';

import { schema } from './schema';
import styles from './CreateEstimateDrawer.module.scss';
import SelectCatalogDropDown from './SelectCatalogDropDown';
import { catalogErrorMessage, customerErrorMessage } from './utils';

import type { TCreateEstimateDrawerProps, TCreateEstimateForm } from './types';
import type { TExcel } from './SelectCatalogDropDown/types';

const CreateEstimateViaTemplateDrawer: FC<TCreateEstimateDrawerProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const foundedCustomers = useAppSelector(foundedCustomerSelector);
  const allCustomers = useAppSelector(customersSelector);
  const allExcel = useAppSelector(excelListSelector);
  const [currentTemplate, setCurrentTemplate] = useState<TExcel | null>(null);
  const [, setSelectedItem] = useState<string[]>([]);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isOpenDrawerEstimate, setIsOpenDrawerEstimate] = useState<boolean>(false);

  const { value, debouncedValue, handleChange } = useInput();

  const { width } = useWindowSize();

  useEffect(() => {
    if (debouncedValue?.trim()?.length) {
      dispatch(findCustomer({ query: debouncedValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    const fetchDefaultTemplate = async () => {
      try {
        await dispatch(getAllTemplates());
      } catch (error) {
        const Error = error as AxiosError;
        throw Error;
      }
    };
    fetchDefaultTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    reset,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm<TCreateEstimateForm>({ resolver: yupResolver(schema) });

  const drawerWidth =
    Number(width) >= breakPoints.TABLET ? 472 : Number(width) >= breakPoints.TABLET_M ? 400 : 375;
  const estimateName = watch('name');
  const estimate_version_tag = watch('version_tag');
  const description = watch('description');
  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    navigate(Routes.EstimateTemplateForm, {
      state: {
        Template_ID: currentTemplate?.id,
        estimateName,
        estimate_version_tag,
        description,
        CustomerID: data?.customer?.id as number,
      },
    });
    reset();
    handleClose();
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerCloseReset = async () => {
    reset({
      name: '',
    });
    setCurrentTemplate(null);
    setSelectedItem([]);
    handleClose();
  };

  const isDisabled = currentTemplate?.id;

  const customersOptions = foundedCustomers?.data?.map((customer) => ({
    id: customer?.id,
    value: customer?.name,
    label: customer?.name,
  }));

  const notHaveCatalogs = allExcel?.total_count === 0;
  const notHaveCustomers = allCustomers?.total_count === 0;

  const goForCreationTheCustomer = () =>
    navigate(Routes.Customer, { state: { isOpenCreateDrawer: true } });

  const openDrawer = () => setIsOpenDrawer(true);
  const customersHelperText = errors?.customer?.id?.message;
  useRefreshClearState();

  return (
    <>
      <DrawerLayout
        open={open}
        width={drawerWidth}
        onClose={onDrawerClose}
        headerTitle=''
        onCloseReset={onDrawerCloseReset}
      >
        <form className={styles.container} onSubmit={handleSubmit(handleCreateData)}>
          <CustomTypography className={styles.container__title}>Create Estimate</CustomTypography>
          <ControlledInput
            required
            name='name'
            control={control}
            borderRadius='5px'
            borderColor={Colors.SOFT_SILVER}
            label='Estimate name:'
            backgroundColor={Colors.FROST_WHITE}
            fontFamily={FontNames.CIRCULAR_LIGHT}
            placeholder='Estimate name:*'
          />
          <ControlledInput
            required
            name='version_tag'
            control={control}
            borderRadius='5px'
            borderColor={Colors.SOFT_SILVER}
            label='Version Tag:'
            backgroundColor={Colors.FROST_WHITE}
            fontFamily={FontNames.CIRCULAR_LIGHT}
            placeholder='Estimate name:*'
          />
          <ControlledInput
            required
            name='description'
            control={control}
            borderRadius='5px'
            borderColor={Colors.SOFT_SILVER}
            label='Description:'
            backgroundColor={Colors.FROST_WHITE}
            fontFamily={FontNames.CIRCULAR_LIGHT}
            placeholder='Estimate name:*'
          />
          <div>
            <ControlledAutocomplete
              name='customer'
              label='Customer:'
              control={control}
              borderRadius='5px'
              inputValue={value}
              optionsName='label'
              borderColor={Colors.SOFT_SILVER}
              placeholder='Customer:*'
              backgroundColor={Colors.FROST_WHITE}
              errors={!!errors.customer}
              options={customersOptions}
              fontFamily={FontNames.CIRCULAR_LIGHT}
              handleInputChange={handleChange}
              helperText={
                notHaveCustomers
                  ? customerErrorMessage
                  : customersHelperText && String(customersHelperText)
              }
            />

            <Button
              className={styles.container__add}
              startIcon={<PlusIcon width={16} height={16} />}
              onClick={goForCreationTheCustomer}
              fontSize='12px'
            >
              CREATE CUSTOMER NOT LISTED
            </Button>
          </div>
          <div>
            <SelectCatalogDropDown
              currentTemplate={currentTemplate}
              setCurrentTemplate={setCurrentTemplate}
              helperText={notHaveCatalogs ? catalogErrorMessage : ''}
            />
            <Button
              fontSize='12px'
              onClick={openDrawer}
              className={styles.container__add}
              startIcon={<PlusIcon width={16} height={16} />}
            >
              CREATE PRICING TEMPLATE NOT LISTED
            </Button>
          </div>

          <Button
            type='submit'
            // onClick={EstimateCreation}
            className={styles.container__submit}
            disabled={!isDisabled || isSubmitting || !isValid}
          >
            GO TO ESTIMATE FORM
          </Button>
        </form>
      </DrawerLayout>
      <AddExcelDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} />
      <AddEstimateDrawer
        isOpen={isOpenDrawerEstimate}
        setIsOpen={setIsOpenDrawerEstimate}
        id={currentTemplate?.id || 0}
      />
    </>
  );
};

export default CreateEstimateViaTemplateDrawer;
