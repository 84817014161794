import type { RootState } from 'types';

export const allPurchaseOrdersSelector = (state: RootState) =>
  state.purchaseOrder.allPurchaseOrders;

export const allPurchaseOrdersLoadingSelector = (state: RootState) =>
  state.purchaseOrder.allPurchaseOrdersLoading;

export const allPurchaseOrdersErrorSelector = (state: RootState) =>
  state.purchaseOrder.allPurchaseOrdersError;

export const allPurchaseOrdersLimitSelector = (state: RootState) =>
  state.purchaseOrder.allPurchaseOrdersLimit;

export const allPurchaseOrderAscSelector = (state: RootState) =>
  state.purchaseOrder.purchaseOrderAsc;

export const allPurchaseOrdersOffsetSelector = (state: RootState) =>
  state.purchaseOrder.allPurchaseOrdersOffset;

export const purchaseOrderItemsSelector = (state: RootState) =>
  state.purchaseOrder.purchaseOrderItems;

export const purchaseOrderDataByIdSelector = (state: RootState) =>
  state.purchaseOrder.purchaseOrderDataById;

export const purchaseOrderDataByIdLoadSelector = (state: RootState) =>
  state.purchaseOrder.purchaseOrderDataByIdLoad;

export const purchaseOrderDataItemsLoadSelector = (state: RootState) =>
  state.purchaseOrder.purchaseOrderItemsLoad;
