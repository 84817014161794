import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AxiosCacheInstance, buildMemoryStorage, setupCache } from 'axios-cache-interceptor';
import localforage from 'localforage';

import store from 'store';
import { getNewToken } from 'store/slices/authSlice/authThunks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TAuthHeader } from './types';

const baseURL = process.env.REACT_APP_BASE_URL;

const client: AxiosInstance = axios.create({
  baseURL,
});

client.interceptors.request.use((config: any) => {
  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  if (token) {
    config.headers = {
      ...config.headers,
      ...({
        Authorization: `Bearer ${JSON.parse(token)}`,
      } as TAuthHeader),
    };
  }
  return config;
});

client.interceptors.response.use((response: AxiosResponse) => {
  return response;
});

client.interceptors.response.use(null, async (error) => {
  const errorStatus = error.response.status === 403 || error.response.status === 401;
  if (error.response && errorStatus) {
    try {
      const response: any = await store.dispatch(getNewToken());

      const errorMessageExist =
        (response?.error && response?.error?.message?.includes('401')) ||
        response?.error?.message?.includes('500') ||
        response?.error?.message?.includes('400');
      response?.error?.message?.includes("could not connect to organization's resource");

      const inInvitedUsersPage = window.location.pathname?.includes('sign-up');

      if (errorMessageExist && !inInvitedUsersPage) {
        localStorage.clear();
        sessionStorage.clear();
        localforage.clear();
        window.location.href = '/';
      }

      const token =
        BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
        BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

      if (token) {
        const config = error.config;
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        return axios.request(config);
      }
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  }
  return Promise.reject(error);
});

const clientCache: AxiosCacheInstance = setupCache(
  axios.create({
    baseURL,
  }),
  {
    storage: buildMemoryStorage(true, 2000, 1000),
  },
);

clientCache.interceptors.request.use((config: any) => {
  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  if (token) {
    config.headers = {
      ...config.headers,
      ...({
        Authorization: `Bearer ${JSON.parse(token)}`,
      } as TAuthHeader),
    };
  }
  return config;
});

clientCache.interceptors.response.use((response: any) => {
  return response;
});

clientCache.interceptors.response.use(null, async (error) => {
  const errorStatus = error.response.status === 403 || error.response.status === 401;
  if (error.response && errorStatus) {
    try {
      await store.dispatch(getNewToken());

      const token =
        BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
        BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

      if (token) {
        const config = error.config;
        config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        return axios.request(config);
      }
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  }
  return Promise.reject(error);
});

export { client, clientCache };
