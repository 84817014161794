import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { CardElement } from '@stripe/react-stripe-js';

import { Colors, FontNames } from 'types';
import { stateOptions } from 'constants/States';
import { CustomTextField, ProcessFormTitle, Select } from 'components';

import FormLayout from '../FormLayout';
import NumberControlledInput from '../NumberControlledInput';

import styles from './Payment.module.scss';
import { TPaymentProps } from './types';

const Payment: FC<TPaymentProps> = ({ errors, control, handleCardValid }) => {
  const cardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },

      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <Box className={styles.container}>
      <ProcessFormTitle lightSubtitle title='Payment' />
      <Box className={styles.container__content}>
        <FormLayout title='Card Details' className={styles.container__content__form}>
          <Box className={styles.container__card}>
            <Controller
              name='name'
              defaultValue=''
              control={control}
              rules={{ required: 'Card holder name is required' }}
              render={({ field }) => (
                <CustomTextField
                  required
                  {...field}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  label='Card Holder Name'
                  placeholder='Card Holder Name:'
                  fontFamily={FontNames.CIRCULAR_REG}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
              )}
            />

            <Box className={styles.container__card__bottom}>
              <CardElement options={cardElementOptions} onChange={handleCardValid} />
            </Box>
          </Box>
        </FormLayout>
        <FormLayout title='Address'>
          <Box className={styles.container__address}>
            <Stack direction='column' width='100%' sx={{ marginBottom: '16px' }} gap={2}>
              <NumberControlledInput
                control={control}
                name='number'
                label='Phone Number'
                error={!!errors.number}
                placeholder='Phone Number:*'
                fontFamily={FontNames.CIRCULAR_REG}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                helperText={errors.number ? errors.number.message : ''}
              />

              <Controller
                name='address'
                control={control}
                defaultValue=''
                rules={{ required: 'Address is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Address'
                    placeholder='Address:'
                    error={!!errors.address}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Stack>
            <Stack direction='row' sx={{ gap: '20px', width: '100%' }}>
              <Controller
                name='state'
                control={control}
                defaultValue=''
                rules={{ required: 'State is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='State:*'
                    onChange={onChange}
                    options={stateOptions}
                    error={!!errors.state}
                    helperText={errors?.state?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />

              <Controller
                name='zip'
                control={control}
                defaultValue=''
                rules={{ required: 'Zip code is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Zip'
                    placeholder='Zip:*'
                    error={!!errors.zip}
                    helperText={errors?.zip?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Stack>
          </Box>
        </FormLayout>
      </Box>
    </Box>
  );
};

export default Payment;
