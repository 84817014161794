import type { RootState } from 'types';

export const allRequisitionsList = (state: RootState) => state.requisition.allRequisitions;

export const allRequisitionsLoadingSelector = (state: RootState) =>
  state.requisition.allRequisitionsLoading;

export const allRequisitionsErrorSelector = (state: RootState) =>
  state.requisition.allRequisitionsError;

export const allRequisitionsLimitSelector = (state: RootState) =>
  state.requisition.allRequisitionsLimit;

export const allRequisitionsAscSelector = (state: RootState) =>
  state.requisition.allRequisitionsAsc;

export const allRequisitionsOffsetSelector = (state: RootState) =>
  state.requisition.allRequisitionsOffset;

export const requisitionItemsSelector = (state: RootState) => state.requisition.requisitionItems;

export const requisitionItemsLoadingSelector = (state: RootState) =>
  state.requisition.requisitionItemsLoading;
