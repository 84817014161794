import { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';

import {
  Button,
  DeletePopup,
  ScrollLayout,
  PortalDropDown,
  CustomDataGrid,
  CustomTypography,
  CustomBreadcrumbs,
  DynamicPaginationControl,
  PurchaseOrderVendorDrawer,
} from 'components';
import { Colors, FontNames, Routes } from 'types';
import { MoreIcon, NewPlusIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setVendorPage, setVendorsLimit } from 'store/slices/inventorySlice';
import AddVendorDrawer from 'containers/Inventory/VendorList/AddVendor';
import { getVendorById, getVendorList, removeVendorById } from 'store/thunks';
import CreatePurchaserOrderDrawer from 'containers/PurchaseOrder/CreatePurchaserOrderDrawer';
import {
  vendorListAscSelector,
  vendorListLimitSelector,
  vendorListOffsetSelector,
  vendorListSelector,
} from 'store/slices/inventorySlice/selectors';
import { GetVendorListParamsSort, TVendor } from 'store/slices/inventorySlice/types';
import { EmptyTitles } from 'constants/EmptyTitles';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { PermissionMessages } from 'constants/PermissionMessages';

import styles from './Vendors.module.scss';
import { breadCrumbsOptions, columns } from './utils';
import { dropDownOptionsCreator } from './Vendors.utils';

const Vendors = () => {
  const dispatch = useAppDispatch();
  const { isAccessToInventoryEditor, isAccessToInventoryViewer } =
    useAppSelector(userPermissionsSelector);

  const haveAccessToInventories = isAccessToInventoryEditor || isAccessToInventoryViewer;

  const allVendors = useAppSelector(vendorListSelector);
  const allVendorsAsc = useAppSelector(vendorListAscSelector);
  const allVendorsLimit = useAppSelector(vendorListLimitSelector);
  const allVendorsOffset = useAppSelector(vendorListOffsetSelector);
  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const [page, setPage] = useState<number>(1);
  const [isCreatePo, setIsCreatePo] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<TVendor | null>(null);
  const [isVendorDrawerOpen, setIsVendorDrawerOpen] = useState<boolean>(false);

  const [newSortBy, setNewSortBy] = useState<GetVendorListParamsSort | null>(null);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(
      column.field === 'number'
        ? GetVendorListParamsSort.ID
        : (column.field as GetVendorListParamsSort),
    );

    const sortValue =
      column.field === 'number'
        ? GetVendorListParamsSort.ID
        : (column.field as GetVendorListParamsSort);

    dispatch(
      getVendorList({
        sort_by: sortValue || 'name',
        asc: allVendorsAsc,
        limit: allVendorsLimit,
        offset: allVendorsOffset,
      }),
    );
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted =
      column.field === 'number'
        ? newSortBy === GetVendorListParamsSort.ID
        : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !allVendorsAsc },
      {
        [styles.activeSortHeader_asc]: allVendorsAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted && column?.field ? headerClasses : '',
    };
  });

  const openDrawer = () => setIsOpenDrawer(true);

  const getSelectedItem = (item: TVendor) => setSelectedVendor(item);

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(setVendorPage(newPage + 1));
  };

  const handleEditVendor = async () => {
    await dispatch(getVendorById(selectedVendor?.id as number));
    setIsOpenEditDrawer(true);
  };

  const handleRemoveVendor = async () => {
    await dispatch(removeVendorById(selectedVendor?.id as number));
    setSelectedVendor(null);
    setIsPopupOpen(false);
  };

  const handleCreatePO = async () => {
    setIsCreatePo(true);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setVendorsLimit(event.target.value));
  };

  useEffect(() => {
    dispatch(getVendorList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVendorsLimit, allVendorsOffset]);

  const dropDownOptions = dropDownOptionsCreator({
    removeCallback: togglePopup,
    createPOCallback: handleCreatePO,
    editCallback: handleEditVendor,
  });

  const renderRows = allVendors?.data?.map((row) => {
    return {
      id: row.id,
      name: row?.name,
      number: `V-${row?.id}`,

      address: row.address,
      phone: row.phone,
      email: row.email,
      options: isAccessToInventoryEditor && (
        <PortalDropDown
          selectedItem={row}
          selectedId={row?.id}
          title={<MoreIcon />}
          options={dropDownOptions}
          getSelectedItem={getSelectedItem}
        />
      ),
    };
  });

  useEffect(() => {
    if (!allVendors?.total_count && page) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVendors?.total_count]);

  if (!haveAccessToInventories && !userAllRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container}>
        <CustomBreadcrumbs options={breadCrumbsOptions} />
        <Box className={styles.container__head}>
          <CustomTypography className={styles.container__head__title}>Vendors</CustomTypography>
          <Button
            color='white'
            fontSize='14px'
            maxWidth='137px'
            borderRadius='5px'
            padding='10px 6px'
            isUppercase={false}
            tooltipMessage={
              !isAccessToInventoryEditor ? PermissionMessages.VendorCreationMessage : undefined
            }
            onClick={openDrawer}
            backgroundColor={Colors.SAPPHIRE}
            disabled={!isAccessToInventoryEditor}
            fontFamily={FontNames.CIRCULAR_REG}
            startIcon={<NewPlusIcon width={24} height={24} />}
            className={styles.container__header__create__button}
          >
            Add Vendor
          </Button>
        </Box>

        <Box className={styles.container__main}>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={64}
              headerHeight={44}
              rows={renderRows}
              columns={renderColumns}
              emptyTitle={EmptyTitles.Vendors}
              onColumnHeaderClick={handleColumnHeaderClick}
            />
          </ScrollLayout>
        </Box>
        <Box className={styles.container__footer}>
          {!!allVendors?.data?.length && (
            <DynamicPaginationControl
              page={page}
              rowsPerPage={allVendorsLimit}
              count={allVendors?.total_count}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </Box>
      </Box>
      <AddVendorDrawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer} />
      <AddVendorDrawer isOpen={isOpenEditDrawer} setIsOpen={setIsOpenEditDrawer} inEditMode />

      <CreatePurchaserOrderDrawer
        isOpen={isCreatePo}
        existedSelectedVendorType={{
          id: selectedVendor?.id as number,
          name: selectedVendor?.name as string,
        }}
        onClose={() => setIsCreatePo(false)}
        openVendorDrawer={() => setIsVendorDrawerOpen(true)}
      />
      <PurchaseOrderVendorDrawer
        open={isVendorDrawerOpen}
        handleClose={() => setIsVendorDrawerOpen(false)}
      />
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        onDelete={handleRemoveVendor}
        body='Please confirm if you wants to delete the vendor.'
      />
    </>
  );
};

export default Vendors;
