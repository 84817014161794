import { useState, type FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { fillSelectedIds, setInventoryParts } from 'store/slices/optionsSlice';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import {
  createPurchaseOrder,
  updatePurchaseOrder,
  updatePurchaseOrderItem,
  getPurchaseOrderDataById,
} from 'store/slices/purchaseOrderSlice/thunks';
import {
  Button,
  DrawerLayout,
  ControlledInput,
  CustomTypography,
  PurchaseOrderItems,
} from 'components';
import { Colors, FontNames } from 'types';
import { purchaseOrderFormValuesGenerator } from 'utils';
import { TPurchaseOrderData } from 'store/slices/purchaseOrderSlice/types';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { purchaseOrderDataByIdSelector } from 'store/slices/purchaseOrderSlice/selectors';

import { sxDrawerStyles } from '../styles';
import { breakPoints } from '../../../constants';

import { schema } from './schema';
import SelectVendorDropDown from './SelectVendorDropDown';
import styles from './CreatePurchaserOrderDrawer.module.scss';

import type { TCreatePurchaseOrderProps, TSelectedVendorType, TSendedParts } from './types';

const CreatePurchaserOrderDrawer: FC<TCreatePurchaseOrderProps> = ({
  isOpen,
  onClose,
  inEditMode,
  inventoryData,
  selectedOrderId,
  requisitionItems,
  openVendorDrawer,
  isVendorListOpen,
  isFromRequisition,
  existedSelectedVendorType,
}) => {
  const dispatch = useAppDispatch();

  const { width } = useWindowSize();

  const currentOrder = useAppSelector(purchaseOrderDataByIdSelector);

  const [selectedVendor, setSelectedVendor] = useState<TSelectedVendorType>(
    existedSelectedVendorType || {
      id: null,
      name: '',
    },
  );

  const [descriptionValue, setDescriptionValue] = useState<string>('');

  useEffect(() => {
    if (existedSelectedVendorType) {
      setSelectedVendor(existedSelectedVendorType);
    }
    if (currentOrder?.vendor_id) {
      const existingVendor = { name: currentOrder.vendor_dtl.name, id: currentOrder.vendor_id };
      setSelectedVendor(existingVendor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existedSelectedVendorType, currentOrder?.vendor_id, inEditMode]);

  const editedItemsFromState = currentOrder?.items?.data?.map((item) => {
    return {
      id: item?.id as number,
      itemName: item.name as string,
      quantity: String(item?.quantity) || '0',
      price: String(item?.unit_price) || '0',
      description: descriptionValue || item.description || '',
    };
  });

  const itemsFromRequisition = requisitionItems?.map((el) => {
    return {
      id: el?.part_id as number,
      itemName: el.name as string,
      quantity: el?.quantity ? String(el?.quantity) : '0',
      price: el?.cost ? String(el?.cost) : '0',
      description: descriptionValue || el.description || '',
    };
  });

  const itemsFromInventory = inventoryData?.map((el) => ({
    id: el?.id as number,
    itemName: el.name,
    quantity: el?.qty ? String(el?.qty) : '0',
    price: el?.cost ? String(el?.cost) : '0',
    description: descriptionValue || el.description || '',
  }));

  const {
    reset,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid, errors, isDirty, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    values: purchaseOrderFormValuesGenerator({
      inEditForm:
        (isFromRequisition as boolean) || !!itemsFromInventory?.length || (inEditMode as boolean),
      currentOrder: currentOrder as TPurchaseOrderData,
      editedItems: itemsFromRequisition || itemsFromInventory || editedItemsFromState || [],
    }),
  });

  useEffect(() => {
    if (isFromRequisition || !!itemsFromInventory?.length || inEditMode) {
      setValue('description', currentOrder?.description as string);
      setValue('items', itemsFromRequisition || itemsFromInventory || editedItemsFromState || []);
    } else {
      setValue('description', '');
      setValue('items', []);
      setSelectedVendor({ name: '', id: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFromRequisition, itemsFromInventory?.length, inEditMode, isOpen]);

  const currentId = generateInventoryItemNumber(currentOrder?.id as number);

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    if (inEditMode && currentOrder) {
      const modifiedOrder = {
        id: currentOrder.id,
        status: currentOrder.status,
        description: data.description,
        vendor_id: selectedVendor.id as number,
      };

      data.items.forEach((item: TSendedParts) => {
        const sendedData = {
          id: item.id,
          unit_price: Number(item?.price),
          quantity: Number(item?.quantity),
          description: item.description as string,
        };

        if (sendedData) {
          dispatch(
            updatePurchaseOrderItem({
              options: sendedData,
              id: selectedOrderId as number,
              fromEditOrder: true,
            }),
          );
        }
      });

      const response = await dispatch(
        updatePurchaseOrder({ options: modifiedOrder, id: selectedOrderId as number }),
      );
      if (response?.meta?.requestStatus === 'fulfilled') {
        onClose();
        reset();
      }
    } else {
      const sendedItems = data?.items?.map((item: TSendedParts) => ({
        part_id: item?.id as number,
        quantity: Number(item?.quantity) || 0,
        unit_price: Number(item?.price) || 0,
        description: descriptionValue || item.description || '',
      }));

      const sendedData = {
        vendor_id: Number(selectedVendor?.id),
        description: data.description || '',
        items: sendedItems,
      };

      const response = await dispatch(createPurchaseOrder(sendedData));

      if (response?.meta?.requestStatus === 'fulfilled') {
        onDrawerCloseClear();
        reset();
        dispatch(fillSelectedIds([]));
        dispatch(setInventoryParts([]));

        setSelectedVendor({
          id: null,
          name: '',
        });
      }
    }

    reset();
  };

  const onDrawerClose = () => {
    onClose();
  };

  const onDrawerCloseClear = () => {
    onClose();
    reset({
      description: '',
      items: [],
    });

    !inEditMode &&
      setSelectedVendor({
        id: null,
        name: '',
      });
  };

  const sendedData = watch();

  const itemsFromWatch = sendedData?.items;

  const renderDisabled = selectedVendor?.id && isValid;
  const renderDisabledEdit = selectedVendor?.id && isValid && isDirty;

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 400
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 860;

  const sxStyles = isVendorListOpen ? sxDrawerStyles : null;

  useEffect(() => {
    selectedOrderId && inEditMode && dispatch(getPurchaseOrderDataById(selectedOrderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrderId, inEditMode]);

  return (
    <DrawerLayout
      open={isOpen}
      padding='40px'
      sx={sxStyles}
      inCenter={false}
      width={drawerWidth}
      onClose={isFromRequisition || itemsFromInventory?.length ? onDrawerCloseClear : onDrawerClose}
      onCloseReset={onDrawerCloseClear}
      titleClassName={styles.container__title}
      headerTitle={
        !inEditMode ? (
          'Create Purchasing Order'
        ) : (
          <CustomTypography className={styles.container__title__text}>
            Edit Purchasing Order{' '}
            <span className={styles.container__title__subtitle}>PO-{currentId}</span>
          </CustomTypography>
        )
      }
    >
      <form className={styles.container} onSubmit={handleSubmit(handleCreateData)}>
        <SelectVendorDropDown
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          openVendorNotListed={openVendorDrawer}
        />
        <CustomTypography className={styles.container__item}>Items</CustomTypography>
        <PurchaseOrderItems
          errors={errors}
          control={control}
          inEditMode={inEditMode}
          itemsFromWatch={itemsFromWatch}
          setDescriptionValue={setDescriptionValue}
        />

        <Box className={styles.container__form}>
          <ControlledInput
            rows={4.5}
            multiline
            control={control}
            borderRadius='5px'
            name='description'
            borderColor={Colors.SOFT_SILVER}
            padding='19.5px 16px'
            backgroundColor={Colors.LIGHT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            label='Purchasing Order Notes:(Optional)'
            placeholder='Purchasing Order Notes:(Optional)'
          />
          <Box className={styles.container__submit}>
            <Button
              color={Colors.SAPPHIRE}
              backgroundColor={Colors.PALE_BLUE}
              type='button'
              minWidth='71px'
              padding='11.5px 12px'
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color='white'
              type='submit'
              minWidth='65px'
              padding='11.5px 12px'
              backgroundColor={Colors.SAPPHIRE}
              disabled={(isFromRequisition ? !renderDisabled : !renderDisabledEdit) || isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </DrawerLayout>
  );
};
export default CreatePurchaserOrderDrawer;
