import { FC } from 'react';
import { Tooltip } from '@mui/material';

import { FontNames } from 'types';

import styles from './TableCell.module.scss';
import { TTableCellProps } from './types';

const TableCell: FC<TTableCellProps> = ({
  title,
  style,
  isTooltipDisabled = false,
  fontFamily = FontNames.CIRCULAR_LIGHT,
}) => (
  <Tooltip
    title={title}
    componentsProps={{
      tooltip: {
        sx: {
          maxHeight: '200px',
          overflowY: 'auto',
          paddingLeft: '3px',
          bgcolor: 'rgb(195, 195, 195)',
        },
      },
    }}
    style={{ background: 'red' }}
    disableFocusListener={title === '--' ? true : isTooltipDisabled}
    disableHoverListener={title === '--' ? true : isTooltipDisabled}
  >
    <div style={{ ...style, fontFamily }} className={styles.container}>
      {title}
    </div>
  </Tooltip>
);

export default TableCell;
