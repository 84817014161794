import { Box } from '@mui/material';
import { FC } from 'react';

import { CustomSkeleton } from 'components';
import FileUploadController from 'components/views/FileUpload';

import SectionHead from '../../SectionHead';

import styles from './Templates.module.scss';

import type { TTemplatesProps } from './types';

const Templates: FC<TTemplatesProps> = ({
  isLoading = false,
  control,
  getAgreementFile,
  getEstimateFile,
}) => (
  <Box className={styles.container}>
    <SectionHead
      title='Templates:'
      subtitle='(These templates are used while creating estimates and agreements for customers.)'
    />
    <Box className={styles.container__content}>
      {isLoading ? (
        <>
          <CustomSkeleton width='100%' height={269} />
          <CustomSkeleton width='100%' height={269} />
        </>
      ) : (
        <>
          <FileUploadController
            control={control}
            title='Choose estimate template'
            name='templates[0].media_url_id'
            getFile={getEstimateFile}
            htmlFor='estimate'
          />
          <FileUploadController
            control={control}
            name='templates[1].media_url_id'
            title='Choose agreement template'
            getFile={getAgreementFile}
            htmlFor='agreement'
          />
        </>
      )}
    </Box>
  </Box>
);

export default Templates;
