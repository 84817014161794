import { TTabNavigatorItem } from 'components/views/TabNavigator/types';

export type RoutesProps = {
  path: string;
  isProtected: boolean;
  component: JSX.Element;
} & Partial<{
  withNavbar: boolean;
  isLanding: boolean;
  withLogoHeader: boolean;
  isProtectedWithSession: boolean;
  tabNavigatorTitle: string;
  withTabNavigator: boolean;
  tabNavigatorOptions: TTabNavigatorItem[];
}>;

export enum Routes {
  Error = '*',
  Landing = '/',
  Home = '/home',
  Team = '/team',
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  Profile = '/profile',
  Reports = '/reports',
  Privacy = '/privacy',
  Pricing = '/pricing',
  AboutUs = '/about-us',
  Customer = '/customer',
  RFQ = '/inventory/rfq',
  WorkFlow = '/work-flow',
  Documents = '/documents',
  ContactUs = '/contact-us',
  Scheduling = '/scheduling',
  MyFavorite = '/my-favorite',
  LiveStream = '/live-stream',
  Invitations = '/invitations',
  Permissions = '/permissions',
  AccountSettings = '/settings',
  ProcessForm = '/process-form',
  InviteUsers = '/invite-users',
  ProductInfo = '/product-info',
  Invoice = '/work-flow/invoice',
  ActivityFeed = '/activity-feed',
  Catalog = '/product-info/catalog',
  Agreement = '/work-flow/agreement',
  WorkOrder = '/work-flow/work-order',
  SignUpSuccessPage = '/signup-success',
  DocumentsItem = '/documents/:name/:id',
  LiveStreamJoin = '/live-stream/join',
  Inventory = '/product-info/inventory',
  BomEstimate = '/work-flow/bom-estimate',
  BomPricingEstimate = '/work-flow/bom-pricing-estimate',
  OrganizationsList = '/organizations-list',
  LiveStreamStart = '/live-stream/start/:id',
  EmployeeManagement = '/employee-management',
  Vendors = '/product-info/inventory/vendors',
  LiveStreamDetails = '/live-stream/details/:id',
  ViewWorkOrder = '/work-flow/work-order/view/:id',
  EditWorkOrder = '/work-flow/work-order/edit/:id',
  CreateWorkOrder = '/work-flow/work-order/create',
  Transactions = '/work-flow/invoice/transactions',
  CatalogSectionItems = '/product-info/catalog/:id',
  Requisition = '/product-info/inventory/requisition',
  ExcelTemplate = '/product-info/inventory/excel-template',
  EstimateViaTemplate = '/product-info/inventory/requisition/estimate-via-template',
  EstimateTemplateForm = '/work-flow/estimate-via-template/form',
  UpdateEstimateTemplateForm = '/work-flow/update-estimate/form/:id',
  UpdateEstimateCostTemplateForm = '/work-flow/update-estimate/form/cost-margin/:id',
  EstimateView = '/work-flow/estimate-from-template/estimate-view/:id',
  EstimateItem = '/work-flow/bom-estimate/estimate/:id',
  DocumentsItemSection = '/documents/:name/:id/:section',
  PurchaseOrder = '/product-info/inventory/purchase-order',
  CreateRequisition = '/product-info/inventory/requisition/create',
  PurchaseOrderItems = '/product-info/inventory/purchase-order/:id',
  PurchaseRequisitionItem = '/product-info/inventory/requisition/:id',
  EstimateItemGenerate = '/work-flow/bom-estimate/estimate/:id/generate',
}
