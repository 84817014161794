/* eslint-disable @typescript-eslint/no-unused-vars */
// Todo from Pavan
import { useEffect, type FC, useState, BaseSyntheticEvent, useRef, ChangeEvent } from 'react';
import { noop } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, MenuItem } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { AxiosError } from 'axios';

import { setLimit, setPage } from 'store/slices/estimatesSlice';
import { useAppDispatch, useAppSelector, useRefreshClearState } from 'hooks';
import {
  allEstimatesSelector,
  allEstimatesFromTemplateLimitSelector,
  allEstimatesFromTemplateOffsetSelector,
} from 'store/slices/estimatesSlice/selectors';
import { DownloadSquareIcon, EditIconOutLined, TrashIcon } from 'assets/icons';
import { SortByEnums } from 'store/slices/estimatesSlice/types';
import {
  deleteEstimate,
  getEstimatesFromTemplates,
  sendEstimateToCustomer,
} from 'store/slices/estimatesSlice/thunks';
import { sendAgreementToCustomer } from 'store/thunks';
import DeletePopup from 'components/views/DeletePopup';
import ShareCatalogDropDown from 'components/dropdowns/ShareCatalogDropDown';
import {
  ScrollLayout,
  ShowAfterAccessView,
  CustomDataGrid,
  DynamicPaginationControl,
} from 'components';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';

import { estimateColumns, estimateVersionColumns } from './utils';
import BomEstimateHeader from './BomEstimateHeader';
import { sxMenuShareStyles, sxMenuStyles } from './styles';
import styles from './BomEstimate.module.scss';

const BomEstimateviatemplate: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement | null>(null);

  const estimateList = useAppSelector(allEstimatesSelector);
  const estimateLimit = useAppSelector(allEstimatesFromTemplateLimitSelector);

  const estimateOffset = useAppSelector(allEstimatesFromTemplateOffsetSelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [removedUsersIds, setRemovedUsersIds] = useState<number[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [newSortBy, setNewSortBy] = useState<SortByEnums | null>(null);
  const [mypayload, setmyPayload] = useState([]);
  const [totalRows, settotalRows] = useState<number>(0);
  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field === 'number' ? SortByEnums.ID : (column.field as SortByEnums));

    dispatch(
      getEstimatesFromTemplates({
        limit: estimateLimit,
        offset: estimateOffset,
      }),
    );
  };
  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(8) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  useRefreshClearState();

  const fetchData = async () => {
    try {
      const response = await dispatch(
        getEstimatesFromTemplates({
          limit: estimateLimit,
          offset: estimateOffset,
        }),
      );
      if (response.payload !== null) {
        const jsondata = await response.payload;
        if (jsondata.data) {
          const dataList = jsondata.data.map((element: { [x: string]: any }) => ({
            Created: element['created_on'],
            'Site Name': element['GENERAL INFO']['Site Name'],
            'Site Address': element['GENERAL INFO']['Site Address'],
            'Site Type': element['BUILDING CONSTRUCTION']['Site Type'],
            'Close Probability': element['GENERAL INFO']['Close Probability'],
            'Close Date': element['GENERAL INFO']['Close Date'],
            'Operate Date': element['GENERAL INFO']['Operate Date'],
            Version: element['estimate_version_tag'],
            uuid: element['uuid'],
          }));
          settotalRows(jsondata.total_count);
          setmyPayload(dataList);
        }
      }
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateLimit, estimateOffset]);

  const goToRowPage = (id: string) => navigate(`/work-flow/bom-estimate/estimate/${id}`);

  const handleEvent: GridEventListener<'rowClick'> = async (params) => {
    navigate(`/work-flow/estimate-from-template/estimate-view/${params?.row?.uuid}`);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  const onDelete = async () => {
    await dispatch(deleteEstimate(selectedItemId as number));
    togglePopup();
  };

  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const renderColumns: GridColDef[] = estimateColumns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === SortByEnums.ID : newSortBy === column.field;

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? styles.activeSortHeader : '',
    };
  });

  const renderVersionColumns: GridColDef[] = estimateVersionColumns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === SortByEnums.ID : newSortBy === column.field;

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? styles.activeSortHeader : '',
    };
  });

  const renderRows = mypayload?.map((item, index) => {
    return {
      id: index,
      uuid: item['uuid'],
      Created: item['Created'],
      site_name: item['Site Name'],
      site_address: item['Site Address'],
      site_type: item['Site Type'],
      close_probability: item['Close Probability'],
      close_date: item['Close Date'],
      operate_date: item['Operate Date'],
      version: item['Version'],
    };
  });

  const publishEstimate = () => {
    selectedItemId && dispatch(sendEstimateToCustomer(selectedItemId));
  };

  const publishAgreement = () => {
    selectedItemId && dispatch(sendAgreementToCustomer(selectedItemId));
  };

  const dropdownItems = [
    {
      icon: <EditIconOutLined />,
      label: 'Edit',
      paramsAction: (id: number) => {
        const selectedItem = estimateList?.data?.find((el) => el.id === id);
        goToRowPage(selectedItem?.uuid as string);
      },
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: () => togglePopup(),
    },
    {
      label: 'Download Agreement',
      icon: <DownloadSquareIcon />,
      paramsAction: noop,
    },
    {
      label: 'Send Agr To Customer',
      icon: <DownloadSquareIcon style={{ transform: 'rotate(90deg)' }} />,
      paramsAction: publishAgreement,
    },
    {
      label: 'Send Est To Customer',
      icon: <DownloadSquareIcon style={{ transform: 'rotate(90deg)' }} />,
      paramsAction: () => publishEstimate(),
    },
  ];

  const getDropdownItems = () => {
    const items = dropdownItems.filter((item) => {
      if (item.label !== 'Download Agreement') {
        return !isViewer;
      }

      return true;
    });

    return items;
  };

  const renderMenuItem = getDropdownItems().map((item, idx) => {
    const onClick = () => {
      selectedItemId && item?.paramsAction(selectedItemId);
      handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderMenuClasses = isShareOpen ? sxMenuShareStyles : sxMenuStyles;
  const [currentPage, setCurrentPage] = useState<number>(0);

  return (
    <Box className={styles.container}>
      <BomEstimateHeader totalRows={totalRows} />
      <ShowAfterAccessView emptyMessageTitle='Estimates' isShow={true}>
        <Box className={styles.container__grid}>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={76}
              headerHeight={52}
              // @todo  checkboxSelection
              // @todo  rowSelection={true}
              rows={renderRows}
              isAllSelection={false}
              columns={renderColumns}
              onRowClick={handleEvent}
              // @todo checkboxComponent={CheckboxItem}
              emptyTitle={EmptyTitles.Estimates}
              onColumnHeaderClick={handleColumnHeaderClick}
            />
          </ScrollLayout>
          {totalRows !== 0 && (
            <Box padding='20px 0'>
              <DynamicPaginationControl
                count={totalRows}
                page={currentPage}
                rowsPerPage={estimateLimit}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          )}
        </Box>
        <Menu
          ref={menuRef}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={renderMenuClasses}
        >
          {isShareOpen ? (
            <ShareCatalogDropDown
              onClose={handleClose}
              setIsOpen={setIsShareOpen}
              removedUsersIds={removedUsersIds}
              sharedId={Number(selectedItemId)}
              setRemovedUsersIds={setRemovedUsersIds}
            />
          ) : (
            renderMenuItem
          )}
        </Menu>
      </ShowAfterAccessView>

      <DeletePopup
        withBody
        onDelete={onDelete}
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        body='This will delete the estimate. Please confirm.'
      />
    </Box>
  );
};

export default BomEstimateviatemplate;
