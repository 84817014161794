import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  customer: yup.object().shape({
    id: yup.number().required('The customer must be selected from the list or create new'),
    value: yup.string().trim().required('Name is required'),
    label: yup.string().trim().required('Name is required'),
  }),
});
