const breakPoints = {
  WIDE: 1200,
  MOBILE: 475,
  TABLET: 768,
  WIDE_L: 1440,
  WIDE_XL: 1300,
  TABLET_M: 576,
  DESKTOP: 1024,
  TABLET_L: 992,
  MOBILE_M: 375,
  LARGE_DESKTOP: 1520,
};

export default breakPoints;
