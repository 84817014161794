import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, IconButton } from '@mui/material';

import { BorderSquareIcon } from 'assets/icons';
import CustomDrawer from 'components/views/Drawer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TPaymentMethod } from 'types/global/paymentMethods';
import { updateBillingDetailsFormValuesGenerator } from 'utils';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { canadaStates, countries, stateOptions } from 'constants/States';
import { updateBillingDetails } from 'store/slices/subscriptionSlice/thunks';
import { Button, CustomTextField, CustomTypography, Select } from 'components/shared';
import { TUpdateBillingDetails } from 'store/slices/subscriptionSlice/types';
import { currentPaymentMethodSelector } from 'store/slices/subscriptionSlice/selectors';
import { Colors, FontNames, type TChangeSelectEvent, type TFormSubmit } from 'types';

import { TUpdateBillingDetailsData, TUpdateBillingDetailsProps } from './types';
import styles from './UpdateBillingDetails.module.scss';

const UpdateBillingDetails: FC<TUpdateBillingDetailsProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(userDataSelector);
  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const currentPaymentMethod = useAppSelector(currentPaymentMethodSelector);

  const [activeCountryValue, setActiveCountryValue] = useState<string>('');

  const activeState =
    activeCountryValue === 'US' || currentPaymentMethod?.card?.country === 'US'
      ? stateOptions
      : canadaStates;

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isDirty },
    watch,
  } = useForm<TUpdateBillingDetailsData>({
    values: updateBillingDetailsFormValuesGenerator(currentPaymentMethod as TPaymentMethod),
  });

  const watchResult = watch();

  const { country } = watchResult;

  const handleSubmitForm: TFormSubmit = async (data) => {
    const sendedOptions: TUpdateBillingDetails = {
      org_id: Number(currentOrganization),
      payment_method_id: currentPaymentMethod?.id as string,
      name: data.name,
      email: userInfo?.email as string,
      phone: userInfo?.mobile_number as string,
      address: {
        city: data.townAndCity as string,
        country: data?.country,
        line1: data.address as string,
        postal_code: data.postalCode,
        state: data.state,
      },
    };

    await dispatch(updateBillingDetails(sendedOptions));

    handleClose();
  };

  return (
    <CustomDrawer footer={false} anchor='right' open={open} onClose={handleClose} update>
      <form className={styles.container} onSubmit={handleSubmit(handleSubmitForm)}>
        <Box className={styles.container__head}>
          <CustomTypography className={styles.container__head__title}>
            Update Billing Details
          </CustomTypography>
          <IconButton
            color='inherit'
            aria-label='close'
            onClick={handleClose}
            className={styles.container__head__close_btn}
          >
            <BorderSquareIcon width={24} height={24} />
          </IconButton>
        </Box>
        <Controller
          name='name'
          control={control}
          rules={{ required: 'Full name is required' }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              fontFamily={FontNames.CIRCULAR_REG}
              label='Full Name:'
              placeholder='Full Name:'
              error={!!errors.name}
            />
          )}
        />
        <Controller
          name='address'
          control={control}
          rules={{ required: 'Street Address is required' }}
          render={({ field }) => (
            <CustomTextField
              {...field}
              required
              fontFamily={FontNames.CIRCULAR_REG}
              label='Street Address:'
              placeholder='Street Address:'
              error={!!errors.address}
            />
          )}
        />
        <Box className={styles.container__flex}>
          <Controller
            name='country'
            control={control}
            rules={{ required: 'Country is required' }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                showLabel
                required
                fontFamily={FontNames.CIRCULAR_REG}
                label='Country*'
                options={countries}
                multiple={false}
                onChange={(event: TChangeSelectEvent) => {
                  onChange(event.target.value);
                  setActiveCountryValue(event.target.value);
                }}
                error={!!errors.country}
              />
            )}
          />
          <Controller
            name='state'
            control={control}
            defaultValue=''
            rules={{ required: 'State is required' }}
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                showLabel
                fontFamily={FontNames.CIRCULAR_REG}
                required
                disabled={!country}
                options={activeState}
                label='State*'
                multiple={false}
                onChange={onChange}
                defaultValue='State*'
                error={!!errors.state}
              />
            )}
          />
        </Box>
        <Box className={styles.container__flex}>
          <Controller
            name='townAndCity'
            control={control}
            rules={{ required: 'Town/City is required' }}
            render={({ field }) => (
              <CustomTextField
                fontFamily={FontNames.CIRCULAR_REG}
                required
                {...field}
                label='Town/City:'
                placeholder='Town/City:'
              />
            )}
          />
          <Controller
            name='postalCode'
            control={control}
            rules={{ required: 'Post Code is required' }}
            render={({ field }) => (
              <CustomTextField
                {...field}
                required
                label='Post Code'
                placeholder='Post Code:'
                error={!!errors.postalCode}
                fontFamily={FontNames.CIRCULAR_REG}
              />
            )}
          />
        </Box>
        <CustomTypography className={styles.container__required}>
          *This Field is Mandatory
        </CustomTypography>
        <Box className={styles.container__footer}>
          <Button
            onClick={handleClose}
            padding='11px'
            type='button'
            backgroundColor={Colors.PALE_BLUE}
          >
            CANCEL
          </Button>
          <Button
            type='submit'
            padding='11px'
            color={Colors.WHITE}
            backgroundColor={Colors.SAPPHIRE}
            disabled={!isDirty || isSubmitting}
          >
            UPDATE
          </Button>
        </Box>
      </form>
    </CustomDrawer>
  );
};

export default UpdateBillingDetails;
