import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const invoicesColumns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'Invoice Id',
    flex: 1,
    editable: false,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#2067DD' }} title={params?.value} />;
    },
  },
  {
    field: 'customer_name',
    headerName: 'Customer',
    flex: 0.9,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },

  {
    field: 'estimate_name',
    headerName: 'Estimate Name',
    flex: 1.2,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'issue_date',
    headerName: 'Invoice Date',
    flex: 1.1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'due_date',
    headerName: 'Due Date',
    flex: 0.85,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'total_amount',
    headerName: 'Invoice Amount',
    flex: 1.3,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 0.9,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle fromInvoice style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return params.value;
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 40,
    renderCell: (params) => {
      return params.value;
    },
  },
];
