import { FC, Fragment, useState } from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Hamburger from 'hamburger-react';

import { useAppSelector } from 'hooks';
import { CustomTypography } from 'components';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';

import styles from './TabNavigator.module.scss';

import type { TTabNavigatorProps } from './types';

const TabNavigator: FC<TTabNavigatorProps> = ({ options, tabNavigatorTitle }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer = !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const navigatorItems = options?.map((option) => {
    return (
      <Fragment key={option?.id}>
        {option.id === 3 && isViewer ? null : (
          <NavLink
            onClick={() => setIsExpanded(false)}
            to={option?.goTo}
            className={({ isActive }) =>
              classNames(styles.container__item, {
                [styles.container__item_active]: isActive,
              })
            }
          >
            <p>{option?.label}</p>
          </NavLink>
        )}
      </Fragment>
    );
  });

  const openMenuItems = () => setIsExpanded(!isExpanded);

  const menuClasses = classNames(styles.container__content__menu, {
    [styles.container__content__menu_active]: isExpanded,
  });

  return (
    <Box className={styles.container}>
      <CustomTypography className={styles.container__title}>{tabNavigatorTitle}</CustomTypography>
      <Box className={styles.container__content}>
        <Box className={styles.container__content__burger}>
          <Hamburger toggled={isExpanded} size={18} onToggle={openMenuItems} distance='sm' />
        </Box>
        <Box className={menuClasses}>{navigatorItems}</Box>
      </Box>
    </Box>
  );
};

export default TabNavigator;
