import { Box, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { v4 } from 'uuid';

import CustomDataGrid from 'components/views/CustomDataGrid';
import ControlledInput from 'components/views/ControlledInput';
import {
  Button,
  TextFrame,
  ScrollLayout,
  PortalDropDown,
  CustomSkeleton,
  TableControlledRowItem,
} from 'components';
import { Colors, FontNames } from 'types';
import { FilledPlusIcon, MoreIcon, TrashIcon } from 'assets/icons';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { EmptyTitles } from 'constants/EmptyTitles';

import SectionHead from '../../SectionHead';
import { discountPercentageOptions } from '../../../Discount/utils';

import { paymentNameValues } from './utils';
import { TPaymentScheduleProps } from './types';
import styles from './PaymentSchedule.module.scss';
import { newAddedSectionOptions } from './PaymentSchedule.utils';

const PaymentSchedule: FC<TPaymentScheduleProps> = ({
  control,
  errors,
  getValues,
  isLoading = false,
}) => {
  const { append, remove } = useFieldArray({
    control,
    name: 'payment_schedule.schedule.stages',
  });

  const getIndexByRow = (arr: any[], id: string | number) => {
    return arr ? arr.findIndex((r) => r.id === id) : -1;
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('payment_schedule.schedule.stages'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            required
            placeholder='Name'
            control={control}
            isExisted={false}
            componentType='select'
            options={paymentNameValues}
            name={`payment_schedule.schedule.stages.${[index]}.name`}
          />
        );
      },
    },

    {
      field: 'workCompletion',
      headerName: 'Work Completion',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('payment_schedule.schedule.stages'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            required
            placeholder='Name'
            control={control}
            isExisted={false}
            componentType='select'
            options={discountPercentageOptions}
            name={`payment_schedule.schedule.stages.${[index]}.percentage_complete`}
          />
        );
      },
    },
    {
      field: 'paymentPercentage',
      headerName: 'Payment Percentage',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('payment_schedule.schedule.stages'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            required
            placeholder='Name'
            control={control}
            isExisted={false}
            componentType='select'
            options={discountPercentageOptions}
            name={`payment_schedule.schedule.stages.${[index]}.percentage_amount`}
          />
        );
      },
    },

    {
      field: 'options',
      headerName: '',
      width: 80,
      renderCell: (params) => {
        const index = getIndexByRow(getValues('payment_schedule.schedule.stages'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <PortalDropDown
            title={<MoreIcon />}
            options={[
              {
                id: 1,
                name: 'Delete',
                action: () => remove(index),
                icon: <TrashIcon />,
              },
            ]}
          />
        );
      },
    },
  ];

  const cloned = getValues('payment_schedule.schedule.stages')?.map((item) => ({
    id: item?.id,
    options: item?.name,
    name: item?.name,
    workCompletion: item?.percentage_complete,
  }));

  const renderColumns: GridColDef[] = columns?.map((column) => ({
    ...column,
    hideSortIcons: true,
    sortable: false,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const textFieldSkeletons = (
    <Stack direction='row' gap='24px' marginBottom='12px'>
      <CustomSkeleton height={61} width='100%' borderRadius='4px' />
      <CustomSkeleton height={61} width='100%' borderRadius='4px' />
    </Stack>
  );

  return (
    <Box className={styles.container}>
      <SectionHead
        title='Payment Schedule:'
        subtitle='(This payment schedule is used by linked estimates to determine the invoice schedule.)'
      />
      {isLoading ? (
        textFieldSkeletons
      ) : (
        <Box className={styles.container__content}>
          <ControlledInput
            padding='19px'
            required
            control={control}
            labelColor={Colors.BLACK}
            placeholder='Estimate Duration:'
            fontFamily={FontNames.CIRCULAR_REG}
            name={'payment_schedule.estimated_duration'}
            error={!!errors.payment_schedule?.estimated_duration}
            helperText={errors.payment_schedule?.estimated_duration?.message}
          />

          <TextFrame
            width='100%'
            content='days'
            borderRadius='4px'
            textFontSize='16px'
            textColor={Colors.BLACK}
            padding='21.5px 19.5px 19px 21.5px'
            fontFamily={FontNames.CIRCULAR_REG}
            backgroundColor={Colors.PLATINUM_GRAY}
          />
        </Box>
      )}
      {isLoading ? (
        <CustomSkeleton height={61} width='100%' borderRadius='4px' />
      ) : (
        <ScrollLayout>
          <CustomDataGrid
            columns={renderColumns}
            rows={cloned || []}
            getRowId={(row) => row?.id}
            emptyTitle={EmptyTitles.Catalog_Payment_Schedules}
          />
        </ScrollLayout>
      )}

      <Box className={styles.container__footer}>
        <Button
          color={Colors.BLACK}
          isUppercase={false}
          startIcon={<FilledPlusIcon />}
          fontFamily={FontNames.CIRCULAR_REG}
          className={styles.container__head__add}
          onClick={() => append({ ...newAddedSectionOptions, id: v4() })}
        >
          Add New Payment
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSchedule;
