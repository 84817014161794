import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import {
  getWorkOrderById,
  getAllWorkOrders,
  getAllTasksById,
  getAllTasksByIdForUpdate,
  getTaskById,
} from './thunks';

import type { TInitialState } from './types';

const initialState: TInitialState = {
  allWorkOrdersList: dataStructure,
  allWorkOrdersAsc: true,
  allWorkOrdersLimit: 5,
  allWorkOrdersOffset: 0,
  allWorkOrdersLoading: false,
  allWorkOrdersError: null,
  currentWorkOrderById: null,
  currentWorkOrderByIdLoading: false,
  currentWorkOrderByIdError: null,
  currentTaskById: null,
  currentTaskByIdError: null,
  currentTaskByIdLoading: false,
  allTasksList: dataStructure,
  allTasksAsc: true,
  allTasksLimit: 5,
  allTasksOffset: 0,
  allTasksLoading: false,
  allTasksError: null,
  allTasksListForUpdate: dataStructure,
  allTasksErrorForUpdate: null,
  allTasksLoadingForUpdate: false,
};

const workOrderSlice = createSlice({
  name: 'workOrderSlice',
  initialState,
  reducers: {
    setPage(state, action) {
      state.allWorkOrdersOffset = (action.payload - 1) * state.allWorkOrdersLimit;
    },
    setLimit(state, action) {
      state.allWorkOrdersLimit = action.payload;
    },
    setTasksPage(state, action) {
      state.allTasksOffset = (action.payload - 1) * state.allTasksLimit;
    },
    setTasksLimit(state, action) {
      state.allTasksLimit = action.payload;
    },
    setWorkOrderPage(state, action) {
      state.allWorkOrdersOffset = (action.payload - 1) * state.allWorkOrdersLimit;
    },
    setWorkOrderLimit(state, action) {
      state.allWorkOrdersLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkOrders.pending, (state) => {
        state.allWorkOrdersLoading = true;
        state.allWorkOrdersError = null;
      })
      .addCase(getAllWorkOrders.fulfilled, (state, action) => {
        state.allWorkOrdersList = action.payload;
        state.allWorkOrdersLoading = false;
        state.allWorkOrdersError = null;
      })
      .addCase(getAllWorkOrders.rejected, (state, action) => {
        state.allWorkOrdersLoading = false;
        state.allWorkOrdersError = action.error.message as string;
      })
      .addCase(getWorkOrderById.pending, (state) => {
        state.currentWorkOrderByIdLoading = true;
        state.currentWorkOrderById = null;
        state.currentWorkOrderByIdError = null;
      })
      .addCase(getWorkOrderById.fulfilled, (state, action) => {
        state.currentWorkOrderById = action.payload;
        state.currentWorkOrderByIdLoading = false;
        state.currentWorkOrderByIdError = null;
      })
      .addCase(getWorkOrderById.rejected, (state, action) => {
        state.currentWorkOrderByIdLoading = false;
        state.currentWorkOrderByIdError = action.error.message as string;
      })
      .addCase(getAllTasksById.pending, (state) => {
        state.allTasksLoading = true;
        state.allTasksError = null;
      })
      .addCase(getAllTasksById.fulfilled, (state, action) => {
        state.allTasksList = action.payload;
        state.allTasksLoading = false;
        state.allTasksError = null;
      })
      .addCase(getAllTasksById.rejected, (state, action) => {
        state.allTasksLoading = false;
        state.allTasksError = action.error.message as string;
      })
      .addCase(getAllTasksByIdForUpdate.pending, (state) => {
        state.allTasksLoadingForUpdate = true;
        state.allTasksErrorForUpdate = null;
      })
      .addCase(getAllTasksByIdForUpdate.fulfilled, (state, action) => {
        state.allTasksListForUpdate = action.payload;
        state.allTasksLoadingForUpdate = false;
        state.allTasksErrorForUpdate = null;
      })
      .addCase(getAllTasksByIdForUpdate.rejected, (state, action) => {
        state.allTasksLoadingForUpdate = false;
        state.allTasksErrorForUpdate = action.error.message as string;
      })
      .addCase(getTaskById.pending, (state) => {
        state.currentTaskByIdLoading = true;
        state.currentTaskById = null;
        state.currentTaskByIdError = null;
      })
      .addCase(getTaskById.fulfilled, (state, action) => {
        state.currentTaskById = action.payload;
        state.currentTaskByIdLoading = false;
        state.currentTaskByIdError = null;
      })
      .addCase(getTaskById.rejected, (state, action) => {
        state.currentTaskByIdLoading = false;
        state.currentTaskByIdError = action.error.message as string;
      });
  },
});

export default workOrderSlice.reducer;

export const {
  setPage,
  setLimit,
  setTasksLimit,
  setTasksPage,
  setWorkOrderPage,
  setWorkOrderLimit,
} = workOrderSlice.actions;
