import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { DocumentsIcon } from 'assets/icons';
import breakPoints from 'constants/BreakPoints';
import { excelCreateFormValuesGenerator } from 'utils/formsInitialValues';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { updateVendor, createTemplate } from 'store/slices/inventorySlice/thunks';
import { currentExcelByIdSelector } from 'store/slices/inventorySlice/selectors';
import {
  Button,
  ControlledInput,
  ControlledSelect,
  DrawerLayout,
  NumberControlledInput,
  CompanyExcelFileInput,
} from 'components';
import { Colors, FontNames } from 'types';
import { stateOptions } from 'constants/States';

import styles from './AddVendorDrawer.module.scss';
import { inputConfigurations } from './AddVendor.utils';

export type FieldValues = Record<string, any>;
import type { TAddVendorDrawerProps, TVendorFormValues } from './types';
import type { TVendorUpdate } from 'store/slices/inventorySlice/types';

const AddExcelDrawer: FC<TAddVendorDrawerProps> = ({
  isOpen,
  currentImageSrc,
  getImgSrc,
  setIsOpen,
  inEditMode = false,
}) => {
  const dispatch = useAppDispatch();

  const { width } = useWindowSize();
  const [templateFile, setTemplateFile] = useState<File | null>(null);
  const currentExcel = useAppSelector(currentExcelByIdSelector);
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: excelCreateFormValuesGenerator({ inEditMode, currentExcel }),
    mode: 'all',
  });

  const [, setIsImageRemoved] = useState<boolean>(false);

  const getImageRemovedState = (isRemoved: boolean) => setIsImageRemoved(isRemoved);

  const drawerTitle = inEditMode ? 'Edit Template' : ' Add Template';

  const handleClose = () => setIsOpen(false);

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    // const { mixedUpFormData } = checkDataChanges(data, excelCreateFormValuesGenerator({ inEditMode, currentExcel }));
    // const newCreatedData = createFormData(mixedUpFormData);
    const formData = new FormData();
    formData.append('name', data?.name || '');
    formData.append('version_tag', data.version_tag || '');
    formData.append('description', data.description || '');

    if (templateFile) {
      formData.append('ExcelTemplate', templateFile);
    }

    if (!inEditMode) {
      const response = await dispatch(createTemplate(formData));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    } else {
      const sendedParams = {
        ...data,
        id: currentExcel?.id as number,
        vendor_link: data.vendor_link.trim(),
      };

      const response = await dispatch(updateVendor(sendedParams as TVendorUpdate));

      if (response?.meta?.requestStatus === 'fulfilled') {
        handleClose();
        reset();
      }
    }
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset();
    handleClose();
  };

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 475
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 623;

  const inputFields = inputConfigurations?.map(
    ({ type, id, fontFamily, label, name, placeholder, borderRadius, required }) => {
      if (type === 'number') {
        return (
          <div key={id} className={styles.container__content__item}>
            <NumberControlledInput
              name={name}
              label={label}
              control={control}
              required={required}
              borderColor='#EDEFF1'
              fontFamily={fontFamily}
              placeholder={placeholder}
              borderRadius={borderRadius}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              error={errors[name as keyof TVendorFormValues]}
              helperText={errors[name as keyof TVendorFormValues]?.message}
            />
          </div>
        );
      } else if (type === 'select') {
        return (
          <div key={id} className={styles.container__content__item}>
            <ControlledSelect
              name={name}
              label={label}
              defaultValue=''
              control={control}
              required={false}
              options={stateOptions}
              fontFamily={fontFamily}
              border='1px solid #EDEFF1'
              borderRadius={borderRadius}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              errors={!!errors[name as keyof TVendorFormValues]}
            />
          </div>
        );
        // } else if (type === 'select') {
        //   <div key={id} className={styles.container__content__item}>
        //   <ExcelFileUploadController
        //   control={control}
        //   name='templates[0].media_url_id'
        //   getFile={getEstimateFile}
        //   htmlFor='estimate'
        // />
        // </div>
      } else {
        return (
          <div key={id} className={styles.container__content__item}>
            <ControlledInput
              name={name}
              label={label}
              control={control}
              required={required}
              borderColor='#EDEFF1'
              fontFamily={fontFamily}
              placeholder={placeholder}
              borderRadius={borderRadius}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              error={errors[name as keyof TVendorFormValues]}
              helperText={errors[name as keyof TVendorFormValues]?.message}
            />
          </div>
        );
      }
    },
  );
  const getTemplateFile = (file: File | null) => setTemplateFile(file);
  return (
    <DrawerLayout
      open={isOpen}
      inCenter={false}
      width={drawerWidth}
      onClose={onDrawerClose}
      headerTitle={drawerTitle}
      onCloseReset={onDrawerClearClose}
      titleClassName={styles.container__title}
    >
      <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
        <Box sx={{ flex: '1 1 auto' }}>
          <Box className={styles.container__head}>
            <CompanyExcelFileInput
              reversed
              title='Template'
              maxWidth='100%'
              getFile={getTemplateFile}
              blockMaxWidth='200px'
              borderColor='#EDEFF1'
              icon={<DocumentsIcon />}
              getImgSrc={getImgSrc}
              backgroundColor='#F8F8F8'
              imgSrc={inEditMode ? currentImageSrc : null}
              getSetIsRemovedState={getImageRemovedState}
              containerClassName={styles.container__head__box}
              containerBoxClassName={styles.container__head__box__content}
            />
          </Box>
          <Box className={styles.container__content}>{inputFields}</Box>
        </Box>
        <Box className={styles.container__footer}>
          <Button
            type='button'
            maxWidth='68px'
            padding='11px 8px'
            minWidth='68px'
            borderRadius='5px'
            onClick={onDrawerClose}
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
            fontFamily={FontNames.CIRCULAR_BOLD}
            className={styles.container__content__submit}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='white'
            maxWidth='68px'
            minWidth='68px'
            padding='11px 8px'
            borderRadius='5px'
            backgroundColor='#2067DD'
            fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            disabled={false}
            className={styles.container__content__submit}
          >
            {inEditMode ? 'Update' : 'Add'}
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default AddExcelDrawer;
