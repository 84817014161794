import { FC, useState } from 'react';
import { noop } from 'lodash';
import { Box } from '@mui/material';

import { filterUsers } from 'containers/Team/utils';
import { EditTeamModal } from 'components/views/Modals';
import { TUpdateTeam } from 'store/slices/teamsSlice/types';
import { updateUserTeam } from 'store/slices/teamsSlice/thunks';
import { TTeamInfo } from 'components/views/Cards/TeamCard/types';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import { TEditTeamModal } from 'components/views/Modals/EditTeamModal/types';
import { Button, Chip, CustomTypography, CustomizedAvatar, DrawerLayout } from 'components/shared';
import { Colors } from 'types';

import { breakPoints } from '../../,,/../../../constants';

import styles from './ViewTeamDrawer.module.scss';

const ViewTeamDrawer: FC<TEditTeamModal> = ({
  open,
  item,
  team,
  setTeam,
  onClose,
  setViewTeam,
  onClearClose,
  filteredMembers,
}) => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [value, setValue] = useState<TTeamInfo>({
    name: item.name,
    description: item.description,
  });

  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(18) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const handleOpenModal = () => {
    setOpenModal(true);
    if (openModal) {
      setViewTeam(false);
    }
  };

  const handleSubmit = (options: TUpdateTeam) => {
    dispatch(updateUserTeam(options));
    setOpenModal(false);
  };

  const finalMemberList = filterUsers(item.members);

  const teamLeads = finalMemberList.filter((item) => item.position === 'lead');

  const teamMembers = finalMemberList.filter((item) => item.position === 'member');

  const renderDrawerWidth = Number(width) >= breakPoints.MOBILE ? 472 : 375;

  const renderTeamLeads = teamLeads.map(({ id, profile_image_url_id, name }) => (
    <div className={styles.container__users__content} key={id}>
      <CustomizedAvatar
        withBorder
        width={59}
        height={59}
        key={id}
        alt='Lead'
        borderWidth={1}
        borderColor={Colors.WHITE}
        src={profile_image_url_id}
        className={styles.container__users__item}
      />
      <CustomTypography title={name} className={styles.container__users__content__name}>
        {name}
      </CustomTypography>
    </div>
  ));

  const uniqueUserIds = new Set<number>();

  const renderTeamMembers = teamMembers
    .filter(({ user_id }) => {
      if (!uniqueUserIds.has(user_id)) {
        uniqueUserIds.add(user_id);
        return true;
      }
      return false;
    })
    .map(({ id, profile_image_url_id }) => (
      <div className={styles.container__users__members__item} key={id}>
        <CustomizedAvatar
          withBorder
          width={36.5}
          height={36.5}
          key={id}
          alt='Member'
          borderWidth={1}
          borderColor={Colors.WHITE}
          src={profile_image_url_id}
          className={styles.container__users__item}
        />
      </div>
    ));

  const tagsRenderer = item?.tags?.map((chip) => {
    return <Chip key={chip.id} color={chip.color} label={chip.name} />;
  });
  const handleClose = onClose ? onClose : noop;

  return (
    <DrawerLayout
      padding='40px'
      open={!!open}
      inCenter={false}
      onClose={handleClose}
      headerTitle={item.name}
      width={renderDrawerWidth}
      onCloseReset={onClearClose}
      titleClassName={styles.container__header__title}
      headerContainerClassName={styles.container__header}
    >
      <Box className={styles.container}>
        <Box className={styles.container__wrapper}>
          <Box className={styles.container__users}>
            <Box className={styles.container__users__header}>
              <CustomTypography className={styles.container__users__header__title}>
                Leads*
              </CustomTypography>
              <CustomTypography className={styles.container__users__header__title__count}>
                ({teamLeads.length})
              </CustomTypography>
            </Box>
            <Box className={styles.container__users__box}>{renderTeamLeads}</Box>
          </Box>
          <Box className={styles.container__users}>
            <Box className={styles.container__users__header}>
              <CustomTypography className={styles.container__users__header__title}>
                Members*
              </CustomTypography>
              <CustomTypography className={styles.container__users__header__title__count}>
                ({teamMembers.length})
              </CustomTypography>
            </Box>
            <Box className={styles.container__users__members}>{renderTeamMembers}</Box>
          </Box>

          <Box className={styles.container__tags}>
            <Box className={styles.container__tags__header}>
              <CustomTypography className={styles.container__tags__header__title}>
                Tags
              </CustomTypography>
              <CustomTypography className={styles.container__tags__header__title__count}>
                ({item?.tags?.length ? item?.tags?.length : 0})
              </CustomTypography>
            </Box>
            <Box className={styles.container__tags__content}>
              <Box className={styles.container__tags__content__item}>{tagsRenderer}</Box>
            </Box>
          </Box>

          <Box className={styles.container__details}>
            <CustomTypography className={styles.container__details__title}>
              Description:*
            </CustomTypography>
            <Box className={styles.container__details__description}>
              <CustomTypography className={styles.container__details__description__content}>
                {item.description}
              </CustomTypography>
            </Box>
          </Box>
          <Box className={styles.container__actions}>
            <Button
              color={Colors.SAPPHIRE}
              minWidth='68px'
              padding='8px 11px'
              onClick={handleClose}
              backgroundColor={Colors.PALE_BLUE}
              className={styles.container__actions__item}
            >
              Cancel
            </Button>
            <Button
              color={Colors.WHITE}
              minWidth='59px'
              padding='8px 11px'
              onClick={handleOpenModal}
              backgroundColor={Colors.SAPPHIRE}
              disabled={isViewer}
              className={styles.container__actions__item}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <EditTeamModal
          team={team}
          item={item}
          value={value}
          open={openModal}
          setTeam={setTeam}
          setValue={setValue}
          setViewTeam={setViewTeam}
          handleEditTeam={handleSubmit}
          filteredMembers={filteredMembers}
          closeDrawer={() => setOpenModal(false)}
        />
      </Box>
    </DrawerLayout>
  );
};

export default ViewTeamDrawer;
