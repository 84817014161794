import React from 'react';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import { useCustomTheme } from 'infrastructure/Theming/CustomThemeProvider';

import styles from './Switch.module.scss';

const NewSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(() => {
  const theme = useCustomTheme();
  return {
    width: 24,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,

      '&.Mui-checked': {
        transform: 'translateX(5px)',
        color: theme.colors.white,

        '& + .MuiSwitch-track': {
          backgroundColor: theme.colors.blue100,
          opacity: 1,
          border: 0,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 10,
      height: 10,
      margin: 2,
    },

    '& .MuiSwitch-track': {
      borderRadius: 13,
      backgroundColor: theme.colors.gray100,
      opacity: 1,
      width: 24,
      height: 18,
    },
  };
});

type TReusableSwitchProps = SwitchProps &
  Partial<{
    label: string;
  }>;

const CustomSwitch: React.FC<TReusableSwitchProps> = ({ checked, onChange, label }) => (
  <div className={styles.container}>
    <NewSwitch checked={checked} onChange={onChange} />
    {label && <span className={styles.container__label}>{label}</span>}
  </div>
);

export default CustomSwitch;
