import React, { useMemo, useState } from 'react';

import { useAppSelector } from 'hooks';
import { RemoveMemberIcon } from 'assets/icons';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';
import { Colors } from 'types';

import CustomTypography from '../Typography';
import { CustomSelect, CustomizedAvatar } from '..';
import { TOption } from '../Select/types';

import { TMember, TUserComponent } from './types';
import styles from './Team.module.scss';

const UserComponent: React.FC<TUserComponent> = ({
  item,
  team,
  title,
  members,
  popupIcon,
  setMembers,
  memberRole,
  placeholder,
  filteredMembers,
  handleArrayChange,
}) => {
  const allMembers = useAppSelector(organizationMembersSelector);
  const currentUser = useAppSelector(userDataSelector);

  const modifiedData = useMemo(() => {
    return filteredMembers?.map(({ name, user_id }) => ({
      disabled: user_id === currentUser?.id,
      label: name,
      value: user_id,
      color: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256,
      )}, ${Math.floor(Math.random() * 256)})`,
    }));
  }, [currentUser?.id, filteredMembers]);

  const removeDuplicates = (members: TMember[]) => {
    const seenMembers = new Set();
    return members.filter((member) => {
      const key = `${member.user_id}-${member.position}`;
      if (seenMembers.has(key)) {
        return false;
      }
      seenMembers.add(key);
      return true;
    });
  };

  const compareData = removeDuplicates(members).map((el) => {
    return {
      disabled: el.user_id === currentUser?.id && el.position === 'member',
      label: el.name,
      value: el.user_id,
      color: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256,
      )}, ${Math.floor(Math.random() * 256)})`,
    };
  });

  const [selectedOptions, setSelectedOptions] = useState<TOption[] | []>(
    compareData ? compareData : team,
  );

  const handleChipDelete = (optionToDelete: TOption) => {
    const filteredOptions = selectedOptions?.filter(
      (option) => option.value !== (optionToDelete?.value || optionToDelete?.user_id),
    );

    const membersIds = filteredOptions.map((el) => el.id || el.value);

    const selectedMembers = allMembers?.filter((el) => {
      if (membersIds.includes(el.user_id)) {
        return {
          id: el.user_id,
          name: el.name,
          user_id: el.user_id,
          profile_image_url_id: el.profile_image_url_id,
        };
      }
    });

    setMembers?.(selectedMembers as TMember[]);

    setSelectedOptions(filteredOptions);
    handleArrayChange(filteredOptions);
  };

  const deleteMember = (id: number) => {
    const filteredMembers = members.filter((el) => el?.user_id !== id);
    const selectedMember = members.find((el) => el?.user_id === id);

    setMembers?.(filteredMembers as TMember[]);
    handleChipDelete(selectedMember as TOption);
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <div className={styles.container__title}>
          <CustomTypography className={styles.container__title__typo}>{title}</CustomTypography>
          <CustomTypography className={styles.container__title__count}>
            ({members?.length ? members?.length : 0})
          </CustomTypography>
        </div>
      </div>

      <div className={styles.container__user}>
        {removeDuplicates(members)?.map((member) => (
          <div className={styles.container__user__content} key={member.id}>
            <div style={{ position: 'relative' }}>
              {currentUser?.id === member.user_id && memberRole === 'member' ? null : (
                <RemoveMemberIcon
                  className={styles.container__user__content__remove}
                  onClick={() => deleteMember(member?.user_id)}
                />
              )}
              <CustomizedAvatar
                withBorder
                alt={'img'}
                width={32}
                height={32}
                borderColor={Colors.SILVER_SLATE}
                className={styles.container__img}
                src={member.profile_image_url_id}
              />
            </div>
            <CustomTypography
              className={styles.container__user__content__name}
              title={member.name || member?.label}
            >
              {member.name || member?.label}
            </CustomTypography>
          </div>
        ))}
      </div>
      <div className={styles.container__select}>
        <CustomSelect
          memberRole={memberRole}
          item={item}
          value={team}
          borderRadius='10px'
          borderColor={Colors.SOFT_SILVER}
          popupIcon={popupIcon}
          setMembers={setMembers}
          defaultValue={compareData}
          onChange={handleArrayChange}
          options={modifiedData ?? []}
          placeholder={placeholder || ''}
          handleDelete={handleChipDelete}
          selectedOptions={selectedOptions}
          backGroundColor={Colors.LIGHT_SILVER}
          setSelectedOptions={setSelectedOptions}
        />
      </div>
    </div>
  );
};

export default UserComponent;
