import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import { Routes } from 'types';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const breadCrumbOptions = [{ id: 1, label: 'Documents', goTo: Routes.Documents }];

export const documentsColumns: GridColDef[] = [
  {
    field: 'customer_name',
    headerName: 'Folder/Document',
    flex: 4,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '0', color: '#282828' }}
        />
      );
    },
  },
  {
    field: 'last_modified',
    headerName: 'Last Modified',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
  {
    field: 'size',
    headerName: 'Size',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
];
