import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { DirectionEnums, TInitialState } from './types';
import {
  getDocumentById,
  getDocumentByIdAndSection,
  getDocumentList,
  getDocumentTypes,
} from './thunks';

const initialState: TInitialState = {
  documentTypes: [],
  isViewGrid: false,
  documentsLimit: 5,
  documentsOffset: 0,
  documentsError: null,
  currentDocument: null,
  documentTypesLimit: 5,
  documentsItemLimit: 5,
  documentsItemOffset: 0,
  documentLoading: false,
  documentTypesOffset: 0,
  documentTypesError: null,
  documentsItemError: null,
  documents: dataStructure,
  currentDocumentError: null,
  documentsItemLoading: false,
  documentTypesLoading: false,
  documentsItem: dataStructure,
  currentDocumentLoading: false,
  documentsAsc: DirectionEnums.ASC,
  documentTypesAsc: DirectionEnums.ASC,
};

const documentsSlice = createSlice({
  name: 'documentsSlice',
  reducers: {
    setPage(state, action) {
      state.documentsOffset = (action.payload - 1) * state.documentsLimit;
    },
    setLimit(state, action) {
      state.documentsLimit = action.payload;
    },
    setItemsPage(state, action) {
      state.documentsItemOffset = (action.payload - 1) * state.documentsItemLimit;
    },
    setItemsLimit(state, action) {
      state.documentsItemLimit = action.payload;
    },
    setView(state, action) {
      state.isViewGrid = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentList.pending, (state) => {
        state.documentLoading = true;
        state.documentsError = null;
      })
      .addCase(getDocumentList.fulfilled, (state, action) => {
        state.documents = action.payload;
        state.documentLoading = false;
        state.documentsAsc =
          state.documentsAsc === DirectionEnums.ASC ? DirectionEnums.DESC : DirectionEnums.ASC;
      })
      .addCase(getDocumentTypes.pending, (state) => {
        state.documentTypesLoading = true;
        state.documentTypesError = null;
      })
      .addCase(getDocumentTypes.fulfilled, (state, action) => {
        state.documentTypes = action.payload;
        state.documentTypesLoading = false;
      })
      .addCase(getDocumentByIdAndSection.pending, (state) => {
        state.documentsItemLoading = true;
        state.documentsItemError = null;
      })
      .addCase(getDocumentByIdAndSection.fulfilled, (state, action) => {
        state.documentsItem = action.payload;
        state.documentsItemLoading = false;
        state.documentTypesAsc =
          state.documentTypesAsc === DirectionEnums.ASC ? DirectionEnums.DESC : DirectionEnums.ASC;
      })
      .addCase(getDocumentById.pending, (state) => {
        state.currentDocumentLoading = true;
        state.currentDocumentError = null;
      })
      .addCase(getDocumentById.rejected, (state, action) => {
        state.currentDocumentLoading = false;
        state.currentDocument = null;
        state.currentDocumentError = action?.payload as string;
      })
      .addCase(getDocumentById.fulfilled, (state, action) => {
        state.currentDocument = action.payload;
        state.currentDocumentLoading = false;
        state.currentDocumentError = null;
      });
  },
});

export default documentsSlice.reducer;

export const { setPage, setLimit, setItemsPage, setItemsLimit, setView } = documentsSlice.actions;
