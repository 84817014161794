import { createSlice } from '@reduxjs/toolkit';

import { TInitialState } from './types';
import { createInvitation } from './thunks';

const initialState: TInitialState = {
  currentInvitation: [],
  currentInvitationLoading: false,
  currentInvitationError: null,
};

const invitationSlice = createSlice({
  name: 'invitationSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createInvitation.pending, (state) => {
        state.currentInvitationLoading = true;
        state.currentInvitationError = null;
      })
      .addCase(createInvitation.fulfilled, (state, action) => {
        state.currentInvitationLoading = false;
        state.currentInvitationError = null;
        state.currentInvitation = action.payload;
      })
      .addCase(createInvitation.rejected, (state, action) => {
        state.currentInvitationLoading = false;
        state.currentInvitationError = action.payload as string;
      });
  },
});

export default invitationSlice.reducer;
