import { TEstimate } from 'store/slices/estimatesSlice/types';
import { TCustomer } from 'store/slices/customersSlices/types';

import { TBomEstimateFormValuesGeneratorParams } from './types';

const fromCustomerDefaultData = (
  currentCustomer: TCustomer | null,
  selectedEstimate: TEstimate | null,
) => ({
  full_name: selectedEstimate?.customer_info?.full_name || currentCustomer?.name,
  email: selectedEstimate?.customer_info?.email || currentCustomer?.email,
  phone_number: selectedEstimate?.customer_info?.phone_number || currentCustomer?.phone_number,
  address: selectedEstimate?.customer_info?.address || currentCustomer?.address,
  city: selectedEstimate?.customer_info?.city || currentCustomer?.city,
  state: selectedEstimate?.customer_info?.state || currentCustomer?.state,
  zip: selectedEstimate?.customer_info?.zip || currentCustomer?.zip,
  customer_id: currentCustomer?.id,
  bill_to: selectedEstimate?.bill_to?.bill_to || currentCustomer?.billing?.[0]?.bill_to,
  bill_phone_number:
    selectedEstimate?.bill_to?.phone_number || currentCustomer?.billing?.[0]?.phone_number,
  bill_address: selectedEstimate?.bill_to?.address || currentCustomer?.billing?.[0]?.address,
  bill_city: selectedEstimate?.bill_to?.city || currentCustomer?.billing?.[0]?.city,
  bill_state: selectedEstimate?.bill_to?.state || currentCustomer?.billing?.[0]?.state,
  bill_zip: selectedEstimate?.bill_to?.zip || currentCustomer?.billing?.[0]?.zip,
});

const bomEstimateFormValuesGenerator = ({
  currentCustomer,
  selectedEstimate,
}: TBomEstimateFormValuesGeneratorParams) => ({
  from_customer: fromCustomerDefaultData(currentCustomer, selectedEstimate),

  general_info: {
    ...selectedEstimate?.general_info,
  },

  sections: selectedEstimate?.sections,
});

export type TBomEstimateFormValuesGeneratorReturn = ReturnType<
  typeof bomEstimateFormValuesGenerator
>;

export default bomEstimateFormValuesGenerator;
