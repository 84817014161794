import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TCreateInvoice,
  TCreateItem,
  TInvoicesParams,
  TUpdateInvoice,
  TUpdateItem,
} from 'store/slices/invoicesSlice/types';

export const getAllInvoicesRequest = (options: TInvoicesParams) =>
  client.get(endpoints.InvoiceService.getAllInvoices(), { params: { ...options } });

export const createInvoiceRequest = (invoice: TCreateInvoice) =>
  client.post(endpoints.InvoiceService.createInvoice(), { invoice: invoice });

export const deleteInvoiceIdRequest = (id: number) =>
  client.delete(endpoints.InvoiceService.deleteInvoice(id));

export const getInvoiceByIdRequest = (id: number) =>
  client.get(endpoints.InvoiceService.getInvoiceById(id));

export const updateInvoiceRequest = (invoice: TUpdateInvoice) =>
  client.put(endpoints.InvoiceService.updateInvoice(), { invoice: invoice });

export const getAllItemsByInvoiceIdRequest = (id: number) =>
  client.get(endpoints.InvoiceService.getAllItemsByInvoiceId(id));

export const updateInvoiceItemRequest = (item: TUpdateItem) =>
  client.put(endpoints.InvoiceService.updateInvoiceItem(), { item: item });

export const createInvoiceItemRequest = (new_item: TCreateItem) =>
  client.post(endpoints.InvoiceService.createInvoiceItem(), { new_item: new_item });

export const getInvoiceItemByIdRequest = (id: number) =>
  client.get(endpoints.InvoiceService.getInvoiceItemById(id));

export const deleteInvoiceItemIdRequest = (id: number) =>
  client.delete(endpoints.InvoiceService.deleteInvoiceItem(id));

export const publishInvoiceItemIdRequest = (id: number) =>
  client.post(endpoints.InvoiceService.publishInvoice(id));
