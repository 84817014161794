import { Routes } from 'types';

export const breadCrumbOptions = (id: number) => [
  { id: 1, label: 'Inventory', goTo: Routes.Inventory },
  { id: 2, label: 'Purchase Requisition' as string, goTo: Routes.Requisition },
  {
    id: 3,
    label: `Requisition #${id}` as string,
    goTo: Routes.PurchaseRequisitionItem,
  },
];
