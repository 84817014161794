import { FC, Fragment, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { useAppSelector } from 'hooks';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { Routes } from 'types';

import styles from './Documents.module.scss';
import DocumentsHeader from './DocumentsHeader';
import { generateDocumentSections } from './constants';

const documentsView = BrowserStorageService.get(BrowserStorageKeys.DocumentsView, {
  session: true,
});

const Documents: FC = () => {
  const documentsViewValue = documentsView ? JSON.parse(documentsView) : false;

  const { isAccessToDocumentationAndReports } = useAppSelector(userPermissionsSelector);
  const rolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const [isViewInGrid, setIsViewInGrid] = useState<boolean>(documentsViewValue);

  useEffect(() => {
    if (documentsViewValue) {
      setIsViewInGrid(documentsViewValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const documentSections = generateDocumentSections(isViewInGrid);

  const contentRender = documentSections?.map((item) => {
    return <Fragment key={item.id}>{item.content}</Fragment>;
  });

  if (!isAccessToDocumentationAndReports && !rolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box className={styles.container}>
      <DocumentsHeader isViewInGrid={isViewInGrid} setIsViewInGrid={setIsViewInGrid} />

      <Box className={styles.container__content}>{contentRender}</Box>
    </Box>
  );
};

export default Documents;
