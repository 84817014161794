import { FC } from 'react';
import { Box } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import { useAppDispatch, useWindowSize } from 'hooks';
import { NumberControlledInput } from 'components';
import { Button, DrawerLayout } from 'components/shared';
import ControlledInput from 'components/views/ControlledInput';
import { createVendor } from 'store/slices/inventorySlice/thunks';
import { Colors } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './PurchaseOrderDrawer.module.scss';
import { schema } from './schema';

import type { TVendor } from 'store/slices/inventorySlice/types';
import type { TCreateNotListedVendorDrawer, TNotListedVendorDrawer } from './types';

const PurchaseOrderVendorDrawer: FC<TNotListedVendorDrawer> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<TCreateNotListedVendorDrawer>({
    resolver: yupResolver(schema),
  });

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    const response = await dispatch(createVendor(data as TVendor));

    if (response?.meta?.requestStatus === 'fulfilled') {
      handleClose();
      reset({
        name: '',
        phone: '',
        email: '',
        address: '',
      });
    }
  };

  const handleCloseReset = () => {
    handleClose();
    reset({
      name: '',
      phone: '',
      email: '',
      address: '',
    });
  };

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 576
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 400
      : Number(width) <= breakPoints.MOBILE
      ? 375
      : 600;

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      width={drawerWidth}
      onClose={handleClose}
      onCloseReset={handleCloseReset}
      headerTitle='Add Vendor Not Listed'
    >
      <Box className={styles.container}>
        <form className={styles.container__content} onSubmit={handleSubmit(handleCreateData)}>
          <Box className={styles.container__content__inputs}>
            <ControlledInput
              name='name'
              helperText={errors.name?.message}
              control={control}
              label='Vendor Name:*'
              fontFamily='CircularStdLight'
              error={!!errors?.name}
              backGroundColor={Colors.LIGHT_SILVER}
              borderColor={Colors.SOFT_SILVER}
              borderRadius='4px'
              placeholder='Vendor Name:*'
            />
            <ControlledInput
              name='email'
              label='Email:*'
              control={control}
              fontFamily='CircularStdLight'
              placeholder='Email:*'
              backGroundColor={Colors.LIGHT_SILVER}
              borderColor={Colors.SOFT_SILVER}
              borderRadius='4px'
              error={!!errors?.email}
              helperText={errors.email?.message}
            />
            <NumberControlledInput
              name='phone'
              label='Phone:'
              control={control}
              fontFamily='CircularStdLight'
              placeholder='Phone:'
              backgroundColor={Colors.LIGHT_SILVER}
              borderColor={Colors.SOFT_SILVER}
              borderRadius='4px'
              error={!!errors.phone}
              helperText={errors.phone?.message}
            />
            <ControlledInput
              name='address'
              label='Address:*'
              control={control}
              placeholder='Address:*'
              backGroundColor={Colors.LIGHT_SILVER}
              borderColor={Colors.SOFT_SILVER}
              borderRadius='4px'
              error={!!errors?.address}
              fontFamily='CircularStdLight'
              helperText={errors.address?.message}
            />
          </Box>
          <Box className={styles.container__content__actions}>
            <Button
              type='button'
              minWidth='68px'
              color={Colors.SAPPHIRE}
              onClick={handleClose}
              disabled={isSubmitting}
              backgroundColor={Colors.PALE_BLUE}
              className={styles.container__content__actions__button}
            >
              Cancel
            </Button>
            <Button
              color={Colors.WHITE}
              type='submit'
              minWidth='61px'
              disabled={isSubmitting}
              backgroundColor={Colors.SAPPHIRE}
              className={styles.container__content__actions__button}
            >
              Add
            </Button>
          </Box>
        </form>
      </Box>
    </DrawerLayout>
  );
};

export default PurchaseOrderVendorDrawer;
