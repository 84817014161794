import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'image',
    headerName: 'Image',
    flex: 0.7,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} withSortIcons={false} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params.value}
          style={{ width: '70%', height: '90%', padding: 0 }}
        />
      );
    },
  },
  {
    field: 'name',
    headerName: 'Item Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '0', color: '#282828', minWidth: '87%' }}
        />
      );
    },
  },
  {
    field: 'brand',
    headerName: 'Brand',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '15px', color: '#282828' }}
        />
      );
    },
    sortComparator: (v1, v2) => {
      const name1 = v1?.props.title || '';
      const name2 = v2?.props.title || '';
      return name1.localeCompare(name2);
    },
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '15px', color: '#282828' }}
        />
      );
    },
    sortComparator: (v1, v2) => {
      const name1 = v1?.props.title || '';
      const name2 = v2?.props.title || '';
      return name1.localeCompare(name2);
    },
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '0', color: '#282828' }}
        />
      );
    },
    sortComparator: (v1, v2) => {
      const name1 = v1?.props.title || '';
      const name2 = v2?.props.title || '';
      return name1.localeCompare(name2);
    },
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    flex: 1,
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ paddingLeft: '0', color: '#282828' }}
        />
      );
    },
    valueGetter: (params) => params.row.quantity,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    sortComparator: (v1, v2) => {
      const name1 = v1 || '';
      const name2 = v2 || '';
      return String(name1).localeCompare(String(name2));
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];
