export const sxStyles = {
  '& .MuiStepLabel-root': {
    cursor: 'pointer !important',
  },

  '& .MuiStep-root': {
    cursor: 'pointer',

    ' & .MuiStepLabel-root': {
      padding: '0 !important',

      svg: {
        borderRadius: '50%',
      },
    },
  },

  '& .Mui-active': {
    color: 'unset !important',
    fontWeight: '400 !important',
  },

  '& .MuiStepLabel-iconContainer': {
    paddingRight: '16px',
  },

  '& .MuiStepLabel-label': {
    fontSize: '14px',
    fontWeight: '700 !important',
    lineHeight: 'normal',
    letterSpacing: '0.175px',
  },
};
