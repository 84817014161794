import { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { Colors, Routes, TFormSubmit } from 'types';
import { getUserTeams } from 'store/slices/teamsSlice/thunks';
import { allTeamsSelector } from 'store/slices/teamsSlice/selectors';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { createLiveStream } from 'store/slices/liveStreamSlice/thunks';
import { organizationByIdSelector } from 'store/slices/organizationsSlice/selectors';
import {
  Button,
  DrawerLayout,
  LiveStreamTabs,
  ControlledInput,
  NumberControlledInput,
  CustomTypography,
} from 'components';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { transformPhoneNumber } from 'utils';
import { TCreateLiveStream as TCreateLiveStreamParams } from 'store/slices/liveStreamSlice/types';

import { breakPoints } from '../../../../constants';

import { schema } from './schema';
import { tabs } from './constants';
import styles from './CreateLivestreamDrawer.module.scss';

import type { TCreateLiveStreamDrawerProps, TCreateLiveStream } from './types';
import type { TTabs } from 'components/shared/LivestreamTabs/types';

const CreateLiveStreamDrawer: FC<TCreateLiveStreamDrawerProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { width } = useWindowSize();

  const organizationInfo = useAppSelector(organizationByIdSelector);
  const userTeams = useAppSelector(allTeamsSelector);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const {
    control,
    reset,

    handleSubmit: updateSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<TCreateLiveStream>({
    resolver: yupResolver(schema),
  });

  const userTeamId = userTeams?.data?.find((team) => team?.id);

  useEffect(() => {
    if (organizationInfo?.id) {
      dispatch(getUserTeams({ org_id: organizationInfo?.id as number }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationInfo?.id]);

  const drawerWidth = Number(width) >= breakPoints.TABLET_M ? '493px' : '100dvw';

  const handleConnecting: TFormSubmit = async (data) => {
    const sendedData: TCreateLiveStreamParams = {
      title: data?.title,
      audience: userTeamId ? 'team' : 'custom',
      team_id: Number(userTeamId) || null,
      external_members: [
        {
          name: data?.customer,
          ...(data?.phone?.length ? {} : { email: data?.email || null }),
          phone_number: transformPhoneNumber(data?.phone) || null,
        },
      ],
    };

    const response = await dispatch(createLiveStream(sendedData));

    if (response?.meta?.requestStatus === 'fulfilled') {
      navigate(Routes.LiveStreamJoin);
      BrowserStorageService.set(
        BrowserStorageKeys.currentLiveStreamId,
        response?.payload?.livestream?.id,
        {
          session: true,
        },
      );

      BrowserStorageService.set(
        BrowserStorageKeys.currentLiveStreamUUID,
        response?.payload?.livestream?.uuid,
        {
          session: true,
        },
      );

      BrowserStorageService.set(
        BrowserStorageKeys.currentLiveStreamToken,
        response?.payload?.token,
      );

      reset({
        title: null,
        phone: null,
        email: null,
        customer: null,
      });

      handleClose();
    }
  };

  const handleTabChange = (newActiveTab: TTabs) => {
    setActiveTab(newActiveTab);
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset({
      title: null,
      phone: null,
      email: null,
      customer: null,
    });
    handleClose();
  };

  return (
    <DrawerLayout
      open={open}
      width={drawerWidth}
      onClose={onDrawerClose}
      headerTitle=''
      onCloseReset={onDrawerClearClose}
      titleClassName={styles.container__title}
    >
      <form className={styles.container} onSubmit={updateSubmit(handleConnecting)}>
        <CustomTypography className={styles.container__title}>Create Livestream</CustomTypography>
        <LiveStreamTabs activeTab={activeTab} setActiveTab={handleTabChange} tabs={tabs} />
        <Box className={styles.container__item}>
          <ControlledInput
            required
            name='title'
            label='Title:'
            control={control}
            borderRadius='5px'
            padding='15px 16px'
            placeholder='Title:*'
            borderColor={Colors.SOFT_SILVER}
            error={!!errors?.title}
            backgroundColor={Colors.FROST_WHITE}
            fontFamily='CircularStdLight'
          />
          <ControlledInput
            required
            name='customer'
            borderRadius='5px'
            control={control}
            padding='15px 16px'
            borderColor={Colors.SOFT_SILVER}
            backgroundColor={Colors.FROST_WHITE}
            error={!!errors?.customer}
            label='Customer/Technician:'
            fontFamily='CircularStdLight'
            placeholder='Customer/Technician:*'
          />

          {activeTab?.tab === 'Invite Via SMS' ? (
            <NumberControlledInput
              required
              name='phone'
              label='Phone:'
              padding='15px 16px'
              borderRadius='5px'
              control={control}
              placeholder='Phone:*'
              borderColor={Colors.SOFT_SILVER}
              error={!!errors?.phone}
              backgroundColor={Colors.FROST_WHITE}
              fontFamily='CircularStdLight'
            />
          ) : (
            <ControlledInput
              required
              name='email'
              label='Email:'
              padding='15px 16px'
              control={control}
              borderRadius='5px'
              borderColor={Colors.SOFT_SILVER}
              placeholder='Email:*'
              error={!!errors?.email}
              backgroundColor={Colors.FROST_WHITE}
              fontFamily='CircularStdLight'
            />
          )}
        </Box>
        <Stack alignItems='center' width='100%' padding='62px 0'>
          <Button
            color='white'
            type='submit'
            padding='12px 0'
            maxWidth='109px'
            isUppercase={false}
            borderRadius='4px'
            backgroundColor={Colors.SAPPHIRE}
            disabled={!isValid || isSubmitting}
          >
            Send Invite
          </Button>
        </Stack>
      </form>
    </DrawerLayout>
  );
};

export default CreateLiveStreamDrawer;
