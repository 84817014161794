import { FC } from 'react';
import { CircularProgress, Stack } from '@mui/material';

import { CustomTypography } from 'components';

import styles from './EmptyTitle.module.scss';

import type { TEmptyTitleProps } from './types';

const EmptyTitle: FC<TEmptyTitleProps> = ({ title, loadingDone = false, style }) => {
  if (loadingDone) {
    return (
      <Stack justifyContent='center' alignItems='center' margin='20px 0'>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      {!loadingDone && (
        <div className={styles.container} style={style}>
          <CustomTypography className={styles.container__title}>{title}</CustomTypography>
        </div>
      )}
    </>
  );
};

export default EmptyTitle;
