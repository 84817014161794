import { useLayoutEffect, useState, KeyboardEvent } from 'react';

const useNumberFormatInput = (value: string) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  const [isBackspaceClick, setIsBackSpaceClick] = useState<boolean>(false);

  const formatPhoneNumber = (phoneNumber: string) => {
    if (isBackspaceClick) {
      return phoneNumber;
    } else {
      phoneNumber = phoneNumber?.replace(/\D/g, '');
      phoneNumber = phoneNumber?.slice(0, 10);

      let formattedNumber = '';
      for (let i = 0; i < phoneNumber?.length; i++) {
        if (i === 0) formattedNumber += `(${phoneNumber[i]}`;
        else if (i === 2) formattedNumber += `${phoneNumber[i]}) `;
        else if (i === 5) formattedNumber += `${phoneNumber[i]}-`;
        else formattedNumber += phoneNumber[i];
      }

      return formattedNumber;
    }
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const isBackspace = event.key === 'Backspace';
    setIsBackSpaceClick(isBackspace);
  };

  const validatePhoneNumber = (value: string) => {
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    return phoneNumberRegex.test(value);
  };

  useLayoutEffect(() => {
    if (validatePhoneNumber(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [value]);

  return {
    onKeyDown,
    isValid,
    formatPhoneNumber,
    isBackspaceClick,
  };
};

export default useNumberFormatInput;
