import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

import type {
  TAddRequisitionItemBody,
  TCreateRequisitionBody,
  TModifyData,
  TRequisitionsParams,
  TRequisitionUpdateBody,
} from 'store/slices/requisitionSlices/types';

export const getAllRequisitionsRequest = (options: TRequisitionsParams) => {
  return client.get(endpoints.PurchaseRequisitionService.getAllRequisitions(), {
    params: { ...options },
  });
};

export const createRequisitionsRequest = (body: TCreateRequisitionBody[]) => {
  return client.post(endpoints.PurchaseRequisitionService.createRequisitions(), body);
};

export const getRequisitionItemsRequest = (id: number | string) => {
  return client.get(endpoints.PurchaseRequisitionService.getRequisitionItemsById(id));
};

export const getRequisitionItemsByUuidRequest = (id: string) => {
  return client.get(endpoints.PurchaseRequisitionService.getRequisitionItemsByUuid(id));
};

export const updateRequisitionRequest = (body: TRequisitionUpdateBody) => {
  return client.put(endpoints.PurchaseRequisitionService.updateRequisition(), body);
};

export const modifyRequisitionRequest = (body: TModifyData) => {
  return client.put(endpoints.PurchaseRequisitionService.modifyRequisition(), {
    item_update: body,
  });
};

export const deleteRequisitionRequest = (id: number) => {
  return client.delete(endpoints.PurchaseRequisitionService.deleteRequisition(id));
};

export const deleteRequisitionItemRequest = (id: number) => {
  return client.delete(endpoints.PurchaseRequisitionService.deleteRequisitionItem(id));
};

export const cloneRequisitionRequest = (id: number) => {
  return client.post(endpoints.PurchaseRequisitionService.cloneRequisition(id));
};
export const addItemsToRequisitionRequest = (body: TAddRequisitionItemBody) => {
  return client.post(endpoints.PurchaseRequisitionService.addItemsToRequisition(), body);
};

export const approveDeclineRequisitionRequest = (ids: number[] | number, status: string) => {
  return client.put(endpoints.PurchaseRequisitionService.requisitionApproveDecline(status), ids);
};
