import { RootState } from 'types';

export const customersSliceSelector = (state: RootState) => state.customers;
export const customersSelector = (state: RootState) => state.customers.customers;

export const customersLoadingSelector = (state: RootState) => state.customers.customerLoading;

export const customersLimitSelector = (state: RootState) => state.customers.customersLimit;

export const customersOffsetSelector = (state: RootState) => state.customers.customersOffset;

export const customersAscSelector = (state: RootState) => state.customers.customersAsc;

export const currentCustomerSelector = (state: RootState) => state.customers.currentCustomer;

export const currentCustomerLoadingSelector = (state: RootState) =>
  state.customers.currentCustomerLoading;

export const currentCustomerErrorSelector = (state: RootState) =>
  state.customers.currentCustomerError;
