import { FC, useEffect } from 'react';
import { Box, Drawer } from '@mui/material';

import { DrawerHeader } from 'components';
import useLockIosToolbar from 'hooks/useLockIOSToolbar';
import { useAppDispatch } from 'hooks';
import { setDrawerState } from 'store/slices/optionsSlice';

import type { TDrawerLayoutProps } from './types';

const DrawerLayout: FC<TDrawerLayoutProps> = ({
  open,
  sx,
  onClose,
  width = 700,
  onCloseReset,
  children,
  icon,
  headerTitle,
  headerSubtitle,
  inCenter = true,
  anchor = 'right',
  clearAll = false,
  titleClassName,
  withHeader = true,
  subtitleClassName,
  padding = '25px',
  inEditMode = false,
  hideBackdrop = false,
  headerContainerClassName,
}) => {
  const dispatch = useAppDispatch();

  useLockIosToolbar(open);

  const handleOnClose = () => {
    if (clearAll) {
      onCloseReset?.();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    dispatch(setDrawerState(open));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Drawer open={open} onClose={handleOnClose} anchor={anchor} hideBackdrop={hideBackdrop} sx={sx}>
      <Box sx={{ width, padding }}>
        {withHeader && (
          <DrawerHeader
            icon={icon}
            inCenter={inCenter}
            title={headerTitle}
            inEditMode={inEditMode}
            subtitle={headerSubtitle}
            titleClassName={titleClassName}
            onClose={onCloseReset || onClose}
            subtitleClassName={subtitleClassName}
            headerContainerClassName={headerContainerClassName}
          />
        )}
        {children}
      </Box>
    </Drawer>
  );
};

export default DrawerLayout;
