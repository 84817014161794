import localforage from 'localforage';

import { LocalForageKeys, BrowserStorageOptions } from './types';

const LocalForageService = {
  get: async (field: LocalForageKeys, options?: BrowserStorageOptions): Promise<any | null> => {
    const storage = options?.session
      ? localforage.createInstance({ name: 'sessionStorage' })
      : localforage;

    try {
      const value = await storage.getItem(field);
      return value;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error retrieving data:', error);
      return null;
    }
  },

  set: async (
    field: LocalForageKeys,
    value: any,
    options?: BrowserStorageOptions,
  ): Promise<void> => {
    const storage = options?.session
      ? localforage.createInstance({ name: 'sessionStorage' })
      : localforage;

    try {
      await storage.setItem(field, value);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error storing data:', error);
    }
  },

  remove: async (field: LocalForageKeys, options?: BrowserStorageOptions): Promise<void> => {
    const storage = options?.session
      ? localforage.createInstance({ name: 'sessionStorage' })
      : localforage;

    try {
      await storage.removeItem(field);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error removing data:', error);
    }
  },

  update: async (
    field: LocalForageKeys,
    value: any,
    options?: BrowserStorageOptions,
  ): Promise<void> => {
    const storage = options?.session
      ? localforage.createInstance({ name: 'sessionStorage' })
      : localforage;

    try {
      if (await storage.getItem(field)) {
        await storage.removeItem(field);
        await storage.setItem(field, value);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating data:', error);
    }
  },

  updatePromisify: async (field: LocalForageKeys, value: any): Promise<void> => {
    const storage = await localforage.createInstance({ name: 'sessionStorage' });

    try {
      if (await storage.getItem(field)) {
        await storage.removeItem(field);
        await storage.setItem(field, value);
      } else {
        await localforage.removeItem(field);
        await localforage.setItem(field, value);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating data:', error);
    }
  },
};

export default LocalForageService;
