import { GridColDef } from '@mui/x-data-grid';

import { Routes } from 'types';
import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

import { TDocumentsReturnType, TGeneratedDocument } from './types';

export const breadCrumbOptions = (name: string) => [
  { id: 1, label: 'Documents', goTo: Routes.Documents },
  { id: 2, label: name, goTo: Routes.DocumentsItem },
];

export const documentsColumns: GridColDef[] = [
  {
    field: 'documentName',
    headerName: 'Folder/Document',
    flex: 4,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell isTooltipDisabled style={{ paddingLeft: '0' }} title={params?.value} />;
    },

    sortComparator: (v1, v2) => {
      const name1 = v1.props.children[1].props.children || '';
      const name2 = v2.props.children[1].props.children || '';
      return name1.localeCompare(name2);
    },
  },
  {
    field: 'last_modified',
    headerName: 'Last Modified',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },

  {
    field: 'size',
    headerName: 'Size',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: '0' }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '0', color: '#282828' }} title={params?.value} />;
    },
  },
];
export const documentTypes = [
  {
    label: 'Agreements',
    value: 'agreement',
  },
  {
    label: 'Estimates',
    value: 'estimate',
  },
  {
    label: 'Invoices',
    value: 'invoice',
  },
  {
    label: 'Receipts',
    value: 'receipt',
  },
  {
    label: 'Live Streams',
    value: 'livestreams',
  },
];

export const matchDocumentTypes = ({ documentTypes, documents }: TDocumentsReturnType) => {
  const results: TGeneratedDocument[] = [];

  documentTypes.forEach((type) => {
    const matched = documents.some((document) => document.type === type.value);
    const foundDocument = documents.find((document) => document.type === type.value);

    results.push({
      ...type,
      size: foundDocument?.size || 0,
      last_modified: foundDocument?.last_modified,
      matched,
    });
  });

  return results;
};
