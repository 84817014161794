import { GridColDef } from '@mui/x-data-grid';

import { CustomTypography, TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { Colors, Routes } from 'types';

export const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Vendor Name',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return (
        <TableCell
          title={
            <CustomTypography
              fontFamily='CircularStdRegular'
              fontSize='14px'
              color={Colors.SAPPHIRE}
              outlined
            >
              {params?.value}
            </CustomTypography>
          }
        />
      );
    },
  },
  {
    flex: 1,
    field: 'number',
    headerName: 'Vendor No',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'address',
    headerName: 'Vendor Address',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'phone',
    headerName: 'Contact Number',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },

  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];

export const breadCrumbsOptions = [
  { id: 1, label: 'Inventory', goTo: Routes.Inventory },
  { id: 2, label: 'Vendors', goTo: Routes.Vendors },
];
