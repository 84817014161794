import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

import { Colors } from 'types';
import { subscriptionsApi } from 'api';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Button, CardDetails, CustomTypography, DrawerLayout } from 'components';
import { currentPaymentMethodSelector } from 'store/slices/subscriptionSlice/selectors';
import { useAppDispatch, useAppSelector, useStripePayment, useWindowSize } from 'hooks';
import { getDefaultPaymentMethod, getPaymentMethods } from 'store/slices/subscriptionSlice/thunks';

import { breakPoints } from '../../../../constants';

import { TUpdatePaymentMethodProps } from './types';
import styles from './UpdatePaymentMethod.module.scss';

const UpdatePaymentMethod: FC<TUpdatePaymentMethodProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const currentOrganization =
    BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId) ||
    BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId, { session: true });

  const [isCardValid, setIsCardValid] = useState<boolean>(false);
  const {
    handleSubmit: paymentSubmit,
    control: paymentControl,
    watch,
    reset,
    formState: { errors: paymentError, isSubmitting },
  } = useForm();

  const handleCardChange = (event: StripeCardElementChangeEvent) =>
    setIsCardValid(!!event.complete);

  const currentPaymentMethod = useAppSelector(currentPaymentMethodSelector);
  const sendedData = watch();

  const { handleSubmit } = useStripePayment({
    name: sendedData?.name,
    phone: currentPaymentMethod?.billing_details?.phone as string,
    state: currentPaymentMethod?.billing_details?.address?.state as string,
    postal_code: currentPaymentMethod?.billing_details?.address?.postal_code as string,
    orgId: Number(currentOrganization),
    forUpdate: true,
  });

  const isButtonDisabled = isCardValid && !!sendedData?.name?.trim()?.length;

  const handleCardChangeSubmit = async () => {
    await setupPaymentMethod();

    dispatch(getPaymentMethods(Number(currentOrganization)));
    dispatch(getDefaultPaymentMethod(Number(currentOrganization)));
    handleClose();
  };

  const setupPaymentMethod = async () => {
    return await subscriptionsApi
      .setupPaymentIntentRequest(Number(currentOrganization))
      .then((res) => handleSubmit(res?.data?.client_secret));
  };

  const handleClearClose = () => {
    reset({
      name: '',
    });
    handleClose();
  };
  const drawerSizes = Number(width) <= breakPoints.TABLET_M ? 375 : 550;

  return (
    <DrawerLayout
      open={open}
      anchor='right'
      width={drawerSizes}
      onClose={handleClose}
      onCloseReset={handleClearClose}
      headerTitle='Update Payment Method'
      titleClassName={styles.container__head__title}
    >
      <form className={styles.container} onSubmit={paymentSubmit(handleCardChangeSubmit)}>
        <CardDetails
          control={paymentControl}
          handleCardValid={handleCardChange}
          errors={paymentError}
        />
        <CustomTypography className={styles.container__required}>
          *This Field is Mandatory
        </CustomTypography>
        <Box className={styles.container__footer}>
          <Button
            onClick={handleClose}
            padding='11px'
            type='button'
            backgroundColor={Colors.PALE_BLUE}
          >
            CANCEL
          </Button>
          <Button
            type='submit'
            color={Colors.WHITE}
            padding='11px'
            disabled={!isButtonDisabled || isSubmitting}
            backgroundColor={Colors.SAPPHIRE}
          >
            UPDATE
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default UpdatePaymentMethod;
