import { EditIconOutLined, PlusIconBlackIcon, TrashIcon } from 'assets/icons';

import type { TDropDownOptionsParams } from './types';

export const dropDownOptionsCreator = ({
  removeCallback,
  createPOCallback,
  editCallback,
}: TDropDownOptionsParams) => [
  {
    id: 1,
    name: 'Create PO',
    icon: <PlusIconBlackIcon />,
    action: createPOCallback,
  },
  {
    id: 2,
    name: 'Edit',
    icon: <EditIconOutLined />,
    action: editCallback,
  },
  {
    id: 3,
    name: 'Delete',
    icon: <TrashIcon />,
    action: removeCallback,
  },
];
