import { MutableRefObject } from 'react';

import { captureScreenshot } from 'containers/LiveStream/utils';

const captureThumb = async (contentRef: MutableRefObject<HTMLDivElement | null>) => {
  const response = await captureScreenshot(contentRef);

  return response;
};

export default captureThumb;
