import { FC } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';

import { Colors } from 'types';
import { useAppDispatch } from 'hooks';
import { bookDemoSkyHawk } from 'store/slices/landingSlice/thunks';
import {
  Button,
  ControlledInput,
  CustomTypography,
  ModalBoxHeader,
  NumberControlledInput,
} from 'components';

import { bookDemoSchema } from './schema';
import styles from './BookDemoModal.module.scss';

import type { TBookDemoData, TBookDemoFormProps } from './types';

const BookDemoForm: FC<TBookDemoFormProps> = ({
  onClose,
  titleClassName,
  boxClassName,
  forModal = true,
  containerClassName,
}) => {
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TBookDemoData>({
    resolver: yupResolver(bookDemoSchema),
    mode: 'all',
  });

  const containerClasses = classNames(styles.container, containerClassName);

  const titleClasses = classNames(styles.container__title, titleClassName);
  const boxClasses = classNames(styles.container__content, boxClassName);

  const onCloseWithClear = () => {
    onClose?.();
    reset();
  };

  const sendBookingRequest = async (data: TBookDemoData) => {
    await dispatch(
      bookDemoSkyHawk({
        email: data.email,
        full_name: data.fullName,
        note: data.note,
        phone: data.phone,
      }),
    );

    onCloseWithClear();
  };

  return (
    <>
      {forModal && <ModalBoxHeader title='Request Demo' onClose={onCloseWithClear} />}

      <form className={containerClasses} onSubmit={handleSubmit(sendBookingRequest)}>
        {!forModal && <CustomTypography className={titleClasses}>Request Demo</CustomTypography>}

        <Box className={boxClasses}>
          <ControlledInput
            required
            name='fullName'
            borderWidth='1px'
            control={control}
            borderRadius='8px'
            borderColor={Colors.SOFT_SILVER}
            label='Full Name:'
            placeholder='Full Name:'
          />
          <NumberControlledInput
            required
            name='phone'
            label='Phone'
            control={control}
            placeholder='Phone:'
          />

          <ControlledInput
            required
            name='email'
            label='Email:'
            borderWidth='1px'
            control={control}
            borderRadius='8px'
            placeholder='Email:'
            borderColor={Colors.SOFT_SILVER}
          />
          <ControlledInput
            required
            name='note'
            label='Note:'
            borderWidth='1px'
            control={control}
            borderRadius='8px'
            placeholder='Note:'
            borderColor={Colors.SOFT_SILVER}
            backGroundColor={Colors.PLATINUM_GRAY}
          />
        </Box>
        <Box className={styles.container__footer}>
          {forModal && (
            <Button
              type='button'
              onClick={onCloseWithClear}
              borderRadius='4px'
              padding='12px 16px'
              color={Colors.SAPPHIRE}
              backgroundColor={Colors.PASTEL_SKY}
            >
              Cancel
            </Button>
          )}
          <Button
            disabled={isSubmitting}
            type='submit'
            borderRadius='4px'
            padding='12px 16px'
            color={Colors.WHITE}
            backgroundColor={Colors.SAPPHIRE}
          >
            Send
          </Button>
        </Box>
      </form>
    </>
  );
};

export default BookDemoForm;
