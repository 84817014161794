import { EditIconOutLined, FlagIcon, TrashIcon } from 'assets/icons';

import {
  TDropDownOptionsCuratedParams,
  TDropDownOptionsParams,
  TSimpleDropDownOptionsParams,
} from './types';

export const dropdownOptionsMainCreator = ({
  editAction,
  flagAction,
  deleteAction,
}: TDropDownOptionsParams) => [
  { id: 1, name: 'Edit', icon: <EditIconOutLined />, action: editAction },
  { id: 2, name: 'Flag', icon: <FlagIcon />, action: flagAction },
  { id: 3, name: ' Delete', icon: <TrashIcon />, action: deleteAction },
];

export const dropdownOptionsCuratedCreator = ({
  flagAction,
  deleteAction,
}: TDropDownOptionsCuratedParams) => [
  { id: 2, name: 'Flag', icon: <FlagIcon />, action: flagAction },
  { id: 3, name: ' Delete', icon: <TrashIcon />, action: deleteAction },
];

export const dropdownOptionsSimpleCreator = ({ flagAction }: TSimpleDropDownOptionsParams) => [
  { id: 1, name: 'Flag', icon: <FlagIcon />, action: flagAction },
];
