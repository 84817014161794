import { client } from 'api/client';
import { endpoints } from 'api/endpoints';

import type {
  TPurchaseOrdersParams,
  TUpdatePurchaseOrderItem,
  TPurchaseOrderCreateBody,
  TPurchaseOrderItemEdit,
} from 'store/slices/purchaseOrderSlice/types';

export const getAllPurchaseOrdersRequest = (options: TPurchaseOrdersParams) => {
  return client.get(endpoints.PurchaseOrderServices.getAllPurchaseOrders(), {
    params: { ...options },
  });
};

export const createPurchaseOrderRequest = (body: TPurchaseOrderCreateBody) => {
  return client.post(endpoints.PurchaseOrderServices.createPurchaseOrder(), { new_order: body });
};

export const getPurchaseOrderItemsByIdRequest = (id: number) => {
  return client.get(endpoints.PurchaseOrderServices.getPurchaseOrderItemsById(id));
};

export const getPurchaseOrderByIdRequest = (id: number) => {
  return client.get(endpoints.PurchaseOrderServices.getPurchaseOrderById(id));
};

export const getPurchaseOrderByUuidRequest = (id: string) => {
  return client.get(endpoints.PurchaseOrderServices.getPurchaseOrderByUuid(id));
};

export const updatePurchaseOrder = (options: TPurchaseOrderItemEdit) => {
  return client.put(endpoints.PurchaseOrderServices.updatePurchaseOrder(), { ...options });
};

export const updatePurchaseOrderItemRequest = (options: TUpdatePurchaseOrderItem) => {
  return client.put(endpoints.PurchaseOrderServices.updatePurchaseOrderItem(), { ...options });
};

export const receivePurchaseOrderItemRequest = (id: number) => {
  return client.put(endpoints.PurchaseOrderServices.receivePurchaseOrderItem(id));
};
export const submitPurchaseOrderItemRequest = (id: number) => {
  return client.post(endpoints.PurchaseOrderServices.submitPurchaseOrderItem(id));
};

export const deletePurchaseOrderItemRequest = (id: number) => {
  return client.delete(endpoints.PurchaseOrderServices.deletePurchaseOrderItem(id));
};
