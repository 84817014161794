import { TVersionItem } from 'store/slices/catalogSlice/types';
import { VoidCallback } from 'types';

type THeaderButtonsCreatorParams = {
  removeAction: VoidCallback;
  editAction: VoidCallback;
};

export const headerButtonsCreator = ({ editAction, removeAction }: THeaderButtonsCreatorParams) => [
  { id: 1, action: () => removeAction(), name: 'Delete', maxWidth: '76px', minWidth: '76px' },
  { id: 4, action: () => editAction(), name: 'Edit', maxWidth: '59px', minWidth: '59px' },
];

export const generateMockDataArray = (count: number): TVersionItem[] => {
  const mockDataArray: TVersionItem[] = [];

  for (let i = 1; i <= count; i++) {
    mockDataArray.push({
      price: Math.random() * 1000,
      vendor: `Vendor ${i}`,
      part_name: `Part ${i}`,
      description: `Description for Part ${i}`,
      image_url_id: `image-${i}`,
      qty_required: Math.floor(Math.random() * 10) + 1,
      manufacturer: `Manufacturer ${i}`,
      part_id: i % 2 === 0 ? i : null,
      preferred_vendor_name: `Preferred Vendor ${i}`,
    });
  }

  return mockDataArray;
};
