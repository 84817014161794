import { FC, Fragment } from 'react';

import { CustomSkeleton } from 'components';

import type { TNodeOrSkeletonProps } from './types';

const NodeOrSkeleton: FC<TNodeOrSkeletonProps> = ({ isLoading, child, height, width }) => (
  <Fragment>{isLoading ? <CustomSkeleton height={height} width={width} /> : child}</Fragment>
);

export default NodeOrSkeleton;
