import React from 'react';
import classNames from 'classnames';

import { CustomTypography } from 'components';

import styles from './LandingPricing.module.scss';

import type { TLandingPricing } from './types';

const LandingPricing: React.FC<TLandingPricing> = ({ isMonthly, options, isSelected }) => {
  const selectedClasses = classNames(styles.container, { [styles.container_active]: isSelected });

  const renderContent = options.content.map(({ id, name, Icon }) => {
    return (
      <div className={styles.container_content_wrapper} key={id}>
        {Icon}
        <CustomTypography className={styles.container_content_text}>{name}</CustomTypography>
      </div>
    );
  });

  const optionsTitle = isMonthly ? options.monthly : options.yearly;
  let duration;
  if (options.header === 'Enterprise Grid') {
    duration = '';
  } else {
    duration = isMonthly ? 'User/Month' : 'User/Month';
  }

  return (
    <div className={selectedClasses}>
      <div className={styles.container__main}>
        <div className={styles.container_header}>
          <CustomTypography className={styles.container_header_title}>
            {options.header}
          </CustomTypography>
        </div>

        <div className={styles.container_content}>{renderContent}</div>
      </div>
      <div className={styles.container_price}>
        <CustomTypography className={styles.container_price_title}>
          {optionsTitle}
          <span>{duration}</span>
        </CustomTypography>
      </div>
    </div>
  );
};

export default LandingPricing;
