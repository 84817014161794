export const sxStyles = {
  width: '100%',
  borderRadius: '10px',
  zIndex: '4',
  '& .MuiAutocomplete-endAdornment': {
    background: 'red !important',
    position: 'absolute',
    right: '16px !important',

    '& > button': {
      background: 'red !important',
      '&:first-of-type': {
        display: 'none !important',
      },
    },

    '& .MuiAutocomplete-clearIndicator': {
      display: 'none !important',
    },
  },
};
