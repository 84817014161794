import { ChangeEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks';
import { DeactivateIcon, MoreIcon, PencilIcon } from 'assets/icons';
import {
  UserCard,
  DeletePopup,
  ScrollLayout,
  DividedTable,
  ShadowLayout,
  EmployeeStatus,
  EditUserDrawer,
  PortalDropDown,
  DynamicPaginationControl,
} from 'components';
import {
  allUsersDataSelector,
  allUsersLimitSelector,
  allUsersOffsetSelector,
  allUsersLoadSelector,
} from 'store/slices/organizationsSlice/selectors';
import {
  getAllUsers,
  updateOrganizationMemberStatus,
} from 'store/slices/organizationsSlice/thunks';
import { TUserProfile } from 'store/slices/authSlice/types';
import { getAllRoles } from 'store/slices/rolesSlice/thunks';
import { setLimit, setPage } from 'store/slices/organizationsSlice';
import { StatusEnums } from 'components/shared/EmployeeStatus/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { userDataSelector, userDesignationSelector } from 'store/slices/authSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';

import { columns } from './options';
import styles from './EmployeeTable.module.scss';

const EmployeeTable = () => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(userDataSelector);
  const newData = useAppSelector(allUsersDataSelector);
  const userRole = useAppSelector(userDesignationSelector);
  const organizationMembersLimit = useAppSelector(allUsersLimitSelector);
  const organizationMembersOffset = useAppSelector(allUsersOffsetSelector);

  const organizationMembersLoading = useAppSelector(allUsersLoadSelector);
  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenActivateModal, setIsOpenActivateModal] = useState<boolean>(false);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<TUserProfile | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllUsers({ limit: organizationMembersLimit, offset: organizationMembersOffset }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationMembersOffset, organizationMembersLimit, currentOrganization]);

  const dropDownOptionsNotActive = [
    { id: 1, name: 'Edit', icon: <PencilIcon />, action: () => openEditDrawer() },
    {
      id: 2,
      name: 'Activate',
      icon: <DeactivateIcon />,
      action: () => setIsOpenActivateModal(true),
    },
  ];

  const dropDownOptionsActive = [
    { id: 1, name: 'Edit', icon: <PencilIcon />, action: () => openEditDrawer() },
    { id: 2, name: 'Deactivate', icon: <DeactivateIcon />, action: () => openRemoveModal() },
  ];

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  const updateEmployeeStatus = (status: StatusEnums) => {
    dispatch(
      updateOrganizationMemberStatus({
        status,
        member_id: selectedUserId as number,
        org_id: Number(currentOrganization),
      }),
    );
    isOpenModal && setIsOpenModal(!isOpenModal);
    isOpenActivateModal && setIsOpenActivateModal(false);
  };

  const selectedUser = newData?.data?.find((user) => user.id === selectedUserId);

  useEffect(() => {
    setCurrentUser(selectedUser || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const rows = newData?.data?.map((item, idx) => {
    const statusValues = !item?.is_active ? StatusEnums.DEACTIVATE : StatusEnums.ACTIVE;

    return {
      id: `${item?.id}/${idx}`,
      role: item.designation || '--',
      options:
        item?.id !== userInfo?.id && item?.designation !== 'owner' ? (
          <PortalDropDown
            getSelectedId={setSelectedUserId}
            selectedId={item.id}
            title={<MoreIcon className={styles.container__icon} />}
            options={item?.is_active ? dropDownOptionsActive : dropDownOptionsNotActive}
          />
        ) : (
          ''
        ),
      email: item?.email || '--',
      name:
        (
          <UserCard
            width={24}
            height={24}
            userName={item?.name}
            userImg={item?.profile_image_url_id}
          />
        ) || '--',
      status: (
        <EmployeeStatus status={statusValues} title={item?.is_active ? 'Active' : 'Inactive'} />
      ),
    };
  });

  const rowsManager = newData?.data?.map((item, idx) => {
    const statusValues = !item?.is_active ? StatusEnums.DEACTIVATE : StatusEnums.ACTIVE;

    return {
      id: `${item?.id}/${idx}`,
      role: item.designation || '--',
      options:
        item?.id !== userInfo?.id && item?.designation === 'employee' ? (
          <PortalDropDown
            title={<MoreIcon className={styles.container__icon} />}
            getSelectedId={setSelectedUserId}
            selectedId={item?.id}
            options={item?.is_active ? dropDownOptionsActive : dropDownOptionsNotActive}
          />
        ) : (
          ''
        ),
      email: item.email || '--',
      name: (
        <UserCard
          width={24}
          height={24}
          userName={item?.name}
          userImg={item?.profile_image_url_id}
        />
      ),
      status: (
        <EmployeeStatus status={statusValues} title={item?.is_active ? 'Active' : 'Inactive'} />
      ),
    };
  });

  const renderedRows = userRole === 'owner' ? rows : rowsManager;

  const openEditDrawer = () => setIsOpenEditDrawer(true);

  const openRemoveModal = () => {
    setIsOpenModal(true);
  };

  const handlePageChange = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const columnsWithSorting: GridColDef[] = columns.map((column) => {
    const isColumnSorted = newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const organizationMembersCount = newData?.total_count;

  const popUpTitle = StatusEnums.DEACTIVATE ? 'Confirm Deactivation' : 'Confirm Activation';

  const bodyText = StatusEnums.DEACTIVATE
    ? 'Please confirm to deactivate the employee.'
    : 'Please confirm to activate the employee.';

  useEffect(() => {
    if (!organizationMembersCount && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationMembersCount]);

  return (
    <>
      <Box className={styles.container}>
        <ShadowLayout>
          <ScrollLayout>
            <DividedTable
              layoutHeight={544}
              rows={renderedRows || []}
              columns={columnsWithSorting}
              emptyTitle={EmptyTitles.Employees}
              isLoading={organizationMembersLoading}
              onColumnHeaderClick={handleColumnHeaderClick}
              sortModel={[{ field: newSortBy || 'name', sort: asc ? 'asc' : 'desc' }]}
            />
          </ScrollLayout>
        </ShadowLayout>
        {!!organizationMembersCount && (
          <Box padding='20px 0'>
            <DynamicPaginationControl
              page={currentPage}
              onPageChange={handlePageChange}
              count={organizationMembersCount}
              rowsPerPage={organizationMembersLimit}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>
      <DeletePopup
        withBody
        body={bodyText}
        title={popUpTitle}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onDelete={() => updateEmployeeStatus(StatusEnums.DEACTIVATE)}
      />

      <DeletePopup
        withBody
        body='Please confirm to activate the employee.'
        title='Confirm Activation'
        isOpen={isOpenActivateModal}
        onClose={() => setIsOpenActivateModal(false)}
        onDelete={() => updateEmployeeStatus(StatusEnums.ACTIVE)}
      />

      <EditUserDrawer
        open={isOpenEditDrawer}
        user={currentUser as TUserProfile}
        handleClose={() => setIsOpenEditDrawer(false)}
      />
    </>
  );
};

export default EmployeeTable;
