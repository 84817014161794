import { Fragment, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import Hamburger from 'hamburger-react';

import { CustomTypography } from 'components';
import { useAppDispatch, useAppSelector, useOnClickOutside, useUserOrganizationsList } from 'hooks';
import {
  navbarItems,
  navbarItemsOwner,
  navbarItemsManager,
  navbarItemsEmployees,
  navbarItemsManagerEmployees,
  navbarItemsOwnerCreated,
} from 'constants/AccountSettings';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { AccountSettingsNavbarItems, MyItems } from 'constants/AccountSettings/types';
import {
  userDataSelector,
  userDesignationSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';
import { setOrganizationLoadValue } from 'store/slices/optionsSlice';

import styles from './AccountSettings.module.scss';

const myItemsTitle = MyItems.ACTIVITY_FEED;

const AccountSettings = () => {
  const dispatch = useAppDispatch();

  const userRole = useAppSelector(userDesignationSelector);
  const userData = useAppSelector(userDataSelector);

  const { currentOrganization, getAllOrganizations } = useUserOrganizationsList();

  const { isSendInvite } = useAppSelector(userPermissionsSelector);

  const activeSettingsTitleFromStorage = BrowserStorageService.get(
    BrowserStorageKeys.ActiveSettingsTitle,
    { session: true },
  );

  const [title, setTitle] = useState<string>(
    activeSettingsTitleFromStorage || AccountSettingsNavbarItems.PROFILE,
  );

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (activeSettingsTitleFromStorage && userRole === 'owner') {
      setTitle(activeSettingsTitleFromStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole]);

  useEffect(() => {
    dispatch(setOrganizationLoadValue(false));
    getAllOrganizations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickTitle = (title: string) => {
    dispatch(setOrganizationLoadValue(false));

    setTitle(title);
    BrowserStorageService.set(BrowserStorageKeys.ActiveSettingsTitle, title, { session: true });
    setShowDropdown(false);
    if (title !== AccountSettingsNavbarItems.VIDEOS) {
      setIsMenuOpen(false);
    }

    if (title === AccountSettingsNavbarItems.VIDEOS) {
      setShowDropdown(!showDropdown);
    }
  };

  const handleClickMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useOnClickOutside(menuRef, closeMenu);

  const renderedItems =
    userRole === 'owner' && currentOrganization?.created_by === userData?.id
      ? navbarItemsOwnerCreated
      : userRole === 'owner' && currentOrganization?.created_by !== userData?.id
      ? navbarItemsOwner
      : userRole === 'manager' && !isSendInvite
      ? navbarItemsManager
      : userRole === 'manager' && isSendInvite
      ? navbarItemsManagerEmployees
      : userRole === 'employee' && isSendInvite
      ? navbarItemsEmployees
      : navbarItems;

  const navbarItemsRender = renderedItems?.map((item) => {
    const navbarItemClasses = classNames(styles.container__navbar__items__item, {
      [styles.container__navbar__items__item_active]: title === item.title,
      [styles.container__navbar__items__item_myitem]:
        item.title === AccountSettingsNavbarItems.VIDEOS,
    });

    return (
      <Fragment key={item.id}>
        <div
          onClick={() => handleClickTitle(item.title)}
          className={navbarItemClasses}
          key={item.id}
        >
          <CustomTypography
            className={styles.container__navbar__items__item__title}
            fontWeight={400}
          >
            {myItemsTitle === MyItems.ACTIVITY_FEED && item.id === 2 ? myItemsTitle : item.title}
          </CustomTypography>
        </div>
      </Fragment>
    );
  });

  const contentRender = renderedItems?.map((item) => {
    return <Fragment key={item.id}>{title === item.title && item.content}</Fragment>;
  });

  return (
    <Box className={styles.container}>
      <CustomTypography className={styles.container__title}>Account Settings</CustomTypography>
      <Box className={styles.container__navbar}>
        <div className={styles.container__navbar__items}>{navbarItemsRender}</div>
        <div ref={menuRef} className={styles.container__navbar__ref}>
          <div className={styles.container__navbar__menu}>
            <Hamburger size={20} onToggle={handleClickMenu} toggled={isMenuOpen} />
          </div>
          {isMenuOpen && (
            <div className={styles.container__navbar__menu__items}>{navbarItemsRender}</div>
          )}
        </div>
        <div className={styles.container__navbar__content}>{contentRender}</div>
      </Box>
    </Box>
  );
};
export default AccountSettings;
