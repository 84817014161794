import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { schedulingApi } from 'api';

import { TAllSchedulesRequestType } from './types';

export const getAllSchedules = createAsyncThunk(
  'scheduleSlice/getAllSchedules',
  async (options: TAllSchedulesRequestType) => {
    try {
      const response = await schedulingApi.getAllSchedules(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
