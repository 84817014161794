import { TRequisition } from 'store/slices/requisitionSlices/types';

import { getValue } from '../getValue';

const requisitionFormValuesGenerator = (inEditMode: boolean, itemData: TRequisition) => {
  const items = itemData.items?.map((el) => {
    return { description: el.description, id: el.id, itemNo: el.name, quantity: el.quantity };
  });

  return {
    description: getValue(inEditMode, itemData?.description),
    requisitionItems: items,
  };
};

export type TRequisitionFormValuesGeneratorReturn = ReturnType<
  typeof requisitionFormValuesGenerator
>;

export default requisitionFormValuesGenerator;
