import { FC } from 'react';

import { CustomTypography } from 'components';

import styles from './InventoryCard.module.scss';

import type { TInventoryCardDescriptionProps } from './types';

const InventoryCardDescription: FC<TInventoryCardDescriptionProps> = ({ title, subTitle }) => (
  <div className={styles.container__content__items}>
    <CustomTypography fontSize='14px'>{title}:</CustomTypography>
    <span title={subTitle} className={styles.container__content__items__subtitle}>
      {subTitle}
    </span>
  </div>
);

export default InventoryCardDescription;
