import { BrowserStorageKeys, BrowserStorageOptions } from './types';

const BrowserStorageService = {
  get: (field: BrowserStorageKeys, options?: BrowserStorageOptions): string | null => {
    const storage = options?.session ? sessionStorage : localStorage;

    return storage.getItem(field);
  },

  set: (field: BrowserStorageKeys, value: string, options?: BrowserStorageOptions): void => {
    const storage = options?.session ? sessionStorage : localStorage;

    storage.setItem(field, value);
  },

  remove: (field: BrowserStorageKeys, options?: BrowserStorageOptions): void => {
    const storage = options?.session ? sessionStorage : localStorage;

    storage.removeItem(field);
  },

  update: (field: BrowserStorageKeys, value: string, options?: BrowserStorageOptions): void => {
    const storage = options?.session ? sessionStorage : localStorage;

    if (storage.getItem(field)) {
      storage.removeItem(field);
      storage.setItem(field, value);
    }
  },

  updatePromisify: (field: BrowserStorageKeys, value: string): Promise<void> =>
    new Promise((resolve) => {
      if (sessionStorage.getItem(field)) {
        sessionStorage.removeItem(field);
        sessionStorage.setItem(field, value);
        resolve();
      } else {
        localStorage.removeItem(field);
        localStorage.setItem(field, value);
        resolve();
      }
    }),
};

export default BrowserStorageService;
