import { useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'hooks';
import {
  TUpdateSubscriptionParams,
  UserSubscriptionStatus,
} from 'store/slices/subscriptionSlice/types';
import {
  getDefaultPaymentMethod,
  getPaymentMethods,
  getUserSubscriptions,
  updateSubscription,
} from 'store/thunks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { currentPaymentMethodSelector } from 'store/slices/subscriptionSlice/selectors';
import { SubscriptionUpdateToastMessages } from 'constants/ToastMessages';

import { TUSeSubscriptionUpdateParams, TUpdatedData } from './types';

const useUpdateSubscription = ({
  org_id,
  subscription_id,
  subscription,
}: TUSeSubscriptionUpdateParams) => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();

  const currentPaymentMethod = useAppSelector(currentPaymentMethodSelector);

  const subscriptionClientSecret = BrowserStorageService.get(
    BrowserStorageKeys.subscriptionClientSecret,
  );

  const handleChangeSubscription = async () => {
    const start = toast.loading(SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_START);

    const newSubscription: TUpdateSubscriptionParams = {
      org_id: Number(org_id),
      subscription_id: subscription_id,
      subscription: subscription,
    };

    const response = (await dispatch(
      updateSubscription({ ...newSubscription, toast: start }),
    ).unwrap()) as unknown as TUpdatedData;

    if (subscriptionClientSecret) {
      BrowserStorageService.update(
        BrowserStorageKeys.subscriptionClientSecret,
        response.data?.subscription,
      );
    } else {
      BrowserStorageService.set(
        BrowserStorageKeys.subscriptionClientSecret,
        response.data?.subscription,
      );
    }

    if (response?.type === 'updated_subscription') {
      dispatch(
        getUserSubscriptions({
          org_id: Number(org_id),
          subscription_status: UserSubscriptionStatus.ACTIVE,
        }),
      );
    } else {
      if (stripe && response?.data?.client_secret) {
        stripe
          .confirmCardPayment(response.data.client_secret, {
            payment_method: currentPaymentMethod?.id,
          })
          .then(function (result) {
            if (result.error) {
              toast.update(start, {
                render: SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_FAILURE,
                type: 'error',
                isLoading: false,
                autoClose: 3000,
              });
            } else {
              toast.update(start, {
                render: SubscriptionUpdateToastMessages.SUBSCRIPTION_UPDATE_SUCCESS,
                type: 'success',
                isLoading: false,
                autoClose: 3000,
              });
            }
          })
          .finally(() => {
            dispatch(
              getUserSubscriptions({
                org_id: Number(org_id),
                subscription_status: UserSubscriptionStatus.ACTIVE,
              }),
            );
          });
      }
    }
  };

  useEffect(() => {
    dispatch(getPaymentMethods(Number(org_id)));
    dispatch(getDefaultPaymentMethod(Number(org_id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { handleChangeSubscription };
};

export default useUpdateSubscription;
