const checkDataChanges = <K, T extends Record<string, K>>(
  data: T,
  watched: T,
): { changed: Partial<T>; previous: Partial<T>; mixedUpFormData: Record<string, K> } => {
  const changedItems: Partial<T> = {};
  const previousItems: Partial<T> = {};
  const previousItemsToString: Record<string, string> = {};
  let mixedUpFormData: Record<string, K> = {};

  for (const key in data) {
    if (watched[key] !== undefined && data[key] !== watched[key]) {
      changedItems[key] = data[key];
    } else {
      previousItems[key] = data[key];
      previousItemsToString[key] = '';
    }
  }

  mixedUpFormData = { ...changedItems, ...previousItemsToString };

  return { changed: changedItems, previous: previousItems, mixedUpFormData };
};

export default checkDataChanges;
