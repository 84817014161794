import { FC, useRef } from 'react';

import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import PreloadedImg from 'components/views/PreloadedImg';

import styles from './PostImagePreview.module.scss';
import { TPostImagePreviewProps } from './types';

const PostImagePreview: FC<TPostImagePreviewProps> = ({ imgSrc, contained = false }) => {
  const imgRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className={styles.container}>
      {contained && (
        <div className={styles.container_image}>
          <PreloadedImg folderName={ImgUrlFolders.ACTIVITY_FEED} imgId={imgSrc} />
        </div>
      )}

      <div className={styles.container__post} ref={imgRef}>
        <PreloadedImg folderName={ImgUrlFolders.ACTIVITY_FEED} imgId={imgSrc} />
      </div>
    </div>
  );
};

export default PostImagePreview;
