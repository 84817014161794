import { Box } from '@mui/material';
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import localforage from 'localforage';

import { Colors, FontNames, Routes } from 'types';
import { Button, CustomTypography } from 'components';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import styles from './SignUpSuccessPage.module.scss';

const SignUpSuccessPage = () => {
  const navigate = useNavigate();

  const activeExistedvalue = BrowserStorageService.get(BrowserStorageKeys.ProcessFormValue, {
    session: true,
  });

  const tokenInLocalStorage = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const goToLandingPage = () => {
    toast.success('You will receive authentication email shortly');

    localStorage.clear();
    localforage.clear();

    sessionStorage.clear();
    navigate(Routes.Landing);
  };

  if (tokenInLocalStorage) {
    return <Navigate replace={true} to={Routes.ActivityFeed} />;
  }

  if (activeExistedvalue !== '3' && !tokenInLocalStorage) {
    return <Navigate replace={true} to={Routes.ProcessForm} />;
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.container__content}>
        <CustomTypography className={styles.container__content__title}>
          Thank you for setting up the organization.
        </CustomTypography>
        <CustomTypography className={styles.container__content__subtitle}>
          Your organization is being created. Please check your email to login to your organization
          account.
        </CustomTypography>
      </Box>
      <Button
        minWidth='148px'
        borderRadius='4px'
        isUppercase={false}
        padding='12px 16px'
        color={Colors.WHITE}
        onClick={goToLandingPage}
        backgroundColor={Colors.SAPPHIRE}
        fontFamily={FontNames.CIRCULAR_BOLD}
      >
        Go to home page
      </Button>
    </Box>
  );
};

export default SignUpSuccessPage;
