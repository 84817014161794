import { RootState } from 'types';

export const invoicesSelector = (state: RootState) => state.invoices.invoices;
export const invoicesLoadingSelector = (state: RootState) => state.invoices.invoicesLoading;

export const invoicesLimitSelector = (state: RootState) => state.invoices.invoiceLimit;

export const invoicesOffsetSelector = (state: RootState) => state.invoices.invoiceOffset;

export const invoiceAscSelector = (state: RootState) => state.invoices.invoiceAsc;

export const currentInvoiceByIdSelector = (state: RootState) => state.invoices.currentInvoiceById;

export const currentAgreementByIdLoadSelector = (state: RootState) =>
  state.invoices.currentInvoiceByIdLoad;
