import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';

import { rolesApi } from 'api';

import type { TOrganizationRoleData } from './types';

const useOrganizationNameById = () => {
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);
  const [data, setData] = useState<TOrganizationRoleData[]>([]);

  const getOrganizationsRoles = async () => {
    try {
      setIsLoad(true);
      const response = await rolesApi.getAllRolesRequest('organization');

      setData(response?.data);
      setIsLoad(false);
    } catch (error) {
      const Error = error as AxiosError;
      setError(Error);
      setIsLoad(false);
    }
  };

  useEffect(() => {
    getOrganizationsRoles();
  }, []);

  const identifyName = (id: number) => {
    const foundedName = data?.find((role) => role?.id === id);

    return foundedName?.role;
  };

  return { identifyName, data, isLoad, error };
};

export default useOrganizationNameById;
