import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { Routes } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

const ProtectedWithSession = ({ children }: { children: ReactNode }) => {
  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  if (!token) {
    return <Navigate to={Routes.Landing} replace />;
  }

  return children;
};

export default ProtectedWithSession;
