import { FC } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';

import { useAppSelector } from 'hooks';
import { userAllRolesLoadingSelector } from 'store/slices/authSlice/selectors';

import styles from './ShowAfterAccessView.module.scss';

import type { TShowAfterAccessViewProps } from './types';

const ShowAfterAccessView: FC<TShowAfterAccessViewProps> = ({
  isShow,
  children,
  emptyMessageTitle,
}) => {
  const isLoad = useAppSelector(userAllRolesLoadingSelector);

  if (isLoad) {
    return (
      <Stack justifyContent='center' alignItems='center' margin='20px'>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      {isShow && !isLoad ? (
        children
      ) : (
        <Box className={styles.container}>{`You don't have access to ${emptyMessageTitle}`}</Box>
      )}
    </>
  );
};

export default ShowAfterAccessView;
