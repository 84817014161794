import 'swiper/css';
import 'swiper/css/pagination';
import './styles.scss';

import { FC } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import classNames from 'classnames';

import { TSlidePaginationProps } from './types';

const SlidePagination: FC<TSlidePaginationProps> = ({ elements, className }) => {
  const slideElements = elements?.map((element, idx) => (
    <SwiperSlide key={idx}>{element}</SwiperSlide>
  ));

  const swiperClasses = classNames('mySwiper', className);

  return (
    <Swiper
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className={swiperClasses}
    >
      {slideElements}
    </Swiper>
  );
};

export default SlidePagination;
