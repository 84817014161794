import { useEffect, type FC, useState, BaseSyntheticEvent, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { GridColDef } from '@mui/x-data-grid';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AutocompleteInputChangeReason, Box, IconButton, Menu, MenuItem } from '@mui/material';

import { deepEqual } from 'utils/deepEqual';
import { useAppDispatch, useAppSelector, useOnClickOutside } from 'hooks';
import { estimatesSelector } from 'store/slices/estimatesSlice/selectors';
import {
  Button,
  DeletePopup,
  ScrollLayout,
  CustomDataGrid,
  ControlledInput,
  ProfitMarginDrawer,
  CustomSkeleton,
} from 'components';
import { Colors, Routes } from 'types';
import { bomEstimateFormValuesGenerator } from 'utils';
import {
  editEstimate,
  getCustomerById,
  getEstimateByUuid,
  getCouponByCatalogId,
  deleteEstimateItemSection,
  getCurrentSectionVersions,
  editEstimateSectionItemById,
} from 'store/thunks';
import { EmptyTitles } from 'constants/EmptyTitles';
import { clearDiscountError, setDiscount, setDiscountError } from 'store/slices/estimatesSlice';
import { sxMenuStyles } from 'containers/BomEstimate/styles';
import { couponsSelector } from 'store/slices/discountSlice/selectors';
import { columns } from 'containers/GenerateEstimate/utils';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { currentSectionVersionsSelector } from 'store/slices/catalogSlice/selectors';
import { ArrowCircleDown, EditIconOutLined, MoreIcon, TrashIcon } from 'assets/icons';

import { schema } from './schema';
import styles from './BomEstimateItem.module.scss';
import { defaultCustomerInfo, estimateInfoDetailMock, paymentScheduleData } from './utils';
import BomEstimateItemHeader from './BomEstimateItemHeader/BomEstimateItemHeader';
import BomEstimateFooter from './BomEstimateFooter';

import type { TValues } from 'components/shared/EstimateForms/types';
import type { TEstimateEdit } from 'store/slices/estimatesSlice/types';
import type { TCustomerChangesReason, TCustomerDefaultValues } from './types';
import type { TRow } from 'containers/PurchaseOrderItems/PurchaseOrderItemsTableEdit/types';
import type { TBomEstimateFormValuesGeneratorReturn } from 'utils/formsInitialValues/bomEstimateForm';

const BomEstimateItem: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const currentCustomer = useAppSelector(currentCustomerSelector);
  const coupons = useAppSelector(couponsSelector).couponForCatalog;
  const versions = useAppSelector(currentSectionVersionsSelector);
  const { discount, estimateItem, estimateSectionItemsLoader, discountCouponError } =
    useAppSelector(estimatesSelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState<boolean>(false);
  const [changeReason, setChangeReason] = useState(defaultCustomerInfo);
  const [selectedDetailId, setSelectedDetailId] = useState<number[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>(discount.inputValue as string);
  const [selectedSectionItemId, setSelectedSectionItemId] = useState<number | null>(null);
  const [isProfitMarginDrawerOpen, setIsProfitMarginDrawerOpen] = useState<boolean>(false);
  const [customerValues, setCustomerValues] = useState<TCustomerDefaultValues>(defaultCustomerInfo);

  useEffect(() => {
    if (!inputValue) {
      dispatch(clearDiscountError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  let totalAmount = 0;

  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClose = () => setAnchorEl(null);

  useOnClickOutside(menuRef, handleClose);

  const handleMoreClick = (event: BaseSyntheticEvent, id: number) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
    setSelectedSectionItemId(id);
  };

  const openProfitMarginDrawer = () => setIsProfitMarginDrawerOpen(true);
  const closeProfitMarginDrawer = () => setIsProfitMarginDrawerOpen(false);
  const handleChange = (event: BaseSyntheticEvent) => setInputValue(event?.target.value);

  const {
    control,
    handleSubmit,
    formState: { errors, defaultValues, isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(schema),
    values: bomEstimateFormValuesGenerator({ currentCustomer, selectedEstimate: estimateItem }),
  });

  useEffect(() => {
    if (id) {
      const getEstimate = async () => {
        const response = await dispatch(getEstimateByUuid(id));
        if (response.meta.requestStatus !== 'fulfilled') {
          navigate(Routes.BomEstimate);
        }
      };

      getEstimate();
    }

    setInputValue('');
    dispatch(getCustomerById(estimateItem?.customer_id as number));
    if (estimateItem?.catalog_versions_id) {
      dispatch(getCouponByCatalogId(estimateItem?.catalog_id as number));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, estimateItem?.customer_id, estimateItem?.catalog_versions_id, estimateItem?.catalog_id]);

  const slicePhoneNumber = (phoneNumber: string) => {
    if (!phoneNumber) return;

    return phoneNumber?.length === 15 ? phoneNumber.slice(0, -1) : phoneNumber;
  };

  const slicedBillValue = slicePhoneNumber(customerValues?.bill_to.phone_number);
  const slicedCustomerValue = slicePhoneNumber(customerValues?.customer_info.phone_number);

  const chooseChangedReason = (
    reason: AutocompleteInputChangeReason,
    name: keyof TCustomerChangesReason,
  ) => {
    setChangeReason({ ...changeReason, [name]: reason });
  };

  const navigateToGenerate = () => navigate(`/work-flow/bom-estimate/estimate/${id}/generate`);

  const handleChangeId = (id: number) => {
    const filteredItem = selectedDetailId.filter((el) => el !== id);
    selectedDetailId.includes(id)
      ? setSelectedDetailId(filteredItem)
      : setSelectedDetailId([...selectedDetailId, id]);
  };

  const handleChangeSectionId = (id: number) => {
    id === selectedSectionId ? setSelectedSectionId(null) : setSelectedSectionId(id);
  };

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const changePaymentStatus = () => setIsPaymentOpen(!isPaymentOpen);

  const onEditEstimate: SubmitHandler<TBomEstimateFormValuesGeneratorReturn> = async (data) => {
    const sendedDataNew = {
      id: estimateItem?.id,
      name: estimateItem?.name,
      customer_id: data?.from_customer?.customer_id,
      version_no: estimateItem?.version_no,
      status: estimateItem?.status,
      bill_to: {
        bill_to: customerValues.bill_to.bill_to || data?.from_customer?.bill_to,
        phone_number: slicedBillValue || data?.from_customer?.bill_phone_number,
        address: customerValues.bill_to.address || data?.from_customer?.bill_address,
        city: customerValues.bill_to.city || data?.from_customer?.bill_city,
        state: customerValues.bill_to.state || data?.from_customer?.bill_state,
        zip: customerValues.bill_to.zip || data?.from_customer?.bill_zip,
      },
      phone: data?.from_customer?.phone_number,
      address: data?.from_customer?.address,
      city: data?.from_customer?.city,
      state: data?.from_customer?.state,
      zip: data?.from_customer?.zip,
      general_info: data?.general_info,
      customer_info: {
        full_name: customerValues.customer_info.full_name || data?.from_customer?.full_name,
        email: customerValues.customer_info.email || data?.from_customer?.email,
        phone_number: slicedCustomerValue || data?.from_customer?.phone_number,
        address: customerValues.customer_info.address || data?.from_customer?.address,
        city: customerValues.customer_info.city || data?.from_customer?.city,
        state: customerValues.customer_info.state || data?.from_customer?.state,
        zip: customerValues.customer_info.zip || data?.from_customer?.zip,
      },
      sections: data.sections,
      coupon_code: discount?.inputValue || '',
    };

    const edit = await dispatch(
      editEstimate({ id: Number(estimateItem?.id), body: sendedDataNew as TEstimateEdit }),
    );

    if (edit.meta.requestStatus === 'fulfilled') {
      navigateToGenerate();
    }
  };

  const allValuesDefined = _.every(defaultValues, (value: TValues) => !_.isUndefined(value));

  const paymentItemClasses = classNames(styles.container__main__content__schedule, {
    [styles.container__main__content__schedule__open]: isPaymentOpen,
  });

  const renderDetails = estimateInfoDetailMock.map((el) => {
    const renderDetailsIconClasses = classNames(styles.container__main__content__item__icon, {
      [styles.container__main__content__item__icon__open]: selectedDetailId?.includes(el?.id),
    });

    return (
      <Box key={el.id}>
        <Box
          className={styles.container__main__content__item}
          onClick={() => handleChangeId(el.id)}
        >
          <ArrowCircleDown width={24} height={24} className={renderDetailsIconClasses} />
          {el.title}
        </Box>
        {!!defaultValues && allValuesDefined && selectedDetailId.includes(el.id) && (
          <Box>
            {
              <el.Component
                isInEditMode
                errors={errors}
                control={control}
                setValues={setCustomerValues}
                chooseChangedReason={chooseChangedReason}
                values={customerValues as TCustomerDefaultValues}
              />
            }
          </Box>
        )}
      </Box>
    );
  });

  const dropdownItems = [
    {
      icon: <EditIconOutLined />,
      label: 'Edit',
      paramsAction: (id: number) => {
        setSelectedSectionItemId(id);
        setIsEditing(true);
      },
    },
    {
      icon: <TrashIcon />,
      label: 'Delete',
      paramsAction: togglePopup,
    },
  ];

  const renderMenuItem = dropdownItems.map((item, idx) => {
    const onClick = () => {
      selectedSectionItemId && item.paramsAction(selectedSectionItemId);
      handleClose();
    };

    return (
      <MenuItem key={idx} onClick={onClick}>
        {item.icon}
        {item.label}
      </MenuItem>
    );
  });

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    editable: isEditing && column.field !== 'amount' && column.field !== 'item',
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const deleteItem = () => {
    dispatch(
      deleteEstimateItemSection({
        item_id: selectedSectionItemId as number,
        estimate_section_id: selectedSectionId as number,
        estimate_id: estimateItem?.id as number,
      }),
    );
  };

  const handleRowClick = () => {
    setHasUnsavedChanges(true);
  };

  const processRowUpdate = async (newRow: TRow, oldRow: TRow) => {
    if (!deepEqual(newRow, oldRow)) {
      const updatedRow = { ...newRow, isNew: false };

      // const selectedSectionItem = estimateItem?.sections
      //   ?.find((section) => section?.id === selectedSectionId)
      //   ?.items?.find((item) => item?.id === selectedSectionItemId); Todo Hamo

      dispatch(
        editEstimateSectionItemById({
          estimate_id: estimateItem?.id as number,
          estimate_section_id: Number(selectedSectionId),
          item_id: Number(selectedSectionItemId),
          price: newRow?.price,
          quantity: newRow?.quantity,
          description: newRow.description,
          part_name: newRow.name,
        }),
      );

      setHasUnsavedChanges(false);

      return updatedRow;
    } else {
      return newRow;
    }
  };

  const renderSections = estimateItem?.sections?.map((section) => {
    let totalSectionAmount = 0;

    const renderSectionIconClasses = classNames(styles.container__main__content__item__icon, {
      [styles.container__main__content__item__icon__open]: selectedSectionId === section?.id,
    });

    const renderBlockClasses = classNames(styles.container__main__content__item__table, {
      [styles.container__main__content__item__table__open]: selectedSectionId === section.id,
    });

    const renderRows = section.items.map((el) => {
      const itemId = !isNaN(el.part_id as number) && generateInventoryItemNumber(el.id);

      const priceValue = Number(el?.price);

      totalSectionAmount += priceValue * Number(el.qty_required);

      const nodeOrSkeleton = (child: string) =>
        estimateSectionItemsLoader ? <CustomSkeleton height='10px' width='100px' /> : child;

      return {
        id: el.id,
        name: nodeOrSkeleton(el?.part_name),
        item: nodeOrSkeleton(itemId ? `#${itemId}` : '--'),
        description: nodeOrSkeleton(el.description || '--'),
        price: nodeOrSkeleton(String(priceValue) || '0'),
        quantity: nodeOrSkeleton(el?.qty_required),
        amount: nodeOrSkeleton(Number(el.amount).toFixed(2)),

        options: !isEditing ? (
          <IconButton
            sx={{ padding: '3px', margin: '0 auto' }}
            onClick={(event) => handleMoreClick(event, el.id)}
          >
            <MoreIcon className={styles.container__icon} />
          </IconButton>
        ) : null,
      };
    });

    totalAmount += totalSectionAmount;

    return (
      <Box key={section?.id} className={styles.container__main__content__section}>
        <Box
          className={styles.container__main__content__item}
          onClick={() => handleChangeSectionId(section.id)}
        >
          <ArrowCircleDown width={24} height={24} className={renderSectionIconClasses} />
          {section.section_name}
        </Box>
        <Box className={renderBlockClasses}>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={44}
              editMode='cell'
              rows={renderRows}
              headerHeight={44}
              columns={renderColumns}
              onRowClick={handleRowClick}
              processRowUpdate={processRowUpdate}
              emptyTitle={EmptyTitles.Estimate_Items}
            />
          </ScrollLayout>
          {isEditing && (
            <Box className={styles.container__main__content__section__save}>
              <Button
                backgroundColor={Colors.SAPPHIRE}
                color={Colors.WHITE}
                borderRadius='5px'
                disabled={hasUnsavedChanges}
                onClick={() => setIsEditing(false)}
              >
                Save
              </Button>
            </Box>
          )}
        </Box>

        <Menu
          ref={menuRef}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={sxMenuStyles}
        >
          {renderMenuItem}
        </Menu>
      </Box>
    );
  });

  useEffect(() => {
    estimateItem?.catalog_versions_id &&
      dispatch(getCurrentSectionVersions(estimateItem?.catalog_versions_id as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateItem?.catalog_versions_id]);

  const totalAmountFormatted = totalAmount.toFixed(2);

  const handleApplyCoupon = () => {
    const currentDate = new Date();

    const filteredData = coupons?.data?.filter((coupon) => {
      const endDate = new Date(coupon.end_date);
      return coupon.coupon_status === 'active' && endDate > currentDate;
    });

    const foundedCoupon = filteredData?.find((coupon) => coupon.coupon_code === inputValue);

    const discount = (
      (Number(totalAmountFormatted) * (foundedCoupon?.discount_percent as number) || 0) / 100
    ).toFixed(2);

    if (foundedCoupon) {
      dispatch(setDiscount({ discount, inputValue }));
    } else {
      dispatch(setDiscountError());
    }
  };

  const formattedSchedule = versions?.payment_schedule.schedule.stages.map((el) => {
    return {
      percent: `${el.percentage_complete}%`,
      amount: `$ ${(Number(totalAmountFormatted) * el.percentage_complete) / 100} /-`,
    };
  });

  const aggregatedData = paymentScheduleData.map((header) => {
    const values = formattedSchedule?.map((item: any) => item[header.value.toLowerCase()]); // Get the values for the specific header

    return {
      title: header.title,
      id: header.id,
      values,
    };
  });

  const renderPaymentScheduleItem = aggregatedData.map(({ title, values, id }) => {
    const renderItems = values?.map((item, index) => (
      <ControlledInput
        asText
        key={index}
        focused={false}
        readonly
        control={control}
        placeholder={item}
        fromPayment={true}
        borderRadius='10px'
        name='payment_item'
        borderColor={Colors.SOFT_SILVER}
        backgroundColor={Colors.FROST_WHITE}
        placeHolderColor={Colors.MIDNIGHT_BLACK}
      />
    ));

    return (
      <Box key={id} className={styles.container__main__content__schedule__item}>
        <ControlledInput
          readonly
          asText
          name='payment'
          control={control}
          focused={false}
          fromPayment={true}
          borderColor={Colors.SOFT_SILVER}
          borderRadius='10px'
          placeholder={title}
          backgroundColor={Colors.FROST_WHITE}
          placeHolderColor={Colors.MIDNIGHT_BLACK}
        />
        {renderItems}
      </Box>
    );
  });

  const renderPaymentIconClasses = classNames(
    styles.container__main__content__schedule_item__content__title__icon,
    {
      [styles.container__main__content__schedule_item__content__title__icon__open]: isPaymentOpen,
    },
  );

  const renderPaymentSchedule = (
    <>
      <Box className={styles.container__main__content__schedule_item}>
        <Box
          className={styles.container__main__content__schedule_item__content}
          onClick={changePaymentStatus}
        >
          <Box className={styles.container__main__content__schedule_item__content__title}>
            <ArrowCircleDown width={24} height={24} className={renderPaymentIconClasses} />
            Payment Schedule
          </Box>
        </Box>
        <Box className={paymentItemClasses}>{renderPaymentScheduleItem}</Box>
      </Box>
    </>
  );

  useEffect(() => {
    dispatch(setDiscount({ discount: 0, inputValue: '' }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taxValue =
    ((Number(totalAmountFormatted) * Number(currentCustomer?.tax)) / 100).toFixed(2) || 0;

  const totalPrice = (
    (Number(totalAmountFormatted) - discount.discount || Number(totalAmountFormatted)) +
    Number(taxValue)
  ).toFixed(2);

  return (
    <>
      <form className={styles.container} onSubmit={handleSubmit(onEditEstimate)}>
        <Box className={styles.container__main}>
          <BomEstimateItemHeader openProfitMarginDrawer={openProfitMarginDrawer} />

          <Box className={styles.container__main__content}>
            {renderDetails}
            {renderSections}
            {renderPaymentSchedule}
          </Box>

          <BomEstimateFooter
            taxValue={taxValue}
            totalPrice={totalPrice}
            inputValue={inputValue}
            handleChange={handleChange}
            handleApplyCoupon={handleApplyCoupon}
            totalAmountFormatted={totalAmountFormatted}
          />
        </Box>
        <Box className={styles.container__generate}>
          <Button
            type='submit'
            className={styles.container__generate__button}
            disabled={isSubmitting || !!discountCouponError}
          >
            Generate Estimate
          </Button>
        </Box>
      </form>
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onDelete={deleteItem}
        title='Delete Confirmation'
        body='This will delete the item. Please confirm.'
      />
      <ProfitMarginDrawer
        isOpen={isProfitMarginDrawerOpen}
        onClose={closeProfitMarginDrawer}
        sections={estimateItem?.sections as []}
      />
    </>
  );
};

export default BomEstimateItem;
