import { Box } from '@mui/material';
import { useEffect, type FC } from 'react';

import { useAppSelector } from 'hooks';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import {
  CircumIcon,
  NewPlusIcon,
  GridViewIcon,
  ActiveCardsIcon,
  DisableTableIcon,
} from 'assets/icons';
import { Button, CustomBreadcrumbs, CustomTypography } from 'components';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { PermissionMessages } from 'constants/PermissionMessages';
import { Colors } from 'types';

import { useCatalog } from '../hooks';

import styles from './CatalogHeader.module.scss';
import { breadCrumbsOptions } from './utils';

import type { TCatalogHeaderProps } from './types';

const CatalogHeader: FC<TCatalogHeaderProps> = ({
  totalRows,
  isViewInGrid,
  setIsViewInGrid,
  createNewDrawer,
}) => {
  const { page, setPage, handlePageChange } = useCatalog();

  const { isAccessToCatalogsViewer, isAccessToCatalogsEditor, isAccessToInventoryEditor } =
    useAppSelector(userPermissionsSelector);

  const handleClick = (value: boolean) => {
    setIsViewInGrid(value);
    BrowserStorageService.set(BrowserStorageKeys.CatalogsView, JSON.stringify(value), {
      session: true,
    });
  };

  useEffect(() => {
    if (!totalRows && page) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRows]);

  const disableCreate = !isAccessToCatalogsEditor || !isAccessToInventoryEditor;

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbsOptions} />

      <Box className={styles.container__header}>
        <CustomTypography className={styles.container__header__title}>Catalogs</CustomTypography>
        {isAccessToCatalogsViewer && (
          <Box className={styles.container__header__view}>
            {!isViewInGrid ? (
              <DisableTableIcon onClick={() => handleClick(true)} />
            ) : (
              <GridViewIcon />
            )}
            {!isViewInGrid ? (
              <ActiveCardsIcon />
            ) : (
              <CircumIcon onClick={() => handleClick(false)} />
            )}
          </Box>
        )}
      </Box>

      <Box className={styles.container__header}>
        <div />

        <Button
          color='white'
          onClick={createNewDrawer}
          isUppercase={false}
          backgroundColor={Colors.SAPPHIRE}
          className={styles.container__header__create}
          startIcon={<NewPlusIcon width={24} height={24} />}
          disabled={disableCreate}
          tooltipMessage={disableCreate ? PermissionMessages.CatalogCreateMessage : undefined}
        >
          Create Catalog
        </Button>
      </Box>
    </Box>
  );
};

export default CatalogHeader;
