import { FC, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Colors, FontNames } from 'types';
import ToggleChip from 'components/views/ToggleChip';
import { useAppDispatch, useOnClickOutside, useWindowSize } from 'hooks';
import { TChangeInputEvent } from 'types/global/events';
import { AddIcon, CheckedReasonIcon } from 'assets/icons';
import { createActivityFlag } from 'store/slices/activitiesSlices/thunks';
import { Button, CustomCheckbox, CustomTypography, ModalBoxHeader, ModalLayout } from 'components';

import { breakPoints, reportsReasons } from '../../../../constants';

import styles from './ReportsModal.module.scss';
import ReportsTextField from './ReportsTextField';
import { schema } from './schema';

import type { TReportForm, TReportsModalProps } from './types';

const ReportsModal: FC<TReportsModalProps> = ({ isOpen, onClose, activityId }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement | null>(null);

  const [isStarted, setIsStarted] = useState<boolean>(false);

  const { control, handleSubmit, reset } = useForm<TReportForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const [moreInfo, setMoreInfo] = useState<string>();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleMoreInfoValue = (event: TChangeInputEvent) => {
    setMoreInfo(event.target.value);
  };

  const handleChecked = (event: TChangeInputEvent) => {
    setIsChecked(event.target.checked);
  };

  const handleChipClick = (reason: string) => {
    setSelectedReasons((prevSelectedReasons) =>
      prevSelectedReasons.includes(reason)
        ? prevSelectedReasons.filter((r) => r !== reason)
        : [...prevSelectedReasons, reason],
    );
  };

  const reportItems = reportsReasons?.map((reason) => (
    <ToggleChip
      withIcon
      key={reason?.id}
      borderRadius='4px'
      label={reason?.value}
      fontFamily='CircularStdRegular'
      onDelete={() => handleChipClick(reason?.value)}
      selected={selectedReasons.includes(reason?.value)}
      color={selectedReasons.includes(reason?.value) ? 'white' : '#999'}
      backgroundColor={selectedReasons.includes(reason?.value) ? '#1C6402' : '#F2F2F2'}
      deleteIcon={selectedReasons.includes(reason?.value) ? <CheckedReasonIcon /> : <AddIcon />}
    />
  ));

  const sendReport = async (data: TReportForm) => {
    if (selectedReasons && activityId) {
      await dispatch(
        createActivityFlag({
          activity_feed_id: activityId,
          reasons: selectedReasons,
          recieve_update: isChecked,
          more_info: data?.report as string,
        }),
      );

      timedOnClose();
      setSelectedReasons([]);
      setMoreInfo('');
      reset();
    }
  };

  const { width } = useWindowSize();

  const modalWidth =
    Number(width) <= breakPoints.TABLET_L
      ? 500
      : Number(width) <= breakPoints?.TABLET_M
      ? 400
      : 701;

  const timedOnClose = () => {
    setIsStarted(true);
    setTimeout(() => {
      onClose();
      setIsStarted(false);
    }, 1000);
  };

  const onClearClose = () => {
    setSelectedReasons([]);
    setMoreInfo('');
    setIsChecked(false);
    timedOnClose();
  };

  useOnClickOutside(ref, timedOnClose);

  return (
    <ModalLayout
      isOpen={isOpen}
      isClosed={isStarted}
      onClose={onClearClose}
      width={modalWidth}
      borderRadius='10px'
    >
      <Box ref={ref}>
        <ModalBoxHeader
          title='Flag Post'
          onClose={onClearClose}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
        <form className={styles.container} onSubmit={handleSubmit(sendReport)}>
          <Box className={styles.container__reason}>
            <CustomTypography className={styles.container__reason__title}>
              Select a reason
            </CustomTypography>
            <Box className={styles.container__reason__content}>{reportItems}</Box>
          </Box>
          <ReportsTextField
            control={control}
            onChange={handleMoreInfoValue}
            value={moreInfo as string}
          />
          <CustomCheckbox
            checked={isChecked}
            onChecked={handleChecked}
            label='Receive update on this report'
          />
          <Box className={styles.container__footer}>
            <Button
              maxWidth='87px'
              borderRadius='4px'
              padding='12px 16px'
              type='submit'
              isUppercase={false}
              color={Colors.WHITE}
              backgroundColor={Colors.SAPPHIRE}
              fontFamily={FontNames.CIRCULAR_BOLD_MAX}
            >
              SUBMIT
            </Button>
          </Box>
        </form>
      </Box>
    </ModalLayout>
  );
};

export default ReportsModal;
