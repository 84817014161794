import React from 'react';
import Chip from '@mui/material/Chip';

import { TCustomChipProps } from './types';

const CustomChip: React.FC<TCustomChipProps> = ({
  label,
  color,
  variant,
  disabled,
  onDelete,
  deleteIcon,
}) => {
  const chipStyle: React.CSSProperties = {
    color: 'white',
    marginTop: '2px',
    fontSize: '14px',
    userSelect: 'none',
    marginRight: '8px',
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    borderRadius: '5px',
    letterSpacing: '0.035px',
    fontFamily: 'CircularStdRegular',
    backgroundColor: color || '#6E8BF3',
  };

  return (
    <Chip
      label={label}
      style={chipStyle}
      onDelete={onDelete}
      disabled={disabled}
      variant={variant || 'filled'}
      deleteIcon={onDelete && deleteIcon}
    />
  );
};

export default CustomChip;
