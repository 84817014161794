import { UserRole } from 'types/global/userRoles';
import { TUserDefinedRole } from 'store/slices/authSlice/types';

const getUserPermissions = (roles: TUserDefinedRole[], userRole: string) => {
  if (userRole === 'owner') {
    return {
      isSendInvite: true,
      isAccessToNotices: true,
      isAccessToReports: true,
      isAccessToInvoices: true,
      isAccessToLivestream: true,
      isAccessToTeamsViewer: true,
      isAccessToTeamsEditor: true,
      isAccessToEditWorkOrder: true,
      isAccessToCatalogsEditor: true,
      isAccessToCatalogsViewer: true,
      isAccessToCustomerEditor: true,
      isAccessToCustomerViewer: true,
      isAccessToCreateWorkOrder: true,
      isAccessToInventoryViewer: true,
      isAccessToInventoryEditor: true,
      isAccessToEstimatesEditor: true,
      isAccessToEstimatesViewer: true,
      isAccessToTransactionHistory: true,
      isAccessToWorkOrdersAndScheduling: true,
      isAccessToDocumentationAndReports: true,
    };
  } else if (userRole === 'employee') {
    const rolePermissionsEmployee = {
      isSendInvite: false,
      isAccessToNotices: false,
      isAccessToReports: false,
      isAccessToInvoices: false,
      isAccessToLivestream: false,
      isAccessToTeamsEditor: false,
      isAccessToTeamsViewer: false,
      isAccessToEditWorkOrder: false,
      isAccessToCatalogsEditor: false,
      isAccessToCatalogsViewer: false,
      isAccessToCustomerEditor: false,
      isAccessToCustomerViewer: false,
      isAccessToCreateWorkOrder: false,
      isAccessToEstimatesEditor: false,
      isAccessToEstimatesViewer: false,
      isAccessToInventoryEditor: false,
      isAccessToInventoryViewer: false,
      isAccessToTransactionHistory: false,
      isAccessToDocumentationAndReports: false,
      isAccessToWorkOrdersAndScheduling: false,
    };

    roles?.forEach((userRole) => {
      switch (userRole.role) {
        case UserRole.AllowInviteUsers:
          rolePermissionsEmployee.isSendInvite = true;
          break;
        case UserRole.LiveStreams:
          rolePermissionsEmployee.isAccessToLivestream = true;
          break;
        case UserRole.InvoiceViewer:
          rolePermissionsEmployee.isAccessToInvoices = true;
          break;
        case UserRole.CatalogEditor:
          rolePermissionsEmployee.isAccessToCatalogsEditor = true;
          break;
        case UserRole.CatalogViewer:
          rolePermissionsEmployee.isAccessToCatalogsViewer = true;
          break;
        case UserRole.EstimatesEditor:
          rolePermissionsEmployee.isAccessToEstimatesEditor = true;
          break;
        case UserRole.EstimatesViewer:
          rolePermissionsEmployee.isAccessToEstimatesViewer = true;
          break;
        case UserRole.CustomersEditor:
          rolePermissionsEmployee.isAccessToCustomerEditor = true;
          break;
        case UserRole.CustomersViewer:
          rolePermissionsEmployee.isAccessToCustomerViewer = true;
          break;
        case UserRole.DocumentsViewer:
          rolePermissionsEmployee.isAccessToDocumentationAndReports = true;
          break;
        case UserRole.InventoryEditor:
          rolePermissionsEmployee.isAccessToInventoryEditor = true;
          break;
        case UserRole.InventoryViewer:
          rolePermissionsEmployee.isAccessToInventoryViewer = true;
          break;
        case UserRole.NoticeBoardEditor:
          rolePermissionsEmployee.isAccessToNotices = true;
          break;
        case UserRole.TeamsEditor:
          rolePermissionsEmployee.isAccessToTeamsEditor = true;
          break;
        case UserRole.TeamsViewer:
          rolePermissionsEmployee.isAccessToTeamsViewer = true;
          break;
        case UserRole.TransactionHistoryViewer:
          rolePermissionsEmployee.isAccessToTransactionHistory = true;
          break;
        case UserRole.WorkOrderCreate:
          rolePermissionsEmployee.isAccessToCreateWorkOrder = true;
          break;
        case UserRole.WorkOrderEdit:
          rolePermissionsEmployee.isAccessToEditWorkOrder = true;
          break;
        case UserRole.WOandScheduling:
          rolePermissionsEmployee.isAccessToWorkOrdersAndScheduling = true;
          break;
      }
    });

    return rolePermissionsEmployee;
  } else {
    const rolePermissionsManager = {
      isSendInvite: false,
      isAccessToNotices: true,
      isAccessToReports: true,
      isAccessToInvoices: true,
      isAccessToLivestream: true,
      isAccessToTeamsViewer: true,
      isAccessToTeamsEditor: true,
      isAccessToEditWorkOrder: true,
      isAccessToCatalogsEditor: true,
      isAccessToCatalogsViewer: true,
      isAccessToCustomerEditor: true,
      isAccessToCustomerViewer: true,
      isAccessToCreateWorkOrder: true,
      isAccessToInventoryViewer: true,
      isAccessToEstimatesEditor: true,
      isAccessToInventoryEditor: true,
      isAccessToEstimatesViewer: true,
      isAccessToTransactionHistory: false,
      isAccessToWorkOrdersAndScheduling: true,
      isAccessToDocumentationAndReports: true,
    };

    roles?.forEach((userRole) => {
      switch (userRole.role) {
        case UserRole.AllowInviteUsers:
          rolePermissionsManager.isSendInvite = true;
          break;
        case UserRole.TransactionHistoryViewer:
          rolePermissionsManager.isAccessToTransactionHistory = true;
          break;
      }
    });

    return rolePermissionsManager;
  }
};

export default getUserPermissions;
