import { FC } from 'react';
import { Box } from '@mui/material';

import styles from './ShadowLayout.module.scss';

import type { TShadowLayoutProps } from './types';

const ShadowLayout: FC<TShadowLayoutProps> = ({ children, maxHeight = '544px' }) => (
  <Box className={styles.container} sx={{ maxHeight }}>
    {children}
  </Box>
);

export default ShadowLayout;
