type MyCardDate = {
  month: number;
  year: number;
};

const formatCardDate = (date: MyCardDate): string => {
  const { month, year } = date;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  return `${formattedMonth}/${year}`;
};

export default formatCardDate;
