import { GridColDef } from '@mui/x-data-grid';

import { FontNames } from 'types';
import { TableCell } from 'components';

import HeaderTitle from '../EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'plan',
    headerName: 'Plan/Invoice Date',
    width: 250,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          withSortIcons={false}
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ paddingLeft: '0' }}
          title={headerName}
        />
      );
    },

    renderCell: (params) => {
      return (
        <TableCell
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ paddingLeft: '0' }}
          title={params?.value}
        />
      );
    },
  },
  {
    field: 'state',
    headerName: 'State',
    width: 150,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          withSortIcons={false}
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ paddingLeft: '16px' }}
          title={headerName}
        />
      );
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ paddingLeft: '16px' }} />;
    },
  },
  {
    field: 'card',
    headerName: 'Card Used',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          withSortIcons={false}
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ paddingLeft: '16px' }}
          title={headerName}
        />
      );
    },

    renderCell: (params) => {
      return <TableCell style={{ paddingLeft: '16px' }} title={params?.value} />;
    },
  },
];
