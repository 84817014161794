import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';

import { discountsApi } from 'api';
import { RootState, TPagination } from 'types';

import type { TCreateCoupon, TUpdateCoupon } from './types';

export const createCoupon = createAsyncThunk(
  'discountSlice/createCoupon',
  async (options: TCreateCoupon, { dispatch }) => {
    const newData: TCreateCoupon = {
      ...options,
      start_date: moment(options?.start_date).format('YYYY-MM-DD'),
      end_date: moment(options?.end_date).format('YYYY-MM-DD'),
    };

    try {
      const response = await discountsApi.createCouponRequest(newData);

      dispatch(getAllCoupons());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getAllCoupons = createAsyncThunk(
  'discountSlice/getAllCoupons',
  async (params: TPagination | undefined, { getState }) => {
    const {
      discounts: { allCouponsLimit, allCouponsOffset },
    } = getState() as RootState;

    const { limit = allCouponsLimit, offset = allCouponsOffset } = params ?? {};

    try {
      const response = await discountsApi.getAllCouponsRequest({ limit, offset });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateCoupon = createAsyncThunk(
  'discountSlice/updateCoupon',
  async (options: TUpdateCoupon) => {
    // const start = toast.loading(DiscountToastMessages.DISCOUNT_EDIT_START);

    const newData: TUpdateCoupon = {
      ...options,
      start_date: moment(options?.start_date).format('YYYY-MM-DD'),
      end_date: moment(options?.end_date).format('YYYY-MM-DD'),
    };

    try {
      const response = await discountsApi.updateCouponRequest(newData);

      // toast.update(start, {
      //   render: DiscountToastMessages.DISCOUNT_EDIT_SUCCESS,
      //   type: 'success',
      //   isLoading: false,
      //   autoClose: 3000,
      // });
      return response.data;
    } catch (error) {
      // toast.update(start, {
      //   render: DiscountToastMessages.DISCOUNT_EDIT_FAILURE,
      //   type: 'error',
      //   isLoading: false,
      //   autoClose: 3000,
      // });

      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCouponById = createAsyncThunk('discountSlice/getCouponById', async (id: number) => {
  try {
    const response = await discountsApi.getCouponByIdRequest(id);

    return response.data;
  } catch (error) {
    const Error = error as AxiosError;
    throw Error;
  }
});

export const getCouponByCatalogId = createAsyncThunk(
  'discountSlice/getCouponByCatalogId',
  async (id: number) => {
    try {
      const response = await discountsApi.getCouponByCatalogIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeCouponById = createAsyncThunk(
  'discountSlice/removeCouponById',
  async (id: number) => {
    try {
      const response = await discountsApi.removeCouponByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
