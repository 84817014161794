import { Routes } from 'types';
import { CheckSquareBlueIcon, CheckSquareIcon, ReloadIcon } from 'assets/icons';

import { TBreadCrumbOptions } from './types';

export const breadCrumbOptions = ({ number, id }: TBreadCrumbOptions) => [
  { id: 1, label: 'Inventory', goTo: Routes.Inventory },
  { id: 2, label: 'Purchase Orders', goTo: Routes.PurchaseOrder },
  { id: 3, label: `PO #${number}`, goTo: `/inventory/purchase-order/${id}` },
];

export const purchaseOrderStatuses = [
  {
    Icon: CheckSquareBlueIcon,
    title: 'submitted',
    color: '#1A53B2',
  },
  {
    Icon: CheckSquareIcon,
    title: 'recieved',
    color: '#0D7D0B',
  },
  {
    Icon: ReloadIcon,
    title: 'draft',
    color: '#667085',
  },
];
