import { FC, memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Routes } from 'types';
import Header from 'components/views/Header';
import LogoHeader from 'components/views/LogoHeader';
import { useAppDispatch, useRefreshClearState, useWindowSize } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { getAllUserRoles, getCurrentUser } from 'store/slices/authSlice/authThunks';
import {
  getAllOrganisations,
  getOrganizationById,
  getOrganizationMembers,
  getOrganizationTemplateType,
} from 'store/slices/organizationsSlice/thunks';
import { TabNavigator } from 'components/views';

import { breakPoints } from '../../../constants';
import Navbar from '../../../components/shared/Navbar';
import LargeLayout from '../LargeLayout';

import styles from './Layout.module.scss';

import type { TLayoutProps } from './types';

const Layout: FC<TLayoutProps> = ({
  withLogoHeader,
  withNavbar,
  children,
  withTabNavigator,
  tabNavigatorOptions,
  tabNavigatorTitle,
}) => {
  const dispatch = useAppDispatch();

  useRefreshClearState();

  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const { pathname } = useLocation();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

  const openDrawer = () => setIsOpenDrawer(true);
  const hideDrawer = () => setIsOpenDrawer(false);

  const { width } = useWindowSize();

  const isLayoutWithBackground = pathname === Routes.ActivityFeed;

  useEffect(() => {
    if (pathname !== Routes.ProcessForm && pathname !== Routes.OrganizationsList) {
      BrowserStorageService.remove(BrowserStorageKeys.ProcessFormValue, { session: true });
    }

    if (pathname === Routes.ActivityFeed) {
      BrowserStorageService.remove(BrowserStorageKeys.InProcessForm);
      BrowserStorageService.remove(BrowserStorageKeys.HaveCreatedOrganization);
      BrowserStorageService.remove(BrowserStorageKeys.StripeClientSecret);
    }
  }, [pathname]);

  const childClasses = classNames(styles.wrapper__content_child, {
    [styles.wrapper__content_child_background]: isLayoutWithBackground,
  });

  const token =
    BrowserStorageService.get(BrowserStorageKeys.AccessToken) ||
    BrowserStorageService.get(BrowserStorageKeys.AccessToken, { session: true });

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser());
      dispatch(getAllUserRoles());
      dispatch(getOrganizationTemplateType());

      dispatch(getAllOrganisations());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(getOrganizationMembers({ org_id: Number(currentOrganization) }));
      dispatch(getOrganizationById(Number(currentOrganization)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const isLarge = Number(width) > breakPoints.LARGE_DESKTOP;

  return (
    <>
      {withLogoHeader ? (
        <Box className={styles.container}>
          <LogoHeader />
          <Box className={styles.container__content}>{children}</Box>
        </Box>
      ) : isLarge ? (
        <LargeLayout
          tabNavigatorTitle={tabNavigatorTitle}
          withTabNavigator={withTabNavigator}
          tabNavigatorOptions={tabNavigatorOptions}
        >
          {children}
        </LargeLayout>
      ) : withNavbar ? (
        <Box className={styles.wrapper}>
          <Drawer
            anchor='left'
            onClose={hideDrawer}
            open={isOpenDrawer}
            className={styles.wrapper__drawer}
          >
            <Box className={styles.container__drawer}>
              <Navbar asDrawer onClose={hideDrawer} />
            </Box>
          </Drawer>
          <Navbar />
          <Box className={styles.wrapper__content}>
            <Header onOpenNavbar={openDrawer} />

            <Box className={childClasses}>
              {withTabNavigator && tabNavigatorOptions && tabNavigatorTitle && (
                <TabNavigator options={tabNavigatorOptions} tabNavigatorTitle={tabNavigatorTitle} />
              )}
              {children}
            </Box>
          </Box>
        </Box>
      ) : (
        <main>{children}</main>
      )}
    </>
  );
};

export default memo(Layout);
