import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import store from 'store';
import { StripeProvider } from 'context';
import CustomThemeProvider from 'infrastructure/Theming/CustomThemeProvider';
import { UpdateVersionPopup } from 'components';

import { RouterProvider } from './routes';

import './styles/index.global.scss';
import 'react-toastify/dist/ReactToastify.css';

const SkyHawk = () => (
  <StripeProvider>
    <CustomThemeProvider>
      <GoogleOAuthProvider clientId={String(process.env.REACT_APP_CLIENT_ID)}>
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ToastContainer />
            <UpdateVersionPopup />

            <RouterProvider />
          </LocalizationProvider>
        </Provider>
      </GoogleOAuthProvider>
    </CustomThemeProvider>
  </StripeProvider>
);

export default SkyHawk;
