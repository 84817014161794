import React from 'react';
import { Controller, useController } from 'react-hook-form';
import { Autocomplete, AutocompleteInputChangeReason, Chip } from '@mui/material';

import { CustomTextField } from 'components';
import { ArrowDown, SquareDeleteIcon } from 'assets/icons';

import { sxStyles } from './styles';

import type { TChangeInputEvent } from 'types/global/events';
import type { TControlledAutocompleteProps } from './types';

const ControlledAutocomplete = <T,>({
  name,
  rows,
  label,
  errors,
  control,
  options,
  padding,
  disabled,
  variant,
  multiline,
  inputName,
  hoverColor,
  inputValue,
  helperText,
  isWithIcon,
  placeholder,
  optionsName,
  isWithChips,
  borderColor,
  borderRadius,
  isFromWorkOrder,
  labelColor,
  backgroundColor,
  readonly = false,
  placeholderColor,
  required = true,
  handleInputChange,
  chooseChangedReason,
  fromRequisition = false,
  inputIcon = <ArrowDown />,
  inputClassName,
  inputContainerClassName,
  isSingleControlled = false,
  singleKeyName = 'qty_required',
  fontFamily = 'CircularStdRegular',
}: TControlledAutocompleteProps<T>) => {
  const {
    field: { onChange: fieldOnchange, value: fieldValue },
  } = useController({ name, control });

  const filteredOptions = options
    ? options?.filter(
        (item: T) =>
          item?.[optionsName as keyof T] !== null &&
          item?.[optionsName as keyof T] !== undefined &&
          item?.[optionsName as keyof T] !== '',
      )
    : [];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, ...field } }) => {
        return (
          <Autocomplete
            id={`part_id/${fieldValue?.part_id}/${name}.qty_required`}
            clearIcon={false}
            freeSolo={!isFromWorkOrder}
            popupIcon={(isFromWorkOrder || isWithIcon) && inputIcon}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option, { index }) => (
              <li key={`${index} ${option[optionsName]}`} {...props}>
                {option[optionsName]}
              </li>
            )}
            getOptionDisabled={(option) => (isWithChips && !option.value ? true : false)}
            sx={
              (sxStyles(fontFamily),
              {
                ' .MuiAutocomplete-popupIndicator:hover ': {
                  backgroundColor: hoverColor,
                },

                ' .MuiAutocomplete-root': {
                  backgroundColor: backgroundColor,
                },
              })
            }
            multiple={isWithChips}
            renderTags={
              isWithChips
                ? (value, getTagProps) =>
                    value.map((option, index) => {
                      return (
                        <Chip
                          sx={{
                            background: option.color,
                            borderRadius: '10px',
                            color: '#fff',
                          }}
                          deleteIcon={<SquareDeleteIcon />}
                          label={option.label}
                          {...getTagProps({ index })}
                          key={index}
                          style={{ borderRadius: '5px !important' }}
                        />
                      );
                    })
                : () => null
            }
            onInputChange={(
              event: React.SyntheticEvent,
              value: string,
              reason: AutocompleteInputChangeReason,
            ) => {
              chooseChangedReason?.(reason);

              if (reason === 'input' && !!fieldValue && isSingleControlled) {
                fieldOnchange({ ...fieldValue, [singleKeyName]: value });
              }
            }}
            onChange={(_, data) => {
              if (data) {
                onChange(data || '');
              }
            }}
            options={filteredOptions || []}
            {...field}
            readOnly={readonly}
            getOptionLabel={(options) => {
              return options[optionsName] ? String(options[optionsName]) : '';
            }}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  onChange: (event: TChangeInputEvent) => {
                    handleInputChange?.(event);
                  },
                  onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                      e.stopPropagation();
                    }
                  },
                }}
                rows={rows}
                label={label}
                withHelperText
                error={!!errors}
                labelColor={labelColor}
                variant={variant}
                name={inputName}
                padding={padding}
                value={inputValue}
                readonly={readonly}
                disabled={disabled}
                required={required}
                inputRef={ref}
                multiline={multiline}
                helperText={helperText}
                fontFamily={fontFamily}
                isFromAutoComplete={true}
                borderColor={borderColor}
                placeholder={placeholder}
                className={inputClassName}
                borderRadius={borderRadius}
                fromRequisition={fromRequisition}
                backGroundColor={backgroundColor}
                placeHolderColor={placeholderColor}
                onChange={params?.inputProps?.onChange}
                containerClasses={inputContainerClassName}
              />
            )}
          />
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
