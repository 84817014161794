import React, { useEffect, useState } from 'react';

import { TChangeInputEvent } from 'types/global/events';
import { TFileMedia } from 'store/slices/optionsSlice/types';
import { TTagImgUser } from 'store/slices/activitiesSlices/types';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { createActivity } from 'store/slices/activitiesSlices/thunks';
import { TOrganization } from 'store/slices/organizationsSlice/types';
import { CreatePostModal, PostMediaElement, TextFrame } from 'components';
import { postMediasDataSelector } from 'store/slices/optionsSlice/selectors';
import { clearAllPostMediaData, clearImgMarkers } from 'store/slices/optionsSlice';
import { useAppDispatch, useAppSelector, useUserOrganizationsList, useWindowSize } from 'hooks';
import { Colors } from 'types';

import Audience from '../Audience';
import PostActions from '../PostActions';
import CustomTypography from '../Typography';
import { TEmoji } from '../EmojiPicker/types';
import Editor from '../CreateNoticeModal/editor';
import CustomizedAvatar from '../CustomizedAvatar';
import { breakPoints } from '../../../constants';

import styles from './CreatePost.module.scss';

const CreatePost: React.FC = () => {
  const dispatch = useAppDispatch();

  const postMedias = useAppSelector(postMediasDataSelector);

  const { getAllOrganizations, currentOrganization } = useUserOrganizationsList();

  const [activeId, setActiveId] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [openEmoji, setOpenEmoji] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [mediaData, setMediaData] = useState<TFileMedia[]>(postMedias);
  const [selectedAudience, setSelectedAudience] = useState(currentOrganization?.name as string);

  useEffect(() => {
    setMediaData(postMedias);
  }, [postMedias]);

  useEffect(() => {
    setSelectedAudience(currentOrganization?.name as string);
  }, [currentOrganization?.name]);

  const getActiveTeamId = (id: number) => setActiveId(id);

  const { width } = useWindowSize();

  const userInfo = useAppSelector(userDataSelector);

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userImg = userInfo?.profile_image_url_id;

  const sendedMedias = mediaData?.map((media) => (
    <PostMediaElement mediaData={mediaData} key={media?.id} media={media} />
  ));

  const isDisabled = !postMedias?.length && !inputValue.trim().length;

  const handleInputChange = (event: TChangeInputEvent) => {
    setInputValue(event.target.value);
  };

  const handleAudienceChange = (audience: string) => {
    setSelectedAudience(audience);
  };

  const createPostModal = () => {
    setOpenModal(true);
  };

  const closePostModal = () => {
    setOpenModal(false);
    setInputValue('');
    setMediaData([]);
    dispatch(clearAllPostMediaData());
  };

  const closePostModalWithClear = () => {
    setOpenModal(false);
    setInputValue('');
    setMediaData([]);
    dispatch(clearAllPostMediaData());
  };

  const hideEmojiBar = () => {
    setOpenEmoji(false);
  };

  const showEmojiBar = () => {
    setOpenEmoji(true);
  };

  const inputElement = document.getElementById('create_post') as HTMLTextAreaElement;

  const handleEmojiSelect = (emoji: TEmoji) => {
    if (inputElement) {
      const cursor = inputElement.selectionStart || 0;

      const text = inputValue.slice(0, cursor) + emoji.native + inputValue.slice(cursor);
      setInputValue(text);

      //Codes added for the new cursor
      const newCursor = cursor + emoji.native.length;
      setTimeout(() => inputElement.setSelectionRange(newCursor, newCursor), 10);
      inputElement.focus();
    } else {
      setInputValue((prevComment) => prevComment + emoji.native);
    }
    // setInputValue((prevValue) => prevValue + ' ' + emoji.native);
    // hideEmojiBar();
  };

  const transformMediaData = (medias: TFileMedia[]): TTagImgUser[] | [] => {
    const newTagsArray: TTagImgUser[] = [];

    medias?.forEach((media: TFileMedia, mediaIndex: number) => {
      if (media?.tags) {
        media?.tags.forEach((tag) => {
          newTagsArray.push({
            pos_x: tag.x,
            pos_y: tag.y,
            on_media: true,
            tagged_user_id: tag.id,
            media_index: mediaIndex + 1,
          });
        });
      } else {
        return [];
      }
    });

    return newTagsArray;
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (postMedias?.length) {
      for (const media of postMedias) {
        formData.append('media', media?.file);
      }
    }

    const sendedImgMarkers = transformMediaData(postMedias);

    const sendedAudience = activeId === 0 ? 'public' : 'team';

    const sendedData = {
      org_id: Number(currentOrganization),
      team_id: activeId !== 0 ? activeId : null,
      audience: sendedAudience,
      content: inputValue.trim(),
      tags: sendedImgMarkers || [],
    };

    if (sendedData) {
      formData.append('data', JSON.stringify(sendedData));
    }

    await dispatch(createActivity(formData));
    setInputValue('');
    dispatch(clearAllPostMediaData());
    dispatch(clearImgMarkers());
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <CustomizedAvatar
          withBorder
          src={userImg}
          alt='Remy Sharp'
          borderRadius='50%'
          className={styles.container_header_avatar}
        />
        <div onClick={createPostModal} className={styles.container_header__input} role='button'>
          <TextFrame
            cursor='pointer'
            borderRadius='5px'
            padding='19px 16px'
            letterSpacing='0.08px'
            backgroundColor={Colors.FROST_BLUE}
            border='0.5px solid #EDEFF1'
            content='Ask me something...'
          />
        </div>

        <CreatePostModal
          open={openModal}
          openEmoji={openEmoji}
          onSubmit={handleSubmit}
          isDisabled={isDisabled}
          onClose={closePostModal}
          hideEmoji={hideEmojiBar}
          showEmojiBar={showEmojiBar}
          openPostModal={createPostModal}
          handleEmojiSelect={handleEmojiSelect}
          onCloseWithClear={closePostModalWithClear}
        >
          <div className={styles.container_header_user}>
            <CustomizedAvatar
              withBorder
              src={userImg}
              width={45}
              height={45}
              alt='Remy Sharp'
              borderRadius='50%'
              className={styles.container_header_avatar}
            />
            <div className={styles.container_header_user__content}>
              <CustomTypography className={styles.container_header_username}>
                {userInfo?.name}
              </CustomTypography>
              <Audience
                onChange={handleAudienceChange}
                getActiveTeamId={getActiveTeamId}
                selectedAudience={selectedAudience}
                currentOrganization={currentOrganization as TOrganization}
              />
            </div>
          </div>
          <Editor
            padding={0}
            outlined={false}
            value={inputValue}
            onChange={handleInputChange}
            placeholder='Ask me something...'
            height={Number(width) <= breakPoints.TABLET_L ? '80px' : '80px'}
          />

          {!!postMedias?.length && <div className={styles.container__images}>{sendedMedias}</div>}
        </CreatePostModal>
      </div>
      <div className={styles.container_footer}>
        <PostActions createPostModal={createPostModal} />
      </div>
    </div>
  );
};

export default CreatePost;
