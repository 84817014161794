import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Enter a valid email address',
    )
    .required(),
  phone: yup
    .string()
    .test('is-valid-mobile', 'Phone Number must be a 10-digit number', function (value) {
      const { path, createError } = this;
      if (value && value.length > 0) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(value)) {
          return createError({
            path,
            message: 'Phone Number must be a 10-digit number',
          });
        }
      }
      return true;
    }),
  name: yup.string().trim().required('Name is a required field'),
  address: yup.string().trim().required('Address is a required field'),
});
