import { ChangeEvent, FC, useState } from 'react';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { BorderSquareIcon } from 'assets/icons';
import { convertToMMDDYYYY } from 'utils/formatDate';
import CustomDataGrid from 'components/views/CustomDataGrid';
import { ContactLink, CustomTypography, DrawerLayout, ScrollLayout } from 'components/shared';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import DynamicPaginationControl from 'components/shared/DynamicPaginationControl';
import {
  itemUsageDataSelector,
  itemUsageDataLimitSelector,
  itemUsageDataOffsetSelector,
} from 'store/slices/catalogSlice/selectors';
import { setItemUsageLimit, setItemUsagePage } from 'store/slices/catalogSlice';
import { EmptyTitles } from 'constants/EmptyTitles';
import { getCatalogListItemUsage } from 'store/thunks';
import { currentPartByIdSelector } from 'store/slices/inventorySlice/selectors';

import { breakPoints } from '../../../../constants';

import styles from './ItemUsageDrawer.module.scss';
import { itemUsageColumns } from './utils';
import { sxDrawerStyles } from './styles';

import type { SortByEnums, TItemUsageDrawerProps } from './types';

const ItemUsageDrawer: FC<TItemUsageDrawerProps> = ({ open, onClose, itemName, itemNumber }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const itemUsage = useAppSelector(itemUsageDataSelector);
  const currentItemData = useAppSelector(currentPartByIdSelector);
  const itemUsageLimit = useAppSelector(itemUsageDataLimitSelector);
  const itemUsageOffset = useAppSelector(itemUsageDataOffsetSelector);

  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>(null);

  const { width } = useWindowSize();

  const [page, setPage] = useState<number>(1);
  // const [rowsPerPage, setRowsPerPage] = useState<number>(20);

  const drawerSizes =
    Number(width) <= breakPoints.WIDE_L && Number(width) >= breakPoints.TABLET
      ? '760px'
      : Number(width) <= breakPoints.TABLET
      ? '370px'
      : 1286;

  const renderColumns: GridColDef[] = itemUsageColumns?.map((column) => {
    const isColumnSorted = column.field ? newSortBy === column.field : 'name';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      hideSortIcons: true,
      sortable: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const handleOpenCatalogPage = (id: string) => navigate(`/product-info/catalog/${id}`);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setAsc((prev) => !prev);
    setNewSortBy(column.field as SortByEnums);

    const sortByValue =
      column?.field === 'number'
        ? 'created_at'
        : column?.field === 'dateModified'
        ? 'updated_at'
        : column?.field === 'catalog'
        ? 'name'
        : column?.field;

    dispatch(
      getCatalogListItemUsage({
        limit: itemUsageLimit,
        offset: itemUsageOffset,
        item_id: currentItemData?.id,
        sort_by: sortByValue,
        asc,
      }),
    );
  };

  const rows = itemUsage?.data?.map((item, idx) => ({
    id: `${idx}/${item?.uuid}`,
    catalog:
      (
        <ContactLink
          onClick={() => handleOpenCatalogPage(item?.uuid)}
          value={item.name}
          tagName='text'
        />
      ) || '--',
    number: item.number ? `#${item.number}` : `#${generateInventoryItemNumber(item.id)}`,
    createdBy: item?.created_by || '--',
    dateModified: convertToMMDDYYYY(item?.updated_at),
  }));

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);

    dispatch(setItemUsagePage(newPage + 1));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    handlePageChange(null, 0);
    dispatch(setItemUsageLimit(event.target.value));
  };

  return (
    <DrawerLayout
      withHeader={false}
      width={drawerSizes}
      open={open}
      onClose={onClose}
      headerTitle='Item Usage'
      sx={sxDrawerStyles}
    >
      <Box className={styles.container}>
        <Box className={styles.container__head}>
          <Box className={styles.container__head__info}>
            <CustomTypography className={styles.container__head__info__title}>
              Catalog Usage For “{itemName || 'name'}”
            </CustomTypography>
            <CustomTypography className={styles.container__head__info__number}>
              Item Number: <span>{itemNumber}</span>
            </CustomTypography>
          </Box>
          <BorderSquareIcon width={24} height={24} onClick={onClose} />
        </Box>

        <ScrollLayout>
          <CustomDataGrid
            rows={rows}
            rowHeight={76}
            headerHeight={49}
            columns={renderColumns}
            emptyTitle={EmptyTitles.Inventory_Usage}
            onColumnHeaderClick={handleColumnHeaderClick}
            sortModel={[{ field: newSortBy || 'name', sort: asc ? 'asc' : 'desc' }]}
          />
        </ScrollLayout>
        {!!rows?.length && (
          <Box className={styles.container__footer}>
            <DynamicPaginationControl
              page={page}
              count={itemUsage.total_count}
              rowsPerPage={itemUsageLimit}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        )}
      </Box>
    </DrawerLayout>
  );
};

export default ItemUsageDrawer;
