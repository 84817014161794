import { BrowserStorageKeys, BrowserStorageService } from 'services';

import { TCreateOrganizationFormValuesGeneratorOptions } from './types';

const email = BrowserStorageService.get(BrowserStorageKeys.CurrentUserEmail, { session: true });

const createOrganizationFormValuesGenerator = ({
  userInfo,
}: TCreateOrganizationFormValuesGeneratorOptions) => ({
  industryVertical: '',
  email: (userInfo?.email as string) || JSON.parse(email as string),
  location: '',
  organization: '',

  state: '',
  ownerLastName: '',
  ownerFirstName: '',
  zip: '',
  phoneNumber: '',
  typeOfBusiness: '',
});

export default createOrganizationFormValuesGenerator;
