import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  CustomBreadcrumbs,
  CustomTypography,
  EmptyTitle,
  DeletePopup,
  PurchaseOrderForm,
  ScrollLayout,
} from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Colors, Routes } from 'types';
import {
  deletePurchaseOrder,
  getPurchaseOrderDataByUuid,
  getPurchaseOrderItems,
  receivePurchaseOrderItem,
  submitPurchaseOrderItem,
} from 'store/slices/purchaseOrderSlice/thunks';
import {
  purchaseOrderDataByIdLoadSelector,
  purchaseOrderDataByIdSelector,
  purchaseOrderDataItemsLoadSelector,
  purchaseOrderItemsSelector,
} from 'store/slices/purchaseOrderSlice/selectors';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import { generateInventoryItemNumber } from 'utils/generateInventoryItemNumber';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';

import styles from './PurchaseOrderItems.module.scss';
import PurchaseOrderItemsTable from './PurchaseOrderItemsTable';
import { breadCrumbOptions, purchaseOrderStatuses } from './utils';

const PurchaseOrderItems = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [status, setStatus] = useState<string>('');
  const [isErrorPage, setIsErrorPage] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isShowForm, setIsShowForm] = useState<boolean>(state?.edit || false);

  const handleCloseForm = () => setIsShowForm(false);
  const openForm = () => setIsShowForm(true);

  const userRoles = useAppSelector(userAllRolesSelector);
  const purchaseOrderItems = useAppSelector(purchaseOrderItemsSelector);
  const purchaseOrderData = useAppSelector(purchaseOrderDataByIdSelector);
  const purchaseOrderDataByIdLoad = useAppSelector(purchaseOrderDataByIdLoadSelector);
  const purchaseOrderDataItemsLoad = useAppSelector(purchaseOrderDataItemsLoadSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const allMembers = useAppSelector(organizationMembersSelector);

  const loading = purchaseOrderDataItemsLoad || purchaseOrderDataByIdLoad;

  useEffect(() => {
    const getOrders = async () => {
      if (id) {
        const response = await dispatch(getPurchaseOrderDataByUuid(id)).unwrap();

        dispatch(getPurchaseOrderItems(Number(response.id)));

        if (!response.id) {
          setIsErrorPage(true);
          navigate(Routes.Error);
        }
      }
    };

    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const togglePopup = (actionStatus: string) => {
    setIsPopupOpen(!isPopupOpen);
    setStatus(actionStatus);
  };

  const deleteOrder = async () => {
    if (purchaseOrderData) {
      dispatch(deletePurchaseOrder(purchaseOrderData.id));
      navigate(Routes.PurchaseOrder);
    }
  };

  const creator = allMembers?.find((member) => member.user_id === purchaseOrderData?.created_by);

  const renderNumber = generateInventoryItemNumber(Number(purchaseOrderData?.id));
  const renderOptions = breadCrumbOptions({
    number: Number(purchaseOrderData?.id),
    id: id as string,
  });

  const receivePO = () => {
    purchaseOrderData &&
      dispatch(
        receivePurchaseOrderItem({ id: purchaseOrderData.id, uuid: purchaseOrderData.uuid }),
      );
  };

  const submitPO = () => {
    purchaseOrderData &&
      dispatch(submitPurchaseOrderItem({ id: purchaseOrderData.id, uuid: purchaseOrderData.uuid }));
  };

  const openConfirmation = () => {
    if (status === 'submit') {
      submitPO();
    } else if (status === 'delete') {
      deleteOrder();
    } else {
      receivePO();
    }
    togglePopup('');
  };

  const renderPopupTitle =
    `${status} Confirmation`.charAt(0).toUpperCase() + `${status} Confirmation`.slice(1);

  const renderStatus = purchaseOrderStatuses.map(
    (el) =>
      el.title === purchaseOrderData?.status && (
        <Box key={el.title} className={styles.container__head__info__number__status}>
          <p style={{ color: `${el.color}` }}>{el.title}</p>
          <el.Icon />
        </Box>
      ),
  );

  return (
    <>
      {!isErrorPage &&
        (!!purchaseOrderItems?.data?.length && (!loading || isShowForm) ? (
          <>
            <Box className={styles.container}>
              <Box className={styles.container__head}>
                <Box className={styles.container__head__navigation}>
                  <CustomBreadcrumbs options={renderOptions} />
                </Box>
                <Box className={styles.container__head__info}>
                  <Box className={styles.container__head__info__number}>
                    Purchase Order{' '}
                    <a className={styles.container__head__info__number__link}>#{renderNumber}</a>
                    {renderStatus}
                  </Box>
                  <Box className={styles.container__head__info__actions}>
                    <CustomTypography className={styles.container__head__info__actions__text}>
                      Created by:{' '}
                      <span className={styles.container__head__info__actions__text__name}>
                        {creator?.name}
                      </span>
                    </CustomTypography>
                    <Box className={styles.container__head__info__actions__buttons}>
                      <Button
                        color={Colors.WHITE}
                        minWidth='59px'
                        borderRadius='5px'
                        onClick={openForm}
                        variant='contained'
                        backgroundColor={Colors.SAPPHIRE}
                        disabled={purchaseOrderData?.status === 'recieved' || isViewer}
                        className={styles.container__head__info__actions__buttons__item}
                      >
                        Edit
                      </Button>
                      <Button
                        color={Colors.WHITE}
                        minWidth='76px'
                        borderRadius='5px'
                        variant='contained'
                        onClick={() => togglePopup('delete')}
                        backgroundColor={Colors.SAPPHIRE}
                        disabled={!purchaseOrderData?.items?.total_count || isViewer}
                        className={styles.container__head__info__actions__buttons__item}
                      >
                        Delete
                      </Button>
                      <Button
                        color={Colors.WHITE}
                        borderRadius='5px'
                        variant='contained'
                        backgroundColor={Colors.SAPPHIRE}
                        onClick={() => togglePopup('submit')}
                        disabled={
                          purchaseOrderData?.status === 'submitted' ||
                          purchaseOrderData?.status === 'recieved' ||
                          !purchaseOrderData?.items?.total_count ||
                          isViewer
                        }
                        className={styles.container__head__info__actions__buttons__item}
                      >
                        Submit PO
                      </Button>
                      <Button
                        color={Colors.WHITE}
                        borderRadius='5px'
                        variant='contained'
                        onClick={() => togglePopup('receive')}
                        backgroundColor={Colors.SAPPHIRE}
                        disabled={
                          purchaseOrderData?.status === 'draft' ||
                          purchaseOrderData?.status === 'recieved' ||
                          !purchaseOrderData?.items?.total_count ||
                          isViewer
                        }
                        className={styles.container__head__info__actions__buttons__item}
                      >
                        Receive PO
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {purchaseOrderItems?.data?.length ? (
                <ScrollLayout>
                  <PurchaseOrderItemsTable onOpen={openForm} data={purchaseOrderItems?.data} />
                </ScrollLayout>
              ) : (
                <EmptyTitle title="Purchase order doesn't have items" loadingDone={loading} />
              )}
              <Box className={styles.container__footer}>
                <CustomTypography className={styles.container__footer__text}>
                  Total Cost: {purchaseOrderData?.grand_total || '$0'}
                </CustomTypography>
              </Box>
            </Box>
            <PurchaseOrderForm
              itemId={id as string}
              open={isShowForm}
              onClose={handleCloseForm}
              purchaseOrderData={purchaseOrderData}
            />
            <DeletePopup
              withBody
              isOpen={isPopupOpen}
              title={renderPopupTitle}
              onDelete={openConfirmation}
              onClose={() => togglePopup('')}
              body={`Please confirm to ${status} the purchase order.`}
            />
          </>
        ) : (
          <Box className={styles.loader}>
            <CircularProgress />
          </Box>
        ))}
    </>
  );
};

export default PurchaseOrderItems;
