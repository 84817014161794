import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import { PencilIcon, TrashIcon } from 'assets/icons';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { TInvitedUserData } from 'store/slices/organizationsSlice/types';
import { Colors, FontNames, VoidCallback } from 'types';
import { capitalizeFirstLetter } from 'utils';

export const dropdownOptionsPending = (revokeAction: VoidCallback, deleteAction: VoidCallback) => [
  { id: 1, name: 'Revoke', icon: <PencilIcon />, action: revokeAction },
  { id: 2, name: 'Delete', icon: <TrashIcon />, action: deleteAction },
];

export const dropdownOptionsRevoke = (resendAction: VoidCallback, deleteAction: VoidCallback) => [
  { id: 1, name: 'Resend Invitation', icon: <PencilIcon />, action: resendAction },
  { id: 2, name: 'Delete', icon: <TrashIcon />, action: deleteAction },
];

export const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',

    renderHeader: (params) => {
      return (
        <HeaderTitle
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ padding: 0 }}
          title={params.field}
        />
      );
    },

    renderCell: (params) => {
      return (
        <TableCell
          style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }}
          fontFamily={FontNames.CIRCULAR_REG}
          title={params?.value}
        />
      );
    },
  },

  {
    flex: 1,
    field: 'role',
    headerName: 'Role',
    renderCell: (params) => {
      const userRole = capitalizeFirstLetter(params?.value);

      return (
        <TableCell
          style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }}
          fontFamily={FontNames.CIRCULAR_REG}
          title={userRole}
        />
      );
    },
    renderHeader: (params) => {
      return (
        <HeaderTitle
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ padding: 0 }}
          title={params.field}
        />
      );
    },
  },

  {
    flex: 1,
    field: 'email',
    headerName: 'Email',
    renderCell: (params) => {
      return (
        <TableCell
          style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }}
          fontFamily={FontNames.CIRCULAR_REG}
          title={params?.value}
        />
      );
    },
    renderHeader: (params) => {
      return (
        <HeaderTitle
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ padding: 0 }}
          title={params.field}
        />
      );
    },
  },

  {
    flex: 0.5,
    field: 'status',
    headerName: 'Status',
    renderHeader: (params) => {
      return (
        <HeaderTitle
          fontFamily={FontNames.CIRCULAR_BOLD}
          style={{ padding: 0 }}
          title={params.field}
        />
      );
    },
    renderCell: (params) => {
      return (
        <TableCell
          style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }}
          fontFamily={FontNames.CIRCULAR_REG}
          title={params?.value}
        />
      );
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 72,
    renderCell: (params) => {
      return <TableCell title={params.value} style={{ minWidth: '100%' }} isTooltipDisabled />;
    },
  },
];

export const generateUserArray = (count: number): TInvitedUserData[] => {
  const userArray: TInvitedUserData[] = [];

  for (let i = 1; i <= count; i++) {
    const user: TInvitedUserData = {
      email: `user${i}@example.com`,
      status: `Active`,
      id: i,
      org_id: i,
      role_id: i,
      full_name: 'name',
      created_at: '',
      updated_at: '',
    };

    userArray.push(user);
  }

  return userArray;
};
