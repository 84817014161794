import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';

import { Routes } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  if (!token) {
    return <Navigate to={Routes.Landing} replace />;
  }

  return children;
};

export default ProtectedRoute;
