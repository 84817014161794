import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TCreateLiveStream,
  TLiveStreamParams,
  TUpdateLiveStream,
  TCreateLiveStreamNote,
  TGetLiveStreamDetails,
  TUpdateLastReadMessage,
  TGetLiveStreamMessages,
  TInviteExternalUserStream,
  TUpdateChatMessageOptions,
  TGetCompletedPreviousStreams,
} from 'store/slices/liveStreamSlice/types';
import { TGetWithParams } from 'types';

export const getAllLiveStreamRequest = (options: TLiveStreamParams) => {
  return client.get(endpoints.LivestreamService.getAllLiveStreams(), {
    params: { ...options },
  });
};

export const getUserCreatedCompletedLiveStreamsRequest = (
  options: TGetCompletedPreviousStreams,
) => {
  return client.get(endpoints.LivestreamService.getAllLiveStreams(), {
    params: { ...options },
  });
};

export const createLiveStreamRequest = (options: TCreateLiveStream) => {
  return client.post(endpoints.LivestreamService.createLivestream(), { ...options });
};

export const getLiveStreamByIdRequest = (id: number) => {
  return client.get(endpoints.LivestreamService.getAllLiveStreamById(id));
};

export const createLiveStreamMessageRequest = (body: FormData) => {
  return client.post(endpoints.LivestreamService.createLivestreamMessage(), body);
};

export const deleteLiveStreamRequest = (liveStream_id: number) => {
  return client.delete(endpoints.LivestreamService.deleteLivestream(liveStream_id));
};

export const deleteLiveStreamMessageRequest = (id: number) => {
  return client.delete(endpoints.LivestreamService.deleteLivestreamMessageById(id));
};

export const updateLivestreamMessageRequest = (options: TUpdateChatMessageOptions) => {
  const { content, id } = options;

  const queryParams = {
    content,
    id,
  };

  return client.put(endpoints.LivestreamService.updateLivestreamMessage(), null, {
    params: { ...queryParams },
  });
};

export const getLiveStreamMessagesRequest = (options: TGetLiveStreamMessages) => {
  const queryParams: Record<string, number> = {};

  if (options?.limit) {
    queryParams.limit = options.limit || 10;
  }
  if (options?.offset) {
    queryParams.offset = options.offset || 0;
  }

  return client.get(endpoints.LivestreamService.getLivestreamMessages(options?.livestream_id), {
    params: queryParams,
  });
};

export const getLiveStreamDetailsRequest = (options: TGetLiveStreamDetails) => {
  return client.get(endpoints.LivestreamService.getLiveStreamDetails(options?.id), {
    params: { ...options },
  });
};

export const updateLiveStreamRequest = (params: TUpdateLiveStream) =>
  client.put(endpoints.LivestreamService.updateLiveStream(), params);

export const inviteMemberRequest = (livestream_id: number, user_id: number) => {
  return client.post(endpoints.LivestreamService.inviteMember(livestream_id, user_id));
};

export const inviteExternalMemberRequest = (options: TInviteExternalUserStream) => {
  return client.post(endpoints.LivestreamService.inviteExternalMember(options?.livestream_id), {
    ...options,
  });
};

export const joinLivestreamRequest = (livestream_id: number) =>
  client.post(endpoints.LivestreamService.joinLivestream(livestream_id));

export const updateLastReadRequest = (options: TUpdateLastReadMessage) => {
  return client.put(endpoints.LivestreamService.updateLastRead(), { options });
};

export const getExternalLivestreamRequest = (token: string) => {
  return client.get(endpoints.LivestreamService.getExternalLivestream(token));
};

export const leaveLivestreamRequest = (livestream_id: number) => {
  return client.delete(endpoints.LivestreamService.leaveLivestream(livestream_id));
};

export const removeMemberFromLiveStreamRequest = (livestream_id: number, member_id: number) => {
  return client.delete(endpoints.LivestreamService.removeLivestream(livestream_id, member_id));
};

export const removeExternalMemberLivestreamRequest = (token: string) => {
  return client.delete(endpoints.LivestreamService.removeExternalMemberLivestream(token));
};

export const getLivestreamMessageByIdRequest = (id: number) => {
  return client.get(endpoints.LivestreamService.getLivestreamMessageById(id));
};

export const getMyCurrentStreamRequest = () => {
  return client.get(endpoints.LivestreamService.getMyCurrentStream());
};

export const createNoteForStreamRequest = (data: TCreateLiveStreamNote) => {
  return client.put(endpoints.LivestreamService.createNoteForStream(), { ...data });
};

export const endStreamRequest = (livestream_id: number) => {
  return client.delete(endpoints.LivestreamService.endStream(livestream_id), {
    params: { livestream_id },
  });
};

export const uploadMediaToStreamRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.LivestreamService.uploadMediaToStream(), body, options);
};

export const updateMediaNoteRequest = () => {
  return client.put(endpoints.LivestreamService.updateMediaNote());
};

export const createStreamNoteRequest = (data: FormData) => {
  return client.post(endpoints.LivestreamService.createStreamNote(), data);
};

export const getStreamNoteByIdRequest = (id: number) => {
  return client.get(endpoints.LivestreamService.getStreamNoteById(id));
};

export const getStreamAllNotesRequest = ({
  id,
  limit,
  offset,
}: TGetWithParams<{ id: number }, 'id'>) => {
  return client.get(endpoints.LivestreamService.getStreamAllNotes(id), {
    params: { limit, offset },
  });
};

export const removeStreamNoteRequest = (id: number) => {
  return client.delete(endpoints.LivestreamService.removeStreamNote(id));
};

export const getStreamByUUIDRequest = (id: string) => {
  return client.get(endpoints.LivestreamService.getStreamWithUUID(id));
};
