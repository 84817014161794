export const columns = [
  {
    id: 1,
    name: 'Item',
  },
  {
    id: 2,
    name: 'Quantity',
  },
  {
    id: 3,
    name: 'Description',
  },
  {
    id: 4,
    name: '',
  },
];

export const addNewSectionOptions = (index: number) => ({
  name: `Section ${index + 1}`,
  description: '',

  items: [
    {
      qty_required: 0,
      part_name: '',
      description: '',
    },
  ],
});

export const addNewSectionItemOptions = {
  qty_required: 0,
  part_name: '',
  description: '',
};
