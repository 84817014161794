import type { TPlanDurationPrices } from 'types/global/durationTypes';

export enum ProcessFormNames {
  PROCESSFORM = 'Process form',
  SUBSCRIPTIONPLAN = 'Subscription plan',
  PAYMENT = 'Payment',
  PAYMENT_ONBOARDING = 'Payment Onboarding',
  INVITEUSERS = 'Invite users',
}

export type TSendDuration = {
  title: string;
  duration: string | TPlanDurationPrices;
} & Partial<{
  amount: number;
}>;

export type TOrganizationCreateData = {
  email: string;
  location: string;
  phoneNumber: string;
  organization: string;
  typeOfBusiness: string;
  industryVertical: string;
};

export type TOrganizationData = {
  zip: string;
  city: string;
  size: string;
  email: string;
  state: string;
  address: string;
  location: string;
  phoneNumber: string;
  organization: string;
  ownerLastName: string;
  template_type: string;
  typeOfBusiness: string;
  ownerFirstName: string;
  industryVertical: string;
};

export type TOrganizationForm = {
  zip: string;
  email: string;
  state: string;
  location: string;
  phoneNumber: string;
  organization: string;
  ownerLastName: string;
  ownerFirstName: string;
  industryVertical: string;
  typeOfBusiness: string | null;
};

export type ConvertedData = {
  org_id: number;
  account_details: {
    email: string;
  };
  business_profile: {
    merchant_category_code: string;
    website_url: string;
    business_type: string;
  };
  company_details: {
    name: string;
    city: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
    phone_number: string;
    tax_id: string;
  };
  owner_details: {
    first_name: string;
    last_name: string;
    email: string;
    id_number: string;
    dob: string;
    phone_number: string;
    city: string;
    line1: string;
    line2: string;
    state: string;
    postal_code: string;
  };
  representative_details: {
    first_name: string;
    last_name: string;
    city: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
    email: string;
    dob: string;
    phone: string;
    ssn: string;
    id_number: string;
  };
  bank_account_details: {
    account_number: string;
    account_holder_name: string;
    account_holder_type: string;
    routing_number: string;
  };
  tos_acceptance: {
    ip: string;
    date: string;
  };
};
