import { EditIconOutLined, ShareIcon, TrashIcon } from 'assets/icons';
import { VoidCallback } from 'types';

type TMenuDropDownItemsParams = {
  editAction: VoidCallback;
  removeAction: VoidCallback;
  shareAction: VoidCallback;
  seeDetailsAction: VoidCallback;
};

export const menuDropDownItems = ({
  editAction,
  removeAction,
  shareAction,
}: TMenuDropDownItemsParams) => [
  {
    label: 'Edit',
    icon: <EditIconOutLined />,
    paramsAction: editAction,
  },
  {
    label: 'Delete',
    icon: <TrashIcon />,
    paramsAction: removeAction,
  },
  {
    label: 'Share',
    icon: <ShareIcon />,
    isNotClosable: true,
    paramsAction: shareAction,
  },
];
