import { FC } from 'react';
import classNames from 'classnames';
import { StepConnector, StepIcon, StepIconProps } from '@mui/material';
import { StepLabel, Stepper, Step, Box } from '@mui/material';

import { useWindowSize } from 'hooks';
import { StepCheckedIcon } from 'assets/icons';

import styles from './HorizontalStepper.module.scss';

import type { THorizontalStepperProps } from './types';

const HorizontalStepper: FC<THorizontalStepperProps> = ({
  activeStep,
  stepsOptions,
  isStepSkipped,
}) => {
  const { width } = useWindowSize();

  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed } = props;

    const icons: Record<number, number> = stepsOptions.reduce((acc, curr, index) => {
      acc[index + 1] = index + 1;
      return acc;
    }, {} as Record<number, number>);

    const iconLayoutClassNames = classNames(styles.icon__layout, {
      [styles.icon__layout_active]: active,
      [styles.icon__layout_completed]: completed,
    });

    return (
      <span className={iconLayoutClassNames}>
        <StepIcon
          {...props}
          icon={completed ? <StepCheckedIcon /> : icons[Number(props.icon)]}
          completed={completed}
          active={active}
        />
      </span>
    );
  };

  const stepItems = stepsOptions?.map((label, index) => {
    const stepProps: { completed?: boolean } = {};

    const labelProps: {
      optional?: React.ReactNode;
    } = {};

    if (isStepSkipped(index)) {
      stepProps.completed = false;
    }

    return (
      <Step key={label.id} {...stepProps}>
        <StepLabel
          StepIconComponent={CustomStepIcon}
          classes={{
            label: styles.label,
            completed: styles.label_completed,
            active: styles.label_active,

            iconContainer: styles.label__icon,
          }}
          {...labelProps}
        >
          <span
            className={classNames(styles.label__name, {
              [styles.label__name_disable]: activeStep !== label?.id,
            })}
          >
            {Number(width) <= 576 ? activeStep === index && label.title : label.title}
          </span>
        </StepLabel>
      </Step>
    );
  });

  return (
    <Box className={styles.container}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={
          <StepConnector
            classes={{
              root: styles.connector,
              line: styles.connector__line,
              active: styles.connector__line_active,
              completed: styles.connector__line_active,
            }}
          />
        }
      >
        {stepItems}
      </Stepper>
    </Box>
  );
};

export default HorizontalStepper;
