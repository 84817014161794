import {
  BWImg,
  ChefImg,
  EngineerImg,
  CarpenterImg,
  LiveStreamsImg,
  ActivityFeedsImg,
  ScheduleTasksImg,
  GardenerFirstImg,
  TeamManagmentImg,
  InvoicePaymentsImg,
  LandingBackgroundImg,
  EstimateGenerationImg,
  InventoryManagementImg,
} from 'assets/images';

import { TWhatWeDo } from './types';

export const featuresData: TWhatWeDo[] = [
  {
    id: 0,
    title: 'Inventory Management',
    subTitle:
      'Effortlessly oversee your inventory in real-time using our distinctive Gen AI Inventory Assist. Initiate Purchase Orders, and stay informed about any modifications in your stock, all while efficiently managing vendors and purchase requisitions.',
    Icon: InventoryManagementImg,
    videoUrl:
      'https://storage.googleapis.com/swifttask-nonprod-storage/TutorialVideos/Catalogs.mp4',
  },
  {
    id: 1,
    title: 'Estimate Generation',
    subTitle:
      'Create comprehensive client estimates for all your services effortlessly with our advanced Gen AI Estimate Agent. Our Bill of Materials (BOM) estimates provide you with unparalleled customization options to perfectly align with your unique requirements.',
    Icon: EstimateGenerationImg,
    videoUrl:
      'https://storage.googleapis.com/swifttask-nonprod-storage/TutorialVideos/Workflow.mp4',
  },
  {
    id: 2,
    title: 'Schedule Tasks',
    subTitle:
      'Streamline the process of assigning tasks and delegating field workflows effortlessly! With our innovative Scheduling AI Assist, you can efficiently manage jobs, ensuring both your customers and employees are delighted with the seamless workflow.',
    Icon: ScheduleTasksImg,
    videoUrl: '',
  },
  {
    id: 3,
    title: 'Invoice e-Payments',
    subTitle: `Effortlessly create and customize professional
    invoices, enabling clients to make secure online payments. Automatically send invoice and reminders to ensure timely transactions.`,
    Icon: InvoicePaymentsImg,
    videoUrl:
      'https://storage.googleapis.com/swifttask-nonprod-storage/TutorialVideos/Workflow.mp4',
  },
  {
    id: 4,
    title: 'Live Stream',
    subTitle: `Establish instant connections with your customers through our one-of-a-kind AI-based livestream feature. Diagnose and visually assess issues even before dispatching a technician to the field, ensuring swift and informed resolution.`,
    Icon: LiveStreamsImg,
    videoUrl:
      'https://storage.googleapis.com/swifttask-nonprod-storage/TutorialVideos/Livestream.mp4',
  },
  {
    id: 5,
    title: 'Activity Feed',
    subTitle: `Gain insights into daily employee activities via our interactive Activity Feed section. Leverage our AI-based activity feed to facilitate knowledge sharing among employees. Efficiently create notices for all staff members in your organization, whether for instructional guidance or sharing valuable information.`,
    Icon: ActivityFeedsImg,
    videoUrl:
      'https://storage.googleapis.com/swifttask-nonprod-storage/TutorialVideos/ActivityFeed.mp4',
  },
  {
    id: 6,
    title: 'Team Management',
    subTitle: `Effortlessly organize teams and enhance collaboration. Streamline communication and boost productivity. `,
    Icon: TeamManagmentImg,
    videoUrl: 'https://storage.googleapis.com/swifttask-nonprod-storage/TutorialVideos/Teams.mp4',
  },
];

export const landingBackgroundImages = [
  {
    id: 1,
    userImg: LandingBackgroundImg,
    content: `Woohoo! Thumbs up for this one...`,
    subContent: `SwiftTaskAI, as a Gen AI powered workflow management portal, elevates business operational efficiency by integrating AI agents, live streaming, estimation and inventory management, optimized business workflows, and e-payment systems.`,
  },
  {
    id: 2,
    userImg: BWImg,
    content: 'I am loving it...',
    subContent: `SwiftTask AI has significantly enhanced my business workflow, leading to increased profitability.`,
  },
  {
    id: 3,
    userImg: ChefImg,
    content: 'Woohoo! It does all for me...',
    subContent: `SwiftTask AI offers Estimate Generation and Inventory Management to optimize your business processes increasing your return on investment (ROI).`,
  },
  {
    id: 4,
    userImg: EngineerImg,
    content: `Give it a try - you'll be glad you did...`,
    subContent: `SwiftTaskAI provides live stream and activity feed collaboration to elevate the efficiency of your business operations, fostering seamless communication and coordination among team members.`,
  },
  {
    id: 5,
    userImg: CarpenterImg,
    content: 'me too loving this... makes me more money!',
    subContent: `SwiftTaskAI facilitates e-payments from your clients, contributing to increased profitability. Your clients benefit from the ease of payment, ensuring a mutually satisfying and efficient transaction experience.`,
  },
  {
    id: 6,
    userImg: GardenerFirstImg,
    content: 'Its simply the next generation platform...',
    subContent: `SwiftTask AI's Gen AI capabilities support you at every stage of your business workflow, handling tasks and actions for you—a glimpse into the future of seamless business operations.`,
  },
];
