const createFormData = <K, T extends Record<string, K>>(data: T): FormData => {
  const formData = new FormData();

  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      const value = data[key];
      if (typeof value === 'string' || value instanceof Blob) {
        formData.append(key, value);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Unsupported type for key ${key}`);
      }
    }
  }

  return formData;
};

export default createFormData;
