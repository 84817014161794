export enum BrowserStorageKeys {
  Mode = 'mode',
  Role = 'role',
  Scroll = 'scroll',
  Language = 'i18nextLng',
  GoogleToken = 'googleToken',
  AccessToken = 'accessToken',
  CurrentAmount = 'currentAmount',
  DocumentsView = 'documentsView',
  CatalogsView = 'catalogsView',
  RefreshToken = 'refreshToken',
  InProcessForm = 'inProcessForm',
  inventoryView = 'inventoryView',
  sortingSource = 'sortingSource',
  sortingTeamIds = 'sortingTeamIds',
  WorkOrdersSortBy = 'WorkOrdersSortBy',
  TaskListSortBy = 'TaskListSortBy',
  TaskListSortByAsc = 'TaskListSortByAsc',
  InventorySortBy = 'inventorySortBy',
  InventorySortByAsc = 'inventorySortByAsc',
  IsInvitedUser = 'isInvitedUser',
  SubscriptionId = 'subscriptionId',
  CurrentIndustry = 'CurrentIndustry',
  IsFirstTimeUser = 'isFirstTimeUser',
  InvitationToken = 'invitationToken',
  CurrentUserEmail = 'CurrentUserEmail',
  ProcessFormValue = 'processFormValue',
  StripeClientSecret = 'stripeClientSecret',
  ActiveSettingsTitle = 'activeSettingsTitle',
  activeSortedValues = 'activeSortedValues',
  currentLiveStreamId = 'currentLiveStreamId',
  currentLiveStreamUUID = 'currentLiveStreamUUID',
  CurrentOrganizationId = 'currentOrganizationId',
  currentLiveStreamToken = 'currentLiveStreamToken',
  currentOrganizationType = 'currentOrganizationType',
  HaveCreatedOrganization = 'haveCreatedOrganization',
  InvitedOrganizationName = 'invitedOrganizationName',
  subscriptionClientSecret = 'subscriptionClientSecret',
  CurrentCreatedCatalogImg = 'CurrentCreatedCatalogImg',
  ProcessFormFirstStepData = 'ProcessFormFirstStepData',
  ProcessFormSecondStepData = 'ProcessFormSecondStepData',
  CurrentCreatedInventoryImg = 'CurrentCreatedInventoryImg',
}

export type BrowserStorageOptions = {
  session: boolean;
};
