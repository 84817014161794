import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';

import { Button, CustomTypography, StatusInfo } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  subscriptionsSelector,
  userSubscriptionsTrialPlansDataName,
} from 'store/slices/subscriptionSlice/selectors';
import { getUserSubscriptionsTrailing } from 'store/thunks';
import { firstLetterUpper } from 'utils/firstLetterUpper';
import { Colors, Routes } from 'types';

import { StatusEnums } from '../StatusInfo/types';

import styles from './BillingDetails.module.scss';

import type { TBillingDetailsProps } from './types';

const BillingDetails: FC<TBillingDetailsProps> = ({ price, planTitle, interval }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { userSubscriptionsError, userSubscriptionsTrial } = useAppSelector(subscriptionsSelector);
  const trialPlan = useAppSelector(userSubscriptionsTrialPlansDataName);

  const trialPeriodInterval = userSubscriptionsTrial?.data?.[0]?.plan.interval;

  useEffect(() => {
    dispatch(getUserSubscriptionsTrailing());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedAmount = Number(price?.toString().slice(0, -2));

  const logOut = async () => {
    localStorage.clear();
    sessionStorage.clear();
    await localforage.clear();
    navigate(Routes.Landing);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.container__main}>
        <CustomTypography className={styles.container__title}>Current Plan:</CustomTypography>
        <Box className={styles.container__content}>
          <Box className={styles.container__head}>
            <CustomTypography className={styles.container__head__title}>
              {planTitle}
            </CustomTypography>
          </Box>
          {userSubscriptionsError ? (
            <div className={styles.container__subtitle_lowercase}>
              Free 30 days{' '}
              {trialPlan ? `${firstLetterUpper(trialPlan)} plan (${trialPeriodInterval})` : ''}
              <Button
                borderRadius='4px'
                className={styles.container__subtitle_lowercase_cancel}
                onClick={logOut}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <CustomTypography className={styles.container__subtitle}>
              {'('} ${price === undefined ? 0 : formattedAmount}{' '}
              <span className={styles.container__subtitle__time}>Per {interval}</span> {')'}
            </CustomTypography>
          )}
        </Box>
      </Box>
      <StatusInfo title='Active' color={Colors.TABLE_CELL_GRAY} status={StatusEnums.ACTIVE} />
    </Box>
  );
};

export default BillingDetails;
