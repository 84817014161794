import { SubscriptionPlans } from 'store/slices/subscriptionSlice/types';

export type RootObject = {
  title: string;
  duration: TDuration;
  amount: number;
};

type TDuration = {
  id: number;
  duration: string;
  price: number;
};

export const generatePlan = (obj: { duration: TDuration; title: string }): SubscriptionPlans => {
  const {
    duration: { duration },
    title,
  } = obj;

  if (duration === 'Monthly') {
    if (title === 'Pro Plan') {
      return SubscriptionPlans.PRO_MONTHLY;
    }
    if (title.includes('Business')) {
      return SubscriptionPlans.BUSINESS_MONTHLY;
    }
    if (title.includes('Enterprise')) {
      return SubscriptionPlans.ENTERPRISE_MONTHLY;
    }
  } else if (duration === 'Yearly') {
    if (title === 'Pro Plan') {
      return SubscriptionPlans.PRO_YEARLY;
    }
    if (title.includes('Business')) {
      return SubscriptionPlans.BUSINESS_YEARLY;
    }
    if (title.includes('Enterprise')) {
      return SubscriptionPlans.ENTERPRISE_YEARLY;
    }
  }

  // If no matching plan is found, return the default plan
  return SubscriptionPlans.BASIC;
};
