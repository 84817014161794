import { FC, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Navigate, useLocation } from 'react-router-dom';
import NavigationIcon from '@mui/icons-material/Navigation';

import { Pricing } from 'containers';
import { Colors, Routes } from 'types';
import { BackToTopIcon } from 'assets/icons';
import breakPoints from 'constants/BreakPoints';
import { talkingUsers } from 'constants/TalkingUsers';
import TalkingUser from 'components/views/TalkingUser';
import { useAppSelector, useScrollPosition, useWindowSize } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import BookDemoForm from 'components/views/Modals/BookDemoModal/BookDemoForm';
import { Button, LandingFooter, LandingHeader, RevealBlock } from 'components';
import { isModalOpenedSelector, optionsSelector } from 'store/slices/optionsSlice/selectors';

import styles from './LandingLayout.module.scss';

import type { TLandingLayoutProps } from './types';

const LandingLayout: FC<TLandingLayoutProps> = ({ children, isLanding }) => {
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  const { isModalOpened } = useAppSelector(optionsSelector);
  const isModalOpen: boolean = useAppSelector(isModalOpenedSelector);

  const renderedUsersRef = useRef<HTMLDivElement | null>(null);

  const position = useScrollPosition();

  const isLayoutPadding = pathname === Routes.Landing;
  const isLayoutAboutUsOrContact = pathname === Routes.AboutUs || pathname === Routes.ContactUs;

  const layoutClasses = classNames(styles.container_content, {
    [styles.container_content_margin]: isLayoutPadding,
    [styles.container_content_about]: isLayoutAboutUsOrContact,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  if (token) {
    return <Navigate to={Routes.ActivityFeed} replace={true} />;
  }

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const scrollButtonStyles = classNames(styles.container__back, {
    [styles.container__back_active]: position > 30 && !isModalOpen,
  });

  const users = talkingUsers?.map((user) => (
    <TalkingUser
      left={user.left}
      objectFit={user.objectFit}
      top={user.top}
      right={user.right}
      key={user.id}
      userImg={user.userImg}
      title={user.title}
      position={user.position}
    />
  ));

  return (
    <div className={styles.container}>
      <div className={scrollButtonStyles} role='button'>
        {position > 30 && !isModalOpened && !isModalOpen && (
          <>
            {Number(width) < breakPoints.TABLET_M ? (
              <button className={styles.container__back__new} onClick={scrollToTop}>
                <NavigationIcon />
              </button>
            ) : (
              <Button
                borderRadius='4px'
                padding='12px 16px'
                onClick={scrollToTop}
                color={Colors.WHITE}
                textClassName={styles.container__back__text}
                startIcon={<BackToTopIcon />}
                backgroundColor={Colors.SAPPHIRE}
              >
                BACK TO TOP
              </Button>
            )}
          </>
        )}
      </div>
      <div className={styles.container__main}>
        {isLanding && <LandingHeader />}
        <div className={layoutClasses}>{children}</div>
        {isLayoutPadding && (
          <RevealBlock renderedContentRef={renderedUsersRef}>
            <div className={styles.container__new}>
              <div className={styles.container__bottom} style={{ width: '100%', display: 'flex' }}>
                <Pricing />
              </div>
              <div className={styles.container__booking} style={{ width: '100%', display: 'flex' }}>
                <BookDemoForm
                  forModal={false}
                  boxClassName={styles.container__booking__box}
                  titleClassName={styles.container__booking__title}
                  containerClassName={styles.container__booking__container}
                />
              </div>

              <div className={styles.container__users}>{users}</div>
            </div>
          </RevealBlock>
        )}
      </div>

      {isLanding && <LandingFooter isReversed={true} />}
    </div>
  );
};

export default LandingLayout;
