import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import { Colors, FontNames } from 'types';
import { capitalizeFirstLetter } from 'utils';

import HeaderTitle from './HeaderTitle';

export const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'name',
    headerName: 'Name',
    renderHeader: (params) => {
      return (
        <HeaderTitle
          style={{ padding: 0 }}
          title={params.field}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    sortComparator: (v1, v2) => {
      const name1 = v1.props.userName || '';
      const name2 = v2.props.userName || '';
      return name1.localeCompare(name2);
    },

    renderCell: (params) => {
      return (
        <TableCell style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }} title={params?.value} />
      );
    },
  },

  {
    flex: 1,
    field: 'role',
    headerName: 'Role',
    renderHeader: (params) => {
      return (
        <HeaderTitle
          style={{ padding: 0 }}
          title={params.field}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      const userRole = capitalizeFirstLetter(params?.value);

      return (
        <TableCell
          fontFamily={FontNames.CIRCULAR_REG}
          style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }}
          title={userRole}
        />
      );
    },
  },

  {
    flex: 1,
    field: 'email',
    headerName: 'Email',

    renderCell: (params) => {
      return (
        <TableCell
          title={params?.value}
          fontFamily={FontNames.CIRCULAR_REG}
          style={{ padding: 0, color: Colors.TABLE_CELL_GRAY }}
        />
      );
    },
    renderHeader: (params) => {
      return <HeaderTitle style={{ padding: 0 }} title={params.field} />;
    },
  },

  {
    flex: 0.5,
    field: 'status',
    headerName: 'Status',
    renderHeader: (params) => {
      return (
        <HeaderTitle
          style={{ padding: 0 }}
          title={params.field}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      return params?.value;
    },
    sortComparator: (v1, v2) => {
      const name1 = v1.props.status || '';
      const name2 = v2.props.status || '';
      return name1.localeCompare(name2);
    },
  },
  {
    field: 'options',
    headerName: '',
    width: 72,
    renderCell: (params) => {
      return <TableCell title={params.value} style={{ minWidth: '100%' }} isTooltipDisabled />;
    },
  },
];
