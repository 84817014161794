export const breadCrumbsStyles = {
  background: 'none',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    textUnderlineOffset: '3px !important',
  },
};

export const breadCrumbContainer = { background: 'none', cursor: 'auto' };
export const breadCrumbBoxStyles = { cursor: 'auto' };
