import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';

import { Colors, FontNames } from 'types';
import { MinusBlackIcon } from 'assets/icons';
import { useAppSelector, useWindowSize } from 'hooks';
import { CustomTextField, CustomTypography, Select } from 'components';
import { allRolesSelector } from 'store/slices/rolesSlice/selectors';
import { userDataSelector, userDesignationSelector } from 'store/slices/authSlice/selectors';

import { breakPoints } from '../../../constants';

import styles from './InviteUsers.module.scss';
import { TInviteUserItemProps } from './types';

const InviteUserItem: FC<TInviteUserItemProps> = ({
  idx,
  errors,
  control,
  removeUserInfo,
  setIsInvitingYourself,
}) => {
  const { width } = useWindowSize();
  const userRole = useAppSelector(userDesignationSelector);
  const currentUser = useAppSelector(userDataSelector);

  const allRoles = useAppSelector(allRolesSelector)?.filter((role) => role?.label !== 'team_lead');

  const allRolesForManager = useAppSelector(allRolesSelector)?.filter(
    (role) => role?.label === 'employee',
  );

  const [inviteValidationError, setInviteValidationError] = useState<string>('');

  const invitationHandler = (value?: string) => {
    if (value?.trim() === currentUser?.email) {
      setInviteValidationError("You can't invite yourself");
      setIsInvitingYourself?.(true);
    } else {
      setInviteValidationError('');
      setIsInvitingYourself?.(false);
    }
  };

  const filteredOptions = userRole === 'manager' ? allRolesForManager : allRoles;

  const selectMaxWidth = Number(width) >= breakPoints.TABLET_L ? '178px' : '100%';
  const stackDirection = Number(width) <= breakPoints.TABLET_M ? 'column' : 'row';

  return (
    <Box className={styles.container__content}>
      <Box className={styles.container__content__items}>
        <Stack width='100%' direction={stackDirection} gap='10px'>
          <Controller
            control={control}
            name={`invitedUsers[${idx}].fullName`}
            defaultValue=''
            render={({ field }) => (
              <CustomTextField
                {...field}
                required
                label='Full Name'
                borderRadius='10px'
                withHelperText={false}
                placeholder='Full Name:'
                backGroundColor={Colors.PLATINUM_GRAY}
                error={!!errors?.fullName}
                fontFamily={FontNames.CIRCULAR_REG}
              />
            )}
          />

          <Controller
            control={control}
            name={`invitedUsers[${idx}].email`}
            render={({ field: { value, onChange } }) => {
              if (value?.trim()) {
                invitationHandler(value?.trim());
              }

              return (
                <div className={styles.container__content__email}>
                  <CustomTextField
                    required
                    label='Email'
                    onChange={onChange}
                    borderRadius='10px'
                    placeholder='Email:'
                    value={value?.trim()}
                    withHelperText={false}
                    error={!!errors?.email}
                    backGroundColor={Colors.PLATINUM_GRAY}
                    fontFamily={FontNames.CIRCULAR_REG}
                  />
                  <CustomTypography className={styles.container__content__email__error}>
                    {inviteValidationError || errors?.email?.message}
                  </CustomTypography>
                </div>
              );
            }}
          />

          <Controller
            control={control}
            name={`invitedUsers[${idx}].role`}
            defaultValue=''
            render={({ field }) => (
              <Select
                {...field}
                required
                label='Role'
                multiple={false}
                defaultValue='Role'
                borderRadius='10px'
                error={!!errors?.role}
                maxWidth={selectMaxWidth}
                options={filteredOptions}
                disabled={!filteredOptions.length}
                fontFamily={FontNames.CIRCULAR_REG}
                backgroundColor={Colors.PLATINUM_GRAY}
              />
            )}
          />
        </Stack>
        {idx !== 0 && (
          <MinusBlackIcon
            role='button'
            onClick={removeUserInfo}
            className={styles.container__content__remove}
          />
        )}
      </Box>
    </Box>
  );
};

export default InviteUserItem;
