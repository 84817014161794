import { FC } from 'react';
import classNames from 'classnames';

import { ButtonLoader } from 'components/shared';

import styles from './LoadButton.module.scss';

import type { TLoadButtonProps } from './types';

const LoadButton: FC<TLoadButtonProps> = ({
  inLoad,
  onClick,
  children,
  color,
  className,
  backgroundColor,
  loader = <ButtonLoader />,
}) => (
  <button
    onClick={onClick}
    className={classNames(styles.container, className)}
    style={{ background: backgroundColor, color }}
  >
    {children} {inLoad && loader}
  </button>
);

export default LoadButton;
