export enum PermissionMessages {
  CatalogCreateMessage = 'Access to both the Inventory Editor and Catalog Editor is required for catalog creation.',
  InventoryCreationMessage = 'Access to the Inventory Editor is required for inventory creation.',
  VendorCreationMessage = 'Access to the Inventory Editor is required for vendor creation.',
  RequisitionItemsCreationMessage = 'Access to the Inventory Editor is required for requisition item creation.',
  PurchaseOrderCreationMessage = 'Access to the Inventory Editor is required for purchase order creation.',
  TeamCreationMessage = 'Access to the Team Editor is required for team creation.',
  InviteUsersMessage = 'Access to the ability to invite users is required for user invitation.',
  NoticeMessage = 'Access to the Notice Board Editor is required for notice actions.',
  EstimateCreationMessage = 'Access to the Customers Editor, Catalog Editor, Inventory Editor, and Estimate Editor is required for estimate actions.',
  WorkOrderCreatingMessage = 'Access to the Work Order Create is required for Work Order creation.',
}
