import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { authApi, orgranizationsApi } from 'api';
import { TAllOrganizationsReturnType, TOrganization } from 'store/slices/organizationsSlice/types';
import { TokenProvider } from 'store/slices/authSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Routes } from 'types';
import { SignInToastMessages } from 'constants/ToastMessages';

const useUserOrganizationsList = () => {
  const navigate = useNavigate();

  const organizationFromStorage = BrowserStorageService.get(
    BrowserStorageKeys.CurrentOrganizationId,
  );

  const [data, setData] = useState<TOrganization[]>([]);

  const getAllOrganizations = async () => {
    try {
      const response = await orgranizationsApi.getUserAllOrganizationsRequest();

      const receivedData = response?.data as TAllOrganizationsReturnType;

      const filteredData = receivedData?.data?.filter(
        (organization) => organization?.resource_status !== 'pending',
      );

      setData(filteredData);

      return response?.data as TAllOrganizationsReturnType;
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  };

  const currentOrganization = data?.find(
    (organization) => organization?.id === Number(organizationFromStorage),
  );

  const loginDependentInOrganization = async (org_id: number, googleToken: string) => {
    const start = toast.loading(SignInToastMessages.SIGNIN_START);
    try {
      const sendedData = new FormData();

      sendedData.append('oauth_token', String(googleToken));
      sendedData.append('oauth_provider', TokenProvider.GOOGLE);
      sendedData.append('org_id', String(org_id));

      const response = await authApi.signInOauthRequest(sendedData);
      const accessToken = response?.data?.token?.access_token;

      if (accessToken) {
        BrowserStorageService.set(BrowserStorageKeys.AccessToken, JSON.stringify(accessToken));

        BrowserStorageService.set(
          BrowserStorageKeys.RefreshToken,
          JSON.stringify(response?.data?.token?.refresh_token),
        );
      }

      if (response) {
        navigate(Routes.ActivityFeed);

        BrowserStorageService.set(BrowserStorageKeys.CurrentOrganizationId, JSON.stringify(org_id));

        toast.update(start, {
          render: SignInToastMessages.SIGNIN_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.update(start, {
        render: SignInToastMessages.SIGNIN_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  };

  return { getAllOrganizations, data, loginDependentInOrganization, currentOrganization };
};

export default useUserOrganizationsList;
