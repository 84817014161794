import { FC } from 'react';
import { useSpring, animated } from 'react-spring';

import CustomTab from 'components/views/CustomTab';
import ControlledInput from 'components/views/ControlledInput';
import { Colors, FontNames } from 'types';
import { useAppSelector } from 'hooks';
import { allPartsSelector } from 'store/slices/inventorySlice/selectors';

import NestedItems from '../NestedItems/NestedItems';

import { TCatalogTabPanelsProps } from './types';

const CatalogTabPanels: FC<TCatalogTabPanelsProps> = ({
  idx,
  name,
  label,
  remove,
  errors,
  control,
  getValues,
  getActiveIndex,
}) => {
  const inventoryItems = useAppSelector(allPartsSelector);

  const isDisabled = inventoryItems?.total_count === 0;

  const springProps = useSpring({
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: 300 },
    config: { duration: 300 },
  });

  return (
    <animated.div style={springProps}>
      <CustomTab
        remove={remove}
        control={control}
        label={label}
        name={name}
        getActiveIndex={getActiveIndex}
      >
        <NestedItems control={control} errors={errors} idx={idx} getValues={getValues} />
        <ControlledInput
          rows={4}
          multiline={true}
          control={control}
          borderRadius='8px'
          label='Description'
          disabled={isDisabled}
          placeholder='Description'
          borderColor={Colors.SOFT_SILVER}
          fontFamily={FontNames.CIRCULAR_REG}
          backgroundColor={Colors.LIGHT_SILVER}
          name={`sections[${idx}].description`}
          labelColor={Colors.ENIGMATIC_MIDNIGHT}
        />
      </CustomTab>
    </animated.div>
  );
};

export default CatalogTabPanels;
