import { useState } from 'react';
import { Tooltip } from '@mui/material';

import { createMarkup, timeDiffer } from 'utils';
import { PortalDropDown } from 'components/dropdowns';
import { CreateNoticeDrawer } from 'components/views';
import DeletePopup from 'components/views/DeletePopup';
import { TNotice } from 'store/slices/activitiesSlices/types';
import { EditIconOutLined, MoreIcon, TrashIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { removeNotice } from 'store/slices/activitiesSlices/thunks';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { Colors } from 'types';

import CustomTypography from '../Typography';
import { breakPoints } from '../../../constants';
import CreateNoticeModal from '../CreateNoticeModal';

import styles from './NoticeBoard.module.scss';

const NoticeItem: React.FC<TNotice> = ({ id, title, body, userId, created_at, created_by }) => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userDataSelector);

  const [openDropdown, setOpenDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const wrapperStyle = {
    backgroundColor: Colors.FROST_BLUE,
  };

  const titleStyle = {
    color: 'black',
  };

  const toggleDropdown = () => setOpenDropdown(!openDropdown);

  const removeNoticeById = () => {
    dispatch(removeNotice(Number(id)));
  };

  const handleOpenModal = () => setOpenModal(true);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
    toggleDropdown();
  };

  const { width } = useWindowSize();

  const asADrawer = Number(width) <= breakPoints.TABLET_L;

  const options = [
    { id: 1, name: 'Edit', icon: <EditIconOutLined />, action: handleOpenModal },
    { id: 2, name: 'Delete', icon: <TrashIcon />, action: togglePopup },
  ];

  return (
    <>
      <div style={wrapperStyle} className={styles.container__wrapper}>
        <div className={styles.container}>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  maxHeight: '200px',
                  overflowY: 'auto',
                  padding: '12px',
                },
              },
            }}
            title={
              <CustomTypography className={styles.container__title__tooltip}>
                {title}
              </CustomTypography>
            }
            placement='top-start'
          >
            <div style={titleStyle} className={styles.container__title}>
              {title}:
            </div>
          </Tooltip>
          {userData?.id === userId && (
            <div>
              <PortalDropDown title={<MoreIcon />} options={options} />
            </div>
          )}
        </div>
        <div className={styles.container__wrapper__main}>
          <CustomTypography
            className={styles.container__content}
            dangerouslySetInnerHTML={createMarkup(body as string)}
          ></CustomTypography>
        </div>
        <div>
          <CustomTypography className={styles.container__content__createdBy} title={created_by}>
            Created By; {created_by}
          </CustomTypography>
          <CustomTypography className={styles.container__content__createdAt}>
            {timeDiffer(created_at)}
          </CustomTypography>
        </div>
        {asADrawer ? (
          <CreateNoticeDrawer
            activeId={id}
            activeTitle={title}
            activeValue={body}
            inEditMode
            open={openModal}
            handleClose={() => setOpenModal(false)}
          />
        ) : (
          <>
            {openModal && (
              <CreateNoticeModal
                value={{
                  id: id || 0,
                  title,
                  body,
                }}
                open={openModal}
                onClose={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                title='Give Your Notice A Title:*'
                submitBtnText='SAVE'
                cancelBtnText='CANCEL'
              />
            )}
          </>
        )}
      </div>
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onDelete={removeNoticeById}
        title='Delete Confirmation'
        body='Are you sure that you want to delete the notice'
      />
    </>
  );
};

export default NoticeItem;
