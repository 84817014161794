import { AxiosRequestConfig } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import { TSearchParams } from 'store/slices/searchSlice/types';

import type {
  TAllPartsRequest,
  TGetVendorListParams,
  TVendor,
  TVendorUpdate,
} from 'store/slices/inventorySlice/types';

export const getAllPartsRequest = (options: TAllPartsRequest) =>
  client.get(endpoints.InventoriesService.getAllParts(), { params: { ...options } });

export const getPartByPartIdRequest = (id: number) =>
  client.get(endpoints.InventoriesService.getPartById(id));

export const updatePartBySnapShotIdRequest = (id: number) =>
  client.put(endpoints.InventoriesService.updatePartBySnapShotId(id));

export const getSnapShotByPartIdOpenedRequest = (id: number) =>
  client.get(endpoints.InventoriesService.getSnapShotByPartIdOpened(id));

export const getPartsHistoryByPartIdRequest = (id: number) =>
  client.get(endpoints.InventoriesService.getPartsHistoryByPartId(id));

export const getSnapShotDeclineRequest = (id: number) =>
  client.get(endpoints.InventoriesService.getSnapShotDecline(id));

export const removePartByIdRequest = (ids: number[]) => {
  const config: AxiosRequestConfig = {
    data: ids,
  };

  return client.delete(endpoints.InventoriesService.removePartById(), config);
};

export const createPartRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.InventoriesService.createPart(), body, options);
};

export const createPartSnapShotRequest = (body: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return client.post(endpoints.InventoriesService.createPartSnapShot(), body, options);
};

export const getAllVendorListRequest = (options: TGetVendorListParams) =>
  client.get(endpoints.InventoriesService.getVendorList(), { params: { ...options } });

export const removeVendorByIdRequest = (id: number) =>
  client.delete(endpoints.InventoriesService.removeVendorById(id));

export const removeExcelByIdRequest = (id: number) =>
  client.delete(endpoints.InventoriesService.removeExcelById(id));

export const setDefaultExcelByIdRequest = (id: number) =>
  client.put(endpoints.InventoriesService.setDefaultExcelById(id));

export const getVendorByIdRequest = (id: number) =>
  client.get(endpoints.InventoriesService.getVendorById(id));

export const getExcelByIdRequest = (id: number) =>
  client.get(endpoints.InventoriesService.getExcelById(id));

export const createVendorRequest = (body: TVendor) => {
  return client.post(endpoints.InventoriesService.createVendor(), { new_vendor: body });
};

export const createExcelRequest = (data: FormData) => {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  return client.post(endpoints.InventoriesService.createExcel(), data, options);
};

export const createTemplateRequest = (body: FormData) => {
  return client.post(endpoints.InventoriesService.createVendor(), { new_vendor: body });
};

export const updateVendorRequest = (body: TVendorUpdate) => {
  return client.put(endpoints.InventoriesService.editVendor(), { ...body });
};

export const findVendorRequest = ({ query, limit, offset }: TSearchParams) => {
  return client.post(endpoints.InventoriesService.findVendor(query), null, {
    params: { limit, offset },
  });
};
