import { FC, useEffect, useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Colors } from 'types';
import { findCustomer } from 'store/thunks';
import { PlusIcon, SelectedIcon } from 'assets/icons';
import ControlledInput from 'components/views/ControlledInput';
import { createEstimate } from 'store/slices/estimatesSlice/thunks';
import { getCustomersList } from 'store/slices/customersSlices/thunks';
import {
  catalogListSelector,
  currentSectionVersionsSelector,
} from 'store/slices/catalogSlice/selectors';
import { customersSelector } from 'store/slices/customersSlices/selectors';
import { foundedCustomerSelector } from 'store/slices/searchSlice/selectors';
import {
  useInput,
  useWindowSize,
  useAppDispatch,
  useAppSelector,
  useRefreshClearState,
} from 'hooks';
import {
  Button,
  ControlledAutocomplete,
  CreateCustomerDrawer,
  CustomTypography,
  DrawerLayout,
} from 'components';

import { breakPoints } from '../../../../constants';
import CatalogCreateConfigure from '../CatalogCreateConfigure';

import { schema } from './schema';
import styles from './CreateEstimateDrawer.module.scss';
import SelectCatalogDropDown from './SelectCatalogDropDown';
import { catalogErrorMessage, customerErrorMessage } from './utils';

import type { TCreateEstimateDrawerProps, TCreateEstimateForm } from './types';
import type { TCatalog } from './SelectCatalogDropDown/types';

const CreateEstimateDrawer: FC<TCreateEstimateDrawerProps> = ({ open, handleClose }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const foundedCustomers = useAppSelector(foundedCustomerSelector);
  const allCustomers = useAppSelector(customersSelector);
  const allCatalogs = useAppSelector(catalogListSelector);

  const [currentCatalog, setCurrentCatalog] = useState<TCatalog | null>(null);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);
  const [openCatalogDrawer, setOpenCatalogDrawer] = useState<boolean>(false);
  const [openCustomerDrawer, setOpenCustomerDrawer] = useState<boolean>(false);

  const { value, debouncedValue, handleChange } = useInput();

  const { width } = useWindowSize();

  useEffect(() => {
    if (debouncedValue?.trim()?.length) {
      dispatch(findCustomer({ query: debouncedValue, limit: 100 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    dispatch(getCustomersList({ offset: 0, limit: 1 }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<TCreateEstimateForm>({ resolver: yupResolver(schema) });

  const drawerWidth =
    Number(width) >= breakPoints.TABLET ? 472 : Number(width) >= breakPoints.TABLET_M ? 400 : 375;

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    const selectedItemsChanged = selectedItem?.map((item) => ({
      uuid: item,
      profit_margin: 0,
    }));

    const estimateData = {
      name: data.name,
      version_no: '1' || null,
      section_uuids: selectedItemsChanged,
      customer_id: data?.customer?.id as number,
      catalog_versions_id: currentCatalog?.version_id as number,
    };

    const response = await dispatch(createEstimate(estimateData as any)).unwrap();
    if (response) {
      reset();
      onDrawerCloseReset();
      navigate(`/work-flow/bom-estimate/estimate/${response?.uuid}`);
    }
  };

  const handleSelect = (id: string) => {
    if (!selectedItem?.includes(id)) {
      setSelectedItem([...selectedItem, id]);
    } else {
      const filteredItem = selectedItem.filter((el: string) => el !== id);

      setSelectedItem(filteredItem);
    }
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerCloseReset = async () => {
    reset({
      name: '',
      customer: {},
    });
    setCurrentCatalog(null);
    setSelectedItem([]);
    handleClose();
  };

  const isDisabled = currentCatalog?.id && selectedItem?.length;

  const currentSections = useAppSelector(currentSectionVersionsSelector);

  const renderSections = currentCatalog?.sections?.map((section) => {
    const renderActive = selectedItem.includes(section.uuid);

    return (
      <Button
        padding='12px 8px'
        key={section.uuid}
        width='100%'
        maxWidth='100%'
        tooltipMessage={
          !currentSections?.is_ready ? 'Section creation is in progress please wait' : undefined
        }
        disabled={!currentSections?.is_ready}
        endIcon={
          !renderActive ? (
            <PlusIcon width={20} height={20} onClick={() => handleSelect(section.uuid)} />
          ) : (
            <SelectedIcon onClick={() => handleSelect(section.uuid)} />
          )
        }
        className={classNames(styles.container__details__sections__item, {
          [styles.container__details__sections__item__active]: renderActive,
        })}
      >
        {section?.name}
      </Button>
    );
  });

  const customersOptions = foundedCustomers?.data?.map((customer) => ({
    id: customer?.id,
    value: customer?.name,
    label: customer?.name,
  }));

  const notHaveCatalogs = allCatalogs?.total_count === 0;
  const notHaveCustomers = allCustomers?.total_count === 0;

  const goForCreationTheCustomer = () => setOpenCustomerDrawer(true);

  const goForCreationTheCatalog = () => setOpenCatalogDrawer(true);

  const customersHelperText = errors?.customer?.id?.message;

  useRefreshClearState();

  return (
    <>
      <DrawerLayout
        open={open}
        padding='40px'
        inCenter={false}
        width={drawerWidth}
        onClose={onDrawerClose}
        headerTitle='Create Estimate'
        onCloseReset={onDrawerCloseReset}
      >
        <form className={styles.container} onSubmit={handleSubmit(handleCreateData)}>
          <Box className={styles.container__content}>
            <ControlledInput
              required
              name='name'
              control={control}
              borderRadius='5px'
              label='Estimate name:'
              fontFamily='CircularStdLight'
              placeholder='Estimate name:*'
              borderColor={Colors.SOFT_SILVER}
              backgroundColor={Colors.FROST_WHITE}
            />
            <div>
              <ControlledAutocomplete
                name='customer'
                label='Customer:'
                control={control}
                borderRadius='5px'
                inputValue={value}
                optionsName='label'
                placeholder='Customer:*'
                errors={!!errors.customer}
                options={customersOptions}
                fontFamily='CircularStdLight'
                borderColor={Colors.SOFT_SILVER}
                handleInputChange={handleChange}
                backgroundColor={Colors.FROST_WHITE}
                helperText={customersHelperText && String(customersHelperText)}
              />

              {notHaveCustomers && (
                <FormHelperText
                  sx={{ color: 'red', marginTop: '3px', marginLeft: '14px !important' }}
                >
                  {customerErrorMessage}
                </FormHelperText>
              )}

              <Button
                fontSize='12px'
                className={styles.container__add}
                onClick={goForCreationTheCustomer}
                startIcon={<PlusIcon width={16} height={16} />}
              >
                CREATE CUSTOMER NOT LISTED
              </Button>
            </div>
            <div>
              <SelectCatalogDropDown
                currentCatalog={currentCatalog}
                setCurrentCatalog={setCurrentCatalog}
                helperText={notHaveCatalogs ? catalogErrorMessage : ''}
              />
              <Button
                fontSize='12px'
                onClick={goForCreationTheCatalog}
                className={styles.container__add}
                startIcon={<PlusIcon width={16} height={16} />}
              >
                CREATE CATALOG NOT LISTED
              </Button>
            </div>

            {!!currentCatalog?.sections?.length && (
              <Box className={styles.container__details}>
                {!!currentCatalog?.sections?.length && (
                  <CustomTypography className={styles.container__details__title}>
                    Select Sections:
                    <span
                      className={styles.container__details__title__count}
                    >{` (${currentCatalog?.sections?.length})`}</span>
                  </CustomTypography>
                )}
                <Box className={styles.container__details__sections}>{renderSections}</Box>
              </Box>
            )}
          </Box>
          <Box className={styles.container__actions}>
            <Button
              type='button'
              minWidth='68px'
              borderRadius='5px'
              padding='11px 8px'
              onClick={onDrawerClose}
              color={Colors.SAPPHIRE}
              backgroundColor={Colors.PALE_BLUE}
              className={styles.container__submit}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              minWidth='68px'
              borderRadius='5px'
              padding='12px 16px'
              color={Colors.WHITE}
              backgroundColor={Colors.SAPPHIRE}
              className={styles.container__submit}
              disabled={!isDisabled || isSubmitting || !isValid}
            >
              Create
            </Button>
          </Box>
        </form>
      </DrawerLayout>
      <CatalogCreateConfigure
        open={openCatalogDrawer}
        onClose={() => setOpenCatalogDrawer(false)}
      />
      <CreateCustomerDrawer
        open={openCustomerDrawer}
        handleClose={() => setOpenCustomerDrawer(false)}
      />
    </>
  );
};

export default CreateEstimateDrawer;
