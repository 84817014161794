import { useEffect, useMemo, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import {
  Button,
  DrawerLayout,
  CustomTextField,
  CustomTypography,
  ActivityDropDown,
} from 'components/shared';
import { TOption } from 'components/shared/Select/types';
import { TCreateTeam } from 'store/slices/teamsSlice/types';
import { allTagsSelector } from 'store/slices/teamsSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { ControlledAutocomplete, JobTypesComplete } from 'components';
import { createTeam, getAllTags } from 'store/slices/teamsSlice/thunks';
import { useAppDispatch, useAppSelector, useOnClickOutside, useWindowSize } from 'hooks';
import { ArrowDown } from 'assets/icons';
import { Colors, type TFormSubmit } from 'types';

import { breakPoints } from '../../../../constants';

import { TNewTeamModal, TTeamObject, TWatchTeamLead } from './types';
import styles from './NewTeamModal.module.scss';

const NewTeamModal: React.FC<TNewTeamModal> = ({ members, open, onClose }) => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();

  const tags = useAppSelector(allTagsSelector);

  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const [addTag, setAddTag] = useState<boolean>(false);
  const [addMore, setAddMore] = useState<boolean>(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<(number | string)[]>([]);
  const [selectedActivityTypes, setSelectedActivityType] = useState<TOption[]>([]);

  const {
    watch,
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const createNewTeam: TFormSubmit = async (data) => {
    const options = {
      org_id: Number(currentOrganization),
      name: data.teamTitle.trim(),
      description: data.teamDetails.trim(),
      team_lead_ids: data?.teamLeader?.map((leader: TTeamObject) => leader?.value),
      member_ids: data?.teamMembers?.map((member: TTeamObject) => member?.value),
      tag_ids: data?.jobType ? data?.jobType?.map((tag: TTeamObject) => tag.value) : [],
    } as TCreateTeam;

    await dispatch(createTeam(options));
    onClose();
    reset();
  };

  const filteredMembers = members?.filter((item) => {
    if (!selectedIds?.includes(item.user_id as number)) {
      return item;
    }
  });

  const returnedMembers = filteredMembers?.map(({ name, user_id }) => {
    return {
      label: name || '--',
      value: user_id,
      color: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256,
      )}, ${Math.floor(Math.random() * 256)})`,
    };
  });

  const modifiedData = returnedMembers;

  const activityData = useMemo(
    () =>
      tags?.data.map(({ name, id, color }) => ({
        label: name as string,
        value: id as number,
        color: color as string,
        title: name,
        name: name as string,
        id,
      })),
    [tags],
  );

  useEffect(() => {
    dispatch(getAllTags());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const watchResult = watch();

  useEffect(() => {
    const ids = watchResult?.teamLeader?.map((el: TWatchTeamLead) => el.value) || [];

    const memberIds = watchResult?.teamMembers?.map((el: TWatchTeamLead) => el.value) || [];

    const returnedIds = ids.concat(memberIds);

    setSelectedIds(returnedIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchResult?.teamLeader, watchResult?.teamMembers]);

  const handleAddTags = () => {
    setAddMore(true);
    setAutocompleteOpen(false);
  };
  const handleClose = () => {
    setAddMore(false);
    setAutocompleteOpen(false);
  };

  const boxRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(boxRef, handleClose);

  const isTeamLeaderExist = watchResult?.teamLeader?.length;
  const isTeamMemberExist = watchResult?.teamMembers?.length;

  const renderDisabled =
    (isTeamLeaderExist || isTeamMemberExist) &&
    watchResult?.teamTitle?.trim() &&
    watchResult?.teamDetails?.trim();

  const handleCloseReset = () => {
    reset({
      jobType: '',
      teamTitle: '',
      teamLeader: [],
      teamDetails: '',
      teamMembers: [],
    });
    onClose();
  };

  const renderDrawerWidth = Number(width) >= breakPoints.MOBILE ? 472 : 375;

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      onClose={onClose}
      width={renderDrawerWidth}
      onCloseReset={handleCloseReset}
      headerTitle='Create A New Team'
      titleClassName={styles.container__title}
    >
      <Box className={styles.container}>
        <form className={styles.container__content} onSubmit={handleSubmit(createNewTeam)}>
          <div className={styles.container__content__wrapper}>
            <div className={styles.container__content__title}>
              <Controller
                name='teamTitle'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomTextField
                    required
                    label='Title:'
                    value={value}
                    onChange={onChange}
                    backGroundColor={Colors.LIGHT_SILVER}
                    borderColor={Colors.SOFT_SILVER}
                    borderRadius='10px'
                    placeholder={'Title:*'}
                    fontFamily='CircularStdRegular'
                    className={styles.container__content__title__input}
                  />
                )}
              />
            </div>
            <div className={styles.container__content__select}>
              <div>
                <CustomTypography className={styles.container__fields}>Leads*</CustomTypography>
                <ControlledAutocomplete
                  isWithChips={true}
                  name='teamLeader'
                  label='Add Team Leads...'
                  isFromWorkOrder={true}
                  hoverColor='transparent'
                  backgroundColor={Colors.LIGHT_SILVER}
                  borderColor={Colors.SOFT_SILVER}
                  inputIcon={<ArrowDown width={24} height={24} />}
                  control={control}
                  required={false}
                  optionsName='label'
                  placeholder={!isTeamLeaderExist ? 'Add Team Leads...' : ''}
                  errors={null}
                  fontFamily='CircularStdRegular'
                  options={modifiedData || []}
                />
              </div>

              <div>
                <CustomTypography className={styles.container__fields}>Members*</CustomTypography>
                <ControlledAutocomplete
                  isWithIcon
                  errors={null}
                  required={false}
                  control={control}
                  name='teamMembers'
                  isWithChips={true}
                  optionsName='label'
                  borderColor={Colors.SOFT_SILVER}
                  isFromWorkOrder={true}
                  hoverColor='transparent'
                  backgroundColor={Colors.LIGHT_SILVER}
                  label='Add Team Members...'
                  options={modifiedData || []}
                  fontFamily='CircularStdRegular'
                  inputIcon={<ArrowDown width={24} height={24} />}
                  placeholder={!isTeamMemberExist ? 'Add Team Members...' : ''}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <CustomTypography className={styles.container__fields}>Tags</CustomTypography>

                {addMore && (
                  <ActivityDropDown
                    title='Tag'
                    addTag={addTag}
                    data={tags.data}
                    setAddTag={setAddTag}
                    setAddMore={setAddMore}
                    selectedJobTypes={selectedActivityTypes}
                    setSelectedJobType={setSelectedActivityType}
                    className={styles.container__content__select__wrapper__dropdown__box}
                  />
                )}
                <Controller
                  name='jobType'
                  control={control}
                  defaultValue=''
                  render={({ field: { onChange, value } }) => (
                    <JobTypesComplete
                      isBold
                      ref={boxRef}
                      label='Add Tags...'
                      activeValue={value}
                      borderColor={Colors.SOFT_SILVER}
                      options={activityData}
                      placeholder='Add Tags...'
                      backgroundColor={Colors.LIGHT_SILVER}
                      error={!!errors?.jobType}
                      handleOnChange={onChange}
                      onTagCreate={handleAddTags}
                      autocompleteOpen={autocompleteOpen}
                      setAutocompleteOpen={setAutocompleteOpen}
                    />
                  )}
                />
              </div>

              <div className={styles.container__content__select__details}>
                <CustomTypography className={styles.container__fields}>
                  Description:*
                </CustomTypography>

                <Controller
                  name='teamDetails'
                  control={control}
                  rules={{ required: 'Team Details is required' }}
                  render={({ field: { onChange, value } }) => (
                    <CustomTextField
                      rows={4}
                      required
                      multiline
                      value={value}
                      onChange={onChange}
                      label='Description:'
                      borderColor={Colors.SOFT_SILVER}
                      backGroundColor={Colors.LIGHT_SILVER}
                      placeholder='Description:*'
                      fontFamily='CircularStdRegular'
                      className={styles.container__content__title__input}
                      helperText={errors.teamDetails && String(errors.teamDetails.message)}
                    />
                  )}
                />
              </div>
            </div>

            <div className={styles.container__content__actions}>
              <Button
                type='button'
                color={Colors.SAPPHIRE}
                minWidth='68px'
                disabled={isSubmitting}
                backgroundColor={Colors.PALE_BLUE}
                onClick={onClose}
                className={styles.container__content__actions__item}
              >
                Cancel
              </Button>
              <Button
                color={Colors.WHITE}
                type='submit'
                minWidth='65px'
                disabled={isSubmitting || !renderDisabled}
                backgroundColor={Colors.SAPPHIRE}
                className={styles.container__content__actions__item}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </Box>
    </DrawerLayout>
  );
};

export default NewTeamModal;
