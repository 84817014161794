import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { Routes } from 'types';

export const breadCrumbOptions = [
  { id: 1, label: 'Workflow', goTo: Routes.BomEstimate },
  { id: 2, label: 'Work Order', goTo: Routes.WorkOrder },
  { id: 2, label: 'View', goTo: Routes.ViewWorkOrder },
];

export const columns: GridColDef[] = [
  {
    flex: 1,
    field: 'sequence',
    headerName: 'Sequence',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    flex: 1,
    field: 'employee_name',
    headerName: 'Tech Name',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    flex: 1,
    field: 'start_date',
    headerName: 'Start Date',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    flex: 1,
    field: 'end_date',
    headerName: 'End Date',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    flex: 1,
    field: 'start_time',
    headerName: 'Start Time',
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },

    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'daily_hours',
    headerName: 'Daily hours',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} />;
    },
  },
];
