import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import {
  getPurchaseOrderDataById,
  getPurchaseOrderDataByUuid,
  getPurchaseOrderItems,
  getPurchaseOrders,
} from './thunks';

import type { TInitialState, TPurchaseOrderData, TPurchaseOrderReturnType } from './types';

const initialState: TInitialState = {
  purchaseOrderAsc: true,
  allPurchaseOrdersLimit: 5,
  allPurchaseOrdersOffset: 0,
  purchaseOrderDataById: null,
  allPurchaseOrdersError: null,
  purchaseOrderItemsError: null,
  purchaseOrderItemsLoad: false,
  allPurchaseOrdersLoading: false,
  allPurchaseOrders: dataStructure,
  purchaseOrderDataByIdLoad: false,
  purchaseOrderDataByIdError: null,
  purchaseOrderItems: dataStructure,
};

const purchaseOrderSlice = createSlice({
  name: 'purchaseOrderSlice',
  reducers: {
    setPage(state, action) {
      state.allPurchaseOrdersOffset = (action.payload - 1) * state.allPurchaseOrdersLimit;
    },
    setLimit(state, action) {
      state.allPurchaseOrdersLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPurchaseOrders.pending, (state) => {
        state.allPurchaseOrdersLoading = true;
        state.allPurchaseOrdersError = null;
      })
      .addCase(getPurchaseOrders.fulfilled, (state, action) => {
        state.allPurchaseOrdersLoading = false;
        state.allPurchaseOrders = action.payload;
        state.purchaseOrderAsc = !state.purchaseOrderAsc;
      })
      .addCase(getPurchaseOrders.rejected, (state, action) => {
        state.allPurchaseOrdersLoading = false;
        state.allPurchaseOrdersError = action.error.message as string;
      })
      .addCase(
        getPurchaseOrderItems.fulfilled,
        (state, action: PayloadAction<TPurchaseOrderReturnType>) => {
          state.purchaseOrderItems = action.payload;
          state.purchaseOrderItemsLoad = false;
          state.purchaseOrderItemsError = null;
        },
      )
      .addCase(getPurchaseOrderItems.pending, (state) => {
        state.purchaseOrderItemsLoad = true;
        state.purchaseOrderItemsError = null;
      })
      .addCase(getPurchaseOrderItems.rejected, (state, action) => {
        state.purchaseOrderItemsLoad = false;
        state.purchaseOrderItemsError = action.payload as string;
      })
      .addCase(
        getPurchaseOrderDataByUuid.fulfilled,
        (state, action: PayloadAction<TPurchaseOrderData>) => {
          state.purchaseOrderDataById = action.payload;
          state.purchaseOrderDataByIdLoad = false;
          state.purchaseOrderDataByIdError = null;
        },
      )
      .addCase(getPurchaseOrderDataByUuid.pending, (state) => {
        state.purchaseOrderDataByIdLoad = true;
        state.purchaseOrderDataByIdError = null;
      })
      .addCase(getPurchaseOrderDataByUuid.rejected, (state, action) => {
        state.purchaseOrderDataByIdLoad = false;
        state.purchaseOrderDataByIdError = action.payload as string;
      })

      .addCase(
        getPurchaseOrderDataById.fulfilled,
        (state, action: PayloadAction<TPurchaseOrderData>) => {
          state.purchaseOrderDataById = action.payload;
          state.purchaseOrderDataByIdLoad = false;
          state.purchaseOrderDataByIdError = null;
        },
      )
      .addCase(getPurchaseOrderDataById.pending, (state) => {
        state.purchaseOrderDataByIdLoad = true;
        state.purchaseOrderDataByIdError = null;
      })
      .addCase(getPurchaseOrderDataById.rejected, (state, action) => {
        state.purchaseOrderDataByIdLoad = false;
        state.purchaseOrderDataByIdError = action.payload as string;
      });
  },
});

export default purchaseOrderSlice.reducer;

export const { setPage, setLimit } = purchaseOrderSlice.actions;
