import React, { useEffect, useState, useRef, BaseSyntheticEvent, useMemo } from 'react';
import classNames from 'classnames';
import { Box, Checkbox, Menu } from '@mui/material';

import { TOrganizationMember } from 'store/slices/organizationsSlice/types';
import { allUsersDataSelector } from 'store/slices/organizationsSlice/selectors';
import { createUserRole, deleteUserRole } from 'store/slices/authSlice/authThunks';
import { Button, CustomTextField, CustomTypography, EmptyTitle } from 'components';
import { ArrowDown, CheckedIcon, SearchFilterIcon, UncheckedIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector, useOnClickOutside, useUsersByRole } from 'hooks';
import {
  userDataSelector,
  userAllRolesSelector,
  userDesignationSelector,
  userAllRolesLoadingSelector,
} from 'store/slices/authSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Colors } from 'types';

import { menuStyles } from './styles';
import styles from './EmployeeDropdown.module.scss';

import type { TEmployeeDropdown } from './types';

const EmployeeDropdown: React.FC<TEmployeeDropdown> = ({ role }) => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(userDataSelector);
  const userRole = useAppSelector(userDesignationSelector);
  const isLoad = useAppSelector(userAllRolesLoadingSelector);
  const userRoles = useAppSelector(userAllRolesSelector);
  const [value, setValue] = useState<string>('');

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer = !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const organizationMembers = useAppSelector(allUsersDataSelector);
  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const [anchorEl, setAnchorEl] = useState(null);
  const [removedUsersIds, setRemovedUsersIds] = useState<number[]>([]);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const renderedMembersForOwner = useMemo(
    () =>
      organizationMembers.data
        ?.filter((user) => user?.designation !== 'owner' && user?.id !== userInfo?.id && !!user?.id)
        ?.filter((user) => {
          if (role?.id !== 10) {
            return user?.designation !== 'manager';
          } else {
            return user;
          }
        }),
    [organizationMembers.data, role?.id, userInfo?.id],
  );

  const renderedMembersForManagers = useMemo(
    () =>
      organizationMembers?.data
        ?.filter((user) => user?.designation !== 'owner' && user?.id !== userInfo?.id && !!user?.id)
        ?.filter((user) => user?.designation === 'employee'),
    [organizationMembers?.data, userInfo?.id],
  );

  const renderedList = useMemo(
    () => (userRole === 'manager' ? renderedMembersForManagers : renderedMembersForOwner),
    [renderedMembersForManagers, renderedMembersForOwner, userRole],
  );
  const [searchedMembers, setSearchedMembers] = useState<TOrganizationMember[] | []>(
    renderedList || [],
  );

  const { usersList, getUsersByRoles } = useUsersByRole(role?.id as number);

  const usersListIds = useMemo(
    () => organizationMembers?.data?.filter((user) => !!user?.id)?.map((user) => user?.id),
    [organizationMembers],
  );

  const newCommonUserIds = useMemo(
    () =>
      usersList
        ?.filter((user) => usersListIds?.includes(user.user_id))
        ?.map((user) => user?.user_id),
    [usersList, usersListIds],
  );

  const [selectedEmployees, setSelectedEmployees] = useState<number[]>(newCommonUserIds || []);

  useEffect(() => {
    setSelectedEmployees(newCommonUserIds);
  }, [anchorEl, newCommonUserIds]);

  useEffect(() => {
    if (renderedList) {
      setSearchedMembers(renderedList);
    }
  }, [renderedList]);

  const arrowClasses = classNames(styles.container__header__icon, {
    [styles.container__header__icon_active]: anchorEl,
  });

  const handleEmployeeSelect = (selectedItems: number[]) => {
    const sendedData = new Set(selectedItems);

    const filteredData = Array.from(sendedData)?.filter((id) => !newCommonUserIds?.includes(id));

    const selectedData = filteredData?.map((selectedEmployee) => ({
      role_id: role.id as number,
      user_id: selectedEmployee,
      org_id: Number(currentOrganization),
    }));

    if (filteredData?.length) {
      dispatch(createUserRole(selectedData));
      getUsersByRoles();
    }

    if (removedUsersIds?.length) {
      dispatch(deleteUserRole(removedUsersIds as number[]));
      setRemovedUsersIds([]);
      getUsersByRoles();
    }

    setAnchorEl(null);
  };

  const sendedData = new Set(selectedEmployees);
  const filteredData = Array.from(sendedData)?.filter((id) => !newCommonUserIds?.includes(id));

  const handleItemToggle = async (user: TOrganizationMember) => {
    if (selectedEmployees.includes(user?.id)) {
      setSelectedEmployees(selectedEmployees.filter((id) => id !== user?.id));
      const uncheckedUserRoleId = usersList?.find((member) => member?.user_id === user?.id);
      if (uncheckedUserRoleId) {
        setRemovedUsersIds([...removedUsersIds, uncheckedUserRoleId?.id]);
      }
    } else {
      setSelectedEmployees([...selectedEmployees, user?.id]);
      const uncheckedUserRoleId = usersList?.find((member) => member?.id === user?.id);

      setRemovedUsersIds(removedUsersIds?.filter((item) => item !== uncheckedUserRoleId?.id));
    }
  };

  const closeDropdown = () => {
    setAnchorEl(null);
    setRemovedUsersIds([]);
    setSelectedEmployees([]);
  };

  const handleMoreClick = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (event: BaseSyntheticEvent) => {
    setValue(event.target.value);
    const filteredList = renderedList?.filter((el) => {
      return el.name.toLowerCase().includes(event.target.value.toLowerCase());
    });

    setSearchedMembers(filteredList || []);
  };

  const renderSelectList = searchedMembers?.map((item: TOrganizationMember) => (
    <Box key={item?.id} className={styles.container__content__list__user}>
      <Checkbox
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        disabled={item.id === null}
        onChange={() => handleItemToggle(item)}
        checked={selectedEmployees.includes(item.id)}
      />
      <CustomTypography className={styles.container__content__list__user__name} title={item?.name}>
        {item?.name}
      </CustomTypography>
    </Box>
  ));

  const handleSendClick = () => {
    getUsersByRoles();
    handleEmployeeSelect(selectedEmployees);
  };

  useOnClickOutside(menuRef, closeDropdown);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const titleClasses = classNames(styles.container__header__title, {
    [styles.container__header__title_active]: anchorEl,
  });

  const headerClasses = classNames(styles.container__header, {
    [styles.container__header_active]: anchorEl,
  });

  useEffect(() => {
    setSearchedMembers(renderedList || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.container}>
      <Box className={styles.container}>
        <button onClick={(event) => handleMoreClick(event)} className={headerClasses}>
          <CustomTypography className={titleClasses}>Select...</CustomTypography>
          <ArrowDown className={arrowClasses} />
        </button>

        <Menu
          sx={menuStyles}
          ref={menuRef}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box className={styles.container__content}>
            <Box className={styles.container__content__header}>
              <span>{role?.role}</span>
              <ArrowDown className={styles.container__content__header__icon} />
            </Box>
            <Box className={styles.container__content__body}>
              <CustomTextField
                value={value}
                borderRadius='5px'
                padding='8px 16px'
                onChange={handleChange}
                placeholder='“Search"'
                backGroundColor={Colors.WHITE}
                borderColor={Colors.LIGHT_GRAYISH_SILVER}
                className={styles.container__content__search}
                leftIcon={<SearchFilterIcon width={20} height={20} />}
              />

              <Box className={styles.container__content__list}>
                {!renderSelectList?.length ? (
                  <EmptyTitle title='No users founded' loadingDone={false} />
                ) : (
                  <>
                    {isLoad ? (
                      <EmptyTitle title='No users founded' loadingDone={isLoad} />
                    ) : (
                      renderSelectList
                    )}
                  </>
                )}
              </Box>

              <Box className={styles.container__content__buttons}>
                <Button
                  type='button'
                  maxWidth='68px'
                  color={Colors.SAPPHIRE}
                  minWidth='68px'
                  padding='11px 8px'
                  borderRadius='5px'
                  onClick={handleClose}
                  backgroundColor={Colors.PALE_BLUE}
                  className={styles.container__content__buttons__cancel}
                >
                  Cancel
                </Button>
                <Button
                  color='white'
                  type='submit'
                  maxWidth='73px'
                  minWidth='73px'
                  padding='12px 16px'
                  borderRadius='5px'
                  onClick={handleSendClick}
                  backgroundColor={Colors.SAPPHIRE}
                  className={styles.container__content__buttons__apply}
                  disabled={(!filteredData?.length && !removedUsersIds?.length) || isViewer}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </Menu>
      </Box>
    </Box>
  );
};

export default EmployeeDropdown;
