export const mccOptions = [
  { label: 'A/C, Refrigeration Repair', value: '7623' },
  { label: 'Accounting/Bookkeeping Services', value: '8931' },
  { label: 'Advertising Services', value: '7311' },
  { label: 'Airports, Flying Fields', value: '4582' },
  { label: 'Antique Reproductions', value: '5937' },
  { label: 'Antique Shops', value: '5932' },
  { label: 'Aquariums', value: '7998' },
  { label: 'Art Dealers and Galleries', value: '5971' },
  { label: 'Artists Supply and Craft Shops', value: '5970' },
  { label: 'Auto Body Repair Shops', value: '7531' },
  { label: 'Auto Paint Shops', value: '7535' },
  { label: 'Auto Service Shops', value: '7538' },
  { label: 'Auto and Home Supply Stores', value: '5531' },
  { label: 'Automated Fuel Dispensers', value: '5542' },
  { label: 'Automobile Associations', value: '8675' },
  { label: 'Automotive Parts and Accessories Stores', value: '5533' },
  { label: 'Automotive Tire Stores', value: '5532' },
  { label: 'Bakeries', value: '5462' },
  { label: 'Bands, Orchestras', value: '7929' },
  { label: 'Barber and Beauty Shops', value: '7230' },
  { label: 'Bicycle Shops', value: '5940' },
  { label: 'Billiard/Pool Establishments', value: '7932' },
  { label: 'Boat Dealers', value: '5551' },
  { label: 'Boat Rentals and Leases', value: '4457' },
  { label: 'Book Stores', value: '5942' },
  { label: 'Books, Periodicals, and Newspapers', value: '5192' },
  { label: 'Bowling Alleys', value: '7933' },
  { label: 'Business/Secretarial Schools', value: '8244' },
  { label: 'Buying/Shopping Services', value: '7278' },
  { label: 'Cable, Satellite, and Other Pay Television and Radio', value: '4899' },
  { label: 'Camera and Photographic Supply Stores', value: '5946' },
  { label: 'Candy, Nut, and Confectionery Stores', value: '5441' },
  { label: 'Car Rental Agencies', value: '7512' },
  { label: 'Car Wash', value: '7542' },
  { label: 'Car and Truck Dealers (New & Used)', value: '5511' },
  { label: 'Car and Truck Dealers (Used Only)', value: '5521' },
  { label: 'Carpentry Services', value: '1750' },
  { label: 'Carpet/Upholstery Cleaning', value: '7217' },
  { label: 'Caterers', value: '5811' },
  { label: 'Chemicals and Allied Product', value: '5169' },
  { label: 'Child Care Services', value: '8351' },
  { label: 'Childrens and Infants Wear Stores', value: '5641' },
  { label: 'Civic, Social, Fraternal Associations', value: '8641' },
  { label: 'Cleaning and Maintenance', value: '7349' },
  { label: 'Clothing Rental', value: '7296' },
  { label: 'Colleges, Universitie', value: '8220' },
  { label: 'Commercial Equipment', value: '5046' },
  { label: 'Commercial Footwear', value: '5139' },
  { label: 'Commercial Photography, Art and Graphics', value: '7333' },
  { label: 'Commuter Transport, Ferries', value: '4111' },
  { label: 'Computer Network Services', value: '4816' },
  { label: 'Computer Programming', value: '7372' },
  { label: 'Computer Repair', value: '7379' },
  { label: 'Computer Software Stores', value: '5734' },
  { label: 'Computers, Peripherals, and Software', value: '5045' },
  { label: 'Concrete Work Services', value: '1771' },
  { label: 'Construction Materials', value: '5039' },
  { label: 'Consulting, Public Relations', value: '7392' },
  { label: 'Correspondence Schools', value: '8241' },
  { label: 'Cosmetic Stores', value: '5977' },
  { label: 'Country Clubs', value: '7997' },
  { label: 'Courier Services', value: '4215' },
  { label: 'Credit Reporting Agencies', value: '7321' },
  { label: 'Dairy Products Stores', value: '5451' },
  { label: 'Dance Hall, Studios, Schools', value: '7911' },
  { label: 'Department Stores', value: '5311' },
  { label: 'Detective Agencies', value: '7393' },
  { label: 'Digital Goods Media – Books, Movies, Music', value: '5815' },
  { label: 'Digital Goods – Games', value: '5816' },
  { label: 'Direct Marketing - Catalog Merchant', value: '5964' },
  { label: 'Direct Marketing - Insurance Services', value: '5960' },
  { label: 'Direct Marketing - Other', value: '5969' },
  { label: 'Direct Marketing - Subscription', value: '5968' },
  { label: 'Discount Stores', value: '5310' },
  { label: 'Drapery, Window Covering, and Upholstery Stores', value: '5714' },
  { label: 'Drinking Places', value: '5813' },
  { label: 'Dry Cleaners', value: '7216' },
  { label: 'Duty Free Stores', value: '7309' },
  { label: 'Eating Places, Restaurants', value: '5812' },
  { label: 'Educational Services', value: '8299' },
  { label: 'Electric Vehicle Charging', value: '5552' },
  { label: 'Electrical Parts and Equipment', value: '5065' },
  { label: 'Electrical Services', value: '1731' },
  { label: 'Electronics Repair Shops', value: '7622' },
  { label: 'Electronics Stores', value: '5732' },
  { label: 'Family Clothing Stores', value: '5651' },
  { label: 'Fast Food Restaurants', value: '5814' },
  { label: 'Fireplace, Fireplace Screens, and Accessories Stores', value: '5718' },
  { label: 'Floor Covering Stores', value: '5713' },
  { label: 'Florists Supplies, Nursery Stock, and Flowers', value: '5193' },
  { label: 'Freezer and Locker Meat Provisioners', value: '5422' },
  { label: 'Funeral Services, Crematories', value: '7261' },
  { label: 'Furniture Repair, Refinishing', value: '7641' },
  { label: 'Furriers and Fur Shops', value: '5681' },
  { label: 'Gift, Card, Novelty, and Souvenir Shops', value: '5947' },
  { label: 'Glass, Paint, and Wallpaper Stores', value: '5231' },
  { label: 'Glassware, Crystal Stores', value: '5950' },
  { label: 'Golf Courses - Public', value: '7992' },
  { label: 'Grocery Stores, Supermarkets', value: '5411' },
  { label: 'Hardware Stores', value: '5251' },
  { label: 'Health and Beauty Spas', value: '7298' },
  { label: 'Heating, Plumbing, A/C', value: '1711' },
  { label: 'Hobby, Toy, and Game Shops', value: '5945' },
  { label: 'Hotels, Motels, and Resorts', value: '7011' },
  { label: 'Household Appliance Stores', value: '5722' },
  { label: 'Information Retrieval Services', value: '7375' },
  { label: 'Jewelry Stores, Watches, Clocks, and Silverware Stores', value: '5944' },
  { label: 'Landscaping Services', value: '0780' },
  { label: 'Laundries', value: '7211' },
  { label: 'Legal Services, Attorneys', value: '8111' },
  { label: 'Luggage and Leather Goods Stores', value: '5948' },
  { label: 'Massage Parlors', value: '7297' },
  { label: 'Mens and Boys Clothing and Accessories Stores', value: '5611' },
  { label: 'Mens, Womens Clothing Stores', value: '5691' },
  { label: 'Moving and Storage Companies, and Local Delivery Services', value: '4214' },
  { label: 'Motorcycle Shops and Dealers', value: '5571' },
  { label: 'Music Stores-Musical Instruments, Pianos, and Sheet Music', value: '5733' },
  { label: 'Nurseries, Lawn and Garden Supply Stores', value: '5261' },
  { label: 'Office and Commercial Furniture', value: '5021' },
  { label: 'Package Stores-Beer, Wine, and Liquor', value: '5921' },
  { label: 'Paints, Varnishes, and Supplies', value: '5198' },
  { label: 'Pawn Shops', value: '5933' },
  { label: 'Pet Shops, Pet Food, and Supplies', value: '5995' },
  { label: 'Photographic Studios', value: '7221' },
  { label: 'Public Warehousing and Storage', value: '4225' },
  { label: 'Religious Goods Stores', value: '5973' },
  { label: 'Sewing, Needlework, Fabric, and Piece Goods Stores', value: '5949' },
  { label: 'Shoe Stores', value: '5661' },
  { label: 'Sporting Goods Stores', value: '5941' },
  { label: 'Stamp and Coin Stores', value: '5972' },
  { label: 'Stationary, Office Supplies, Printing and Writing Paper', value: '5111' },
  { label: 'Taxicabs/Limousines', value: '4121' },
  { label: 'Tourist Attractions and Exhibits', value: '7991' },
  { label: 'Towing Services', value: '7549' },
  { label: 'Transportation Services', value: '4789' },
  { label: 'Travel Agencies, Tour Operators', value: '4722' },
  { label: 'Veterinary Services', value: '0742' },
  { label: 'Watch/Jewelry Repair', value: '7631' },
  { label: 'Womens Accessory and Specialty Shops', value: '5631' },
];
