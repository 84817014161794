import { ImgUrlFolders } from 'types/global/imgUrlFolders';

const returnImgUrl = (folderName: ImgUrlFolders, imgId: string | null): string => {
  try {
    const baseUrl = process.env.REACT_APP_BUCKET_BASE_URL;
    const bucketName = process.env.REACT_APP_BUCKET_NAME;

    if (imgId?.startsWith('https')) {
      return imgId;
    } else if (!imgId) {
      return '';
    } else {
      return `${baseUrl}/${bucketName}/${folderName}/${imgId}`;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('An error occurred:', error);
    return '';
  }
};

export default returnImgUrl;
