import { FC } from 'react';
import classNames from 'classnames';

import { useWindowSize } from 'hooks';

import CustomSkeleton from '../CustomSkeleton';
import { breakPoints } from '../../../constants';

import { TTableEmptyOverlayProps } from './types';
import styles from './TableEmptyOverlay.module.scss';

const TableEmptyOverlay: FC<TTableEmptyOverlayProps> = ({
  isFromDivided,
  isLoading = false,
  emptyTitle = 'No rows',
}) => {
  const { width } = useWindowSize();

  const rowSkeletons = Array.from({ length: 4 })?.map((_, idx) => (
    <CustomSkeleton key={idx} height={30} width='100%' />
  ));

  const renderTitleClasses = classNames(styles.container__title, {
    [styles.container__title__divided]: isFromDivided,
  });

  const renderWidth =
    Number(width) <= breakPoints.WIDE && Number(width) >= breakPoints.TABLET_L
      ? `${Number(width) - 80}px`
      : Number(width) <= breakPoints.TABLET_L
      ? `${Number(width) - 48}px`
      : '100%';

  return (
    <div
      className={styles.container}
      style={{
        width: renderWidth,
      }}
    >
      {isLoading ? rowSkeletons : <p className={renderTitleClasses}>{emptyTitle}</p>}
    </div>
  );
};

export default TableEmptyOverlay;
