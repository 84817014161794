import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitialState } from './types';
import {
  findVendor,
  getAllParts,
  getOpenedSnapshotData,
  getPartById,
  getPartHistory,
  getVendorById,
  getVendorList,
  getExcelList,
} from './thunks';

const initialState: TInitialState = {
  allPartsLimit: 5,
  allPartsAsc: true,
  allPartsOffset: 0,
  allVendorsLimit: 5,
  allVendorsAsc: true,
  allPartsError: null,
  allVendorsOffset: 0,
  currentVendor: null,
  currentPartById: null,
  allVendorsError: null,
  allPartsLoading: false,
  allParts: dataStructure,
  allVendorsLoading: false,
  allExcelLoading: false,
  allExcelError: null,
  allVendors: dataStructure,
  currentPartByIdLoad: false,
  currentPartByIdError: null,
  partHistoryDataError: null,
  foundedVendors: dataStructure,
  openedSnapshotDataError: null,
  partHistoryDataLoading: false,
  partHistoryData: dataStructure,
  openedSnapshotDataLoading: false,
  openedSnapshotData: dataStructure,
  currentExcel: null,
  allExcel: dataStructure,
  allExcelLimit: 5,
  allExcelOffset: 0,
  allExcelAsc: true,
  excelListLoading: false,
};

const inventorySlice = createSlice({
  name: 'inventorySlice',
  reducers: {
    setPage(state, action) {
      state.allPartsOffset = (action.payload - 1) * state.allPartsLimit;
    },
    setLimit(state, action) {
      state.allPartsLimit = action.payload;
    },
    setVendorsLimit(state, action) {
      state.allVendorsLimit = action.payload;
    },
    setExcelLimit(state, action) {
      state.allExcelLimit = action.payload;
    },
    setVendorPage(state, action) {
      state.allVendorsOffset = (action.payload - 1) * state.allVendorsLimit;
    },
    setExcelPage(state, action) {
      state.allExcelOffset = (action.payload - 1) * state.allExcelLimit;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllParts.pending, (state) => {
        state.allPartsLoading = true;
        state.allPartsError = null;
      })
      .addCase(getAllParts.fulfilled, (state, action) => {
        state.allPartsLoading = false;
        state.allParts = action.payload;
        state.allPartsAsc = !state.allPartsAsc;
      })
      .addCase(getVendorById.fulfilled, (state, action) => {
        state.currentVendor = action.payload;
      })
      .addCase(getPartById.fulfilled, (state, action) => {
        state.currentPartById = action.payload;
        state.currentPartByIdLoad = false;
        state.currentPartByIdError = null;
      })
      .addCase(getPartById.rejected, (state, action) => {
        state.currentPartByIdLoad = false;
        state.currentPartByIdError = action.error.message as string;
      })
      .addCase(getPartById.pending, (state) => {
        state.currentPartByIdLoad = true;
        state.currentPartById = null;
        state.currentPartByIdError = null;
      })
      .addCase(getAllParts.rejected, (state, action) => {
        state.allPartsLoading = false;
        state.allPartsError = action.error.message as string;
      })
      .addCase(getVendorList.pending, (state) => {
        state.allVendorsLoading = true;
        state.allVendorsError = null;
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        state.allVendorsLoading = false;
        state.allVendors = action.payload;
        state.allVendorsAsc = !state.allVendorsAsc;
      })
      .addCase(getVendorList.rejected, (state, action) => {
        state.allVendorsLoading = false;
        state.allVendorsError = action.error.message as string;
      })
      .addCase(getExcelList.pending, (state) => {
        state.allExcelLoading = true;
        state.allExcelError = null;
      })
      .addCase(getExcelList.fulfilled, (state, action) => {
        state.allExcelLoading = false;
        state.allExcel = action.payload;
        state.allVendorsAsc = !state.allVendorsAsc;
      })
      .addCase(getExcelList.rejected, (state, action) => {
        state.allExcelLoading = false;
        state.allExcelError = action.error.message as string;
      })
      .addCase(getOpenedSnapshotData.pending, (state) => {
        state.openedSnapshotDataLoading = true;
        state.openedSnapshotDataError = null;
      })
      .addCase(getOpenedSnapshotData.fulfilled, (state, action) => {
        state.openedSnapshotDataLoading = false;
        state.openedSnapshotData = action.payload;
      })
      .addCase(getOpenedSnapshotData.rejected, (state, action) => {
        state.openedSnapshotDataLoading = false;
        state.openedSnapshotDataError = action.error.message as string;
      })
      .addCase(getPartHistory.pending, (state) => {
        state.partHistoryDataLoading = true;
        state.partHistoryDataError = null;
      })
      .addCase(getPartHistory.fulfilled, (state, action) => {
        state.partHistoryDataLoading = false;
        state.partHistoryData = action.payload;
      })
      .addCase(getPartHistory.rejected, (state, action) => {
        state.partHistoryDataLoading = false;
        state.partHistoryDataError = action.error.message as string;
      })
      .addCase(findVendor.fulfilled, (state, action) => {
        state.foundedVendors = action.payload;
      });
  },
});

export default inventorySlice.reducer;

export const { setPage, setVendorPage, setExcelPage, setLimit, setVendorsLimit, setExcelLimit } =
  inventorySlice.actions;
