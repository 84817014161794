import { useLayoutEffect } from 'react';

import { useMobileDetect } from './useMobileDetect';

const useLockIosToolbar = (isOpen: boolean): void => {
  const { isIos, isMobile } = useMobileDetect();

  useLayoutEffect(() => {
    if (!isIos || !isMobile) {
      return;
    }

    document.body.style.height = '100%';
    document.body.style.overflow = isOpen ? 'hidden' : 'visible';
    document.body.style.position = isOpen ? 'relative' : 'unset';

    document.documentElement.style.height = isOpen ? '100%' : 'unset';
    document.documentElement.style.overflow = isOpen ? 'hidden' : 'unset';
    document.documentElement.style.position = isOpen ? 'relative' : 'unset';
  }, [isIos, isMobile, isOpen]);
};

export default useLockIosToolbar;
