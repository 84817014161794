import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';

import { useWindowSize } from 'hooks';
import { getSubtitleOptionsByIndustry } from 'utils';
import { industryOptions, subtitleOptions } from 'constants/BusinessTypes';
import {
  Select,
  ControlledInput,
  ProcessFormTitle,
  CompanyFileInput,
  ControlledSelect,
  CustomTextField,
  NumberControlledInput,
} from 'components';
import { SettingsIcon } from 'assets/icons';
import { stateOptions } from 'constants/States';
import { sizeOptions } from 'constants/sizeOptions';
import { Colors, FontNames, type TChangeSelectEvent } from 'types';
import { BrowserStorageKeys, BrowserStorageService, LocalForageService } from 'services';
import { LocalForageKeys } from 'services/localForage/types';
import { TemplateOptions } from 'constants/TemplateOptions';

import styles from './CreateAccountForm.module.scss';

import type { TCreateAccountFormProps, TOrganizationData } from './types';

const CreateAccountForm: FC<TCreateAccountFormProps> = ({
  control,
  errors,
  getFile,
  resetField,
  getTypeOfBusinessValue,
}) => {
  const { width } = useWindowSize();

  const [organizationPreviousData, setOrganizationPreviousData] =
    useState<TOrganizationData | null>(null);

  const [logoSrc, setLogoSrc] = useState<string | null>(null);

  useEffect(() => {
    if (organizationPreviousData?.companyLogo) {
      try {
        const imageUrl = URL.createObjectURL(organizationPreviousData?.companyLogo);
        setLogoSrc(imageUrl);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error creating object URL:', error);
      }
    }
  }, [organizationPreviousData?.companyLogo]);

  const currentIndustry = BrowserStorageService.get(BrowserStorageKeys.CurrentIndustry, {
    session: true,
  });

  const organizationData = async () =>
    await LocalForageService.get(LocalForageKeys.ProcessFormFirstStepData).then((res) => {
      setOrganizationPreviousData(res);
    });

  useEffect(() => {
    organizationData();
  }, []);

  const [activeValue, setActiveValue] = useState<string>(currentIndustry || '');

  const secondOptions = getSubtitleOptionsByIndustry(activeValue, industryOptions, subtitleOptions);

  return (
    <Box className={styles.container}>
      <ProcessFormTitle
        lightSubtitle
        title={Number(width) > 576 ? 'Create Organization' : 'Profile Form'}
      />
      <Box className={styles.container__content}>
        <Box className={styles.container__content__main}>
          <ControlledInput
            control={control}
            name='organization'
            required
            label='Organization Name:'
            borderColor={Colors.SOFT_SILVER}
            error={!!errors.organization}
            placeholder='Organization Name:'
            backGroundColor={Colors.LIGHT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            helperText={errors.organization ? (errors?.organization?.message as string) : ''}
          />
          <Controller
            control={control}
            name='industryVertical'
            rules={{ required: 'Industry vertical is required' }}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  onChange={(event: TChangeSelectEvent) => {
                    onChange(event.target.value);
                    setActiveValue(event.target.value);
                    getTypeOfBusinessValue?.('');

                    resetField?.();
                  }}
                  value={value}
                  defaultValue=''
                  borderRadius='5px'
                  multiple={false}
                  label='Industry:*'
                  options={industryOptions}
                  fontFamily='CircularStdRegular'
                  error={!!errors.industryVertical}
                  border={`1px solid ${Colors.SOFT_SILVER}`}
                  backgroundColor={Colors.LIGHT_SILVER}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
              );
            }}
          />
          <Controller
            name='typeOfBusiness'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                onChange={(event: TChangeSelectEvent) => {
                  onChange(event.target.value);
                  getTypeOfBusinessValue?.(event?.target.value);
                }}
                required
                showLabel
                value={value}
                defaultValue=''
                multiple={false}
                borderRadius='5px'
                options={secondOptions}
                label='Type of Business:*'
                fontFamily='CircularStdRegular'
                error={!!errors.typeOfBusiness}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                backgroundColor={Colors.LIGHT_SILVER}
                border={`1px solid ${Colors.SOFT_SILVER}`}
              />
            )}
          />
          <ControlledSelect
            name='size'
            icon={SettingsIcon}
            defaultValue=''
            control={control}
            options={sizeOptions}
            label='Organization Size:'
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            backgroundColor={Colors.LIGHT_SILVER}
            border={`1px solid ${Colors.SOFT_SILVER}`}
          />
          <NumberControlledInput
            name='phoneNumber'
            control={control}
            label='Phone number'
            placeholder='Phone number'
            borderColor={Colors.SOFT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            backgroundColor={Colors.LIGHT_SILVER}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
          />
          <p className={styles.container__content__main__select}>
            Would you like to choose a Catalog or a Pricing Template for creating an Estimate?
          </p>
          <ControlledSelect
            name='template_type'
            // icon={SettingsIcon}
            defaultValue=''
            control={control}
            options={TemplateOptions}
            label='Estimate Type:'
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            backgroundColor={Colors.LIGHT_SILVER}
            border={`1px solid ${Colors.SOFT_SILVER}`}
          />
        </Box>
        <Box className={styles.container__content__main}>
          <Box className={styles.container__content__items}>
            <ControlledInput
              control={control}
              name='ownerFirstName'
              label='Owner First Name:*'
              placeholder='Owner First Name:*'
              borderColor={Colors.SOFT_SILVER}
              fontFamily={FontNames.CIRCULAR_REG}
              backGroundColor={Colors.LIGHT_SILVER}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />
            <ControlledInput
              control={control}
              name='ownerLastName'
              label='Owner Last Name:*'
              placeholder='Owner Last Name:*'
              borderColor={Colors.SOFT_SILVER}
              fontFamily={FontNames.CIRCULAR_REG}
              backGroundColor={Colors.LIGHT_SILVER}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />
          </Box>
          <ControlledInput
            name='email'
            label='Email:*'
            control={control}
            readonly
            disabled
            placeholder='Email:*'
            fontFamily={FontNames.CIRCULAR_REG}
            borderColor={Colors.SOFT_SILVER}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            backGroundColor={Colors.LIGHT_SILVER}
            helperText={errors.email && (errors?.email?.message as string)}
          />

          <ControlledInput
            name='address'
            label='Address'
            control={control}
            placeholder='Address'
            borderColor={Colors.SOFT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            backGroundColor={Colors.LIGHT_SILVER}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
          />
          <Box className={styles.container__content__items}>
            <ControlledSelect
              name='state'
              label='State'
              control={control}
              borderRadius='5px'
              options={stateOptions}
              fontFamily={FontNames.CIRCULAR_REG}
              backgroundColor={Colors.LIGHT_SILVER}
              border={`1px solid ${Colors.SOFT_SILVER}`}
            />
            <ControlledInput
              name='city'
              label='City'
              control={control}
              borderRadius='5px'
              placeholder='City'
              borderColor={Colors.SOFT_SILVER}
              backGroundColor={Colors.LIGHT_SILVER}
              fontFamily={FontNames.CIRCULAR_REG}
            />
            <Controller
              name='zip'
              control={control}
              defaultValue=''
              rules={{ required: 'Zip code is required' }}
              render={({ field, formState: { errors } }) => (
                <CustomTextField
                  {...field}
                  label='Zip'
                  placeholder='Zip:'
                  error={!!errors.zip}
                  borderColor={Colors.SOFT_SILVER}
                  backGroundColor={Colors.LIGHT_SILVER}
                  fontFamily={FontNames.CIRCULAR_REG}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  helperText={errors?.zip?.message as string}
                />
              )}
            />
          </Box>

          <Box className={styles.container__content__footer}>
            <CompanyFileInput
              forLogo
              maxWidth='100%'
              imgSrc={logoSrc}
              getFile={getFile}
              blockMaxWidth='100%'
              containerMaxWidth='100%'
              withBottomBlock={false}
              containerClassName={styles.container__content__footer__file}
              containerBoxClassName={styles.container__content__footer__file}
              replaceClasses={styles.container__content__footer__file__replace}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAccountForm;
