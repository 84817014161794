import React, { FC, Fragment } from 'react';

import {
  AddNewInventoryDrawer,
  DeletePopup,
  InventoryDetailsDrawer,
  PurchaseOrderVendorDrawer,
} from 'components';
import CreatePurchaserOrderDrawer from 'containers/PurchaseOrder/CreatePurchaserOrderDrawer';
import { useAppSelector } from 'hooks';
import { optionsSelector } from 'store/slices/optionsSlice/selectors';

import { TInventoryDrawersProps } from './types';

const InventoryDrawers: FC<TInventoryDrawersProps> = ({
  file,
  getFile,
  onClose,
  onDelete,
  hideInfo,
  closeDrawer,
  hideCreatePo,
  isShowInfo,
  isNewDrawer,
  isCreatePo,
  isPopupOpen,
  isEditInventory,
  closeEditDrawer,
  currentImageSrc,
  isVendorListOpen,
  openVendorDrawer,
  getCurrentImgSrc,
  isVendorDrawerOpen,
  closeVendorListedDrawer,
}) => {
  const { selectedInventoryItem: selectedItems } = useAppSelector(optionsSelector);

  return (
    <Fragment>
      <AddNewInventoryDrawer
        file={file}
        getFile={getFile}
        open={isNewDrawer}
        handleClose={closeDrawer}
        getImgSrc={getCurrentImgSrc}
        currentImageSrc={currentImageSrc as string}
      />
      <AddNewInventoryDrawer
        file={file}
        inEditMode
        getFile={getFile}
        open={isEditInventory}
        getImgSrc={getCurrentImgSrc}
        handleClose={closeEditDrawer}
        currentImageSrc={currentImageSrc as string}
      />
      <PurchaseOrderVendorDrawer open={isVendorDrawerOpen} handleClose={closeVendorListedDrawer} />
      <InventoryDetailsDrawer open={isShowInfo} onClose={hideInfo} />
      <DeletePopup
        withBody
        onClose={onClose}
        onDelete={onDelete}
        isOpen={isPopupOpen}
        title='Delete Confirmation'
        body='Please confirm if you wants to delete the inventory item.'
      />
      <CreatePurchaserOrderDrawer
        isOpen={isCreatePo}
        onClose={hideCreatePo}
        inventoryData={selectedItems}
        isVendorListOpen={isVendorListOpen}
        openVendorDrawer={openVendorDrawer}
      />
    </Fragment>
  );
};

export default InventoryDrawers;
