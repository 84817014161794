import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';

import { PlusIcon } from 'assets/icons';

import styles from './RequisitionForm.module.scss';
import RequisitionItems from './RequisitionItems';

import type { TRequisitionForm } from './types';

const RequisitionForm: FC<TRequisitionForm> = ({ control, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requisitionItems',
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({ itemNo: '', quantity: '', description: '', id: Math.random() });
    }
  }, [fields, append]);

  const removeUserInfo = (index: number) => {
    remove(index);
  };

  const requisitionItems = fields.map((user, idx) => (
    <Controller
      key={user.id}
      control={control}
      name={`requisitionItems[${idx}]`}
      render={() => (
        <RequisitionItems
          idx={idx}
          control={control}
          errors={errors?.[idx]}
          removeUserInfo={() => removeUserInfo(idx)}
        />
      )}
    />
  ));

  return (
    <Box className={styles.container}>
      {requisitionItems}
      <PlusIcon className={styles.container__plus} role='button' onClick={append} />
    </Box>
  );
};

export default RequisitionForm;
