import { useEffect, useState } from 'react';

import useDebounce from 'hooks/useDebounce';
import { TChangeInputEvent } from 'types/global/events';

const useInput = (value?: string) => {
  const [inputValue, setInputValue] = useState<string>(value || '');

  const handleChange = (event: TChangeInputEvent) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (value) {
      setInputValue(value);
    }
  }, [value]);

  const debouncedValue = useDebounce(inputValue);

  return {
    value: inputValue,
    handleChange,
    debouncedValue,
  };
};

export default useInput;
