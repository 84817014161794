import { TOption } from 'components/shared/Select/types';

export type TInitialState = {
  allWorkOrdersList: TAllWorkOrdersReturnType;
  allWorkOrdersAsc: boolean;
  allWorkOrdersLimit: number;
  allWorkOrdersOffset: number;
  allWorkOrdersLoading: boolean;
  allWorkOrdersError: Error | null | string;
  currentWorkOrderById: TWorkOrder | null;
  currentWorkOrderByIdLoading: boolean;
  currentWorkOrderByIdError: Error | null | string;
  currentTaskById: TWorkOrderTask | null;
  currentTaskByIdLoading: boolean;
  currentTaskByIdError: Error | null | string;
  allTasksList: TAllTaskReturnType;
  allTasksAsc: boolean;
  allTasksLimit: number;
  allTasksOffset: number;
  allTasksLoading: boolean;
  allTasksError: Error | null | string;
  allTasksListForUpdate: TAllTaskReturnType;
  allTasksLoadingForUpdate: boolean;
  allTasksErrorForUpdate: Error | null | string;
};

export type TAllWorkOrdersReturnType = {
  data: TWorkOrder[];
  total_count: number;
};

export type TAllTaskReturnType = {
  data: TWorkOrderTask[];
  total_count: number;
};

export type TWorkOrder = {
  id: number;
  customer_id: number;
  supervisor_id: number;
  start_date: Date | string;
  end_date: Date | string;
  notes: string;
  estimate_id: number;
  status: string;
  created_at: string;
  updated_at: string;
  customer_name: string;
  supervisor_name: string;
  customer_phone: string;
  customer_email: string;
  customer_address: string;
  estimate_name: string;
};

export type TWorkOrderBody = {
  customer_id: null | number;
  supervisor_id: null | number;
  start_date: string | Date;
  end_date: string | Date;
  notes: string | Date;
  estimate_id: number | null;
};

export type TWorkOrderUpdate = {
  customer_id: null | number;
  supervisor_id: null | number;
  start_date: string | Date;
  end_date: string | Date;
  notes: string | Date;
  estimate_id: number | null;
  status: string;
  id: number;
};

export type TWorkOrderTask = {
  id: number;
  technician_id: number | string;
  start_date: Date | string;
  end_date: Date | string;
  start_time: string;
  daily_hours: number;
  work_order_id: number;
  sequence: number | string;
  description: string;
  status: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  employee_name: string | TOption;
} & Partial<{
  tempTask: boolean;
}>;

export type TTaskUpdateBody = {
  technician_id: number;
  start_date: string;
  end_date: string;
  start_time: string;
  daily_hours: number | string;
  work_order_id: number | string;
  sequence: number | string;
  description: string;
  status: string;
} & Partial<{
  id: number;
}>;

export type TTaskCreateBody = {
  technician_id: number;
  start_date: string;
  end_date: string;
  start_time: string;
  daily_hours: number;
  work_order_id: number;
  sequence: number;
  description: string;
  status: string;
};

export enum WorkOrdersSortBy {
  ID = 'id',
  CUSTOMER_NAME = 'customer_name',
  ESTIMATE_NAME = 'estimate_name',
  START_DATE = 'start_date',
  STATUS = 'status',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
}

export type TAllWorkOrderRequest = {
  limit: number;
  offset: number;
} & Partial<{
  asc: boolean;
  sort_by: WorkOrdersSortBy | null;
}>;

export enum TaskSortBy {
  ID = 'id',
  EMPLOYEE_NAME = 'employee_name',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  START_TIME = 'start_time',
  DAILY_HOURS = 'daily_hours',
  SEQUENCE = 'sequence',
  STATUS = 'status',
}

export type TAllTaskRequestoptions = {
  limit: number;
  offset: number;
} & Partial<{
  asc: boolean;
  sort_by: TaskSortBy | null;
}>;
