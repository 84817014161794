import { TReasonItem } from './types';

const reportsReasons: TReasonItem[] = [
  { id: 1, value: 'Sexual Contents' },
  { id: 2, value: 'Fraud Or Scam' },
  { id: 3, value: 'Spam' },
  { id: 4, value: 'Misinformation' },
  { id: 5, value: 'Threat Or  Violence' },
  { id: 6, value: 'Self-harm' },
  { id: 7, value: 'Graphic Content' },
  { id: 8, value: 'Infringement' },
  { id: 9, value: 'Hateful Speech' },
  { id: 10, value: 'Dangerous or extremist  organization' },
];

export default reportsReasons;
