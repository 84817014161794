import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';

import { MinusFilledIcon } from 'assets/icons';
import { findInventories } from 'store/slices/searchSlice/thunks';
import { useAppDispatch, useAppSelector, useInput } from 'hooks';
import ControlledAutocomplete from 'components/views/ControlledAutocomplete';
import { foundedInventoriesSelector } from 'store/slices/searchSlice/selectors';
import { CustomTextField } from 'components/shared';
import { Colors } from 'types';

import styles from './PurchaseOrderFieldItem.module.scss';
import { TPurchaseOrderFieldItem } from './types';

const PurchaseOrderFieldItem: FC<TPurchaseOrderFieldItem> = ({
  idx,
  item,
  control,
  itemsFromWatch,
  removeUserInfo,
  sectionName = 'sectionOne',
}) => {
  const dispatch = useAppDispatch();

  const [isQtyValueChanged, setIsQtyValueChanged] = useState<boolean>(false);
  const [isPriceValueChanged, setIsPriceValueChanged] = useState<boolean>(false);

  const {
    value: nameValue,
    debouncedValue: nameDebouncedValue,
    handleChange: nameHandleChange,
  } = useInput();

  const {
    value: priceValue,
    debouncedValue: priceDebouncedValue,
    handleChange: priceHandleChange,
  } = useInput();

  const {
    value: qtyValue,
    debouncedValue: qtyDebouncedValue,
    handleChange: qtyHandleChange,
  } = useInput();

  useEffect(() => {
    if (nameDebouncedValue?.length > 0) {
      dispatch(findInventories({ query: nameDebouncedValue }));
    }

    if (priceDebouncedValue?.length > 0) {
      dispatch(findInventories({ query: priceDebouncedValue }));
    }

    if (qtyDebouncedValue?.length > 0) {
      dispatch(findInventories({ query: qtyDebouncedValue }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameDebouncedValue, priceDebouncedValue, qtyDebouncedValue]);

  const foundedParts = useAppSelector(foundedInventoriesSelector);

  const options = foundedParts?.data?.map((item) => ({
    id: item?.id,
    itemName: item?.name,
    price: item?.cost || 0,
    quantity: item?.qty || 0,
    description: item?.description,
  }));

  const isNameValueValid = options?.some((option) =>
    option.itemName.toLowerCase().includes(nameValue.toLowerCase()),
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.container__content}>
        <Box className={styles.container__top}>
          <Box className={styles.container__top__name}>
            <ControlledAutocomplete
              options={options}
              control={control}
              borderRadius='5px'
              label='Item No/Name:'
              borderColor={Colors.SOFT_SILVER}
              padding='16.5px 16px'
              inputValue={nameValue}
              optionsName='itemName'
              placeholder='Item No/Name:*'
              fontFamily='CircularStdRegular'
              name={`${sectionName}[${idx}]`}
              handleInputChange={nameHandleChange}
              backgroundColor={Colors.LIGHT_SILVER}
              errors={nameValue && !isNameValueValid}
              helperText='Name should be selected from options list'
            />
          </Box>
          <Box className={styles.container__top__field}>
            <Controller
              control={control}
              name={`${sectionName}[${idx}].quantity`}
              render={({ field: { onChange } }) => (
                <CustomTextField
                  label='Quantity:'
                  type='number'
                  borderRadius='5px'
                  borderColor={Colors.SOFT_SILVER}
                  value={
                    itemsFromWatch?.[idx]?.quantity
                      ? isQtyValueChanged
                        ? qtyValue
                        : item?.quantity || String(itemsFromWatch?.[idx]?.quantity)
                      : ''
                  }
                  padding='16.5px 16px'
                  placeholder='Quantity:*'
                  backGroundColor={Colors.LIGHT_SILVER}
                  fontFamily='CircularStdRegular'
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const isValidInput = /^\d*$/.test(inputValue) && inputValue !== '';

                    if (isValidInput) {
                      onChange(event);
                      qtyHandleChange(event);
                      setIsQtyValueChanged(true);
                    }
                  }}
                />
              )}
            />
          </Box>
          <Box className={styles.container__top__field}>
            <Controller
              control={control}
              name={`${sectionName}[${idx}].price`}
              render={({ field: { onChange } }) => (
                <CustomTextField
                  label='Inventory Price:'
                  required={false}
                  borderRadius='5px'
                  placeholder='Inventory Price:'
                  padding='16.5px 16px'
                  borderColor={Colors.SOFT_SILVER}
                  value={
                    itemsFromWatch?.[idx]?.price
                      ? isPriceValueChanged
                        ? priceValue
                        : item.price || String(itemsFromWatch?.[idx]?.price)
                      : ''
                  }
                  backGroundColor={Colors.LIGHT_SILVER}
                  fontFamily='CircularStdRegular'
                  name={`${sectionName}[${idx}]`}
                  onChange={(event) => {
                    const inputValue = event.target.value;
                    const isValidInput = /^\d*$/.test(inputValue) && inputValue !== '';

                    if (isValidInput) {
                      onChange(event);
                      priceHandleChange(event);
                      setIsPriceValueChanged(true);
                    }
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </Box>

      {idx !== 0 && (
        <MinusFilledIcon
          width={24}
          height={24}
          role='button'
          onClick={removeUserInfo}
          className={styles.container__content__remove}
        />
      )}
    </Box>
  );
};

export default PurchaseOrderFieldItem;
