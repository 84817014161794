import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { CustomTypography, Button } from 'components';
import { Colors, FontNames, Routes } from 'types';
import { useAppSelector } from 'hooks';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { CustomBreadcrumbs } from 'components';

import { breadCrumbOptions } from './utils';
import styles from './InvoiceHeader.module.scss';

import type { FC } from 'react';
//import {useState} from 'react';
import type { TInvoiceHeaderProps } from './types';

const InvoiceHeader: FC<TInvoiceHeaderProps> = () => {
  const { isAccessToTransactionHistory } = useAppSelector(userPermissionsSelector);

  const navigate = useNavigate();
  const navigateToTransactions = () => navigate(Routes.Transactions);
  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbOptions} />

      <Box className={styles.container__header}>
        <CustomTypography className={styles.container__header__title}>Invoice</CustomTypography>
        {isAccessToTransactionHistory && (
          <Button
            color={Colors.SAPPHIRE}
            borderRadius='5px'
            variant='contained'
            padding='11px 8px'
            disableColor={Colors.WHITE}
            backgroundColor={Colors.PALE_BLUE}
            fontFamily={FontNames.CIRCULAR_REG}
            onClick={navigateToTransactions}
          >
            Transaction History
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default InvoiceHeader;
