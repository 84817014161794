import { useEffect, useState } from 'react';

import { authApi } from 'api';
import { TUserByRole } from 'store/slices/authSlice/types';

const useUsersByRole = (id: number) => {
  const [usersList, setUsersList] = useState<TUserByRole[]>([]);
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const getUsersByRoles = async () => {
    try {
      setIsLoad(true);
      const response = await authApi.getUsersByRoleRequest(id);
      setUsersList(response?.data);
      setIsLoad(false);
    } catch (error) {
      setIsLoad(false);
      setUsersList([]);
    }
  };

  useEffect(() => {
    if (id) {
      getUsersByRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    isLoad,
    usersList,
    getUsersByRoles,
  };
};

export default useUsersByRole;
