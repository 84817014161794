import { FC } from 'react';

import styles from './IconOutline.module.scss';

import type { TIconOutlineProps } from './types';

const IconOutline: FC<TIconOutlineProps> = ({
  width,
  height,
  children,
  borderRadius,
  backgroundColor,
}) => (
  <div className={styles.container} style={{ width, height, borderRadius, backgroundColor }}>
    {children}
  </div>
);

export default IconOutline;
