import { FC } from 'react';
import classNames from 'classnames';

import { QuoteFrameIcon, QuoteFrameRightIcon } from 'assets/icons';

import styles from './UserQuote.module.scss';

import type { TUserQuoteProps } from './types';

const UserQuote: FC<TUserQuoteProps> = ({ title, position = 'left' }) => {
  const containerClasses = classNames(styles.container, {
    [styles.container__right]: position === 'right',
  });

  const Icon =
    position === 'left' ? (
      <QuoteFrameIcon className={styles.container__icon} />
    ) : (
      <QuoteFrameRightIcon className={styles.container__icon} />
    );

  return (
    <div className={containerClasses}>
      {Icon}
      <p className={styles.container__title} title={title}>
        {title}
      </p>
    </div>
  );
};

export default UserQuote;
