import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import { GridColDef } from '@mui/x-data-grid';

import CustomDataGrid from 'components/views/CustomDataGrid';
import {
  Button,
  CustomSkeleton,
  DeletePopup,
  PortalDropDown,
  ScrollLayout,
  TableControlledRowItem,
} from 'components';
import { Colors, FontNames } from 'types';
import { FilledPlusIcon, MoreIcon, TrashIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { EmptyTitles } from 'constants/EmptyTitles';
import { HourOptions, TimeOptions } from 'constants/States';
import { TWorkOrderTask } from 'store/slices/workOrderSlice/types';
import { deleteTaskById } from 'store/thunks';

import SectionHead from '../Drawers/CatalogCreateConfigure/SectionHead';

import { TtasksSectionProps } from './types';

const AddTask: FC<TtasksSectionProps> = ({
  control,
  isLoading = false,
  getValues,
  employees,
  tasks,
}) => {
  const dispatch = useAppDispatch();

  const { append, remove } = useFieldArray({ control, name: 'tasks' });
  const [maxId, setMaxId] = useState<any>(0);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<any>(0);
  const [deleteInd, setDeleteInd] = useState<any>(0);
  const togglePopup = () => setIsDeletePopupOpen(!isDeletePopupOpen);
  const deleteTaskText = `This will delete the Task. Please confirm.`;

  const onDelete = () => {
    handleRemoveDiscount(deleteInd, deleteId);
    togglePopup();
  };

  useEffect(() => {
    if (tasks) {
      tasks.forEach((row) => {
        const modifiedStartTime = row.start_time.substring(0, 5);
        const employee = employees?.find((emp) => emp.id === row.technician_id);
        if (employee) {
          append({
            task: {
              id: row?.id,
              employee_name: employee,
              description: row?.description,
              start_time: modifiedStartTime,
              start_date: row?.start_date,
              end_date: row?.end_date,
              daily_hours: row?.daily_hours,
              sequence: row?.sequence,
              technician_id: row?.technician_id,
              work_order_id: row?.work_order_id,
              status: row?.status,
              created_at: row?.created_at,
              updated_at: row?.updated_at,
              created_by: row?.created_by,
            },
          });
        }
      });

      if (tasks.length > 0) {
        const maxId = Math.max(...tasks.map((item: { id: any }) => item.id));
        setMaxId(maxId + 1); // Set tempId after fetching tasks
      }
    }
  }, [tasks]);

  const handleAddTaskClick = () => {
    append({
      task: {
        id: maxId,
        end_date: '',
        employee_name: '',
        start_time: '',
        start_date: '',
        daily_hours: 1,
        description: '',
        sequence: 1,
        technician_id: '',
        work_order_id: tasks ? tasks[0].work_order_id : 0,
        status: 'pending',
        created_at: '',
        updated_at: '',
        created_by: '',
      },
    });
    setMaxId(maxId + 1);
  };

  const handleRemoveDiscount = (idx: number, id: number) => {
    const taskIndex = tasks?.findIndex((task) => task.id === id);

    if (taskIndex !== -1) {
      dispatch(deleteTaskById(id));
    }
    remove(idx);
  };

  const getIndexByRow = (arr: any[], id: string | number) => {
    return arr ? arr.findIndex((r) => r.task?.id === id) : -1;
  };

  const columns: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Tech Name',
      flex: 0.7,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }
        return (
          <TableControlledRowItem
            placeholder='Name'
            control={control}
            required
            inputOptions={employees}
            componentType='autocomplete'
            inputOptionsName='label'
            inputClassName='name'
            isExisted={false}
            name={`tasks.${[index]}.task.employee_name`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_code}
          />
        );
      },
    },
    {
      field: 'Description',
      headerName: 'Description',
      flex: 1,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            placeholder='Description'
            componentType='text'
            control={control}
            enableSpaceKeyDown={true}
            isExisted={false}
            name={`tasks.${[index]}.task.description`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_code}
          />
        );
      },
    },
    {
      field: 'sequence',
      headerName: 'Sequence',
      flex: 0.4,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return (
          <HeaderTitle
            withSortIcons={false}
            title={headerName}
            style={{ padding: 0 }}
            fontFamily={FontNames.CIRCULAR_BOLD}
          />
        );
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            placeholder='Sequence'
            control={control}
            isExisted={false}
            name={`tasks.${[index]}.task.sequence`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_code}
          />
        );
      },
    },
    {
      field: 'Start Time',
      headerName: 'Start Time',
      flex: 0.5,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            isExisted={false}
            required
            placeholder='Start Time'
            componentType='select'
            options={TimeOptions}
            name={`tasks.${[index]}.task.start_time`}
            // error={!!errors?.tasks?.[index]?.task?.discount_percent}
          />
        );
      },
    },
    {
      field: 'start',
      headerName: 'Start Date',
      flex: 0.6,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            componentType='picker'
            placeholder='Start Date'
            isExisted={false}
            name={`tasks.${[index]}.task.start_date`}
            // error={!!errors?.tasks?.[index]?.task?.start_date}
          />
        );
      },
    },
    {
      field: 'end',
      headerName: 'End Date',
      flex: 0.6,

      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            value={null}
            isExisted={false}
            componentType='picker'
            placeholder='End Date'
            name={`tasks.${[index]}.task.end_date`}
            // error={!!errors?.tasks?.[index]?.task?.end_date}
          />
        );
      },
    },
    {
      field: 'daily_hours',
      headerName: 'Daily Hours',
      flex: 0.45,
      renderHeader: (params) => {
        const headerName = params?.colDef?.headerName || '';
        return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
      },
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <TableControlledRowItem
            control={control}
            componentType='select'
            placeholder='Daily Hours'
            isExisted={false}
            options={HourOptions}
            required
            // selectDefaultValue='daily_hours'
            name={`tasks.${[index]}.task.daily_hours`}
            // error={!!errors?.tasks?.[index]?.task?.coupon_status}
          />
        );
      },
    },
    {
      field: 'options',
      headerName: '',
      width: 80,
      renderCell: (params) => {
        const index = getIndexByRow(getValues('tasks'), params.id);

        if (index === -1) {
          return null;
        }

        return (
          <PortalDropDown
            title={<MoreIcon />}
            options={[
              {
                id: 1,
                name: 'Delete',
                action: () => {
                  togglePopup();
                  setDeleteId(Number(params.id));
                  setDeleteInd(index);
                },
                icon: <TrashIcon />,
              },
            ]}
          />
        );
      },
    },
  ];

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    sortable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const cloned = getValues('tasks')?.map(({ task }) => ({
    id: task?.id,
    employee_name: task?.employee_name,
    description: task?.description,
    start_time: task?.start_time,
    start_date: task?.start_date,
    end_date: task?.end_date,
    daily_hours: task?.daily_hours,
    sequence: task?.sequence,
    technician_id: task?.technician_id,
    work_order_id: task?.work_order_id,
    status: task?.status,
    created_at: task?.created_at,
    updated_at: task?.updated_at,
    created_by: task?.created_by,
  }));

  return (
    <Box>
      <SectionHead title='tasks' subtitle={`(Assign tasks to employees.)`} />
      <Box>
        {isLoading ? (
          <CustomSkeleton width='100%' height={80} />
        ) : (
          tasks && (
            <ScrollLayout>
              <CustomDataGrid
                rowHeight={70}
                columns={renderColumns}
                rows={cloned || []}
                getRowId={(row: TWorkOrderTask) => row.id}
                emptyTitle={EmptyTitles.WorkOrder_Task}
              />
            </ScrollLayout>
          )
        )}
      </Box>
      <Box>
        <Button
          color={Colors.BLACK}
          isUppercase={false}
          startIcon={<FilledPlusIcon />}
          fontFamily={FontNames.CIRCULAR_REG}
          onClick={handleAddTaskClick}
        >
          Add New Task
        </Button>
      </Box>
      <DeletePopup
        withBody
        onDelete={onDelete}
        isOpen={isDeletePopupOpen}
        onClose={togglePopup}
        body={deleteTaskText}
        title='Delete Confirmation'
      />
    </Box>
  );
};

export default AddTask;
