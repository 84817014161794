import { FC, useState, useEffect, ChangeEvent, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks';
import {
  documentsSelector,
  documentsAscSelector,
  documentsLimitSelector,
  documentsOffsetSelector,
  documentsLoadingSelector,
} from 'store/slices/documentsSlice/selectors';
import { DocumentCard } from 'containers';
import { DocumentIcon } from 'assets/icons';
import { EmptyTitles } from 'constants/EmptyTitles';
import { convertToMMDDYYYY } from 'utils/formatDate';
import { setLimit, setPage } from 'store/slices/documentsSlice';
import { getDocumentList } from 'store/slices/documentsSlice/thunks';
import { SortByEnums, TDocument } from 'store/slices/documentsSlice/types';
import { CustomDataGrid, DynamicPaginationControl, EmptyTitle, ScrollLayout } from 'components';

import { documentsColumns } from '../../utils';

import { type TDocumentContent } from './types';
import styles from './DocumentsContent.module.scss';

const DocumentsContent: FC<TDocumentContent> = ({ isViewInGrid }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const documents = useAppSelector(documentsSelector);
  const documentsAsc = useAppSelector(documentsAscSelector);
  const documentsLimit = useAppSelector(documentsLimitSelector);
  const documentsOffset = useAppSelector(documentsOffsetSelector);
  const documentsLoading = useAppSelector(documentsLoadingSelector);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [newSortBy, setNewSortBy] = useState<SortByEnums>(SortByEnums.CUSTOMER);
  const [isSorted, setIsSorted] = useState<boolean>(false);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field as SortByEnums);
    setIsSorted(true);

    dispatch(
      getDocumentList({
        limit: documentsLimit,
        direction: documentsAsc,
        offset: documentsOffset,
        order_by: column.field as SortByEnums,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      getDocumentList({
        order_by: newSortBy,
        limit: documentsLimit,
        direction: documentsAsc,
        offset: documentsOffset,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsLimit, documentsOffset]);

  const changeItemName = (name: string, id: number, uuid: string) => {
    navigate(`/documents/${name}/${uuid}`);
  };

  const totalRows = documents?.total_count;

  const renderColumns: GridColDef[] = documentsColumns?.map((column) => {
    const isColumnSorted = newSortBy === column.field && isSorted;

    const isAsc = documentsAsc === 'ASC';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !isAsc },
      {
        [styles.activeSortHeader_asc]: isAsc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const renderRows = documents?.data?.map((el: TDocument) => {
    return {
      id: el.customer_id,
      name: el.customer_name,
      uuid: el.customer_uuid,
      customer_name: (
        <div className={styles.container__name}>
          <DocumentIcon width={24} height={24} />
          <a>{el.customer_name}</a>
        </div>
      ),
      last_modified: convertToMMDDYYYY(el?.last_modified),
      size: `${Math.ceil(el.size / 1024)} KB` || '--',
    };
  });

  const handlePageChange = useCallback((_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    dispatch(setPage(newPage + 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentPage(0);
    dispatch(setLimit(event.target.value));
    handlePageChange(null, 0);
  };

  const renderDocumentsCards = renderRows?.map((document, idx) => (
    <DocumentCard key={idx} document={document.name} itemName={document.name} id={document.uuid} />
  ));

  useEffect(() => {
    if (!totalRows && currentPage) {
      setCurrentPage(currentPage - 1);
      handlePageChange(null, currentPage - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRows]);

  return (
    <>
      {isViewInGrid ? (
        renderDocumentsCards?.length ? (
          <Box className={styles.container__cards}>
            <Box className={styles.container__cards__content}>{renderDocumentsCards}</Box>
            {!!totalRows && (
              <Box className={styles.container__footer}>
                <DynamicPaginationControl
                  count={totalRows}
                  page={currentPage}
                  rowsPerPage={documentsLimit}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Box>
            )}
          </Box>
        ) : (
          <EmptyTitle
            title='No Documents order documents added yet'
            loadingDone={documentsLoading}
          />
        )
      ) : (
        <>
          <ScrollLayout>
            <CustomDataGrid
              rowHeight={77}
              headerHeight={52}
              isLoading={documentsLoading}
              rows={renderRows}
              columns={renderColumns}
              emptyTitle={EmptyTitles.Document_Content}
              onColumnHeaderClick={handleColumnHeaderClick}
              onRowClick={({ row }) => changeItemName(row.name, row.id, row.uuid)}
            />
          </ScrollLayout>
          {!!totalRows && (
            <Box className={styles.container__footer}>
              <DynamicPaginationControl
                count={totalRows}
                page={currentPage}
                rowsPerPage={documentsLimit}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default DocumentsContent;
