import { FC } from 'react';
import { Skeleton } from '@mui/material';

import { TCustomSkeletonProps } from './types';

const CustomSkeleton: FC<TCustomSkeletonProps> = ({
  width,
  height,
  maxWidth,
  minWidth,
  minHeight,
  animation,
  maxHeight,
  borderRadius,
  variant = 'rectangular',
}) => (
  <Skeleton
    width={width}
    height={height}
    variant={variant}
    animation={animation}
    sx={{ borderRadius, minHeight, maxHeight, maxWidth, minWidth }}
  />
);

export default CustomSkeleton;
