import { FC } from 'react';

import { TStreamLayoutProps } from './types';
import styles from './StreamLayout.module.scss';

const StreamLayout: FC<TStreamLayoutProps> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.container__content}>{children}</div>
  </div>
);

export default StreamLayout;
