export const sxStyles = {
  margin: '0 !important',
  width: '100%',
  flexDirection: 'row-reverse',
  justifyContent: 'flex-end',

  '& .MuiFormControlLabel-root': {
    width: '100%',
    margin: '0 !important',
  },

  '& .MuiFormControlLabel-label': {
    width: '100%',
    maxWidth: '240px',
  },
};
