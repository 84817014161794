import { FC } from 'react';

import { PostFilterItemProps } from '../types';
import styles from '../PostFilter.module.scss';

import CustomCheckbox from './CustomCheckBox';

import type { TChangeInputEvent } from 'types';

const PostFilterItem: FC<PostFilterItemProps> = ({ value, checked, onChange }) => {
  const handleOnChange = (event: TChangeInputEvent) => {
    onChange({
      id: value?.id,
      checked: event?.target?.checked,
      value: value?.value,
    });
  };

  return (
    <div className={styles.container_absolute_content}>
      <CustomCheckbox
        value={value?.value}
        label={value?.value}
        checked={checked}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default PostFilterItem;
