import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import PreloadedImg from 'components/views/PreloadedImg';

import { TImageGalleryProps } from './types';
import styles from './ImageGallery.module.scss';

const ImageGallery: FC<TImageGalleryProps> = ({ imageList, onClick }) => {
  const containerClasses = classNames(styles.container, {
    [styles.container__three]: imageList?.length === 3,
    [styles.container__four]: imageList?.length === 4,
    [styles.container__five]: imageList?.length >= 5,
  });

  const imageElements = imageList?.slice(0, 5)?.map((image, idx) => {
    if (image?.file_type?.startsWith('image')) {
      return (
        <Box className={styles.container__item} key={image?.id}>
          <PreloadedImg imgId={image?.media_url_id} folderName={ImgUrlFolders.ACTIVITY_FEED} />
          {imageList?.length > 5 && idx === 4 && (
            <Box className={styles.container__layout}>+{imageList?.length - 5}</Box>
          )}
        </Box>
      );
    } else {
      return (
        <Box className={styles.container__item} key={image?.id}>
          <PreloadedImg
            imgId={image?.media_url_id}
            folderName={ImgUrlFolders.ACTIVITY_FEED}
            forVideo
          />
          {imageList?.length > 5 && idx === 4 && (
            <Box className={styles.container__layout}>+{imageList?.length - 5}</Box>
          )}
        </Box>
      );
    }
  });

  return (
    <Box className={containerClasses} onClick={onClick}>
      {imageElements}
    </Box>
  );
};

export default ImageGallery;
