import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';

import { paymentOnboardingSchema } from 'constants/Schemas';
import { ConvertedData } from 'containers/ProcessForm/types';
import { createStripeAccount } from 'store/slices/subscriptionSlice/thunks';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FieldValues } from 'containers/Inventory/ExcelList/AddVendor';
import {
  createOrganizationSelector,
  organizationByIdSelector,
} from 'store/slices/organizationsSlice/selectors';
import BrowserStorageService from 'services/browserStorage';
import { BrowserStorageKeys } from 'services/browserStorage/types';
import BillingInfoItem from 'components/shared/BillingDetailsUpdate/BillingInfoItem/BillingInfoItem';
import { getOrganizationById } from 'store/slices/organizationsSlice/thunks';
import CustomTypography from 'components/shared/Typography/index';

import PaymentOnboarding from '../index';

import styles from './PaymentOnboardingContainer.module.scss';

const PaymentOnboardingContainer = () => {
  const [showPaymentOnboarding, setShowPaymentOnboarding] = useState(false);
  const [documentFront, setDocumentFront] = useState<File | null>(null);
  const [documentLast, setDocumentLast] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const [timeStamp, setTimeStamp] = useState<string>('');
  const [agreement, setAgreement] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState<'CREATE' | 'UPDATE'>('CREATE');

  const currentOrganization = useAppSelector(createOrganizationSelector);

  const organizationData = useAppSelector(organizationByIdSelector);

  const organizationIdFromStorage = BrowserStorageService.get(
    BrowserStorageKeys.CurrentOrganizationId,
  );

  useEffect(() => {
    if (organizationIdFromStorage) {
      dispatch(getOrganizationById(Number(organizationIdFromStorage)));
    }
  }, [organizationIdFromStorage, dispatch]);

  const handleButton = (action: 'UPDATE' | 'CREATE') => {
    setShowPaymentOnboarding((prev) => !prev);
    setSubmitButtonText(action);
  };

  const [ip, setIp] = useState<string>('');

  const handleClick: SubmitHandler<FieldValues> = () => {
    setAgreement(!agreement);
    setTimeStamp(String(Math.floor(Date.now() / 1000)));

    fetchIpAddress().then((ip) => {
      setIp(ip);
    });
  };

  const fetchIpAddress = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      return null;
    }
  };

  const onSubmit = async (data: any) => {
    const StripeData: ConvertedData = {
      org_id: currentOrganization?.id || Number(organizationIdFromStorage),
      account_details: {
        email: data.account_email,
      },
      business_profile: {
        merchant_category_code: data.mcc,
        website_url: data.url,
        business_type: data.business_type,
      },
      company_details: {
        name: data.company_name,
        city: data.company_city,
        line1: data.company_line1,
        line2: data.company_line2,
        postal_code: data.company_zip,
        state: data.company_state,
        phone_number: data.company_phone_number,
        tax_id: data.tax_id,
      },
      owner_details: {
        first_name: data.owner_firstname,
        last_name: data.owner_lastname,
        email: data.account_email,
        id_number: data.owner_id_number,
        dob: data.owner_dob,
        phone_number: data.owner_phone_number,
        city: data.owner_city,
        line1: data.owner_line1,
        line2: data.owner_line2,
        state: data.owner_state,
        postal_code: data.owner_zip,
      },
      representative_details: {
        first_name: data.representative_firstname,
        last_name: data.representative_lastname,
        city: data.representative_city,
        line1: data.representative_line1,
        line2: data.representative_line2,
        postal_code: data.representative_zip,
        state: data.representative_state,
        email: data.representative_email,
        dob: data.representative_dob,
        phone: data.representative_number,
        ssn: data.ssn_last4,
        id_number: data.representative_id_number,
      },
      bank_account_details: {
        account_number: data.acc_number,
        account_holder_name: data.acc_holder_name,
        account_holder_type: data.acc_holder_type,
        routing_number: data.routing_number,
      },
      tos_acceptance: {
        ip: ip,
        date: timeStamp,
      },
    };
    if (StripeData) {
      const formData = new FormData();
      if (documentFront !== null) {
        formData.append('representative_id_front_image', documentFront);
      }
      if (documentLast !== null) {
        formData.append('representative_id_back_image', documentLast);
      }
      formData.append('account_details', JSON.stringify(StripeData));
      await dispatch(createStripeAccount(formData));

      setShowPaymentOnboarding(false);
    }
  };

  const getDocumentFront = (file: File | null) => {
    setDocumentFront(file);
  };

  const getDocumentLast = (file: File | null) => {
    setDocumentLast(file);
  };

  const {
    handleSubmit: PaymentOnboardingSubmit,
    control: control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(paymentOnboardingSchema),
    mode: 'all',
  });

  return (
    <div>
      <Box className={styles.container__head}>
        <CustomTypography className={styles.container__head__title}>
          Account Details
        </CustomTypography>
        {organizationData?.stripe_account_id ? (
          <Button className={styles.container__head__button} onClick={() => handleButton('UPDATE')}>
            {submitButtonText === 'UPDATE' ? 'UPDATE' : 'Update'}
          </Button>
        ) : (
          <Button className={styles.container__head__button} onClick={() => handleButton('CREATE')}>
            {submitButtonText === 'CREATE' ? 'CREATE' : 'Create'}
          </Button>
        )}
      </Box>
      <Box className={styles.container__content}>
        <>
          <BillingInfoItem
            title='Stripe Account Id'
            subtitle={organizationData?.stripe_account_id || null}
          />
          <BillingInfoItem
            title='Stripe Customer Id'
            subtitle={organizationData?.stripe_customer_id || null}
          />
          <BillingInfoItem title='Email' subtitle={organizationData?.email || null} />
        </>
      </Box>
      {showPaymentOnboarding && (
        <>
          <PaymentOnboarding
            errors={errors}
            control={control}
            handleClick={handleClick}
            getFileFront={getDocumentFront}
            getFileLast={getDocumentLast}
          />
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={PaymentOnboardingSubmit(onSubmit)}
              style={{
                color: 'white',
                backgroundColor: '#2067dd',
                fontSize: '16px',
                padding: '10px 20px',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                marginTop: '10px',
              }}
            >
              {submitButtonText === 'CREATE' ? 'CREATE' : 'Update'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentOnboardingContainer;
