import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import { TDocumentsListParams } from 'store/slices/documentsSlice/types';

export const getAllDocumentsRequest = (options: TDocumentsListParams) =>
  client.get(endpoints.DocumentsService.getDocumentsList(), { params: { ...options } });

export const getDocumentTypesRequest = (id: string, options: TDocumentsListParams) =>
  client.get(endpoints.DocumentsService.getDocumentTypes(id), { params: { ...options } });

export const uploadDocumentRequest = (body: FormData) =>
  client.post(endpoints.DocumentsService.uploadDocument(), body);

export const getDocumentByIdRequest = (id: string) =>
  client.get(endpoints.DocumentsService.getDocumentById(id));

export const getDocumentByIdAndSectionRequest = (
  id: string,
  type: string,
  options: TDocumentsListParams,
) =>
  client.get(endpoints.DocumentsService.getDocumentByIdAndSection(id, type), {
    params: { ...options },
  });

export const deleteDocumentRequest = (id: number) =>
  client.delete(endpoints.DocumentsService.deleteDocument(id));
