import { FC } from 'react';
import { Box } from '@mui/material';

import { Button } from 'components';
import { StreamNoteAddIcon } from 'assets/icons';
import { Colors } from 'types';

import styles from '../LiveStream.module.scss';

import type { TStreamHeaderProps } from './types';

const StreamHeader: FC<TStreamHeaderProps> = ({ openNoticeModal }) => (
  <Box className={styles.container__head__controls}>
    <Button
      color='white'
      iconWidth={16}
      minWidth='94px'
      iconHeight={16}
      padding='12px 16px'
      borderRadius='5px'
      isUppercase={false}
      iconMarginRight='8px'
      onClick={openNoticeModal}
      backgroundColor={Colors.SAPPHIRE}
      startIcon={<StreamNoteAddIcon />}
    >
      Notes
    </Button>
  </Box>
);

export default StreamHeader;
