import { AxiosError } from 'axios';
import { useState } from 'react';

import useAppSelector from 'hooks/useAppSelector';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { activitiesSliceSelector } from 'store/slices/activitiesSlices/selector';
import { TActivityReturnType, TPost } from 'store/slices/activitiesSlices/types';
import { activitiesApi } from 'api';

const sortedTeamIds = BrowserStorageService.get(BrowserStorageKeys.sortingTeamIds, {
  session: true,
});

const sortedTeamSource = BrowserStorageService.get(BrowserStorageKeys.sortingSource, {
  session: true,
});

const useActivityList = () => {
  const { activities: activityData } = useAppSelector(activitiesSliceSelector);

  const [currentList, setCurrentList] = useState<TPost[]>(activityData?.data);
  const [currentListLoad, setCurrentListLoad] = useState<boolean>(false);

  const getNewPostsList = async (limit: number, offset: number) => {
    try {
      const sendedParams = sortedTeamSource
        ? { limit, offset, team_ids: sortedTeamIds, source: 'team' }
        : { limit, offset };

      setCurrentListLoad(true);
      const response = await activitiesApi.getAllActivitiesRequest(sendedParams as any);

      if (response?.data as TActivityReturnType) {
        setCurrentList((prevList) => [...prevList, ...(response.data?.data as TPost[])]);
      }

      setCurrentListLoad(false);
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    } finally {
      setCurrentListLoad(false);
    }
  };

  return {
    currentList,
    currentListLoad,
    setCurrentList,
    getNewPostsList,
  };
};

export default useActivityList;
