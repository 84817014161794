import { FC } from 'react';

import { CustomizedAvatar, CustomTypography, KeyboardButton } from 'components';
import { timeDiffer } from 'utils';

import { TUserCardDataInfoProps } from './types';
import styles from './UserCardDataInfo.module.scss';

const UserCardDataInfo: FC<TUserCardDataInfoProps> = ({
  img,
  role,
  name,
  time,
  openTeamInfo,
  teamName,
  withTeam = true,
  variant = 'square',
}) => (
  <div className={styles.container}>
    <div className={styles.container__image}>
      <CustomizedAvatar
        alt='alt'
        src={img}
        width={45}
        height={45}
        variant={variant}
        borderRadius='50%'
        className={styles.container__image__icon}
      />
    </div>
    <div className={styles.container_typo}>
      <CustomTypography className={styles.container_typo_name} title={name}>
        {name}
      </CustomTypography>
      <CustomTypography className={styles.container_typo_role}>{role || 'Role'}</CustomTypography>
      {withTeam && (
        <CustomTypography className={styles.container_typo_hour}>
          {timeDiffer(time as string)}
        </CustomTypography>
      )}
    </div>
    {teamName && (
      <>
        {withTeam && (
          <KeyboardButton title={teamName} onClick={openTeamInfo}>
            <span title={teamName} className={styles.container_typo_team}>
              {teamName || 'team'}
            </span>
          </KeyboardButton>
        )}
      </>
    )}
  </div>
);

export default UserCardDataInfo;
