import { StreamMembersIcon, StreamNotesIcon, TrashIcon } from 'assets/icons';

import { TDropDownActionsCreatorOptions } from './types';

export const dropDownOptionsCreator = ({
  removeCallback,
  membersCallBack,
  notesCallBack,
}: TDropDownActionsCreatorOptions) => [
  { id: 2, name: 'Notes', icon: <StreamNotesIcon />, action: () => notesCallBack() },
  { id: 3, name: 'Members', icon: <StreamMembersIcon />, action: () => membersCallBack() },
  { id: 4, name: 'Delete', icon: <TrashIcon />, action: () => removeCallback() },
];
