import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, FormHelperText } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import breakPoints from 'constants/BreakPoints';
import { workOrderCreateSchema } from 'constants/Schemas';
import { useAppDispatch, useAppSelector, useWindowSize, useInput } from 'hooks';
import { Button, DrawerLayout, ControlledAutocomplete, ControlledFormPicker } from 'components';
import { Routes } from 'types';
import { findEstimate, getEstimates } from 'store/thunks';
import { Colors, FontNames } from 'types';
import { allEstimatesSelector } from 'store/slices/estimatesSlice/selectors';
import { foundedEstimatesSelector } from 'store/slices/searchSlice/selectors';
import { PlusIcon } from 'assets/icons';
import CreateEstimateDrawer from 'components/views/Drawers/CreateEstimateDrawer';

import styles from './AddWorkOrder.module.scss';
import { EstimateErrorMessage } from './utils';

import type { TAddWorkOrderDrawerProps, TWorkOrderFormValues } from './types';

const AddWorkOrder: FC<TAddWorkOrderDrawerProps> = ({ isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const foundedEstimates = useAppSelector(foundedEstimatesSelector);
  const allEstimates = useAppSelector(allEstimatesSelector);
  const { value, debouncedValue, handleChange } = useInput();
  const [openEstimateDrawer, setopenEstimateDrawer] = useState<boolean>(false);

  useEffect(() => {
    if (debouncedValue?.trim()?.length) {
      dispatch(findEstimate({ query: debouncedValue, limit: 100 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    dispatch(getEstimates({ offset: 0, limit: 1 }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goForEstimateCreation = () => setopenEstimateDrawer(true);

  const notHaveEstimates = allEstimates?.total_count === 0;
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<TWorkOrderFormValues>({
    resolver: yupResolver(workOrderCreateSchema),
  });

  const watchResult = watch();

  const { estimate, wo_start_date } = watchResult;

  const isFormValid = estimate?.id && wo_start_date?.toString().length;

  const drawerTitle = 'Add Work Order';

  const handleClose = () => setIsOpen(false);

  const handleCreateData: SubmitHandler<FieldValues> = async (data) => {
    navigate(Routes.CreateWorkOrder, {
      state: {
        WorkOrderID: data?.estimate?.id as number,
        StartWODate: data.wo_start_date,
      },
    });
    reset();
    handleClose();
  };

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset();
    handleClose();
  };

  const estimatesHelperText = errors?.estimate?.id?.message;

  const estimatesOptions = foundedEstimates?.data?.map((estimate) => ({
    id: estimate?.id,
    value: estimate?.name,
    label: estimate?.name,
  }));

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 376
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 275
      : Number(width) <= breakPoints.MOBILE
      ? 175
      : 423;

  return (
    <>
      <DrawerLayout
        open={isOpen}
        inCenter={true}
        width={drawerWidth}
        onClose={onDrawerClose}
        headerTitle={drawerTitle}
        onCloseReset={onDrawerClearClose}
        titleClassName={styles.container__title}
      >
        <form onSubmit={handleSubmit(handleCreateData)} className={styles.container}>
          <Box sx={{ flex: '1 1 auto' }}>
            <Box className={styles.container__content}>
              <div className={styles.container__content__item}>
                <ControlledAutocomplete
                  name='estimate'
                  label='Estimate:'
                  control={control}
                  borderRadius='5px'
                  inputValue={value}
                  optionsName='label'
                  placeholder='Estimate:*'
                  errors={!!errors.estimate}
                  options={estimatesOptions}
                  fontFamily='CircularStdLight'
                  borderColor={Colors.SOFT_SILVER}
                  handleInputChange={handleChange}
                  backgroundColor={Colors.FROST_WHITE}
                  helperText={estimatesHelperText && String(estimatesHelperText)}
                />
                {notHaveEstimates && (
                  <FormHelperText
                    sx={{ color: 'red', marginTop: '3px', marginLeft: '14px !important' }}
                  >
                    {EstimateErrorMessage}
                  </FormHelperText>
                )}
                <Button
                  fontSize='12px'
                  className={styles.container__add}
                  onClick={goForEstimateCreation}
                  startIcon={<PlusIcon width={16} height={16} />}
                >
                  CREATE ESTIMATE NOT LISTED
                </Button>
              </div>
              <div className={styles.container__content__item}>
                <ControlledFormPicker
                  // value={value}
                  padding='10 8px'
                  label='WO Start Date:'
                  name='wo_start_date'
                  control={control}
                  // error={specificError}
                  backgroundColor={Colors.WHITE}
                  className={styles.container__picker}
                  // helperText={specificErrorMessage || specificError?.message}
                />
              </div>
            </Box>
          </Box>
          <Box className={styles.container__footer}>
            <Button
              type='button'
              maxWidth='68px'
              padding='11px 8px'
              minWidth='68px'
              borderRadius='5px'
              onClick={onDrawerClose}
              color={Colors.SAPPHIRE}
              backgroundColor={Colors.PALE_BLUE}
              fontFamily={FontNames.CIRCULAR_BOLD}
              className={styles.container__content__submit}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              color='white'
              maxWidth='68px'
              minWidth='68px'
              padding='11px 8px'
              borderRadius='5px'
              backgroundColor={Colors.SAPPHIRE}
              fontFamily={FontNames.CIRCULAR_BOLD_MAX}
              className={styles.container__content__submit}
              disabled={!isFormValid || isSubmitting || !isDirty}
              // disabled={isSubmitting || !isDirty}
            >
              Next
            </Button>
          </Box>
        </form>
      </DrawerLayout>
      <CreateEstimateDrawer
        open={openEstimateDrawer}
        handleClose={() => setopenEstimateDrawer(false)}
      />
    </>
  );
};

export default AddWorkOrder;
