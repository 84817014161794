// App.js

import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import default styles
import './Scheduling.scss'; // Import custom styles
import { Box } from '@mui/material';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  ControlledAutocomplete,
  CustomTypography,
  Select,
  TasksViewDrawer,
} from 'components';
import { useAppDispatch, useAppSelector, useInput } from 'hooks';
import { findEstimate, findUser, getEstimates, getTaskById, getWorkOrderById } from 'store/thunks';
import { allSchedulesList } from 'store/slices/scheduleSlice/selectors';
import { getAllSchedules } from 'store/slices/scheduleSlice/thunks';
import { TSchedules } from 'store/slices/scheduleSlice/types';
import { currentTaskByIdSelector } from 'store/slices/workOrderSlice/selectors';
import { UserImg } from 'assets/images';
import { returnImgUrl } from 'utils';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import { Colors, FontNames } from 'types';
import { schedulingOptions } from 'constants/schedulingOptions';
import { foundedEstimatesSelector, foundedUsersSelector } from 'store/slices/searchSlice/selectors';

import styles from './Schedule.module.scss';

const localizer = momentLocalizer(moment);

const MyEvent: React.FC<{
  event: TSchedules;
  setIsEdited: React.Dispatch<React.SetStateAction<boolean>>;
  isEdited: boolean;
}> = ({ event, setIsEdited, isEdited }) => {
  const dispatch = useAppDispatch();
  const currentTask = useAppSelector(currentTaskByIdSelector);

  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
  const handleClick = async () => {
    await dispatch(getTaskById(event.id));
    if (currentTask) {
      await dispatch(getWorkOrderById(currentTask.work_order_id));
      setIsShowInfo(true);
    }
  };

  const technicianImage = event.profile_image_url_id
    ? returnImgUrl(ImgUrlFolders.PROFILE_INFO, String(event.profile_image_url_id))
    : UserImg;

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleClick}>
        <img
          src={technicianImage}
          alt='Technician'
          style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }}
        />
        <div>{event.description}</div>
      </div>
      <TasksViewDrawer
        onEdit={() => setIsEdited(!isEdited)}
        open={isShowInfo}
        onClose={() => setIsShowInfo(false)}
        onOpen={function (): void {
          throw new Error('Function not implemented.');
        }}
      />
    </>
  );
};

const App = () => {
  const dispatch = useAppDispatch();

  const allSchedules = useAppSelector(allSchedulesList);
  const foundedEstimates = useAppSelector(foundedEstimatesSelector);
  const foundedEmployees = useAppSelector(foundedUsersSelector);
  const { value, debouncedValue, handleChange } = useInput();

  const [events, setEvents] = useState<TSchedules[]>([]);
  const [estId, setEstId] = useState<number | null>(null);
  const [empId, setEmpId] = useState<number | null>(null);
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [fetchType, setFetchType] = useState<'day' | 'week' | 'month'>('day');
  const [searchBy, setSearchBy] = useState('');

  const { control, handleSubmit, reset, watch } = useForm<any>();
  const [isEdited, setIsEdited] = useState(false);

  const watchResult = watch();
  const { estimate, employee } = watchResult;

  useEffect(() => {
    if (estimate?.id) {
      setEstId(estimate.id);
      setEmpId(null);
    } else if (employee?.id) {
      setEmpId(employee.id);
      setEstId(null);
    }
  }, [estimate?.id, employee?.id]);

  useEffect(() => {
    if (debouncedValue?.trim()?.length) {
      if (searchBy === 'estimate') {
        dispatch(findEstimate({ query: debouncedValue, limit: 100 }));
      } else if (searchBy === 'employee') {
        dispatch(findUser({ query: debouncedValue, limit: 100, designation: 'employee' }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const getAllEstimates = async () => {
    await dispatch(getEstimates({ offset: 0, limit: 10 }));
  };

  const estimatesOptions = foundedEstimates?.data?.map((estimate) => ({
    id: estimate?.id,
    value: estimate?.name,
    label: estimate?.name,
  }));
  const employeeOptions = foundedEmployees?.data?.map((estimate) => ({
    id: estimate?.id,
    value: estimate?.name,
    label: estimate?.name,
  }));

  const fetch = async ({
    type,
    selected_date,
    start_date,
    end_date,
  }: {
    type: 'day' | 'week' | 'month';
    selected_date?: string;
    start_date?: string;
    end_date?: string;
  }) => {
    setFetchType(type);
    if (type === 'day' && selected_date) {
      setSelectedDate(selected_date);
      setStartDate('');
      setEndDate('');
      if (estId && empId) {
        dispatch(
          getAllSchedules({
            selected_date: selected_date,
            technician_id: empId,
            estimate_id: estId,
          }),
        );
      } else if (estId) {
        dispatch(
          getAllSchedules({
            selected_date: selected_date,
            estimate_id: estId,
          }),
        );
      } else if (empId) {
        dispatch(
          getAllSchedules({
            selected_date: selected_date,
            technician_id: empId,
          }),
        );
      } else if (!empId && !estId) {
        dispatch(
          getAllSchedules({
            selected_date: selected_date,
          }),
        );
      }
    } else if (type === 'week' || type === 'month') {
      if (start_date && end_date) {
        setStartDate(start_date);
        setEndDate(end_date);
        setSelectedDate('');
      }
      if (empId && estId) {
        dispatch(
          getAllSchedules({
            start_date: start_date,
            end_date: end_date,
            technician_id: empId,
            estimate_id: estId,
          }),
        );
      } else if (estId) {
        dispatch(
          getAllSchedules({
            start_date: start_date,
            end_date: end_date,
            estimate_id: estId,
          }),
        );
      } else if (empId) {
        dispatch(
          getAllSchedules({
            start_date: start_date,
            end_date: end_date,
            technician_id: empId,
          }),
        );
      } else if (!empId && !estId) {
        dispatch(
          getAllSchedules({
            start_date: start_date,
            end_date: end_date,
          }),
        );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllSchedules({ selected_date: formattedDate }));
    };

    fetchData();
    getAllEstimates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEvents(allSchedules);
  }, [allSchedules]);

  useEffect(() => {
    fetch({
      type: fetchType,
      selected_date: selectedDate,
      start_date: startDate,
      end_date: endDate,
    });
  }, [estId, empId, isEdited]);

  const handleOnChange = async (e: any) => {
    reset();
    setSearchBy(e.target.value);
  };

  const handleClearData: SubmitHandler<FieldValues> = async () => {
    reset();
  };

  const updatedEvents: any[] | undefined = [];

  events?.forEach((event) => {
    const startDate = moment(event.start_date);
    const endDate = moment(event.end_date);
    const currentDate = startDate.clone(); // Use const instead of let

    while (currentDate.isSameOrBefore(endDate)) {
      const startDateTime = moment(currentDate.format('YYYY-MM-DD') + ' ' + event.start_time);
      const endDateTime = moment(currentDate.format('YYYY-MM-DD') + ' ' + event.end_time);

      updatedEvents.push({
        id: event.id,
        technician_id: event.technician_id,
        start: startDateTime.toDate(),
        end: endDateTime.toDate(),
        description: event.description,
        work_order_id: event.work_order_id,
        sequence: event.sequence,
        status: event.status,
        created_by: event.created_by,
        profile_image_url_id: event.profile_image_url_id,
      });

      currentDate.add(1, 'day');
    }
  });

  const handleNavigate = async (date: moment.MomentInput, view: any) => {
    switch (view) {
      case 'day':
        fetch({ type: 'day', selected_date: moment(date).format('YYYY-MM-DD') });
        break;
      case 'week':
        fetch({
          type: 'week',
          start_date: moment(date).startOf('week').format('YYYY-MM-DD'),
          end_date: moment(date).endOf('week').format('YYYY-MM-DD'),
        });
        break;
      case 'month':
        fetch({
          type: 'month',
          start_date: moment(date).startOf('month').format('YYYY-MM-DD'),
          end_date: moment(date).endOf('month').format('YYYY-MM-DD'),
        });
        break;
      default:
        break;
    }
  };
  const handleView = async (view: any) => {
    switch (view) {
      //@for future purpose. dont uncomment this.
      // case 'day':
      //   console.log('Selected Date:', moment().format('YYYY-MM-DD'));
      //   fetch({ type: 'day', selected_date: moment().format('YYYY-MM-DD')});

      //   break;
      case 'week':
        fetch({
          type: 'week',
          start_date: moment().startOf('week').format('YYYY-MM-DD'),
          end_date: moment().endOf('week').format('YYYY-MM-DD'),
        });

        break;
      case 'month':
        fetch({
          type: 'month',
          start_date: moment().startOf('month').format('YYYY-MM-DD'),
          end_date: moment().endOf('month').format('YYYY-MM-DD'),
        });
        break;
      default:
        break;
    }
  };
  return (
    <div className='app'>
      <Box className={styles.container__head}>
        <CustomTypography className={styles.container__head__title}>Scheduling</CustomTypography>
      </Box>
      <form onSubmit={handleSubmit(handleClearData)} className={styles.container}>
        <Box className={styles.container__content}>
          <Select
            showLabel
            label='Search By '
            value={searchBy}
            border='1px solid #EDEFF1'
            maxWidth='15%'
            multiple={false}
            options={schedulingOptions}
            required={true}
            onChange={handleOnChange}
            fontFamily={FontNames.CIRCULAR_REG}
            labelColor={Colors.ENIGMATIC_MIDNIGHT}
            defaultValue='Search By :'
            borderRadius='8px'
            backgroundColor='#FCFCFC'
          />
          <Box className={styles.container__content__item}>
            {searchBy === '' && (
              <ControlledAutocomplete
                name='none'
                label='Text:'
                width='20px'
                control={control}
                borderRadius='5px'
                inputValue={''}
                optionsName='label'
                placeholder='Text:*'
                //errors={!!errors.estimate}
                options={estimatesOptions}
                fontFamily='CircularStdLight'
                borderColor={Colors.SOFT_SILVER}
                handleInputChange={handleChange}
                backgroundColor={Colors.FROST_WHITE}
                disabled={searchBy === ''}
                //helperText={estimatesHelperText && String(estimatesHelperText)}
              />
            )}
            {searchBy === 'estimate' ? (
              <ControlledAutocomplete
                name='estimate'
                label='Estimate:'
                width='20%'
                control={control}
                borderRadius='5px'
                inputValue={value}
                optionsName='label'
                placeholder='Estimate:*'
                //errors={!!errors.estimate}
                options={estimatesOptions}
                fontFamily='CircularStdLight'
                borderColor={Colors.SOFT_SILVER}
                handleInputChange={handleChange}
                backgroundColor={Colors.FROST_WHITE}
                //helperText={estimatesHelperText && String(estimatesHelperText)}
              />
            ) : searchBy === 'employee' ? (
              <ControlledAutocomplete
                name='employee'
                label='Employee:'
                control={control}
                borderRadius='5px'
                inputValue={value}
                optionsName='label'
                placeholder='Employee:*'
                //errors={!!errors.estimate}
                options={employeeOptions}
                fontFamily='CircularStdLight'
                borderColor={Colors.SOFT_SILVER}
                handleInputChange={handleChange}
                backgroundColor={Colors.FROST_WHITE}
                //helperText={estimatesHelperText && String(estimatesHelperText)}
              />
            ) : (
              <></>
            )}
          </Box>
          <Button
            type='button'
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
            fontFamily={FontNames.CIRCULAR_MEDIUM}
            padding='11px 8px'
            borderRadius='5px'
            disabled={searchBy === ''}
            onClick={() => {
              setEstId(null);
              setEmpId(null);
              setSearchBy('');
              reset();
            }}
          >
            Clear
          </Button>
        </Box>
      </form>
      <div className='calendar-container'>
        <Calendar
          localizer={localizer}
          events={updatedEvents}
          defaultView={'day'}
          scrollToTime={moment().subtract(2, 'hours').toDate()}
          views={['day', 'week', 'month']}
          startAccessor='start'
          endAccessor='end'
          timeslots={1}
          onNavigate={handleNavigate}
          onView={handleView}
          style={{ margin: '0px' }}
          components={{
            event: (props) => <MyEvent {...props} setIsEdited={setIsEdited} isEdited={isEdited} />,
          }}
          dayLayoutAlgorithm='no-overlap'
        />
      </div>
    </div>
  );
};

export default App;
