import { FC, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, CustomTypography, DeletePopup } from 'components';
import {
  CircumIcon,
  GridViewIcon,
  NewPlusIcon,
  ActiveCardsIcon,
  DisableTableIcon,
} from 'assets/icons';
import { Colors, FontNames, Routes } from 'types';
import { removePartById } from 'store/thunks';
import { useAppDispatch, useAppSelector } from 'hooks';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { allPartsSelector } from 'store/slices/inventorySlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { PermissionMessages } from 'constants/PermissionMessages';
import { optionsSelector } from 'store/slices/optionsSlice/selectors';

import styles from '../Inventory.module.scss';

import type { TInventoryHeaderProps } from './types';

const InventoryHeader: FC<TInventoryHeaderProps> = ({
  selection,
  isDisabled,
  isViewInGrid,
  createNewDrawer,
  setIsViewInGrid,
  openVendorDrawer,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isShowTemplateButton } = useAppSelector(optionsSelector);

  const {
    isAccessToInventoryViewer,
    isAccessToCatalogsViewer,
    isAccessToInventoryEditor,
    isAccessToCatalogsEditor,
  } = useAppSelector(userPermissionsSelector);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

  const haveAccessToInventories = isAccessToInventoryViewer || isAccessToInventoryEditor;

  const allPartsData = useAppSelector(allPartsSelector);

  const navigateToRequisition = () => navigate(Routes.Requisition);

  const ExcelTemplate = () => navigate(Routes.ExcelTemplate);

  const navigateToPurchaseOrder = () => navigate(Routes.PurchaseOrder);

  const navigateToCatalog = () => navigate(Routes.Catalog);

  const handleClick = (value: boolean) => {
    setIsViewInGrid(value);
    BrowserStorageService.set(BrowserStorageKeys.inventoryView, String(value), { session: true });

    localStorage.setItem('SelectedItems', JSON.stringify(selection));
  };
  const handleVendorListClick = () => navigate(Routes.Vendors);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const containerClasses = classNames(styles.container__head);

  const onDelete = () => {
    const selectedItems = allPartsData?.data
      ?.filter((item) => selection?.includes(item?.id))
      ?.filter((item) => !item?.in_catalog && !item?.in_purchase_order && !item?.in_requisition)
      ?.map((item) => item?.id);

    const selectedItemsInCatalog = allPartsData?.data
      ?.filter((item) => selection?.includes(item?.id))
      ?.filter((item) => item?.in_catalog || item?.in_purchase_order || item?.in_requisition)
      ?.map((item) => item?.id);

    if (selectedItems?.length) {
      dispatch(removePartById(selectedItems as number[]));
    }

    if (selectedItemsInCatalog?.length) {
      const textDependedOnLength =
        selectedItemsInCatalog?.length > 1
          ? `${selectedItemsInCatalog?.length} items can't be removed because they exist in catalogs, purchase orders, or requisitions.`
          : `The item cannot be removed because it exists in catalogs, purchase orders, or requisitions.`;

      toast.info(textDependedOnLength);
    }

    togglePopup();
  };

  return (
    <Box className={containerClasses}>
      <Box className={styles.container__head__top}>
        <Box className={styles.container__header}>
          <CustomTypography className={styles.container__header__title}>Inventory</CustomTypography>
          {haveAccessToInventories && (
            <Box className={styles.container__header__buttons}>
              <Box className={styles.container__header__buttons__views}>
                {isShowTemplateButton && (
                  <Button
                    color='#2067DD'
                    borderRadius='5px'
                    variant='contained'
                    padding='11px 8px'
                    disableColor='white'
                    backgroundColor='#D2E1F9'
                    fontFamily={FontNames.CIRCULAR_REG}
                    onClick={ExcelTemplate}
                  >
                    Pricing Template
                  </Button>
                )}
                <Button
                  color={Colors.SAPPHIRE}
                  borderRadius='5px'
                  variant='contained'
                  padding='11px 8px'
                  disableColor={Colors.WHITE}
                  backgroundColor={Colors.PALE_BLUE}
                  fontFamily={FontNames.CIRCULAR_REG}
                  onClick={navigateToRequisition}
                >
                  Requisitions
                </Button>
                {!isShowTemplateButton && (
                  <Button
                    color={Colors.SAPPHIRE}
                    borderRadius='5px'
                    padding='11px 8px'
                    variant='contained'
                    disableColor={Colors.WHITE}
                    backgroundColor={Colors.PALE_BLUE}
                    onClick={navigateToCatalog}
                    fontFamily={FontNames.CIRCULAR_REG}
                    disabled={!isAccessToCatalogsViewer && !isAccessToCatalogsEditor}
                  >
                    Catalogs
                  </Button>
                )}
                <Button
                  color={Colors.SAPPHIRE}
                  borderRadius='5px'
                  padding='11px 8px'
                  variant='contained'
                  disableColor={Colors.WHITE}
                  backgroundColor={Colors.PALE_BLUE}
                  fontFamily={FontNames.CIRCULAR_REG}
                  onClick={navigateToPurchaseOrder}
                >
                  Purchase Orders
                </Button>
                <Button
                  color={Colors.SAPPHIRE}
                  borderRadius='5px'
                  padding='11px 8px'
                  variant='contained'
                  disableColor={Colors.WHITE}
                  backgroundColor={Colors.PALE_BLUE}
                  fontFamily={FontNames.CIRCULAR_REG}
                  onClick={handleVendorListClick}
                >
                  Vendors
                </Button>
              </Box>

              <Box className={styles.container__header__view}>
                {!isViewInGrid ? (
                  <DisableTableIcon onClick={() => handleClick(true)} />
                ) : (
                  <GridViewIcon />
                )}
                {!isViewInGrid ? (
                  <ActiveCardsIcon />
                ) : (
                  <CircumIcon onClick={() => handleClick(false)} />
                )}
              </Box>
            </Box>
          )}
        </Box>
        <Box className={styles.container__header}>
          <Box className={styles.container__header__create}>
            {isAccessToInventoryEditor && (
              <Button
                color={Colors.WHITE}
                minWidth='76px'
                borderRadius='5px'
                padding='10px 16px'
                disableColor={Colors.WHITE}
                onClick={togglePopup}
                backgroundColor={Colors.SAPPHIRE}
                fontFamily={FontNames.CIRCULAR_REG}
                className={styles.container__header__create__delete}
                disabled={isDisabled || !isAccessToInventoryEditor || !selection?.length}
              >
                Delete
              </Button>
            )}
            {isAccessToInventoryEditor && (
              <Button
                color={Colors.SAPPHIRE}
                borderRadius='5px'
                padding='10px 16px'
                disableColor={Colors.WHITE}
                backgroundColor={Colors.PALE_BLUE}
                onClick={openVendorDrawer}
                fontFamily={FontNames.CIRCULAR_REG}
                disabled={isDisabled || !isAccessToInventoryEditor || !selection?.length}
              >
                Create PO
              </Button>
            )}

            <Button
              color='white'
              padding='9px 16px'
              onClick={createNewDrawer}
              tooltipMessage={
                !isAccessToInventoryEditor ? PermissionMessages.InventoryCreationMessage : undefined
              }
              backgroundColor={Colors.SAPPHIRE}
              fontFamily={FontNames.CIRCULAR_REG}
              disabled={!isAccessToInventoryEditor}
              startIcon={<NewPlusIcon width={20} height={20} />}
              className={styles.container__header__create__button}
            >
              Add Inventory
            </Button>
          </Box>
        </Box>
      </Box>
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        title='Delete Confirmation'
        onDelete={onDelete}
        body='Please confirm if you want to delete the inventory item.'
      />
    </Box>
  );
};

export default InventoryHeader;
