import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { useAppSelector } from 'hooks';
import { NewPlusIcon } from 'assets/icons';
import { Button, CustomTypography } from 'components';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { Colors } from 'types';

import styles from './CustomerHeader.module.scss';

import type { TCustomerHeader } from './types';

const CustomerHeader: FC<TCustomerHeader> = ({ createNewDrawer }) => {
  const { isAccessToCustomerEditor } = useAppSelector(userPermissionsSelector);

  const containerClasses = classNames(styles.container, styles.container__head);

  return (
    <Box className={containerClasses}>
      <Box className={styles.container__header}>
        <CustomTypography className={styles.container__header__title}>Customers</CustomTypography>
      </Box>

      <Box className={styles.container__header}>
        <Box className={styles.container__header__create}>
          <Button
            disabled={!isAccessToCustomerEditor}
            startIconLeftPosition='0'
            startIcon={
              <NewPlusIcon
                width={24}
                height={24}
                className={styles.container__header__create__button_icon}
              />
            }
            color='white'
            borderRadius='5px'
            isUppercase={false}
            backgroundColor={Colors.SAPPHIRE}
            onClick={createNewDrawer}
            className={styles.container__header__create__button}
          >
            Add Customer
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerHeader;
