import { Colors, FontNames } from 'types';
import { TOption } from 'components/shared/Select/types';

import { TInputOption } from './types';

export const VENDOR_ERROR_MESSAGE = 'Vendor must be selected from the list';

export const selectOptions = [
  { id: 1, label: 'Item', value: 'item' },
  { id: 2, label: 'Labour', value: 'labour' },
];

export const inputOptions: TInputOption<TOption>[] = [
  {
    name: 'name',
    type: 'text',
    required: true,
    borderRadius: '5px',
    label: 'Item name:',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    placeholder: 'Item name:*',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
    rulesMessage: 'Item name is required',
  },
  {
    name: 'qty',
    type: 'text',
    required: true,
    borderRadius: '5px',
    label: 'Quantity:',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    placeholder: 'Quantity:*',
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
    rulesMessage: 'Quantity is required',
  },
  {
    type: 'select',
    required: true,
    name: 'item_type',
    borderRadius: '5px',
    padding: '16.5px 16px',
    label: 'Item Type:*',
    borderColor: Colors.SOFT_SILVER,
    options: selectOptions,
    backgroundColor: Colors.LIGHT_SILVER,
    border: '1px solid #EDEFF1',
    placeholder: 'Select Item Type',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    name: 'cost',
    label: 'Cost:',
    type: 'text',
    required: true,
    placeholder: 'Cost:*',
    rulesMessage: 'Cost is required',
    borderRadius: '5px',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    name: 'sku',
    label: 'UPC/SKU:',
    type: 'text',
    required: true,
    placeholder: 'UPC/SKU:*',
    rulesMessage: 'UPC/SKU is required',
    borderRadius: '5px',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    name: 'preferred_vendor',
    label: 'Vendor:',
    type: 'text',
    required: false,
    placeholder: 'Vendor:',
    borderRadius: '5px',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    name: 'manufacturer',
    label: 'Manufacturer:',
    type: 'text',
    required: true,
    placeholder: 'Manufacturer:*',
    rulesMessage: 'Manufacturer is required',
    borderRadius: '5px',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    name: 'brand',
    label: 'Brand:',
    type: 'text',
    required: false,
    placeholder: 'Brand:',
    borderRadius: '5px',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'text',
    required: false,
    borderRadius: '5px',
    name: 'item_location',
    padding: '17px 16px',
    label: 'Item Location:',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    placeholder: 'Item Location:',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    name: 'description',
    label: 'Description',
    multiline: true,

    rows: 4,
    type: 'textarea',
    required: true,
    placeholder: 'Description',
    borderRadius: '5px',
    padding: '17px 16px',
    borderColor: Colors.SOFT_SILVER,
    backgroundColor: Colors.LIGHT_SILVER,
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
];
