import { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import breakPoints from 'constants/BreakPoints';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { Button, DrawerLayout, CustomTypography, TaskEditDrawer } from 'components';
import { Colors } from 'types';
import {
  currentTaskByIdSelector,
  currentWorkOrderByIdSelector,
} from 'store/slices/workOrderSlice/selectors';
import { TWorkOrder, TWorkOrderTask } from 'store/slices/workOrderSlice/types';
import { getEstimateById, getOrganizationMembers } from 'store/thunks';
import { estimateItemSelector } from 'store/slices/estimatesSlice/selectors';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { TOption } from 'components/shared/Select/types';

import TasksViewInfo from './TasksViewInfo';
import styles from './CustomersViewDrawer.module.scss';

import type { TTasksViewDrawerProps } from './types';

const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

const TasksViewDrawer: FC<TTasksViewDrawerProps> = ({ onEdit, onClose, open }) => {
  const currentTask = useAppSelector(currentTaskByIdSelector);
  const currentWorkOrder = useAppSelector(currentWorkOrderByIdSelector);
  const currentEstimate = useAppSelector(estimateItemSelector);
  const [task, setTask] = useState<TWorkOrderTask | null>(currentTask);
  const [workOrder, setWorkOrder] = useState<TWorkOrder | null>(currentWorkOrder);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTask(currentTask);
    setWorkOrder(currentWorkOrder);
  }, [currentTask, currentWorkOrder]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentWorkOrder && !currentEstimate) {
        await dispatch(getEstimateById(currentWorkOrder?.estimate_id));
      }
    };

    fetchData();
  }, [currentWorkOrder]);

  const [inEditMode, setInEditMode] = useState<boolean>(false);

  const { isAccessToCustomerEditor } = useAppSelector(userPermissionsSelector);

  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 600
      : Number(width) <= breakPoints?.TABLET_M
      ? 370
      : 895;

  const handleCloseEdit = () => {
    setInEditMode(false);
  };

  const [employeeOptions, setEmployeeOptions] = useState<TOption[]>([]);

  const getMembers = async () => {
    const response = await dispatch(
      getOrganizationMembers({ org_id: Number(currentOrganization), limit: 100 }),
    );

    if (response.payload) {
      const { members } = response.payload;
      const employeeOptions = members
        .filter(
          (member: any) => member.designation === 'employee' || member.designation === 'owner',
        )
        .map((employee: any) => ({
          id: employee.user_id,
          value: employee.name,
          label: employee.name,
        }));

      setEmployeeOptions(employeeOptions);
    }
  };

  const handleOpenEdit = () => {
    getMembers().then(() => {
      setInEditMode(true);
      onClose();
    });
  };

  return (
    <>
      <DrawerLayout
        open={open}
        padding='40px'
        inCenter={false}
        onClose={onClose}
        width={drawerWidth}
        titleClassName={styles.container__head__title}
        headerTitle='Task Information'
      >
        {task && workOrder && (
          <Box className={styles.container}>
            <Box className={styles.container__content}>
              <Box className={styles.container__content__first}>
                <Box className={styles.container__content__first__item}>
                  <TasksViewInfo
                    title='Technician Name'
                    subtitle={task?.employee_name.toString()}
                  />
                  <TasksViewInfo title='Task Description' subtitle={task?.description.toString()} />
                </Box>
                <Box className={styles.container__content__first__item}>
                  <TasksViewInfo title='Sequence' subtitle={'1'} />
                  <TasksViewInfo title='Start Date' subtitle={task?.start_date.toString()} />
                </Box>
                <Box className={styles.container__content__first__item}>
                  <TasksViewInfo title='End Date' subtitle={task?.end_date.toString()} />
                  <TasksViewInfo title='Daily Hours' subtitle={task?.daily_hours.toString()} />
                </Box>
              </Box>
              <Box className={styles.container__content__first}>
                <CustomTypography className={styles.container__head__subtitle}>
                  Work Order Information
                </CustomTypography>
              </Box>
              <Box className={styles.container__content__first__item}>
                <TasksViewInfo
                  title='Estimate Name'
                  isClickable //onclick go to estimate page
                  link={`/work-flow/bom-estimate/estimate/${currentEstimate?.uuid}/generate`}
                  subtitle={workOrder?.estimate_name}
                />
                <TasksViewInfo title='Customer Name' subtitle={workOrder?.customer_name} />
              </Box>
              <Box className={styles.container__content__first__item}>
                <TasksViewInfo title='Customer Phone' subtitle={workOrder?.customer_phone} />
                <TasksViewInfo title='Customer Email' subtitle={workOrder?.customer_email} />
              </Box>
              <Box className={styles.container__content__first__item}>
                <TasksViewInfo title='Customer Address' subtitle={workOrder?.customer_address} />
                <TasksViewInfo title='Start Date' subtitle={workOrder?.start_date.toString()} />
              </Box>
              <Box className={styles.container__content__first__item}>
                <TasksViewInfo title='Supervisor Name' subtitle={workOrder?.supervisor_name} />
                <TasksViewInfo title='End Date' subtitle={workOrder?.end_date.toString()} />
              </Box>
            </Box>
            <Box className={styles.container__footer}>
              <Button
                type='submit'
                color='white'
                maxWidth='65px'
                padding='12px 16px'
                onClick={handleOpenEdit}
                backgroundColor={Colors.SAPPHIRE}
                disabled={!isAccessToCustomerEditor}
              >
                Edit
              </Button>
            </Box>
          </Box>
        )}
      </DrawerLayout>
      <TaskEditDrawer
        onEdit={() => onEdit()}
        open={inEditMode}
        options={employeeOptions}
        handleClose={handleCloseEdit}
      />
    </>
  );
};

export default TasksViewDrawer;
