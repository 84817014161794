import { v4 } from 'uuid';

import { TCatalog, TVersionByVersionId, TVersionSection } from 'store/slices/catalogSlice/types';
import { TCouponItem } from 'store/slices/discountSlice/types';
import { getValue } from 'utils/formsInitialValues/getValue';
import { TDataReturn } from 'types';
import { StatusEnums } from 'components/shared/EmployeeStatus/types';

import { TCatalogFormData } from './types';

type TCatalogDefaultValuesCreatorParams = {
  currentCatalogById: TCatalog;
  couponForCatalog: TDataReturn<TCouponItem>;
  currentSectionVersions: TVersionByVersionId;
} & Partial<{
  inEditMode: boolean;
}>;

export const defaultValuesCreator = ({
  couponForCatalog,
  inEditMode = false,
  currentCatalogById,
  currentSectionVersions,
}: TCatalogDefaultValuesCreatorParams): TCatalogFormData => ({
  name: getValue(inEditMode, currentCatalogById?.name),
  category: getValue(inEditMode, currentCatalogById?.category),
  description: getValue(inEditMode, currentCatalogById?.description),
  templates: inEditMode
    ? currentSectionVersions?.templates
    : [
        {
          type: 'agreements',
          media_url_id: '',
          description: null,
          id: null,
        },
        {
          type: 'estimate',
          media_url_id: '',
          description: null,
          id: null,
        },
      ],
  discounts: inEditMode
    ? couponForCatalog?.data?.map((item) => ({
        coupon: {
          id: item?.id,
          uniqueId: item?.id,
          end_date: item?.end_date as Date,
          start_date: item?.start_date as Date,
          coupon_code: item?.coupon_code as string,
          coupon_status: item?.coupon_status as string,
          discount_percent: item?.discount_percent,
        },
      }))
    : [
        {
          coupon: {
            id: v4(),
            coupon_code: '',
            discount_percent: 0,
            start_date: new Date(),
            end_date: new Date(),
            coupon_status: StatusEnums.INACTIVE,
          },
        },
      ],
  payment_schedule: inEditMode
    ? {
        estimated_duration: currentSectionVersions?.payment_schedule?.estimated_duration,

        schedule: {
          stages: currentSectionVersions?.payment_schedule?.schedule?.stages?.map((item) => ({
            ...item,
            id: v4(),
          })),
          type: 'stages',
        },

        description: currentSectionVersions?.payment_schedule?.description,
      }
    : {
        estimated_duration: 1,

        schedule: {
          type: 'stages',
          stages: [
            {
              id: v4(),
              name: 'Initial Payment',
              percentage_complete: 0,
              percentage_amount: 33,
            },
            {
              id: v4(),

              name: 'Midway Payment',
              percentage_complete: 50,
              percentage_amount: 33,
            },
            {
              id: v4(),

              name: 'Final Payment',
              percentage_complete: 100,
              percentage_amount: 34,
            },
          ],
        },
        description: 'string',
      },
  sections: inEditMode
    ? currentSectionVersions?.sections
    : [
        {
          name: 'Section 1',
          description: '',
          items: [
            {
              qty_required: 0,
              part_id: 0,
              part_name: '',
              price: 0,
              description: '',
              preferred_vendor_name: '',
            },
          ],
        },
      ],

  generalInformation: inEditMode
    ? currentCatalogById?.general_info?.map((item) => ({
        ...item,
        uniqueId: item?.id,
      }))
    : [
        {
          type: '',
          name: '',
          value: '',
        },
      ],
});

export const cacheTest = (asyncValidate: (val: string) => any) => {
  let _valid = false;
  let _value = '';

  return async (value: string) => {
    if (value !== _value) {
      const response = await asyncValidate(value);
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};

export const checkSectionFieldsChange = (arr: TVersionSection[]) =>
  arr?.some((obj) => checkSectionFieldChange(obj));

const checkSectionFieldChange = (obj: TVersionSection) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj?.hasOwnProperty(key)) {
      const value = obj[key as keyof TVersionSection];

      if (typeof value === 'object' && !Array.isArray(value)) {
        if (checkSectionFieldChange(value)) {
          return true;
        }
      }
    }
  }

  return false;
};
