import { useState, type FC, BaseSyntheticEvent, useEffect, SyntheticEvent } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import classNames from 'classnames';

import {
  Button,
  DeletePopup,
  ScrollLayout,
  PortalDropDown,
  CustomDataGrid,
  CustomTextField,
  CustomTypography,
  CustomBreadcrumbs,
  ControlledAutocomplete,
  TableCell,
  ImgWithDefault,
} from 'components';
import { ImageBlank } from 'assets/images';
import { Colors, ImgUrlFolders, Routes } from 'types';
import PreloadedImg from 'components/views/PreloadedImg';
import { useAppDispatch, useAppSelector, useInput } from 'hooks';
import { createRequisitions, findInventories, getAllParts } from 'store/thunks';
import { MoreIcon, PlusFilledIcon, TrashIcon } from 'assets/icons';
import { foundedInventoriesSelector } from 'store/slices/searchSlice/selectors';
import { TSendedParts } from 'containers/PurchaseOrder/CreatePurchaserOrderDrawer/types';
import { EmptyTitles } from 'constants/EmptyTitles';
import { allPartsSelector } from 'store/slices/inventorySlice/selectors';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import { breadCrumbOptions, columns } from './utils';
import styles from './CreateRequisition.module.scss';

const CreateRequisition: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { isAccessToInventoryEditor } = useAppSelector(userPermissionsSelector);
  const userAllRolesLoading = useAppSelector(userAllRolesLoadingSelector);

  const {
    value: nameValue,
    debouncedValue: nameDebouncedValue,
    handleChange: nameHandleChange,
  } = useInput();

  useEffect(() => {
    dispatch(getAllParts());

    if (nameDebouncedValue?.length > 0) {
      dispatch(findInventories({ query: nameDebouncedValue, limit: 100 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameDebouncedValue]);

  const inventories = useAppSelector(allPartsSelector);
  const foundedParts = useAppSelector(foundedInventoriesSelector);

  const renderColumns: GridColDef[] = columns.map((column) => ({
    ...column,
    hideSortIcons: true,
    disableColumnMenu: true,
    disableColumnSelector: true,
  }));

  const { control, watch } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const [descValue, setDescValue] = useState<string | null>('');
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

  const addClasses = classNames(styles.container__add, {
    [styles.container__add_disable]: inventories?.total_count === 0,
  });

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const dropdownItems = [
    {
      id: 1,
      icon: <TrashIcon />,
      name: 'Delete',
      action: togglePopup,
    },
  ];

  const options = foundedParts?.data?.map((item) => ({
    id: item?.id,
    itemName: item?.name,
    price: item?.cost || 0,
    quantity: item?.qty || 0,
    brand: item?.brand || '',
    description: item?.description,
    image: item?.image_url_id || '',
    vendor: item.preferred_vendor_id,
    manufacturer: item?.manufacturer || '',
  }));

  const changeDescValue = (event: BaseSyntheticEvent) => setDescValue(event.target.value);

  const isNameValueValid = options?.some((option) =>
    option.itemName.toLowerCase().includes(nameValue.toLowerCase()),
  );

  const watchResult = watch();

  const renderRows = fields.map((row, idx) => {
    return {
      id: row.id,
      image: watchResult?.items[idx]?.itemName ? (
        watchResult?.items[idx].image ? (
          <PreloadedImg
            loading={false}
            folderName={ImgUrlFolders.INVENTORY}
            imgId={String(watchResult?.items[idx].image)}
          />
        ) : (
          <ImgWithDefault
            src={ImageBlank}
            fallback={ImageBlank}
            style={{ paddingLeft: 0, maxWidth: '100%', height: '100%' }}
          />
        )
      ) : null,
      name: (
        <ControlledAutocomplete
          padding='3px'
          fromRequisition
          options={options}
          control={control}
          borderRadius='8px'
          borderColor={Colors.SOFT_SILVER}
          inputValue={nameValue}
          optionsName='itemName'
          name={`items[${idx}]`}
          placeholder='Item Name'
          backgroundColor={Colors.LIGHT_SILVER}
          fontFamily='CircularStdRegular'
          handleInputChange={nameHandleChange}
          errors={!isNameValueValid && nameValue}
          helperText={'The name should be selected from option list'}
        />
      ),
      brand: isNameValueValid && watchResult?.items[idx].brand && (
        <TableCell title={String(watchResult?.items[idx].brand)} />
      ),
      cost: isNameValueValid && watchResult?.items[idx].price && (
        <TableCell title={String(watchResult?.items[idx].price)} />
      ),
      manufacturer: isNameValueValid && watchResult?.items[idx].manufacturer && (
        <TableCell title={String(watchResult?.items[idx].manufacturer)} />
      ),
      quantity: (
        <Box className={styles.container__quantity}>
          <CustomTypography>Quantity</CustomTypography>
          <Controller
            control={control}
            name={`items[${idx}].quantity`}
            render={({ field: { onChange } }) => (
              <CustomTextField
                type='number'
                padding='5px'
                withHelperText
                fromRequisition
                borderRadius='5px'
                borderColor={Colors.SOFT_SILVER}
                placeholder='Quantity'
                backGroundColor={Colors.FROST_WHITE}
                onChange={(event) => {
                  const inputValue = event.target.value;

                  if (inputValue?.startsWith('0') && inputValue?.length > 1) {
                    event.preventDefault();
                  } else {
                    onChange(event);
                  }
                }}
                value={
                  isNameValueValid && watchResult?.items[idx].quantity
                    ? String(watchResult?.items[idx].quantity)
                    : ''
                }
                error={Number(watchResult?.items[idx].quantity) > 1000000}
                helperText="Quantity value can't be bigger than 1.000.000"
              />
            )}
          />
        </Box>
      ),
      options: (
        <PortalDropDown
          title={
            <IconButton className={styles.container__icon}>
              <MoreIcon />
            </IconButton>
          }
          selectedId={idx}
          options={dropdownItems}
          getSelectedId={setSelectedItemId}
        />
      ),
    };
  });

  const isNameFieldEmpty =
    watchResult?.items?.some((item: TSendedParts) => !item.itemName) ||
    watchResult?.items?.some((item: TSendedParts) => Number(item.quantity) > 10000000);

  const handleAppend = () => {
    if (inventories.total_count > 0) {
      append({
        cost: '',
        item: '',
        image: '',
        brand: '',
        quantity: '',
        manufacturer: '',
      });
    }
  };

  const deleteItem = () => {
    remove(selectedItemId as number);

    togglePopup();
  };

  const handleClick = (event: SyntheticEvent, data: any, buttonType: string) => {
    event.preventDefault();

    const items = data?.items?.map((item: TSendedParts) => {
      return {
        part_id: item?.id,
        quantity: Number(item?.quantity) || 0,
      };
    });

    if (buttonType === 'saveAsDraft') {
      const formattedData = {
        description: descValue as string,
        items,
        status: 'draft',
      };
      dispatch(createRequisitions([formattedData]));
      navigate(Routes.Requisition);
    } else if (buttonType === 'submitForApproval') {
      const formattedData = {
        description: descValue as string,
        items,
        status: 'pending',
      };
      dispatch(createRequisitions([formattedData]));
      navigate(Routes.Requisition);
    }
  };

  if (!isAccessToInventoryEditor && !userAllRolesLoading) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <form>
        <Box className={styles.container__header}>
          <CustomTypography className={styles.container__title}>New Requisition</CustomTypography>
          <Box className={styles.container__actions}>
            <Button
              type='button'
              color={Colors.SAPPHIRE}
              borderRadius='5px'
              variant='contained'
              padding='11px 16px'
              disableColor={Colors.WHITE}
              backgroundColor={Colors.PALE_BLUE}
              className={styles.container__actions__item}
              disabled={!fields.length || isNameFieldEmpty}
              onClick={(event) => handleClick(event, watchResult, 'saveAsDraft')}
            >
              Save As Draft
            </Button>
            <Button
              color={Colors.WHITE}
              type='button'
              borderRadius='5px'
              variant='contained'
              padding='11px 15px'
              backgroundColor={Colors.SAPPHIRE}
              className={styles.container__actions__item}
              disabled={!fields.length || isNameFieldEmpty}
              onClick={(event) => handleClick(event, watchResult, 'submitForApproval')}
            >
              Submit For Approval
            </Button>
          </Box>
        </Box>
        <ScrollLayout>
          <CustomDataGrid
            columns={renderColumns}
            rows={renderRows}
            rowHeight={64}
            fromRequisition={true}
            emptyTitle={EmptyTitles.Purchase_Requisitions}
          />
        </ScrollLayout>
        <Box className={addClasses} onClick={handleAppend}>
          <PlusFilledIcon width={24} height={24} />
          <CustomTypography className={styles.container__add__text}>
            Add An Item For Requisition
          </CustomTypography>
        </Box>
      </form>
      <CustomTextField
        maxWidth={459}
        borderRadius='5px'
        padding='15px 16px'
        borderColor={Colors.SOFT_SILVER}
        backGroundColor={Colors.FROST_WHITE}
        onChange={changeDescValue}
        value={descValue as string}
        label='Requisition Notes:(Optional)'
        placeholder='Requisition Notes:(Optional)'
      />
      <DeletePopup
        withBody
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onDelete={deleteItem}
        title='Delete Confirmation'
        body='This will delete the item. Please confirm.'
      />
    </Box>
  );
};

export default CreateRequisition;
