import { ChangeEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { GridColDef } from '@mui/x-data-grid';

import { Colors, FontNames } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getAllTasksById, getCustomerById, getWorkOrderById } from 'store/thunks';
import {
  CustomBreadcrumbs,
  CustomTypography,
  ControlledInput,
  Button,
  ScrollLayout,
  CustomDataGrid,
  DynamicPaginationControl,
  ControlledFormPicker,
} from 'components';
import { EmptyTitles } from 'constants/EmptyTitles';
import {
  allTasksAsc,
  allTasksLimitSelector,
  allTasksList,
  allTasksOffsetSelector,
  currentWorkOrderByIdSelector,
} from 'store/slices/workOrderSlice/selectors';
import { setTasksPage, setTasksLimit } from 'store/slices/workOrderSlice';
import { TaskSortBy } from 'store/slices/workOrderSlice/types';
import { currentCustomerSelector } from 'store/slices/customersSlices/selectors';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';

import { breadCrumbOptions, columns } from './utils';
import styles from './AddWorkOrderDrawer.module.scss';

const ViewWorkOrderForm = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentWorkOrder = useAppSelector(currentWorkOrderByIdSelector);
  const currentCustomer = useAppSelector(currentCustomerSelector);
  const { isAccessToEditWorkOrder } = useAppSelector(userPermissionsSelector);

  const allTaskList = useAppSelector(allTasksList);
  const allTaskAsc = useAppSelector(allTasksAsc);
  const allTaskLimit = useAppSelector(allTasksLimitSelector);
  const allTaskOffset = useAppSelector(allTasksOffsetSelector);

  const [page, setPage] = useState<number>(1);
  const [newSortBy, setNewSortBy] = useState<TaskSortBy | null>(null);
  const [asc, setAsc] = useState<boolean>(true);

  const fetchData = async () => {
    if (id) {
      await Promise.all([
        dispatch(getWorkOrderById(Number(id))),
        dispatch(
          getAllTasksById({
            id: Number(id),
            options: {
              limit: allTaskLimit,
              offset: allTaskOffset,
            },
          }),
        ),
      ]);
      dispatch(setTasksLimit(5));
      dispatch(setTasksPage(1));
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (currentWorkOrder) {
      dispatch(getCustomerById(currentWorkOrder.customer_id));
    }
  }, [dispatch, currentWorkOrder]);

  const handleColumnHeaderClick = (column: GridColDef) => {
    const sortValue = column.field === 'number' ? TaskSortBy.ID : (column.field as TaskSortBy);
    if (newSortBy === (column.field as TaskSortBy)) {
      setAsc(!asc);
      dispatch(
        getAllTasksById({
          id: Number(id),
          options: {
            sort_by: sortValue || 'number',
            asc: !asc,
            limit: allTaskLimit,
            offset: allTaskOffset,
          },
        }),
      );
    } else {
      setAsc(true);
      setNewSortBy(column.field === 'id' ? TaskSortBy.ID : (column.field as TaskSortBy));

      dispatch(
        getAllTasksById({
          id: Number(id),
          options: {
            sort_by: sortValue || 'number',
            asc: allTaskAsc,
            limit: allTaskLimit,
            offset: allTaskOffset,
          },
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(
      getAllTasksById({
        id: Number(id),
        options: {
          limit: allTaskLimit,
          offset: allTaskOffset,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTaskLimit, allTaskOffset]);

  const navigate = useNavigate();
  const navigateToEditWorkOrder = () => {
    navigate(`/work-flow/work-order/edit/${id}`);
  };

  const { control } = useForm<any>();

  const renderRows = allTaskList?.data?.map((row) => {
    return {
      id: row.id,
      sequence: row.sequence,
      employee_name: row.employee_name,
      start_date: row?.start_date,
      end_date: row?.end_date,
      start_time: row?.start_time,
      daily_hours: row?.daily_hours,
      status: row?.status,
    };
  });

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted =
      column.field === 'number' ? newSortBy === TaskSortBy.ID : newSortBy === column.field;

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted && column?.field ? headerClasses : '',
    };
  });

  const handlePageChange = (_event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(setTasksPage(newPage + 1));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setTasksLimit(event.target.value));
  };

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={breadCrumbOptions} />
      <Box className={styles.container__head}>
        <CustomTypography className={styles.container__head__title}>
          Work Order WO-{currentWorkOrder?.id}
        </CustomTypography>
        {isAccessToEditWorkOrder && (
          <Button
            color='white'
            fontSize='14px'
            maxWidth='167px'
            borderRadius='5px'
            padding='10px 6px'
            isUppercase={false}
            onClick={navigateToEditWorkOrder}
            backgroundColor={Colors.SAPPHIRE}
            fontFamily={FontNames.CIRCULAR_REG}
            className={styles.container__header__create__button}
          >
            Edit Work Order
          </Button>
        )}
      </Box>
      <Box>
        <CustomTypography className={styles.container__head__body}>
          {currentCustomer?.address}, {currentCustomer?.city}
        </CustomTypography>
        <CustomTypography className={styles.container__head__body}>
          {currentCustomer?.state} {currentCustomer?.zip}
        </CustomTypography>
      </Box>
      <Box className={styles.estimateBox}>
        <CustomTypography className={styles.estimateText}>
          Estimate: {currentWorkOrder?.estimate_name}
        </CustomTypography>
      </Box>
      <Box className={styles.customerInfoContainer}>
        <Box className={styles.customerInfoBox}>
          <CustomTypography className={styles.customerText}>
            Customer Name: {currentWorkOrder?.customer_name}
          </CustomTypography>
        </Box>
        <Box className={styles.customerInfoBox}>
          <CustomTypography className={styles.customerText}>
            Customer Phone: {currentWorkOrder?.customer_phone}
          </CustomTypography>
        </Box>
      </Box>
      <Box className={styles.customerInfoContainer}>
        <Box className={styles.customerInfoBox}>
          <CustomTypography className={styles.customerText}>
            Customer Email: {currentWorkOrder?.customer_email}
          </CustomTypography>
        </Box>
        <Box className={styles.customerInfoBox}>
          <CustomTypography className={styles.customerText}>
            Customer Address: {currentCustomer?.address}, {currentCustomer?.city},{' '}
            {currentCustomer?.state} {currentCustomer?.zip}
          </CustomTypography>
        </Box>
      </Box>
      <Box className={styles.card__items}>
        <ControlledFormPicker
          disablePast={false}
          defaultValue={dayjs(currentWorkOrder?.start_date).toDate()}
          padding='0 8px'
          label='WO Start Date'
          name='wo_start_date'
          control={control}
          disabled
          value={dayjs(currentWorkOrder?.start_date).toDate()}
          // error={specificError}
          backgroundColor={Colors.WHITE}
          className={styles.container__picker}
          // helperText={specificErrorMessage || specificError?.message}
        />
        <ControlledFormPicker
          disablePast={false}
          defaultValue={dayjs(currentWorkOrder?.end_date).toDate()}
          padding='0 8px'
          label='WO End Date'
          name='wo_end_date'
          control={control}
          disabled
          value={dayjs(currentWorkOrder?.end_date).toDate()}
          // error={specificError}
          backgroundColor={Colors.WHITE}
          className={styles.container__picker}
          // helperText={specificErrorMessage || specificError?.message}
        />
      </Box>
      <Box className={styles.container__content}>
        <div className={styles.container__content__item}>
          <ControlledInput
            name={'Notes'}
            label={'Notes'}
            defaultValue={currentWorkOrder?.notes}
            control={control}
            value={currentWorkOrder?.notes}
            // maxWidth='1000px'
            disabled={true}
            rows={3}
            required={false}
            borderColor={Colors.SOFT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            placeholder={'Notes'}
            borderRadius='5px'
            labelColor={Colors.BLACK}
            backgroundColor={Colors.WHITE}
            // error={errors[name as keyof TWorkOrderFormValues]}
            // helperText={errors[name as keyof TWorkOrderFormValues]?.message}
          />
        </div>
      </Box>
      <Box className={styles.container__content}>
        <div className={styles.container__content__subitem}>
          <ControlledInput
            name={'Supervisor Name'}
            label={'Supervisor'}
            defaultValue={currentWorkOrder?.supervisor_name}
            value={currentWorkOrder?.supervisor_name}
            control={control}
            // maxWidth='1000px'
            disabled={true}
            rows={3}
            required={false}
            borderColor={Colors.SOFT_SILVER}
            fontFamily={FontNames.CIRCULAR_REG}
            placeholder={'Supervisor'}
            borderRadius='5px'
            labelColor={Colors.BLACK}
            backgroundColor={Colors.WHITE}
            // error={errors[name as keyof TWorkOrderFormValues]}
            // helperText={errors[name as keyof TWorkOrderFormValues]?.message}
          />
        </div>
      </Box>
      <Box>
        <ScrollLayout>
          <CustomDataGrid
            rowHeight={64}
            headerHeight={44}
            rows={renderRows}
            columns={renderColumns}
            emptyTitle={EmptyTitles.WorkOrder_Task}
            onColumnHeaderClick={handleColumnHeaderClick}
          />
        </ScrollLayout>
        {!!allTaskList?.data?.length && (
          <DynamicPaginationControl
            count={allTaskList?.total_count}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={allTaskLimit}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        )}
      </Box>
    </Box>
  );
};

export default ViewWorkOrderForm;
