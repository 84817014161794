import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { catalogApi } from 'api';
import dataStructure from 'constants/options';
import { CatalogShareToastMessages, CatalogToastMessages } from 'constants/ToastMessages';

import type { RootState } from 'types';
import type {
  TCatalogParams,
  TShareCatalog,
  TTemplateResponse,
  TCreateCatalogVersion,
  TAddGeneralInformationParams,
  TUpdateGeneralInformationParams,
} from './types';

export const getCatalogList = createAsyncThunk(
  'catalogSlice/getCatalogList',
  async (options: TCatalogParams) => {
    try {
      const response = await catalogApi.getCatalogListRequest(options);

      if (Array.isArray(response?.data) && !response?.data?.length) {
        return dataStructure;
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCatalogListItemUsage = createAsyncThunk(
  'catalogSlice/getCatalogListItemUsage',
  async (options: TCatalogParams, { getState }) => {
    const {
      catalog: { itemUsageDataLimit, itemUsageDataOffset },
    } = getState() as RootState;

    const { limit = itemUsageDataLimit, offset = itemUsageDataOffset, item_id } = options ?? {};

    try {
      const response = await catalogApi.getCatalogListRequest({
        limit,
        offset,
        item_id,
        sort_by: options?.sort_by || 'name',
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCatalogById = createAsyncThunk(
  'catalogSlice/getCatalogById',
  async (id: number) => {
    try {
      const response = await catalogApi.getCatalogByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createCatalog = createAsyncThunk(
  'catalogSlice/createCatalog',
  async (body: FormData, { dispatch, getState }) => {
    const start = toast.loading(CatalogToastMessages.CATALOG_CREATION);

    const {
      catalog: { catalogLimit, catalogOffset },
    } = getState() as RootState;

    try {
      const response = await catalogApi.createCatalogRequest(body);

      dispatch(getCatalogList({ limit: catalogLimit, offset: catalogOffset }));

      toast.update(start, {
        render: CatalogToastMessages.CATALOG_CREATION_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogToastMessages.CATALOG_CREATION_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteCatalogById = createAsyncThunk(
  'catalogSlice/deleteCatalog',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(CatalogToastMessages.CATALOG_REMOVAL_START);

    const {
      catalog: { catalogLimit },
    } = getState() as RootState;

    try {
      const response = await catalogApi.deleteCatalogRequest(id);

      dispatch(getCatalogList({ limit: catalogLimit, offset: 0 }));

      toast.update(start, {
        render: CatalogToastMessages.CATALOG_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogToastMessages.CATALOG_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const updateCatalog = createAsyncThunk(
  'catalogSlice/update',
  async (body: FormData, { dispatch, getState }) => {
    // const start = toast.loading(CatalogToastMessages.CATALOG_EDIT_START);

    const {
      catalog: { catalogLimit, catalogOffset },
    } = getState() as RootState;

    try {
      const response = await catalogApi.updateCatalogRequest(body);

      dispatch(getCatalogList({ limit: catalogLimit, offset: catalogOffset }));
      dispatch(getCatalogById(response?.data?.id as number));

      // toast.update(start, {
      //   render: CatalogToastMessages.CATALOG_EDIT_SUCCESS,
      //   type: 'success',
      //   isLoading: false,
      //   autoClose: 3000,
      // });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getCurrentSectionVersions = createAsyncThunk(
  'catalogSlice/getCurrentSectionVersions',
  async (id: number) => {
    try {
      const response = await catalogApi.getCatalogVersionsByVersionIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCatalogVersionsById = createAsyncThunk(
  'catalogSlice/getCatalogVersionsById',
  async (id: number) => {
    try {
      const response = await catalogApi.getCatalogVersionsByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createCatalogVersion = createAsyncThunk(
  'catalogSlice/createCatalogVersion',
  async (version: TCreateCatalogVersion, { dispatch, getState }) => {
    const {
      catalog: { catalogLimit, catalogOffset },
    } = getState() as RootState;

    try {
      const response = await catalogApi.createCatalogVersionRequest(version);

      dispatch(getCatalogList({ limit: catalogLimit, offset: catalogOffset }));
      dispatch(getCurrentSectionVersions(response?.data?.id));
      await dispatch(getCatalogVersionsById(response?.data?.catalog_id));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const shareCatalog = createAsyncThunk(
  'catalogSlice/shareCatalogRequest',
  async (options: TShareCatalog) => {
    const start = toast.loading(CatalogShareToastMessages.CATALOG_ACCESS_START);
    try {
      const response = await catalogApi.shareCatalogRequest(options);

      toast.update(start, {
        render: CatalogShareToastMessages.CATALOG_SHARE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogShareToastMessages.CATALOG_SHARE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getShareDCatalogDataById = createAsyncThunk(
  'catalogSlice/getShareDCatalogDataById',
  async (id: number) => {
    try {
      const response = await catalogApi.shareCatalogDataByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeCatalogSectionById = createAsyncThunk(
  'catalogSlice/removeCatalogSectionByIdRequest',
  async (id: number) => {
    try {
      const response = await catalogApi.removeCatalogSectionByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removeGeneralInformation = createAsyncThunk(
  'catalogSlice/removeGeneralInformation',
  async (id: number) => {
    try {
      const response = await catalogApi.removeGeneralInformationRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const addGeneralInformation = createAsyncThunk(
  'catalogSlice/addGeneralInformation',
  async (params: TAddGeneralInformationParams) => {
    try {
      const response = await catalogApi.addGeneralInformationRequest(params);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateGeneralInformation = createAsyncThunk(
  'catalogSlice/updateGeneralInformationRequest',
  async ({ catalogId, ...rest }: TUpdateGeneralInformationParams, { dispatch }) => {
    try {
      const response = await catalogApi.updateGeneralInformationRequest(rest);
      if (catalogId) {
        dispatch(getCatalogById(catalogId));
      }

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createCatalogTemplate = createAsyncThunk(
  'catalogSlice/createCatalogTemplate',
  async (options: FormData) => {
    try {
      const response = await catalogApi.createCatalogTemplateRequest(options);

      return response.data as TTemplateResponse;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateCatalogTemplate = createAsyncThunk(
  'catalogSlice/updateCatalogTemplateRequest',
  async (options: FormData) => {
    try {
      const response = await catalogApi.updateCatalogTemplateRequest(options);

      return response.data as TTemplateResponse;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getCatalogByUUID = createAsyncThunk(
  'catalogSlice/getCatalogByUUID',
  async (uuid: string) => {
    try {
      const response = await catalogApi.getCatalogByUUIDRequest(uuid);

      return response.data as TTemplateResponse;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const removedAccessById = createAsyncThunk(
  'catalogSlice/removedAccessById',
  async (id: number[]) => {
    const start = toast.loading(CatalogShareToastMessages.CATALOG_ACCESS_REMOVED_START);
    try {
      const response = await catalogApi.removedAccessByIdRequest(id);

      toast.update(start, {
        render: CatalogShareToastMessages.CATALOG_ACCESS_REMOVED_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: CatalogShareToastMessages.CATALOG_ACCESS_REMOVED_FAILED,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);
