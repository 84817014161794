import { MutableRefObject, useEffect, useState } from 'react';

const useHover = (ref: MutableRefObject<any | null>, delay = 1000, startHovering = true) => {
  const [isHovering, setHovering] = useState(false);
  let hoverTimeout: NodeJS.Timeout | null = null;

  const on = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      hoverTimeout = null;
    }

    hoverTimeout = setTimeout(() => {
      setHovering(true);
    }, delay);
  };

  const off = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      hoverTimeout = null;
    }

    hoverTimeout = setTimeout(() => {
      setHovering(false);
    }, delay);
  };

  useEffect(() => {
    const node = ref.current;

    const handleMouseEnter = () => startHovering && on();
    const handleMouseMove = () => startHovering && on();
    const handleMouseLeave = () => off();

    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mousemove', handleMouseMove);
      node.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mousemove', handleMouseMove);
        node.removeEventListener('mouseleave', handleMouseLeave);
      }

      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, startHovering]);

  return { isHovering, setHovering };
};

export default useHover;
