import { Avatar } from '@mui/material';
import { FC } from 'react';
import classNames from 'classnames';

import { returnImgUrl } from 'utils';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import { CustomSkeleton } from 'components';
import { UserImg } from 'assets/images';

import CustomTypography from '../Typography';

import { TCustomizedAvatarProps } from './types';
import styles from './TCustomizedAvatar.module.scss';

const CustomizedAvatar: FC<TCustomizedAvatarProps> = ({
  src,
  alt,
  width,
  height,
  content,
  variant,
  className,
  withBorder,
  borderColor,
  borderWidth,
  borderRadius,
  backGroundColor,
  isLoad = false,
  contentClasses,
}) => {
  const avatarClasses = classNames(
    styles.container,
    { [styles.container__border]: withBorder },
    className,
  );

  if (isLoad) {
    return <CustomSkeleton width={width} height={height} borderRadius={borderRadius} />;
  }

  return (
    <>
      {content ? (
        <Avatar
          variant={variant}
          className={avatarClasses}
          sx={{ bgcolor: backGroundColor }}
          style={{ borderColor, width, height, borderRadius }}
        >
          <CustomTypography className={contentClasses}>{content}</CustomTypography>
        </Avatar>
      ) : (
        <>
          <Avatar
            alt={alt}
            variant={variant}
            className={avatarClasses}
            style={{ borderColor, width, height, borderRadius, borderWidth }}
            src={src ? returnImgUrl(ImgUrlFolders.PROFILE_INFO, String(src)) : UserImg}
          />
        </>
      )}
    </>
  );
};

export default CustomizedAvatar;
