import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { FC, useState } from 'react';
import classNames from 'classnames';

import { useAppSelector } from 'hooks';
import { StatusEnums } from 'components/shared/StatusInfo/types';
import { couponsSelector } from 'store/slices/discountSlice/selectors';
import { CustomDataGrid, CustomSkeleton, ScrollLayout, StatusInfo, TableCell } from 'components';
import SectionHead from 'components/views/Drawers/CatalogCreateConfigure/SectionHead';
import { EmptyTitles } from 'constants/EmptyTitles';
import { sortByKey } from 'utils/sortByKey';

import { columns } from './DiscountsView.utils';
import styles from './DiscountsView.module.scss';
import { TDiscountsViewProps, TKeyValues } from './types';

const DiscountsView: FC<TDiscountsViewProps> = ({ isLoading }) => {
  const { couponForCatalog } = useAppSelector(couponsSelector);

  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>('code');

  const keyValues: TKeyValues = {
    code: 'coupon_code',
    discount: 'discount_percent',
    start: 'start_date',
    end: 'end_date',
    status: 'coupon_status',
  };

  const sortedData = sortByKey(
    couponForCatalog?.data,
    keyValues[newSortBy as keyof TKeyValues] as any,
    asc,
  );

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted = column.field ? newSortBy === column.field : 'code';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      sortable: true,
      hideSortIcons: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const rows = sortedData?.map((coupon) => ({
    id: coupon?.id,
    code: <TableCell title={coupon?.coupon_code} />,
    discount: <TableCell title={`${coupon?.discount_percent}%`} />,
    start: <TableCell title={moment(coupon?.start_date).format('MMM D, YYYY') as string} />,
    end: <TableCell title={moment(coupon?.end_date).format('MMM D, YYYY') as string} />,
    status: (
      <StatusInfo
        title={coupon?.coupon_status as string}
        status={coupon?.coupon_status as StatusEnums}
      />
    ),
  }));

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  return (
    <Box className={styles.container}>
      <SectionHead
        withUnderlinedTitle
        title='Discounts'
        subtitle={`(These discount codes can be applied to linked estimates via the estimate screen.)`}
      />
      <ScrollLayout>
        {isLoading ? (
          <CustomSkeleton height={100} width='100%' borderRadius='4px' />
        ) : (
          <CustomDataGrid
            emptyTitle={EmptyTitles.Catalog_Discounts}
            columns={renderColumns}
            rows={rows || []}
            sortModel={[{ field: newSortBy || 'code', sort: asc ? 'asc' : 'desc' }]}
            onColumnHeaderClick={handleColumnHeaderClick}
          />
        )}
      </ScrollLayout>
    </Box>
  );
};

export default DiscountsView;
