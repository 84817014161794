import { BaseSyntheticEvent, ChangeEvent, useEffect, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';

import { useAppDispatch, useAppSelector } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { PartsSortBy, TAllPartsReturnType, TPart } from 'store/slices/inventorySlice/types';
import { partsSelector } from 'store/slices/inventorySlice/selectors';
import { getAllParts, getPartById, removePartById } from 'store/thunks';
import { optionsSelector } from 'store/slices/optionsSlice/selectors';
import { fillSelectedIds, setInventoryParts } from 'store/slices/optionsSlice';
import { setLimit, setPage as setInventoryPage } from 'store/slices/inventorySlice';

const view = BrowserStorageService.get(BrowserStorageKeys.inventoryView, {
  session: true,
});

const sortByFromStorage = BrowserStorageService.get(BrowserStorageKeys.InventorySortBy, {
  session: true,
});

const sortByAscFromStorage = BrowserStorageService.get(BrowserStorageKeys.InventorySortByAsc, {
  session: true,
});

const useInventory = (mediaSource: string | null, renderedParts: TAllPartsReturnType) => {
  const dispatch = useAppDispatch();

  const { allParts, allPartsAsc, currentPartById, allPartsLimit, allPartsOffset } =
    useAppSelector(partsSelector);

  const { selectedIds: selectedItemsIdsFromStore } = useAppSelector(optionsSelector);

  const [anchorEl, setAnchorEl] = useState(null);
  const [file, setFile] = useState<File | null>(null);
  const [isShowInfo, setIsShowInfo] = useState<boolean>(false);
  const [isCreatePo, setIsCreatePo] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isNewDrawer, setIsNewDrawer] = useState<boolean>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<PartsSortBy | null>(null);
  const [selectedPart, setSelectedPart] = useState<TPart | null>(null);
  const [isEditInventory, setIsEditInventory] = useState<boolean>(false);
  const [isVendorListOpen, setIsVendorListOpen] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [isVendorDrawerOpen, setIsVendorDrawerOpen] = useState<boolean>(false);
  const [isViewInGrid, setIsViewInGrid] = useState<boolean>((view && JSON.parse(view)) || true);
  const sendedValue = currentPartById?.image_url_id ? mediaSource : currentPartById?.image_url_id;

  const [currentImageSrc, setCurrentImageSrc] = useState<string | null>(sendedValue as string);

  const [page, setPage] = useState<number>(0);

  const handleMoreClick = (event: BaseSyntheticEvent, row: TPart) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);

    if (row) {
      setSelectedPart(row);
    }

    if (row?.id) {
      setSelectedItemId(row?.id);
    }
  };

  const handleClose = () => {
    if (!isShareOpen) {
      setAnchorEl(null);
    }

    setIsShareOpen(false);
  };

  const getFile = (file: File | null) => {
    setFile(file);
    setCurrentImageSrc(null);
  };

  const getCurrentImgSrc = (src: string | null) => setCurrentImageSrc(src);

  const startCreatePo = () => setIsCreatePo(true);

  const hideCreatePo = () => setIsCreatePo(false);

  const hideInfo = () => setIsShowInfo(false);

  const showInfo = async (id: number) => {
    await dispatch(getPartById(id));

    setIsShowInfo(true);
  };

  const openDrawer = () => setIsNewDrawer(true);

  const closeDrawer = () => setIsNewDrawer(false);

  const handleRemoveSelect = () => {
    setIsSelectedAll(false);
    dispatch(setInventoryParts([]));
    dispatch(fillSelectedIds([]));
  };

  const selectAll = () => {
    if (renderedParts?.data?.length !== selectedItemsIdsFromStore?.length) {
      const allInventoryIds = renderedParts?.data?.map((inventory) => inventory.id);
      dispatch(fillSelectedIds(allInventoryIds));
      dispatch(setInventoryParts(renderedParts?.data));

      setIsSelectedAll(true);
    } else {
      dispatch(fillSelectedIds([]));

      dispatch(setInventoryParts([]));

      setIsSelectedAll(false);
    }
  };

  const closeEditDrawer = () => setIsEditInventory(false);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const openEditDrawer = async (id: number) => {
    await dispatch(getPartById(id));

    setIsEditInventory(true);
  };

  const openVendorDrawer = () => setIsVendorDrawerOpen(true);
  const closeVendorListedDrawer = () => setIsVendorDrawerOpen(false);

  const [asc, setAsc] = useState<boolean>(false);

  const handleColumnHeaderClick = (column: GridColDef) => {
    setAsc((prev) => !prev);
    setNewSortBy(column.field === 'number' ? PartsSortBy.ID : (column.field as PartsSortBy));

    const sortByValue = column.field === 'number' ? PartsSortBy.ID : (column.field as PartsSortBy);

    if (sortByFromStorage) {
      BrowserStorageService.update(
        BrowserStorageKeys.InventorySortBy,
        JSON.stringify(sortByValue),
        {
          session: true,
        },
      );
    } else {
      BrowserStorageService.set(BrowserStorageKeys.InventorySortBy, JSON.stringify(sortByValue), {
        session: true,
      });
    }

    if (sortByAscFromStorage) {
      BrowserStorageService.update(BrowserStorageKeys.InventorySortByAsc, JSON.stringify(asc), {
        session: true,
      });
    } else {
      BrowserStorageService.set(BrowserStorageKeys.InventorySortByAsc, JSON.stringify(asc), {
        session: true,
      });
    }

    if (column.field !== '__check__' && column.field !== 'image') {
      dispatch(
        getAllParts({
          sort_by: sortByValue || JSON.parse(sortByFromStorage as string),
          asc: allPartsAsc,
          limit: allPartsLimit,
          offset: allPartsOffset,
        }),
      );
    }
  };

  const handlePageChange = (_: unknown, newPage: number) => {
    setPage(newPage);

    dispatch(setInventoryPage(newPage + 1));
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    handlePageChange(null, 0);
    dispatch(setLimit(event.target.value));
  };

  const onDelete = async () => {
    await dispatch(removePartById([selectedItemId as number]));

    if (page > 0) {
      setPage((prev) => prev - 1);
    }

    togglePopup();
  };

  useEffect(() => {
    if (renderedParts?.data?.length === selectedItemsIdsFromStore?.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderedParts?.data?.length, selectedItemsIdsFromStore?.length]);

  useEffect(() => {
    setCurrentImageSrc(sendedValue as string);
  }, [sendedValue]);

  useEffect(() => {
    if (view) {
      setIsViewInGrid(JSON.parse(view));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!allParts?.total_count && page) {
      setPage(page - 1);
      handlePageChange(null, page - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPartsLimit]);

  return {
    page,
    handleMoreClick,
    handleClose,
    isViewInGrid,
    anchorEl,
    file,
    getFile,
    hideInfo,
    showInfo,
    onDelete,
    newSortBy,
    selectAll,
    isShowInfo,
    openDrawer,
    isShareOpen,
    isCreatePo,
    togglePopup,
    selectedPart,
    closeDrawer,
    isNewDrawer,
    isPopupOpen,
    hideCreatePo,
    isSelectedAll,
    startCreatePo,
    openEditDrawer,
    selectedItemId,
    setIsShareOpen,
    closeEditDrawer,
    currentImageSrc,
    isEditInventory,
    setIsViewInGrid,
    getCurrentImgSrc,
    isVendorListOpen,
    handlePageChange,
    openVendorDrawer,
    isVendorDrawerOpen,
    handleRemoveSelect,
    setIsVendorListOpen,
    closeVendorListedDrawer,
    handleRowsPerPageChange,
    handleColumnHeaderClick,
  };
};

export default useInventory;
