import { FC } from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';

import { MinusIcon } from 'assets/icons';
import { CustomTextField } from 'components';

import styles from './RequisitionForm.module.scss';
import { TRequisitionItemsProps } from './types';

const RequisitionItems: FC<TRequisitionItemsProps> = ({ idx, removeUserInfo, control, errors }) => (
  <Box className={styles.container__content}>
    <Box className={styles.container__content__back}>
      <Box className={styles.container__content__items}>
        <Controller
          control={control}
          name={`requisitionItems[${idx}].itemNo`}
          defaultValue=''
          render={({ field }) => (
            <CustomTextField
              {...field}
              placeholder='Item No/Name:'
              required
              label='Item No/Name:'
              error={!!errors?.itemNo}
            />
          )}
        />

        <Controller
          control={control}
          name={`requisitionItems[${idx}].quantity`}
          defaultValue=''
          render={({ field }) => (
            <CustomTextField
              {...field}
              type='number'
              placeholder='Quantity:'
              label='Quantity:'
              error={!!errors?.quantity}
            />
          )}
        />
      </Box>

      <Controller
        control={control}
        name={`requisitionItems[${idx}].description`}
        defaultValue=''
        render={({ field }) => (
          <CustomTextField
            rows={4}
            multiline
            {...field}
            label='Description:'
            placeholder='Description:'
            error={!!errors?.description}
          />
        )}
      />
    </Box>

    {idx !== 0 && (
      <>
        lllll
        <MinusIcon
          role='button'
          onClick={removeUserInfo}
          className={styles.container__content__remove}
        />
      </>
    )}
  </Box>
);

export default RequisitionItems;
