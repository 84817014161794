import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { useState } from 'react';

import { client } from 'api';
import { XLSXDownloadToastMessages, XLSXViewToastMessages } from 'constants/ToastMessages';

import type { TUsePdfDownloadParams } from './types';

const useXLSXDownload = ({ fileUrl, folder, withToastMessages = false }: TUsePdfDownloadParams) => {
  const [isXLStarted, setIsXLStarted] = useState<boolean>(false);

  const getTheXLSXFile = async (fileFromParam?: string, toastMessages = true) => {
    let start;
    const url = fileUrl || fileFromParam;

    if (withToastMessages && toastMessages) {
      start = toast.loading(XLSXDownloadToastMessages.XLSX_DOWNLOAD_IN_PROGRESS);
    }
    try {
      setIsXLStarted(true);
      const response = await client.get(`/media-api/sh/media/${folder}/${url}`, {
        responseType: 'arraybuffer',
      });

      if (start && withToastMessages && toastMessages) {
        toast.update(start, {
          render: XLSXDownloadToastMessages.XLSX_DOWNLOAD_SUCCESS,
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      setIsXLStarted(false);

      return response.data;
    } catch (error) {
      if (start && withToastMessages && toastMessages) {
        toast.update(start, {
          render: XLSXDownloadToastMessages.XLSX_DOWNLOAD_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
      setIsXLStarted(false);

      const Error = error as AxiosError;
      throw Error;
    } finally {
      setIsXLStarted(false);
    }
  };

  const openXLSXInNewTab = async (testUrl?: string, toastMessages = false) => {
    const start = toast.loading(XLSXViewToastMessages.XLSX_VIEW_IN_PROGRESS);
    try {
      const pdfData = await getTheXLSXFile(testUrl, toastMessages);
      const blob = new Blob([pdfData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');

      toast.update(start, {
        render: XLSXViewToastMessages.XLSX_VIEW_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      toast.update(start, {
        render: XLSXViewToastMessages.XLSX_VIEW_FAILURE,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  };

  const downloadXLSX = async (pdfData: any, fileName: string) => {
    const blob = new Blob([pdfData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, fileName?.endsWith('.xlsx') ? fileName : `${fileName}.xlsx` || `file.xlsx`);
  };

  const fetchXLDataAndDownload = async (url?: string) => {
    try {
      const pdfData = await getTheXLSXFile(url);
      downloadXLSX(pdfData, fileUrl);
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  };

  return { fetchXLDataAndDownload, isXLStarted, openXLSXInNewTab };
};

export default useXLSXDownload;
