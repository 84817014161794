import { StatusEnums } from 'components/shared/EmployeeStatus/types';
import {
  TEstimateSectionItemOptions,
  TGetEstimateSectionItems,
} from 'store/slices/estimatesSlice/types';

export const endpoints = {
  LandingService: {
    bookDemo: (): string => '/auth-api/sh/demo_bookings/book_new_demo',
  },
  AuthService: {
    signUp: (): string => `/auth-api/sh/user/temp/register/`,
    signIn: (): string => '/auth-api/sh/user/temp/login/',
    signInOAuth: (): string => '/auth-api/sh/user/oauth_login/',
    refreshToken: (): string => '/auth-api/sh/user/refresh_token/',
    getUserByToken: (): string => '/auth-api/sh/user/me/',
    getUserById: (): string => '/auth-api/sh/user/id/',
    getUserPermissions: (): string => '/auth-api/sh/user/permissions/',
    searchUserByQuery: (query: string): string => `/auth-api/sh/user/search/${query}`,
    updateUser: (): string => '/auth-api/sh/user/update/',
    updateImage: (): string => '/auth-api/sh/user/update_image/',
    getUserSettings: (): string => '/auth-api/sh/user/setting/',
    updateUserSettings: (): string => '/auth-api/sh/user/setting/',
    getAllRoles: (): string => '/auth-api/sh/roles/roles',
    createRole: (): string => '/auth-api/sh/roles/roles',
    getRoleById: (role_id: number): string => `/auth-api/sh/roles/roles/${role_id}/`,
    createUserRole: (): string => '/auth-api/sh/user_roles/user_roles',
    getAllUserRoles: (): string => '/auth-api/sh/user_roles/current_user_roles/',
    deleteUserRole: (): string => `/auth-api/sh/user_roles/delete_user_roles/`,
    invitedUser: (): string => '/auth-api/sh/invited_user/create/',
    getInvitationByOrgId: (): string => `/auth-api/sh/invited_user/invitations/`,
    invitedPendingUsers: (): string => '/auth-api/sh/invited_user/pending-invitations/',
    getAllUsers: (): string => `/auth-api/sh/user/users/`,
    getInvitationById: (id: number): string => `/auth-api/sh/invited_user/invitations/${id}`,
    updateInvitationStatus: (id: number, status: string): string =>
      `/auth-api/sh/invited_user/status?id=${id}&status=${status}`,
    getUsersByRole: (): string => '/auth-api/sh/user_roles/',
    resendInvitationToUser: (id: number): string => `/auth-api/sh/invited_user/resend/?id=${id}`,
    deleteInvitedUser: (id: number): string => `/auth-api/sh/invited_user/${id}`,
  },
  OrganizationsService: {
    createOrganization: (): string => '/profiles-api/sh/organizations/create_organization',
    getOrganizationById: (): string => '/profiles-api/sh/organizations/get_organization/id/',
    getAllOrganizations: (): string => '/profiles-api/sh/organizations/get_organization/all/',
    updateOrganization: (): string => '/profiles-api/sh/organizations/update_organizations/',
    getOrganizationBussinesType: (): string =>
      '/profiles-api/sh/organizations/metadata/business_types',
    getOrganizationMembers: (): string => `/profiles-api/sh/organizations/members`,
    removeOrganizationMember: (status: string, member_id: number): string =>
      `/profiles-api/sh/organizations/members/${member_id}?status=${status}`,
    sendOrganizationResources: (org_id: number): string =>
      `/profiles-api/sh/organizations/${org_id}/resources`,
    getUserOrganizations: (): string => '/auth-api/sh/user/organizations/',
    updateOrganizationMemberStatus: (member_id: number, status: StatusEnums): string =>
      `/profiles-api/sh/organizations/members/${member_id}/status?member_status=${status}`,
    updateOrganizationMember: (): string => '/auth-api/sh/user/edit/',
    GetOrganizationTemplateType: (): string =>
      '/profiles-api/sh/organizations/get_organization_template_type',
  },
  SubscriptionsService: {
    createSubscription: (): string => '/profiles-api/sh/subscriptions/create_subscription',
    createPaymentIntent: (): string => '/profiles-api/sh/subscriptions/create_payment_intent',
    setupPaymentIntent: (): string => '/profiles-api/sh/subscriptions/create_setup_intent',
    getUserSubscriptions: (): string => '/profiles-api/sh/subscriptions/get_user_subscriptions',
    CreateStripeAccount: (): string => '/profiles-api/sh/stripe_account/create_stripe_account/',
    getPaymentMethods: (): string => '/profiles-api/sh/subscriptions/get_payment_methods',
    getDefaultPaymentMethod: (): string => '/profiles-api/sh/subscriptions/payment_method/default',
    setDefaultPaymentMethod: (): string =>
      '/profiles-api/sh/subscriptions/payment_method/set_default',
    updateBillingDetails: (): string =>
      '/profiles-api/sh/subscriptions/payment_method/billing_details',
    getInvoiceHistory: (): string => '/profiles-api/sh/subscriptions/get_invoice_history',
    updateSubscription: (): string => '/profiles-api/sh/subscriptions/update_subscription',
  },
  RolesService: {
    getAllRoles: (): string => '/auth-api/sh/roles/roles',
  },
  InvitationService: {
    createInvitation: (): string => '/auth-api/sh/invited_user/create/',
  },

  ActivityService: {
    getActivities: (): string => '/activities-api/sh/activity_feed/',
    getFilterActivities: (id: number): string => `/activities-api/sh/activity_feed/${id}`,

    createActivities: (): string => '/activities-api/sh/activity_feed/',
    getActivityById: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}`,
    updateActivityById: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}`,
    deleteActivityById: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}`,
    getActivityByUserId: (user_id: number): string =>
      `/activities-api/sh/activity_feed/user/${user_id}`,
    getActivityByQuery: (query: string): string =>
      `/activities-api/sh/activity_feed/search/${query}`,
    postActivityLike: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}/like`,
    removeActivityLike: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}/like`,
    getActivityComment: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}/comment`,
    createActivityComment: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}/comment`,
    getActivityReplies: (comment_id: number): string =>
      `/activities-api/sh/activity_feed/comment/${comment_id}/replies`,
    createActivityFlag: (): string => '/activities-api/sh/activity_feed/flag',
    getActivityFlags: (id: number): string => `/activities-api/sh/activity_feed/flag/${id}`,
    disableActivity: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/disable/${activity_feed_id}`,
    getDisabledActivity: (): string => '/activities-api/sh/activity_feed/disable/',
    updatePostComment: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}`,
    removePostComment: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}`,
    likePostComment: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}/like`,
    removePostCommentLike: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/like/${comment_id}`,
    getNotices: (): string => '/activities-api/sh/notice/',
    updateNotice: (): string => '/activities-api/sh/notice/',
    createNotice: (): string => '/activities-api/sh/notice/',
    getNoticeById: (notice_id: number): string => `/activities-api/sh/notice/${notice_id}`,
    deleteNoticeById: (notice_id: number): string => `/activities-api/sh/notice/${notice_id}/`,
    getPostCommentById: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}`,
    hidePost: (activity_feed_id: number): string =>
      `/activities-api/sh/hidden_activities/${activity_feed_id}/`,
    getHiddenActivityById: (id: number): string => `/activities-api/sh/hidden_activities/${id}`,
    removeHiddenActivity: (id: number): string => `/activities-api/sh/hidden_activities/${id}`,
    getAllHiddenActivityFeeds: (): string => `/activities-api/sh/hidden_activities/`,
    genAi: (): string => '/activities-api/sh/genai/',
  },
  TeamsService: {
    updateTeams: (): string => '/teams-api/sh/teams/',
    createTeams: (): string => '/teams-api/sh/teams/',
    getUserTeams: (): string => '/teams-api/sh/teams/user_teams/',
    getTeamsById: (team_id: number): string => `/teams-api/sh/teams/${team_id}`,
    deleteTeam: (team_id: number): string => `/teams-api/sh/teams/${team_id}/`,
    addMember: (): string => '/teams-api/sh/teams/add_member',
    removeMember: (): string => '/teams-api/sh/teams/remove_member',
    postListMembers: (team_id: number): string => `/teams-api/sh/teams/${team_id}/list_members`,
    getTags: (): string => '/teams-api/sh/tags/',
    updateTags: (): string => '/teams-api/sh/tags/',
    createTags: (): string => '/teams-api/sh/tags/',
    getTagsById: (id: number): string => `/teams-api/sh/tags/${id}`,
    deleteTag: (id: number): string => `/teams-api/sh/tags/${id}`,
    creteTeamTags: (): string => '/teams-api/sh/team_tags/',
    deleteTeamTags: (): string => '/teams-api/sh/team_tags/',
  },
  InventoriesService: {
    getAllParts: (): string => '/inventory-api/sh/parts/',
    createPart: (): string => '/inventory-api/sh/parts/',
    updatePartSnapShot: (): string => '/inventory-api/sh/parts/',
    createPartSnapShot: (): string => '/inventory-api/sh/parts/snapshot/',
    getVendorList: (): string => '/inventory-api/sh/vendors/',
    getVendorById: (id: number): string => `/inventory-api/sh/vendors/${id}`,
    getExcelById: (id: number): string => `/catalogs-api/sh/excel-template/get-excel/${id}`,
    createExcel: (): string => '/catalogs-api/sh/excel-template/excel/',
    createVendor: (): string => '/inventory-api/sh/vendors/',
    getSnapShotByPartIdOpened: (part_id: number): string =>
      `/inventory-api/sh/parts/snapshots/opened/${part_id}`,
    getPartsHistoryByPartId: (part_id: number): string =>
      `/inventory-api/sh/parts/history/${part_id}`,
    getSnapShotById: (snapshot_id: number): string =>
      `/inventory-api/sh/parts/snapshots/${snapshot_id}`,
    getPartById: (part_id: number): string => `/inventory-api/sh/parts/${part_id}`,
    removePartById: (): string => `/inventory-api/sh/parts/delete-parts/`,
    updatePartBySnapShotId: (snapshot_id: number): string =>
      `/inventory-api/sh/parts/${snapshot_id}`,
    removePartBySnapShotId: (snapshot_id: number): string =>
      `/inventory-api/sh/parts/snapshot/${snapshot_id}`,
    getSnapShotDecline: (snapshot_id: number): string =>
      `/inventory-api/sh/parts/snapshot/${snapshot_id}/decline`,
    removeVendorById: (id: number): string => `/inventory-api/sh/vendors/${id}`,
    removeExcelById: (id: number): string =>
      `/catalogs-api/sh/excel-template/delete-template/${id}`,
    setDefaultExcelById: (id: number): string =>
      `/catalogs-api/sh/excel-template/update-template/${id}`,
    editVendor: (): string => '/inventory-api/sh/vendors/',
    findVendor: (query: string): string => `/inventory-api/sh/vendors/search/${query}`,
  },

  CatalogsService: {
    getCatalogs: (): string => '/catalogs-api/sh/catalogs/',
    getCatalogById: (id: number) => `/catalogs-api/sh/catalogs/${id}`,
    createCatalog: () => '/catalogs-api/sh/catalogs/',
    deleteCatalog: (id: number) => `/catalogs-api/sh/catalogs/${id}`,
    deleteCatalogSection: (id: number) => `/catalogs-api/sh/catalogs/version/${id}`,
    updateCatalog: () => `/catalogs-api/sh/catalogs/`,
    getCatalogVersions: (id: number) => `/catalogs-api/sh/catalogs/${id}/versions`,
    createCatalogVersion: () => '/catalogs-api/sh/catalogs/version/',
    getCatalogVersionsByVersionId: (id: number) => `/catalogs-api/sh/catalogs/versions/${id}`,
    shareCatalog: () => '/catalogs-api/sh/access/',
    getShareDCatalogDataById: (id: number) => `/catalogs-api/sh/access/get/${id}`,
    getShareDCatalogDataByUserId: (user_id: number) => `/catalogs-api/sh/access/${user_id}`,
    getShareDCatalogDataByCatalogId: (catalog_id: number) =>
      `/catalogs-api/sh/access/catalog_id/${catalog_id}`,
    removeCatalogAccess: (id: number[]): string => `/catalogs-api/sh/access/${id}`,
    addGeneralInformation: (): string => '/catalogs-api/sh/catalogs/general-info/',
    updateGeneralInformation: (): string => '/catalogs-api/sh/catalogs/general-info/',
    removeGeneralInformation: (general_info_id: number): string =>
      `/catalogs-api/sh/catalogs/catalog/${general_info_id}/`,
    createCatalogTemplate: (): string => `/catalogs-api/sh/catalogs/template/`,
    updateCatalogTemplate: (): string => `/catalogs-api/sh/catalogs/update_template/`,
    getCatalogByUUID: (uuid: string): string => `/catalogs-api/sh/catalogs/uuid/${uuid}`,
    getExcelList: (): string => '/catalogs-api/sh/excel-template/get-all/',
  },

  PurchaseRequisitionService: {
    getAllRequisitions: (): string => '/product-planning-api/sh/requisitions/',
    createRequisitions: (): string => '/product-planning-api/sh/requisitions/',
    getRequisitionItemsById: (id: number | string) => `/product-planning-api/sh/requisitions/${id}`,
    getRequisitionItemsByUuid: (uuid: string) =>
      `/product-planning-api/sh/requisitions/uuid/${uuid}`,
    updateRequisition: (): string => '/product-planning-api/sh/requisitions/',
    modifyRequisition: (): string => '/product-planning-api/sh/requisitions/items/',
    addItemsToRequisition: (): string => '/product-planning-api/sh/requisitions/items/',
    deleteRequisition: (id: number): string => `/product-planning-api/sh/requisitions/${id}`,
    cloneRequisition: (id: number): string => `/product-planning-api/sh/requisitions/${id}`,
    deleteRequisitionItem: (id: number): string =>
      `/product-planning-api/sh/requisitions/items/${id}`,
    requisitionApproveDecline: (status: string): string =>
      `/product-planning-api/sh/requisitions/{id}/items/update?status=${status}`,
  },

  RfqsService: {
    getRfqs: (): string => '/product-planning-api/sh/rfqs/',
  },

  LivestreamService: {
    getAllLiveStreams: (): string => '/livestreams-api/sh/livestreams/',
    createNoteForStream: (): string => '/livestreams-api/sh/livestreams/',
    createLivestream: (): string => '/livestreams-api/sh/livestreams/',
    getAllLiveStreamById: (id: number) => `/livestreams-api/sh/livestreams/${id}`,
    updateLastRead: (): string => '/livestreams-api/sh/livestreams/member/last_read',
    inviteExternalMember: (livestream_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}/invite`,
    inviteMember: (livestream_id: number, user_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}/invite/${user_id}`,
    joinLivestream: (livestream_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}/join`,
    getExternalLivestream: (token: string): string =>
      `/livestreams-api/sh/livestreams/join/external/${token}`,
    leaveLivestream: (livestream_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}/leave`,
    removeLivestream: (livestream_id: number, member_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}/remove/${member_id}`,
    removeExternalMemberLivestream: (token: string): string =>
      `/livestreams-api/sh/livestreams/leave/external/${token}`,
    deleteLivestream: (livestream_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}`,
    updateLivestreamMessage: (): string => '/livestreams-api/sh/messages/',
    createLivestreamMessage: (): string => '/livestreams-api/sh/messages/',
    getLivestreamMessageById: (id: number): string => `/livestreams-api/sh/messages/${id}`,
    getLivestreamMessages: (livestream_id: number): string =>
      `/livestreams-api/sh/messages/${livestream_id}/messages`,
    deleteLivestreamMessageById: (message_id: number): string =>
      `/livestreams-api/sh/messages/${message_id}`,
    getLiveStreamDetails: (id: number): string => `/livestreams-api/sh/livestreams/${id}/details`,
    updateLiveStream: (): string => '/livestreams-api/sh/livestreams/',
    getMyCurrentStream: (): string => '/livestreams-api/sh/livestreams/current/',
    endStream: (livestream_id: number): string =>
      `/livestreams-api/sh/livestreams/${livestream_id}/end`,
    uploadMediaToStream: (): string => '/livestreams-api/sh/livestreams/media/',

    updateMediaNote: (): string => '/livestreams-api/sh/notes/',
    createStreamNote: (): string => '/livestreams-api/sh/notes/',
    getStreamNoteById: (id: number): string => `/livestreams-api/sh/notes/${id}`,
    getStreamAllNotes: (livestream_id: number): string =>
      `/livestreams-api/sh/notes/${livestream_id}/notes`,
    removeStreamNote: (note_id: number): string => `/livestreams-api/sh/notes/${note_id}`,
    getStreamWithUUID: (uuid: string): string => `/livestreams-api/sh/livestreams/uuid/${uuid}`,
  },

  PurchaseOrderServices: {
    getAllPurchaseOrders: (): string => '/product-planning-api/sh/purchase_orders/',
    createPurchaseOrder: (): string => '/product-planning-api/sh/purchase_orders/',
    getPurchaseOrderItemsById: (id: number): string =>
      `/product-planning-api/sh/purchase_orders/${id}/items`,
    updatePurchaseOrder: (): string => '/product-planning-api/sh/purchase_orders/',
    getPurchaseOrderById: (id: number): string => `/product-planning-api/sh/purchase_orders/${id}`,
    getPurchaseOrderByUuid: (uuid: string): string =>
      `/product-planning-api/sh/purchase_orders/uuid/${uuid}`,
    updatePurchaseOrderItem: (): string => `/product-planning-api/sh/purchase_orders/items/`,
    deletePurchaseOrderItem: (id: number): string =>
      `/product-planning-api/sh/purchase_orders/${id}`,
    receivePurchaseOrderItem: (id: number): string =>
      `/product-planning-api/sh/purchase_orders/${id}/recieve`,
    submitPurchaseOrderItem: (id: number): string =>
      `/product-planning-api/sh/purchase_orders/publish/${id}`,
  },

  EstimatesServices: {
    getAllEstimates: (): string => '/estimate-api/sh/estimate/',
    getAllEstimatesFromTemplate: (): string =>
      '/estimate-api/sh/estimate-template/get-all-estimates/',
    createEstimateVersionsFromTemplate: (uuid: string): string =>
      `/estimate-api/sh/estimate-template/get-estimate-version/?parent_uuid=${uuid}`,
    createEstimate: (): string => '/estimate-api/sh/estimate/',
    createEstimateFromTemplate: (id: number): string =>
      `/estimate-api/sh/estimate-template/create-estimate/?excel_template_id=${id}`,
    UpdateEstimateFromTemplate: (uuid: string, id: number): string =>
      `/estimate-api/sh/estimate-template/update-estimate/?uuid=${uuid}&excel_template_id=${id}`,
    getEstimateById: (id: number): string => `/estimate-api/sh/estimate/${id}`,
    getElementsById: (): string => `/estimate-api/sh/estimate-template/estimate-elements/`,
    getEstimateByUUID: (uuid: string): string =>
      `/estimate-api/sh/estimate-template/get-estimate-by-uuid/${uuid}`,
    deleteEstimate: (id: number): string => `/estimate-api/sh/estimate/${id}`,
    deleteEstimateSection: (estimate_id: number, estimate_section_id: number): string =>
      `/estimate-api/sh/estimate/${estimate_id}/section/${estimate_section_id}`,
    createEstimateSection: (id: number): string => `/estimate-api/sh/estimate/${id}/section/`,
    deleteEstimateSectionItem: (
      estimate_id: number,
      estimate_section_id: number,
      item_id: number,
    ): string =>
      `/estimate-api/sh/estimate/${estimate_id}/section/${estimate_section_id}/item/${item_id}`,
    editEstimate: (id: number): string => `/estimate-api/sh/estimate/${id}`,
    createEstimateSectionItem: (estimate_id: number, estimate_section_id: number) =>
      `/estimate-api/sh/estimate/${estimate_id}/section/${estimate_section_id}/item/`,
    editProfitMargin: (estimate_id: number, estimate_section_id: number): string =>
      `/estimate-api/sh/estimate/${estimate_id}/section/${estimate_section_id}`,
    editEstimateSectionById: (options: TEstimateSectionItemOptions) =>
      `/estimate-api/sh/estimate/${options.estimate_id}/section/${options.estimate_section_id}/item/${options.item_id}`,
    getEstimateSections: (estimate_id: number): string =>
      `/estimate-api/sh/estimate/${estimate_id}/section/`,
    getEstimateSectionItems: (options: TGetEstimateSectionItems): string =>
      `/estimate-api/sh/estimate/${options?.estimate_id}/section/${options?.estimate_section_id}`,

    sendEstimateToCustomer: (estimate_id: number): string =>
      `/estimate-api/sh/estimate/${estimate_id}/publish`,
    getEstimateByUuid: (estimate_uuid: string): string =>
      `/estimate-api/sh/estimate/uuid/${estimate_uuid}`,
  },

  AgreementServices: {
    getAllAgreements: (): string => '/agreements-api/sh/agreements/',
    createAgreement: (): string => '/agreements-api/sh/agreements/',
    deleteAgreement: (id: number) => `/agreements-api/sh/agreements/${id}`,
    getAgreementById: (id: number) => `/agreements-api/sh/agreements/${id}`,
    updateAgreement: (): string => '/agreements-api/sh/agreements/',
    getJobTypes: (): string => '/agreements-api/sh/job_type/',
    updateJobType: (): string => '/agreements-api/sh/job_type/',
    createJobType: (): string => '/agreements-api/sh/job_type/',
    getJobTypeById: (id: number) => `/agreements-api/sh/job_type/${id}`,
    deleteJobType: (id: number) => `/agreements-api/sh/job_type/${id}`,
    sendAgreementToCustomer: (agreement_id: number) =>
      `/estimate-api/sh/estimate/${agreement_id}/publish_agreement`,
  },

  WorkOrderServices: {
    getAllWorkOrders: (): string => '/work-orders-api/sh/wo/',
    updateWorkOrder: (): string => '/work-orders-api/sh/wo/',
    createWorkOrder: (): string => '/work-orders-api/sh/wo/',
    createWorkOrderAssignee: (): string => '/work-orders-api/sh/work_order/assignee/',
    getWorkOrderById: (work_order_id: number) => `/work-orders-api/sh/wo/${work_order_id}`,
    deleteWorkOrder: (work_order_id: number) => `/work-orders-api/sh/wo/${work_order_id}`,
    deleteWorkOrderAssignee: (id: number) => `/work-orders-api/sh/work_order/assignee/${id}`,
    createWorkOrderJobType: (): string => '/work-orders-api/sh/work_order/job_type',
    deleteWorkOrderJobType: (id: number) => `/work-orders-api/sh/work_order/${id}/job_type`,
    getWorkOrderTasks: (work_order_id: number) =>
      `/work-orders-api/sh/workorder_tasks/all-tasks-by-workorder-id/${work_order_id}`,
    updateWorkOrderTasks: () => '/work-orders-api/sh/workorder_tasks/',
    createWorkOrderTask: () => '/work-orders-api/sh/workorder_tasks/',
    getWorkOrderTaskById: (task_id: number) => `/work-orders-api/sh/workorder_tasks/${task_id}`,
    deleteWorkOrderTaskById: (task_id: number) => `/work-orders-api/sh/workorder_tasks/${task_id}`,
  },

  CustomerService: {
    getAllCustomers: (): string => '/customers-api/sh/customers/',
    updateCustomer: (): string => '/customers-api/sh/customers/',
    createCustomer: (): string => '/customers-api/sh/customers/',
    getCustomerById: (id: number): string => `/customers-api/sh/customers/${id}`,
    deleteCustomerById: (id: number): string => `/customers-api/sh/customers/${id}`,
    getContactsByCustomerId: (customer_id: number): string =>
      `/customers-api/sh/customers/${customer_id}/contacts`,
    updateCustomerContact: (): string => '/customers-api/sh/contacts/',
    createCustomerContact: (): string => '/customers-api/sh/contacts/',
    getCustomerContact: (id: number): string => `/customers-api/sh/contacts/${id}`,
    deleteCustomerContact: (id: number): string => `/customers-api/sh/contacts/${id}`,
    updateBillingInfo: (): string => '/customers-api/sh/billing/',
    createBillingInfo: (): string => '/customers-api/sh/billing/',
    getBillingInfoById: (id: number): string => `/customers-api/sh/billing/${id}`,
    removeBillingInfoById: (id: number): string => `/customers-api/sh/billing/${id}`,
  },
  WebRtcService: {
    webRtcOffer: (): string => '/webrtc-api/sh/offer',
  },

  InvoiceService: {
    getAllInvoices: (): string => '/invoices-api/sh/invoices/',
    updateInvoice: (): string => '/invoices-api/sh/invoices/',
    createInvoice: (): string => '/invoices-api/sh/invoices/',
    getInvoiceById: (id: number) => `/invoices-api/sh/invoices/${id}`,
    deleteInvoice: (id: number) => `/invoices-api/sh/invoices/${id}`,
    getAllItemsByInvoiceId: (id: number) => `/invoices-api/sh/invoices/${id}/items`,
    updateInvoiceItem: (): string => '/invoices-api/sh/invoices/items/',
    createInvoiceItem: (): string => '/invoices-api/sh/invoices/items/',
    getInvoiceItemById: (id: number) => `/invoices-api/sh/invoices/items/${id}`,
    deleteInvoiceItem: (id: number) => `/invoices-api/sh/invoices/items/${id}`,
    publishInvoice: (id: number) => `/invoices-api/sh/invoices/${id}/publish`,
  },
  ScheduleService: {
    getSchedulesByRange: (): string => '/schedule-api/sh/wo_schedule/',
    filterSchedulesByStatus: (): string => '/schedule-api/sh/wo_schedule/filter/status',
    filterSchedulesByName: (): string => '/schedule-api/sh/wo_schedule/filter/task',
  },
  DiscountService: {
    getAllCoupons: (): string => '/catalogs-api/sh/discount-coupons/',
    updateCoupon: (): string => '/catalogs-api/sh/discount-coupons/',
    createCoupon: (): string => '/catalogs-api/sh/discount-coupons/',
    getCouponById: (coupon_id: number): string => `/catalogs-api/sh/discount-coupons/${coupon_id}`,
    removeCouponById: (coupon_id: number): string =>
      `/catalogs-api/sh/discount-coupons/${coupon_id}/`,
    getCouponBuCatalogId: (catalog_id: number): string =>
      `/catalogs-api/sh/discount-coupons/${catalog_id}/`,
  },

  DocumentsService: {
    uploadDocument: (): string => '/documents-api/sh/documents/',
    getDocumentsList: (): string => '/documents-api/sh/documents/folders/',
    getDocumentTypes: (customer_uuid: string): string =>
      `/documents-api/sh/documents/folders/${customer_uuid}`,
    getDocumentById: (document_uuid: string): string =>
      `/documents-api/sh/documents/uuid/${document_uuid}`,
    deleteDocument: (document_id: number): string => `/documents-api/sh/documents/${document_id}`,
    getDocumentByIdAndSection: (customer_uuid: string, type: string): string =>
      `/documents-api/sh/documents/folders/${customer_uuid}/${type}/`,
  },

  SearchService: {
    searchInventory: (query: string): string => `/inventory-api/sh/parts/search/${query}`,
    searchCatalog: (): string => '/catalogs-api/sh/catalogs/search/',
    searchEstimate: (query: string): string => `/estimate-api/sh/estimate/search/${query}`,
    searchPurchaseRequisition: (): string => '/product-planning-api/sh/requisitions/search/',
    searchPurchaseOrder: (): string => '/product-planning-api/sh/purchase_orders/search/',
    searchAgreement: (): string => '/agreements-api/sh/agreements/search/',
    searchWorkOrder: (): string => '/work-orders-api/sh/work_order/search/',
    searchCustomer: (query: string): string => `/customers-api/sh/customers/search/${query}`,
    searchInvoice: (): string => '/invoices-api/sh/invoices/search/',
    searchUser: (query: string): string => `/auth-api/sh/user/search/${query}`,
  },

  TransactionService: {
    getAllTransactions: (): string => '/invoices-api/sh/invoices/transaction/',
  },
};
