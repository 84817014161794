import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'hooks';
import { industryOptions, subtitleOptions } from 'constants/BusinessTypes';
import { getSubtitleOptionsByIndustry, organizationFormGenerator, returnImgUrl } from 'utils';
import { getOrganizationById, updateOrganization } from 'store/slices/organizationsSlice/thunks';
import {
  Button,
  Select,
  CustomSkeleton,
  CustomTextField,
  ControlledInput,
  ControlledSelect,
  CompanyFileInput,
  NumberControlledInput,
} from 'components';
import {
  organizationByIdSelector,
  organizationByIdLoadingSelector,
  updatedOrganizationLoadingSelector,
} from 'store/slices/organizationsSlice/selectors';
import { stateOptions } from 'constants/States';
import { sizeOptions } from 'constants/sizeOptions';
import { ImgUrlFolders } from 'types/global/imgUrlFolders';
import { TOrganization } from 'store/slices/organizationsSlice/types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Colors, FontNames, type TChangeSelectEvent, type TFormSubmit } from 'types';

import styles from './Organization.module.scss';

const Organization = () => {
  const dispatch = useAppDispatch();

  const organizationData = useAppSelector(organizationByIdSelector);
  const organizationDataLoading = useAppSelector(organizationByIdLoadingSelector);
  const updatedOrganizationLoading = useAppSelector(updatedOrganizationLoadingSelector);

  const currentImage = returnImgUrl(
    ImgUrlFolders.PROFILE_INFO,
    String(organizationData?.media_url_id),
  );

  const [file, setFile] = useState<File | null>(null);
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const [activeValue, setActiveValue] = useState<string>(
    organizationData?.industry_vertical as string,
  );

  const [newSelectedValue, setNewSelectedValue] = useState<string>(
    organizationData?.industry_vertical as string,
  );

  useEffect(() => {
    setNewSelectedValue(String(organizationData?.business_type));
  }, [organizationData?.business_type]);

  useEffect(() => {
    setActiveValue(String(organizationData?.industry_vertical));
  }, [organizationData?.industry_vertical]);

  const getSetIsRemovedState = (isRemoved: boolean) => {
    setIsRemoved(isRemoved);
  };

  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(getOrganizationById(Number(currentOrganization)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const internalWidth = '100%';

  //Todo @Hamo
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const loadingStates = updatedOrganizationLoading || organizationDataLoading;

  const getFile = (file: File | null) => setFile(file);

  const secondOptions = getSubtitleOptionsByIndustry(activeValue, industryOptions, subtitleOptions);

  const handleFormChange: TFormSubmit = async (data) => {
    const formData = new FormData();

    if (currentOrganization && organizationData) {
      formData.append('email', data?.email || organizationData?.email);
      formData.append('address', data?.address || '');
      formData.append('name', data.organization);
      formData.append('industry_vertical', data.industryVertical);
      formData.append('business_type', data.typeOfBusiness);
      formData.append('size', data?.size);
      formData.append('is_active', String(organizationData?.is_active));
      formData.append('phone', String(data?.phone));
      formData.append('state', String(data?.state));
      formData.append('city', String(data?.city));
      formData.append('zip', String(data?.zip));
    }

    if (isRemoved) {
      formData.append('remove_image', 'true');
    }

    if (file) {
      formData.append('media', file);
    }

    await dispatch(updateOrganization(formData));

    setIsRemoved(false);
    setFile(null);
    dispatch(getOrganizationById(Number(currentOrganization)));
  };

  const {
    handleSubmit: organizationSubmit,
    control: organizationControl,
    watch,
    reset,

    formState: { errors: organizationError, isDirty, isSubmitting },
  } = useForm({
    values: organizationFormGenerator(organizationData as TOrganization),
  });

  const watchResult = watch();

  const { organization, typeOfBusiness, industryVertical } = watchResult;

  const isValidToChange =
    organization?.length &&
    typeOfBusiness?.length &&
    industryVertical?.length &&
    !!newSelectedValue;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fieldSkeletons = Array.from({ length: 5 })?.map((_, idx) => (
    <CustomSkeleton height='54px' width='100%' key={idx} borderRadius='4px' />
  ));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fieldSkeletonsSecond = Array.from({ length: 3 })?.map((_, idx) => (
    <CustomSkeleton height='56px' width='100%' key={idx} borderRadius='4px' />
  ));

  const somethingChanged = isDirty || !!file || isRemoved;

  return (
    <form className={styles.container} onSubmit={organizationSubmit(handleFormChange)}>
      <Box className={styles.container__content}>
        <Box className={styles.container__content__main}>
          <Box className={styles.container__content__main__grid}>
            {loadingStates ? (
              fieldSkeletons
            ) : (
              <>
                <Controller
                  name='organization'
                  control={organizationControl}
                  defaultValue=''
                  rules={{ required: 'Organization is required' }}
                  render={({ field }) => (
                    <CustomTextField
                      required
                      {...field}
                      padding='14px 16px'
                      borderColor={Colors.SOFT_SILVER}
                      labelColor={Colors.ENIGMATIC_MIDNIGHT}
                      label='Organization Name:'
                      fontFamily={FontNames.CIRCULAR_REG}
                      maxWidth={internalWidth}
                      placeholder='Organization Name:'
                      error={!!organizationError.organization}
                    />
                  )}
                />
                <Controller
                  name='email'
                  control={organizationControl}
                  defaultValue=''
                  rules={{ required: 'Organization email is required' }}
                  render={({ field }) => (
                    <CustomTextField
                      required
                      {...field}
                      padding='14px 16px'
                      labelColor={Colors.ENIGMATIC_MIDNIGHT}
                      borderColor={Colors.SOFT_SILVER}
                      label='Organization Email:'
                      fontFamily={FontNames.CIRCULAR_REG}
                      maxWidth={internalWidth}
                      placeholder='Organization Email:*'
                      error={!!organizationError.organization}
                    />
                  )}
                />
                <Controller
                  name='typeOfBusiness'
                  control={organizationControl}
                  defaultValue=''
                  rules={{ required: 'Type of business is required' }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      onChange={(event: TChangeSelectEvent) => {
                        onChange(event.target.value);
                        setNewSelectedValue(event.target.value);
                      }}
                      showLabel
                      lineHeight='unset'
                      padding='14px 16px'
                      value={value}
                      multiple={false}
                      options={secondOptions}
                      disabled={!activeValue}
                      label='Type of business'
                      fontFamily={FontNames.CIRCULAR_REG}
                      labelColor={Colors.ENIGMATIC_MIDNIGHT}
                      error={!!organizationError.typeOfBusiness || !typeOfBusiness}
                    />
                  )}
                />
                <ControlledSelect
                  name='size'
                  lineHeight='unset'
                  padding='14px 16px'
                  defaultValue=''
                  options={sizeOptions}
                  label='Organization Size:'
                  control={organizationControl}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
                <Controller
                  name='industryVertical'
                  control={organizationControl}
                  defaultValue=''
                  rules={{ required: 'Industry vertical is required' }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      padding='14px 16px'
                      onChange={(event: TChangeSelectEvent) => {
                        onChange(event.target.value);
                        setActiveValue(event.target.value);
                        setNewSelectedValue('');

                        reset({
                          typeOfBusiness: '',
                          industryVertical: event.target.value,
                          organization: organization,
                        });
                      }}
                      required
                      lineHeight='unset'
                      value={value}
                      defaultValue=''
                      multiple={false}
                      label='Industry :*'
                      maxWidth={internalWidth}
                      options={industryOptions}
                      fontFamily={FontNames.CIRCULAR_REG}
                      labelColor={Colors.ENIGMATIC_MIDNIGHT}
                      error={!!organizationError.industryVertical}
                    />
                  )}
                />
              </>
            )}
          </Box>
          <Box className={styles.container__content__main__address}>
            {loadingStates ? (
              <CustomSkeleton height='54px' width='100%' borderRadius='4px' />
            ) : (
              <ControlledInput
                name='address'
                label='Address'
                borderColor={Colors.SOFT_SILVER}
                padding='14px 16px'
                placeholder='Address'
                control={organizationControl}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                fontFamily={FontNames.CIRCULAR_REG}
              />
            )}

            <Box className={styles.container__content__main__address_row}>
              {loadingStates ? (
                fieldSkeletonsSecond
              ) : (
                <>
                  <ControlledInput
                    name='city'
                    padding='14px 16px'
                    label='City:'
                    borderColor={Colors.SOFT_SILVER}
                    placeholder='City:'
                    control={organizationControl}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    fontFamily={FontNames.CIRCULAR_REG}
                  />
                  <ControlledSelect
                    name='state'
                    label='State'
                    padding='14px 16px'
                    options={stateOptions}
                    control={organizationControl}
                    fontFamily={FontNames.CIRCULAR_REG}
                    backgroundColor={Colors.PLATINUM_GRAY}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                  <ControlledInput
                    name='zip'
                    label='Zip:'
                    placeholder='Zip:'
                    borderColor={Colors.SOFT_SILVER}
                    padding='14px 16px'
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                    control={organizationControl}
                    fontFamily={FontNames.CIRCULAR_REG}
                  />
                </>
              )}
            </Box>
            {loadingStates ? (
              <CustomSkeleton height='54px' width='100%' borderRadius='4px' />
            ) : (
              <NumberControlledInput
                name='phone'
                padding='14px 16px'
                borderColor={Colors.SOFT_SILVER}
                control={organizationControl}
                label='Organization Phone Number:'
                fontFamily={FontNames.CIRCULAR_REG}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                placeholder='Organization Phone Number:'
              />
            )}

            <div className={styles.container__upload}>
              {loadingStates ? (
                <CustomSkeleton height='108px' width='100%' borderRadius='4px' />
              ) : (
                <CompanyFileInput
                  forLogo
                  getFile={getFile}
                  borderRadius='12px'
                  borderStyle='dashed'
                  maxWidth='100%'
                  blockMaxWidth='100%'
                  containerMaxWidth='100%'
                  withBottomBlock={false}
                  getSetIsRemovedState={getSetIsRemovedState}
                  containerClassName={styles.container__upload__container}
                  containerBoxClassName={styles.container__upload__container__box}
                  imgSrc={organizationData?.media_url_id ? currentImage : null}
                  replaceClasses={styles.container__upload__container__box__replace}
                />
              )}
            </div>
          </Box>
        </Box>
      </Box>

      <div className={styles.container__button}>
        <Button
          type='submit'
          maxWidth='65px'
          variant='contained'
          isUppercase={false}
          borderRadius='4px'
          padding='12px 16px'
          minWidth='65px'
          backgroundColor={Colors.SAPPHIRE}
          disabled={!isValidToChange || !somethingChanged || isSubmitting}
        >
          Save
        </Button>
      </div>
    </form>
  );
};
export default Organization;
