import { Box } from '@mui/material';
import { FC, useEffect } from 'react';

import { useAppSelector, useUserOrganizationsList } from 'hooks';
import {
  organizationByIdSelector,
  needToFetchOrganizationSelector,
  organizationByIdLoadingSelector,
} from 'store/slices/organizationsSlice/selectors';
import { returnImgUrl } from 'utils';
import { ImgUrlFolders } from 'types';
import { LogoFooter, LogoIcon } from 'assets/icons';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { optionsSelector } from 'store/slices/optionsSlice/selectors';

import CustomSkeleton from '../CustomSkeleton';

import styles from './OrganizationLogo.module.scss';
import { TOrganizationLogoProps } from './types';

const OrganizationLogo: FC<TOrganizationLogoProps> = ({
  image,
  minWidth,
  maxWidth,
  onClick,
  fromList = false,
  height = '120px',
}) => {
  const { data, getAllOrganizations } = useUserOrganizationsList();

  const organizationData = useAppSelector(organizationByIdSelector);
  const needToFetchOrganizationList = useAppSelector(needToFetchOrganizationSelector);
  const organizationDataLoad = useAppSelector(organizationByIdLoadingSelector);
  const { isShowOrganizationLogoLoad } = useAppSelector(optionsSelector);

  useEffect(() => {
    getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToFetchOrganizationList]);

  const existedOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const organizationFromList = data?.find(
    (organization) => organization?.id === Number(existedOrganization),
  );

  const imgUrl = returnImgUrl(
    ImgUrlFolders.PROFILE_INFO,
    String(image || organizationFromList?.media_url_id),
  );

  if (organizationDataLoad && isShowOrganizationLogoLoad) {
    return (
      <Box className={styles.container} sx={{ minWidth, maxWidth, height }}>
        <CustomSkeleton width='100%' height='100%' />
      </Box>
    );
  }

  if (fromList) {
    return image ? (
      <img src={imgUrl} alt='logo' style={{ height: '50px', maxWidth: '50px', minWidth: '50x' }} />
    ) : (
      <LogoFooter />
    );
  }

  return (
    <>
      {(!organizationDataLoad || !isShowOrganizationLogoLoad) && (
        <Box
          onClick={onClick}
          className={styles.container}
          sx={{
            minWidth,
            maxWidth,
            height: organizationData?.media_url_id ? height : 'auto',
            cursor: 'pointer',
          }}
        >
          {organizationFromList?.media_url_id ? <img src={imgUrl} alt='logo' /> : <LogoIcon />}
        </Box>
      )}
    </>
  );
};

export default OrganizationLogo;
