import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { BrowserStorageKeys, BrowserStorageService } from 'services';

import {
  signUp,
  loginOAuth,
  signInAuth,
  getNewToken,
  getUserById,
  refreshToken,
  getUsersByRole,
  getAllUserRoles,
  getCurrentUser,
  getUserSettings,
  searchUserByQuery,
  updateUserSettings,
  getCurrentUserById,
  getUserPermissions,
} from './authThunks';
import { TInitialState, TUser, TUserNotificationSettings, TUserProfile } from './types';

const initialState: TInitialState = {
  users: [],
  roles: [],
  error: null,
  userData: null,
  loading: false,
  foundUsers: [],
  searchName: '',
  permissions: [],
  usersByRoles: [],
  userAllRoles: [],
  userDataError: null,
  currentUserById: null,
  allRolesLoading: false,
  refreshTOkenError: null,
  userDataLoading: false,
  currentUserError: null,
  userRolesLoading: false,
  currentUserLoading: false,
  notificationSetting: null,
  currentSingleUserById: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action: PayloadAction<TUserProfile>) => {
      state.loading = false;
      state.error = null;
      state.userData = action.payload;

      BrowserStorageService.set(
        BrowserStorageKeys.AccessToken,
        JSON.stringify(action.payload.token.access_token),
      );

      BrowserStorageService.set(
        BrowserStorageKeys.RefreshToken,
        JSON.stringify(action.payload?.token.access_token),
      );
    });

    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;

      BrowserStorageService.set(
        BrowserStorageKeys.AccessToken,
        JSON.stringify(action.payload?.access_token),
      );
    });

    builder.addCase(loginOAuth.fulfilled, (state, action: PayloadAction<TUserProfile>) => {
      state.loading = false;
      state.error = null;
      state.userData = action.payload;

      BrowserStorageService.set(
        BrowserStorageKeys.AccessToken,
        JSON.stringify(action.payload.token.access_token),
        { session: true },
      );

      BrowserStorageService.set(
        BrowserStorageKeys.RefreshToken,
        JSON.stringify(action.payload?.token.refresh_token),
        { session: true },
      );

      BrowserStorageService.set(
        BrowserStorageKeys.IsFirstTimeUser,
        JSON.stringify(action.payload?.is_first_time_user),
        { session: true },
      );

      BrowserStorageService.set(
        BrowserStorageKeys.IsInvitedUser,
        JSON.stringify(action.payload?.is_invited_user),
        { session: true },
      );

      BrowserStorageService.set(
        BrowserStorageKeys.CurrentUserEmail,
        JSON.stringify(action.payload?.email),
        { session: true },
      );
    });

    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.userDataLoading = false;
      state.error = null;
      state.userData = action.payload;
    });

    builder.addCase(getAllUserRoles.fulfilled, (state, action) => {
      state.userAllRoles = action.payload;
      state.userRolesLoading = false;
    });

    builder.addCase(getAllUserRoles.pending, (state) => {
      state.userRolesLoading = true;
    });

    builder.addCase(getUsersByRole.fulfilled, (state, action) => {
      state.usersByRoles = action.payload;
    });

    builder.addCase(getNewToken.pending, (state) => {
      state.refreshTOkenError = null;
    });

    builder.addCase(getNewToken.fulfilled, (state) => {
      state.refreshTOkenError = null;
    });
    builder.addCase(getNewToken.rejected, (state, action) => {
      state.refreshTOkenError = action.error?.message as string;
    });

    builder.addCase(getCurrentUserById.fulfilled, (state, action) => {
      state.currentSingleUserById = action.payload;
    });

    builder.addCase(
      getUserSettings.fulfilled,
      (state, action: PayloadAction<TUserNotificationSettings>) => {
        state.notificationSetting = action.payload;
      },
    );

    builder.addCase(
      updateUserSettings.fulfilled,
      (state, action: PayloadAction<TUserNotificationSettings>) => {
        state.notificationSetting = action.payload;
      },
    );

    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.userDataLoading = false;
      state.userDataError = action?.error?.message as string;
    });

    builder.addCase(getCurrentUser.pending, (state) => {
      state.userDataLoading = true;
      state.userDataError = null;
    });

    builder.addCase(signInAuth.fulfilled, (state, action: PayloadAction<TUserProfile>) => {
      state.userData = action.payload;

      BrowserStorageService.set(
        BrowserStorageKeys.AccessToken,
        JSON.stringify(action.payload.token.access_token),
      );

      BrowserStorageService.set(
        BrowserStorageKeys.RefreshToken,
        JSON.stringify(action.payload.token.refresh_token),
      );
    });

    builder.addCase(signInAuth.rejected, (state, action) => {
      state.error = action.error as Error;
      state.loading = false;
    });

    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.currentUserById = action.payload;
      state.currentUserLoading = false;
    });

    builder.addCase(getUserById.pending, (state) => {
      state.currentUserLoading = true;
      state.currentUserError = null;
    });

    builder.addCase(getUserById.rejected, (state, action) => {
      state.currentUserError = action.payload as string;
      state.currentUserLoading = false;
    });

    builder.addCase(searchUserByQuery.fulfilled, (state, action: PayloadAction<TUser[]>) => {
      state.loading = false;
      state.error = null;
      state.foundUsers = action.payload;
    });

    builder.addCase(searchUserByQuery.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(searchUserByQuery.rejected, (state, action) => {
      state.error = action.payload as string;
      state.loading = false;
    });

    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
      state.loading = false;
      state.error = null;
    });
  },
});

export default authSlice.reducer;
