import { GridColDef } from '@mui/x-data-grid';
import classNames from 'classnames';
import { useState } from 'react';

import { CustomDataGrid, ScrollLayout, TableCell } from 'components';
import { useAppSelector } from 'hooks';
import { currentSectionVersionsSelector } from 'store/slices/catalogSlice/selectors';
import { EmptyTitles } from 'constants/EmptyTitles';
import { sortByKey } from 'utils/sortByKey';

import { columns } from './PaymentViewTable.utils';
import styles from './PaymentViewTable.module.scss';
import { TKeyValues } from './types';

const PaymentViewTable = () => {
  const versions = useAppSelector(currentSectionVersionsSelector);

  const [asc, setAsc] = useState<boolean>(false);
  const [newSortBy, setNewSortBy] = useState<string | null>('name');

  const renderColumns: GridColDef[] = columns.map((column) => {
    const isColumnSorted = column.field ? newSortBy === column.field : 'name';

    const headerClasses = classNames(
      { [styles.activeSortHeader]: !asc },
      {
        [styles.activeSortHeader_asc]: asc,
      },
    );

    return {
      ...column,
      hideSortIcons: true,
      sortable: true,
      disableColumnMenu: true,
      disableColumnSelector: true,
      headerClassName: isColumnSorted ? headerClasses : '',
    };
  });

  const array = versions?.payment_schedule?.schedule?.stages;

  const keyValues: TKeyValues = {
    workCompletion: 'percentage_complete',
    name: 'name',
    paymentPercentage: 'percentage_complete',
  };

  const sortedData =
    sortByKey(array || [], keyValues[newSortBy as keyof TKeyValues] as any, asc) || [];

  const rows = sortedData?.map((item, idx) => ({
    id: `${item?.percentage_amount}/${idx}`,
    name: <TableCell title={item?.name} />,
    workCompletion: <TableCell title={`${item?.percentage_complete}%`} />,
    paymentPercentage: <TableCell title={`${item?.percentage_amount}%`} />,
  }));

  const handleColumnHeaderClick = (column: GridColDef) => {
    setNewSortBy(column.field);
    setAsc((prev) => !prev);
  };

  return (
    <div>
      <ScrollLayout>
        <CustomDataGrid
          columns={renderColumns}
          rows={rows || []}
          sortModel={[{ field: newSortBy || 'name', sort: asc ? 'asc' : 'desc' }]}
          onColumnHeaderClick={handleColumnHeaderClick}
          emptyTitle={EmptyTitles.Catalog_Payment_Schedules}
        />
      </ScrollLayout>
    </div>
  );
};

export default PaymentViewTable;
