import { FC } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { FontNames } from 'types';
import { CustomTypography } from 'components';
import { BorderSquareIcon } from 'assets/icons';

import styles from './ModalBoxHeader.module.scss';

import type { TModalBoxHeaderProps } from './types';

const ModalBoxHeader: FC<TModalBoxHeaderProps> = ({
  title,
  onClose,
  className,
  fontFamily = FontNames.CIRCULAR_REG,
}) => {
  const containerClasses = classNames(styles.container, className);

  return (
    <Box className={containerClasses}>
      <CustomTypography className={styles.container__title} sx={{ fontFamily }}>
        {title}
      </CustomTypography>
      <BorderSquareIcon width={24} height={24} onClick={onClose} />
    </Box>
  );
};

export default ModalBoxHeader;
