import { FC, Fragment } from 'react';
import { Box, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import breakPoints from 'constants/BreakPoints';
import { useAppSelector, useAppDispatch, useWindowSize } from 'hooks';
import { HourOptions, TimeOptions } from 'constants/States';
import {
  Button,
  DrawerLayout,
  ControlledSelect,
  NumberControlledInput,
  ControlledInput,
  ControlledFormPicker,
} from 'components';
import { updateTask } from 'store/thunks';
import { Colors, FontNames, TFormSubmit } from 'types';
import { currentTaskByIdSelector } from 'store/slices/workOrderSlice/selectors';
import { TOption } from 'components/shared/Select/types';
import { TTaskUpdateBody } from 'store/slices/workOrderSlice/types';
import { WorkOrderToastMessages } from 'constants/ToastMessages';

import { formDataTask } from './utils';
import styles from './CreateCustomDrawer.module.scss';

import type { TTaskEditDrawerProps, TTaskForm } from './types';

const TaskEditDrawer: FC<TTaskEditDrawerProps> = ({ onEdit, options, handleClose, open }) => {
  const currentTask = useAppSelector(currentTaskByIdSelector);
  const dispatch = useAppDispatch();

  // const [typedValue, setTypedValue] = useState<string>('');

  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 376
      : Number(width) <= breakPoints.TABLET_M && Number(width) >= breakPoints.MOBILE
      ? 275
      : Number(width) <= breakPoints.MOBILE
      ? 175
      : 423;

  const getValue = (value?: string | number | Date | TOption) => {
    return value ? String(value) : '';
  };

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = useForm<TTaskForm>({
    values: {
      task: {
        tech_name: getValue(currentTask?.employee_name),
        description: getValue(currentTask?.description),
        sequence: getValue(currentTask?.sequence),
        start_date: getValue(currentTask?.start_date),
        end_date: getValue(currentTask?.end_date),
        daily_hours: getValue(currentTask?.daily_hours),
        start_time: getValue(currentTask?.start_time).substring(0, 5),
      },
    },
  });

  const elementsTask = formDataTask?.map(({ type, name, ...rest }, index) => {
    // const splitStrings: string[] = name.split('.');

    // const result: string[] = splitStrings?.slice(1);
    // const errorItem: any = errors?.customer?.[result[0] as keyof TCustomerFormData];

    let element;
    if (rest.label) {
      switch (type) {
        case 'ControlledInput':
          element = (
            <ControlledInput
              name={name}
              control={control}
              required={rest.required}
              label={rest.label}
              //helperText={errorItem?.message}
              // withHelperText
              //error={!!errorItem}
              borderColor={rest.borderColor}
              borderRadius={rest.borderRadius}
              backgroundColor={rest.backgroundColor}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              placeholder={rest.placeholder as string}
              fontFamily={rest.fontFamily as FontNames}
            />
          );
          break;

        case 'NumberControlledInput':
          element = (
            <NumberControlledInput
              required
              name={name}
              control={control}
              label={rest.label}
              // error={!!errorItem}
              // helperText={errorItem?.message}
              borderColor={rest.borderColor}
              borderRadius={rest.borderRadius}
              backgroundColor={rest.backgroundColor}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
              placeholder={rest.placeholder as string}
              fontFamily={rest.fontFamily as FontNames}
            />
          );
          break;

        case 'ControlledSelectHour':
          element = (
            <ControlledSelect
              control={control}
              required
              name={name}
              // errors={!!errorItem}
              border={rest.border}
              options={HourOptions}
              label={rest.defaultValue}
              // setValue={setTypedValue}
              borderRadius={rest.borderRadius}
              backgroundColor={rest.backgroundColor}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />
          );
          break;
        case 'ControlledSelectTime':
          element = (
            <ControlledSelect
              control={control}
              required
              name={name}
              // errors={!!errorItem}
              border={rest.border}
              options={TimeOptions}
              label={rest.defaultValue}
              // setValue={setTypedValue}
              borderRadius={rest.borderRadius}
              backgroundColor={rest.backgroundColor}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />
          );
          break;
        case 'ControlledSelectEmployee':
          element = (
            <ControlledSelect
              control={control}
              required
              name={name}
              // errors={!!errorItem}
              border={rest.border}
              options={options}
              label={rest.defaultValue}
              // setValue={setTypedValue}
              borderRadius={rest.borderRadius}
              backgroundColor={rest.backgroundColor}
              labelColor={Colors.ENIGMATIC_MIDNIGHT}
            />
          );
          break;
        case 'ControlledPicker':
          element = (
            <ControlledFormPicker
              disablePast={false}
              padding='0 8px'
              label={rest.label}
              name={name}
              control={control}
              // error={specificError}
              backgroundColor={Colors.WHITE}
              className={styles.container__picker}
              // helperText={specificErrorMessage || specificError?.message}
            />
          );
          break;

        default:
          element = null;
      }
    }
    return <Fragment key={index}>{element}</Fragment>;
  });

  const watchResult = watch();

  const submit = async () => {
    if (currentTask) {
      const emp = options.find((emp) => emp.label === watchResult.task.tech_name);

      const tasks_data: TTaskUpdateBody[] = [
        {
          technician_id: Number(emp?.id),
          start_time: watchResult.task.start_time,
          description: watchResult.task.description,
          start_date: new Date(watchResult.task.start_date).toISOString().split('T')[0],
          end_date: new Date(watchResult.task.end_date).toISOString().split('T')[0],
          daily_hours: Number(watchResult.task.daily_hours),
          work_order_id: currentTask?.work_order_id,
          sequence: Number(watchResult.task.sequence),
          status: currentTask?.status,
          id: currentTask?.id,
        },
      ];
      const start = toast.loading(WorkOrderToastMessages.TASK_UPDATE_START);

      try {
        await dispatch(updateTask(tasks_data)).then(() => {
          toast.update(start, {
            render: WorkOrderToastMessages.TASK_UPDATE_SUCCESS,
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
          handleClose();
          onEdit();
          reset();
        });
      } catch (error) {
        toast.update(start, {
          render: WorkOrderToastMessages.TASK_UPDATE_FAILURE,
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  };

  const handleCreateData: TFormSubmit = async () => {
    handleClose();
    reset();
  };

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      width={drawerWidth}
      titleClassName={styles.container__head__title}
      headerTitle='Task Information'
      onClose={handleClose}
    >
      <form className={styles.container} onSubmit={handleSubmit(handleCreateData)}>
        <Box>
          <Box className={styles.container__grid}>{elementsTask}</Box>
        </Box>
        <Stack alignItems='flex-end' justifyContent='flex-end' direction='row' gap='24px'>
          <Button
            type='button'
            maxWidth='65px'
            borderRadius='4px'
            padding='12px 16px'
            isUppercase={false}
            onClick={handleClose}
            color={Colors.SAPPHIRE}
            backgroundColor={Colors.PALE_BLUE}
          >
            Cancel
          </Button>
          <Button
            type='button'
            color='white'
            maxWidth='65px'
            borderRadius='4px'
            padding='12px 16px'
            isUppercase={false}
            backgroundColor={Colors.SAPPHIRE}
            disabled={isSubmitting}
            onClick={submit}
          >
            Update
          </Button>
        </Stack>
      </form>
    </DrawerLayout>
  );
};

export default TaskEditDrawer;
