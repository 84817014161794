import { FC, useEffect, useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import StopIcon from '@mui/icons-material/Stop';

import { Colors, Routes } from 'types';
import { Button, CustomTypography } from 'components';
import { RecordLiveIcon, MicrophoneLiveIcon, MicrophoneLiveActiveIcon } from 'assets/icons';
import { myCurrentStreamSelector } from 'store/slices/liveStreamSlice/selector';
import { useTime, useRecording, useAppSelector, useAppDispatch, usePageReloadHandler } from 'hooks';
import {
  endLiveStream,
  getLiveStreamsById,
  getMyCurrentStream,
} from 'store/slices/liveStreamSlice/thunks';
import { ffmPegLoad } from 'utils/ffmpegLoad';
import Stream from 'components/views/Stream/Stream';
import { LocalForageKeys } from 'services/localForage/types';
import { BrowserStorageKeys, BrowserStorageService, LocalForageService } from 'services';
import { userDataSelector, userPermissionsSelector } from 'store/slices/authSlice/selectors';

import styles from './LiveStream.module.scss';
import StreamHeader from './StreamHeader/StreamHeader';

import type { TLiveProps } from './types';

const Live: FC<TLiveProps> = ({ openNoticeModal, contentRef, error }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const currentStream = useAppSelector(myCurrentStreamSelector);
  const userInfo = useAppSelector(userDataSelector);
  const { isAccessToLivestream } = useAppSelector(userPermissionsSelector);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const websocketRef = useRef<WebSocket | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const [currentStreamData, setCurrentStreamData] = useState<MediaStream | null>(null);
  const [hostStreamHasEnded, setHostStreamHasEnded] = useState<boolean>(false);

  useEffect(() => {
    ffmPegLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePageReloadHandler();

  const getCurrentStreamData = (stream: MediaStream | null) => setCurrentStreamData(stream);

  const [isMuted, setIsMuted] = useState<boolean>(true);

  const { startTimer, restartTimer, stopTimer, time, durationMs, pauseTimer } = useTime(false);

  const {
    startRecording,
    stopRecording,
    isRecording,
    pauseRecording,
    isPaused,

    sendVideoToBackend,
  } = useRecording({
    stream: currentStreamData,
    time,
    stopTimer,
    startTimer,
    durationMs,
    pauseTimer,
    restartTimer,
    contentRef,
  });

  const createdLiveStreamId = BrowserStorageService.get(BrowserStorageKeys.currentLiveStreamId, {
    session: true,
  });

  useEffect(() => {
    if (currentStream?.livestream?.id) {
      dispatch(getLiveStreamsById(Number(currentStream?.livestream?.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStream?.livestream?.id]);

  useEffect(() => {
    dispatch(getMyCurrentStream());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    stopTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const voiceToggle = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getAudioTracks().forEach((track) => {
        track.enabled = !isMuted;
      });

      setIsMuted(!isMuted);
    }
  };

  const endStreaming = async () => {
    try {
      if (websocketRef.current) {
        websocketRef.current.close();
        websocketRef.current = null;
      }

      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => {
          track.stop();
        });
        mediaStreamRef.current = null;
      }

      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error ending streaming:', error);
    }
  };

  const endStream = async () => {
    if (hostStreamHasEnded) return;
    await stopRecording();

    const videoFile = await LocalForageService.get(LocalForageKeys.StreamVideoFiles);

    if (videoFile && videoFile?.size > 5) {
      stopTimer();

      sendVideoToBackend(videoFile);
    }

    setHostStreamHasEnded(true);

    if (websocketRef?.current) {
      websocketRef.current?.send(JSON.stringify({ event: 'end-stream' }));
    }

    const response = await dispatch(
      endLiveStream(Number(createdLiveStreamId || currentStream?.livestream?.id)),
    );

    if (response?.meta?.requestStatus === 'fulfilled') {
      const response = await dispatch(getMyCurrentStream()).unwrap();

      if (!response) {
        await endStreaming();

        BrowserStorageService.remove(BrowserStorageKeys.currentLiveStreamId, { session: true });
        BrowserStorageService.remove(BrowserStorageKeys.currentLiveStreamUUID, { session: true });
        BrowserStorageService.remove(BrowserStorageKeys.currentLiveStreamToken);
        await LocalForageService.remove(LocalForageKeys.StreamVideoFiles);
        await LocalForageService.remove(LocalForageKeys.CapturedThumb);

        if (isAccessToLivestream) {
          navigate(Routes.LiveStream);
        } else {
          navigate(Routes.ActivityFeed);
        }
      }

      const stream = videoRef?.current?.srcObject as MediaStream;

      const tracks = stream?.getTracks() || [];

      tracks.forEach((track) => track.stop());
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStartRecord = () => {
    startRecording();
  };

  useEffect(() => {
    if (error) {
      endStreaming();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (error) {
    return <Navigate to={isAccessToLivestream ? Routes.LiveStream : Routes.ActivityFeed} />;
  }

  return (
    <>
      <Box className={styles.container__head}>
        <Box className={styles.container__head__info}>
          <Box className={styles.container__head__info__label}>Live Stream</Box>
          <Box className={styles.container__head__info__title}>
            {currentStream?.livestream?.title}
          </Box>
          <div style={{ width: '138px' }} />
        </Box>
        <StreamHeader openNoticeModal={openNoticeModal} />
      </Box>

      <Box className={styles.container__content}></Box>
      <Box className={styles.container__content__live}>
        <Stream
          endStream={endStream}
          isMuted={isMuted}
          canvasRef={canvasRef}
          isVideoOpen={false}
          contentRef={contentRef}
          localVideoRef={videoRef}
          webSocketRef={websocketRef}
          mediaStreamRef={mediaStreamRef}
          peerConnectionRef={peerConnectionRef}
          getCurrentStreamData={getCurrentStreamData}
        />
      </Box>
      <Box className={styles.container__content__footer}>
        <Box className={styles.container__content__footer__controls}>
          <Button
            backgroundColor={Colors.SAPPHIRE}
            borderRadius='4px'
            padding='12px 16px'
            className={styles.container__content__footer__mute}
            startIcon={
              !isMuted ? (
                <MicrophoneLiveIcon width={20} height={20} />
              ) : (
                <MicrophoneLiveActiveIcon width={20} height={20} />
              )
            }
            onClick={voiceToggle}
          />
        </Box>
        <Stack direction='row' gap='24px'>
          <Box className={styles.container__content__box}>
            {isRecording || isPaused ? (
              <Button
                startIcon={isPaused ? <StopIcon style={{ fill: 'red' }} /> : <RecordLiveIcon />}
                onClick={isPaused ? startRecording : pauseRecording}
              >
                <CustomTypography className={styles.container__content__box__title}>
                  {time}
                </CustomTypography>
              </Button>
            ) : (
              <Button
                startIcon={<RecordLiveIcon />}
                onClick={handleStartRecord}
                disabled={!currentStreamData}
              >
                <CustomTypography className={styles.container__content__box__title}>
                  Record
                </CustomTypography>
              </Button>
            )}
          </Box>
          {userInfo?.id === currentStream?.livestream?.created_by && (
            <Button
              borderRadius='4px'
              padding='11px 8px'
              color={Colors.WHITE}
              onClick={endStream}
              isUppercase={false}
              backgroundColor={Colors.SAPPHIRE}
            >
              End stream
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Live;
