import { FC } from 'react';
import classNames from 'classnames';

import { FontNames } from 'types';
import { TableSortIcon } from 'assets/icons';

import styles from './HeaderTitle.module.scss';

import type { THeaderTitleProps } from './types';

const HeaderTitle: FC<THeaderTitleProps> = ({
  title,
  style,
  fromInvoice,
  withSortIcons = true,
  fontFamily = FontNames.CIRCULAR_BOLD,
}) => {
  const containerClasses = classNames(styles.container, {
    [styles.container__invoice]: fromInvoice,
  });

  return (
    <div
      style={{ ...style, fontFamily, cursor: withSortIcons ? 'pointer' : 'default' }}
      className={containerClasses}
    >
      {title}{' '}
      {withSortIcons && <TableSortIcon width={16} height={16} className={styles.container__icon} />}
    </div>
  );
};

export default HeaderTitle;
