import { FC, SyntheticEvent, useState } from 'react';
import { Box } from '@mui/material';

import { ImgUrlFolders } from 'types';
import { ImageBlank } from 'assets/images';
import IconOutline from 'components/shared/IconOutline';
import { useAppSelector, useMediaFetching } from 'hooks';
import { ImgWithDefault, PortalDropDown } from 'components';
import { userAllRolesSelector, userPermissionsSelector } from 'store/slices/authSlice/selectors';
import { EditIconOutLined, FilledCheckedIcon, HideIcon, MoreIcon, TrashIcon } from 'assets/icons';

import styles from './InventoryCard.module.scss';
import InventoryCardDescription from './InventoryCardDescription';

import type { TInventoryCardProps } from './types';

const InventoryCardNew: FC<TInventoryCardProps> = ({
  id,
  cost,
  title,
  inventory,
  checked,
  onDelete,
  onClick,
  imageUrl,
  showInfo,
  quantityLeft,
  openDrawerInEditMode,
}) => {
  const { isAccessToInventoryEditor } = useAppSelector(userPermissionsSelector);

  const userRoles = useAppSelector(userAllRolesSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const { mediaSource } = useMediaFetching({
    imgId: imageUrl,
    folderName: ImgUrlFolders.INVENTORY,
    withCashing: false,
  });

  const dropdownItems = [
    {
      id: 1,
      icon: <EditIconOutLined />,
      name: 'Edit',
      action: () => openDrawerInEditMode?.(id),
    },
    {
      id: 3,
      icon: <TrashIcon />,
      name: 'Delete',
      action: () => onDelete(),
    },
    {
      id: 2,
      icon: <HideIcon />,
      name: 'See Details',
      action: () => showInfo?.(id),
    },
  ];

  const dropdownItemsHaveInCatalog = [
    {
      id: 1,
      icon: <EditIconOutLined />,
      name: 'Edit',
      action: () => openDrawerInEditMode?.(id),
    },

    {
      id: 2,
      icon: <HideIcon />,
      name: 'See Details',
      action: () => showInfo?.(id),
    },
  ];

  const renderedItems = inventory?.in_catalog ? dropdownItemsHaveInCatalog : dropdownItems;

  const getDropdownItems = () => {
    const items = renderedItems.filter((item) => {
      if (item.name !== 'See Details') {
        return !isViewer;
      }

      return true;
    });

    return items;
  };

  const handleOnClick = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (!isOpened) {
      onClick?.();
    }
  };

  const getDropDownState = (state: boolean) => setIsOpened(state);

  return (
    <Box
      className={styles.container}
      onClick={(event) => isAccessToInventoryEditor && handleOnClick(event)}
    >
      {checked && (
        <Box className={styles.container__checked}>
          <IconOutline>
            <FilledCheckedIcon />
          </IconOutline>
        </Box>
      )}
      <Box className={styles.container__menu}>
        <PortalDropDown
          getDropDownState={getDropDownState}
          title={<MoreIcon style={{ display: 'flex' }} />}
          options={getDropdownItems()}
        />
      </Box>
      <div className={styles.container__image}>
        <ImgWithDefault
          fallback={ImageBlank}
          src={mediaSource as string}
          style={{ height: 172, objectFit: 'cover' }}
        />
      </div>
      <p className={styles.container__title}>{title}</p>
      <div className={styles.container__content}>
        <InventoryCardDescription title='Name' subTitle={inventory?.name} />
        <InventoryCardDescription title='UPC/SKU' subTitle={inventory?.sku} />
        <InventoryCardDescription title='Cost' subTitle={`${cost}`} />
        <InventoryCardDescription title='Quantity left' subTitle={String(quantityLeft)} />
      </div>
    </Box>
  );
};

export default InventoryCardNew;
