import { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { TDataReturn } from 'types';

type TUseLoadMoreParams<T> = {
  callback: ({
    limit,
    offset,
  }: {
    limit: number;
    offset: number;
  }) => Promise<AxiosResponse<TDataReturn<T>>>;
};

const useLoadMore = <T>({ callback }: TUseLoadMoreParams<T>) => {
  const initialLimit = 10;
  const [limit, setLimit] = useState<number>(initialLimit);
  const [offset, setOffset] = useState<number>(0);
  const [data, setData] = useState<TDataReturn<T>>({ data: [], total_count: 0 });
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null>(null);

  const getData = async () => {
    try {
      setIsLoad(true);

      const response = await callback({ limit, offset });

      const returnedData = response.data as TDataReturn<T>;

      setIsLoad(false);

      if (returnedData?.data?.length) {
        setData((prevData) => ({
          data: [...prevData.data, ...returnedData.data],
          total_count: returnedData.total_count,
        }));

        setOffset((prevOffset) => prevOffset + limit);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      setError(axiosError);
    }
  };

  const noLoad = data?.data?.length === data?.total_count;

  const loadMore = () => {
    if (!isLoad && !noLoad) {
      getData();
    }
  };

  useEffect(() => {
    setOffset(0);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  return { data, isLoad, error, loadMore, setLimit, noLoad };
};

export default useLoadMore;
