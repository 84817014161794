import { TOption } from 'components/shared/Select/types';
import { Colors, FontNames } from 'types';
import { HourOptions, TimeOptions } from 'constants/States';

export const customerData: TOption[] = [
  { id: 1, value: 'organization', label: 'Organization' },
  { id: 2, value: 'individual', label: 'Individual' },
];

export const formDataTask = [
  {
    type: 'ControlledSelectEmployee',
    required: true,
    name: 'task.tech_name',
    label: 'Name:',
    borderRadius: '8px',
    borderColor: '#EDEFF1',
    backgroundColor: '#FCFCFC',
    fontFamily: FontNames.CIRCULAR_REG,
    placeholder: 'Technician Name',
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
    options: HourOptions,
    border: '1px solid #EDEFF1',
    defaultValue: 'Employee:*',
  },
  {
    type: 'ControlledInput',
    required: true,
    name: 'task.description',
    label: 'Description:',
    borderRadius: '8px',
    borderColor: '#EDEFF1',
    backgroundColor: '#FCFCFC',
    fontFamily: FontNames.CIRCULAR_REG,
    placeholder: 'Task Description',
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledInput',
    required: true,
    label: 'Sequence:',
    borderColor: '#EDEFF1',
    backgroundColor: '#FCFCFC',
    placeholder: 'Task Sequence:*',
    borderRadius: '8px',
    fontFamily: FontNames.CIRCULAR_REG,
    name: 'task.sequence',
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledSelectHour',
    name: 'task.daily_hours',
    borderRadius: '8px',
    options: HourOptions,
    backgroundColor: '#FCFCFC',
    border: '1px solid #EDEFF1',
    defaultValue: 'Daily Hours:*',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledSelectTime',
    name: 'task.start_time',
    label: 'start Time',
    borderRadius: '8px',
    options: TimeOptions,
    backgroundColor: '#FCFCFC',
    border: '1px solid #EDEFF1',
    defaultValue: 'Start Time:*',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
  {
    type: 'ControlledPicker',
    name: 'task.start_date',
    borderRadius: '8px',
    backgroundColor: '#FCFCFC',
    border: '1px solid #EDEFF1',
    label: 'Task Start Date:*',
    defaultValue: 'dfd',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },

  {
    type: 'ControlledPicker',
    name: 'task.end_date',
    borderRadius: '8px',
    backgroundColor: '#FCFCFC',
    border: '1px solid #EDEFF1',
    label: 'Task End Date:*',
    defaultValue: 'dfd',
    fontFamily: FontNames.CIRCULAR_REG,
    labelColor: Colors.ENIGMATIC_MIDNIGHT,
  },
];
