import React from 'react';

import CustomTypography from '../Typography';

import styles from './TitleSubTitle.module.scss';

import type { TTitleSubTitle } from './types';

const TitleSubTitle: React.FC<TTitleSubTitle> = ({ title, subTitle, style }) => (
  <div className={styles.container} style={style}>
    <CustomTypography className={styles.container_title}>{title}</CustomTypography>
    <CustomTypography className={styles.container_subtitle}>{subTitle}</CustomTypography>
  </div>
);

export default TitleSubTitle;
