import { TOption } from 'components/shared/Select/types';
import { BusssinesType } from 'constants/BusinessTypes/types';

const getSubtitleOptionsByIndustry = (
  industry: string,
  industryOptions: TOption[],
  subtitleOptions: Record<BusssinesType, { value: string; label: string }[]>,
): TOption[] => {
  const industryOption = industryOptions.find((option) => option.value === industry);

  if (industryOption) {
    return subtitleOptions[industry as BusssinesType];
  }

  return [];
};

export default getSubtitleOptionsByIndustry;
