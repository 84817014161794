import { FC } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import localforage from 'localforage';

import { Routes } from 'types';

import styles from './Dropdown.module.scss';

import type { TDropdownProps } from './types';

const Dropdown: FC<TDropdownProps> = ({ options, withIcon = true, className, onClick }) => {
  const dropDownClasses = classNames(styles.container, className);

  const navigate = useNavigate();

  const navigationWithPath = (path: Routes) => {
    if (path === Routes.Home || path === Routes.Landing) {
      localStorage.clear();
      sessionStorage.clear();
      localforage.clear();
    }

    navigate(path);
  };

  const itemClick = (path: Routes) => {
    if (path) {
      navigationWithPath(path);
    } else {
      onClick?.();
    }
  };

  const optinsRenderer = options.map(({ id, content, Icon, path }) => {
    return (
      <div
        role='button'
        className={styles.container__box}
        key={id}
        onClick={() => itemClick(path as Routes)}
      >
        {withIcon && Icon}
        <div className={styles.container__box_text} role='button'>
          {content}
        </div>
      </div>
    );
  });

  return <div className={dropDownClasses}>{optinsRenderer}</div>;
};

export default Dropdown;
