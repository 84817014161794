import { useEffect, useState } from 'react';

const useTime = (shouldStartInitially = true) => {
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(shouldStartInitially);
  const [pausedTime, setPausedTime] = useState<number | null>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isActive) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!isActive && seconds !== 0 && interval) {
      clearInterval(interval as NodeJS.Timeout);
    }

    return () => clearInterval(interval as NodeJS.Timeout);
  }, [isActive, seconds]);

  const startTimer = () => {
    if (pausedTime !== null) {
      setSeconds(pausedTime);
      setPausedTime(null);
    }
    setIsActive(true);
  };

  const stopTimer = () => {
    setIsActive(false);
    setSeconds(0);
    setPausedTime(0);
  };

  const pauseTimer = () => {
    if (isActive) {
      setPausedTime(seconds);
      setIsActive(false);
    }
  };

  const restartTimer = () => {
    setIsActive(false);
    setSeconds(0);
  };

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(
      secs,
    ).padStart(2, '0')}`;
  };

  return {
    durationMs: seconds * 1000,
    time: formatTime(seconds),
    isActive,
    startTimer,
    stopTimer,
    pauseTimer,
    restartTimer,
  };
};

export default useTime;
