import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const itemUsageColumns: GridColDef[] = [
  {
    field: 'catalog',
    headerName: 'Catalog Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return (
        <TableCell
          isTooltipDisabled
          title={params?.value}
          style={{ padding: 0, color: '#2067DD' }}
        />
      );
    },
  },
  {
    field: 'number',
    headerName: 'Catalog Number',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ paddingLeft: 0 }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: 0 }} />;
    },
  },
  {
    field: 'createdBy',
    headerName: 'Created By',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: 0 }} />;
    },
  },

  {
    field: 'dateModified',
    headerName: 'Date Modified',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ paddingLeft: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell title={params?.value} style={{ padding: 0 }} />;
    },
  },
];

export const mockUsageList = [
  {
    id: 1,
    catalog: 'Company name',
    number: 'V1',
    adress: '6391 Elgin St. Celina,Delaware 10299',
    createdBy: 'Darlene Robertson',
    modifiedBy: '(505) 555-0125',
    dateModified: 'example@gmail.com',
  },
  {
    id: 2,
    catalog: 'Company name',
    number: 'V1',
    adress: '6391 Elgin St. Celina,Delaware 10299',
    createdBy: 'Darlene Robertson',
    modifiedBy: '(505) 555-0125',
    dateModified: 'example@gmail.com',
  },
  {
    id: 3,
    catalog: 'Company name',
    number: 'V1',
    adress: '6391 Elgin St. Celina,Delaware 10299',
    createdBy: 'Darlene Robertson',
    modifiedBy: '(505) 555-0125',
    dateModified: 'example@gmail.com',
  },
];
