import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { requisitionsApi } from 'api';
import {
  PurchaseRequisitionToastMessages,
  PurchaseRequisitionToastMessagesNew,
} from 'constants/ToastMessages';
import { RootState } from 'types';

import type {
  TRequisitionModify,
  TRequisitionsParams,
  TRequisitionUpdateBody,
  TCreateRequisitionBody,
  TAddRequisitionItemBody,
  TDeleteRequisitionOptions,
} from './types';

export const getRequisitions = createAsyncThunk(
  'requisitionSlice/getRequisitions',
  async (options: TRequisitionsParams) => {
    try {
      const response = await requisitionsApi.getAllRequisitionsRequest(options);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const createRequisitions = createAsyncThunk(
  'requisitionSlice/createRequisitions',
  async (body: TCreateRequisitionBody[], { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessagesNew.CREATE_START);

    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.createRequisitionsRequest(body);

      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));

      toast.update(start, {
        render: PurchaseRequisitionToastMessagesNew.CREATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      toast.update(start, {
        render: PurchaseRequisitionToastMessagesNew.CREATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getRequisitionItems = createAsyncThunk(
  'requisitionSlice/getRequisitionItems',
  async (id: number | string) => {
    try {
      const response = await requisitionsApi.getRequisitionItemsRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getRequisitionItemsByUuid = createAsyncThunk(
  'requisitionSlice/getRequisitionItemsByUuid',
  async (uuid: string) => {
    try {
      const response = await requisitionsApi.getRequisitionItemsByUuidRequest(uuid);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const updateRequisition = createAsyncThunk(
  'requisitionSlice/updateRequisition',
  async (body: TRequisitionUpdateBody, { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessagesNew.UPDATE_START);

    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.updateRequisitionRequest(body);

      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));

      toast.update(start, {
        render: PurchaseRequisitionToastMessagesNew.UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      toast.update(start, {
        render: PurchaseRequisitionToastMessagesNew.UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const modifyRequisitionItem = createAsyncThunk(
  'requisitionSlice/modifyRequisitionItem',
  async ({ body, id }: TRequisitionModify, { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessagesNew.UPDATE_START);
    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.modifyRequisitionRequest(body);

      dispatch(getRequisitionItems(id));
      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));

      toast.update(start, {
        render: PurchaseRequisitionToastMessagesNew.UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      return response.data;
    } catch (error) {
      toast.update(start, {
        render: PurchaseRequisitionToastMessagesNew.UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const clonePurchaseRequisition = createAsyncThunk(
  'requisitionSlice/cloneRequisition',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessages.REQUISITION_CLONE_START);
    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.cloneRequisitionRequest(id);

      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_CLONE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_CLONE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);
export const addItemsToRequisition = createAsyncThunk(
  'requisitionSlice/addItemsToRequisition',
  async (body: TAddRequisitionItemBody) => {
    try {
      const response = await requisitionsApi.addItemsToRequisitionRequest(body);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const deletePurchaseRequisition = createAsyncThunk(
  'requisitionSlice/deleteRequisition',
  async (id: number, { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessages.REQUISITION_DELETE_START);

    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.deleteRequisitionRequest(id);

      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const deletePurchaseRequisitionItem = createAsyncThunk(
  'requisitionSlice/deleteRequisitionItem',
  async ({ id, requisitionId }: TDeleteRequisitionOptions, { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessages.REQUISITION_ITEM_DELETE_START);

    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.deleteRequisitionItemRequest(id);

      dispatch(getRequisitionItems(requisitionId));
      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_ITEM_DELETE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_ITEM_DELETE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);

export const updateRequisitionItemStatus = createAsyncThunk(
  'requisitionSlice/deleteRequisitionItem',
  async ({ ids, status }: TRequisitionUpdateBody, { dispatch, getState }) => {
    const start = toast.loading(PurchaseRequisitionToastMessages.REQUISITION_STATUS_UPDATE_START);

    const {
      requisition: { allRequisitionsLimit, allRequisitionsOffset },
    } = getState() as RootState;

    try {
      const response = await requisitionsApi.approveDeclineRequisitionRequest(
        ids as number[],
        status,
      );

      dispatch(getRequisitions({ limit: allRequisitionsLimit, offset: allRequisitionsOffset }));
      // dispatch(getRequisitionItems(id));

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_STATUS_UPDATE_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: PurchaseRequisitionToastMessages.REQUISITION_STATUS_UPDATE_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      throw Error;
    }
  },
);
