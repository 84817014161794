import * as yup from 'yup';

import { getPartByPartIdRequest } from 'api/inventory';

const catalogSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  category: yup.string().required('Category is required'),
  description: yup.string().required('Description is required'),
  discounts: yup.array().of(
    yup.object().shape({
      coupon: yup.object().shape({
        coupon_code: yup.string(),
        discount_percent: yup.string(),
        start_date: yup.date().nullable(),
        end_date: yup.date().nullable(),
        coupon_status: yup.string(),
      }),
    }),
  ),
  payment_schedule: yup.object().shape({
    estimated_duration: yup.number().required('Estimated duration is required'),
    schedule: yup.object().shape({
      type: yup.string().required('Schedule type is required'),
      stages: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Stage name is required'),
          percentage_complete: yup.number().required('Percentage complete is required'),
          percentage_amount: yup.number().required('Percentage amount is required'),
        }),
      ),
    }),
    description: yup.string().required('Payment schedule description is required'),
  }),
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Section name is required'),
      description: yup.string().required('Section description is required'),
      items: yup.array().of(
        yup.object().shape({
          qty_required: yup.string().required('Quantity required is required'),
          part_name: yup.string().required('Part name is required'),
          description: yup.string().required('Item description is required'),
          part_id: yup.number().notRequired(),
        }),
      ),
    }),
  ),
  generalInformation: yup.array().of(
    yup.object().shape({
      key: yup.string().required('Key is required'),
      value: yup.string().required('Value is required'),
    }),
  ),
});

const validationSchema = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  category: yup.string().trim().required('Category is required'),
  description: yup.string().trim(),
  templates: yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(),
      type: yup.string().nullable(),
      media_url_id: yup.string().required('Template is required'),
      description: yup.string().nullable(),
    }),
  ),
  discounts: yup.array().of(
    yup.object().shape({
      coupon: yup.object().shape({
        coupon_code: yup
          .string()
          .ensure()
          .when('discount_percent', (discount_percent, schema) => {
            return discount_percent[0] !== '0'
              ? schema.required('Coupon code is required when you have a percentage more 0%')
              : schema.notRequired();
          }),

        discount_percent: yup.string().trim(),

        start_date: yup.date().nullable(),
        end_date: yup
          .date()
          .nullable()
          .test('end', 'End date should be right after the start day', function (value) {
            const { parent } = this;
            if (parent?.coupon_code?.trim()?.length && value) {
              const startDate = new Date(parent.start_date);

              const minEndDate = new Date(startDate);
              minEndDate.setDate(startDate.getDate() + 1);

              return value > minEndDate;
            } else {
              return true;
            }
          }),

        coupon_status: yup.string().trim(),
      }),
    }),
  ),

  payment_schedule: yup.object().shape({
    estimated_duration: yup
      .string()
      .trim()
      .required('Duration is required')
      .matches(/^[1-9][0-9]*$/, 'Duration must be a natural number greater than 0')
      .test('maxDuration', 'Duration must not exceed 1000000', (value) => {
        if (value) {
          const durationValue = parseInt(value, 10);
          return durationValue <= 1000000;
        }
        return true;
      }),
    schedule: yup.object().shape({
      type: yup.string().trim().required('Schedule type is required'),
      stages: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required('Stage name is required')
            .oneOf(
              ['Initial Payment', 'Midway Payment', 'Final Payment'],
              'Please choose one of the following: Initial Payment, Midway Payment, Final Payment',
            ),
          percentage_complete: yup.string().required('Percentage complete is required'),
          percentage_amount: yup.string().required('Percentage amount is required'),
        }),
      ),
    }),
    description: yup.string().trim().required('Payment schedule description is required'),
  }),
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required('Section name is required'),
      description: yup.string().trim().notRequired(),
      items: yup
        .array()
        .of(
          yup.object().shape({
            part_id: yup.number().required('Part ID is required'),
            part_name: yup
              .string()
              .trim()
              .required('Part name is required and must be selected from the Inventory items list'),
            qty_required: yup
              .string()
              .trim()
              .required('Quantity is required')
              .matches(/^[1-9][0-9]*$/, 'Quantity must be a natural number greater than 0')
              .test(
                'is-greater-than-initial',
                'Quantity must be smaller than the initial value',
                async function (value) {
                  if (
                    this.parent.part_id &&
                    !!value &&
                    (document.activeElement?.id.includes(
                      `part_id/${this.parent.part_id}/${this.path}`,
                    ) ||
                      document.activeElement?.id.includes(`catalogForm`))
                  ) {
                    const data = await getPartByPartIdRequest(this.parent.part_id);
                    const initialQty = data.data.qty;
                    return value <= initialQty;
                  } else {
                    return true;
                  }
                },
              ),

            description: yup.string().trim().required('Description is required'),
          }),
        )
        .required()
        .min(1),
    }),
  ),
  generalInformation: yup.array().of(
    yup.object().shape({
      type: yup.string().trim().required('Type is required'),
      name: yup.string().trim().required('Name is required'),
      value: yup.string().trim().required('Value is required'),
    }),
  ),
});

const validationSchemaEdit = yup.object().shape({
  name: yup.string().trim().required('Name is required'),
  category: yup.string().trim().required('Category is required'),
  description: yup.string().trim(),
  templates: yup.array().of(
    yup.object().shape({
      id: yup.number().nullable(),
      type: yup.string().nullable(),
      media_url_id: yup.string().required('Template is required'),
      description: yup.string().nullable(),
    }),
  ),
  discounts: yup.array().of(
    yup.object().shape({
      coupon: yup.object().shape({
        coupon_code: yup
          .string()
          .ensure()
          .when('discount_percent', (discount_percent, schema) => {
            return discount_percent[0] !== '0'
              ? schema.required('Coupon code is required when you have a percentage more 0%')
              : schema.notRequired();
          }),

        discount_percent: yup.string().trim(),

        start_date: yup.date().nullable(),
        end_date: yup.date().nullable(),

        coupon_status: yup.string().trim(),
      }),
    }),
  ),

  payment_schedule: yup.object().shape({
    estimated_duration: yup
      .string()
      .trim()
      .required('Duration is required')
      .matches(/^[1-9][0-9]*$/, 'Duration must be a natural number greater than 0')
      .test('maxDuration', 'Duration must not exceed 1000000', (value) => {
        if (value) {
          const durationValue = parseInt(value, 10);
          return durationValue <= 1000000;
        }
        return true;
      }),
    schedule: yup.object().shape({
      type: yup.string().trim().required('Schedule type is required'),
      stages: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .trim()
            .required('Stage name is required')
            .oneOf(
              ['Initial Payment', 'Midway Payment', 'Final Payment'],
              'Please choose one of the following: Initial Payment, Midway Payment, Final Payment',
            ),
          percentage_complete: yup.string().required('Percentage complete is required'),
          percentage_amount: yup.string().required('Percentage amount is required'),
        }),
      ),
    }),
    description: yup.string().trim().required('Payment schedule description is required'),
  }),
  sections: yup.array().of(
    yup.object().shape({
      name: yup.string().trim().required('Section name is required'),
      description: yup.string().trim().notRequired(),
      items: yup
        .array()
        .of(
          yup.object().shape({
            part_id: yup.number().required('Part ID is required'),
            part_name: yup
              .string()
              .trim()
              .required('Part name is required and must be selected from the Inventory items list'),
            qty_required: yup
              .string()
              .trim()
              .required('Quantity is required')
              .matches(/^[1-9][0-9]*$/, 'Quantity must be a natural number greater than 0')
              .test(
                'is-greater-than-initial',
                'Quantity must be smaller than the initial value',
                async function (value) {
                  if (
                    this.parent.part_id &&
                    !!value &&
                    (document.activeElement?.id.includes(
                      `part_id/${this.parent.part_id}/${this.path}`,
                    ) ||
                      document.activeElement?.id.includes(`catalogForm`))
                  ) {
                    const data = await getPartByPartIdRequest(this.parent.part_id);
                    const initialQty = data.data.qty;
                    return value <= initialQty;
                  } else {
                    return true;
                  }
                },
              ),

            description: yup.string().trim().required('Description is required'),
          }),
        )
        .required()
        .min(1),
    }),
  ),
  generalInformation: yup.array().of(
    yup.object().shape({
      type: yup.string().trim().required('Type is required'),
      name: yup.string().trim().required('Name is required'),
      value: yup.string().trim().required('Value is required'),
    }),
  ),
});

export { catalogSchema, validationSchema, validationSchemaEdit };
