import { createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';

import { TInitalState } from './types';
import {
  findInvoice,
  findEstimate,
  findCatalogs,
  findCustomer,
  findWorkOrder,
  findAgreement,
  findInventories,
  findPurchaseOrder,
  findPurchaseRequisition,
  findUser,
} from './thunks';

const initialState: TInitalState = {
  foundedCatalogsError: null,
  foundedCustomerError: null,
  foundedInvoicesError: null,
  foundedAgreementError: null,
  foundedEstimatesError: null,
  foundedWorkOrderError: null,
  foundedCustomerLoading: false,
  foundedInventoriesError: null,
  foundedCatalogsLoading: false,
  foundedInvoicesLoading: false,
  foundedUsersError: null,
  foundedUsersLoading: false,
  foundedUsers: dataStructure,
  foundedWorkOrderLoading: false,
  foundedAgreementLoading: false,
  foundedCustomer: dataStructure,
  foundedEstimatesLoading: false,
  foundedInvoices: dataStructure,
  foundedCatalogs: dataStructure,
  foundedAgreement: dataStructure,
  foundedEstimates: dataStructure,
  foundedInventoriesLoading: false,
  foundedPurchaseOrderError: null,
  foundedInventories: dataStructure,
  foundedPurchaseOrderLoading: false,
  foundedPurchaseOrder: dataStructure,
  foundedPurchaseRequisitionsError: null,
  foundedPurchaseRequisitionsLoading: false,
  foundedPurchaseRequisitions: dataStructure,
};

const searchSlice = createSlice({
  name: 'searchSlice',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(findInventories.pending, (state) => {
        state.foundedInventoriesLoading = true;
        state.foundedInventoriesError = null;
      })
      .addCase(findInventories.fulfilled, (state, action) => {
        state.foundedInventoriesLoading = false;
        state.foundedInventoriesError = null;
        state.foundedInventories = action.payload;
      })
      .addCase(findInventories.rejected, (state, action) => {
        state.foundedInventoriesLoading = false;
        state.foundedInventoriesError = action.payload as string;
      })
      .addCase(findCatalogs.pending, (state) => {
        state.foundedCatalogsLoading = true;
        state.foundedCatalogsError = null;
      })
      .addCase(findCatalogs.fulfilled, (state, action) => {
        state.foundedCatalogsLoading = false;
        state.foundedCatalogsError = null;
        state.foundedCatalogs = action.payload;
      })
      .addCase(findCatalogs.rejected, (state, action) => {
        state.foundedCatalogsLoading = false;
        state.foundedCatalogsError = action.payload as string;
      })
      .addCase(findEstimate.pending, (state) => {
        state.foundedEstimatesLoading = true;
        state.foundedEstimatesError = null;
      })
      .addCase(findEstimate.fulfilled, (state, action) => {
        state.foundedEstimatesLoading = false;
        state.foundedEstimatesError = null;
        state.foundedEstimates = action.payload;
      })
      .addCase(findEstimate.rejected, (state, action) => {
        state.foundedEstimatesLoading = false;
        state.foundedEstimatesError = action.payload as string;
      })
      .addCase(findPurchaseRequisition.pending, (state) => {
        state.foundedPurchaseRequisitionsLoading = true;
        state.foundedPurchaseRequisitionsError = null;
      })
      .addCase(findPurchaseRequisition.fulfilled, (state, action) => {
        state.foundedPurchaseRequisitionsLoading = false;
        state.foundedPurchaseRequisitionsError = null;
        state.foundedPurchaseRequisitions = action.payload;
      })
      .addCase(findPurchaseRequisition.rejected, (state, action) => {
        state.foundedPurchaseRequisitionsLoading = false;
        state.foundedPurchaseRequisitionsError = action.payload as string;
      })
      .addCase(findPurchaseOrder.pending, (state) => {
        state.foundedPurchaseOrderLoading = true;
        state.foundedPurchaseOrderError = null;
      })
      .addCase(findPurchaseOrder.fulfilled, (state, action) => {
        state.foundedPurchaseOrderLoading = false;
        state.foundedPurchaseOrderError = null;
        state.foundedPurchaseOrder = action.payload;
      })
      .addCase(findPurchaseOrder.rejected, (state, action) => {
        state.foundedPurchaseOrderLoading = false;
        state.foundedPurchaseOrderError = action.payload as string;
      })
      .addCase(findAgreement.pending, (state) => {
        state.foundedAgreementLoading = true;
        state.foundedAgreementError = null;
      })
      .addCase(findAgreement.fulfilled, (state, action) => {
        state.foundedAgreementLoading = false;
        state.foundedAgreementError = null;
        state.foundedAgreement = action.payload;
      })
      .addCase(findAgreement.rejected, (state, action) => {
        state.foundedAgreementLoading = false;
        state.foundedAgreementError = action.payload as string;
      })
      .addCase(findWorkOrder.pending, (state) => {
        state.foundedWorkOrderLoading = true;
        state.foundedWorkOrderError = null;
      })
      .addCase(findWorkOrder.fulfilled, (state) => {
        state.foundedWorkOrderLoading = false;
        state.foundedWorkOrderError = null;
      })
      .addCase(findWorkOrder.rejected, (state, action) => {
        state.foundedWorkOrderLoading = false;
        state.foundedWorkOrderError = action.payload as string;
      })
      .addCase(findCustomer.pending, (state) => {
        state.foundedCustomerLoading = true;
        state.foundedCustomerError = null;
      })
      .addCase(findCustomer.fulfilled, (state, action) => {
        state.foundedCustomerLoading = false;
        state.foundedCustomerError = null;
        state.foundedCustomer = action.payload;
      })
      .addCase(findCustomer.rejected, (state, action) => {
        state.foundedCustomerLoading = false;
        state.foundedCustomerError = action.payload as string;
      })
      .addCase(findInvoice.pending, (state) => {
        state.foundedInvoicesLoading = true;
        state.foundedInvoicesError = null;
      })
      .addCase(findInvoice.fulfilled, (state, action) => {
        state.foundedInvoicesLoading = false;
        state.foundedInvoicesError = null;
        state.foundedInvoices = action.payload;
      })
      .addCase(findInvoice.rejected, (state, action) => {
        state.foundedInvoicesLoading = false;
        state.foundedInvoicesError = action.payload as string;
      })
      .addCase(findUser.pending, (state) => {
        state.foundedUsersLoading = true;
        state.foundedUsersError = null;
      })
      .addCase(findUser.fulfilled, (state, action) => {
        state.foundedUsersLoading = false;
        state.foundedUsersError = null;
        state.foundedUsers = action.payload;
      })
      .addCase(findUser.rejected, (state, action) => {
        state.foundedUsersLoading = false;
        state.foundedUsersError = action.payload as string;
      });
  },
});

export default searchSlice.reducer;
