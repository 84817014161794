import { FC } from 'react';
import classNames from 'classnames';
import Typography, { TypographyProps } from '@mui/material/Typography';

type TCustomTypographyProps = TypographyProps &
  Partial<{
    outlined: boolean;

    className: string;
    fontWeight: number | 'normal';
    headingSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | string;
  }>;

const CustomTypography: FC<TCustomTypographyProps> = ({
  children,
  className,
  headingSize,
  color = 'inherit',
  outlined = false,
  fontWeight = 'normal',
  ...rest
}) => {
  const customTypographyStyles = {
    fontWeight:
      typeof fontWeight === 'number' ? fontWeight : fontWeight === 'normal' ? 400 : 'bold',
    color,
    textDecoration: outlined ? 'underline' : 'unset',
  };

  const containerClasses = classNames(className);

  if (headingSize) {
    return (
      <Typography
        variant={headingSize}
        style={customTypographyStyles}
        className={containerClasses}
        color={color}
        {...rest}
      >
        {children}
      </Typography>
    );
  }

  return (
    <Typography style={customTypographyStyles} color={color} {...rest} className={containerClasses}>
      {children}
    </Typography>
  );
};

export default CustomTypography;
