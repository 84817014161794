import { FC } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'hooks';
import { BorderSquareIcon, LogoIcon } from 'assets/icons';
import {
  userPermissionsSelector,
  userDesignationSelector,
  userAllRolesLoadingSelector,
} from 'store/slices/authSlice/selectors';
import { Routes } from 'types';
import { checkRouteValidity } from 'utils';

import CustomSkeleton from '../CustomSkeleton';
import OrganizationLogo from '../OrganizationLogo';

import styles from './Navbar.module.scss';
import { dropdownOptions } from './constant';
import NavbarOption from './navbarOption';

import type { TNavbarProps } from './types';

const Navbar: FC<TNavbarProps> = ({ asDrawer = false, onClose, forLargeScreens = false }) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isLoad = useAppSelector(userAllRolesLoadingSelector);
  const userAccesses = useAppSelector(userPermissionsSelector);
  const userDesignation = useAppSelector(userDesignationSelector);

  const logoClasses = classNames(styles.container_logo, {
    [styles.container_logo_close]: asDrawer,
  });

  const containerClasses = classNames(styles.container, {
    [styles.container__drawer]: asDrawer,
  });

  const footerClasses = classNames(styles.container__footer, {
    [styles.container__footer__large]: forLargeScreens,
  });

  const initialSkeletons = dropdownOptions?.map((_, idx) => (
    <Box sx={{ padding: '10px' }} key={idx}>
      <CustomSkeleton height={25} />
    </Box>
  ));

  const dropdownOptionsCreator = () => {
    const updatedDropdownOptions = dropdownOptions.filter((item) => {
      if (item.isInDropDownMode) {
        const hasEstimatesAccess =
          userAccesses.isAccessToEstimatesEditor || userAccesses.isAccessToEstimatesViewer;
        const hasInvoicesAccess = userAccesses.isAccessToInvoices;
        const hasWorkOrderAccess =
          userAccesses.isAccessToWorkOrdersAndScheduling || userAccesses.isAccessToEditWorkOrder;

        return hasEstimatesAccess || hasInvoicesAccess || hasWorkOrderAccess;
      }

      return true;
    });

    return updatedDropdownOptions;
  };

  const renderNavbarOptions = dropdownOptionsCreator()?.map((option) => {
    const isInWorkFlowPage =
      option.content === 'Work flow' &&
      (pathname === Routes.BomEstimate ||
        pathname === Routes.Invoice ||
        pathname === Routes.BomPricingEstimate ||
        pathname === Routes.WorkOrder);

    const isActive = pathname === option.linkTo || isInWorkFlowPage;

    const isRouteActive = checkRouteValidity(userAccesses, option?.linkTo || '');

    return (
      <NavbarOption
        key={option.id}
        option={option}
        onClose={onClose}
        selected={isActive}
        linkTo={option?.linkTo || ''}
        isRouteActive={userDesignation === 'owner' || isRouteActive}
      />
    );
  });

  const goToActivityFeed = () => navigate(Routes.ActivityFeed);

  return (
    <div className={containerClasses}>
      {!forLargeScreens && (
        <div className={logoClasses}>
          {asDrawer ? (
            <BorderSquareIcon onClick={onClose} />
          ) : (
            <OrganizationLogo onClick={goToActivityFeed} />
          )}
        </div>
      )}
      <div className={styles.container__content}>
        {userDesignation !== 'owner' &&
        userDesignation !== 'manager' &&
        userDesignation !== 'employee' &&
        isLoad
          ? initialSkeletons
          : renderNavbarOptions}
      </div>
      <div className={footerClasses}>
        <span>Powered by</span>
        <LogoIcon />
      </div>
    </div>
  );
};

export default Navbar;
