import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { Routes } from 'types';
import { Button, CustomSkeleton } from 'components';
import { TSendDuration } from 'containers/ProcessForm/types';
import SubscriptionPlanBoxes from 'components/views/SubscriptionPlanBoxes';
import {
  getUserSubscriptions,
  getUserSubscriptionsTrailing,
} from 'store/slices/subscriptionSlice/thunks';
import { useAppDispatch, useAppSelector, useUpdateSubscription, useWindowSize } from 'hooks';
import {
  userSubscriptionsPlansData,
  userSubscriptionsPlanSelector,
  userSubscriptionsLoaderSelector,
  userSubscriptionsIntervalSelector,
  currentSubscriptionPlanLoadingSelector,
  userSubscriptionsTrialPlan,
} from 'store/slices/subscriptionSlice/selectors';
import { RootObject, generatePlan } from 'utils/generatePlan';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { UserSubscriptionStatus } from 'store/slices/subscriptionSlice/types';

import { breakPoints } from '../../../../constants';

import styles from './Plans.module.scss';

const Plans = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const interval = useAppSelector(userSubscriptionsIntervalSelector);
  const userSubscriptions = useAppSelector(userSubscriptionsPlanSelector);
  const userActiveSubscription = useAppSelector(userSubscriptionsPlansData);
  const userActiveTrialSubscription = useAppSelector(userSubscriptionsTrialPlan);
  const userSubscriptionsLoader = useAppSelector(userSubscriptionsLoaderSelector);
  const createdSubscriptionPlan = useAppSelector(currentSubscriptionPlanLoadingSelector);

  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  const { width } = useWindowSize();

  const skeletonsArrayLength = Number(width) <= breakPoints.TABLET_L ? 1 : 3;
  const stackSpace = Number(width) <= breakPoints.TABLET_L ? 'center' : 'space-between';

  const activePlanTitle =
    userSubscriptions?.[0] === 'pro'
      ? 'Pro Plan'
      : userSubscriptions?.[0] === 'business'
      ? 'Business+ Plan'
      : userSubscriptions?.[0] === 'enterprise'
      ? 'Enterprise Grid'
      : 'basic';

  const activePlanInterval = interval?.[0] === 'month' ? 'Monthly' : 'Yearly';

  const [activeCardForSend, setActiveCardForSend] = useState<TSendDuration>({
    title: activePlanTitle,
    duration: activePlanInterval,
    amount: 0,
  });

  const changedDuration =
    typeof activeCardForSend.duration === 'string'
      ? activeCardForSend.duration
      : activeCardForSend.duration.duration;

  const changedCondition =
    activeCardForSend?.title === activePlanTitle && changedDuration === activePlanInterval;

  useEffect(() => {
    if (!userSubscriptionsLoader && !createdSubscriptionPlan) {
      setActiveCardForSend({
        title: activePlanTitle,
        duration: activePlanInterval,
        amount: 0,
      });
    }
  }, [
    activePlanTitle,
    userSubscriptions,
    activePlanInterval,
    createdSubscriptionPlan,
    userSubscriptionsLoader,
  ]);

  useEffect(() => {
    if (currentOrganization) {
      dispatch(
        getUserSubscriptions({
          org_id: Number(currentOrganization),
          subscription_status: UserSubscriptionStatus.ACTIVE,
        }),
      );

      dispatch(getUserSubscriptionsTrailing());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrganization]);

  const sendedSubscriptionId =
    userActiveSubscription?.[0]?.id || userActiveTrialSubscription?.data?.[0]?.id;

  const { handleChangeSubscription } = useUpdateSubscription({
    org_id: Number(currentOrganization),
    subscription_id: sendedSubscriptionId as string,
    subscription: generatePlan(activeCardForSend as RootObject),
  });

  const buttonValue = pathname === Routes.AccountSettings ? 'SAVE' : 'NEXT';

  const skeletonSize = Number(width) <= breakPoints.TABLET_L ? '360px' : '100%';

  const initalSkeletons = Array.from({ length: skeletonsArrayLength })?.map((_, idx) => (
    <CustomSkeleton
      key={idx}
      height='380px'
      width={skeletonSize}
      maxWidth='100%'
      borderRadius='10px'
    />
  ));

  return (
    <Box className={styles.container}>
      {userSubscriptionsLoader ? (
        <Box sx={{ maxWidth: '100%' }}>
          <Box
            sx={{
              width: '100%',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CustomSkeleton height='35px' width='100%' maxWidth='116px' borderRadius='5px' />
          </Box>
          <Stack direction='row' justifyContent={stackSpace} gap='22px'>
            {initalSkeletons}
          </Stack>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              padding: '20px 40px',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <CustomSkeleton height='42px' width='100%' maxWidth='65px' borderRadius='4px' />
          </Box>
        </Box>
      ) : (
        <>
          <SubscriptionPlanBoxes
            withTitle={false}
            activePlanTitle={activePlanTitle}
            className={styles.container__cards}
            activeCardForSend={activeCardForSend}
            activePlanInterval={activePlanInterval}
            cardClasses={styles.container__cards__width}
            setActiveCardForSend={setActiveCardForSend}
          />

          <div className={styles.container__button}>
            <Button
              minWidth='65px'
              maxWidth='65px'
              borderRadius='4px'
              variant='contained'
              padding='12px 16px'
              disabled={changedCondition}
              onClick={handleChangeSubscription}
            >
              {buttonValue}
            </Button>
          </div>
        </>
      )}
    </Box>
  );
};
export default Plans;
