import { DownloadIcon, UsersListIcon } from 'assets/icons';

import { TOptionsDropDownProps } from './types';

export const dropDownActionsCreator = ({
  withNotes,
  downloadAction,
  openMembersAction,
}: TOptionsDropDownProps) => {
  const actionsArray = [
    {
      id: 1,
      name: 'Download',
      action: downloadAction,
      icon: <DownloadIcon />,
    },
    {
      id: 2,
      name: 'Joinee List',
      action: openMembersAction,
      icon: <UsersListIcon />,
    },
  ];

  if (!withNotes) {
    actionsArray.shift();
  }

  return actionsArray;
};
