import { Box } from '@mui/material';
import { FC } from 'react';

import CustomersViewInfo from '../CustomersViewInfo';

import styles from './ContactsInformationView.module.scss';

import type { TContactsInformationViewProps } from './types';

const ContactsInformationView: FC<TContactsInformationViewProps> = ({ contact }) => (
  <Box className={styles.container}>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='Full Name' subtitle={contact?.name} />
      <CustomersViewInfo title='Phone Number 1' subtitle={contact?.phone_number_1} />
    </Box>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='Email' subtitle={contact?.email} />
      <CustomersViewInfo title='Phone Number 2' subtitle={contact?.phone_number_2} />
    </Box>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='Address' subtitle={contact?.address} />
      <CustomersViewInfo title='State' subtitle={contact?.state} />
    </Box>
    <Box className={styles.container__item}>
      <CustomersViewInfo title='City' subtitle={contact?.city} />
      <CustomersViewInfo title='Zip' subtitle={contact?.zip} />
    </Box>
  </Box>
);

export default ContactsInformationView;
