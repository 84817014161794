import { FC, useState } from 'react';
import { Box, FormControl, RadioGroup } from '@mui/material';

import { useWindowSize } from 'hooks';
import { TPlanDurationPrices } from 'types/global/durationTypes';
import { subscriptionPlansNew } from 'constants/SubscriptionPlans';
import { DurationTabs, ProcessFormTitle } from 'components';
import SubscriptionPlanCard from 'components/views/SubscriptionPlanCard/SubscriptionPlanCard';

import { breakPoints } from '../../../constants';
import SlidePagination from '../SlidePagination/SlidePagination';

import styles from './SubscriptionPlanBoxes.module.scss';

import type { TSubscriptionPlanBoxesProps } from './types';

const SubscriptionPlanBoxes: FC<TSubscriptionPlanBoxesProps> = ({
  cardClasses,
  className,
  activePlanTitle,
  defaultDuration,
  activeCardForSend,
  withTitle = false,
  activePlanInterval,
  setActiveCardForSend,
}) => {
  const { width } = useWindowSize();

  const [activeDuration, setActiveDuration] = useState<TPlanDurationPrices>({
    id: 1,
    duration: 'Monthly',
  });

  const plans = subscriptionPlansNew.map((plan) => (
    <SubscriptionPlanCard
      plan={plan}
      key={plan.id}
      className={cardClasses}
      duration={activeDuration}
      defaultDuration={defaultDuration}
      getCardOptions={setActiveCardForSend}
      setActiveDuration={setActiveDuration}
      activePlanInterval={activePlanInterval}
      activePlanTitle={activePlanTitle}
      isActive={String(activeCardForSend.title) === String(plan.title)}
    />
  ));

  //Todo don't remove
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isDisableFreeTrial = activeCardForSend?.title === 'basic';

  const getActiveDuration = (duration: TPlanDurationPrices) => setActiveDuration(duration);

  const selectedPlan = subscriptionPlansNew.find((el) => el.title === activeCardForSend.title);

  return (
    <>
      {Number(width) > breakPoints.DESKTOP ? (
        <Box>
          <Box sx={{ maxWidth: '100%' }}>
            {withTitle && <ProcessFormTitle title='Subscription plan' />}
            <Box sx={{ marginBottom: '20px' }}>
              <DurationTabs
                durations={[
                  { id: 1, duration: 'Monthly' },
                  { id: 2, duration: 'Yearly' },
                ]}
                selectedPlan={selectedPlan}
                activeDuration={activeDuration}
                activeCardForSend={activeCardForSend}
                changeActiveDuration={getActiveDuration}
                setActiveCardForSend={setActiveCardForSend}
              />
            </Box>

            <FormControl>
              <RadioGroup
                className={className}
                name='radio-buttons-group'
                defaultValue={subscriptionPlansNew[0].title}
                aria-labelledby='demo-radio-buttons-group-label'
                style={{ display: 'flex', gap: 32, flexDirection: 'row', flexWrap: 'nowrap' }}
              >
                {plans}
              </RadioGroup>
            </FormControl>
          </Box>
          {/* Do not remove from codebase */}
          {/* {pathname !== Routes.AccountSettings && (
            <Stack alignItems='flex-end' width='100%' padding='0 10px'>
              <Button
                isUppercase={false}
                disabled={isDisableFreeTrial}
                onClick={callSubscriptionWithFree}
                textClassName={styles.container__free}
              >
                Free 30 Day Trial
              </Button>
            </Stack>
          )} */}
        </Box>
      ) : (
        <Box>
          {withTitle && <ProcessFormTitle title='Subscription plan' />}
          <Box sx={{ marginBottom: '20px' }}>
            <DurationTabs
              durations={[
                { id: 1, duration: 'Monthly' },
                { id: 2, duration: 'Yearly' },
              ]}
              selectedPlan={selectedPlan}
              activeDuration={activeDuration}
              activeCardForSend={activeCardForSend}
              changeActiveDuration={getActiveDuration}
              setActiveCardForSend={setActiveCardForSend}
            />
          </Box>
          <SlidePagination elements={plans} className={styles.container} />
          {/* Do not remove from code */}
          {/* {pathname !== Routes.AccountSettings && (
            <Stack alignItems='flex-end' width='100%' padding='0 10px'>
              <Button
                isUppercase={false}
                disabled={isDisableFreeTrial}
                onClick={callSubscriptionWithFree}
                textClassName={styles.container__free}
              >
                Free 30 Day Trial
              </Button>
            </Stack>
          )} */}
        </Box>
      )}
    </>
  );
};

export default SubscriptionPlanBoxes;
