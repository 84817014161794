import { RootState } from 'types';

export const partsSelector = (state: RootState) => state.inventory;
export const allPartsSelector = (state: RootState) => state.inventory.allParts;
export const foundedVendorsSelector = (state: RootState) => state.inventory.foundedVendors;

export const openedSnapshotDataSelector = (state: RootState) => state.inventory.openedSnapshotData;

export const openedSnapshotDataLoadSelector = (state: RootState) =>
  state.inventory.openedSnapshotDataLoading;

export const partHistoryDataSelector = (state: RootState) => state.inventory.partHistoryData;

export const partHistoryDataLoadSelector = (state: RootState) =>
  state.inventory.partHistoryDataLoading;

export const allPartsLoadingSelector = (state: RootState) => state.inventory.allPartsLoading;

export const allPartsErrorSelector = (state: RootState) => state.inventory.allPartsError;

export const allPartsLimitSelector = (state: RootState) => state.inventory.allPartsLimit;

export const allPartsAscSelector = (state: RootState) => state.inventory.allPartsAsc;

export const allPartsOffsetSelector = (state: RootState) => state.inventory.allPartsOffset;

export const vendorListSelector = (state: RootState) => state.inventory.allVendors;

export const vendorListLimitSelector = (state: RootState) => state.inventory.allVendorsLimit;

export const vendorListAscSelector = (state: RootState) => state.inventory.allVendorsAsc;

export const vendorListOffsetSelector = (state: RootState) => state.inventory.allVendorsOffset;

export const vendorListLoadingSelector = (state: RootState) => state.inventory.allVendorsLoading;

export const vendorListErrorSelector = (state: RootState) => state.inventory.allVendorsError;

export const currentPartByIdSelector = (state: RootState) => state.inventory.currentPartById;

export const currentPartByIdLoadSelector = (state: RootState) =>
  state.inventory.currentPartByIdLoad;

export const currentVendorByIdSelector = (state: RootState) => state.inventory.currentVendor;

export const currentExcelByIdSelector = (state: RootState) => state.inventory.currentExcel;

export const excelListSelector = (state: RootState) => state.inventory.allExcel;

export const excelListLimitSelector = (state: RootState) => state.inventory.allExcelLimit;

export const excelListAscSelector = (state: RootState) => state.inventory.allExcelAsc;

export const excelListOffsetSelector = (state: RootState) => state.inventory.allExcelOffset;

export const excelListLoadingSelector = (state: RootState) => state.inventory.allExcelOffset;
