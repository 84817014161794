import { TCustomer } from 'store/slices/customersSlices/types';

import { TBillingInfoFromCustomerReturn, TCustomerInfoFromCustomerCreatorReturn } from './types';

export const customerInfoFromCustomerCreator = (
  currentCustomer: TCustomer | null,
): TCustomerInfoFromCustomerCreatorReturn => ({
  full_name: currentCustomer?.name,
  email: currentCustomer?.email,
  phone_number: currentCustomer?.phone_number,
  address: currentCustomer?.address,
  city: currentCustomer?.city,
  state: currentCustomer?.state,
  zip: currentCustomer?.zip,
});

export const billingInfoFromCustomerCreator = (
  currentCustomer: TCustomer | null,
): TBillingInfoFromCustomerReturn => ({
  bill_to: currentCustomer?.billing?.[0]?.bill_to,
  phone_number: currentCustomer?.billing?.[0]?.phone_number,
  address: currentCustomer?.billing?.[0]?.address,
  city: currentCustomer?.billing?.[0]?.city,
  state: currentCustomer?.billing?.[0]?.state,
  zip: currentCustomer?.billing?.[0]?.zip,
});
