import { FontNames } from 'types';

export const inputConfigurations = [
  {
    id: 1,
    type: 'text',
    name: 'name',
    required: true,
    borderRadius: '5px',
    label: 'Vendor Name',
    placeholder: 'Vendor Name:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 3,
    type: 'text',
    required: true,
    borderRadius: '5px',
    label: 'Contact Name',
    name: 'contact_person',
    placeholder: 'Contact Name:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 4,
    name: 'phone',
    type: 'number',
    required: true,
    borderRadius: '5px',
    label: 'Contact Number:',
    placeholder: 'Contact Number:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 5,
    name: 'email',
    type: 'text',
    required: true,
    borderRadius: '5px',
    label: 'Contact Email',
    placeholder: 'Contact Email:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 2,
    type: 'text',
    name: 'address',
    required: false,
    borderRadius: '5px',
    label: 'Address',
    placeholder: 'Address',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 8,
    type: 'text',
    name: 'city',
    borderRadius: '5px',
    required: false,
    label: 'City',
    placeholder: 'City',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 9,
    name: 'state',
    type: 'select',
    label: 'State',
    required: false,
    borderRadius: '5px',
    placeholder: 'State',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 10,
    type: 'text',
    name: 'zip',
    label: 'Zip',
    required: false,
    placeholder: 'Zip',
    borderRadius: '5px',
    fontFamily: FontNames.CIRCULAR_REG,
  },

  {
    id: 6,
    type: 'text',
    name: 'vendor_link',
    required: false,
    borderRadius: '5px',
    label: 'Link To Supplier',
    placeholder: 'Link To Supplier',
    fontFamily: FontNames.CIRCULAR_REG,
  },
];
