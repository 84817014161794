import styles from './FromTopToBottom.module.scss';

const FromRightLine: React.FC = () => (
  <svg
    width='880'
    height='301'
    viewBox='0 0 880 301'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M872 0.560059L872 140.476C872 144.894 868.418 148.476 864 148.476L16 148.476C11.5817 148.476 8.00001 152.058 8.00001 156.476L8.00018 292.56'
      stroke='#D2E1F9'
      strokeWidth='8'
    >
      <animate
        attributeName='stroke-dasharray'
        values='0 1700;1700 0'
        dur='4s'
        repeatCount='indefinite'
      />
    </path>
    <path
      className={styles.circle_web}
      d='M0 292.56C0 288.142 3.58172 284.56 8 284.56C12.4183 284.56 16 288.142 16 292.56C16 296.978 12.4183 300.56 8 300.56C3.58172 300.56 0 296.978 0 292.56Z'
      fill='#D2E1F9'
    />
  </svg>
);

export default FromRightLine;
