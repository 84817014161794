import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { Button, ControlledInput, CustomTypography, DrawerLayout } from 'components';
import { getRequisitionItems } from 'store/slices/requisitionSlices/thunks';
import RequisitionForm from 'containers/PurchaseRequisition/RequisitionForm';
import { requisitionItemsSelector } from 'store/slices/requisitionSlices/selectors';
import requisitionFormValuesGenerator, {
  TRequisitionFormValuesGeneratorReturn,
} from 'utils/formsInitialValues/purchaseRequisitionForm';
import { TRequisitionItemsOptions } from 'containers/PurchaseRequisition/RequisitionForm/types';
import { Colors, type TFormErrorsArr, type TFormSubmit } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './CreateRequisitionDrawer.module.scss';

import type { TCreatePurchaseDrawerProps } from './types';
import type { TRequisition } from 'store/slices/requisitionSlices/types';

const CreateRequisitionDrawer: FC<TCreatePurchaseDrawerProps> = ({
  open,
  handleClose,
  requisitionId,
  setRequisitionId,
  inEditMode = false,
}) => {
  const dispatch = useAppDispatch();
  const itemData = useAppSelector(requisitionItemsSelector);

  const { width } = useWindowSize();

  const drawerWidth =
    Number(width) <= breakPoints.TABLET_L && Number(width) > breakPoints?.TABLET_M
      ? 600
      : Number(width) <= breakPoints?.TABLET_M
      ? '100%'
      : 975;

  const {
    handleSubmit,
    control: RequisitionControl,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TRequisitionFormValuesGeneratorReturn>({
    values: requisitionFormValuesGenerator(inEditMode, itemData as TRequisition),
  });

  useEffect(() => {
    requisitionId && inEditMode && dispatch(getRequisitionItems(requisitionId as number));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitForm: TFormSubmit = async () => {};

  const title = (
    <Box className={styles.header__title}>
      <CustomTypography className={styles.header__title}>Purchase Requisition</CustomTypography>
      <CustomTypography className={styles.header__subtitle}>
        Fill In Details Of The Item You Are Requesting for.
      </CustomTypography>
    </Box>
  );

  const onDrawerClose = () => {
    handleClose();
  };

  const onDrawerClearClose = () => {
    reset({
      requisitionItems: [],
      description: '',
    });
    handleClose();
    setRequisitionId?.(null);
  };

  return (
    <DrawerLayout
      open={open}
      width={drawerWidth}
      headerTitle={title}
      onClose={onDrawerClose}
      onCloseReset={onDrawerClearClose}
      titleClassName={styles.container__title}
    >
      <form className={styles.container} onSubmit={handleSubmit(handleSubmitForm)}>
        <RequisitionForm
          inEditMode={inEditMode}
          control={RequisitionControl}
          errors={errors.requisitionItems as TFormErrorsArr<TRequisitionItemsOptions>}
        />

        <Box className={styles.container__item}>
          <ControlledInput
            multiline
            rows={4}
            name='description'
            label='Description:'
            placeholder='Description:'
            error={errors?.description}
            control={RequisitionControl}
            rulesMessage='Description is required'
          />
        </Box>

        <Box className={styles.container__submit}>
          <Button
            color='white'
            type='submit'
            padding='21.5px 16.5px'
            backgroundColor={Colors.SAPPHIRE}
            disabled={isSubmitting}
          >
            {inEditMode ? 'Update Requisition' : 'Create Requisition'}
          </Button>
        </Box>
      </form>
    </DrawerLayout>
  );
};

export default CreateRequisitionDrawer;
