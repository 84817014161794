import { FC } from 'react';

import styles from './KeyboardButton.module.scss';

import type { TKeyboardButtonProps } from './types';

const KeyboardButton: FC<TKeyboardButtonProps> = ({ children, title, onClick }) => (
  <button className={styles.key__button} title={title} onClick={onClick}>
    {children}
  </button>
);

export default KeyboardButton;
