import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BsStars } from 'react-icons/bs';

import { Routes } from 'types';
import { MenuHamburgerIcon } from 'assets/icons';
import { OrganizationLogo, User, Button } from 'components';
import { Colors, FontNames } from 'types';

import AiDrawer from '../Drawers/AiDrawer';

import styles from './Header.module.scss';

import type { THeaderProps } from './types';

const Header: FC<THeaderProps> = ({ onOpenNavbar, withLogo = false }) => {
  const navigate = useNavigate();

  const goToActivityFeed = () => navigate(Routes.ActivityFeed);

  const [isAiDrawerOpen, setIsAiDrawerOpen] = useState<boolean>(false);
  const toggleAiDrawer = () => {
    setIsAiDrawerOpen(!isAiDrawerOpen);
  };

  return (
    <Box className={styles.container}>
      <MenuHamburgerIcon onClick={onOpenNavbar} className={styles.container__menu} />

      <Box className={styles.container__search}>
        {withLogo && <OrganizationLogo onClick={goToActivityFeed} height='auto' maxWidth='80px' />}
      </Box>
      <Button
        color={Colors.SAPPHIRE}
        borderRadius='5px'
        padding='11px 8px'
        variant='contained'
        disableColor={Colors.WHITE}
        backgroundColor={Colors.PALE_BLUE}
        onClick={toggleAiDrawer}
        fontFamily={FontNames.CIRCULAR_REG}
      >
        <BsStars />
        AI Agent
      </Button>
      <AiDrawer handleClose={toggleAiDrawer} open={isAiDrawerOpen} />
      <Box className={styles.container__user}>
        <User />
      </Box>
    </Box>
  );
};

Header.displayName = 'Header';

export default Header;
