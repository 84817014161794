import { TRemoteMemberInfo } from 'components/views/Stream/types';

export const streamByMembers = (remoteUsers: TRemoteMemberInfo[], upcomingStreams: MediaStream[]) =>
  (remoteUsers || []).reduce((accumulator, firstObject) => {
    const matchingMediaStream = upcomingStreams.find((secondObject) => {
      return firstObject.stream_id === secondObject.id;
    });

    if (matchingMediaStream) {
      accumulator.push({
        member_id: firstObject.member_id,
        stream: matchingMediaStream,
        aspect_ratio: firstObject?.aspect_ratio,
      });
    }

    return accumulator;
  }, [] as { member_id: string; stream: MediaStream; aspect_ratio: string | undefined }[]);
