import { FC } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { ControlledInput, NumberControlledInput } from 'components';
import { Colors } from 'types';

import styles from '../InfoForms.module.scss';

import type { TGeneralInfo, TState } from '../types';
import type { TCustomerDefaultValues } from '../types';

const GeneralInfoForm: FC<TState<TCustomerDefaultValues>> = ({ control, values, isInEditMode }) => {
  const { pathname } = useLocation();

  const isInGeneratePage = pathname.includes('generate');

  const defaultValues = values.general_info as TGeneralInfo;

  return (
    <Box
      className={classNames(styles.container, {
        [styles.container__generate]: isInGeneratePage,
      })}
    >
      <ControlledInput
        control={control}
        label='Site name:'
        placeholder='Site name:'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        readonly={!isInEditMode}
        name='general_info.site_name'
        fontFamily='CircularStdLight'
        defaultValue={defaultValues?.site_name}
      />
      <ControlledInput
        control={control}
        label='Site address:'
        readonly={!isInEditMode}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        placeholder='Site address:'
        name='general_info.site_address'
        defaultValue={defaultValues?.site_address}
      />
      <ControlledInput
        control={control}
        readonly={!isInEditMode}
        label='Site contact email:'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        name='general_info.site_email'
        placeholder='Site contact email:'
        defaultValue={defaultValues?.site_email}
      />
      <NumberControlledInput
        control={control}
        label='Site contact phone:'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='5px'
        backgroundColor={Colors.FROST_WHITE}
        name='general_info.site_phone'
        placeholder='Site contact phone:'
      />
      <ControlledInput
        control={control}
        borderRadius='5px'
        borderColor={Colors.SOFT_SILVER}
        label='Contract term:'
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        placeholder='Contract term:'
        name='general_info.contract_term'
        defaultValue={defaultValues?.contract_term}
      />
      <ControlledInput
        control={control}
        borderRadius='5px'
        borderColor={Colors.SOFT_SILVER}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        label='Closing probability:'
        placeholder='Closing probability:'
        name='general_info.closing_probability'
        defaultValue={defaultValues?.closing_probability}
      />
      <ControlledInput
        control={control}
        borderRadius='5px'
        label='Close date:'
        borderColor={Colors.SOFT_SILVER}
        readonly={!isInEditMode}
        placeholder='Close date:'
        backgroundColor={Colors.FROST_WHITE}
        name='general_info.close_date'
        defaultValue={defaultValues?.close_date}
      />
      <ControlledInput
        control={control}
        borderRadius='5px'
        label='Operate date:'
        borderColor={Colors.SOFT_SILVER}
        readonly={!isInEditMode}
        backgroundColor={Colors.FROST_WHITE}
        placeholder='Operate date:'
        name='general_info.operate_date'
        defaultValue={defaultValues?.operate_date}
      />
    </Box>
  );
};

export default GeneralInfoForm;
