import { useEffect, useState } from 'react';

import { activitiesApi } from 'api';
import { TPostCommentReturnType } from 'store/slices/activitiesSlices/types';

import { dataStructure } from '../../constants';

const usePostComments = (postId: number, fetchStart: boolean, limit = 500, initialOffset = 0) => {
  const [postComments, setPostComments] = useState<TPostCommentReturnType>(dataStructure);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [offset, setOffset] = useState<number>(initialOffset);

  const getPostComments = async () => {
    try {
      setIsLoad(true);
      const response = await activitiesApi.getAllPostCommentsRequest(postId, limit, offset);
      setPostComments(response?.data);
      setIsLoad(false);
      setError(null);

      return response;
    } catch (error) {
      setIsLoad(false);
      setError(error as string);
    }
  };

  useEffect(() => {
    if (fetchStart) {
      getPostComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStart, offset]);

  const changeToNext = () => {
    if (offset + limit < postComments?.total_count) {
      setOffset((prevOffset) => prevOffset + limit);
      getPostComments();
    }
  };

  const changeToPrev = () => {
    if (offset > 0) {
      setOffset((prevOffset) => Math.max(prevOffset - limit, 0));
      getPostComments();
    }
  };

  const isDisablePrev = offset === 0;
  const isDisableNext = offset + limit > postComments?.total_count;

  return {
    error,
    isLoad,
    postComments,
    getPostComments,
    changeToNext,
    changeToPrev,
    isDisablePrev,
    isDisableNext,
  };
};

export default usePostComments;
