import { FC, useState } from 'react';

import { ModalLayout } from 'components';
import breakPoints from 'constants/BreakPoints';
import { useWindowSize } from 'hooks';

import BookDemoForm from './BookDemoForm';

import type { TBookDemoModalProps } from './types';

const BookDemoModal: FC<TBookDemoModalProps> = ({ isOpen, onClose }) => {
  const { width } = useWindowSize();

  const modalWidth = Number(width) <= breakPoints.LARGE_DESKTOP ? 658 : 370;

  const [isStarted, setIsStarted] = useState<boolean>(false);

  const timedOnClose = () => {
    setIsStarted(true);

    setTimeout(() => {
      onClose();
      setIsStarted(false);
    }, 1000);
  };

  return (
    <ModalLayout
      isOpen={isOpen}
      width={modalWidth}
      borderRadius='24px'
      isClosed={isStarted}
      onClose={timedOnClose}
    >
      <BookDemoForm onClose={timedOnClose} />
    </ModalLayout>
  );
};

export default BookDemoModal;
