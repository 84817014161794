import { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import classNames from 'classnames';

import styles from './ListItem.module.scss';

import type { TListItemProps } from './types';

const ListItem: FC<TListItemProps> = ({ icon, title, spacing = 1, isActive = false }) => {
  const listItemClasses = classNames(styles.container, { [styles.container_active]: isActive });

  return (
    <Stack direction='row' spacing={spacing} className={listItemClasses} alignItems='flex-start'>
      <Box className={styles.container__icon}>{icon}</Box>
      <Typography variant='body2' className={styles.container__item}>
        {title}
      </Typography>
    </Stack>
  );
};

export default ListItem;
