import { MutableRefObject, useEffect, useState } from 'react';

const useEllipsesPresent = (ref: MutableRefObject<HTMLParagraphElement | null>) => {
  const [isEllipsesPresent, setIsEllipsesPresent] = useState<boolean>(false);

  useEffect(() => {
    const element = ref.current;

    if (element) {
      const isEllipsisActive =
        element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;

      if (isEllipsisActive) {
        setIsEllipsesPresent(true);
      } else {
        setIsEllipsesPresent(false);
      }
    }
  }, [ref]);

  return isEllipsesPresent;
};

export default useEllipsesPresent;
