export const getTimeAgo = (date: string) => {
  const dateObj = new Date(date);
  const now = new Date();

  const timeDifferenceInSeconds = Math.floor((now.getTime() - dateObj.getTime()) / 1000);

  if (timeDifferenceInSeconds >= 86400) {
    const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
    return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
  } else if (timeDifferenceInSeconds >= 3600) {
    const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
    return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
  } else if (timeDifferenceInSeconds >= 60) {
    const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
    return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
  } else {
    return 'Just now';
  }
};

export const convertToMMDDYYYY = (isoTimestamp: string) => {
  const date = new Date(isoTimestamp);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatDate = (data: string) => {
  const date = new Date(data);

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  function getDayWithOrdinal(day: number) {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }

  const formattedDay = getDayWithOrdinal(day);

  const finalFormattedDate = `${formattedDay} ${month}, ${year}`;

  return finalFormattedDate;
};

export const formatTime = (time: string) => {
  const [hours, minutes] = time.split(':');
  let period = 'AM';

  let formattedHours = parseInt(hours);

  if (formattedHours >= 12) {
    period = 'PM';
    if (formattedHours > 12) {
      formattedHours -= 12;
    }
  }

  const formattedTime = `${formattedHours.toString().padStart(2, '0')}:${minutes} ${period}`;
  return formattedTime;
};
