import { FC } from 'react';
import classNames from 'classnames';

import { ImgUrlFolders } from 'types';
import { useFileDownload } from 'hooks';
import { CustomTypography } from 'components';
import { DownloadTemplateIcon, TemplateFile } from 'assets/icons';
import usePdfDownload from 'hooks/usePDFDownload';

import styles from './TemplateBlock.module.scss';

import type { TTemplateBlockProps } from './types';

const TemplateBlock: FC<TTemplateBlockProps> = ({ template, fileName }) => {
  const { fetchDataAndDownload, isStarted } = useFileDownload({
    fileUrl: template?.media_url_id,
    folder: ImgUrlFolders.CATALOG,
  });

  const { fetchDataAndDownload: fetchPdfData, isStarted: isStartedPdf } = usePdfDownload({
    fileUrl: template?.media_url_id,
    folder: ImgUrlFolders.CATALOG,
    withToastMessages: true,
  });

  const fetchData = template?.media_url_id?.endsWith('.pdf') ? fetchPdfData : fetchDataAndDownload;
  const isLoaded = template?.media_url_id?.endsWith('.pdf') ? isStartedPdf : isStarted;

  const handleDataFetching = () => {
    if (!isLoaded) {
      fetchData();
    }
  };

  const iconButtonClasses = classNames(styles.container__download, {
    [styles.container__download_disabled]: isLoaded,
  });

  return (
    <div className={styles.container}>
      <TemplateFile height={20} width={20} />
      <CustomTypography className={styles.container__title}>{fileName}</CustomTypography>
      <div className={iconButtonClasses} onClick={handleDataFetching} role='button'>
        <DownloadTemplateIcon />
        Download
      </div>
    </div>
  );
};

export default TemplateBlock;
