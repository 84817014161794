import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { useAppSelector } from 'hooks';
import { CustomSkeleton, CustomTypography } from 'components';
import { currentCustomerLoadingSelector } from 'store/slices/customersSlices/selectors';

import styles from './TasksViewInfo.module.scss';

import type { TTasksViewInfoProps } from './types';

const TasksViewInfo: FC<TTasksViewInfoProps> = ({
  title,
  subtitle,
  isClickable = false,
  link = '',
}) => {
  const isLoad = useAppSelector(currentCustomerLoadingSelector);

  const subtitleClasses = classNames(styles.container__subtitle, {
    [styles.container__subtitle_link]: isClickable,
  });

  return (
    <Box className={styles.container}>
      <CustomTypography className={styles.container__title}>{title}:</CustomTypography>

      <CustomTypography className={subtitleClasses}>
        {!isLoad ? (
          isClickable ? (
            <a href={link} target='blank'>
              {subtitle}
            </a>
          ) : (
            subtitle
          )
        ) : (
          <CustomSkeleton height={15} width={100} borderRadius='5px' />
        )}
      </CustomTypography>
    </Box>
  );
};

export default TasksViewInfo;
