import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';

import { activitiesApi } from 'api';
import { BlueAddIcon, FilledPlusIcon } from 'assets/icons';
import { getAllNotices } from 'store/slices/activitiesSlices/thunks';
import {
  allNoticesSelector,
  noticesLimitSelector,
  noticesOffsetSelector,
  allNoticesLoadingSelector,
} from 'store/slices/activitiesSlices/selector';
import { LIMIT, OFFSET } from 'constants/Pagination';
import useFetcher from 'hooks/useFetcher';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { userPermissionsSelector } from 'store/slices/authSlice/selectors';
import {
  Button,
  EmptyTitle,
  LoadButton,
  CustomTypography,
  ShowAfterAccessView,
  CreateNoticeDrawer,
} from 'components';
import { changeNoticePage } from 'store/slices/activitiesSlices';
import { PermissionMessages } from 'constants/PermissionMessages';
import { Colors } from 'types';

import { breakPoints } from '../../../constants';
import CreateNoticeModal from '../CreateNoticeModal';

import NoticeItem from './NoticeCard';
import styles from './NoticeBoard.module.scss';

const NoticeBoard = () => {
  const dispatch = useAppDispatch();

  const { isAccessToNotices } = useAppSelector(userPermissionsSelector);

  const [openModal, setOpenModal] = useState(false);
  const noticeData = useAppSelector(allNoticesSelector);
  const noticeDataLoading = useAppSelector(allNoticesLoadingSelector);
  const noticeLimit = useAppSelector(noticesLimitSelector);
  const noticesOffset = useAppSelector(noticesOffsetSelector);

  useEffect(() => {
    dispatch(getAllNotices({ offset: 0, limit: noticeLimit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, getNewData, isLoad } = useFetcher(
    LIMIT,
    OFFSET,
    noticeData?.data,
    activitiesApi.getAllNotices,
  );

  const openNoticeModal = () => {
    setOpenModal(true);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const noticeItems = useMemo(() => {
    const reversedData = data && [...data];

    return reversedData?.map(({ title, body, name, created_at, id, updated_at, created_by }) => {
      const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256,
      )}, ${Math.floor(Math.random() * 256)})`;

      const randomBackgroundColor = `${randomColor.slice(0, -1)}, 0.1)`;

      return (
        <NoticeItem
          id={id}
          key={id}
          body={body}
          name={name}
          title={title}
          created_by={name}
          updated_at={updated_at}
          created_at={created_at}
          userId={Number(created_by)}
          randomColors={{
            randomColor,
            randomBackgroundColor,
          }}
        />
      );
    });
  }, [data]);

  const { width } = useWindowSize();

  const asADrawer = Number(width) <= breakPoints.TABLET_L;

  const boardScrollClasses = classNames(styles.board__scroll, {
    [styles.board__scroll_empty]: !noticeData?.data?.length,
    [styles.board__scroll_more]: noticeData?.data?.length > 2,
  });

  const handleGetNewData = async () => {
    if (!isLoad) {
      const newOffset = noticeLimit + noticesOffset;
      dispatch(changeNoticePage(newOffset));
      getNewData(noticeLimit, newOffset);
    }
  };

  return (
    <div className={styles.board_box}>
      <div className={styles.board_box_head}>
        <CustomTypography className={styles.board_box_title}>Notice Board:</CustomTypography>
        {Number(width) >= breakPoints.WIDE_XL ? (
          <Button
            isUppercase={false}
            onClick={openNoticeModal}
            disabled={!isAccessToNotices}
            startIcon={<FilledPlusIcon />}
            className={styles.board_box_button}
            tooltipMessage={!isAccessToNotices ? PermissionMessages.NoticeMessage : undefined}
          >
            Create Notice
          </Button>
        ) : (
          <Tooltip title={!isAccessToNotices ? PermissionMessages.NoticeMessage : undefined}>
            <button
              disabled={!isAccessToNotices}
              onClick={openNoticeModal}
              style={{
                cursor: isAccessToNotices ? 'pointer' : 'auto',
                opacity: isAccessToNotices ? 1 : 0.7,
              }}
            >
              <BlueAddIcon />
            </button>
          </Tooltip>
        )}
      </div>
      <ShowAfterAccessView emptyMessageTitle='Notices' isShow={true}>
        <div className={styles.board}>
          <div className={boardScrollClasses}>
            {noticeData?.total_count ? (
              noticeItems
            ) : (
              <EmptyTitle title='No notices added yet' loadingDone={noticeDataLoading} />
            )}
            {noticeData?.total_count > data?.length && (
              <div className={styles.board__scroll_load}>
                <LoadButton
                  inLoad={isLoad}
                  color={Colors.WHITE}
                  onClick={handleGetNewData}
                  backgroundColor={Colors.SAPPHIRE}
                >
                  Load More
                </LoadButton>
              </div>
            )}
          </div>
        </div>
        {!asADrawer ? (
          <CreateNoticeModal
            open={openModal}
            onClose={onClose}
            onCancel={onClose}
            title='Give Your Notice A Title:*'
            submitBtnText='Save'
            cancelBtnText='Cancel'
          />
        ) : (
          <CreateNoticeDrawer open={openModal} handleClose={onClose} />
        )}
      </ShowAfterAccessView>
    </div>
  );
};
export default NoticeBoard;
