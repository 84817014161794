import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'image',
    headerName: 'Image',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params.value;
    },
  },
  {
    field: 'itemName',
    headerName: 'Item Name',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: 0 }} title={params?.value} />;
    },
  },

  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: 0 }} title={params?.value} />;
    },
  },
  {
    field: 'quantity',
    headerName: 'quantity',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: 0 }} title={params?.value} />;
    },
  },
];

export const rowsList = [
  {
    id: 1,
    itemNumber: 'INV-001',
    image: 'Image',
    description: 'description1',
    quantity: 'quantity1',
    manufacturer: 'manufacturer1',
    vendor: 'vendor1',
  },
  {
    id: 2,
    itemNumber: 'INV-002',
    image: 'Image',
    description: 'description2',
    quantity: 'quantity2',
    manufacturer: 'manufacturer2',
    vendor: 'vendor2',
  },
  {
    id: 3,
    itemNumber: 'INV-003',
    image: 'Image3',
    description: 'description3',
    quantity: 'quantity3',
    manufacturer: 'manufacturer3',
    vendor: 'vendor3',
  },
];
