import { FontNames } from 'types';

export const inputConfigurations = [
  {
    id: 1,
    type: 'text',
    name: 'name',
    required: false,
    borderRadius: '5px',
    label: 'Template Name',
    placeholder: 'Template Name:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 2,
    type: 'text',
    name: 'version_tag',
    required: false,
    borderRadius: '5px',
    label: 'Version Tag',
    placeholder: 'Version Tag:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
  {
    id: 3,
    type: 'text',
    name: 'description',
    required: false,
    borderRadius: '5px',
    label: 'Description',
    placeholder: 'Description:',
    fontFamily: FontNames.CIRCULAR_REG,
  },
];
