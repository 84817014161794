import { getValue } from '../getValue';

import { TExcelCreateFormValuesGeneratorParams } from './types';

const excelCreateFormValuesGenerator = ({
  inEditMode,
  currentExcel,
}: TExcelCreateFormValuesGeneratorParams) => ({
  file_upload: getValue(inEditMode, currentExcel?.media_url_id),
  name: getValue(inEditMode, currentExcel?.name),
  Version_Tag: getValue(inEditMode, currentExcel?.version_tag),
  description: getValue(inEditMode, currentExcel?.description),
  created_by: getValue(inEditMode, currentExcel?.created_by),
  created_at: getValue(inEditMode, currentExcel?.created_at),
  updated_at: getValue(inEditMode, currentExcel?.updated_at),
});

export default excelCreateFormValuesGenerator;
