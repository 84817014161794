import { TimeSlider } from '@vidstack/react';
import './TimeSlider.scss';

const TimeSliderCustom = () => (
  <div
    style={{
      display: 'flex',
      padding: '0 15px',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <TimeSlider.Root className='vds-time-slider vds-slider'>
      <TimeSlider.Track className='vds-slider-track' />
      <TimeSlider.TrackFill className='vds-slider-track-fill vds-slider-track' />
      {/* <TimeSlider.Progress className='vds-slider-progress vds-slider-track' /> */}
      <TimeSlider.Thumb className='vds-slider-thumb' />
    </TimeSlider.Root>
  </div>
);

export default TimeSliderCustom;
