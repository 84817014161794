import { FC } from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ValidationImage from 'components/views/PaymentOnboarding/validationImage';
import SectionHead from 'components/views/PaymentOnboarding/SectionHead';
import { CustomTextField, Select, TableCheckbox, CustomTypography } from 'components';
import { Colors, FontNames } from 'types';
import { stateOptions } from 'constants/States';
import { businessOptions } from 'constants/BusinessTypes';
import { verificationOptions } from 'constants/verificationOptions';
import { accountHolderOptions } from 'constants/AccountSettings';
import { mccOptions } from 'constants/mccOptions';
import { FormLayout } from 'components';
import { NumberControlledInput } from 'components';

import styles from './PaymentOnboarding.module.scss';
import { TPaymentOnboardingProps } from './types';

const PaymentOnboarding: FC<TPaymentOnboardingProps> = ({
  errors,
  control,
  handleClick,
  getFileFront,
  getFileLast,
}) => {
  const miniDate = dayjs().subtract(18, 'year').toDate();

  return (
    <Box className={styles.container}>
      <Box className={styles.container__content}>
        <FormLayout title='' className={styles.container__content__form}>
          <SectionHead
            title='Account Details'
            subtitle={`(An email address associated with the account.)`}
          />
          <Box className={styles.container__card}>
            <Controller
              name='account_email'
              defaultValue=''
              control={control}
              rules={{ required: 'Email is required' }}
              render={({ field }) => (
                <CustomTextField
                  required
                  {...field}
                  error={!!errors.account_email}
                  helperText={errors?.account_email?.message}
                  label='Email'
                  placeholder='Email:'
                  fontFamily={FontNames.CIRCULAR_REG}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
              )}
            />
          </Box>
        </FormLayout>
        <FormLayout title='' className={styles.container__content__form}>
          <SectionHead
            title='Business Profile'
            subtitle={`(Business information about the account.)`}
          />
          <Box className={styles.container__card}>
            <Controller
              name='url'
              defaultValue=''
              control={control}
              rules={{ required: 'Business Website is required' }}
              render={({ field }) => (
                <CustomTextField
                  required
                  {...field}
                  error={!!errors.url}
                  helperText={errors?.url?.message}
                  label='Business Website'
                  placeholder='Business Website:'
                  fontFamily={FontNames.CIRCULAR_REG}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
              )}
            />
            <Box className={styles.container__card__items}>
              <Controller
                name='business_type'
                control={control}
                defaultValue=''
                rules={{ required: 'Business Type is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='Business Type:*'
                    onChange={onChange}
                    options={businessOptions}
                    error={!!errors.business_type}
                    helperText={errors?.business_type?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='mcc'
                control={control}
                defaultValue=''
                rules={{ required: 'Industry Type is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='Industry Type:*'
                    onChange={onChange}
                    options={mccOptions}
                    error={!!errors.mcc}
                    helperText={errors?.mcc?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
          </Box>
        </FormLayout>
        <FormLayout title='' className={styles.container__content__form}>
          <SectionHead
            title='Company Details'
            subtitle={`(Information about the company or business.)`}
          />
          <Box className={styles.container__card}>
            <Controller
              name='company_name'
              defaultValue=''
              control={control}
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <CustomTextField
                  required
                  {...field}
                  error={!!errors.company_name}
                  helperText={errors?.company_name?.message}
                  label='Name'
                  placeholder='Name:'
                  fontFamily={FontNames.CIRCULAR_REG}
                  labelColor={Colors.ENIGMATIC_MIDNIGHT}
                />
              )}
            />
            <Box className={styles.container__card__items}>
              <NumberControlledInput
                required
                control={control}
                name='company_phone_number'
                label='Phone Number'
                error={!!errors.company_phone_number}
                placeholder='Phone Number:*'
                fontFamily={FontNames.CIRCULAR_REG}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                helperText={errors.company_phone_number ? errors.company_phone_number.message : ''}
              />
              <Controller
                name='tax_id'
                defaultValue=''
                control={control}
                rules={{ required: 'Tax Id is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.tax_id}
                    helperText={errors?.tax_id?.message}
                    label='Tax Id'
                    placeholder='Tax Id:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='company_line1'
                defaultValue=''
                control={control}
                rules={{ required: 'Line 1 is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.company_line1}
                    helperText={errors?.company_line1?.message}
                    label='Line 1'
                    placeholder='Line 1:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='company_line2'
                defaultValue=''
                control={control}
                rules={{ required: 'Line 2 is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.company_line2}
                    helperText={errors?.company_line2?.message}
                    label='Line 2'
                    placeholder='Line 2:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='company_state'
                control={control}
                defaultValue=''
                rules={{ required: 'State is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='State:*'
                    onChange={onChange}
                    options={stateOptions}
                    error={!!errors.company_state}
                    helperText={errors?.company_state?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='company_city'
                defaultValue=''
                control={control}
                rules={{ required: 'City is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.company_city}
                    helperText={errors?.company_city?.message}
                    label='City'
                    placeholder='City:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='company_zip'
                control={control}
                defaultValue=''
                rules={{ required: 'Zip code is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Zip'
                    placeholder='Zip:*'
                    error={!!errors.company_zip}
                    helperText={errors?.company_zip?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
          </Box>
        </FormLayout>
        <FormLayout title='' className={styles.container__content__form}>
          <SectionHead
            title='Owner Details'
            subtitle={`(Details of the person who is the owner of the account’s legal entity.)`}
          />
          <Box className={styles.container__card}>
            <Box className={styles.container__card__items}>
              <Controller
                name='owner_firstname'
                defaultValue=''
                control={control}
                rules={{ required: 'First Name is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.owner_firstname}
                    helperText={errors?.owner_firstname?.message}
                    label='First Name'
                    placeholder='First Name:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='owner_lastname'
                defaultValue=''
                control={control}
                rules={{ required: 'Last Name is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.owner_lastname}
                    helperText={errors?.owner_lastname?.message}
                    label='Last Name'
                    placeholder='Last Name:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='owner_id_number'
                defaultValue=''
                control={control}
                rules={{ required: 'Id Number is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.owner_id_number}
                    helperText={errors?.owner_id_number?.message}
                    label='Id Number'
                    placeholder='Id Number:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='owner_dob'
                defaultValue=''
                control={control}
                rules={{ required: 'Date Of Birth is required' }}
                render={({ field }) => (
                  <DatePicker
                    //disableFuture
                    maxDate={miniDate}
                    sx={{
                      '.MuiInputBase-root': {
                        '&:hover': {
                          fieldset: {
                            borderColor: '#2067dd',
                          },
                        },
                      },
                    }}
                    {...field}
                    label='DOB'
                    className={styles.container__content__date}
                    value={field.value ? dayjs(field.value).toDate() : null} // Convert date to JavaScript Date object
                    onChange={(newValue) => {
                      field.onChange(dayjs(newValue).format('MM-DD-YYYY')); // Format the date before saving it
                    }}
                  />
                )}
              />
              <NumberControlledInput
                required
                control={control}
                name='owner_phone_number'
                label='Phone Number'
                error={!!errors.owner_phone_number}
                placeholder='Phone Number:*'
                fontFamily={FontNames.CIRCULAR_REG}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                helperText={errors.owner_phone_number ? errors.owner_phone_number.message : ''}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='owner_line1'
                defaultValue=''
                control={control}
                rules={{ required: 'Line 1 is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.owner_line1}
                    helperText={errors?.owner_line1?.message}
                    label='Line 1'
                    placeholder='Line 1:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='owner_line2'
                defaultValue=''
                control={control}
                rules={{ required: 'Line 2 is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.owner_line2}
                    helperText={errors?.owner_line2?.message}
                    label='Line 2'
                    placeholder='Line 2:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='owner_state'
                control={control}
                defaultValue=''
                rules={{ required: 'State is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='State:*'
                    onChange={onChange}
                    options={stateOptions}
                    error={!!errors.owner_state}
                    helperText={errors?.owner_state?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='owner_city'
                defaultValue=''
                control={control}
                rules={{ required: 'City is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.owner_city}
                    helperText={errors?.owner_city?.message}
                    label='City'
                    placeholder='City:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='owner_zip'
                control={control}
                defaultValue=''
                rules={{ required: 'Zip code is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Zip'
                    placeholder='Zip:*'
                    error={!!errors.owner_zip}
                    helperText={errors?.owner_zip?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
          </Box>
        </FormLayout>
        <FormLayout title='' className={styles.container__content__form}>
          <SectionHead
            title='Representative Details'
            subtitle={`(Person nominated by the business to provide information about themselves, and general information about the account.)`}
          />
          <Box className={styles.container__card}>
            <Box className={styles.container__card__items}>
              <Controller
                name='representative_firstname'
                defaultValue=''
                control={control}
                rules={{ required: 'First Name is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_firstname}
                    helperText={errors?.representative_firstname?.message}
                    label='First Name'
                    placeholder='First Name:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='representative_lastname'
                defaultValue=''
                control={control}
                rules={{ required: 'Last Name is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_lastname}
                    helperText={errors?.representative_lastname?.message}
                    label='Last Name'
                    placeholder='Last Name:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='representative_line1'
                defaultValue=''
                control={control}
                rules={{ required: 'Line 1 is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_line1}
                    helperText={errors?.representative_line1?.message}
                    label='Line 1'
                    placeholder='Line 1:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='representative_line2'
                defaultValue=''
                control={control}
                rules={{ required: 'Line 2 is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_line2}
                    helperText={errors?.representative_line2?.message}
                    label='Line 2'
                    placeholder='Line 2:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='representative_state'
                control={control}
                defaultValue=''
                rules={{ required: 'State is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='State:*'
                    onChange={onChange}
                    options={stateOptions}
                    error={!!errors.representative_state}
                    helperText={errors?.representative_state?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='representative_city'
                defaultValue=''
                control={control}
                rules={{ required: 'City is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_city}
                    helperText={errors?.representative_city?.message}
                    label='City'
                    placeholder='City:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='representative_zip'
                control={control}
                defaultValue=''
                rules={{ required: 'Zip code is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    label='Zip'
                    placeholder='Zip:*'
                    error={!!errors.representative_zip}
                    helperText={errors?.representative_zip?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='representative_email'
                defaultValue=''
                control={control}
                rules={{ required: 'Email is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_email}
                    helperText={errors?.representative_email?.message}
                    label='Email'
                    placeholder='Email:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='representative_dob'
                defaultValue=''
                control={control}
                rules={{ required: 'Date Of Birth is required' }}
                render={({ field }) => (
                  <DatePicker
                    //disableFuture
                    maxDate={miniDate}
                    {...field}
                    className={styles.container__content__date}
                    label='DOB'
                    value={field.value ? dayjs(field.value).toDate() : null} // Convert date to JavaScript Date object
                    onChange={(newValue) => {
                      field.onChange(dayjs(newValue).format('MM-DD-YYYY')); // Format the date before saving it
                    }}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <NumberControlledInput
                required
                control={control}
                name='representative_number'
                label='Phone Number'
                error={!!errors.representative_number}
                placeholder='Phone Number:*'
                fontFamily={FontNames.CIRCULAR_REG}
                labelColor={Colors.ENIGMATIC_MIDNIGHT}
                helperText={
                  errors.representative_number ? errors.representative_number.message : ''
                }
              />
              <Controller
                name='ssn_last4'
                defaultValue=''
                control={control}
                rules={{ required: 'SSN Last 4 digits is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.ssn_last4}
                    helperText={errors?.ssn_last4?.message}
                    label='SSN Last 4 digits'
                    placeholder='SSN Last 4 digits:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='representative_id_number'
                defaultValue=''
                control={control}
                rules={{ required: 'Id number is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.representative_id_number}
                    helperText={errors?.representative_id_number?.message}
                    label='Id Number'
                    placeholder='Id Number:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='document_type'
                control={control}
                defaultValue=''
                rules={{ required: 'Document is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='Verification Document:*'
                    onChange={onChange}
                    options={verificationOptions}
                    error={!!errors.document_type}
                    helperText={errors?.document_type?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <ValidationImage
                name='firstPage'
                control={control}
                title='Front Page'
                getFile={getFileFront}
                htmlFor='front_page'
              />
              <ValidationImage
                name='lastPage'
                control={control}
                title='Last Page'
                getFile={getFileLast}
                htmlFor='last_page'
              />
            </Box>
          </Box>
        </FormLayout>
        <FormLayout title='' className={styles.container__content__form}>
          <SectionHead
            title='Bank Account Details'
            subtitle={`(Bank accounts are financial accounts for the purpose of transferring funds )`}
          />
          <Box className={styles.container__card}>
            <Box className={styles.container__card__items}>
              <Controller
                name='acc_number'
                defaultValue=''
                control={control}
                rules={{ required: 'Account Number is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.acc_number}
                    helperText={errors?.acc_number?.message}
                    label='Account Number'
                    placeholder='Account Number:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='acc_holder_name'
                defaultValue=''
                control={control}
                rules={{ required: 'Account Holder Name is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.acc_holder_name}
                    helperText={errors?.acc_holder_name?.message}
                    label='Account Holder Name'
                    placeholder='Account Holder Name:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
            <Box className={styles.container__card__items}>
              <Controller
                name='acc_holder_type'
                control={control}
                defaultValue=''
                rules={{ required: 'Account Holder Type is required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    required
                    value={value}
                    multiple={false}
                    maxWidth='100%'
                    label='Account Holder Type:*'
                    onChange={onChange}
                    options={accountHolderOptions}
                    error={!!errors.acc_holder_type}
                    helperText={errors?.acc_holder_type?.message}
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
              <Controller
                name='routing_number'
                defaultValue=''
                control={control}
                rules={{ required: 'Routing Number is required' }}
                render={({ field }) => (
                  <CustomTextField
                    required
                    {...field}
                    error={!!errors.routing_number}
                    helperText={errors?.routing_number?.message}
                    label='Routing Number'
                    placeholder='Routing Number:'
                    fontFamily={FontNames.CIRCULAR_REG}
                    labelColor={Colors.ENIGMATIC_MIDNIGHT}
                  />
                )}
              />
            </Box>
          </Box>
        </FormLayout>
        <Box className={styles.container__content__agreement}>
          <TableCheckbox onClick={handleClick}></TableCheckbox>
          <CustomTypography style={{ margin: '9px' }}>
            By registering your account, you agree to our Services Agreement and the Stripe
            Connected Account Agreement.
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};
export default PaymentOnboarding;
