export const sxStyles = {
  zIndex: 3,
  width: '100%',
  position: 'relative',
  border: 'none',

  '@media (max-width: 768px)': {
    minWidth: '1000px',
  },

  '& .MuiDataGrid-overlay': {
    display: 'none !important',
  },

  '& .MuiDataGrid-columnHeader': {
    border: 'none',
    padding: 0,

    '&:first-of-type': {
      paddingLeft: '16px ',

      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        '& > div': {
          paddingLeft: '16px !important',
        },
      },
    },
  },

  '& .MuiDataGrid-cell ': {
    borderLeft: '1px dashed #E6E6E6',
    borderBottom: 'none',
    paddingLeft: '0 !important',

    '&:last-child ': {
      borderBottom: 'none',
    },

    '& > div': {
      paddingLeft: '16px !important',
    },
    '&:first-of-type ': {
      '& > div': {
        paddingLeft: '32px !important',
      },
    },
  },

  '& .MuiDataGrid-row': {
    borderBottom: '1px dashed #E6E6E6',

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  '& .MuiDataGrid-virtualScroller': {
    maxWidth: '100%',
    borderRight: '1px dashed #E6E6E6',
    borderBottom: '1px dashed #E6E6E6',
  },

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    borderColor: '#E6E6E6',
    borderStyle: 'dashed',
  },

  '& .MuiDataGrid-columnHeaderRow': {
    width: '100%',
  },

  '& .MuiDataGrid-columnHeadersInner': {
    width: '100%',
  },

  '& .MuiDataGrid-columnHeaders': {
    background: '#D2E1F9',
    textTransform: 'capitalize',
    padding: '16px 0 !important',
    maxHeight: '82px !important',
  },

  '& .MuiDataGrid-virtualScrollerContent': {
    width: '100% !important',
  },

  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },

  '& .MuiDataGrid-cell--withRightBorder': {
    borderStyle: 'dashed',
  },

  '& .MuiDataGrid-cellCheckbox': {
    border: 'none !important',
    minWidth: 'unset !important',

    '& span': {
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'CircularStdRegular',
    fontSize: '14px',
    lineHeight: 'normal',
    letterSpacing: '0.08px',
  },

  '& .MuiDataGrid-cellContent': {
    paddingLeft: '9px !important',
    fontFamily: 'CircularStdRegular',
  },

  '& .MuiDataGrid-cell>img': {
    border: '1px dashed #ccc',
  },

  '& .MuiDataGrid-cell::nth-of-type(-n+2)': {
    paddingLeft: '32px',
  },

  '& .MuiDataGrid-cell': {
    '& a': {
      paddingLeft: '12px',
    },

    '&:last-child': {
      justifyContent: 'center',
    },

    ' .MuiAvatar-square': {
      width: '24px !important',
      height: '24px !important',
      border: 'none !important',

      '& img': {
        width: '24px',
        height: '24px',
      },
    },
  },

  '& .MuiDataGrid-columnSeparator--sideRight': {
    display: 'none !important',
  },

  '& .MuiDataGrid-columnHeaderCheckbox': {
    minWidth: 'unset !important',
    width: 'unset !important',

    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      width: '24px',
      height: '24px',

      '& div': {
        paddingLeft: '0 !important',
      },

      '&  span': {
        padding: '0 !important',
      },
    },
  },
};

export const sxMenuStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '0.5px dashed #ccc)',
    background: '#fff',
    padding: '10px 8px',

    '& .MuiMenuItem-root ': {
      display: 'flex',
      gap: '8px',
      background: 'white',

      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
};
export const sxMenuShareStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px 10px 0 0',

    '& .MuiList-root ': {
      padding: '0 !important',
    },
  },
};
