import { FC, useRef, useState } from 'react';
import { useController } from 'react-hook-form';

import { Colors, FontNames } from 'types';
import { ControlledInput } from 'components';
import { useHover, useOnClickOutside } from 'hooks';
import { EditIconOutLined, TrashIcon } from 'assets/icons';

import styles from './EditableTab.module.scss';

import type { TEditableTabProps } from './types';

const EditableTab: FC<TEditableTabProps> = ({
  name,
  index,
  remove,
  control,
  onClick,
  activeTab,
  sectionsLength,
  isDisabled = false,
}) => {
  const [isStartEdit, setIsStartEdit] = useState<boolean>(false);

  const itemRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLParagraphElement | null>(null);

  const { isHovering } = useHover(buttonRef, 200, index === activeTab);

  const {
    field: { value },
    formState: { errors },
  } = useController({ name, control });

  const haveError = errors?.sections?.[index]?.name;
  const haveErrorInSection = errors?.sections?.[index];

  const borderColor = index === activeTab ? Colors.SAPPHIRE : Colors.OBSIDIAN_AURA;

  const color =
    haveError || haveErrorInSection
      ? Colors.DANGER
      : index === activeTab && !haveError && !haveErrorInSection
      ? Colors.SAPPHIRE
      : Colors.OBSIDIAN_AURA;

  const handleClose = () => {
    if (haveError) return;
    setIsStartEdit(false);
  };

  useOnClickOutside(itemRef, handleClose);

  const handleRemoveSection = (index: number) => {
    if (index !== 0) {
      onClick(index - 1);
      remove(index);
    } else {
      remove(index);
      onClick(0);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();

      if (!haveError) {
        setIsStartEdit(false);
      }
    }
  };

  return (
    <>
      {isStartEdit && (
        <div
          ref={itemRef}
          className={styles.wrapper}
          style={{
            color: color,
            borderColor: haveError
              ? Colors.DANGER
              : index === activeTab && !haveError
              ? Colors.SAPPHIRE
              : Colors.OBSIDIAN_AURA,
          }}
          onClick={() => {
            onClick(index);
          }}
        >
          <ControlledInput
            required
            name={name}
            padding='5px'
            placeholder=''
            maxWidth='100px'
            control={control}
            error={!!haveError}
            backgroundColor={Colors.WHITE}
            borderColor='transparent'
            onKeyDown={handleKeyDown}
            className={styles.container}
            helperText={haveError?.message}
            fontFamily={FontNames.CIRCULAR_MEDIUM}
          />
        </div>
      )}
      <button
        title={value as string}
        type='button'
        className={styles.wrapper__button}
        onClick={(event) => {
          event.stopPropagation();
          if (!isHovering) {
            onClick(index);
          }
        }}
        onMouseDown={(event) => event.stopPropagation()}
        style={{
          cursor: isDisabled ? 'auto' : 'pointer',
          display: !isStartEdit ? 'block' : 'none',
          color: color,
          borderBottom: `2px solid ${borderColor}`,
          borderColor: haveError
            ? Colors.DANGER
            : index === activeTab
            ? Colors.SAPPHIRE
            : Colors.OBSIDIAN_AURA,
        }}
      >
        <p ref={buttonRef} className={styles.wrapper__button__text}>
          <span className={styles.wrapper__button__text__main}>{value as string}</span>
        </p>
        {!isDisabled && (
          <EditIconOutLined
            className={styles.container__button__icon}
            onClick={() => setIsStartEdit(true)}
          />
        )}

        {activeTab === index && (sectionsLength > 1 ? true : index !== 0) && !isStartEdit && (
          <TrashIcon
            className={styles.container__button__icon_del}
            onClick={(event) => {
              event.stopPropagation();
              handleRemoveSection(index);
            }}
          />
        )}
      </button>
    </>
  );
};

export default EditableTab;
