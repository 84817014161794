import { FC } from 'react';
import { Box } from '@mui/material';
import moment from 'moment';

import {
  Button,
  DrawerLayout,
  ScrollLayout,
  CustomTypography,
  OrganizationLogo,
} from 'components/shared';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import {
  purchaseOrderDataByIdSelector,
  purchaseOrderItemsSelector,
} from 'store/slices/purchaseOrderSlice/selectors';
import { submitPurchaseOrderItem } from 'store/thunks';
import { userAllRolesSelector } from 'store/slices/authSlice/selectors';
import PurchaseOrderItemsTableEdit from 'containers/PurchaseOrderItems/PurchaseOrderItemsTableEdit';
import { Colors } from 'types';

import { breakPoints } from '../../../../constants';

import styles from './PurchaseOrderForm.module.scss';
import { TPurchaseOrderFormProps } from './types';

const PurchaseOrderForm: FC<TPurchaseOrderFormProps> = ({ itemId, open, onClose }) => {
  const dispatch = useAppDispatch();

  const userRoles = useAppSelector(userAllRolesSelector);
  const purchaseOrderItems = useAppSelector(purchaseOrderItemsSelector);
  const purchaseOrderData = useAppSelector(purchaseOrderDataByIdSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const { width } = useWindowSize();

  const drawerWidth = Number(width) <= breakPoints.TABLET ? 370 : 756;

  const formattedDate = moment(purchaseOrderData?.created_at).format('M/D/YY');

  const submitPO = async () => {
    await dispatch(submitPurchaseOrderItem({ id: Number(purchaseOrderData?.id) }));
    onClose();
  };

  return (
    <DrawerLayout
      open={open}
      padding='40px'
      inCenter={false}
      onClose={onClose}
      width={drawerWidth}
      titleClassName={styles.container__header__logo}
      headerContainerClassName={styles.container__header}
      headerTitle={<OrganizationLogo maxWidth='100px' minWidth='100px' />}
    >
      <Box className={styles.container}>
        <Box className={styles.container__content}>
          <Box className={styles.container__content__left}>
            <CustomTypography className={styles.container__content__left__head}>
              Purchase Order
            </CustomTypography>
            <Box className={styles.container__content__left__box}>
              <CustomTypography className={styles.container__content__left__box__title}>
                Date:
              </CustomTypography>
              <CustomTypography className={styles.container__content__left__box__subtitle}>
                {formattedDate}
              </CustomTypography>
            </Box>
            <Box className={styles.container__content__left__box}>
              <CustomTypography className={styles.container__content__left__box__title}>
                P.O. number:
              </CustomTypography>
              <CustomTypography className={styles.container__content__left__box__subtitle}>
                {purchaseOrderData?.id}
              </CustomTypography>
            </Box>
          </Box>
          <Box className={styles.container__content__right}>
            <div
              className={styles.container__content__right__head}
              title={purchaseOrderData?.vendor_dtl?.name}
            >
              {purchaseOrderData?.vendor_dtl?.name}
            </div>
            <div
              className={styles.container__content__right__main}
              title={purchaseOrderData?.vendor_dtl?.address}
            >
              {purchaseOrderData?.vendor_dtl?.address}
            </div>
            <CustomTypography className={styles.container__content__right__main}>
              {purchaseOrderData?.vendor_dtl?.phone}
            </CustomTypography>
          </Box>
        </Box>
        <ScrollLayout>
          <PurchaseOrderItemsTableEdit itemUuid={itemId} data={purchaseOrderItems?.data} />
        </ScrollLayout>
        <Box className={styles.container__footer}>
          <CustomTypography className={styles.container__footer__content}>
            <span>Total: $</span>
            <span>{purchaseOrderData?.grand_total}</span>
          </CustomTypography>
        </Box>
        <Box className={styles.container__vendor}>
          <CustomTypography className={styles.container__vendor__title}>
            Static Vendor Template Notes
          </CustomTypography>
          <CustomTypography className={styles.container__vendor__subtitle}>
            {purchaseOrderData?.description}
          </CustomTypography>
        </Box>
        <Box className={styles.container__actions}>
          <Button
            color={Colors.SAPPHIRE}
            minWidth='71px'
            onClick={onClose}
            padding='11.5px 12px'
            backgroundColor={Colors.PALE_BLUE}
            className={styles.container__actions__item}
          >
            Cancel
          </Button>
          <Button
            color={Colors.WHITE}
            onClick={submitPO}
            padding='12px 16px'
            backgroundColor={Colors.SAPPHIRE}
            disabled={purchaseOrderData?.status === 'recieved' || isViewer}
            className={styles.container__actions__item}
          >
            Email to Vendor
          </Button>
        </Box>
      </Box>
    </DrawerLayout>
  );
};

export default PurchaseOrderForm;
