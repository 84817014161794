export type TDocument = {
  size: number;
  customer_id: number;
  customer_name: string;
  last_modified: string;
  customer_uuid: string;
};

export type TDocumentTypes = {
  size: number;
  type: string;
  customer_uuid: string;
  last_modified: string;
};

export type TFinalDocument = {
  id: number;
  name: string;
  size: number;
  type: string;
  type_id: number;
  created_at: string;
  updated_at: string;
  file_url_id: string;
  customer_id: string;
  last_modified: string;
  document_type: string;
};

export type TDocumentsListParams = Partial<{
  asc: boolean;
  limit: number;
  offset: number;
  direction: string;
  order_by: SortByEnums | null;
}>;

export enum SortByEnums {
  NAME = 'name',
  SIZE = 'size',
  TYPE = 'type',
  UPDATED = 'last_updated',
  CUSTOMER = 'customer_name',
}

export enum DirectionEnums {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type TDocumentsTypesListParams = {
  id: string;
  options: TDocumentsListParams;
} & Partial<{
  type: string;
}>;

export type TUploadMedia = {
  media: string;
};

export type TDocumentsReturnType = {
  data: TDocument[];
  total_count: number;
};

export type TFinalDocumentReturnType = {
  data: TFinalDocument[];
  total_count: number;
};

export type TInitialState = {
  isViewGrid: boolean;
  documentsLimit: number;
  documentsOffset: number;
  documentLoading: boolean;
  documentTypesLimit: number;
  documentsItemLimit: number;
  documentsItemOffset: number;
  documentTypesOffset: number;
  documentsAsc: DirectionEnums;
  documentsItemLoading: boolean;
  documentTypesLoading: boolean;
  documents: TDocumentsReturnType;
  currentDocumentLoading: boolean;
  documentTypesAsc: DirectionEnums;
  currentDocument: TDocument | null;
  documentTypes: TDocumentTypes[] | [];
  documentsError: Error | null | string;
  documentsItem: TFinalDocumentReturnType;
  documentsItemError: Error | null | string;
  documentTypesError: Error | null | string;
  currentDocumentError: Error | null | string;
};
