import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import { TCreateCatalogVersionItem } from 'store/slices/catalogSlice/types';

import type {
  TEstimateBody,
  TEstimateOptions,
  TEditEstimateSectionById,
  TGetEstimateSectionItems,
  TEditProfitMarginDataBody,
  TSendedEstimateSectionDataOptions,
  TEstimateEditOptions,
  TSendedEstimateFromTemplateData,
  TEstimateFromTemplateOptions,
  TUpdateEstimateFromTemplateData,
} from 'store/slices/estimatesSlice/types';

export const getAllEstimatesRequest = (options: TEstimateOptions) =>
  client.get(endpoints.EstimatesServices.getAllEstimates(), { params: { ...options } });

export const getAllEstimatesFromTemplateRequest = (options: TEstimateFromTemplateOptions) =>
  client.get(endpoints.EstimatesServices.getAllEstimatesFromTemplate(), { params: { ...options } });

export const getEstimatesVersionFromTemplateRequest = (uuid: string) =>
  client.get(endpoints.EstimatesServices.createEstimateVersionsFromTemplate(uuid));

export const createEstimateRequest = (body: TEstimateBody) =>
  client.post(endpoints.EstimatesServices.createEstimate(), body);

export const createEstimateRequestFromTemplate = (estimate: TSendedEstimateFromTemplateData) =>
  client.post(
    endpoints.EstimatesServices.createEstimateFromTemplate(estimate?.estimate_template_id),
    estimate?.body,
  );

export const updateEstimateRequestFromTemplate = ({
  uuid,
  estimate_template_id,
  body,
}: TUpdateEstimateFromTemplateData) =>
  client.put(
    endpoints.EstimatesServices.UpdateEstimateFromTemplate(uuid, estimate_template_id),
    body,
  );

export const getEstimateByIdRequest = (id: number) =>
  client.get(endpoints.EstimatesServices.getEstimateById(id));

export const getElementsByIdRequest = (id: number) =>
  client.get(endpoints.EstimatesServices.getElementsById(), { params: { excel_template_id: id } });

export const getEstimateByUUIDRequest = (uuid: string) =>
  client.get(endpoints.EstimatesServices.getEstimateByUUID(uuid));

export const getEstimateSectionsRequest = (estimate_id: number) =>
  client.get(endpoints.EstimatesServices.getEstimateSections(estimate_id), {
    params: { estimate_id },
  });

export const getEstimateSectionItemsRequest = (options: TGetEstimateSectionItems) => {
  return client.get(endpoints.EstimatesServices.getEstimateSectionItems(options), {
    params: { ...options },
  });
};

export const deleteEstimateSectionRequest = (estimateId: number, sectionId: number) =>
  client.delete(endpoints.EstimatesServices.deleteEstimateSection(estimateId, sectionId));

export const createEstimateSectionRequest = (estimate: TSendedEstimateSectionDataOptions) =>
  client.post(endpoints.EstimatesServices.createEstimateSection(estimate?.id), estimate?.body);

export const deleteEstimateSectionItemRequest = (
  itemId: number,
  sectionId: number,
  estimateId: number,
) =>
  client.delete(
    endpoints.EstimatesServices.deleteEstimateSectionItem(estimateId, sectionId, itemId),
  );

export const deleteEstimateRequest = (id: number) =>
  client.delete(endpoints.EstimatesServices.deleteEstimate(id));

export const editEstimateRequest = ({ id, body }: TEstimateEditOptions) =>
  client.put(endpoints.EstimatesServices.editEstimate(id), body);

export const createEstimateSectionItemRequest = (
  sectionId: number,
  estimateId: number,
  body: TCreateCatalogVersionItem,
) =>
  client.post(endpoints.EstimatesServices.createEstimateSectionItem(estimateId, sectionId), {
    ...body,
  });

export const editProfitMarginRequest = (
  sectionId: number,
  estimateId: number,
  body: TEditProfitMarginDataBody,
) =>
  client.put(endpoints.EstimatesServices.editProfitMargin(estimateId, sectionId), {
    ...body,
  });

export const editEstimateSectionItem = (options: TEditEstimateSectionById) =>
  client.put(endpoints.EstimatesServices.editEstimateSectionById(options), null, {
    params: { ...options },
  });

export const sendEstimateToCustomerRequest = (id: number) =>
  client.post(endpoints.EstimatesServices.sendEstimateToCustomer(id));

export const getEstimateByUuidRequest = (id: string) =>
  client.get(endpoints.EstimatesServices.getEstimateByUuid(id));
