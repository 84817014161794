export type TInvoicesParams = Partial<{
  asc: boolean;
  limit: number;
  offset: number;
  sort_by: SortByEnums | null;
}>;

export enum SortByEnums {
  ID = 'id',
  DUE = 'due_date',
  STATUS = 'status',
  ISSUE = 'issue_date',
  CREATED = 'created_at',
  UPDATED = 'updated_at',
  AMOUNT = 'total_amount',
  CUSTOMER = 'customer_name',
}

export type TInvoice = {
  id: number;
  tax: number;
  notes: string;
  status: string;
  due_date: string;
  discount: number;
  sub_total: number;
  created_at: string;
  updated_at: string;
  issue_date: string;
  customer_id: number;
  total_amount: number;
  media_url_id: string;
  items: TInvoiceItem[];
  shipping_fees: number;
  customer_name: string;
  estimate_name: string;
};

export type TInvoiceItem = {
  part_name: string;
  created_at: string;
  updated_at: string;
  id: number | string;
  amount: number | string;
  quantity: number | string;
  unit_price: number | string;
};

export type TInvoicesReturnType = {
  data: TInvoice[];
  total_count: number;
};

export type TCreateInvoice = {
  tax: number;
  status: string;
  discount: number;
  sub_total: number;
  customer_id: number;
  customer_name: string;
  shipping_fees: number;
  notes: string | null;
  total_amount: number;
  items: TCreateItem[];
  due_date: string | null;
  issue_date: string | null;
};

export type TCreateItem = {
  amount: number;
  part_id: number;
  quantity: number;
  part_name: string;
  unit_price: number;
} & Partial<{
  invoice_id: number;
}>;

export type TUpdateInvoice = {
  tax: number;
  id: number;
  status: string;
  discount: number;
  sub_total: number;
  customer_id: number;
  notes: string | null;
  total_amount: number;
  customer_name: string;
  shipping_fees: number;
  due_date: string | null;
  issue_date: string | null;
};

export type TUpdateItem = {
  id: number;
  amount: number;
  quantity: number;
  part_id: number;
  part_name: string;
  unit_price: number;
} & Partial<{
  name: string;
}>;

export type TInitialState = {
  invoiceAsc: boolean;
  invoiceLimit: number;
  invoiceOffset: number;
  invoicesLoading: boolean;
  invoices: TInvoicesReturnType;
  currentInvoiceByIdLoad: boolean;
  currentInvoiceById: TInvoice | null;
  invoicesError: Error | null | string;
  currentInvoiceByIdError: Error | null | string;
};
