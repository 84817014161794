import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { getAllTags } from 'store/slices/teamsSlice/thunks';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { TOption } from 'components/shared/Select/types';
import { TStateProps } from 'components/views/Cards/TeamCard/types';
import { ArrowDown } from 'assets/icons';
import {
  Button,
  DrawerLayout,
  CustomTextField,
  CustomTypography,
  ActivityDropDown,
} from 'components/shared';
import { Colors, TFormSubmit } from 'types';
import { filterUsers } from 'containers/Team/utils';
import { TTag } from 'store/slices/teamsSlice/types';
import { TMember } from 'components/shared/Team/types';
import DeletePopup from 'components/views/DeletePopup';
import UserComponent from 'components/shared/Team/UserComponent';
import JobTypesComplete from 'components/views/JobTypesComplete';
import { allTagsSelector } from 'store/slices/teamsSlice/selectors';
import { userDataSelector } from 'store/slices/authSlice/selectors';

import { breakPoints } from '../../../../constants';

import { TEditTeamModal } from './types';
import styles from './EditTeamModal.module.scss';

const EditTeamModal: React.FC<TEditTeamModal & TStateProps> = ({
  item,
  open,
  closeDrawer,
  handleEditTeam,
  filteredMembers,
}) => {
  const { width } = useWindowSize();
  const tags = useAppSelector(allTagsSelector);
  const currentUser = useAppSelector(userDataSelector);

  const [selectedIds, setSelectedIds] = useState<(number | string)[]>([]);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [addMore, setAddMore] = useState<boolean>(false);
  const [addTag, setAddTag] = useState<boolean>(false);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  const uniqueIds = new Set();

  const handleAddTags = () => {
    setAddMore(true);
    setAutocompleteOpen(false);
  };

  const filteredTags = item?.tags?.filter((item) => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      return true;
    }
    return false;
  });

  const finalMemberList = useMemo(() => filterUsers(item.members), [item.members]);

  const leads = useMemo(
    () => finalMemberList?.filter((item) => item.position === 'lead'),
    [finalMemberList],
  );

  const members = useMemo(
    () => finalMemberList?.filter((item) => item.position === 'member'),
    [finalMemberList],
  );

  const [teamLeads, setTeamLeads] = useState<TMember[]>(leads);
  const [teamMembers, setTeamMembers] = useState<TMember[]>(members);
  const [selectedActivityTypes, setSelectedActivityType] = useState<TOption[]>(filteredTags);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (leads && !open) {
      setTeamLeads(leads);
    }
  }, [leads, open]);

  useEffect(() => {
    if (members && !open) {
      setTeamMembers(members);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, open]);

  const filtered = filteredMembers?.filter((item) => {
    if (!selectedIds?.includes(item.user_id as number)) {
      return item;
    }
  });

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = useForm({
    values: {
      name: item.name,
      teamActivity: item.tags,
      teamDetails: item.description,
      teamLeader: item?.members?.filter((el) => el.position === 'lead'),
      teamMembers: item?.members?.filter((el) => el.position === 'member'),
    },
  });

  useEffect(() => {
    dispatch(getAllTags());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ids = teamLeads.map((el) => el.value || el.user_id) || [];
    const memberIds = teamMembers
      .filter((el) => el.user_id !== currentUser?.id)
      .map((el) => el.value || el.user_id);

    const returnedIds = ids.concat(memberIds);

    setSelectedIds(returnedIds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamLeads, teamMembers]);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const watchResult = watch();

  const isDisabled = watchResult && watchResult.name?.trim() && watchResult.teamDetails?.trim();

  const membersIds = teamMembers?.map((el) => (el?.value as number) || (el?.user_id as number));
  const leadersIds = teamLeads?.map((el) => (el?.value as number) || (el?.user_id as number));

  const editTeamInfo: TFormSubmit = (data) => {
    const tagIds = data.teamActivity?.map((el: TTag) => Number(el.id));

    handleEditTeam?.({
      id: item.id,
      name: data.name,
      org_id: item?.org_id,
      tag_ids: tagIds || [],
      member_ids: membersIds || [],
      description: data.teamDetails,
      team_lead_ids: leadersIds || [],
    });

    reset();
    closeDrawer?.();
  };

  const handleCloseReset = () => {
    reset({
      name: item.name,
      teamActivity: item.tags,
      teamDetails: item.description,
      teamLeader: item?.members?.filter((el) => el.position === 'lead'),
      teamMembers: item?.members?.filter((el) => el.position === 'member'),
    });
    closeDrawer();
  };
  const popupIcon = <ArrowDown width={24} height={24} />;

  const activityData = useMemo(
    () =>
      tags?.data.map(({ name, id, color }) => ({
        label: name as string,
        value: id as number,
        color: color as string,
        title: name,
        name: name as string,
        id,
      })),
    [tags],
  );

  const isTeamLeaderExist = leadersIds?.length;
  const isTeamMemberExist = membersIds?.length;

  const renderDisabled = isTeamLeaderExist || isTeamMemberExist;

  const renderDrawerWidth = Number(width) >= breakPoints.MOBILE ? 472 : 375;

  const activeTagsLength = watch()?.teamActivity?.length;

  const handleCloseDrawer = () => {
    setIsPopupOpen(false);
    closeDrawer();
  };

  const isButtonDisabled = !isDisabled || isSubmitting || !renderDisabled || !isDirty;

  return (
    <DrawerLayout
      padding='40px'
      open={open}
      inCenter={false}
      headerTitle='Edit Team'
      onClose={handleCloseReset}
      width={renderDrawerWidth}
      onCloseReset={handleCloseReset}
      titleClassName={styles.container__header}
      headerContainerClassName={styles.container__drawer}
    >
      <Box className={styles.container}>
        <form className={styles.container__form} onSubmit={handleSubmit(editTeamInfo)}>
          <Controller
            control={control}
            name='name'
            render={({ field: { onChange, value } }) => (
              <CustomTextField
                required
                value={value}
                onChange={onChange}
                borderRadius='10px'
                placeholder='Title:*'
                borderColor={Colors.SOFT_SILVER}
                backGroundColor={Colors.LIGHT_SILVER}
                className={styles.container__title__input}
              />
            )}
          />
          <div className={styles.container__form__content}>
            <Controller
              control={control}
              name='teamLeader'
              render={({ field: { onChange } }) => {
                return (
                  <UserComponent
                    item={item}
                    title='Leads*'
                    members={teamLeads}
                    memberRole='lead'
                    popupIcon={popupIcon}
                    setMembers={setTeamLeads}
                    handleArrayChange={onChange}
                    team={teamLeads as TMember[]}
                    placeholder='Add Team Leads...'
                    filteredMembers={filtered || []}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name='teamMembers'
              render={({ field: { onChange } }) => (
                <UserComponent
                  item={item}
                  title='Members*'
                  popupIcon={popupIcon}
                  memberRole='member'
                  members={teamMembers}
                  setMembers={setTeamMembers}
                  handleArrayChange={onChange}
                  team={teamMembers as TMember[]}
                  filteredMembers={filtered || []}
                  placeholder='Add Team Members...'
                />
              )}
            />
            <div className={styles.badge}>
              <div className={styles.badge__wrapper}>
                <div className={styles.badge__title}>
                  <CustomTypography className={styles.badge__title__typo}>Tags</CustomTypography>
                  <CustomTypography className={styles.badge__title__count}>
                    ({activeTagsLength || 0})
                  </CustomTypography>
                </div>
              </div>

              <Box className={styles.container__content__select__wrapper__dropdown}>
                {addMore && (
                  <ActivityDropDown
                    addTag={addTag}
                    setAddTag={setAddTag}
                    setAddMore={setAddMore}
                    title='Tag'
                    data={tags.data}
                    selectedJobTypes={selectedActivityTypes}
                    setSelectedJobType={setSelectedActivityType}
                    className={styles.container__content__select__wrapper__dropdown__box}
                  />
                )}
              </Box>

              <div className={styles.badge__select}>
                <Controller
                  name='teamActivity'
                  control={control}
                  render={({ field: { onChange } }) => (
                    <JobTypesComplete
                      error={false}
                      placeholder='Add Tags...'
                      backgroundColor={Colors.LIGHT_SILVER}
                      borderColor={Colors.SOFT_SILVER}
                      onTagCreate={handleAddTags}
                      activeValue={item.tags}
                      handleOnChange={onChange}
                      autocompleteOpen={autocompleteOpen}
                      setAutocompleteOpen={setAutocompleteOpen}
                      options={activityData || []}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={styles.details}>
            Description:*
            <Controller
              control={control}
              name='teamDetails'
              render={({ field: { onChange, value } }) => (
                <CustomTextField
                  multiline
                  rows={3}
                  value={value}
                  name='description'
                  onChange={onChange}
                  borderRadius='10px'
                  borderColor={Colors.SAPPHIRE}
                  backGroundColor={Colors.WHITE}
                  placeholder='Team Details'
                  className={styles.textarea}
                />
              )}
            />
          </div>
          <div className={styles.container__actions}>
            <Button
              type='button'
              color={Colors.SAPPHIRE}
              minWidth='68px'
              padding='11.5px 16px'
              backgroundColor={Colors.PALE_BLUE}
              className={styles.container__actions__item}
              onClick={togglePopup}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              color={Colors.WHITE}
              minWidth='65px'
              padding='11.5px 16px'
              backgroundColor={Colors.SAPPHIRE}
              disabled={isButtonDisabled}
              className={styles.container__actions__item}
            >
              Save
            </Button>
          </div>
        </form>
      </Box>
      <DeletePopup
        withBody
        title='Confirmation'
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onDelete={handleCloseDrawer}
        body='Are you sure you want to cancel? Changes will be discarded if you do.'
      />
    </DrawerLayout>
  );
};

export default EditTeamModal;
