import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { CustomTypography, ShowAfterAccessView, CreateLiveStreamDrawer } from 'components';
import { Routes } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { myCurrentStreamSelector } from 'store/slices/liveStreamSlice/selector';
import { getMyCurrentStream } from 'store/slices/liveStreamSlice/thunks';
import {
  userAllRolesLoadingSelector,
  userPermissionsSelector,
} from 'store/slices/authSlice/selectors';

import styles from './LiveStream.module.scss';
import PastStreams from './PastStreams';

const LiveStream: React.FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const currentStream = useAppSelector(myCurrentStreamSelector);
  const userRolesLoad = useAppSelector(userAllRolesLoadingSelector);
  const { isAccessToLivestream } = useAppSelector(userPermissionsSelector);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openInviteDrawer, setOpenInviteDrawer] = useState<boolean>(false);

  const currentStreamStarted = currentStream?.livestream?.members?.at(-1)?.status === 'active';

  useEffect(() => {
    if (currentStream && (currentStreamStarted || currentStream.livestream.status === 'ongoing')) {
      navigate(Routes.LiveStreamJoin);
    } else {
      navigate(Routes.LiveStream);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStream, currentStreamStarted]);

  useEffect(() => {
    dispatch(getMyCurrentStream());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setOpenDrawer(false);
  };
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const onCloseInvite = () => {
    setOpenInviteDrawer(false);
  };

  if (!userRolesLoad && !isAccessToLivestream) {
    return <Navigate replace to={Routes.ActivityFeed} />;
  }

  return (
    <div className={styles.container}>
      <CustomTypography className={styles.container__title}>Live Stream</CustomTypography>

      <ShowAfterAccessView emptyMessageTitle='Live streams' isShow={isAccessToLivestream}>
        <CreateLiveStreamDrawer
          open={openInviteDrawer}
          handleClose={onCloseInvite}
          inviteDuringCall
        />
        <PastStreams handleOpenDrawer={handleOpenDrawer} />

        <CreateLiveStreamDrawer open={openDrawer} handleClose={onClose} />
        {/* <InCallMessageDrawer open={openMessageDrawer} handleClose={handleCloseMessage} /> */}
      </ShowAfterAccessView>
    </div>
  );
};

export default LiveStream;
