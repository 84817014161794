import { FC, useState } from 'react';
import { Box } from '@mui/material';

import { AmericanExpressIcon, MasterCardIcon, NewVisaIcon } from 'assets/icons';
import { Button, CustomTypography, UpdateBillingDetails, UpdatePaymentMethod } from 'components';
import { CardTypes } from 'types/global/cardTypes';
import { useAppSelector } from 'hooks';
import { currentPaymentMethodSelector } from 'store/slices/subscriptionSlice/selectors';
import { addressGenerator } from 'utils';

import styles from './BillingDetailsUpdate.module.scss';
import BillingInfoItem from './BillingInfoItem/BillingInfoItem';

import type { TBillingDetailsUpdateProps } from './types';

const BillingDetailsUpdate: FC<TBillingDetailsUpdateProps> = ({
  title,
  expiryDate,
  cardUserName,
  nextBillingDate,
  lastFourNumbers,
  type = 'billing',
  cardType = CardTypes.VISA,
}) => {
  const cardTitle = cardType === CardTypes.VISA ? 'Visa' : 'Master Card';

  const currentPayment = useAppSelector(currentPaymentMethodSelector);

  const address = addressGenerator(
    currentPayment?.billing_details?.address?.line1 as string,
    currentPayment?.billing_details?.address?.city as string,
    currentPayment?.billing_details?.address?.state as string,
    currentPayment?.billing_details?.address?.postal_code as string,
  );

  const phoneNumber = currentPayment?.billing_details?.phone;

  const [update, setUpdate] = useState(false);

  const handleClose = () => setUpdate(false);
  const startUpdate = () => setUpdate(true);

  return (
    <Box className={styles.container}>
      <Box className={styles.container__head}>
        <CustomTypography className={styles.container__head__title}>{title}</CustomTypography>
        <Button className={styles.container__head__button} onClick={startUpdate}>
          UPDATE
        </Button>
      </Box>
      <Box className={styles.container__content}>
        {type === 'payment' ? (
          <>
            <Box className={styles.container__content__item}>
              {cardType === CardTypes.VISA ? (
                <NewVisaIcon />
              ) : cardType === CardTypes.MASTER_CARD ? (
                <MasterCardIcon />
              ) : (
                <AmericanExpressIcon />
              )}
              <Box className={styles.container__content__item_expiry}>
                <CustomTypography className={styles.container__content__item__text}>
                  {cardTitle} Ending in {lastFourNumbers}
                </CustomTypography>
                <CustomTypography className={styles.container__content__item__time}>
                  Expiry:
                  <span className={styles.container__content__item__time__content}>
                    {expiryDate}
                  </span>
                </CustomTypography>
              </Box>
            </Box>

            <BillingInfoItem title='Name on Card' subtitle={String(cardUserName)} />
          </>
        ) : (
          <>
            <BillingInfoItem
              title='Next Billing Date'
              subtitle={nextBillingDate || 'May 2, 2023'}
            />
            <BillingInfoItem title={'Billing Address'} subtitle={address || ''} />
            <BillingInfoItem title={'Billing Number'} subtitle={phoneNumber || ''} />
          </>
        )}
      </Box>
      {type === 'payment' ? null : <UpdateBillingDetails open={update} handleClose={handleClose} />}
      {type === 'billing' ? null : <UpdatePaymentMethod open={update} handleClose={handleClose} />}
    </Box>
  );
};

export default BillingDetailsUpdate;
