import { useEffect } from 'react';
import moment from 'moment';
import { Box } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'hooks';
import { BillingDetails, BillingDetailsUpdate, CustomSkeleton, InvoiceTable } from 'components';
import {
  currentPaymentMethodSelector,
  paymentMethodsLoaderSelector,
  userSubscriptionsPlanSelector,
  userActiveSubscriptionSelector,
  userSubscriptionsAmountSelector,
  userSubscriptionsLoaderSelector,
  userSubscriptionsIntervalSelector,
  defaultPaymentMethodLoaderSelector,
} from 'store/slices/subscriptionSlice/selectors';
import {
  getPaymentMethods,
  getUserSubscriptions,
  getDefaultPaymentMethod,
} from 'store/slices/subscriptionSlice/thunks';
import { formatCardDate } from 'utils';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { UserSubscriptionStatus } from 'store/slices/subscriptionSlice/types';

import styles from './Billing.module.scss';

const Billing = () => {
  const dispatch = useAppDispatch();
  const currentOrganization = BrowserStorageService.get(BrowserStorageKeys.CurrentOrganizationId);

  useEffect(() => {
    dispatch(
      getUserSubscriptions({
        org_id: Number(currentOrganization),
        subscription_status: UserSubscriptionStatus.ACTIVE,
      }),
    );

    dispatch(getPaymentMethods(Number(currentOrganization)));
    dispatch(getDefaultPaymentMethod(Number(currentOrganization)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const amount = useAppSelector(userSubscriptionsAmountSelector);
  const interval = useAppSelector(userSubscriptionsIntervalSelector);
  const subscriptionPlan = useAppSelector(userSubscriptionsPlanSelector);
  const currentPaymentMethod = useAppSelector(currentPaymentMethodSelector);
  const subscriptionsLoader = useAppSelector(userSubscriptionsLoaderSelector);
  const userActiveSubscription = useAppSelector(userActiveSubscriptionSelector);
  const paymentMethodsLoader = useAppSelector(defaultPaymentMethodLoaderSelector);
  const defaultPaymentMethodLoader = useAppSelector(paymentMethodsLoaderSelector);

  const currentPeriodEnd = userActiveSubscription?.current_period_end;

  const nextBillingDate = moment.unix(currentPeriodEnd as number).format('MMM D, YYYY');

  const formattedDate = formatCardDate({
    month: currentPaymentMethod?.card?.exp_month as number,
    year: currentPaymentMethod?.card?.exp_year as number,
  });

  return (
    <Box className={styles.container}>
      <Box className={styles.container__top}>
        {subscriptionsLoader ? (
          <CustomSkeleton width='100%' height={40} />
        ) : (
          <BillingDetails
            price={amount?.[0] as number}
            interval={interval?.[0] as string}
            planTitle={subscriptionPlan?.[0] as string}
            description='For Custom Solutions And Pricing Contact Our Support Team'
          />
        )}
      </Box>
      <Box className={styles.container__content}>
        {paymentMethodsLoader || defaultPaymentMethodLoader ? (
          <CustomSkeleton width='100%' height={150} />
        ) : (
          <BillingDetailsUpdate
            type='payment'
            title='Payment Method'
            expiryDate={formattedDate}
            cardType={currentPaymentMethod?.card?.brand}
            cardUserName={currentPaymentMethod?.billing_details?.name}
            lastFourNumbers={currentPaymentMethod?.card?.last4 as string}
          />
        )}

        {subscriptionsLoader || paymentMethodsLoader || defaultPaymentMethodLoader ? (
          <CustomSkeleton width='100%' height={150} />
        ) : (
          <BillingDetailsUpdate nextBillingDate={nextBillingDate} title='Billing details' />
        )}
      </Box>
      <InvoiceTable />
    </Box>
  );
};

export default Billing;
