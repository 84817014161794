import React, { JSXElementConstructor, ReactElement } from 'react';
import Chip from '@mui/material/Chip';

import { TToggleChipChipProps } from './types';

const ToggleChip: React.FC<TToggleChipChipProps> = ({
  label,
  color,
  variant,
  onClick,
  icon,
  onDelete,
  deleteIcon,
  backgroundColor,
  borderRadius = '5px',
  fontFamily = 'CircularStdLight',
}) => {
  const chipStyle: React.CSSProperties = {
    fontSize: '14px',
    userSelect: 'none',
    borderRadius: borderRadius,
    color: color || 'white',
    letterSpacing: '0.035px',
    fontFamily: fontFamily,
    backgroundColor: backgroundColor,
  };

  return (
    <div onClick={onDelete} style={{ display: 'inline-block', cursor: 'pointer' }}>
      <Chip
        label={label}
        onClick={onClick}
        style={chipStyle}
        onDelete={onDelete}
        variant={variant || 'filled'}
        deleteIcon={
          deleteIcon as ReactElement<SVGElement, string | JSXElementConstructor<SVGElement>>
        }
        icon={icon as ReactElement<SVGElement, string | JSXElementConstructor<SVGElement>>}
      />
    </div>
  );
};

export default ToggleChip;
