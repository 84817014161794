import { FC } from 'react';
import { Box } from '@mui/material';

import { NoticeBoard } from 'components';

import PostsSection from '../PostsSection/PostsSection';
import styles from '../ActivityFeed.module.scss';

import type { TPostsAndNoticesProps } from './types';

const PostsAndNotices: FC<TPostsAndNoticesProps> = ({
  changePage,
  currentList,
  currentListLoad,
  loadingState,
}) => (
  <>
    <PostsSection
      currentList={currentList}
      changePage={changePage}
      loadingState={loadingState}
      currentListLoad={currentListLoad}
    />
    <Box className={styles.container_notice}>
      <NoticeBoard />
    </Box>
  </>
);

export default PostsAndNotices;
