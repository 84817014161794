import { MutableRefObject } from 'react';
import html2canvas from 'html2canvas';

export const startRecording = (mediaRecorderRef: MutableRefObject<MediaRecorder>) => {
  if (mediaRecorderRef.current) {
    const chunks: Blob[] = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const recordedBlob = new Blob(chunks, { type: 'video/webm' });
      const videoUrl = URL.createObjectURL(recordedBlob);

      const a = document.createElement('a');
      a.href = videoUrl;
      a.download = 'recorded-video.webm';
      a.click();
      URL.revokeObjectURL(videoUrl);
    };

    mediaRecorderRef.current.start();
  }
};

export const stopRecording = (mediaRecorderRef: MutableRefObject<MediaRecorder>) => {
  if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
    mediaRecorderRef.current.stop();
  }
};

export const takeScreenshot = async (
  videoRef: MutableRefObject<HTMLVideoElement | null>,
  canvasRef: MutableRefObject<HTMLCanvasElement>,
) => {
  return new Promise<File | null>((resolve) => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        // const screenshotUrl = canvas.toDataURL('image/png');

        canvas.toBlob((blob) => {
          const fileWithPath = new File([blob as Blob], 'new-image.png', {
            type: 'image/webp',
            lastModified: Date.now(),
          }) as File & { path: string };
          fileWithPath.path = 'new-image.png';

          resolve(fileWithPath);
        }, 'image/png');
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

export const captureScreenshot = (
  contentRef: MutableRefObject<HTMLDivElement | null>,
): Promise<File | null> => {
  return new Promise((resolve) => {
    if (contentRef?.current) {
      html2canvas(contentRef?.current).then((canvas) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const dataURL = canvas.toDataURL('image/png');

        canvas.toBlob((blob) => {
          if (blob) {
            const fileWithPath = new File([blob], 'screenshot.png', {
              type: 'image/png',
              lastModified: Date.now(),
            }) as File & { path: string };
            fileWithPath.path = 'screenshot.png';

            resolve(fileWithPath);
          } else {
            resolve(null);
          }
        }, 'image/png');
      });
    } else {
      resolve(null);
    }
  });
};
