import { TMember } from 'components/shared/Team/types';

export const filterUsers = (users: TMember[]): TMember[] => {
  const userMap: { [key: number]: TMember[] } = {};
  users.forEach((user) => {
    const key = user.user_id;
    if (!userMap[key]) {
      userMap[key] = [user];
    } else {
      userMap[key].push(user);
    }
  });

  // Filter the array based on conditions
  const filteredUsers = Object.values(userMap).flatMap((userGroup) => {
    if (userGroup.length === 1) {
      return userGroup;
    } else {
      const leadUser = userGroup.find((user) => user.position === 'lead');
      if (leadUser) {
        return [leadUser];
      } else {
        return [userGroup[0]];
      }
    }
  });

  return filteredUsers;
};
