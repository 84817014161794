import { TOrganization } from 'store/slices/organizationsSlice/types';

import { TOrganizationFormValues } from './types';

const organizationFormGenerator = (organization: TOrganization): TOrganizationFormValues => ({
  organization: organization?.name,
  industryVertical: organization?.industry_vertical,
  typeOfBusiness: organization?.business_type,
  size: organization?.size,
  address: organization?.address,
  email: organization?.email,
  state: organization?.state || '',
  city: organization?.city,
  zip: organization?.zip,
  phone: organization?.phone === 'null' ? '' : organization?.phone || '',
});

export default organizationFormGenerator;
