import { RootState } from 'types';

export const optionsSelector = (state: RootState) => state.options;
export const repliesCountSelector = (state: RootState) => state.options.repliesCount;

export const postMediasDataSelector = (state: RootState) => state.options.postMediasData;

export const imgMarkersSelector = (state: RootState) => state.options.imgMarkers;

export const isMarkersOpenedSelector = (state: RootState) => state.options.isMarkersOpened;

export const selectedInventoryItemsSelector = (state: RootState) =>
  state.options.selectedInventoryItem;

export const selectedIdsInventoryItemsSelector = (state: RootState) => state.options.selectedIds;

export const isModalOpenedSelector = (state: RootState) => state.options.isModalOpened;
export const isDrawerOpenedSelector = (state: RootState) => state.options.isDrawerOpened;
