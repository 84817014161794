export const sx = {
  '& .MuiFormControlLabel-label': {
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '14px',
    letterSpacing: '0.035px',
    color: 'rgba(34, 34, 34, 0.9)',
    fontFamily: 'CircularStdRegular',
  },

  '& .MuiButtonBase-root': {
    svg: {
      width: '23px',
      height: '23px',
    },
  },
};
