export const generalInfoOptions = [
  {
    id: 1,
    label: 'textbox',
    value: 'textbox',
  },
  {
    id: 2,
    label: 'enum',
    value: 'enum',
  },
  {
    id: 3,
    label: 'date',
    value: 'date',
  },
];
