import { GridColDef } from '@mui/x-data-grid';

import { TableCell } from 'components';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';

export const columns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'PO #',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0', color: '#2067DD' }} title={params?.value} />;
    },
  },
  {
    field: 'vendor',
    headerName: 'Vendor',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'created_by_name',
    headerName: 'Created By',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'grand_total',
    headerName: 'Total Cost',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },
    renderCell: (params) => {
      return <TableCell style={{ padding: '0' }} title={params?.value} />;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle style={{ padding: '0' }} title={headerName} />;
    },

    renderCell: (params) => params.value,
  },
  {
    field: 'options',
    headerName: '',
    width: 72,
    renderCell: (params) => {
      return params.value;
    },
  },
];
