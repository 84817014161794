export const sxStyles = {
  zIndex: 3,
  width: '100%',
  position: 'relative',
  border: 'none',

  '@media (max-width: 992px)': {
    minWidth: '1000px',
  },

  '& .Mui-hovered': {
    backgroundColor: 'transparent !important',
  },

  '& .MuiDataGrid-overlay': {
    display: 'flex !important',
  },

  '& .MuiDataGrid-overlayWrapper': {
    height: '100%',
    maxHeight: '150px',
    left: 'unset !important',
    width: '100%',
    overflow: 'hidden',
  },

  '& .MuiDataGrid-overlayWrapperInner': {
    height: 'inherit !important',
    paddingTop: '7px',
    overflowY: 'hidden',
  },

  '& .MuiDataGrid-columnHeader': {
    border: 'none',
    padding: 0,

    '&:first-of-type': {
      paddingLeft: '16px ',

      '& .MuiDataGrid-columnHeaderTitleContainerContent': {
        '& > div': {
          fontFamily: 'CircularStdBold !important',
          paddingLeft: '16px !important',
        },
      },
    },
  },

  '& .MuiDataGrid-cell ': {
    borderBottom: 'none',
    paddingLeft: '0 !important',

    '&:last-child ': {
      borderBottom: 'none',
    },

    '& > div': {
      paddingLeft: '2px !important',
    },

    '&:first-of-type ': {
      '& > div': {
        marginLeft: '30px !important',
        maxWidth: 'unset !important',

        '@media (max-width: 1100px)': {
          paddingLeft: '16px !important',
          marginLeft: '0 !important',
        },
      },
    },
  },

  '& .MuiDataGrid-row': {
    border: '1px solid #E6E6E6',
    margin: '0 0 8px 0',
    width: '100%',
    transition: '0.3s',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02) !important',
    },
  },

  '& .MuiDataGrid-virtualScroller': {
    width: '100%',
    marginTop: '8px',
    overflowX: 'hidden',
    paddingBottom: '24px',
    border: 'none !important',
  },

  '& .MuiDataGrid-virtualScrollerRenderZone': {
    borderColor: '#E6E6E6',
    borderStyle: 'dashed',
    border: 'none !important',
    width: '100%',
  },

  '& .MuiDataGrid-columnHeaderRow': {
    width: '100%',
  },

  '& .MuiDataGrid-columnHeadersInner': {
    width: '100%',
  },

  '& .MuiDataGrid-columnHeaders': {
    background: '#D2E1F9',
    textTransform: 'capitalize',
    padding: '15px 0 !important',
    maxHeight: '82px !important',
  },

  '& .MuiDataGrid-virtualScrollerContent': {
    width: '100% !important',
  },

  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },

  '& .MuiDataGrid-cell--withRightBorder': {
    borderStyle: 'dashed',
    border: 'none !important',
  },

  '& .MuiDataGrid-cellCheckbox': {
    border: 'none !important',
    minWidth: '55px !important',

    '& span': {
      padding: '0 !important',
    },
  },

  '& .MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'CircularStdRegular',
    fontSize: '14px',
    lineHeight: 'normal',
    letterSpacing: '0.08px',
  },

  '& .MuiDataGrid-cellContent': {
    paddingLeft: '9px !important',
    fontFamily: 'CircularStdRegular',
  },

  '& .MuiDataGrid-cell>img': {
    border: '1px dashed #ccc',
  },

  '& .MuiDataGrid-cell::nth-of-type(-n+2)': {
    paddingLeft: '32px',
  },

  '& .MuiDataGrid-cell': {
    ' .MuiAvatar-square': {
      width: '24px !important',
      height: '24px !important',
      border: 'none !important',

      '& img': {
        width: '24px',
        height: '24px',
      },
    },
  },

  '& .MuiDataGrid-columnSeparator--sideRight': {
    display: 'none !important',
  },

  '& .MuiDataGrid-columnHeaderCheckbox': {
    minWidth: 'unset !important',
    width: 'unset !important',

    '& .MuiDataGrid-columnHeaderTitleContainerContent': {
      width: '24px',
      height: '24px',

      '& div': {
        paddingLeft: '0 !important',
      },

      '&  span': {
        padding: '0 !important',
      },
    },
  },
};

export const sxMenuStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px',
    border: '0.5px dashed #ccc)',
    background: '#fff',
    padding: '10px 8px',

    '& .MuiMenuItem-root ': {
      display: 'flex',
      gap: '8px',
    },
  },
};
export const sxMenuShareStyles = {
  '& .MuiPaper-root': {
    borderRadius: '10px 10px 0 0',

    '& .MuiList-root ': {
      padding: '0 !important',
    },
  },
};
