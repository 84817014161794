import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';

import { formatTimestamp } from 'utils/formatTime';
import { CheckSquareIcon, CloseOutlinedIcon, ReloadIcon } from 'assets/icons';
import CreatePurchaserOrderDrawer from 'containers/PurchaseOrder/CreatePurchaserOrderDrawer';
import {
  Button,
  DeletePopup,
  CustomTextField,
  CustomTypography,
  CustomBreadcrumbs,
  PurchaseOrderVendorDrawer,
} from 'components';
import { getCurrentUser } from 'store/thunks';
import {
  TActions,
  actionEnums,
} from 'containers/PurchaseRequisition/PurchaseRequisitionHeader/types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { userAllRolesSelector, userDataSelector } from 'store/slices/authSlice/selectors';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';
import { Colors } from 'types';

import { breadCrumbOptions } from './utils';
import { TRequisitionItemHeaderProps } from './types';
import styles from './RequisitionItemHeader.module.scss';

const RequisitionItemHeader: FC<TRequisitionItemHeaderProps> = ({
  count,
  onSubmit,
  disabled,
  setStatus,
  currentRequisition,
}) => {
  const dispatch = useAppDispatch();
  const renderOptions = breadCrumbOptions(currentRequisition?.id as number);

  const statusClasses = classNames(styles.container__info__status__text, {
    [styles.container__info__status__text__approved]: currentRequisition.status === 'approved',
    [styles.container__info__status__text__declined]: currentRequisition.status === 'declined',
  });

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [actionContent, setActionContent] = useState<TActions | null>(null);

  const currentUser = useAppSelector(userDataSelector);
  const userRoles = useAppSelector(userAllRolesSelector);
  const allMembers = useAppSelector(organizationMembersSelector);

  const filteredRoles = userRoles.map((role) => role.role_id);

  const isViewer =
    !filteredRoles.includes(16) && !filteredRoles.includes(5) && !filteredRoles.includes(3);

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const handleClick = (title: string, body: string) => {
    setActionContent({ title, body });
    togglePopup();
    setStatus(title);
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isVendorDrawerOpen, setIsVendorDrawerOpen] = useState<boolean>(false);

  const toggleVendorDrawer = () => setIsVendorDrawerOpen(!isVendorDrawerOpen);
  const togglePODrawer = () => setIsOpen(!isOpen);

  const onDelete = () => {
    onSubmit();
    togglePopup();
  };

  const renderIcon =
    currentRequisition.status === 'approved' ? (
      <CheckSquareIcon className={styles.container__approve} />
    ) : currentRequisition.status === 'declined' ? (
      <CloseOutlinedIcon className={styles.container__declined} />
    ) : (
      <ReloadIcon className={styles.container__pending} />
    );

  const renderDeclineDisabled =
    currentRequisition.status === 'approved' ||
    currentRequisition.status === 'declined' ||
    isViewer;

  const renderRaiseDisabled = currentRequisition.status !== 'approved' || isViewer;

  const formattedDate = formatTimestamp(currentRequisition.created_at as string);

  const renderBody =
    actionContent?.body === actionEnums(count).decline ? (
      <Box className={styles.container__decline}>
        {actionContent.body}
        <CustomTextField
          rows={4}
          multiline
          borderRadius='5px'
          borderColor={Colors.SOFT_SILVER}
          backGroundColor={Colors.LIGHT_SILVER}
          label='Reason for Decline:(Optional)'
          placeholder='Reason for Decline:(Optional)'
        />
      </Box>
    ) : (
      actionContent?.body
    );

  useEffect(() => {
    dispatch(getCurrentUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRequisition.created_by]);

  const creator = allMembers?.find((member) => member.user_id === currentRequisition?.created_by);

  return (
    <Box className={styles.container}>
      <CustomBreadcrumbs options={renderOptions} />
      <Box className={styles.container__info}>
        <CustomTypography className={styles.container__info__title}>
          Requisition{' '}
          <a className={styles.container__info__title__link}>#{currentRequisition.id}</a>
        </CustomTypography>
        <Box className={styles.container__info__status}>
          <CustomTypography className={statusClasses}>{currentRequisition.status}</CustomTypography>
          {renderIcon}
        </Box>
      </Box>
      <Box className={styles.container__actions}>
        <Box className={styles.container__actions__creation}>
          <CustomTypography className={styles.container__actions__creation__item}>
            Created at: {formattedDate}
          </CustomTypography>
          <CustomTypography className={styles.container__actions__creation__item}>
            Created by:
            <span className={styles.container__actions__creation__item__name}>
              {' '}
              {creator?.name}
            </span>
          </CustomTypography>
        </Box>
        <Box className={styles.container__actions__buttons}>
          {(currentUser?.designation === 'manager' || currentUser?.designation === 'owner') && (
            <>
              {currentRequisition.status === 'draft' && !isViewer && (
                <Button
                  color={Colors.SAPPHIRE}
                  borderRadius='5px'
                  variant='contained'
                  padding='11px 24px'
                  disableColor={Colors.DEEP_SLATE_GRAY}
                  backgroundColor={Colors.PALE_BLUE}
                  className={styles.container__actions__buttons__item}
                  onClick={() => handleClick('Submit', actionEnums(count).submit)}
                >
                  Submit for Approval
                </Button>
              )}
              <Button
                color={Colors.SAPPHIRE}
                borderRadius='5px'
                variant='contained'
                padding='11px 24px'
                disableColor={Colors.DEEP_SLATE_GRAY}
                backgroundColor={Colors.PALE_BLUE}
                disabled={renderDeclineDisabled || disabled}
                className={styles.container__actions__buttons__item}
                onClick={() => handleClick('Decline', actionEnums(count).decline)}
              >
                Decline
              </Button>
              <Button
                color={Colors.SAPPHIRE}
                borderRadius='5px'
                variant='contained'
                padding='11px 24px'
                disableColor={Colors.DEEP_SLATE_GRAY}
                backgroundColor={Colors.PALE_BLUE}
                disabled={renderDeclineDisabled || disabled}
                className={styles.container__actions__buttons__item}
                onClick={() => handleClick('Approval', actionEnums(count).approval)}
              >
                Approve
              </Button>
            </>
          )}
          <Button
            color={Colors.SAPPHIRE}
            borderRadius='5px'
            variant='contained'
            padding='11px 24px'
            disableColor={Colors.DEEP_SLATE_GRAY}
            onClick={togglePODrawer}
            backgroundColor={Colors.PALE_BLUE}
            disabled={renderRaiseDisabled || disabled}
            className={styles.container__actions__buttons__item}
          >
            Raise PO
          </Button>
          {currentRequisition.status === 'pending' && (
            <ReloadIcon className={styles.container__pending} />
          )}
        </Box>
      </Box>
      <CreatePurchaserOrderDrawer
        isOpen={isOpen}
        isFromRequisition={true}
        onClose={togglePODrawer}
        openVendorDrawer={toggleVendorDrawer}
        requisitionItems={currentRequisition.items}
      />
      <PurchaseOrderVendorDrawer open={isVendorDrawerOpen} handleClose={toggleVendorDrawer} />
      <DeletePopup
        withBody
        body={renderBody}
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onDelete={onDelete}
        title={`${actionContent?.title} Confirmation`}
      />
    </Box>
  );
};

export default RequisitionItemHeader;
