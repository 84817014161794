import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

export const options: StripeElementsOptions = {
  locale: 'en',
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap',
    },
  ],

  appearance: {
    theme: 'none',

    rules: {
      '.Input': {
        border: '1px solid #dfe5f4',
        outline: '6px',
      },

      '.Input:focus': {
        outline: '6px',
      },
    },

    variables: {
      colorBackground: '#f4f7f9',
      colorText: '#252849',
      colorDanger: '#df1b41',
      fontWeightBold: '600',
      fontFamily: 'Inter',
      borderRadius: '8px',
      fontSizeBase: '16px',
      fontSmooth: 'always',
    },
  },
};
