import { Box } from '@mui/material';
import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { CardElement } from '@stripe/react-stripe-js';

import { CustomTextField } from 'components';
import { FontNames } from 'types';

import styles from './CardDetails.module.scss';

import type { TCardDetailsProps } from './types';

const CardDetails: FC<TCardDetailsProps> = ({ control, errors, handleCardValid }) => {
  const cardElementOptions = {
    hidePostalCode: true,

    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },

      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <Box className={styles.container}>
      <Controller
        name='name'
        control={control}
        defaultValue=''
        rules={{ required: 'Card holder name is required' }}
        render={({ field }) => (
          <CustomTextField
            required
            {...field}
            error={!!errors.name}
            placeholder='Card Holder:'
            label='Card Holder:'
            fontFamily={FontNames.CIRCULAR_REG}
          />
        )}
      />

      <Box className={styles.container__card}>
        <CardElement options={{ ...cardElementOptions }} onChange={handleCardValid} />
      </Box>
    </Box>
  );
};

export default CardDetails;
