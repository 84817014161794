import { RootState } from 'types';

export const documentsSelector = (state: RootState) => state.documents.documents;

export const documentsLoadingSelector = (state: RootState) => state.documents.documentLoading;

export const documentsLimitSelector = (state: RootState) => state.documents.documentsLimit;

export const documentsAscSelector = (state: RootState) => state.documents.documentsAsc;

export const documentsOffsetSelector = (state: RootState) => state.documents.documentsOffset;

export const documentTypesSelector = (state: RootState) => state.documents.documentTypes;

export const documentTypesLoadingSelector = (state: RootState) =>
  state.documents.documentTypesLoading;

export const documentTypesLimitSelector = (state: RootState) => state.documents.documentTypesLimit;

export const documentViewSelector = (state: RootState) => state.documents.isViewGrid;

export const documentTypesAscSelector = (state: RootState) => state.documents.documentTypesAsc;

export const documentTypesOffsetSelector = (state: RootState) =>
  state.documents.documentTypesOffset;

export const documentsItemSelector = (state: RootState) => state.documents.documentsItem;

export const documentsItemLoadingSelector = (state: RootState) =>
  state.documents.documentsItemLoading;

export const documentsItemLimitSelector = (state: RootState) => state.documents.documentsItemLimit;

export const documentsItemOffsetSelector = (state: RootState) =>
  state.documents.documentsItemOffset;

export const currentDocumentSelector = (state: RootState) => state.documents.currentDocument;

export const currentDocumentLoadingSelector = (state: RootState) =>
  state.documents.currentDocumentLoading;

export const currentDocumentErrorSelector = (state: RootState) =>
  state.documents.currentDocumentError;
