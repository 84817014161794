export type TRequisitionsParams = Partial<{
  asc: boolean;
  limit: number;
  offset: number;
  sort_by: SortByEnums | null;
}>;

export type TInitialState = {
  allRequisitionsAsc: boolean;
  allRequisitionsLimit: number;
  allRequisitionsOffset: number;
  requisitionItemsLimit: number;
  requisitionItemsOffset: number;
  allRequisitionsLoading: boolean;
  requisitionItemsLoading: boolean;
  requisitionItems: TRequisition | null;
  allRequisitionsError: Error | null | string;
  allRequisitions: TAllRequisitionsReturnType;
  requisitionItemsError: Error | null | string;
};
export enum SortByEnums {
  ID = 'id',
  QTY = 'quantity',
  STATUS = 'status',
  DATE = 'created_at',
  ITEMS = 'no_of_items',
  CREATED = 'created_by',
  UPDATED = 'updated_at',
  DESCRIPTION = 'description',
}

export type TAllRequisitionsReturnType = {
  data: TExcel[];
  total_count: number;
};

export type TRequisition = Partial<{
  id: number;
  uuid: string;
  status: string;
  quantity: number;
  created_at: string;
  created_by: number;
  updated_at: string;
  description: string;
  no_of_items: number;
  reviews: string | null;
  created_by_name: string;
  items: TRequisitionItem[];
}>;

export type TExcel = Partial<{
  id: number;
  created_at: string;
  created_by: number;
}>;

export type TRequisitionItem = {
  id: number;
  cost: number;
  name: string;
  brand: string;
  status: string;
  quantity: number;
  updated_at: string;
  created_at: string;
  created_by: number;
  description: string;
  manufacturer: string;
  part_id: number | null;
} & Partial<{
  image: string;
  image_url_id: string;
}>;

export type TItem = {
  item: string;
  quantity: number;
  cost: number;
  brand: string;
  manufacturer: string;
  description: string;
};

export type TRequisitionBody = {
  new_requisition: {
    description: string;
    items: TItem[];
  };
} & Partial<{
  image: string;
}>;

export type TRequisitionUpdateBody = {
  status: string;
} & Partial<{
  id: number;
  ids: number[];
  description: string;
}>;

export type TRequisitionModify = {
  body: TModifyData;
  id: number | string;
};

export type TDeleteRequisitionOptions = {
  id: number;
  requisitionId: number;
};

export type TModifyData = {
  id: number;
  part_id: number;
  quantity: number;
};
export type TCreateRequisitionItem = {
  quantity: number;
  part_id: string;
};

export type TCreateRequisitionBody = {
  description: string;
  items: TCreateRequisitionItem;
  status: string;
};

export type TAddRequisitionItem = {
  part_id: number;
  quantity: number;
  requisition_id: number;
};

export type TAddRequisitionItemBody = {
  items: TAddRequisitionItem[];
};
