import * as yup from 'yup';

export const bookDemoSchema = yup.object({
  fullName: yup.string().trim().required('First Name is required'),
  phone: yup
    .string()
    .trim()
    .required('Phone number is required')
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Phone Number must be a 10-digit number'),
  email: yup
    .string()
    .trim()
    .lowercase()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Enter a valid email address',
    )
    .required('Email is required'),
  note: yup.string().trim().required('Note is required'),
});
