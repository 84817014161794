import { FC } from 'react';
import { useSpring, animated, useInView } from 'react-spring';

import { UserQuote } from 'components/shared';

import styles from './TalkingUser.module.scss';
import { TTalkingUserProps } from './types';

const TalkingUser: FC<TTalkingUserProps> = ({
  left,
  right,
  bottom,
  title,
  userImg,
  top = 0,
  position,
  objectFit = 'contain',
}) => {
  const [ref, inView] = useInView();

  const quoteAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(-100px)',
  });

  const userAnimation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(100px)',
  });

  return (
    <div className={styles.container} ref={ref}>
      <animated.div className={styles.container__img} style={{ ...userAnimation }}>
        <img src={userImg} alt='img' style={{ objectFit }} />
      </animated.div>
      <animated.div
        className={styles.container__quote}
        style={{
          top,
          left,
          right,
          bottom,
          ...quoteAnimation,
        }}
      >
        <UserQuote position={position} title={title} />
      </animated.div>
    </div>
  );
};

export default TalkingUser;
