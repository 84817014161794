import * as yup from 'yup';

export const schema = yup.object().shape({
  profit_margin: yup.array().of(
    yup.object().shape({
      margin: yup
        .string()
        .test(
          'is-valid-margin',
          'Profit margin must be a natural number greater than 0',
          function (value) {
            const { path, createError } = this;

            if (value && Number(value) !== 0) {
              if (!/^[1-9][0-9]*$/.test(value)) {
                return createError({
                  path,
                  message: 'Profit margin must be a natural number greater than 0',
                });
              }
            }
            return true;
          },
        )
        .nullable(),
    }),
  ),
});
