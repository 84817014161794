import { GridColDef } from '@mui/x-data-grid';
import { v4 } from 'uuid';

import { Routes, TDataReturn } from 'types';
import { getValue } from 'utils/formsInitialValues/getValue';
import HeaderTitle from 'components/tables/EmployeeTable/HeaderTitle';
import { FontNames } from 'types';

import { TTaskFormData, TWorkOrderFormData } from './type';

export const breadCrumbOptions = [
  { id: 1, label: 'Workflow', goTo: Routes.BomEstimate },
  { id: 2, label: 'Work Order', goTo: Routes.WorkOrder },
  { id: 2, label: 'edit', goTo: Routes.EditWorkOrder },
];

type TWorkOrderDefaultValuesCreatorParams = {
  currentWorkOrderById: TWorkOrderFormData;
  taskForWorkOrder: TDataReturn<TTaskFormData>;
} & Partial<{
  inEditMode: boolean;
}>;

export const defaultValuesCreator = ({
  taskForWorkOrder,
  inEditMode = false,
  currentWorkOrderById,
}: TWorkOrderDefaultValuesCreatorParams): TWorkOrderFormData => ({
  task: inEditMode
    ? taskForWorkOrder?.data?.map((item) => ({
        id: item?.id,
        uniqueId: item?.id,
        end_date: item?.end_date as Date,
        start_date: item?.start_date as Date,
        sequence: item?.sequence as number,
        tech_name: item?.tech_name as string,
        daily_hours: item?.daily_hours,
        status: item?.status,
      }))
    : [
        {
          id: v4(),
          tech_name: '',
          daily_hours: 0,
          sequence: 1,
          start_date: new Date(),
          end_date: new Date(),
          status: '',
        },
      ],
  id: getValue(inEditMode, currentWorkOrderById?.id),
  uuid: getValue(inEditMode, currentWorkOrderById?.uuid),
});

export const columns: GridColDef[] = [
  {
    field: 'task_id',
    headerName: 'ID',
    flex: 0.4,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          title={headerName}
          withSortIcons={false}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'employee_name',
    headerName: 'Tech Name',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return (
        <HeaderTitle
          title={headerName}
          withSortIcons={false}
          style={{ padding: 0 }}
          fontFamily={FontNames.CIRCULAR_BOLD}
        />
      );
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'sequence',
    headerName: 'Seq',
    flex: 0.4,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'start_time',
    headerName: 'Start Time',
    flex: 0.8,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'start_date',
    headerName: 'Start Date',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    flex: 1,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  {
    field: 'daily_hours',
    headerName: 'Daily Hours',
    flex: 0.7,

    renderHeader: (params) => {
      const headerName = params?.colDef?.headerName || '';
      return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
    },
    renderCell: (params) => {
      return params?.value;
    },
  },
  // {
  //   field: 'status',
  //   headerName: 'Status',
  //   flex: 1,

  //   renderHeader: (params) => {
  //     const headerName = params?.colDef?.headerName || '';
  //     return <HeaderTitle withSortIcons={false} title={headerName} style={{ padding: 0 }} />;
  //   },
  //   renderCell: (params) => {
  //     return params?.value;
  //   },
  // },
  {
    field: 'options',
    headerName: '',
    width: 80,
    renderCell: (params) => {
      return params.value;
    },
  },
];
