import { FC, useState } from 'react';
import classNames from 'classnames';

import { PostLikeToggle } from 'components/views';
import { likePostComment, unlikePostComment } from 'store/slices/activitiesSlices/thunks';
import { CustomTypography } from 'components';

import { TCommentFooterProps } from '../types';
import styles from '../Comment.module.scss';

const CommentsFooter: FC<TCommentFooterProps> = ({
  comment,
  replyCount,
  getComments,
  replyComment,
  parentPostId,
  toggleReplies,
  isRepliesOpened,
  haveReplies = false,
  getPostCommentReplies,
}) => {
  const [likesCount, setLikesCount] = useState<number>(comment?.no_of_likes || 0);

  const addLikesCount = () => {
    setLikesCount((prev) => prev + 1);
  };

  const decreaseLikesCount = () => {
    setLikesCount((prev) => prev - 1);
  };

  const toggleClasses = classNames(
    styles.container_comment_action_block_reply,
    styles.container_comment_action_block_reply_btn,
  );

  return (
    <>
      {comment && (
        <>
          <div className={styles.container_comment_action}>
            <div className={styles.container_comment_action_block}>
              <PostLikeToggle
                id={comment?.id}
                postId={parentPostId}
                isLiked={comment?.is_liked}
                likeRequest={likePostComment}
                addLikesCount={addLikesCount}
                unLikeRequest={unlikePostComment}
                decreaseLikesCount={decreaseLikesCount}
                getPostComments={
                  comment?.activity_type === 'post' ? getComments : getPostCommentReplies
                }
              />
              <div className={styles.container_comment_action_block_divider} />
              <button
                role='button'
                onClick={replyComment}
                className={styles.container_comment_action_block_reply}
              >
                {!isNaN(replyCount) && replyCount !== 0 && replyCount} Reply
              </button>
            </div>
            <div className={styles.container_comment_action_block_likes}>
              <CustomTypography>{Number(likesCount || 0)} Likes</CustomTypography>
            </div>
          </div>
          {haveReplies && (
            <>
              <CustomTypography role='button' onClick={toggleReplies} className={toggleClasses}>
                {isRepliesOpened ? 'Hide Replies' : 'Show Replies'}
              </CustomTypography>
            </>
          )}
        </>
      )}
    </>
  );
};

export default CommentsFooter;
