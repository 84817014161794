import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { UseAutocompleteProps } from '@mui/material';

import { useAppSelector } from 'hooks';
import { ArrowDown, SquareDeleteIcon } from 'assets/icons';
import { organizationMembersSelector } from 'store/slices/organizationsSlice/selectors';

import Chip from '../Chip';
import { TOption } from '../Select/types';
import { TMember } from '../Team/types';

import { TCustomSelect } from './types';
import { sxStyles } from './style';

const SelectWithChips: React.FC<TCustomSelect> = ({
  options,
  onChange,
  className,
  popupIcon,
  setMembers,
  placeholder,
  borderColor,
  memberRole,
  handleDelete,
  borderRadius,
  backGroundColor,
  selectedOptions,
  setSelectedOptions,
  defaultValue = [],
}) => {
  const allMembers = useAppSelector(organizationMembersSelector);

  const handleChange: UseAutocompleteProps<TOption, true, false, false>['onChange'] = (
    _event,
    newValue,
  ) => {
    const val = newValue as TOption[];

    const filteredMembersIds = val.map((el) => el.id || el.value);

    const selectedMembers = allMembers?.filter((el) => {
      if (filteredMembersIds.includes(el.user_id)) {
        return {
          id: el.user_id,
          name: el.name,
          user_id: el.user_id,
          profile_image_url_id: el.profile_image_url_id,
        };
      }
    });

    setSelectedOptions?.(val);

    setMembers?.(selectedMembers as TMember[]);
    onChange?.(val);
  };

  return (
    <Autocomplete
      getOptionDisabled={(option) =>
        !option.value || ((option.disabled && memberRole === 'member') as boolean)
      }
      multiple
      id='select-with-chips'
      options={options}
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.label === value.label
      }
      sx={{
        ...sxStyles,

        backgroundColor: backGroundColor,
        borderRadius: borderRadius,
        border: `1px solid ${borderColor}`,
      }}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option?.label || ''}
      value={selectedOptions}
      onChange={handleChange}
      popupIcon={popupIcon || <ArrowDown />}
      className={className}
      disableClearable
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            color={option.color as string}
            deleteIcon={<SquareDeleteIcon width={16} height={16} />}
            label={option.label || ''}
            {...getTagProps({ index })}
            key={index}
            disabled={option.disabled && memberRole === 'member'}
            onDelete={() => handleDelete?.(option)}
          />
        ));
      }}
      renderInput={({ size, fullWidth, disabled, InputProps, inputProps, id }) => (
        <TextField
          id={id}
          size={size}
          variant='outlined'
          disabled={disabled}
          fullWidth={fullWidth}
          InputProps={{
            ...InputProps,
          }}
          inputProps={inputProps}
          placeholder={!selectedOptions?.length ? placeholder : ''}
        />
      )}
    />
  );
};

export default SelectWithChips;
