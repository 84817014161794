import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import dataStructure from 'constants/options';
import { BrowserStorageKeys, BrowserStorageService } from 'services';

import {
  createLiveStream,
  getLiveStreamsById,
  getMyCurrentStream,
  getLiveStreamDetails,
  getLiveStreamNotices,
  getLiveStreamMessages,
  getUserCreatedCompletedLiveStreams,
} from './thunks';

import type { TInitialState, TLiveStream, TLiveStreamMessagesReturnType } from './types';

const initialState: TInitialState = {
  liveStreamById: null,
  myCurrentStream: null,
  allLiveStreamLimit: 10,
  allLiveStreamOffset: 0,
  allLiveStreamError: null,
  liveStreamByIdError: null,
  myCurrentStreamLoad: false,
  myCurrentStreamError: null,
  userCreatedStreamsLimit: 5,
  allLiveStreamLoading: false,
  liveStreamNoticesLimit: 10,
  liveStreamNoticesOffset: 0,
  userCreatedStreamsOffset: 0,
  liveStreamNoticesError: null,
  liveStreamByIdLoading: false,
  allLiveStream: dataStructure,
  userCreatedStreamsLoad: false,
  liveStreamMessagesError: null,
  userCreatedStreamsError: null,
  currentStreamDetailsOffset: 0,
  currentStreamDetailsLimit: 10,
  currentStreamDetailsError: null,
  currentStreamDetailsLoad: false,
  liveStreamNoticesLoading: false,
  liveStreamNotices: dataStructure,
  liveStreamMessagesLoading: false,
  liveStreamMessages: dataStructure,
  userCreatedStreams: dataStructure,
  currentStreamDetails: dataStructure,
};

const liveStreamSlice = createSlice({
  name: 'requisitionSlice',
  reducers: {
    setPage(state, action) {
      state.allLiveStreamOffset = (action.payload - 1) * state.allLiveStreamLimit;
    },
    changePreviousLives(state, action) {
      state.userCreatedStreamsOffset = (action.payload - 1) * state.userCreatedStreamsLimit;
    },
    changePreviousLivesLimit(state, action) {
      state.userCreatedStreamsLimit = action.payload;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder

      .addCase(getUserCreatedCompletedLiveStreams.pending, (state) => {
        state.userCreatedStreamsLoad = true;
        state.userCreatedStreamsError = null;
      })
      .addCase(getUserCreatedCompletedLiveStreams.fulfilled, (state, action) => {
        state.userCreatedStreamsLoad = false;
        state.userCreatedStreams = action.payload;
        state.userCreatedStreamsError = null;
      })
      .addCase(getUserCreatedCompletedLiveStreams.rejected, (state, action) => {
        state.userCreatedStreamsLoad = false;
        state.userCreatedStreamsError = action.error.message as string;
      })
      .addCase(getLiveStreamNotices.pending, (state) => {
        state.liveStreamNotices = dataStructure;
        state.liveStreamNoticesLoading = true;
        state.liveStreamNoticesError = null;
      })
      .addCase(getLiveStreamNotices.fulfilled, (state, action) => {
        state.liveStreamNoticesLoading = false;
        state.liveStreamNotices = action.payload;
        state.liveStreamNoticesError = null;
      })
      .addCase(getLiveStreamNotices.rejected, (state, action) => {
        state.liveStreamNoticesLoading = false;
        state.liveStreamNoticesError = action.error.message as string;
      })
      .addCase(getLiveStreamDetails.pending, (state) => {
        state.currentStreamDetailsLoad = true;
        state.currentStreamDetailsError = null;
      })
      .addCase(getLiveStreamDetails.fulfilled, (state, action) => {
        state.currentStreamDetailsLoad = false;
        state.currentStreamDetails = action.payload;
        state.currentStreamDetailsError = null;
      })
      .addCase(getLiveStreamDetails.rejected, (state, action) => {
        state.currentStreamDetailsLoad = false;
        state.currentStreamDetailsError = action.error.message as string;
      })
      .addCase(getMyCurrentStream.pending, (state) => {
        state.myCurrentStreamLoad = true;
        state.myCurrentStreamError = null;
      })
      .addCase(getMyCurrentStream.fulfilled, (state, action) => {
        state.myCurrentStreamLoad = false;
        state.myCurrentStream = action.payload;
      })
      .addCase(getMyCurrentStream.rejected, (state, action) => {
        state.myCurrentStreamLoad = false;
        state.myCurrentStreamError = action.error.message as string;
      })
      .addCase(getLiveStreamsById.pending, (state) => {
        state.liveStreamByIdLoading = true;
        state.liveStreamByIdError = null;
      })
      .addCase(getLiveStreamsById.fulfilled, (state, action) => {
        state.liveStreamByIdLoading = false;
        state.liveStreamById = action.payload;
      })
      .addCase(getLiveStreamMessages.rejected, (state, action) => {
        state.liveStreamMessagesLoading = false;
        state.liveStreamMessagesError = action.error.message as string;
      })
      .addCase(getLiveStreamMessages.pending, (state) => {
        state.liveStreamMessagesLoading = true;
        state.liveStreamMessagesError = null;
      })
      .addCase(
        getLiveStreamMessages.fulfilled,
        (state, action: PayloadAction<TLiveStreamMessagesReturnType>) => {
          state.liveStreamMessagesLoading = false;
          state.liveStreamMessages = action.payload;
          state.liveStreamMessagesError = null;
        },
      )
      .addCase(createLiveStream.fulfilled, (_state, action: PayloadAction<TLiveStream>) => {
        BrowserStorageService.set(
          BrowserStorageKeys.currentLiveStreamId,
          String(action.payload.id),
          { session: true },
        );
      })
      .addCase(getLiveStreamsById.rejected, (state, action) => {
        state.liveStreamByIdLoading = false;
        state.liveStreamByIdError = action.error.message as string;
      });
  },
});

export default liveStreamSlice.reducer;

export const { setPage, changePreviousLives, changePreviousLivesLimit } = liveStreamSlice.actions;
