import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { documentsApi } from 'api';
import { DocumentsToastMessages } from 'constants/ToastMessages';
import { RootState } from 'types';

import { TDocumentsListParams, TDocumentsTypesListParams } from './types';

export const getDocumentList = createAsyncThunk(
  'documentsSlice/getDocumentsList',
  async (options: TDocumentsListParams | undefined, { getState }) => {
    const {
      documents: { documentsLimit, documentsOffset },
    } = getState() as RootState;

    const { limit = documentsLimit, offset = documentsOffset, order_by, direction } = options ?? {};

    try {
      const response = await documentsApi.getAllDocumentsRequest({
        limit,
        offset,
        order_by,
        direction,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getDocumentTypes = createAsyncThunk(
  'documentsSlice/getDocumentTypes',
  async ({ id, options }: TDocumentsTypesListParams, { getState }) => {
    const {
      documents: { documentsLimit, documentsOffset },
    } = getState() as RootState;

    const { limit = documentsLimit, offset = documentsOffset, order_by, direction } = options ?? {};

    try {
      const response = await documentsApi.getDocumentTypesRequest(id, {
        limit,
        offset,
        order_by,
        direction,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const getDocumentByIdAndSection = createAsyncThunk(
  'documentsSlice/getDocumentByIdAndSection',
  async ({ id, type, options }: TDocumentsTypesListParams, { getState }) => {
    const {
      documents: { documentsLimit, documentsOffset },
    } = getState() as RootState;

    const { limit = documentsLimit, offset = documentsOffset, order_by, direction } = options ?? {};

    try {
      const response = await documentsApi.getDocumentByIdAndSectionRequest(id, type as string, {
        limit,
        offset,
        order_by,
        direction,
      });

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const uploadDocument = createAsyncThunk(
  'documentsSlice/uploadDocument',
  async (body: FormData, { dispatch }) => {
    const start = toast.loading(DocumentsToastMessages.UPLOAD_START);

    try {
      const response = await documentsApi.uploadDocumentRequest(body);

      toast.update(start, {
        render: DocumentsToastMessages.UPLOAD_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getDocumentList());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: DocumentsToastMessages.UPLOAD_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const deleteDocument = createAsyncThunk(
  'documentsSlice/deleteDocument',
  async (id: number, { dispatch }) => {
    const start = toast.loading(DocumentsToastMessages.DOCUMENT_REMOVAL_START);

    try {
      const response = await documentsApi.deleteDocumentRequest(id);

      toast.update(start, {
        render: DocumentsToastMessages.DOCUMENT_REMOVAL_SUCCESS,
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });

      dispatch(getDocumentList());

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      toast.update(start, {
        render: DocumentsToastMessages.DOCUMENT_REMOVAL_FAILURE,
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      throw Error;
    }
  },
);

export const getDocumentById = createAsyncThunk(
  'customerSlice/getDocumentById',
  async (id: string) => {
    try {
      const response = await documentsApi.getDocumentByIdRequest(id);

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);
