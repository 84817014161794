import moment from 'moment';
import 'moment-timezone';

const getCurrentDateTime = (localTimeZone = moment.tz.guess()): string => {
  // Use moment() to get the current date and time in the local time zone
  const currentDateTime = moment().tz(localTimeZone);

  // Format the current date and time as a string
  const formattedDateTime = currentDateTime.format('YYYY-MM-DD HH:mm:ss');

  return formattedDateTime;
};

export default getCurrentDateTime;
