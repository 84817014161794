import { FC } from 'react';
import { Box } from '@mui/material';

import { ControlledInput, CustomTypography, NumberControlledInput } from 'components';
import { Colors, FontNames } from 'types';
import { MinusIcon } from 'assets/icons';

import styles from './BillingInfoList.module.scss';

import type { TBillingInfoListProps } from './types';

const BillingInfoList: FC<TBillingInfoListProps> = ({ control, errors, idx, name, onRemove }) => (
  <Box className={styles.container}>
    <Box className={styles.container__content}>
      <ControlledInput
        required
        label='Bill to:'
        control={control}
        borderColor={Colors.SOFT_SILVER}
        placeholder='Bill to:'
        borderRadius='8px'
        backgroundColor={Colors.FROST_WHITE}
        name={`${name}.bill_to`}
        fontFamily={FontNames.CIRCULAR_REG}
        error={!!errors?.[idx]?.bill_to}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
        helperText={errors?.[idx]?.bill_to?.message}
      />
      <ControlledInput
        required
        label='Email:'
        control={control}
        placeholder='Email:'
        name={`${name}.email`}
        borderColor={Colors.SOFT_SILVER}
        borderRadius='8px'
        backgroundColor={Colors.FROST_WHITE}
        error={!!errors?.[idx]?.email}
        fontFamily={FontNames.CIRCULAR_REG}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
        helperText={errors?.[idx]?.email?.message}
      />
      <NumberControlledInput
        required
        control={control}
        label='Phone Number:'
        borderColor={Colors.SOFT_SILVER}
        borderRadius='8px'
        backgroundColor={Colors.FROST_WHITE}
        placeholder='Phone Number:'
        name={`${name}.phone_number`}
        fontFamily={FontNames.CIRCULAR_REG}
        error={!!errors?.[idx]?.phone_number}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
        helperText={errors?.[idx]?.phone_number?.message}
      />
      <ControlledInput
        required
        label='State:'
        control={control}
        placeholder='State:'
        borderColor={Colors.SOFT_SILVER}
        name={`${name}.state`}
        borderRadius='8px'
        backgroundColor={Colors.FROST_WHITE}
        fontFamily={FontNames.CIRCULAR_REG}
        error={!!errors?.[idx]?.state}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
        helperText={errors?.[idx]?.state?.message}
      />

      <ControlledInput
        required
        label='Address:'
        control={control}
        borderColor={Colors.SOFT_SILVER}
        placeholder='Address:'
        borderRadius='8px'
        name={`${name}.address`}
        backgroundColor={Colors.FROST_WHITE}
        fontFamily={FontNames.CIRCULAR_REG}
        error={!!errors?.[idx]?.address}
        helperText={errors?.[idx]?.address?.message}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
      />
      <ControlledInput
        required
        label='Zip:'
        control={control}
        placeholder='Zip:'
        borderRadius='8px'
        name={`${name}.zip`}
        borderColor={Colors.SOFT_SILVER}
        backgroundColor={Colors.FROST_WHITE}
        error={!!errors?.[idx]?.zip}
        fontFamily={FontNames.CIRCULAR_REG}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
        helperText={errors?.[idx]?.zip?.message}
      />
      <ControlledInput
        required
        label='City:'
        control={control}
        borderRadius='8px'
        placeholder='City:'
        borderColor={Colors.SOFT_SILVER}
        name={`${name}.city`}
        backgroundColor={Colors.FROST_WHITE}
        error={!!errors?.[idx]?.city}
        fontFamily={FontNames.CIRCULAR_REG}
        labelColor={Colors.ENIGMATIC_MIDNIGHT}
        helperText={errors?.[idx]?.city?.message}
      />
    </Box>
    {idx !== 0 && (
      <Box className={styles.container__add} onClick={() => onRemove(idx)}>
        <MinusIcon width={24} height={24} />

        <CustomTypography className={styles.container__add__text}>Remove</CustomTypography>
      </Box>
    )}
  </Box>
);

export default BillingInfoList;
