import { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { CustomizedAvatar, CustomizedTooltip, ScrollLayout } from 'components/shared';
import PurchaseBox from 'containers/Inventory/PurchaseBox';
import { useAppSelector } from 'hooks';
import { userDataSelector } from 'store/slices/authSlice/selectors';

import styles from './PostLikesView.module.scss';

import type { TPostLikesViewProps } from './types';

const PostLikesView: FC<TPostLikesViewProps> = ({ data }) => {
  const userInfo = useAppSelector(userDataSelector);

  const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

  const handleCloseTooltip = () => setIsShowTooltip(false);
  const handleOpenTooltip = () => setIsShowTooltip(true);

  const lastLikedUser =
    data?.data?.[0]?.user_id === userInfo?.id ? 'You' : data?.data?.[0]?.user_name;

  const users = data?.data?.map((user) => {
    const isMe = user?.user_id === userInfo?.id;

    return (
      <Stack gap='5px' key={user?.user_id} direction='row'>
        <CustomizedAvatar
          height={20}
          width={20}
          key={user?.user_id}
          src={user?.profile_image_url_id}
          alt='img'
        />
        <p>{isMe ? 'You' : user?.user_name}</p>
      </Stack>
    );
  });

  return (
    <Box className={styles.container}>
      {data?.total_likes > 1 ? (
        <CustomizedTooltip
          arrow
          forHtml={false}
          maxWidth={200}
          arrowColor='white'
          open={isShowTooltip}
          placement='bottom-start'
          background='transparent'
          onClose={handleCloseTooltip}
          PopperProps={{ style: { zIndex: 99 } }}
          title={
            <PurchaseBox
              withSubmit={false}
              onClose={handleCloseTooltip}
              title={`Likes (${data?.total_likes})`}
              content={
                <ScrollLayout height={200} width={200} withScrollStyles direction='y'>
                  <Stack gap='5px'>{users}</Stack>
                </ScrollLayout>
              }
            />
          }
        >
          <div role='button' className={styles.container__text} onMouseOver={handleOpenTooltip}>
            {lastLikedUser && (
              <p className={styles.container__text__name} title={lastLikedUser}>
                {lastLikedUser}
              </p>
            )}
            {data?.total_likes > 1 && <span> and {Number(data?.total_likes - 1)} others</span>}
          </div>
        </CustomizedTooltip>
      ) : (
        <div role='button' className={styles.container__text}>
          {lastLikedUser && (
            <p className={styles.container__text__name} title={lastLikedUser}>
              {lastLikedUser}
            </p>
          )}
          {data?.total_likes > 1 && <span> and {Number(data?.total_likes - 1)} others</span>}
        </div>
      )}
    </Box>
  );
};

export default PostLikesView;
