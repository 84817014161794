import { FC } from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import CustomTypography from '../Typography';

import styles from './PriceValueBox.module.scss';

import type { TPriceValueBoxProps } from './types';

const PriceValueBox: FC<TPriceValueBoxProps> = ({ duration, price, isActive }) => {
  const priceBoxClasses = classNames(styles.container, { [styles.container_active]: isActive });

  return (
    <Box className={priceBoxClasses}>
      <Box className={styles.container__content}>
        {price === String(0) ? (
          <CustomTypography className={styles.container__content__price__sales}>
            <Link to={'#'}>Contact Sales</Link> for price estimate
          </CustomTypography>
        ) : (
          <>
            <CustomTypography className={styles.container__content__price}>
              {price}
            </CustomTypography>
            <CustomTypography className={styles.container__content__duration}>
              {duration}
            </CustomTypography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PriceValueBox;
