import { useEffect } from 'react';

import { useAppDispatch } from 'hooks';
import { SettingsItem } from 'components';
import { getUserSettings } from 'store/slices/authSlice/authThunks';

import styles from './Settings.module.scss';
import mockDataSettings from './mockData';

const Settings = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingsItems = mockDataSettings.map(({ title, id, subSettings }) => {
    return <SettingsItem key={id} title={title} subSettings={subSettings} id={id} />;
  });

  return <div className={styles.container}>{settingsItems}</div>;
};

export default Settings;
